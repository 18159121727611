import { Typography } from '@mui/material';
import * as React from 'react';

interface RoadStatusLegendItemProps {
    color: string;
    text: string;
    keySuffix: string;
}

const RoadStatusLegendItem = (props: RoadStatusLegendItemProps) => {
    return (
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: 200, marginTop: 4, marginBottom: 4 }}>
            <div
                key={`${props.keySuffix}-color`}
                style={{ backgroundColor: props.color, width: 20, height: 20, borderRadius: 10, marginRight: 8 }}
            />
            <div
                key={`${props.keySuffix}-text`}
                style={{ color: 'white', minWidth: 100 }}
            >
                {props.text}
            </div>
        </div>
    );
};

export const RoadStatusLegendComponent = () => {
    return (
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap', maxWidth: 590 }}>
            <RoadStatusLegendItem
                color="#9c9c9c"
                text="Unknown"
                keySuffix="unknown"
            />
            <RoadStatusLegendItem
                color="#007f00"
                text="Dry / Normal"
                keySuffix="dry"
            />
            <RoadStatusLegendItem
                color="#3f6fbc"
                text="Wet / Slush"
                keySuffix="Wet"
            />
            <RoadStatusLegendItem
                color="#ea81f3"
                text="Black Ice"
                keySuffix="Ice"
            />
            <RoadStatusLegendItem
                color="#ffffff"
                text="Snow-Covered"
                keySuffix="Snow"
            />
            <RoadStatusLegendItem
                color="#7c1e19"
                text="Fog / Low Visibility"
                keySuffix="visibility"
            />
            <RoadStatusLegendItem
                color="#ff0000"
                text="Severe Driving Conditions"
                keySuffix="Severe"
            />
            <RoadStatusLegendItem
                color="#8f0c91"
                text="Road Closed"
                keySuffix="closed"
            />
            <div style={{ height: 1, backgroundColor: 'white', opacity: 0.5, marginTop: 12, marginBottom: 12, width: '100%' }} />
            <div>
                <Typography variant={'caption'} style={{ opacity: 0.7 }}>
                    * Driving conditions are mapped on major thoroughfares in: MT, NE, SD, MN.
                    <br />
                    Outside these states, we show weather stations that report precipitation.
                    {' '}
                    <strong>Please do not assume that a lack of reports necessarily indicates good conditions.</strong>
                </Typography>
            </div>
        </div >
    );
};