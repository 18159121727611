/*
* Adapted from https://github.com/numbers/numbers.js
*/

const boxMullerTransform = (mu: number, stdev: number): number => {
    let u = 0;
    let v = 0;
    let s = undefined;

    do {
        u = Math.random() * 2 - 1;
        v = Math.random() * 2 - 1;
        s = u * u + v * v;
    } while (s === 0 || s > 1);

    let c = Math.sqrt(-2 * Math.log(s) / s);
    let x = u * c;
    x = mu + x * stdev;
    return x;
};

/**
* Returns an array of random numbers over a normal ditribution
* 
* @param mu mu or mean
* @param stdev sigma or standard deviation
* @param size size of the desired array
* @returns array of size n of a log normal distribution
*/
export function normalDist(mu: number, stdev: number, size:number = 10000): number[] {
    let results = [];

    for (let i = 0; i < size; i++) {
        let randomBMT = boxMullerTransform(mu, stdev);
        results.push(randomBMT);
    }

    return results;
}
