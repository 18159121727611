import { PermissionName } from "../../../types";

export interface Tier {
    maxCalls: number;
    pricePerCall: number;
}

export interface ServiceConfig {
    tiers: Tier[];
    callsMultiplier: number;
}

export interface TierWithCost {
    tier: Tier;
    cost: number;
}

export interface CostCalculationResults {
    tiers: TierWithCost[];
    totalCost: number;
}

export function calculateCostOfConfig(config: ServiceConfig, calls: number) {
    let callsRemaining = calls;
    let totalCost = 0;

    const tiers: TierWithCost[] = config.tiers.map(tier => {
        const callsUsed = calls - callsRemaining;
        const callsInTier = Math.min(callsRemaining, tier.maxCalls - callsUsed);
        callsRemaining -= callsInTier;

        const cost = callsInTier * tier.pricePerCall;
        totalCost += cost;

        return { tier, cost };
    });

    return { tiers, totalCost };
}

export const MAX_CALLS = 9999 * 1000000;

export const configForPermission = (permission: PermissionName, historicalPeriod?: string) => {
    switch (permission) {
        case PermissionName.RIGHTROUTE_FORECAST_STANDARD:
            return rightrouteConfig;
        case PermissionName.RIGHTROUTE_FORECAST_CUSTOM:
            return rightrouteCustomConfig;
        case PermissionName.RIGHTROUTE_FORECAST_RANGE:
            return rightrouteRangeConfig;
        case PermissionName.STANDARD_WEATHER:
            return weatherConfig;
        case PermissionName.HISTORICAL_STANDARD_WEATHER:
            return historicalWeatherConfig;
        case PermissionName.IMPACT:
            return impactConfig;
        case PermissionName.HISTORICAL_IMPACT:
            return historicalImpactConfig;
        case PermissionName.WEATHER_TILES:
            return weatherTilesConfig;
        case PermissionName.IMPACT_TILES:
            return impactTilesConfig;
        case PermissionName.RIGHTROUTE_HISTORICAL_CUSTOM:
            switch (historicalPeriod) {
                case '1year':
                    return rightrouteHistoricalCustom1yearConfig;
                case '5year':
                    return rightrouteHistoricalCustom5yearConfig;
                case 'full':
                    return rightrouteHistoricalCustomFullConfig;
                default:
                    throw new Error(`invalid historical period or no config for historical period: ${historicalPeriod}`);
            }
        case PermissionName.RIGHTROUTE_HISTORICAL_STANDARD:
            switch (historicalPeriod) {
                case '1year':
                    return rightrouteHistoricalStandard1yearConfig;
                case '5year':
                    return rightrouteHistoricalStandard5yearConfig;
                case 'full':
                    return rightrouteHistoricalStandardFullConfig;
                default:
                    throw new Error(`invalid historical period or no config for historical period: ${historicalPeriod}`);
            }
        default:
            return undefined;
    }
};

export const rightrouteConfig: ServiceConfig = {
    callsMultiplier: 100,
    tiers: [
        {
            maxCalls: 200000,
            pricePerCall: 0.004444
        },
        {
            maxCalls: 1000000,
            pricePerCall: 0.003555
        },
        {
            maxCalls: 5000000,
            pricePerCall: 0.002667
        },
        {
            maxCalls: 20000000,
            pricePerCall: 0.001333
        },
        {
            maxCalls: MAX_CALLS,
            pricePerCall: 0.000888
        }
    ]
};

export const rightrouteCustomConfig: ServiceConfig = {
    callsMultiplier: 100,
    tiers: [
        {
            maxCalls: 200000,
            pricePerCall: 0.000833
        },
        {
            maxCalls: 1000000,
            pricePerCall: 0.000667
        },
        {
            maxCalls: 5000000,
            pricePerCall: 0.000500
        },
        {
            maxCalls: 20000000,
            pricePerCall: 0.000250
        },
        {
            maxCalls: MAX_CALLS,
            pricePerCall: 0.000167
        }
    ]
};

export const rightrouteRangeConfig: ServiceConfig = {
    callsMultiplier: 100,
    tiers: [
        {
            maxCalls: 200000,
            pricePerCall: 0.004888
        },
        {
            maxCalls: 1000000,
            pricePerCall: 0.003911
        },
        {
            maxCalls: 5000000,
            pricePerCall: 0.002934
        },
        {
            maxCalls: 20000000,
            pricePerCall: 0.001466
        },
        {
            maxCalls: MAX_CALLS,
            pricePerCall: 0.000977
        }
    ]
};

export const impactConfig: ServiceConfig = {
    callsMultiplier: 200,
    tiers: [
        {
            maxCalls: 200 * 1000,
            pricePerCall: 0.0008
        },
        {
            maxCalls: 1000000,
            pricePerCall: 0.0006
        },
        {
            maxCalls: 5 * 1000000,
            pricePerCall: 0.0004
        },
        {
            maxCalls: 20 * 1000000,
            pricePerCall: 0.0002
        },
        {
            maxCalls: MAX_CALLS,
            pricePerCall: 0.0001
        }
    ]
};

export const historicalImpactConfig: ServiceConfig = {
    callsMultiplier: 200,
    tiers: [
        {
            maxCalls: 200 * 1000,
            pricePerCall: 0.0016
        },
        {
            maxCalls: 1000000,
            pricePerCall: 0.0012
        },
        {
            maxCalls: 5 * 1000000,
            pricePerCall: 0.0008
        },
        {
            maxCalls: 20 * 1000000,
            pricePerCall: 0.0004
        },
        {
            maxCalls: MAX_CALLS,
            pricePerCall: 0.0002
        }
    ]
};

export const weatherConfig: ServiceConfig = {
    callsMultiplier: 200,
    tiers: [
        {
            maxCalls: 500000,
            pricePerCall: 0.0004
        },
        {
            maxCalls: 1000000,
            pricePerCall: 0.0003
        },
        {
            maxCalls: 5 * 1000000,
            pricePerCall: 0.0002
        },
        {
            maxCalls: 20 * 1000000,
            pricePerCall: 0.0001
        },
        {
            maxCalls: MAX_CALLS,
            pricePerCall: 0.00008
        }
    ]
};

export const historicalWeatherConfig: ServiceConfig = {
    callsMultiplier: 200,
    tiers: [
        {
            maxCalls: 500000,
            pricePerCall: 0.0008
        },
        {
            maxCalls: 1000000,
            pricePerCall: 0.0006
        },
        {
            maxCalls: 5 * 1000000,
            pricePerCall: 0.0004
        },
        {
            maxCalls: 20 * 1000000,
            pricePerCall: 0.0002
        },
        {
            maxCalls: MAX_CALLS,
            pricePerCall: 0.00016
        }
    ]
};

export const weatherTilesConfig: ServiceConfig = {
    callsMultiplier: 200,
    tiers: [
        {
            maxCalls: 1_000_000,
            pricePerCall: 0.00025
        },
        {
            maxCalls: 2_000_000,
            pricePerCall: 0.00016
        },
        {
            maxCalls: 5_000_000,
            pricePerCall: 0.00011
        },
        {
            maxCalls: 10_000_000,
            pricePerCall: 0.00009
        },
        {
            maxCalls: MAX_CALLS,
            pricePerCall: 0.000078
        }
    ]
};

export const impactTilesConfig: ServiceConfig = {
    callsMultiplier: 200,
    tiers: [
        {
            maxCalls: 1_000_000,
            pricePerCall: 0.0003
        },
        {
            maxCalls: 2_000_000,
            pricePerCall: 0.0002
        },
        {
            maxCalls: 5_000_000,
            pricePerCall: 0.00015
        },
        {
            maxCalls: 10_000_000,
            pricePerCall: 0.0001
        },
        {
            maxCalls: MAX_CALLS,
            pricePerCall: 0.00008
        }
    ]
};

const historicalCallsMultiplier = 10;

export const rightrouteHistoricalCustom1yearConfig: ServiceConfig = {
    callsMultiplier: historicalCallsMultiplier,
    tiers: [{
        maxCalls: MAX_CALLS,
        pricePerCall: 0.012
    }]
};

export const rightrouteHistoricalCustom5yearConfig: ServiceConfig = {
    callsMultiplier: historicalCallsMultiplier,
    tiers: [{
        maxCalls: MAX_CALLS,
        pricePerCall: 0.016
    }]
};

export const rightrouteHistoricalCustomFullConfig: ServiceConfig = {
    callsMultiplier: historicalCallsMultiplier,
    tiers: [{
        maxCalls: MAX_CALLS,
        pricePerCall: 0.020
    }]
};

export const rightrouteHistoricalStandard1yearConfig: ServiceConfig = {
    callsMultiplier: historicalCallsMultiplier,
    tiers: [{
        maxCalls: MAX_CALLS,
        pricePerCall: 0.018
    }]
};

export const rightrouteHistoricalStandard5yearConfig: ServiceConfig = {
    callsMultiplier: historicalCallsMultiplier,
    tiers: [{
        maxCalls: MAX_CALLS,
        pricePerCall: 0.022
    }]
};

export const rightrouteHistoricalStandardFullConfig: ServiceConfig = {
    callsMultiplier: historicalCallsMultiplier,
    tiers: [{
        maxCalls: MAX_CALLS,
        pricePerCall: 0.026
    }]
};
