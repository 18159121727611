import { BasicLatLng, LocationData, StoreState } from '../../types';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ClearSaveCityError, createCity, deleteCity, SelectCity } from '../../actions/SelectedCity';
import { loadWeatherIfNeeded } from '../../actions/Weather';
import { geocodeCity } from '../../actions/SelectedCity';
import { loadGovernmentalAlertsIfNeeded, loadRatingsIfNeeded } from '../../actions/Ratings';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { ClientAlertsPage } from '../../components/Client/Alerts';
import { MapCenterChanged, MapZoomLevelChanged } from "../../actions/MapView";

export function mapStateToProps(state: StoreState, _ownProps: RouteComponentProps<any>) {
    return {
        ...state.alertsView,

        center: state.mapView.center,
        zoomLevel: state.mapView.zoomLevel,

        weatherData: state.weather.weatherData,
        selectedCityState: state.selectedCity,
        userData: state.user,

        governmentalAlerts: state.ratings.governmentalAlerts,
    };
}

export function mapDispatchToProps(dispatch: ThunkDispatch<StoreState, void, Action<any>>) {
    return {
        onCitySelected: (city: LocationData | undefined) => {
            dispatch(SelectCity({ city }));
            if (city) {
                if (city.needsGeocoding === true) {
                    dispatch(geocodeCity(city));
                } else {
                    dispatch(loadWeatherIfNeeded());
                    dispatch(loadRatingsIfNeeded());
                }
            }
        },
        onCenterChanged: (center: BasicLatLng) => {
            dispatch(MapCenterChanged({ center }));
        },
        onZoomLevelChanged: (zoomLevel: number) => {
            dispatch(MapZoomLevelChanged({ zoomLevel }));
        },
        onCitySaved: (city: LocationData) => {
            dispatch(createCity(city));
        },
        onCityUnsaved: (city: LocationData) => {
            dispatch(deleteCity(city));
        },
        onCitySaveErrorDismissed: (city: LocationData) => {
            dispatch(ClearSaveCityError({ city }));
        },
        loadGovernmentalAlertsIfNeeded: () => {
            dispatch(loadGovernmentalAlertsIfNeeded());
        }
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ClientAlertsPage));
