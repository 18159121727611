import './LoginForm.css';
import * as React from 'react';
import { ScaledIcon } from './ScaledIcon';
import { Button, LinearProgress, TextField, Typography } from '@mui/material';
import { Redirect } from 'react-router';

interface Props {
    lastError?: string;
    isLoggingIn: boolean;
    initiateCreateLogin: (email: string, token: string, company: string, password: string, submit: () => void) => Promise<void>;
}

interface State {
    email: string;
    token: string;
    company: string;
    password: string;
    confirmPassword: string;
    loginCreated: boolean;
}

class CreateLoginFormWithState extends React.Component {
    state: State = {
        email: "",
        token: "",
        company: "",
        password: "",
        confirmPassword: "",
        loginCreated: false,
    };

    props: Props;

    componentDidMount(): void {
        super.componentDidMount?.();

        const urlParams = new URLSearchParams(window.location.search);
        this.setState({
            ...this.state,
            email: urlParams.get('email'),
            token: urlParams.get('token'),
            loginCreated: false,
        });
    }

    onChange(fieldName: string, fieldValue: string) {
        this.setState({
            ...this.state,
            [fieldName]: fieldValue,
        });
    }

    onChangeInput(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
        this.onChange(event.target.name, event.target.value);
    }

    newLoginCreated = () => {
        this.setState({
            ...this.state,
            loginCreated: true,
        });
    };

    onSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        event.stopPropagation();
        event.bubbles = false;

        this.props.initiateCreateLogin(this.state.email, this.state.token, this.state.company, this.state.password, this.newLoginCreated);

        return false;
    }

    render() {
        if (this.state.loginCreated && this.props.lastError === undefined) {
            return (<Redirect to={"/"} />);
        }

        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.get('email') === undefined || urlParams.get('token') === undefined) {
            return (<div>
                Invalid URL please contact us at: contact@weatheroptics.co
            </div>);
        }

        let lastErrorDisplay;
        if (this.props.lastError) {
            lastErrorDisplay = (
                <div>
                    <Typography color={'error'} variant={'body1'}>
                        {this.props.lastError}
                    </Typography>
                    <br />
                </div>
            );
        }

        return (
            <div className={"LoginForm"}>
                <div className={"LoginForm-wrap"}>
                    <ScaledIcon asset='logo' className={"LoginForm-logo"} alt="WeatherOptics" />
                    <br />
                    <Typography variant={'h5'} color={'textPrimary'}>Welcome to the WeatherOptics Insight Portal</Typography>
                    <br />
                    <br />


                    <form onSubmit={event => this.onSubmit(event)}>
                        <TextField
                            required
                            name="company"
                            onChange={(event) => this.onChangeInput(event)}
                            style={{ minWidth: 280 }}
                            placeholder="Company Name"
                        />
                        <br />
                        <br />
                        <TextField
                            required
                            name="password"
                            type="password"
                            onChange={(event) => this.onChangeInput(event)} className={"LoginForm-textfield"}
                            placeholder="Set Password"
                            style={{ minWidth: 280 }}
                        />
                        <br />
                        <br />
                        <TextField
                            required
                            error={this.state.confirmPassword !== "" && this.state.password !== this.state.confirmPassword}
                            helperText={this.state.confirmPassword !== "" && this.state.password !== this.state.confirmPassword ? "Passwords do not match" : ""}
                            name="confirmPassword"
                            type="password"
                            onChange={(event) => this.onChangeInput(event)} className={"LoginForm-textfield"}
                            placeholder="Confirm Password"
                            style={{ minWidth: 280 }}
                        />
                        <br />
                        <br />

                        {lastErrorDisplay}

                        <Typography variant={'body2'} color={'textSecondary'}>
                            Your trial ends after 14 days. During the trial, you will be limited to 50 routes and 100 locations.
                        </Typography>
                        <Typography style={{ marginBottom: 20 }} variant={'body2'} color={'textSecondary'}>
                            You can skip the trial using the &quot;Activate Full Version&quot; button in the My Account tab once you log in.
                        </Typography>

                        {this.props.isLoggingIn && (
                            <div>
                                <LinearProgress />
                                <br />
                            </div>
                        )}

                        <div>
                            <Button
                                type={'submit'}
                                variant={'contained'}
                                color={'primary'}
                                disabled={this.state.company === "" || this.state.password === "" || this.state.password !== this.state.confirmPassword}
                            >
                                Let&apos;s Go
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

const CreateLoginForm = (props: Props) => {
    return (
        <CreateLoginFormWithState {...props} />
    );
};

export default CreateLoginForm;