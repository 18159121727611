import {LocationData} from '../../../types';
import {DataGridPro, GridRenderCellParams, GridValueGetterParams} from '@mui/x-data-grid-pro';
import * as React from 'react';
import { Button } from '@mui/material';
import { DeleteOutline, EditOutlined } from '@mui/icons-material';

export const LocationsTable = ({locations, onDeleteLocationClicked, onEditLocationClicked}: { locations: LocationData[]; onDeleteLocationClicked: (location: LocationData) => void; onEditLocationClicked: (location: LocationData) => void}) => {
    const columns = [{
        field: 'name',
        headerName: 'Name',
        flex: 2
    }, {
        field: 'latitude',
        headerName: 'Latitude',
        flex: 1
    }, {
        field: 'longitude',
        headerName: 'Longitude',
        flex: 1
    }, {
        field: 'actions',
        headerName: 'Actions',
        width: 160,
        sortable: false,
        valueGetter: (params: GridValueGetterParams) => {
            return locations.filter(x => x.id === params.id)[0];
        },
        renderCell: (params: GridRenderCellParams) => {
            return (
                <div>
                    <Button
                        onClick={() => onEditLocationClicked(params.value as LocationData)}
                    >
                        <EditOutlined/>
                    </Button>
                    <Button
                        onClick={() => onDeleteLocationClicked(params.value as LocationData)}
                    >
                        <DeleteOutline/>
                    </Button>
                </div>
            );
        }
    }];

    return (
        <div>
            <DataGridPro
                columns={columns}
                rows={locations}
                pageSize={10}
                pagination
                autoHeight
                disableSelectionOnClick
            />
        </div>
    );
};
