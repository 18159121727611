import { StoreState } from '../../types';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { GovernmentalAlertsPage } from 'src/components/Public/GovernmentalAlerts';

export function mapStateToProps(state: StoreState, _ownProps: RouteComponentProps<any>) {
    const params = new URLSearchParams(_ownProps.location.search);
    return {
        governmentalAlertId: _ownProps.match.params.governmentalAlertId,
        token: params.get('token'),
        showAlertInfo: (params.get('show_alert_info') || 'true') === 'true',
        showMapControls: (params.get('show_map_controls') || 'true') === 'true',
    };
}

export function mapDispatchToProps(dispatch: ThunkDispatch<StoreState, void, Action<any>>) {
    return {};
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GovernmentalAlertsPage));