import * as React from 'react';
import { cardBackgroundColor, API_HOST, IMPACT_TILES_ENDPOINT } from "../../../constants";
import { HistoricalStormEventReport } from '.';
import { getPostData } from 'src/actions/SelectedCity';
import { Blurb, isLatLngInViewport, ViewportData } from 'src/types';

interface HistoricalAccuracyCalloutViewProps {
    apiToken: string | undefined;
    portalToken: string | undefined;
    groundTruthReport: HistoricalStormEventReport | undefined;
    impactBlurbs: Blurb[];
    stormLocation: ViewportData | undefined;

    lat: number;
    lng: number;

    onClose: () => void;
}

const getForecastedImpactForGroundTruthReport = async (groundTruthReport: HistoricalStormEventReport, portalToken: string | undefined, impactAbort: AbortController) => {
    if (groundTruthReport.polygon) {
        const postData: RequestInit = {
            body: JSON.stringify(groundTruthReport.polygon),
            cache: 'no-cache',
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
            signal: impactAbort && impactAbort.signal
        };

        const impactScoreUrl = `${IMPACT_TILES_ENDPOINT}/statistics?url=${encodeURIComponent(groundTruthReport.tileUrl || '')}&expression=b1%2F255*10&token=${portalToken}`;
        try {
            const response = await fetch(impactScoreUrl, postData);
            const json = await response.json();
            if (response.status === 200) {
                // this route seems to be returning the GET version of the /statistics endpoint
                // but only sometimes depending on the url so we just support both as a hack
                try {
                    return json['b1/255*10'].max;
                } catch (error) {
                    return json.properties.statistics['b1/255*10'].max;
                }
            }
            console.log('error loading impact value');
        } catch (error) {
            console.log(error);
        }
        return undefined;
    } else {
        const getData: RequestInit = {
            cache: 'no-cache',
            method: 'GET',
            mode: 'cors',
            signal: impactAbort && impactAbort.signal
        };

        const impactScoreUrl = `${IMPACT_TILES_ENDPOINT}/point/${groundTruthReport.location?.longitude},${groundTruthReport.location?.latitude}?url=${encodeURIComponent(groundTruthReport?.tileUrl || '')}&expression=b1%2F255*10&token=${portalToken}`;
        try {
            const response = await fetch(impactScoreUrl, getData);
            const json = await response.json();
            if (response.status === 200) {
                return json.values[0];
            }
            console.log('error loading impact value');
        } catch (error) {
            console.log(error);
        }
        return undefined;
    }
};

export const HistoricalAccuracyCalloutView = (props: HistoricalAccuracyCalloutViewProps) => {
    const { apiToken, portalToken, groundTruthReport } = props;
    const [impactScore, setImpactScore] = React.useState(undefined);
    const [locationLoading, setLocationLoading] = React.useState<boolean>(true);
    const [impactLoading, setImpactLoading] = React.useState<boolean>(true);
    const [clickEvent, setClickEvent] = React.useState(false);
    const [geocodeAbortController, setGeocodeAbortController] = React.useState<AbortController | undefined>(undefined);
    const [impactAbortController, setImpactAbortController] = React.useState<AbortController | undefined>(undefined);
    const latLngInStormBounds: boolean = props.stormLocation !== undefined ? isLatLngInViewport({ lat: props.lat, lng: props.lng }, props.stormLocation) : false;


    const fetchData = async () => {
        if (groundTruthReport === undefined) return;

        if (groundTruthReport.location.name === 'MAP CLICK') {
            setClickEvent(true);
            if (geocodeAbortController) {
                geocodeAbortController.abort();
            }

            groundTruthReport.location.name = '';
            const geocodeAbort = new AbortController();
            let postData: RequestInit = getPostData(
                {
                    name: groundTruthReport.location.name,
                    latitude: groundTruthReport.location.latitude,
                    longitude: groundTruthReport.location.longitude,
                    zip: groundTruthReport.location.zip,
                    token: apiToken
                },
                geocodeAbort
            );
            setGeocodeAbortController(geocodeAbort);

            const geoLocation = await fetch(`${API_HOST}/geocoding/geocode?token=${apiToken}`, postData)
                .then(
                    (response: Response) => response.json(),
                    (error: Error) => console.log(error)
                ).then((json: JSON) => {
                    return { ...groundTruthReport.location, name: json['name'], zip: json['zip'] };
                }).catch((error => {
                    console.log(error);
                }));
            if (geoLocation) {
                groundTruthReport.location = geoLocation;
            }
            setGeocodeAbortController(undefined);
        }
        setLocationLoading(false);

        if (impactAbortController) {
            impactAbortController.abort();
        }
        const impactAbort = new AbortController();
        setImpactAbortController(impactAbort);

        const impactValue = await getForecastedImpactForGroundTruthReport(groundTruthReport, portalToken, impactAbort);
        setImpactScore(impactValue);
        setImpactLoading(false);
        setImpactAbortController(undefined);
    };

    React.useEffect(() => {
        if (groundTruthReport !== undefined) {
            setLocationLoading(true);
            setImpactLoading(true);
            fetchData();
        }
    }, [groundTruthReport]);

    if (groundTruthReport === undefined) {
        return <div />;
    }

    const location = groundTruthReport.location;
    let locationName = '';
    if (location) {
        const zip = location.zip === undefined || location.zip === 'N/A' ? "" : location.zip;
        locationName = location.name !== '' ? location.name : `${location.latitude}, ${location.longitude}`;
        if (zip) {
            locationName += ` (${zip})`;
        }
    }

    let impactScoreDescription = 'Not Available';
    if (impactScore !== undefined) {
        const flooredImpactScore = parseInt(impactScore);
        impactScoreDescription = `${parseFloat(impactScore).toFixed(1)} -> ${props.impactBlurbs[flooredImpactScore].blurb}`;
    }

    return (
        <div className={'MapCalloutView'} onClick={(event) => event.stopPropagation()}>
            <div className={"container"} style={{ cursor: "default" }}>
                <div className={"body"} style={{ backgroundColor: cardBackgroundColor }}>
                    <button className={"close-button"} onClick={() => props.onClose()}>
                        <img alt={'Close'} src={"/images/popover_x.svg"} />
                    </button>
                    <div className={'historical-accuracy-marker-content'}>
                        <div className={'historical-accuracy-marker-content-title'}>
                            Location:
                        </div>
                        <div className={'historical-accuracy-marker-content-text'}>
                            {locationLoading && clickEvent ?
                                <img src={"/images/loading.gif"} alt={"Loading..."} /> :
                                locationName}
                        </div>
                    </div>
                    {groundTruthReport.description && <div className={'historical-accuracy-marker-content'}>
                        <div className={'historical-accuracy-marker-content-title'}>
                            Description:
                        </div>
                        <div className={'historical-accuracy-marker-content-text'}>
                            {groundTruthReport.description}
                        </div>
                    </div>}
                    {latLngInStormBounds && (
                        <div className={'historical-accuracy-marker-content'}>
                            <div className={'historical-accuracy-marker-content-title'}>
                                WO Predicted Impact:
                            </div>
                            <div className={'historical-accuracy-marker-content-text'}>
                                {impactLoading ?
                                    <img src={"/images/loading.gif"} alt={"Loading..."} /> :
                                    impactScoreDescription}
                            </div>
                        </div>
                    )}
                </div>
                <div className={"arrow-down"} style={{ borderTop: `20px solid ${cardBackgroundColor}` }} />
            </div>
        </div>
    );
};
