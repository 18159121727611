import { BasicLatLng, LocationData, StoreState } from '../../types';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ClientRadarPage } from '../../components/Client/Radar';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import {
    RadarSetAnimationSpeed,
    RadarSetPaused,
} from '../../actions/RadarView';
import { ClearSaveCityError, createCity, deleteCity, geocodeCity, SelectCity } from '../../actions/SelectedCity';
import { loadWeatherIfNeeded } from '../../actions/Weather';
import { loadRatingsIfNeeded } from '../../actions/Ratings';
import { MapCenterChanged, MapZoomLevelChanged } from "../../actions/MapView";

export function mapStateToProps(state: StoreState, _ownProps: RouteComponentProps<any>) {
    return {
        ...state.radarView,
        weatherTilesets: state.ratings.weatherTilesets?.value,
        center: state.mapView.center,
        zoomLevel: state.mapView.zoomLevel,
        weatherData: state.weather.weatherData,
        userData: state.user,
        selectedCityState: state.selectedCity,
        speedIncrements: [1, 2, 3, 0.5],
        baseAnimationDelay: 1000,
    };
}

export function mapDispatchToProps(dispatch: ThunkDispatch<StoreState, void, Action<any>>) {
    return {
        onCenterChanged: (center: BasicLatLng) => {
            dispatch(MapCenterChanged({ center }));
        },
        onZoomLevelChanged: (zoomLevel: number) => {
            dispatch(MapZoomLevelChanged({ zoomLevel }));
        },
        onPauseToggled: (paused: boolean) => {
            dispatch(RadarSetPaused({ paused }));
        },
        onAnimationSpeedChanged: (selectedSpeedMultiplierIndex: number, animationDelay: number) => {
            dispatch(RadarSetAnimationSpeed({ selectedSpeedMultiplierIndex, animationDelay }));
        },
        onCitySelected: (city: LocationData | undefined) => {
            dispatch(SelectCity({ city }));
            if (city) {
                if (city.needsGeocoding) {
                    dispatch(geocodeCity(city));
                } else {
                    dispatch(loadWeatherIfNeeded());
                    dispatch(loadRatingsIfNeeded());
                }
            }
        },
        onCitySaved: (city: LocationData) => {
            dispatch(createCity(city));
        },
        onCityUnsaved: (city: LocationData) => {
            dispatch(deleteCity(city));
        },
        onCitySaveErrorDismissed: (city: LocationData) => {
            dispatch(ClearSaveCityError({ city }));
        },
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ClientRadarPage));
