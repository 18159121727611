import { Redirect } from 'react-router';
import * as React from 'react';
import { UserState } from '../types';

interface Props {
    user: UserState;
}

/*
 * Index page for the app, manages redirecting to splash, camp portal, or client portal as needed.
 */
const IndexPage = ({ user }: Props) => {
    if (typeof user.token !== 'undefined') {
        return <Redirect to={"/client/dashboard"} />;
    } else {
        window.location.href = `${window.location.protocol}//${window.location.host}/login`;
        return <div />;
    }
};

export default IndexPage;
