import { DriverNotification, LocationData, StoreState, VehicleTrackingData } from '../../types';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { ClientPlacesPage } from '../../components/Client/Places';
import {
    ClosedUploadDialog,
    initiateBatchFileUpload,
    OpenedUploadDialog,
    processBatchUpload, SetSelectedPlacesTab
} from '../../actions/PlacesView';
import { BatchType } from '../../types/PlacesView';
import { RouteData } from 'src/types/routes';
import { createDriverNotification, deleteDriverNotification, updateDriverNotification } from 'src/actions/PlacesView';
import { createCity, deleteCity, updateCity } from 'src/actions/SelectedCity';
import { deleteRoute, updateRoute } from 'src/actions/RoutesView';
import { ClearUserCityUpdatedResponse, ClearUserRouteUpdatedResponse, setVehicleNotificationsEnabled } from 'src/actions/User';

export function mapStateToProps(state: StoreState, _ownProps: RouteComponentProps<any>) {
    return {
        userData: state.user,
        cities: state.user.cities ?? [],
        savedRoutes: state.user.savedRoutes ?? [],
        vehicles: state.user.vehicles ?? [],
        driverNotifications: state.user.driverNotifications ?? [],
        alerts: state.alertsView.possibleGovernmentalAlertNames ?? [],
        editRouteResponse: state.user.editRouteResponse,
        editLocationResponse: state.user.editLocationResponse,
        editDriverNotificationResponse: state.user.editDriverNotificationResponse,
        ...state.placesView
    };
}

export function mapDispatchToProps(dispatch: ThunkDispatch<StoreState, void, Action<any>>) {
    return {
        setSelectedTab: (selectedTab: number) => dispatch(SetSelectedPlacesTab({ selectedTab })),
        onUploadDialogOpened: (batchType: BatchType) => dispatch(OpenedUploadDialog({ batchType })),
        onUploadDialogClosed: () => dispatch(ClosedUploadDialog()),
        onBatchUploadInitiated: (blob: Blob, filename: string) => dispatch(initiateBatchFileUpload(blob, filename)),
        onProcessUploadClicked: (batchUploadId: number) => dispatch(processBatchUpload(batchUploadId)),
        onDeleteRouteClicked: (route: RouteData) => dispatch(deleteRoute(route)),
        onEditedRouteSaved: (route: RouteData) => dispatch(updateRoute(route)),
        onEditedRouteFinished: () => dispatch(ClearUserRouteUpdatedResponse()),
        onDeleteLocationClicked: (location: LocationData) => dispatch(deleteCity(location)),
        onEditedLocationSaved: (location: LocationData) => dispatch(updateCity(location)),
        onEditedLocationFinished: () => dispatch(ClearUserCityUpdatedResponse()),
        onCreatedLocationSaved: (location: LocationData) => dispatch(createCity(location, 'my_places')),
        onCreatedDriverNotificationSaved: (driverNotification: DriverNotification) => dispatch(createDriverNotification(driverNotification)),
        onDeleteDriverNotificationClicked: (driverNotification: DriverNotification) => dispatch(deleteDriverNotification(driverNotification)),
        onEditedDriverNotificationSaved: (driverNotification: DriverNotification) => dispatch(updateDriverNotification(driverNotification)),
        onEditedDriverNotificationFinished: () => dispatch(ClearUserCityUpdatedResponse()),
        setVehicleNotificationsEnabled: (vehicle: VehicleTrackingData, enabled: boolean) => dispatch(setVehicleNotificationsEnabled(vehicle, enabled))
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ClientPlacesPage));
