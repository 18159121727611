import * as React from 'react';
import { retryIfRateLimited } from '../../../actions/Ratings';
import { API_HOST } from '../../../constants';
import { LocationData } from '../../../types';
import Autocomplete from '@mui/material/Autocomplete';
import { TextField } from '@mui/material';
import throttle from 'lodash/throttle';
import { unmarshalLocation } from 'src/types/unmarshal';

/*
 * I have a tenuous grasp of this code at best, but it's well-encapsulated.
 * Provides a component that allows a user to type and receive place suggestions.
 */

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TAG = 'MaterialPlaceTextfield';

const loadPlaceSuggestions = (input: string, token: string) => {
    if (input === "") {
        return Promise.resolve([]);
    }

    let postData: RequestInit = {
        body: JSON.stringify({ query: input, token }),
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        }
    };
    return retryIfRateLimited(() => fetch(`${API_HOST}/geocoding/location_suggestions`, postData))
        .then((response: Response) => response.json().then((json) => { return { response, json }; }))
        .then(
            ({ response, json }) => {
                if (response.status === 200) {
                    let results = json.results;

                    let cities: LocationData[] = results.map((result: any) => {
                        let city: LocationData = unmarshalLocation(result, true);
                        return city;
                    });

                    return cities;
                } else {
                    console.warn('unsuccessful response status from loading places', json);
                    return [];
                }
            },
        ).catch((error) => {
            console.warn('error loading places', error);
            return [];
        });
};

const searchLocations = (input: string, token: string | undefined) => {
    if (token === undefined) {
        return Promise.resolve([]);
    }

    return retryIfRateLimited(() => fetch(`${API_HOST}/cities.json?token=${token}&limit=10&query=${input}`))
        .then(
            (response: Response) => response.json(),
            (error: Error) => console.log('Error fetching user cities in search.', error)
        ).then((json: JSON) => {
            let cities: LocationData[] = (json['cities'] as object[]).map(cityJSON => unmarshalLocation(cityJSON));
            return cities;
        });
};

// AutocompleteLocationTextField
export const MaterialPlaceTextfield = (props: {
    userId?: number;
    token: string; // place suggestions token
    placeholder?: string;
    label?: string;
    value?: LocationData;
    onChange: (location?: LocationData) => void;
    logTag?: string;
}) => {
    const value = props.value;
    const [inputValue, setInputValue] = React.useState("");
    const [options, setOptions] = React.useState<LocationData[]>([]);
    const [focused, setFocused] = React.useState(false);

    // console.log(props.logTag ?? TAG, 'value:', value, 'inputValue:', inputValue);

    const abortController = React.useRef(new AbortController()).current;

    const token = props.token;

    const fetchMemo = React.useMemo(
        () => {
            return throttle((request: { input: string }, callback: (locations: LocationData[]) => void) => {
                const loadSuggestedLocations = loadPlaceSuggestions(request.input, token);
                const loadSavedLocations = searchLocations(request.input, token);
                return Promise.all([loadSavedLocations, loadSuggestedLocations]).then(combined => {
                    const [savedLocations, suggestedLocations] = combined;
                    let output: LocationData[] = [];

                    output = output.concat(savedLocations);

                    try {
                        const [latStr, lonStr] = request.input.split(",");
                        const lat = parseFloat(latStr);
                        let lon = parseFloat(lonStr);
                        if (!isNaN(lon) && lon > 180) {
                            lon -= 360;
                        }
                        if (!isNaN(lat) && lat >= -90 && lat <= 90 && !isNaN(lon) && lon >= -180 && lon <= 180) {
                            output.push({
                                id: undefined,
                                name: request.input,
                                latitude: lat,
                                longitude: lon,
                                needsGeocoding: true,
                                impactSummaries: [],
                            });
                        }
                    } catch {
                        // do nothing
                    }

                    // console.log(props.logTag ?? TAG, 'suggestedLocations', suggestedLocations);
                    output = output.concat(suggestedLocations);
                    callback(output);
                });
            }, 200);
        },
        [token, abortController.signal],
    );

    React.useEffect(() => {
        if (value === undefined && !focused) {
            setInputValue('');
        }
    }, [value]);

    React.useEffect(() => {
        let active = true;

        if (inputValue === '') {
            // console.log(props.logTag ?? TAG, 'setting options to value:', value);
            setOptions(value ? [value] : []);
            return undefined;
        }

        // console.log(props.logTag ?? TAG, `inputValue: ${inputValue}`);
        fetchMemo({ input: inputValue }, (results: LocationData[]) => {
            if (active) {
                // console.log(props.logTag ?? TAG, 'setting options to', results);
                setOptions(results);
            }
        });

        return () => {
            active = false;
        };
    }, [value, inputValue, fetchMemo]);

    const onChangeMemo = React.useCallback(
        (value?: LocationData) => props.onChange(value),
        []
        // https://stackoverflow.com/questions/55840294/how-to-fix-missing-dependency-warning-when-using-useeffect-react-hook
    );

    React.useEffect(() => {
        onChangeMemo(value);
    }, [value, onChangeMemo]);

    const showError = value === undefined && inputValue.length > 0 && !focused;
    return (
        <Autocomplete
            sx={{
                width: '100%',
                "& button.MuiButtonBase-root": {
                    visibility: "visible"
                }
            }}
            getOptionLabel={(option: LocationData) => option.name}
            // https://mui.com/material-ui/react-autocomplete/#search-as-you-type
            filterOptions={(x) => x}
            options={options}
            // https://github.com/mui/material-ui/issues/26492#issuecomment-901089142
            renderOption={(props, option) => (
                <li {...props} key={`${option.latitude},${option.longitude}`}>
                    {option.name}
                </li>
            )}
            isOptionEqualToValue={(option, value) => option.name === value.name}
            groupBy={(option) => option.id ? 'SAVED LOCATIONS' : 'OTHER LOCATIONS'}
            noOptionsText={inputValue === '' ? 'Start typing' : 'No locations found'}
            autoComplete
            filterSelectedOptions
            autoHighlight
            // want to leave the input alone when the user switches tabs/applications
            clearOnBlur={false}
            value={value === undefined ? null : value}
            onChange={(event, newValue, reason) => {
                // console.log(props.logTag ?? TAG, 'onChange', newValue, reason);
                if (typeof newValue !== 'string') {
                    onChangeMemo(newValue ? newValue : undefined);
                }
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue, reason) => {
                // console.log(props.logTag ?? TAG, 'onInputChange', newInputValue, reason);
                setInputValue(newInputValue);
                // when the user starts typing, clear the selected value
                onChangeMemo(undefined);
            }}
            onFocus={(event) => setFocused(true)}
            onBlur={(event) => setFocused(false)}
            renderInput={(params) => (
                <TextField
                    {...params}
                    placeholder={props.placeholder}
                    label={props.label}
                    variant="standard"
                    fullWidth
                    error={showError}
                    helperText={showError ? 'Please select an option from the dropdown.' : undefined}
                    InputLabelProps={{ shrink: true }}
                />
            )}
        />
    );
};
