import { Action, ActionCreator } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { StoreState } from '../types';
import { action, props, union } from 'ts-action';

import { retryIfRateLimited } from './Ratings';
import { API_HOST } from 'src/constants';

export const ReceivePossibleGovernmentalAlertNames = action('RECEIVE_POSSIBLE_GOVERNMENTAL_ALERT_NAMES', props<{ alerts: string[] }>());

export const AlertsViewAction = union(ReceivePossibleGovernmentalAlertNames);


export const loadPossibleGovernmentalAlertNamesIfNeeded: ActionCreator<ThunkAction<Promise<void>, StoreState, void, Action<any>>> = (userToken: string | undefined) => {
    return (dispatch, getState) => {
        const state = getState();
        const token = userToken || state.user.token;
        if (token === undefined) {
            // not logged in yet, no need
            return Promise.resolve();
        }

        if (state.alertsView.possibleGovernmentalAlertNames !== undefined) {
            // governmental alerts already loaded
            return Promise.resolve();
        }

        return retryIfRateLimited(() => fetch(`${API_HOST}/governmental_alerts.json?token=${token}`))
            .then(
                (response: Response) => response.json(),
                (error: Error) => console.log('Error fetching governmental alerts in initial load.', error)
            ).then((json: JSON) => {
                let alerts: string[] = json['governmental_alerts'];

                dispatch(ReceivePossibleGovernmentalAlertNames({ alerts }));

                return Promise.resolve();
            });
    };
};