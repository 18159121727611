import { ImpactMapSection } from './sections';
import { MapType } from '../../../types/MapType';
import * as React from 'react';
import { cardBackgroundColor } from "../../../constants";
import { MenuItem, Select } from '@mui/material';

interface MapOptionsComponentProps {
    /// all map categories
    mapCategories: ImpactMapSection[];

    /// all maps in the current category, deduplicated over slugs
    maps: MapType[];

    collapsed: boolean;

    selectedMapCategory: ImpactMapSection;
    selectedMap: MapType;

    onMapCategorySelected: (category: ImpactMapSection) => void;
    onMapSelected: (map: MapType) => void;
    onMapOptionsExpanded: () => void;
    onMapOptionsCollapsed: () => void;
}

export const MapOptionsComponent = (props: MapOptionsComponentProps) => {
    // let mapCategoryOptions: Option<ImpactMapSection>[] = props.mapCategories.map(category => { return {value: category, label: category.title}; });
    // let selectedMapCategoryOption: Option<ImpactMapSection> = {label: props.selectedMapCategory.title, value: props.selectedMapCategory};
    //
    // let mapOptions: Option<MapType>[] = props.maps.map(map => { return {value: map, label: map.title}; });
    // let selectedMapOption: Option<MapType> = {label: props.selectedMap.title, value: props.selectedMap};

    let className = 'MapOptions';
    if (props.collapsed) {
        className += " collapsed";
    }

    return (
        <div className={className} style={{ backgroundColor: cardBackgroundColor }}>
            <div className={'map-options-row'}>
                <div className={'map map-option'}>
                    <div className={'header'}>Select Map Category</div>
                    <Select
                        value={props.selectedMapCategory.key}
                        variant={'outlined'}
                        style={{ width: 235, height: 40 }}
                        onChange={(event) => {
                            const mapCategory = props.mapCategories.find(x => x.key === event.target.value);
                            if (mapCategory) {
                                props.onMapCategorySelected(mapCategory);
                            }
                        }}
                    >
                        {props.mapCategories.map(category => (
                            <MenuItem value={category.key}>{category.title}</MenuItem>
                        ))}
                    </Select>
                </div>

                <div className={'map map-option'}>
                    <div className={'header'}>Select Map</div>
                    <Select
                        value={props.selectedMap.title}
                        variant={'outlined'}
                        style={{ width: 235, height: 40 }}
                        onChange={(event) => props.onMapSelected(props.maps.filter(map => event.target.value === map.title)[0])}
                    >
                        {props.maps.map(map => <MenuItem value={map.title}>{map.title}</MenuItem>)}
                    </Select>
                </div>
            </div>
        </div>
    );
};
