import LoginForm from '../components/LoginForm';
import { StoreState } from '../types';
import {connect} from 'react-redux';
import {Action} from 'redux';
import 'redux-thunk';
import {createLoginAction, createResetPasswordAction} from '../actions/User';
import {ThunkDispatch} from 'redux-thunk';

export function mapStateToProps(state: StoreState) {
    return {
        lastError: state.user.lastLoginError,
        token: state.user.token,
        isLoggingIn: state.user.isLoggingIn,

        isResettingPassword: state.user.submittingPasswordReset,
        passwordResetError: state.user.passwordResetError,
        passwordResetSuccess: state.user.passwordResetSuccess,
    };
}

export function mapDispatchToProps(dispatch: ThunkDispatch<StoreState, void, Action<any>>) {
    return {
        initiateLogin: (email: string, password: string) => dispatch(createLoginAction(email, password)),
        onResetRequested: (email: string) => dispatch(createResetPasswordAction(email))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
