import * as React from 'react';
import * as ReactSelectBase from 'react-select/base';
import Async, {AsyncProps} from 'react-select/async';
import {components, StylesConfig} from 'react-select';
import {cardBackgroundColor} from "../../constants";

type SelectAndAsyncProps<OptionType> = ReactSelectBase.Props<OptionType> & AsyncProps<OptionType>;
export interface PortalAsyncProps<OptionType> extends SelectAndAsyncProps<OptionType> {
    width: number | string;
    height: number | string;

    /// background color of the main container/input
    backgroundColor: string;

    /// background color of the dropdown menu
    menuBackgroundColor: string;

    borderRadius: number;

    /// padding of the input/placeholder field
    paddingLeft: number;
    paddingRight: number;
}

export const defaultAsyncStyleProps = () => ({
    width: 200,
    backgroundColor: cardBackgroundColor,
    menuBackgroundColor: cardBackgroundColor,
    borderRadius: 10,
    paddingLeft: 0,
    paddingRight: 0,
    height: 38
});

// hackaround because of lack of React.ElementConfig export in @types/react
let DropdownIndicatorComponent = components.DropdownIndicator as any;
type DropdownIndicatorType = typeof DropdownIndicatorComponent;

const SearchIndicator = (
    props: JSX.LibraryManagedAttributes<DropdownIndicatorType, DropdownIndicatorType["props"]>
) => {
    return (
        <components.DropdownIndicator {...props}>
            <img alt="" src={"/images/search_icon.svg"}/>
        </components.DropdownIndicator>
    );
};

export class AutocompleteDropdown<OptionType> extends React.Component {
    props: PortalAsyncProps<OptionType>;

    state: {
        menuIsOpen: boolean;
        input: string;
    };

    constructor(props: PortalAsyncProps<OptionType>) {
        super(props);

        this.state = {
            menuIsOpen: props.menuIsOpen ?? false,
            input: ""
        };
    }

    render() {
        let style: StylesConfig = {
            container: (provided) => ({
                ...provided,
                zIndex: this.state.menuIsOpen ? 1005 : 1004,
                width: this.props.width,
                height: this.props.height, // fixes dropdown resizing
            }),
            singleValue: (provided) => ({
                ...provided,
                fontWeight: 600,
                color: '#04a0fd',
                fontSize: '14px',
                paddingLeft: this.props.paddingLeft,
                paddingRight: this.props.paddingRight,
            }),
            input: (provided) => ({
                ...provided,
                color: 'rgb(235, 238, 239)',
                fontSize: '14px',
                paddingLeft: this.props.paddingLeft,
                paddingRight: this.props.paddingRight,
            }),
            noOptionsMessage: (provided) => ({
                ...provided,
                fontStyle: "italic",
            }),
            placeholder: (provided) => ({
                ...provided,
                paddingLeft: this.props.paddingLeft,
                paddingRight: this.props.paddingRight,
            }),
            control: (provided) => {
                return {
                    ...provided,
                    backgroundColor: this.props.backgroundColor,
                    borderRadius: this.state.menuIsOpen && this.state.input !== '' ? `${this.props.borderRadius}px ${this.props.borderRadius}px 0 0` : this.props.borderRadius,
                    borderStyle: 'none',
                    boxShadow: '0 0 0 0 transparent',
                    fontSize: '14px',
                    height: this.props.height,
                };
            },
            dropdownIndicator: (base) => ({...base, marginRight: 6 }),
            indicatorSeparator: () => ({ display: 'none' }),
            menu: (provided) => {
                return {
                    ...provided,
                    margin: 0,
                    backgroundColor: this.state.input === '' ? 'transparent' : this.props.menuBackgroundColor,
                    color: 'rgb(126, 135, 140)',
                    borderRadius: `0 0 ${this.props.borderRadius}px ${this.props.borderRadius}px`,
                    zIndex: 1005,
                    boxShadow: '0 0 0 0 transparent',
                    borderStyle: 'none',
                };
            },
            option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isSelected || state.isFocused ? '#3e464c' : 'transparent',
                '&:active': {
                    backgroundColor: '#3e464c',
                }
            }),
        };

        return (
            <Async
                styles={style}

                {...this.props}
                components={{DropdownIndicator: SearchIndicator}}

                onChange={(value, action) => {
                    this.setState({...this.state, input: ''});
                    this.props.onChange?.(value, action);
                }}

                inputValue={this.state.input}
                onInputChange={(newInput) => {
                    this.setState({...this.state, input: `${newInput}`});
                }}

                noOptionsMessage={({inputValue}) => inputValue === '' ? '' : 'No results found'}

                // never show a value in autocomplete dropdowns
                value={null}

                menuIsOpen={this.state.menuIsOpen}
                onMenuOpen={() => {
                    if (!this.state.menuIsOpen) {
                        this.setState({...this.state, menuIsOpen: true});
                    }
                }}
                onMenuClose={() => {
                    if (this.state.menuIsOpen) {
                        this.setState({...this.state, menuIsOpen: false});
                    }
                }}
            />
        );
    }
}
