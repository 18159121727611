import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, LinearProgress, TextField, TextFieldProps } from "@mui/material";
import React, { Fragment } from "react";
import { LocationData } from "src/types";

interface Props {
    locationToEdit: LocationData | undefined;
    editLocationResponse: LocationData | Error[] | undefined;
    creatingNewLocation: boolean;
    setCreatingNewLocation: (creating: boolean) => void;
    onCreatedLocationSaved: (location: LocationData) => void;
    setLocationToEdit: (location: LocationData | undefined) => void;
    onEditedLocationSaved: (location: LocationData) => void;
    onEditedLocationFinished: () => void;
}

export const EditLocationDialog = ({ locationToEdit, editLocationResponse, creatingNewLocation, setCreatingNewLocation, setLocationToEdit, onCreatedLocationSaved, onEditedLocationSaved, onEditedLocationFinished }: Props) => {
    const [isSavingEdit, setSavingEdit] = React.useState(false);
    const [editErrors, setEditErrors] = React.useState<string[]>([]);
    const [latitude, setLatitude] = React.useState('');
    const [longitude, setLongitude] = React.useState('');

    const prevLocationToEditRef = React.useRef<LocationData | undefined>();

    React.useEffect(() => {
        if (editLocationResponse === undefined) {
            return;
        }
        setSavingEdit(false);
        if (Array.isArray(editLocationResponse)) {
            setEditErrors(editLocationResponse.map(e => e.message));
        } else {
            onEditedLocationFinished();
            setLocationToEdit(undefined);
            setCreatingNewLocation(false);
        }
    }, [editLocationResponse]);

    React.useEffect(() => {
        if (locationToEdit?.id === prevLocationToEditRef.current?.id) {
            return;
        }
        // change NaN to empty string
        const locationToEditLatitude = (locationToEdit && isNaN(locationToEdit.latitude)) ? '' : locationToEdit?.latitude?.toString();
        const locationToEditLongitude = (locationToEdit && isNaN(locationToEdit.longitude)) ? '' : locationToEdit?.longitude?.toString();
        setLatitude(locationToEditLatitude || '');
        setLongitude(locationToEditLongitude || '');
    }, [locationToEdit]);

    React.useEffect(() => {
        prevLocationToEditRef.current = locationToEdit;
    });

    if (locationToEdit === undefined) {
        return <div></div>;
    }

    const handleCancel = () => {
        setSavingEdit(false);
        setEditErrors([]);
        setCreatingNewLocation(false);
        setLocationToEdit(undefined);
    };
    const handleSave = () => {
        const updatedLocation = {
            ...locationToEdit,
        };
        if (latitude !== undefined) {
            updatedLocation.latitude = parseFloat(latitude);
        }
        if (longitude !== undefined) {
            updatedLocation.longitude = parseFloat(longitude);
        }

        setLocationToEdit(updatedLocation);
        setSavingEdit(true);
        setEditErrors([]);
        if (creatingNewLocation) {
            onCreatedLocationSaved(updatedLocation);
        } else {
            onEditedLocationSaved(updatedLocation);
        }
    };

    const sharedTextFieldProps: Partial<TextFieldProps> = {
        // autoFocus: true,
        margin: "dense",
        fullWidth: true,
        variant: "standard",
    };

    return (
        <Dialog open={true} onClose={handleCancel}>
            <DialogTitle>{creatingNewLocation ? 'Create New' : 'Edit'} Location</DialogTitle>
            <DialogContent>
                {editErrors.length > 0 && <DialogContentText>
                    There was an error saving your edits:
                    <br />
                    {editErrors.map(err => (
                        <Fragment>
                            <br />
                            • {err}
                        </Fragment>
                    ))}
                </DialogContentText>}
                <TextField
                    {...sharedTextFieldProps}
                    id="name"
                    label="Name"
                    type="text"
                    autoFocus
                    value={locationToEdit.name}
                    onChange={(event) => {
                        const updatedLocation = {
                            ...locationToEdit,
                        };
                        updatedLocation.name = event.target.value as string;
                        setLocationToEdit(updatedLocation);
                    }}
                />
                <TextField
                    {...sharedTextFieldProps}
                    id="latitude"
                    label="Latitude"
                    type="number"
                    value={latitude ?? ""}
                    onChange={(event) => {
                        setLatitude(event.target.value as string);
                    }}
                />
                <TextField
                    {...sharedTextFieldProps}
                    id="longitude"
                    label="Longitude"
                    type="number"
                    value={longitude ?? ""}
                    onChange={(event) => {
                        setLongitude(event.target.value as string);
                    }}
                />
            </DialogContent>
            {
                !isSavingEdit && <DialogActions>
                    <Button onClick={handleCancel}>Cancel</Button>
                    <Button onClick={handleSave}>Save</Button>
                </DialogActions>
            }
            {isSavingEdit && <LinearProgress />}
        </Dialog >
    );
};
