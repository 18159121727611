import { LoadRoadConditionsFail, LoadRoadConditionsStart, ReceiveRoadConditionsData, TrafficSetSelectedRoadStatus, TrafficViewAction, TrafficSetSelectedWeatherStation, TrafficSetSelectedTrafficIncident, TrafficSetSelectedTruckWarning, TrafficSetSelectedTrafficCongestion, TrafficSetSelectedSpecialEvent, TrafficSetSelectedRoadClosure, TrafficSetSelectedRoadWork, TrafficClearSelectedRoadCondition, TrafficSetTrafficLayer } from 'src/actions/TrafficView';
import { RoadClosureData, RoadStatusData, RoadWorkData, SpecialEventData, TrafficCongestionData, TrafficIncidentData, TrafficViewState, TruckWarningData, WeatherStationData } from '../types';
import { ROAD_CONDITIONS_CACHE_INTERVAL } from 'src/constants';
import stringifyDeterministic from 'json-stringify-deterministic';
import { cyrb53 } from './Weather';

const noSelectedRoadCondition = {
    selectedRoadStatus: undefined,
    selectedRoadWork: undefined,
    selectedRoadClosure: undefined,
    selectedSpecialEvent: undefined,
    selectedTrafficCongestion: undefined,
    selectedTrafficIncident: undefined,
    selectedTruckWarning: undefined,
    selectedWeatherStation: undefined,
};

const noVisibleRoadConditions = {
    isRoadStatusVisible: false,
    isRoadWorkVisible: false,
    isRoadClosuresVisible: false,
    isSpecialEventsVisible: false,
    isTrafficCongestionVisible: false,
    isTrafficIncidentsVisible: false,
    isTruckWarningsVisible: false,
    isWeatherStationsVisible: false,
};

export function trafficView(state: TrafficViewState, action: typeof TrafficViewAction.actions): TrafficViewState {
    if (typeof state === 'undefined') {
        return {
            isFetching: false,
            roadConditionsExpiryTime: undefined,
            isRoadStatusVisible: false,
            isRoadWorkVisible: false,
            isRoadClosuresVisible: false,
            isSpecialEventsVisible: false,
            isTrafficCongestionVisible: false,
            isTrafficIncidentsVisible: false,
            isTruckWarningsVisible: false,
            isWeatherStationsVisible: false,
            selectedRoadStatus: undefined,
            selectedRoadWork: undefined,
            selectedRoadClosure: undefined,
            selectedSpecialEvent: undefined,
            selectedTrafficCongestion: undefined,
            selectedTrafficIncident: undefined,
            selectedTruckWarning: undefined,
            selectedWeatherStation: undefined,
        };
    }

    switch (action.type) {
        case TrafficSetSelectedRoadStatus.type:
            return { ...state, ...noSelectedRoadCondition, selectedRoadStatus: action.roadStatus };
        case TrafficSetSelectedRoadWork.type:
            return { ...state, ...noSelectedRoadCondition, selectedRoadWork: action.roadWork };
        case TrafficSetSelectedRoadClosure.type:
            return { ...state, ...noSelectedRoadCondition, selectedRoadClosure: action.roadClosure };
        case TrafficSetSelectedSpecialEvent.type:
            return { ...state, ...noSelectedRoadCondition, selectedSpecialEvent: action.specialEvent };
        case TrafficSetSelectedTrafficCongestion.type:
            return { ...state, ...noSelectedRoadCondition, selectedTrafficCongestion: action.trafficCongestion };
        case TrafficSetSelectedTrafficIncident.type:
            return { ...state, ...noSelectedRoadCondition, selectedTrafficIncident: action.trafficIncident };
        case TrafficSetSelectedTruckWarning.type:
            return { ...state, ...noSelectedRoadCondition, selectedTruckWarning: action.truckWarning };
        case TrafficSetSelectedWeatherStation.type:
            return { ...state, ...noSelectedRoadCondition, selectedWeatherStation: action.weatherStation };
        case TrafficClearSelectedRoadCondition.type:
            return { ...state, ...noSelectedRoadCondition };
        case TrafficSetTrafficLayer.type:
            let visibleRoadConditions = { ...noVisibleRoadConditions };
            if (action.layer === 'traffic') {
                visibleRoadConditions.isTrafficCongestionVisible = true;
                visibleRoadConditions.isSpecialEventsVisible = true;
            } else if (action.layer === 'traffic-incidents') {
                visibleRoadConditions.isTrafficIncidentsVisible = true;
            } else if (action.layer === 'traffic-road-status') {
                visibleRoadConditions.isRoadStatusVisible = true;
                visibleRoadConditions.isWeatherStationsVisible = true;
            } else if (action.layer === 'traffic-road-work') {
                visibleRoadConditions.isRoadWorkVisible = true;
            } else if (action.layer === 'traffic-road-closures') {
                visibleRoadConditions.isRoadClosuresVisible = true;
            } else if (action.layer === 'traffic-truck-warnings') {
                visibleRoadConditions.isTruckWarningsVisible = true;
            }
            return { ...state, ...visibleRoadConditions };
        case ReceiveRoadConditionsData.type:
            let roadConditionsJson = action.roadConditionsData['features'];
            let roadStatus: RoadStatusData[] = [];
            let roadWork: RoadWorkData[] = [];
            let roadClosures: RoadClosureData[] = [];
            let specialEvents: SpecialEventData[] = [];
            let trafficCongestion: TrafficCongestionData[] = [];
            let trafficIncidents: TrafficIncidentData[] = [];
            let truckWarnings: TruckWarningData[] = [];
            let weatherStations: WeatherStationData[] = [];

            (roadConditionsJson as [object]).map((roadConditionJSON, i) => {
                const properties = roadConditionJSON['properties'];
                const roadConditionType = properties['wo_road_condition_type'];
                if (roadConditionType === 'road_status') {
                    const rs = {
                        id: String(cyrb53(stringifyDeterministic(roadConditionJSON))),
                        type: roadConditionType,
                        geoJson: roadConditionJSON as unknown as GeoJSON.Feature,

                        roadway: properties['route'] as string,
                        description: properties['description'] as string,
                        status: properties['road_condition'] as string,
                        color: properties['color'] as string,
                        start: properties['start_ldist'] as string,
                        end: properties['end_ldist'] as string,
                        strokeColor: properties['stroke'] as string,
                    };
                    if (!rs.geoJson.geometry || !rs.description || rs.description?.trim().length === 0) {
                        return;
                    }
                    roadStatus.push(rs);
                } else if (roadConditionType === 'road_work') {
                    const rw = {
                        id: String(cyrb53(stringifyDeterministic(roadConditionJSON))),
                        type: roadConditionType,
                        geoJson: roadConditionJSON as unknown as GeoJSON.Feature,

                        roadway: properties['roadway'] as string,
                        title: properties['title'] as string,
                        description: properties['description'] as string,
                        severity: properties['severity'] as string,
                        affectedLanes: properties['affected_lanes'] as string,
                        startTime: properties['start_time'] ? new Date(properties['start_time'] as string) : undefined,
                        endTime: properties['end_time'] ? new Date(properties['end_time'] as string) : undefined,
                    };
                    if (!rw.geoJson.geometry) {
                        return;
                    }
                    roadWork.push(rw);
                } else if (roadConditionType === 'road_closure') {
                    const rc = {
                        id: String(cyrb53(stringifyDeterministic(roadConditionJSON))),
                        type: roadConditionType,
                        geoJson: roadConditionJSON as unknown as GeoJSON.Feature,

                        roadway: properties['roadway'] as string,
                        description: properties['description'] as string,
                        reason: properties['reason'] as string,
                        affectedLanes: properties['affected_lanes'] as string,
                        startTime: properties['start_time'] ? new Date(properties['start_time'] as string) : undefined,
                        endTime: properties['end_time'] ? new Date(properties['end_time'] as string) : undefined,
                    };
                    if (!rc.geoJson.geometry) {
                        return;
                    }
                    roadClosures.push(rc);
                } else if (roadConditionType === 'special_event') {
                    const se = {
                        id: String(cyrb53(stringifyDeterministic(roadConditionJSON))),
                        type: roadConditionType,
                        geoJson: roadConditionJSON as unknown as GeoJSON.Feature,

                        title: properties['title'] as string,
                        description: properties['description'] as string,
                        specialEventType: properties['type'] as string,
                        location: properties['location'] as string,
                        startTime: properties['start_time'] ? new Date(properties['start_time'] as string) : undefined,
                        endTime: properties['end_time'] ? new Date(properties['end_time'] as string) : undefined,
                    };
                    if (!se.geoJson.geometry) {
                        return;
                    }
                    specialEvents.push(se);
                } else if (roadConditionType === 'traffic_congestion') {
                    const tc = {
                        id: String(cyrb53(stringifyDeterministic(roadConditionJSON))),
                        type: roadConditionType,
                        geoJson: roadConditionJSON as unknown as GeoJSON.Feature,

                        roadway: properties['roadway'] as string,
                        trafficCongestionType: properties['type'] as string,
                        description: properties['description'] as string,
                        reason: properties['reason'] as string,
                        startTime: properties['start_time'] ? new Date(properties['start_time'] as string) : properties['last_updated'] ? new Date(properties['last_updated'] as string) : undefined,
                        endTime: properties['end_time'] ? new Date(properties['end_time'] as string) : undefined,
                    };
                    if (!tc.geoJson.geometry) {
                        return;
                    }
                    trafficCongestion.push(tc);
                } else if (roadConditionType === 'traffic_incident') {
                    const ti = {
                        id: String(cyrb53(stringifyDeterministic(roadConditionJSON))),
                        type: roadConditionType,
                        geoJson: roadConditionJSON as unknown as GeoJSON.Feature,

                        roadway: properties['roadway'] as string,
                        description: properties['description'] as string,
                        trafficIncidentType: properties['type'] as string,
                        affectedLanes: properties['affected_lanes'] as string,
                        startTime: properties['start_time'] ? new Date(properties['start_time'] as string) : properties['last_updated'] ? new Date(properties['last_updated'] as string) : undefined,
                        endTime: properties['end_time'] ? new Date(properties['end_time'] as string) : undefined,
                    };
                    if (!ti.geoJson.geometry) {
                        return;
                    }
                    trafficIncidents.push(ti);
                } else if (roadConditionType === 'trucker_warning') {
                    const tw = {
                        id: String(cyrb53(stringifyDeterministic(roadConditionJSON))),
                        type: roadConditionType,
                        geoJson: roadConditionJSON as unknown as GeoJSON.Feature,

                        title: properties['title'] as string,
                        description: properties['description'] as string,
                        startTime: properties['start_time'] ? new Date(properties['start_time'] as string) : undefined,
                        endTime: properties['end_time'] ? new Date(properties['end_time'] as string) : undefined,
                    };
                    if (!tw.geoJson.geometry) {
                        return;
                    }
                    truckWarnings.push(tw);
                } else if (roadConditionType === 'weather_station') {
                    const ws = {
                        id: String(cyrb53(stringifyDeterministic(roadConditionJSON))),
                        type: roadConditionType,
                        geoJson: roadConditionJSON as unknown as GeoJSON.Feature,

                        roadway: properties['roadway'] as string ?? properties['id'] as string,
                        description: properties['description'] as string,
                        status: properties['status'] as string,
                        temperature: properties['temperature'] as number,
                        precipitation: properties['precipitation'] as string,
                        windSpeed: properties['wind_speed'] as number,
                        windSpeedDirection: properties['wind_speed_direction'] as string,
                        windGust: properties['wind_gust'] as number,
                        windGustDirection: properties['wind_gust_direction'] as string,
                    };
                    // only including weather stations with location and precip values to include with road status (for now?)
                    if (!ws.geoJson.geometry || !ws.precipitation) {
                        return;
                    }
                    weatherStations.push(ws);
                }
            });

            return {
                ...state,
                isFetching: false,
                roadStatus: roadStatus,
                roadWork: roadWork,
                roadClosures: roadClosures,
                specialEvents: specialEvents,
                trafficCongestion: trafficCongestion,
                trafficIncidents: trafficIncidents,
                truckWarnings: truckWarnings,
                weatherStations: weatherStations,
                roadConditionsExpiryTime: Date.now() + ROAD_CONDITIONS_CACHE_INTERVAL,
            };
        case LoadRoadConditionsFail.type:
            return { ...state, isFetching: false };
        case LoadRoadConditionsStart.type:
            return { ...state, isFetching: true };
        default: return state;
    }
}
