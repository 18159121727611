import ResetPasswordForm from '../components/ResetPasswordForm';
import { StoreState } from '../types';
import {connect} from 'react-redux';
import {Action} from 'redux';
import 'redux-thunk';
import {ClearPasswordResetState, createResetPasswordAction} from '../actions/User';
import {ThunkDispatch} from 'redux-thunk';

export function mapStateToProps(state: StoreState) {
    return {
        isResettingPassword: state.user.submittingPasswordReset,
        lastError: state.user.passwordResetError,
        passwordResetSuccess: state.user.passwordResetSuccess,
    };
}

export function mapDispatchToProps(dispatch: ThunkDispatch<StoreState, void, Action<any>>) {
    return {
        onResetRequested: (email: string) => dispatch(createResetPasswordAction(email)),
        onResetCompleted: () => dispatch(ClearPasswordResetState())
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordForm);
