import {RouteData} from "../../../types/routes";
import * as React from "react";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import TablePagination from "@mui/material/TablePagination";

interface Props {
    routes: RouteData[];

    runButton: JSX.Element;

    selectedRoute?: RouteData;
    setSelectedRoute: (route?: RouteData) => void;
}

export function descendingComparator<T>(a: T, b: T, orderBy: string) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

export function getComparator<T>(order: 'asc' | 'desc', orderBy: string) {
    return order === 'desc'
        ? (a: T, b: T) => descendingComparator(a, b, orderBy)
        : (a: T, b: T) => -descendingComparator(a, b, orderBy);
}

export function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis: [T, number][] = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

export const SavedRoutesTable = ({routes, runButton, selectedRoute, setSelectedRoute}: Props) => {
    const [page, setPage] = React.useState(0);

    const handleClick = (event: React.MouseEvent, id: number) => {
        if (selectedRoute?.id === id) {
            setSelectedRoute(undefined);
        } else {
            setSelectedRoute(routes.find(route => route.id === id));
        }
    };

    const now = new Date();
    const routesInPast = routes.filter(route => route.departureTime <= now);
    const routesInFuture = routes.filter(route => route.departureTime > now);

    const orderedRoutes = stableSort(routesInFuture, getComparator('asc', 'departureTime')).concat(stableSort(routesInPast, getComparator('asc', 'departureTime')));
    const rowsPerPage = 5;

    return (
        <TableContainer>
            <Table size='small' aria-label="dense saved routes table">
                <TableHead>
                    <TableRow>
                        <TableCell>Origin</TableCell>
                        <TableCell>Destination</TableCell>
                        <TableCell>Departure Time</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {orderedRoutes.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(route => {
                        const isItemSelected = selectedRoute?.id === route.id;
                        return (
                            <TableRow
                                key={`route-${route.id}`}
                                hover
                                onClick={(event: React.MouseEvent) => handleClick(event, route.id!)}
                                selected={isItemSelected}
                                style={{cursor: 'pointer'}}
                            >
                                <TableCell component="th" scope="row">{route.origin.label}</TableCell>
                                <TableCell component="th" scope="row">{route.destination.label}</TableCell>
                                <TableCell align="center">{route.departureTime.toLocaleString()}</TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
            <div style={{height: 8}}/>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <div style={{width: 5}}/>
                {runButton}
                <div style={{flex: '1 1 0'}}/>
                <TablePagination
                    component={'div'}
                    rowsPerPageOptions={[5]}
                    count={orderedRoutes.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    style={{borderBottomStyle: 'none'}}
                    onPageChange={(event, newPage) => setPage(newPage)}
                />
            </div>
        </TableContainer>
    );
};
