import { Help } from "@mui/icons-material";
import { Button, Checkbox, FormControlLabel, FormGroup, MenuItem, Paper, Select, Slider, Tooltip } from "@mui/material";
import * as React from "react";
import { HistoricalStormEvent, HistoricalStormEventImpact } from ".";
import { cardBackgroundColor } from "../../../constants";

interface HistoricalEventSelectionProps {
    historicalStormEvents: HistoricalStormEvent[];
    selectedStormEvent: HistoricalStormEvent | undefined;
    selectedStormImpact: HistoricalStormEventImpact;
    selectedForecastDaysOut: number;
    showGroundTruthData: boolean;
    showForecastedStormsData: boolean;

    onStormEventSelected: (event: HistoricalStormEvent | undefined) => void;
    onStormImpactSelected: (impact: HistoricalStormEventImpact) => void;
    onForecastDaysOutSelected: (days: number) => void;
    onShowGroundTruthSelected: (show: boolean) => void;
    onShowForecastedStormsSelected: (show: boolean) => void;
    onResetView: () => void;
}


export const HistoricalEventSelectionComponent = (props: HistoricalEventSelectionProps) => {
    const { historicalStormEvents,
        selectedStormEvent,
        selectedStormImpact,
        selectedForecastDaysOut,
        showGroundTruthData,
        showForecastedStormsData,
        onStormEventSelected,
        onStormImpactSelected,
        onForecastDaysOutSelected,
        onShowGroundTruthSelected,
        onShowForecastedStormsSelected,
        onResetView } = props;

    const totalWoStormsForEvent = selectedStormEvent?.events?.map(x => x.length)?.reduce((a, b) => a + b, 0) ?? 0;
    return (
        <div className={"EventSelectionComponent"}>
            <Paper elevation={3} style={{ backgroundColor: cardBackgroundColor, backgroundImage: 'none', borderRadius: 11, padding: 20 }}>
                <header className={"event-selection-header"}>
                    <div className={"event-selection-title"}>
                        Historical Accuracy
                    </div>
                    <Tooltip title={"Analyze our platform's accuracy by selecting a historical event."}>
                        <Help style={{ color: 'grey' }} />
                    </Tooltip>
                </header>

                <div className={'select-title'}>Event</div>
                <Select
                    value={selectedStormEvent?.name}
                    variant={'outlined'}
                    style={{ width: 315, height: 40 }}
                    onChange={(event) => onStormEventSelected(historicalStormEvents?.filter(historicalEvent => historicalEvent.name === event.target.value)[0])}
                >
                    {historicalStormEvents?.map((historicalEvent: HistoricalStormEvent) => <MenuItem key={historicalEvent.name} value={historicalEvent.name}>{historicalEvent.name}</MenuItem>)}
                </Select>

                <div className={'select-title'}>Impact Index</div>
                <Select
                    value={selectedStormImpact?.name}
                    variant={'outlined'}
                    style={{ width: 315, height: 40 }}
                    onChange={(event) => selectedStormEvent && onStormImpactSelected(selectedStormEvent.impacts.filter(impact => impact.name === event.target.value)[0])}
                >
                    {selectedStormEvent?.impacts.map((impact: HistoricalStormEventImpact) => <MenuItem key={impact.name} value={impact.name}>{impact.name}</MenuItem>)}
                </Select>

                <div style={{ marginTop: 10 }}>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox checked={showGroundTruthData} onChange={event => onShowGroundTruthSelected(event.target.checked)} />} label="View ground truth data" />
                    </FormGroup>
                    {totalWoStormsForEvent > 0 && <FormGroup>
                        <FormControlLabel control={<Checkbox checked={showForecastedStormsData} onChange={event => onShowForecastedStormsSelected(event.target.checked)} />} label="View forecasted storms" />
                    </FormGroup>}
                </div>

                <div style={{ marginTop: 10 }}>
                    <div className={"date-of-forecast"}>
                        <div className={"day-selection-title"}>
                            Date of forecast
                        </div>
                        <Tooltip title={"View forecasts from days leading up to the event. The map always shows forecasted impact during the peak of the storm."}>
                            <Help style={{ color: 'grey' }} />
                        </Tooltip>
                    </div>
                    {/* Sliders don't have a easy way to go max -> min so using -5 to 0 instead and adjusting input, labels and onChange accordingly */}
                    <Slider
                        dir="rtl"
                        value={selectedForecastDaysOut * -1}
                        valueLabelDisplay="auto"
                        size="small"
                        step={1}
                        marks
                        min={-5}
                        max={0}
                        style={{ marginTop: 5 }}
                        valueLabelFormat={value => Math.abs(value)}
                        onChange={(event: Event, newValue: number | number[]) => { onForecastDaysOutSelected(Math.abs(newValue as number)); }}
                    />
                    <div className="day-range-labels">
                        <span>5 days out</span>
                        <span>0 days out</span>
                    </div>
                </div>


                <Button
                    variant={'contained'}
                    color={'primary'}
                    href={`javascript:;`}
                    onClick={() => onResetView()}
                    style={{ height: 40, marginTop: 10 }}
                >
                    RESET VIEW
                </Button>
            </Paper >
        </div >
    );
};
