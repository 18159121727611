import * as React from 'react';
import './index.css';
import { isTilingPermission, MyAccountState, PermissionName, UserState } from '../../../types';
import {
    Button,
    CircularProgress,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import { API_HOST, INFO_EMAIL } from '../../../constants';
import PortalTour from '../PortalTour';
import { WhatsNewDialog } from '../ClientLayout';
import { Config } from 'src/components/shared/useConfig';
import { Logout } from '@mui/icons-material';

export interface Props {
    myAccount?: MyAccountState;
    user?: UserState;

    activateAccount: () => void;
    refreshAccount: () => void;
    logOutLocally: () => void;
}

const ApiAccessRow = (props: { title: string; value: string; emphasize?: boolean }) => {
    return (
        <div style={{ lineHeight: 2 }}>
            <Typography style={{ display: 'inline', fontWeight: props.emphasize === true ? 'bold' : 'normal' }} color={'textSecondary'} variant={'body1'}>
                {props.title}:
            </Typography>
            &nbsp;&nbsp;&nbsp;
            <Typography style={{ display: 'inline' }} color={'textPrimary'} variant={'body1'}>{props.value}</Typography>
        </div>
    );
};

const serviceNameForPermission = (permission: PermissionName) => {
    switch (permission) {
        case PermissionName.IMPACT: return "Impact";
        case PermissionName.HISTORICAL_IMPACT: return "Historical Impact";
        case PermissionName.STANDARD_WEATHER: return "Weather";
        case PermissionName.HISTORICAL_STANDARD_WEATHER: return "Historical Weather";
        case PermissionName.IMPACT_TILES: return "Impact Maps";
        case PermissionName.WEATHER_TILES: return "Weather Maps";
        case PermissionName.WILDFIRE_TILES: return "Wildfire Maps";
        case PermissionName.RIGHTROUTE_FORECAST_STANDARD:
        case PermissionName.RIGHTROUTE_HISTORICAL_STANDARD:
            return "RightRoute (Standard)";
        case PermissionName.RIGHTROUTE_FORECAST_CUSTOM:
        case PermissionName.RIGHTROUTE_HISTORICAL_CUSTOM:
            return "RightRoute (Custom)";
        case PermissionName.RIGHTROUTE_FORECAST_RANGE:
            return "RightRoute (Range)";
        case PermissionName.EVENTS: return "Events";
        case PermissionName.WEATHER_ALERTS: return "Weather Alerts";
        default: throw new Error(`please add a label for ${permission}!`);
    }
};

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

export const formattedAsPrice = (price: number) => {
    return formatter.format(price);
};

const timeframeForPermission = (permission: PermissionName) => {
    switch (permission) {
        case PermissionName.IMPACT:
        case PermissionName.STANDARD_WEATHER:
        case PermissionName.RIGHTROUTE_FORECAST_STANDARD:
        case PermissionName.RIGHTROUTE_FORECAST_CUSTOM:
        case PermissionName.RIGHTROUTE_FORECAST_RANGE:
        case PermissionName.EVENTS:
        case PermissionName.WEATHER_ALERTS:
            return "Forecast";
        case PermissionName.HISTORICAL_IMPACT:
        case PermissionName.HISTORICAL_STANDARD_WEATHER:
        case PermissionName.RIGHTROUTE_HISTORICAL_STANDARD:
        case PermissionName.RIGHTROUTE_HISTORICAL_CUSTOM:
            return "Historical";
        case PermissionName.WEATHER_TILES:
        case PermissionName.IMPACT_TILES:
        case PermissionName.WILDFIRE_TILES:
            return "Live";
        default: throw new Error(`please add a timeframe label for ${permission}!`);
    }
};

export const ClientAccountPage = (props: Props) => {
    React.useEffect(() => {
        props.refreshAccount();
    }, []);

    const [loading, setLoading] = React.useState(false);
    const [intervalId, setIntervalId] = React.useState<NodeJS.Timeout | null>(null);
    const [runTour, setRunTour] = React.useState(false);
    const [showWhatsNewDialog, setShowWhatsNewDialog] = React.useState(false);
    const [portalTourComplete, setPortalTourComplete] = Config.useBooleanConfig(Config.Key.PortalTourComplete);

    React.useEffect(() => {
        if (props.myAccount?.isOnATrial === false || props.user?.lastAccountError !== undefined) {
            if (intervalId) {
                clearInterval(intervalId);
                setIntervalId(null);
            }
            setLoading(false);
        }
    }, [props.myAccount?.isOnATrial, props.user?.lastAccountError]);

    React.useEffect(() => {
        setRunTour(portalTourComplete === false);
    }, []);

    if (props.user === undefined) {
        return <div />;
    }

    const permissionsOfInterest = [
        PermissionName.RIGHTROUTE_FORECAST_STANDARD,
        PermissionName.RIGHTROUTE_HISTORICAL_STANDARD,
        PermissionName.RIGHTROUTE_FORECAST_CUSTOM,
        PermissionName.RIGHTROUTE_HISTORICAL_CUSTOM,
        PermissionName.RIGHTROUTE_FORECAST_RANGE,
        PermissionName.IMPACT,
        PermissionName.HISTORICAL_IMPACT,
        PermissionName.STANDARD_WEATHER,
        PermissionName.HISTORICAL_STANDARD_WEATHER,
        PermissionName.EVENTS,
        PermissionName.WEATHER_ALERTS,
        PermissionName.IMPACT_TILES,
        PermissionName.WEATHER_TILES,
        PermissionName.WILDFIRE_TILES,
    ];

    let areAllTilingPermissions = true;
    const apiAccess = props.myAccount?.apiAccess;
    const apiAccessData = apiAccess && permissionsOfInterest.flatMap(permission => {
        const data = apiAccess[permission];
        if (data !== undefined) {
            // user has this permission of interest
            areAllTilingPermissions = areAllTilingPermissions && isTilingPermission(permission);

            return [{
                label: permission,
                data: apiAccess[permission]
            }];
        } else {
            return [];
        }
    });

    let totalCostIncurredText = '';
    if (apiAccess !== undefined) {
        let minCostIncurred = areAllTilingPermissions ? 250 : 1000;

        if (apiAccess.totalCost < minCostIncurred) {
            totalCostIncurredText = formattedAsPrice(minCostIncurred) + " (minimum charge)";
        } else {
            totalCostIncurredText = formattedAsPrice(apiAccess.totalCost);
        }
    }

    return (
        <div className={'ClientAccountPage'}>
            <Typography color={'textPrimary'} variant={'h3'}>My Account</Typography>
            <Typography style={{ marginBottom: 26 }} color={'textSecondary'}
                variant={'h5'}>{props.user.clientName}</Typography>

            {props.user.lastAccountError && <Typography
                style={{ marginBottom: 26 }}
                color={'textSecondary'}
                variant={'body1'}>{`Latest account error (share this with support): ${props.user.lastAccountError}`}
            </Typography>}

            <Typography style={{ marginBottom: 11 }} color={'textPrimary'} variant={'h6'}>
                Resources
            </Typography>
            <div style={{ display: "flex", flexDirection: "row", marginBottom: 10 }}>
                <Button
                    variant={'contained'}
                    color={'primary'}
                    style={{ backgroundColor: '#90CAF9', color: '#000000', marginRight: 20 }}
                    onClick={() => {
                        (document.querySelector(".DashboardNavLink") as HTMLElement)?.click();
                        setTimeout(() => {
                            setPortalTourComplete(false);
                        }, 500);
                    }}
                >
                    PORTAL TOUR
                </Button>

                <a rel={"noopener noreferrer"} target={"_blank"} href={"https://weatheroptics.super.site"}>
                    <Button
                        variant={'contained'}
                        color={'primary'}
                        style={{ backgroundColor: '#90CAF9', color: '#000000', marginRight: 20 }}
                    >
                        USER MANUAL
                    </Button>
                </a>

                <Button
                    variant={'contained'}
                    color={'primary'}
                    style={{ backgroundColor: '#90CAF9', color: '#000000', marginRight: 20 }}
                    onClick={() => {
                        setShowWhatsNewDialog(true);
                    }}
                >
                    CHANGELOG
                </Button>
            </div>
            <br />

            <Typography style={{ marginBottom: 11 }} color={'textPrimary'} variant={'h6'}>
                More Options
            </Typography>

            <div style={{ display: "flex", flexDirection: "row", marginBottom: 10 }}>
                <a rel={"noopener noreferrer"} target={"_blank"} href={"https://status.weatheroptics.co"}>
                    <Button
                        variant={'contained'}
                        color={'primary'}
                        style={{ backgroundColor: '#90CAF9', color: '#000000', marginRight: 20 }}
                    >
                        API Status
                    </Button>
                </a>

                <Button
                    variant={'contained'}
                    color={'primary'}
                    href={`mailto:${INFO_EMAIL}`}
                    style={{ marginRight: 20 }}
                >
                    CONTACT US
                </Button>
                <Button
                    variant={'contained'}
                    color={'error'}
                    href={`/logout`}
                >
                    LOG OUT
                </Button>
            </div>

            <Typography style={{ marginBottom: 29 }} color={'textSecondary'} variant={'body2'}>
                Please contact us using the button above if you want to add more logins to your team.
            </Typography>

            {props.myAccount?.isStripeCustomer && (
                <div>
                    <Typography style={{ marginBottom: 11 }} color={'textPrimary'} variant={'h6'}>
                        Account Settings
                    </Typography>
                    {props.user.lastAccountError !== undefined &&
                        <Typography style={{ marginBottom: 11 }} color={'textPrimary'} variant={'h6'}>
                            {props.user.lastAccountError}
                        </Typography>
                    }
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <form action={`${API_HOST}/stripe/create_customer_portal_session?token=${props.user.token}`} method="POST">
                            <Button
                                type={'submit'}
                                variant={'contained'}
                                color={'primary'}
                                style={{ backgroundColor: '#90CAF9', color: '#000000', marginRight: 20, marginBottom: 29 }}
                            >
                                Manage Subscription
                            </Button>
                        </form>
                        {props.myAccount?.isOnATrial && <div>
                            {loading ? <CircularProgress /> : (<Button
                                variant={'contained'}
                                color={'primary'}
                                style={{ backgroundColor: '#90CAF9', color: '#000000', marginBottom: 29 }}
                                onClick={() => {
                                    props.activateAccount();
                                    setLoading(true);
                                    setIntervalId(setInterval(() => props.refreshAccount(), 3000));
                                }}
                            >
                                Activate Full Version
                            </Button>)}
                        </div>}
                    </div >
                </div >
            )}

            {
                props.myAccount && (
                    <div>
                        <Typography style={{ marginBottom: 11 }} color={'textPrimary'} variant={'h6'}>
                            Portal Access
                        </Typography>
                        <ApiAccessRow
                            title={'Max Routes'}
                            value={props.myAccount.maxRoutes.toString()}
                        />
                        <ApiAccessRow
                            title={'Max Locations'}
                            value={props.myAccount.maxLocations.toString()}
                        />
                        <br />
                    </div>
                )
            }

            {
                props.myAccount?.apiAccess && (
                    <div>
                        <Typography style={{ marginBottom: 11 }} color={'textPrimary'} variant={'h6'}>
                            API Access
                        </Typography>

                        <ApiAccessRow title={'Token'}
                            value={props.myAccount.apiAccess?.token} />

                        <ApiAccessRow title={'Current Usage Period'} value={
                            new Date(props.myAccount.apiAccess.cycleStartDate).toLocaleDateString() +
                            ' - ' +
                            new Date(props.myAccount.apiAccess.cycleEndDate).toLocaleDateString()
                        } />

                        {apiAccess && <ApiAccessRow
                            title={'Total Cost Incurred'}
                            value={totalCostIncurredText}
                        />}

                        <br />

                        <div style={{ width: '100%', overflowX: 'auto', marginBottom: 40 }}>
                            <TableContainer style={{ width: 1000 }} component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Service</TableCell>
                                            <TableCell>Timeframe</TableCell>
                                            <TableCell align="center">Current Usage (Calls)</TableCell>
                                            <TableCell align="center">Cost Incurred</TableCell>
                                            <TableCell align="center">Max Requests / Second</TableCell>
                                            <TableCell align="center">Max Requests / Minute</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {apiAccessData?.map(({ label, data }) => (
                                            <TableRow
                                                key={label}
                                            >
                                                <TableCell component="th" scope="row">{serviceNameForPermission(label)}</TableCell>
                                                <TableCell component="th" scope="row">{timeframeForPermission(label)}</TableCell>
                                                <TableCell align="center">{Number(data.calls).toLocaleString()}</TableCell>
                                                <TableCell align="center">{data.price !== undefined && formattedAsPrice(data.price)}</TableCell>
                                                <TableCell align="center">{data.maxRequestsPerSecond}</TableCell>
                                                <TableCell align="center">{data.maxRequestsPerMinute}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </div>
                )
            }

            <Typography style={{ marginBottom: 11 }} color={'textPrimary'} variant={'body2'}>
                Advanced Options
            </Typography>

            <IconButton
                color={'primary'}
                onClick={() => props.logOutLocally()}
                style={{ height: 40 }}
            >
                <Logout />
            </IconButton>

            <PortalTour run={runTour} portalTab="ACCOUNT" />
            <WhatsNewDialog
                account={props.myAccount}
                automaticOpen={false}
                forceOpen={showWhatsNewDialog}
                setForceOpen={(status) => setShowWhatsNewDialog(status)} />

        </div >
    );
};
