import * as React from 'react';
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, LinearProgress,
    Typography
} from '@mui/material';
import {ChangeEvent} from 'react';
import {BatchType} from '../../../types/PlacesView';

interface Props {
    batchType: BatchType;
    isOpen: boolean;
    fileName?: string;

    isUploading: boolean;
    uploadErrors: string[];

    pendingBatchUploadId?: number;

    isProcessing: boolean;
    processingError?: string;

    onClose: () => void;
    onBatchUploadInitiated: (blob: Blob, filename: string) => void;
    onProcessUploadClicked: (batchUploadId: number) => void;
}

function sampleCsvFilename(batchType: BatchType): string {
    return `/${batchType}s.csv`;
}

const YourUploadComponent = (props: {fileName: string; isUploading: boolean; uploadErrors: string[]}) => {
    return (
        <div>
            <br />
            <Typography variant={'button'}>Your Upload</Typography>
            <Typography variant={'body1'}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    {props.fileName}
                    &nbsp;
                    {!props.isUploading && props.uploadErrors.length === 0 && <img src={'/images/check_circle.svg'} alt={'Uploaded!'}/>}
                </div>
            </Typography>
            {props.isUploading && <CircularProgress/>}
            {props.uploadErrors.length > 0 && <ul style={{color: '#E57373'}}>
                {props.uploadErrors.map((error, index) => <li key={`error-${index}`}>{error}</li>)}
            </ul>}
        </div>
    );
};

export const UploadDialog = (props: Props) => {
    const inputRef = React.useRef<HTMLInputElement>(null);

    const onFileChosen = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files!.item(0);
        const blob = file!.slice();

        props.onBatchUploadInitiated(blob, file!.name);
    };

    return (
        <Dialog
            open={props.isOpen}
            onClose={() => props.onClose()}
        >
            <DialogTitle>
                Upload {props.batchType.substr(0, 1).toUpperCase()}{props.batchType.substr(1)}s
            </DialogTitle>

            <DialogContent>
                <Typography variant={'body1'}>
                    Fill in this sample csv file and upload it here.
                </Typography>

                <Typography variant={'body1'}>
                    Sample CSV:
                    {' '}
                    <a
                        style={{color: '#90CAF9'}}
                        target={'_blank'}
                        rel={'noopener noreferrer'}
                        href={sampleCsvFilename(props.batchType)}
                    >
                        Download
                    </a>
                </Typography>

                <input type={"file"} ref={inputRef} style={{display: 'none'}} onChange={event => onFileChosen(event)}/>

                {props.fileName !== undefined && <YourUploadComponent fileName={props.fileName} isUploading={props.isUploading} uploadErrors={props.uploadErrors}/>}

                {props.isProcessing && <div>
                    <br />
                    <LinearProgress/>
                </div>}
            </DialogContent>

            <DialogActions>
                {!props.isProcessing && <Button onClick={() => inputRef.current?.click()} color="primary" autoFocus>
                    BROWSE FILES
                </Button>}

                {!props.isProcessing && <Button
                    disabled={props.pendingBatchUploadId === undefined || props.isProcessing}
                    onClick={() => props.onProcessUploadClicked(props.pendingBatchUploadId!)}
                    color="primary"
                >
                    UPLOAD
                </Button>}
            </DialogActions>
        </Dialog>
    );
};
