import { BasicLatLng, LocationData, RoadClosureData, RoadStatusData, RoadWorkData, SpecialEventData, StoreState, TrafficCongestionData, TrafficIncidentData, TruckWarningData, WeatherStationData } from '../../types';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ClearSaveCityError, createCity, deleteCity, SelectCity } from '../../actions/SelectedCity';
import { loadWeatherIfNeeded } from '../../actions/Weather';
import { geocodeCity } from '../../actions/SelectedCity';
import { loadRatingsIfNeeded } from '../../actions/Ratings';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { MapCenterChanged, MapZoomLevelChanged } from "../../actions/MapView";
import { ClientTrafficPage } from 'src/components/Client/Traffic';
import { TrafficSetSelectedRoadClosure, TrafficSetSelectedRoadStatus, TrafficSetSelectedSpecialEvent, TrafficSetSelectedRoadWork, TrafficSetSelectedTrafficCongestion, TrafficSetSelectedTrafficIncident, TrafficSetSelectedTruckWarning, TrafficSetSelectedWeatherStation, TrafficClearSelectedRoadCondition, TrafficSetTrafficLayer } from 'src/actions/TrafficView';
import { DashboardOverlay } from 'src/components/Client/Dashboard';

export function mapStateToProps(state: StoreState, _ownProps: RouteComponentProps<any>) {
    return {
        ...state.trafficView,

        center: state.mapView.center,
        zoomLevel: state.mapView.zoomLevel,

        selectedCityState: state.selectedCity,
        userData: state.user,
    };
}

export function mapDispatchToProps(dispatch: ThunkDispatch<StoreState, void, Action<any>>) {
    return {
        onCitySelected: (city: LocationData | undefined) => {
            dispatch(SelectCity({ city }));
            if (city) {
                if (city.needsGeocoding) {
                    dispatch(geocodeCity(city));
                } else {
                    dispatch(loadWeatherIfNeeded());
                    dispatch(loadRatingsIfNeeded());
                }
            }
        },
        onCenterChanged: (center: BasicLatLng) => {
            dispatch(MapCenterChanged({ center }));
        },
        onTrafficLayerSelected: (layer: DashboardOverlay) => {
            dispatch(TrafficSetTrafficLayer({ layer }));
            dispatch(TrafficClearSelectedRoadCondition());
        },
        onZoomLevelChanged: (zoomLevel: number) => {
            dispatch(MapZoomLevelChanged({ zoomLevel }));
        },
        onRoadStatusSelected: (roadStatus?: RoadStatusData) => {
            dispatch(TrafficSetSelectedRoadStatus({ roadStatus }));
        },
        onRoadWorkSelected: (roadWork?: RoadWorkData) => {
            dispatch(TrafficSetSelectedRoadWork({ roadWork }));
        },
        onRoadClosureSelected: (roadClosure?: RoadClosureData) => {
            dispatch(TrafficSetSelectedRoadClosure({ roadClosure }));
        },
        onSpecialEventSelected: (specialEvent?: SpecialEventData) => {
            dispatch(TrafficSetSelectedSpecialEvent({ specialEvent }));
        },
        onTrafficCongestionSelected: (trafficCongestion?: TrafficCongestionData) => {
            dispatch(TrafficSetSelectedTrafficCongestion({ trafficCongestion }));
        },
        onTrafficIncidentSelected: (trafficIncident?: TrafficIncidentData) => {
            dispatch(TrafficSetSelectedTrafficIncident({ trafficIncident }));
        },
        onTruckWarningSelected: (truckWarning?: TruckWarningData) => {
            dispatch(TrafficSetSelectedTruckWarning({ truckWarning }));
        },
        onWeatherStationSelected: (weatherStation?: WeatherStationData) => {
            dispatch(TrafficSetSelectedWeatherStation({ weatherStation }));
        },
        onClearSelectedRoadCondition: () => {
            dispatch(TrafficClearSelectedRoadCondition());
        },
        onCitySaved: (city: LocationData) => {
            dispatch(createCity(city));
        },
        onCityUnsaved: (city: LocationData) => {
            dispatch(deleteCity(city));
        },
        onCitySaveErrorDismissed: (city: LocationData) => {
            dispatch(ClearSaveCityError({ city }));
        },
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ClientTrafficPage));
