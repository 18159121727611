import { store } from "../..";
import { ClientId } from "../../types";
import { useLocalStorage } from "./useLocalStorage";

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace Config {
    export enum Key {
        // used in production
        ShownWelcomeDialog = 'SHOWN_WELCOME_DIALOG',
        LastSeenWhatsNew = 'LAST_SEEN_WHATS_NEW',
        PortalTourComplete = 'PORTAL_TOUR_COMPLETE_V2',
        PortalTourHasRedirected = 'PORTAL_TOUR_HAS_REDIRECTED',
        // used in staging/dev to test features
        ShouldForceWPC = 'SHOULD_FORCE_WPC',

        ShowRouteVehiclePairs = 'SHOW_ROUTE_VEHICLE_PAIRS',
        ShowGlobalAlerts = 'SHOW_GLOBAL_ALERTS',
        ShowConditionsForVehiclesInNowcast = 'SHOW_CONDITIONS_FOR_VEHICLES_IN_NOWCAST',

        AlwaysLogStateChanges = 'ALWAYS_LOG_STATE_CHANGES',
    }

    export function useBooleanConfig(key: Key) {
        const [storedValue, setStoredValue] = useLocalStorage(key, getDefaultValue(key));
        const setValue = (value: boolean | (() => boolean)) => {
            const valueToStore = value instanceof Function ? value() : value;
            setStoredValue(valueToStore?.toString());
        };
        return [storedValue === 'true', setValue] as const;
    }

    export function getBoolean(key: Key): boolean {
        return getString(key) === 'true';
    }

    export function setBoolean(key: Key, valueToStore: boolean) {
        setString(key, valueToStore.toString());
    }

    export function setString(key: Key, valueToStore: string | null) {
        try {
            // Save to local storage
            if (typeof window !== "undefined") {
                if (valueToStore === null) {
                    window.localStorage.removeItem(key);
                } else {
                    window.localStorage.setItem(key, valueToStore);
                }
                document.dispatchEvent(new Event('localStorageUpdated'));
            }
        } catch (error) {
            // A more advanced implementation would handle the error case
            console.log(error);
        }
    }

    export function useStringConfig(key: Key) {
        const [storedValue, setStoredValue] = useLocalStorage(key, getDefaultValue(key));
        const setValue = (value: string | null | (() => string | null)) => {
            const valueToStore = value instanceof Function ? value() : value;
            setStoredValue(valueToStore === null ? null : valueToStore.toString());
        };
        return [storedValue, setValue] as const;
    }

    export function getString(key: Key): string | null {
        const initialValue = getDefaultValue(key);
        if (typeof window === "undefined") {
            return initialValue;
        }

        try {
            // Get from local storage by key
            const item = window.localStorage.getItem(key);
            // Parse stored json or if none return initialValue
            return item ? item : initialValue;
        } catch (error) {
            // If error also return initialValue
            console.log(error);
            return initialValue;
        }
    }

    export function getDefaultValue(key: Key): string | null {
        switch (key) {
            case Key.ShouldForceWPC: return 'false';
            case Key.LastSeenWhatsNew: return null;
            case Key.ShowRouteVehiclePairs:
                // TODO: is there a better way to do this?
                if ([ClientId.Staging.US_Xpress, ClientId.Production.US_Xpress].includes(store.getState().user?.id ?? -1)) {
                    return 'true';
                }
                return 'false';
            case Key.ShownWelcomeDialog: return 'false';
            case Key.PortalTourComplete: return 'false';
            case Key.PortalTourHasRedirected: return 'false';
            case Key.ShowGlobalAlerts: return 'false';
            case Key.ShowConditionsForVehiclesInNowcast: return 'false';
            case Key.AlwaysLogStateChanges: return 'false';
        }
    }
}
