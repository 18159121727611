import {BatchType, PlacesViewState} from '../types/PlacesView';
import {
    BatchUploadFailed,
    BatchUploadSucceeded, ClosedUploadDialog, OpenedUploadDialog,
    PlacesViewAction, ProcessingBatchUploadFailed, ProcessingBatchUploadSucceeded, SetSelectedPlacesTab,
    StartedBatchUpload,
    StartedProcessingBatchUpload
} from '../actions/PlacesView';

const initialDialogState: Partial<PlacesViewState> = {
    isUploadDialogOpen: false,
    uploadBatchType: BatchType.Route,
    fileName: undefined,
    isUploading: false,
    pendingBatchUploadId: undefined,
    uploadErrors: [],
    isProcessing: false,
    processingError: undefined,
};

export function placesView(state: PlacesViewState, action: typeof PlacesViewAction.actions): PlacesViewState {
    if (state === undefined) {
        return {...initialDialogState, selectedTab: 0} as PlacesViewState;
    }

    switch (action.type) {
        case SetSelectedPlacesTab.type:
            return {...state, selectedTab: action.selectedTab};
        case OpenedUploadDialog.type:
            // always reset to initial upload state when opening the upload dialog
            return {...state, ...initialDialogState, isUploadDialogOpen: true, uploadBatchType: action.batchType};
        case ClosedUploadDialog.type:
            return {...state, ...initialDialogState, isUploadDialogOpen: false};
        case StartedBatchUpload.type:
            return {...state, fileName: action.filename, isUploading: true, uploadErrors: []};
        case BatchUploadFailed.type:
            return {...state, uploadErrors: action.errors, isUploading: false};
        case BatchUploadSucceeded.type:
            return {...state, pendingBatchUploadId: action.batchUploadId, isUploading: false};
        case StartedProcessingBatchUpload.type:
            return {...state, isProcessing: true, processingError: undefined};
        case ProcessingBatchUploadFailed.type:
            return {...state, isProcessing: false, processingError: action.error};
        case ProcessingBatchUploadSucceeded.type:
            // reset to initial upload state with a successful upload
            return {...state, ...initialDialogState};
        default: return state;
    }
}
