import { ImpactLevel, colorForImpactLevel } from "src/types/routes";

export function createImpactClusterSVG(segments, numLocations) {
    const svgns = "http://www.w3.org/2000/svg"; // SVG namespace
    const size = 32;
    const width = size, height = size;
    const centerX = width / 2, centerY = height / 2;
    const radius = size / 2;

    // Create SVG element
    const svg = document.createElementNS(svgns, 'svg');
    svg.setAttribute('width', width);
    svg.setAttribute('height', height);
    svg.setAttribute('viewBox', `0 0 ${width} ${height}`);

    let startAngle = 0;

    segments.forEach(segment => {
        if (segment.fraction >= 1) {
            const circle = document.createElementNS(svgns, 'circle');
            circle.setAttribute('cx', 16);
            circle.setAttribute('cy', 16);
            circle.setAttribute('r', 16);
            circle.setAttribute('fill', segment.color);
            svg.appendChild(circle);
            return;
        }

        if (segment.fraction <= 0) {
            return;
        }

        // Calculate end angle
        const fraction = segment.fraction >= 1 ? 0.999 : segment.fraction;
        const endAngle = startAngle + fraction * 2 * Math.PI;

        // Convert angles to Cartesian coordinates
        const startX = centerX + radius * Math.cos(startAngle - Math.PI / 2);
        const startY = centerY + radius * Math.sin(startAngle - Math.PI / 2);
        const endX = centerX + radius * Math.cos(endAngle - Math.PI / 2);
        const endY = centerY + radius * Math.sin(endAngle - Math.PI / 2);

        // Large arc flag
        const largeArcFlag = (fraction > 0.5) ? 1 : 0;

        // Create the path
        const path = document.createElementNS(svgns, 'path');
        const d = [
            `M ${centerX} ${centerY}`, // Move to center
            `L ${startX} ${startY}`, // Line to start
            `A ${radius} ${radius} 0 ${largeArcFlag} 1 ${endX} ${endY}`, // Arc to end
            `Z` // Close path
        ].join(' ');

        path.setAttribute('d', d);
        path.setAttribute('fill', segment.color);
        svg.appendChild(path);

        // Update start angle to the end angle of the current segment
        startAngle = endAngle;
    });

    // Add donut hole
    const hole = document.createElementNS(svgns, 'circle');
    hole.setAttribute('cx', centerX);
    hole.setAttribute('cy', centerY);
    hole.setAttribute('r', radius * 0.6);
    hole.setAttribute('fill', '#333333');
    svg.appendChild(hole);

    // Add text in the center
    const text = document.createElementNS(svgns, 'text');
    text.setAttribute('x', centerX);
    text.setAttribute('y', centerY);
    text.setAttribute('text-anchor', 'middle');
    text.setAttribute('dominant-baseline', 'central');
    text.setAttribute('font-family', 'sans-serif');
    text.setAttribute('font-weight', 'bold');
    // cap the shown number of location to 999 so that it's not longer than 3 chars
    text.textContent = "" + Math.min(numLocations, 999);
    // make the font smaller when the text is 3 or more characters long
    text.setAttribute('font-size', '' + (size * (text.textContent.length > 2 ? 0.3 : 0.4)));
    text.setAttribute('fill', 'white');
    svg.appendChild(text);

    // TODO: should this be height / 2 like all the others?
    svg.setAttribute("transform", "translate(0 25)");

    return svg;
}

export function makeImpactClusterer(maps, map, MarkerClusterer, Algorithm, clusterClickListener) {
    const locationClusterRenderer = {
        render: (info, stats) => {
            const { count, position, markers } = info;

            let svgEl;
            // Code for clusters that are pie charts representing impact
            const markersInImpactLevel = (impactLevel) => markers.filter(marker => marker.metadata.matchingImpactSummary?.impactLevel === impactLevel);
            const impactLevels = [ImpactLevel.None, ImpactLevel.Low, ImpactLevel.Moderate, ImpactLevel.High, ImpactLevel.Extreme];

            const segments = impactLevels.map(impactLevel => {
                const color = colorForImpactLevel(impactLevel);
                return { color, fraction: markersInImpactLevel(impactLevel).length / count };
            });

            svgEl = createImpactClusterSVG(segments, count);

            return new maps.marker.AdvancedMarkerElement({
                position,
                map: map,
                title: `Cluster of ${count} locations`,
                content: svgEl,
                // adjust zIndex to be above other markers
                // zIndex: Number(maps.Marker.MAX_ZINDEX) + count,
            });
        }
    };

    return new MarkerClusterer({
        markers: [],
        algorithm: new Algorithm({ radius: 120 }),
        onClusterClick: clusterClickListener,
        renderer: locationClusterRenderer,
    });
}