import { ImpactViewState } from '../types';
import { getClientImpactSections } from '../components/Client/Impact/sections';
import {
    ImpactSetAnimationSpeed,
    ImpactSetMapOptionsCollapsed,
    ImpactSetPaused,
    ImpactSetSelectedMap,
    ImpactViewAction,
    ImpactSetCyclonesVisible,
    ImpactSetEarthquakesVisible,
    ImpactSetLightningVisible,
    ImpactSetStormsVisible,
    ImpactSetVolcanoesVisible,
    ImpactSetFiresVisible,
    ImpactSetWildfiresVisible,
    SelectEarthquake,
    SelectCyclone,
    SelectLightning,
    SelectVolcano,
    SelectStorm,
    SelectFire,
    SelectWildfire,
    ImpactSetTileOpacity,
    ImpactSetCurrentTileset,
    ClearSelectedEvent,
    SelectPowerOutage,
    SelectStormReport,
    ImpactSetPowerOutagesVisible,
    ImpactSetStormReportsVisible,
} from '../actions/ImpactView';

export function impactView(state: ImpactViewState, action: typeof ImpactViewAction.actions): ImpactViewState {
    if (typeof state === 'undefined') {
        let impactMapSections = getClientImpactSections(false, false);
        let impactMapSection = impactMapSections[0];
        let firstImpactMapType = impactMapSection.mapTypes[0];

        return {
            tileOpacity: 0.7,
            currentTileset: undefined,
            paused: false,
            animationDelay: 1000,
            selectedGraphView: 'rating',
            mapOptionsCollapsed: false,
            selectedSpeedMultiplierIndex: 0,
            isCyclonesVisible: false,
            isEarthquakesVisible: false,
            isLightningVisible: false,
            isPowerOutagesVisible: false,
            isStormsVisible: false,
            isStormReportsVisible: false,
            isVolcanoesVisible: false,
            isFiresVisible: false,
            isWildfiresVisible: false,
            selectedMapCategorySlug: impactMapSection.slug,
            selectedMapType: firstImpactMapType,
            selectedCyclone: undefined,
            selectedEarthquake: undefined,
            selectedLightning: undefined,
            selectedPowerOutage: undefined,
            selectedStorm: undefined,
            selectedStormReport: undefined,
            selectedVolcano: undefined,
            selectedFire: undefined,
            selectedWildfire: undefined,
        };
    }

    switch (action.type) {
        case ImpactSetSelectedMap.type:
            return {
                ...state,
                selectedGraphView: action.selectedMapCategory.key,
                selectedMapCategorySlug: action.selectedMapCategory.slug,
                selectedMapType: action.selectedMapType,
            };
        case ImpactSetAnimationSpeed.type:
            return {
                ...state,
                selectedSpeedMultiplierIndex: action.selectedSpeedMultiplierIndex,
                animationDelay: action.animationDelay
            };
        case ImpactSetTileOpacity.type:
            return { ...state, tileOpacity: action.tileOpacity };
        case ImpactSetCurrentTileset.type:
            return { ...state, currentTileset: action.tileset };
        case ImpactSetPaused.type:
            return { ...state, paused: action.paused };
        case ImpactSetCyclonesVisible.type:
            return { ...state, isCyclonesVisible: action.visible };
        case ImpactSetEarthquakesVisible.type:
            return { ...state, isEarthquakesVisible: action.visible };
        case ImpactSetLightningVisible.type:
            return { ...state, isLightningVisible: action.visible };
        case ImpactSetPowerOutagesVisible.type:
            return { ...state, isPowerOutagesVisible: action.visible };
        case ImpactSetStormsVisible.type:
            return { ...state, isStormsVisible: action.visible };
        case ImpactSetStormReportsVisible.type:
            return { ...state, isStormReportsVisible: action.visible };
        case ImpactSetVolcanoesVisible.type:
            return { ...state, isVolcanoesVisible: action.visible };
        case ImpactSetFiresVisible.type:
            return { ...state, isFiresVisible: action.visible };
        case ImpactSetWildfiresVisible.type:
            return { ...state, isWildfiresVisible: action.visible };
        case ImpactSetMapOptionsCollapsed.type:
            return { ...state, mapOptionsCollapsed: action.collapsed };
        case SelectCyclone.type:
            return {
                ...state,
                selectedCyclone: action.cyclone,
                selectedEarthquake: undefined,
                selectedLightning: undefined,
                selectedPowerOutage: undefined,
                selectedStorm: undefined,
                selectedStormReport: undefined,
                selectedVolcano: undefined,
                selectedFire: undefined,
                selectedWildfire: undefined,
            };
        case SelectEarthquake.type:
            return {
                ...state,
                selectedCyclone: undefined,
                selectedEarthquake: action.earthquake,
                selectedLightning: undefined,
                selectedPowerOutage: undefined,
                selectedStorm: undefined,
                selectedStormReport: undefined,
                selectedVolcano: undefined,
                selectedFire: undefined,
                selectedWildfire: undefined,
            };
        case SelectLightning.type:
            return {
                ...state,
                selectedCyclone: undefined,
                selectedEarthquake: undefined,
                selectedLightning: action.lightning,
                selectedPowerOutage: undefined,
                selectedStorm: undefined,
                selectedStormReport: undefined,
                selectedVolcano: undefined,
                selectedFire: undefined,
                selectedWildfire: undefined,
            };
        case SelectPowerOutage.type:
            return {
                ...state,
                selectedCyclone: undefined,
                selectedEarthquake: undefined,
                selectedLightning: undefined,
                selectedPowerOutage: action.powerOutage,
                selectedStorm: undefined,
                selectedStormReport: undefined,
                selectedVolcano: undefined,
                selectedFire: undefined,
                selectedWildfire: undefined,
            };
        case SelectStorm.type:
            return {
                ...state,
                selectedCyclone: undefined,
                selectedEarthquake: undefined,
                selectedLightning: undefined,
                selectedPowerOutage: undefined,
                selectedStorm: action.storm,
                selectedStormReport: undefined,
                selectedVolcano: undefined,
                selectedFire: undefined,
                selectedWildfire: undefined,
            };
        case SelectStormReport.type:
            return {
                ...state,
                selectedCyclone: undefined,
                selectedEarthquake: undefined,
                selectedLightning: undefined,
                selectedPowerOutage: undefined,
                selectedStorm: undefined,
                selectedStormReport: action.stormReport,
                selectedVolcano: undefined,
                selectedFire: undefined,
                selectedWildfire: undefined,
            };
        case SelectVolcano.type:
            return {
                ...state,
                selectedCyclone: undefined,
                selectedEarthquake: undefined,
                selectedLightning: undefined,
                selectedPowerOutage: undefined,
                selectedStorm: undefined,
                selectedStormReport: undefined,
                selectedVolcano: action.volcano,
                selectedFire: undefined,
                selectedWildfire: undefined,
            };
        case SelectFire.type:
            return {
                ...state,
                selectedCyclone: undefined,
                selectedEarthquake: undefined,
                selectedLightning: undefined,
                selectedPowerOutage: undefined,
                selectedStorm: undefined,
                selectedStormReport: undefined,
                selectedVolcano: undefined,
                selectedFire: action.fire,
                selectedWildfire: undefined,
            };
        case SelectWildfire.type:
            return {
                ...state,
                selectedCyclone: undefined,
                selectedEarthquake: undefined,
                selectedLightning: undefined,
                selectedPowerOutage: undefined,
                selectedStorm: undefined,
                selectedStormReport: undefined,
                selectedVolcano: undefined,
                selectedFire: undefined,
                selectedWildfire: action.wildfire,
            };
        case ClearSelectedEvent.type:
            return {
                ...state,
                selectedCyclone: undefined,
                selectedEarthquake: undefined,
                selectedLightning: undefined,
                selectedPowerOutage: undefined,
                selectedStorm: undefined,
                selectedStormReport: undefined,
                selectedVolcano: undefined,
                selectedFire: undefined,
                selectedWildfire: undefined,
            };
        default: return state;
    }
}
