import React from 'react';
import '../Dashboard/TimelineChart.css';
import { AlertData, ImpactTag, RatingsDataWithLocationInfo, UserState, VehicleImpactTag, VehicleTrackingData, WeatherData } from "src/types";
import { Timeframe } from "../Dashboard/data";
import { Button, Chip, IconButton, MenuItem, Paper, Select, Tooltip, Typography } from '@mui/material';
import { cardBackgroundColor } from 'src/constants';
import { Close } from '@mui/icons-material';
import { darkTagColorForImpactLevel, ImpactLevel, vehicleTrackingDataToLocationData, wordForImpactLevel } from '../../../types/routes';
import AlertTimelineView from './AlertTimelineView';
import { fetchWeatherDataOnce } from '../../../actions/Weather';
import { fetchRatingsOnce } from '../../../actions/Ratings';
import { Config } from '../../shared/useConfig';
import { SendAlertData, SendAlertDialog } from '../Dashboard/ImpactedVehiclesTable';
import TimeAgo from 'javascript-time-ago';
import { getAssetName } from '.';

const bearingToCompassDirection = (bearing: number | undefined) => {
    if (bearing === undefined)
        return undefined;

    const val = Math.floor((bearing / 22.5) + 0.5) % 16;
    const arr = ["N", "NNE", "NE", "ENE", "E", "ESE", "SE", "SSE", "S", "SSW", "SW", "WSW", "W", "WNW", "NW", "NNW"];
    return arr[val];
};

interface Props {
    userData: UserState;
    selectedVehicle: VehicleTrackingData;
    selectedChartView: string;
    timeframe: Timeframe;

    onCloseClicked?: () => void;
    setSelectedChartView: (view: string) => void;
    setAlerts: (alerts: AlertData[]) => void;
    setSelectedAlert: (alert: AlertData) => void;
    onVehicleMessageRequested: (vehicle: VehicleTrackingData, message: string, urgent: boolean) => void;

    height: number;
    extraStyles?: React.CSSProperties;
}

function SendAlertButton(props: { vehicle: VehicleTrackingData; style?: React.CSSProperties; setAlertData: (data: SendAlertData) => void; setShowAlertDialog: (show: boolean) => void; }) {
    const { vehicle } = props;

    const source = vehicle.source;
    // TODO: move this logic to the server and return in /vehicles endpoint
    let issueWithSendingMessage: string | undefined = undefined;
    switch (source) {
        case "geotab":
            break;
        case "samsara":
            break;
        case "platform_science":
            if (vehicle.externalDriverId === undefined) {
                issueWithSendingMessage = "vehicle has no external driver id";
            }
            break;
        case "weatheroptics":
            break;
        case "motive":
            if (vehicle.externalDriverId === undefined) {
                issueWithSendingMessage = "vehicle has no external driver id";
            }
            break;
        default:
            issueWithSendingMessage = "unsupported vehicle source for messaging";
            break;
    }
    const truckId = vehicle.name || vehicle.externalId;
    const impact = vehicle.currentImpact;
    const tooltipTitle = issueWithSendingMessage || "";
    return (
        <Tooltip title={tooltipTitle} placement="top">
            <span style={props.style}>
                <Button onClick={() => {
                    let alertMessage = '';
                    if (impact) {
                        impact.tags.forEach((tag: VehicleImpactTag, index: number) => {
                            const label = tag.text;
                            if (index === impact.tags.length - 1) {
                                alertMessage += `${label}`;
                            } else {
                                alertMessage += `${label}, `;
                            }
                        });
                    }

                    props.setAlertData({ vehicle: truckId, message: alertMessage, source: source });
                    props.setShowAlertDialog(true);
                }} className={'sendAlertButton'} sx={{ "&:hover": { backgroundColor: "transparent" }, boxShadow: 0 }} disableRipple disableFocusRipple disabled={issueWithSendingMessage !== undefined} size="small">
                    Send Alert
                </Button>
            </span>
        </Tooltip >
    );
}

function VehicleTimelineView(props: Props) {
    const { userData, selectedVehicle, selectedChartView, timeframe, onCloseClicked, setSelectedChartView, setSelectedAlert, setAlerts } = props;

    const [weatherData, setWeatherData] = React.useState<WeatherData | undefined>(undefined);
    const [ratingsData, setRatingsData] = React.useState<RatingsDataWithLocationInfo | undefined>(undefined);
    const [showSendAlertDialog, setShowSendAlertDialog] = React.useState<boolean>(false);
    const [vehicleAlertData, setVehicleAlertData] = React.useState<SendAlertData>({ vehicle: '', message: '', source: '' });
    const showConditionsForVehiclesInNowcast = Config.getBoolean(Config.Key.ShowConditionsForVehiclesInNowcast);

    const fetchWeatherData: () => Promise<WeatherData | undefined> = React.useMemo(
        () => {
            return () => {
                const location = vehicleTrackingDataToLocationData(selectedVehicle);
                if (userData.token === undefined || userData.portalToken === undefined || location === undefined) {
                    return Promise.resolve(undefined);
                }

                return fetchWeatherDataOnce(
                    userData.token,
                    userData.portalToken,
                    location,
                    new AbortController(),
                    location.impactSummariesRatingRunTime,
                    true
                );
            };
        },
        [userData.token, selectedVehicle.id]
    );

    const fetchRatingsData: () => Promise<RatingsDataWithLocationInfo | undefined> = React.useMemo(
        () => {
            return () => {
                if (userData.portalToken === undefined || selectedVehicle.latitude === undefined || selectedVehicle.longitude === undefined) {
                    return Promise.resolve(undefined);
                }

                return fetchRatingsOnce(
                    userData.portalToken,
                    new AbortController(),
                    selectedVehicle.latitude,
                    selectedVehicle.longitude,
                    'all',
                    undefined,
                    true
                );
            };
        },
        [userData.token, selectedVehicle.id]
    );

    React.useEffect(() => {
        // clear initial state when component is reused
        setWeatherData(undefined);

        fetchWeatherData().then(value => {
            setWeatherData(value);
            setAlerts(value?.alerts ?? []);
        });

    }, [fetchWeatherData]);

    React.useEffect(() => {
        // clear initial state when component is reused
        setRatingsData(undefined);

        fetchRatingsData().then(value => {
            setRatingsData(value);
        });

    }, [fetchRatingsData]);

    let impactString = '';
    if (selectedVehicle.currentImpact !== undefined) {
        impactString = `${wordForImpactLevel(selectedVehicle.currentImpact.overallImpactLevel)}`;
    }
    const impactTags: ImpactTag[] = [];
    selectedVehicle.currentImpact?.tags.forEach(tag => {
        // create separate tags for tags that have been combined with same impact level
        tag.text.split(',').forEach(tagText => {
            impactTags.push({
                id: tag.id,
                source: tag.source,
                text: tagText.trim(),
                value: tag.value,
                impactLevel: tag.impactLevel,
            });
        });
    });

    const timeAgo = new TimeAgo('en-us');

    const compassDirection = bearingToCompassDirection(selectedVehicle.bearing);
    const speedText = selectedVehicle.speed?.toFixed(0);
    let subtitle = 'Heading unknown';
    if (compassDirection && speedText && speedText !== '0') {
        subtitle = `Moving ${compassDirection} @ ${speedText} mph`;
    } else if (compassDirection) {
        subtitle = `Facing ${compassDirection}`;
    } else if (speedText && speedText !== '0') {
        subtitle = `Moving @ ${speedText} mph`;
    }
    const externalUpdatedAt = selectedVehicle.externalUpdatedAt;

    const selectedVehicleInfo: JSX.Element = (
        <Paper className="location-info" elevation={4} style={{ backgroundColor: cardBackgroundColor, width: '300px', display: 'flex', flexDirection: 'column' }}>
            <header style={{ padding: '10px 0px 0px 15px', display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
                <Typography variant={'subtitle1'} fontWeight={"bold"} color={'textPrimary'}>Truck {getAssetName(selectedVehicle)}</Typography>
                <div style={{ flex: '1 1 0' }} />
                {!showConditionsForVehiclesInNowcast && <IconButton
                    color={'primary'}
                    onClick={() => onCloseClicked?.()}
                    style={{ height: 30 }}
                >
                    <Close />
                </IconButton>}
            </header>
            <Typography style={{ margin: "0px 0px 5px 15px", opacity: 0.5 }} variant={'caption'} >{subtitle}</Typography>
            {externalUpdatedAt && <Typography style={{ margin: "0px 0px 5px 15px", opacity: 0.5 }} variant={'caption'} >{`Last updated ${timeAgo.format(externalUpdatedAt)}`}</Typography>}
            <SendAlertButton
                vehicle={selectedVehicle}
                style={{ paddingLeft: '10px', }}
                setAlertData={setVehicleAlertData}
                setShowAlertDialog={setShowSendAlertDialog}
            />
            <div style={{ overflow: 'auto', height: '100%' }}>
                <Typography style={{ margin: "5px 15px" }} variant={'body2'} fontWeight={"bold"} color={'textPrimary'}>Current Impact Level</Typography>
                <div style={{ margin: "5px 15px 10px", display: 'flex', flexDirection: 'row', alignContent: 'center' }}>
                    <Chip
                        style={{
                            padding: 8,
                            // subhourlyImpactSummary is missing if you click on the map on impact tab then switch to nowcast and click on a saved location
                            backgroundColor: darkTagColorForImpactLevel(selectedVehicle.currentImpact?.overallImpactLevel ?? ImpactLevel.Unknown),
                            color: 'white',
                            fontWeight: 'bold',
                        }}
                        label={impactString}
                    />
                </div>
                {(selectedVehicle.currentImpact?.overallImpactLevel ?? ImpactLevel.Unknown) > 0 && <React.Fragment>
                    <Typography style={{ margin: "5px 15px" }} fontWeight={"bold"} variant={'body2'} color={'textPrimary'}>Current Risks ({impactTags.length})</Typography>
                    <div style={{ overflowY: 'auto' }}>
                        {impactTags.map(tag => (
                            <div>
                                <Chip
                                    style={{
                                        margin: "5px 15px 5px 15px",
                                        padding: 8,
                                        backgroundColor: darkTagColorForImpactLevel(tag.impactLevel),
                                        color: 'white',
                                        fontWeight: 'bold',
                                        maxWidth: 250
                                    }}
                                    label={tag.text + (tag.value ? ` (${tag.value})` : '')}
                                    onClick={() => {
                                        if (tag.source === 'governmental_alert') {
                                            const alert = weatherData?.alerts?.find(a => a.id === tag.id);
                                            if (alert) {
                                                setSelectedAlert(alert);
                                            }
                                        }
                                    }}
                                />
                            </div>
                        ))}
                    </div>
                </React.Fragment>}
            </div>
        </Paper>);

    const chartViews = [
        { key: 'current_conditions', title: 'Current Conditions' },
        { key: 'alerts', title: 'NWS Alerts' },
    ];
    let currentConditionsInfo: JSX.Element | undefined = undefined;
    const subhourlyWeatherRow = weatherData?.subhourly?.find((row) => row.time.getTime() - 7.5 * 60 * 1000 > new Date().getTime());
    const subhourlyRatingsRowIndex = ratingsData?.disruption?.findIndex((row) => row.time.getTime() - 7.5 * 60 * 1000 > new Date().getTime());
    if (subhourlyWeatherRow && ratingsData && subhourlyRatingsRowIndex) {
        currentConditionsInfo = (
            <div style={{ backgroundColor: cardBackgroundColor }}>
                <div style={{ height: props.height, display: 'flex' }}>
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                <Typography style={{ marginRight: '10px' }}>{"Temperature:"}</Typography>
                                <Typography>{`${subhourlyWeatherRow.temperature}°F`}</Typography>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                <Typography style={{ marginRight: '10px' }}>{"Wind Speed:"}</Typography>
                                <Typography>{`${subhourlyWeatherRow.windSpeed} mph`}</Typography>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                <Typography style={{ marginRight: '10px' }}>{"Wind Gust:"}</Typography>
                                <Typography>{`${subhourlyWeatherRow.windGust} mph`}</Typography>
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                <Typography style={{ marginRight: '10px' }}>{"Heat Index:"}</Typography>
                                <Typography>{`${subhourlyWeatherRow.heatIndex}°F`}</Typography>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                <Typography style={{ marginRight: '10px' }}>{"Visibility:"}</Typography>
                                <Typography>{`${subhourlyWeatherRow.visibility} mi`}</Typography>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                <Typography style={{ marginRight: '10px' }}>{"Road Index:"}</Typography>
                                <Typography>{`${ratingsData.road[subhourlyRatingsRowIndex].value}`}</Typography>
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                <Typography style={{ marginRight: '10px' }}>{"Business Index:"}</Typography>
                                <Typography>{`${ratingsData.disruption[subhourlyRatingsRowIndex].value}`}</Typography>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                <Typography style={{ marginRight: '10px' }}>{"Power Index:"}</Typography>
                                <Typography>{`${ratingsData.power[subhourlyRatingsRowIndex].value}`}</Typography>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                <Typography style={{ marginRight: '10px' }}>{"Life & Property Index:"}</Typography>
                                <Typography>{`${ratingsData.life_property[subhourlyRatingsRowIndex].value}`}</Typography>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        currentConditionsInfo = (
            <div style={{ backgroundColor: cardBackgroundColor }}>
                <div style={{ height: props.height, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    Loading current conditions...
                </div>
            </div>
        );
    }

    return (
        <div className={"NowcastTimeSeriesGraphsContainer"}>
            {showConditionsForVehiclesInNowcast && <div className={"TimeSeriesGraphComponent"}>
                <Paper elevation={3} style={{ backgroundColor: cardBackgroundColor, backgroundImage: 'none', borderRadius: 11, ...props.extraStyles }}>
                    <header style={{ padding: '12px 12px 12px 0px', display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
                        <Select
                            value={selectedChartView}
                            variant={'outlined'}
                            style={{ margin: "0px 5px", width: 200, height: 40 }}
                            onChange={(event) => setSelectedChartView(event.target.value)}
                        >
                            {chartViews.map(chartView => (
                                <MenuItem value={chartView.key}>{chartView.title}</MenuItem>
                            ))}
                        </Select>
                        <div style={{ flex: '1 1 0' }} />
                        <IconButton
                            color={'primary'}
                            onClick={() => onCloseClicked?.()}
                            style={{ height: 30 }}
                        >
                            <Close />
                        </IconButton>
                    </header>
                    {selectedChartView === 'current_conditions' ? currentConditionsInfo : <AlertTimelineView
                        extraStyles={props.extraStyles}
                        selectedChartView={selectedChartView}
                        chartViews={chartViews}
                        alerts={weatherData?.alerts}
                        timeframe={timeframe}
                        timezone={undefined}
                        onCloseClicked={() => onCloseClicked?.()}
                        setSelectedChartView={(view) => setSelectedChartView(view)}
                        setSelectedAlert={(alert) => setSelectedAlert(alert)}
                    />}
                </Paper>
            </div>}
            {selectedVehicleInfo}

            {showSendAlertDialog && <SendAlertDialog
                alertData={vehicleAlertData}
                selectedVehicle={selectedVehicle}
                showAlertDialog={showSendAlertDialog}
                setShowAlertDialog={setShowSendAlertDialog}
                onVehicleMessageRequested={props.onVehicleMessageRequested}
            />}
        </div>
    );
}

export default VehicleTimelineView;
