import { AlertData, Blurb, BlurbsByIndex, ClientId, CycloneData, EarthquakeData, FireData, ImpactTileset, ImpactTilesetsByIndex, LightningData, LoadableResult, LocationData, RatingsDataWithLocationInfo, RoadClosureData, RoadStatusData, RoadWorkData, SelectedCityState, SpecialEventData, StormData, TrafficCongestionData, TrafficIncidentData, TruckWarningData, UserState, VehicleTrackingData, VolcanoData, WeatherData, WeatherStationData, WeatherTilesetsByIndex, WildfireData, getPointForEvent, haversineDistance, isValidUserForFeature } from "../../../types";
import * as React from "react";
import './index.css';
import { ImpactSummary } from "./ImpactSummary";
import { Redirect } from "react-router";
import moment from "moment";
import { filterRoutes, findTimeframeIndexForTime, Timeframe } from "./data";
import { DashboardView, ImpactDetailView, TimelineView } from "./ImpactDetailView";
import { RouteAndMarkerMap, defaultRouteAndMarkerMapProps } from "../../shared/RouteAndMarkerMap";
import { findImpactLevel, ImpactLevel, RouteData } from "../../../types/routes";
import { AutoShowErrorDialog } from "src/components/shared/AutoShowErrorDialog";
import PortalTour from '../PortalTour';
import { Backdrop, CircularProgress, FormControlLabel, FormGroup, IconButton, MenuItem, Paper, Select, Switch, Tab, Tabs, Tooltip, Typography } from "@mui/material";
import { cardBackgroundColor } from "src/constants";
import { KeyboardArrowDown, KeyboardArrowUp, } from "@mui/icons-material";
import { AlertDetailsComponent } from "../Alerts/AlertDetailsComponent";
import { EventDescriptionComponent } from "../Impact/EventDescriptionComponent";
import { Config } from "src/components/shared/useConfig";
import { LegendComponent } from "../Impact/LegendComponent";
import { ImpactMapType, MapType, MaskType } from "src/types/MapType";
import { WeatherCalloutView } from "./WeatherCalloutView";
import { RoadStatusLegendComponent } from "./RoadStatusLegendComponent";
import { ImpactMapSection, getClientImpactSections, getClientRadarSection } from "../Impact/sections";
import { isImpactKey, isWeatherKey } from "src/types/RatingKey";
import { snakeToCamelCase } from "src/types/unmarshal";
import Slider from "rc-slider";
import { useHistory } from 'react-router-dom';
import { AlertsLegend } from "../Alerts/AlertsLegend";
import { Bounds } from "google-map-react";
import { TilesetUtils } from "../Impact";
import { findIndexWithDefaultValue } from "../../shared/MapControlsComponent";
import { TrafficLegend } from "../Traffic/TrafficLegend";
import { GeoJSONLayer } from "../../Public/GovernmentalAlerts";
import { FloodChanceLegend } from "./FloodChanceLegend";
import { trackEvent } from "src/analytics";
import { calculateLightningOpacityFromAge } from "../Impact/MapManagerV2";
import { NearbyLightning } from "../Nowcast";

export type DashboardOverlay = 'impact' | 'alerts' | 'radar' | 'traffic' | 'traffic-incidents' | 'traffic-road-status' | 'traffic-road-work' | 'traffic-road-closures' | 'traffic-truck-warnings' | 'weather' | 'rating' | 'lightning' | 'events' | 'road-status' | 'flood-chance';

export function dashboardOverlayToAnalyticsTerm(overlay: DashboardOverlay): string {
    switch (overlay) {
        case 'impact': return 'summary';
        case 'rating': return 'impact-indices';
        case 'weather': return 'hyperlocal-weather';
        default: return overlay;
    }
}

export function analyticsTermToDashboardOverlay(term: string): DashboardOverlay {
    switch (term) {
        case 'summary': return 'impact';
        case 'impact-indices': return 'rating';
        case 'hyperlocal-weather': return 'weather';
        default: return term as DashboardOverlay;
    }
}

export type PolylineType = 'slowdown' | 'road-risk';

export interface Props {
    userData?: UserState;

    selectedView: DashboardView;
    selectedRoute: RouteData | undefined;
    selectedCityState?: SelectedCityState;
    selectedCity?: LocationData;
    selectedVehicle?: VehicleTrackingData;
    selectedOverlay?: DashboardOverlay;
    selectedCyclone?: CycloneData;
    selectedEarthquake?: EarthquakeData;
    selectedLightning?: LightningData;
    selectedStorm?: StormData;
    selectedVolcano?: VolcanoData;
    selectedFire?: FireData;
    selectedWildfire?: WildfireData;

    selectedRoadStatus?: RoadStatusData;
    selectedRoadWork?: RoadWorkData;
    selectedRoadClosure?: RoadClosureData;
    selectedSpecialEvent?: SpecialEventData;
    selectedTrafficCongestion?: TrafficCongestionData;
    selectedTrafficIncident?: TrafficIncidentData;
    selectedTruckWarning?: TruckWarningData;
    selectedWeatherStation?: WeatherStationData;

    tileOpacity: number;

    ratingsData?: RatingsDataWithLocationInfo;
    weatherData?: WeatherData;
    blurbs: BlurbsByIndex;

    governmentalAlerts: LoadableResult<AlertData[]>;

    cyclones?: CycloneData[];
    earthquakes?: EarthquakeData[];
    lightning?: LightningData[];
    storms?: StormData[];
    volcanoes?: VolcanoData[];
    fires?: FireData[];
    wildfires?: WildfireData[];

    roadStatus?: RoadStatusData[];
    roadWork?: RoadWorkData[];
    roadClosures?: RoadClosureData[];
    specialEvents?: SpecialEventData[];
    trafficCongestion?: TrafficCongestionData[];
    trafficIncidents?: TrafficIncidentData[];
    truckWarnings?: TruckWarningData[];
    weatherStations?: WeatherStationData[];

    impactTilesets?: ImpactTilesetsByIndex;
    weatherTilesets?: WeatherTilesetsByIndex;
    isCyclonesVisible: boolean;
    isEarthquakesVisible: boolean;
    isLightningVisible: boolean;
    isStormsVisible: boolean;
    isVolcanoesVisible: boolean;
    isFiresVisible: boolean;
    isWildfiresVisible: boolean;

    isRoadStatusVisible: boolean;
    isRoadWorkVisible: boolean;
    isRoadClosuresVisible: boolean;
    isSpecialEventsVisible: boolean;
    isTrafficCongestionVisible: boolean;
    isTrafficIncidentsVisible: boolean;
    isTruckWarningsVisible: boolean;
    isWeatherStationsVisible: boolean;

    showDisruptionIndex: boolean;
    showWildfireIndices: boolean;

    setSelectedView: (view: DashboardView) => void;
    setSelectedRoute: (route: RouteData | undefined) => void;
    setSelectedOverlay: (overlay: string) => void;
    onCitySelected: (city: LocationData | undefined) => void;
    onSetTileOpacity: (tileOpacity: number) => void;
    onCitySaved: (city: LocationData) => void;
    onCityUnsaved: (city: LocationData) => void;
    onCitySaveErrorDismissed: (city: LocationData) => void;
    onVehicleSelected: (city: VehicleTrackingData | undefined) => void;
    onVehicleMessageRequested: (vehicle: VehicleTrackingData, message: string, urgent: boolean) => void;
    onRouteRefreshRequested: (route: RouteData) => void;
    onRouteRefreshErrorDismissed: () => void;
    onLoadEvents: () => void;
    onLoadRoadStatus: () => void;
    onCyclonesToggled: (toggled: boolean) => void;
    onEarthquakesToggled: (toggled: boolean) => void;
    onLightningToggled: (toggled: boolean) => void;
    onStormsToggled: (toggled: boolean) => void;
    onVolcanoesToggled: (toggled: boolean) => void;
    onFiresToggled: (toggled: boolean) => void;
    onWildfiresToggled: (toggled: boolean) => void;
    onCycloneSelected: (cyclone: CycloneData | undefined) => void;
    onEarthquakeSelected: (earthquake: EarthquakeData | undefined) => void;
    onLightningSelected: (lightning: LightningData | undefined) => void;
    onStormSelected: (storm: StormData | undefined) => void;
    onVolcanoSelected: (volcano: VolcanoData | undefined) => void;
    onFireSelected: (fire: FireData | undefined) => void;
    onWildfireSelected: (wildfire: WildfireData | undefined) => void;
    onClearSelectedEvent: () => void;
    onRoadStatusSelected: (roadStatus: RoadStatusData | undefined) => void;
    onRoadWorkSelected: (roadWork?: RoadWorkData) => void;
    onRoadClosureSelected: (roadClosure?: RoadClosureData) => void;
    onSpecialEventSelected: (specialEvent?: SpecialEventData) => void;
    onTrafficCongestionSelected: (trafficCongestion?: TrafficCongestionData) => void;
    onTrafficIncidentSelected: (trafficIncident?: TrafficIncidentData) => void;
    onTruckWarningSelected: (truckWarning?: TruckWarningData) => void;
    onWeatherStationSelected: (weatherStation?: WeatherStationData) => void;
    onClearSelectedRoadCondition: () => void;
    onTrafficLayerSelected: (trafficLayer: string) => void;
    onMapSelected: (selectedMapCategory: ImpactMapSection, selectedMapType: MapType) => void;
    openedPage: (page: string) => void;

    loadLocation: (locationId: string) => void;
    loadRoute: (routeId: string) => void;
    loadGovernmentalAlertsIfNeeded: () => void;
}

export const ClientDashboardPage = ({
    userData, selectedView, selectedRoute, selectedCityState, selectedCity, selectedVehicle, selectedOverlay,
    selectedCyclone, selectedEarthquake, selectedLightning, selectedStorm, selectedVolcano, selectedFire, selectedWildfire,
    selectedRoadStatus, selectedRoadWork, selectedRoadClosure, selectedSpecialEvent, selectedTrafficCongestion,
    selectedTrafficIncident, selectedTruckWarning, selectedWeatherStation,
    weatherData, ratingsData, blurbs, weatherTilesets, impactTilesets,
    isCyclonesVisible, isEarthquakesVisible, isLightningVisible, isStormsVisible, isVolcanoesVisible, isFiresVisible, isWildfiresVisible,
    isRoadStatusVisible, isRoadWorkVisible, isRoadClosuresVisible, isSpecialEventsVisible, isTrafficCongestionVisible, isTrafficIncidentsVisible, isTruckWarningsVisible, isWeatherStationsVisible,
    cyclones, earthquakes, lightning, storms, volcanoes, fires, wildfires,
    roadStatus, roadWork, roadClosures, specialEvents, trafficCongestion, trafficIncidents, truckWarnings, weatherStations,
    showDisruptionIndex, showWildfireIndices, tileOpacity, governmentalAlerts,
    setSelectedView, setSelectedRoute, setSelectedOverlay, onCitySelected, onCitySaved, onCityUnsaved,
    onCitySaveErrorDismissed, onSetTileOpacity, onVehicleSelected, onVehicleMessageRequested,
    onRouteRefreshRequested, onRouteRefreshErrorDismissed,
    onLoadEvents, onLoadRoadStatus,
    onCyclonesToggled, onEarthquakesToggled, onLightningToggled, onStormsToggled, onVolcanoesToggled, onFiresToggled,
    onWildfiresToggled, onCycloneSelected, onEarthquakeSelected,
    onLightningSelected, onStormSelected, onVolcanoSelected, onFireSelected, onWildfireSelected, onClearSelectedEvent,
    onRoadStatusSelected, onRoadWorkSelected, onRoadClosureSelected, onSpecialEventSelected, onTrafficCongestionSelected,
    onTrafficIncidentSelected, onTruckWarningSelected, onWeatherStationSelected, onClearSelectedRoadCondition,
    onMapSelected, loadLocation, loadRoute, onTrafficLayerSelected,
    loadGovernmentalAlertsIfNeeded, openedPage,
}: Props) => {
    const [zoomLevel, setZoomLevel] = React.useState(9);
    const [selectedTimeframeIndex, setSelectedTimeframeIndex] = React.useState(0);
    const [selectedTimelineView, setSelectedTimelineView] = React.useState<TimelineView>('impact');

    const [showOnlyHighImpactLocations, setShowOnlyHighImpactLocations] = React.useState(false);
    const [filterNoImpactVehicles, setFilterNoImpactVehicles] = React.useState(true);

    const [startPortalTour, setStartPortalTour] = React.useState(false);

    const [legendCollapsed, setLegendCollapsed] = React.useState(false);
    const [showAlertDetails, setShowAlertDetails] = React.useState(false);

    const mapCategories = getClientImpactSections(showDisruptionIndex, showWildfireIndices);
    const ratingMapTypes = mapCategories[0].mapTypes;
    const weatherMapTypes = mapCategories[1].mapTypes;
    const radarMapTypes = getClientRadarSection().mapTypes;
    const [selectedMapType, setSelectedMapType] = React.useState<ImpactMapType | undefined>(undefined);
    const [paused, setPaused] = React.useState<boolean>(false);
    const timerRef = React.useRef<NodeJS.Timeout | undefined>(undefined);

    const [nearbyLightning, setNearbyLightning] = React.useState<NearbyLightning | undefined>(undefined);

    const [polylineType, setPolylineType] = React.useState<PolylineType>('slowdown');

    const history = useHistory();
    const hasNoSearchString = window.location.search.length === 0;
    // if we have no search string, then we have "processed" it
    const [processedRouteParams, setProcessedRouteParams] = React.useState(hasNoSearchString);
    const [processedLocationParams, setProcessedLocationParams] = React.useState(hasNoSearchString);
    const [processedTileParams, setProcessedTileParams] = React.useState(hasNoSearchString);

    const [mapBounds, setMapBounds] = React.useState<Bounds | undefined>(undefined);
    const [, setVisibleTileset] = React.useState<ImpactTileset | undefined>(undefined);
    const [desiredTileset, setDesiredTileset] = React.useState<ImpactTileset | undefined>(undefined);

    const [floodChanceGeojsonLayers, setFloodChanceGeojsonLayers] = React.useState<GeoJSONLayer[]>([]);

    const [showModelForecastTracksForCycloneId, setShowModelForecastTracksForCycloneId] = React.useState<string | undefined>(undefined);

    const isOnRoutesTab = selectedView === 'route';
    const isOnLocationsTab = selectedView === 'location';
    const isOnVehiclesTab = selectedView === 'vehicle';
    const shouldShowRouteVehiclePairs = Config.getBoolean(Config.Key.ShowRouteVehiclePairs);

    React.useEffect(() => {
        openedPage('dashboard');
    }, []);

    React.useEffect(() => {
        function storageUpdate() {
            if (!Config.getBoolean(Config.Key.PortalTourComplete) && Config.getBoolean(Config.Key.ShownWelcomeDialog)) {
                setStartPortalTour(true);
            }
        }

        onLoadEvents();
        onLoadRoadStatus();

        const urlParams = new URLSearchParams(window.location.search);
        const mapLayer = urlParams.get('map_layer');
        if (mapLayer !== null) {
            // handle map layer deeplinks
            setSelectedOverlay(analyticsTermToDashboardOverlay(mapLayer));
        } else {
            setSelectedOverlay('impact');
        }

        // explictly check if the tour should be started even if there wasnt a local storage update
        storageUpdate();
        document.addEventListener('localStorageUpdated', storageUpdate);

        return () => {
            document.removeEventListener('localStorageUpdated', storageUpdate);
        };
    }, []);

    React.useEffect(() => {
        if (selectedOverlay === undefined) return;

        trackEvent('Dashboard Map Layer Selected', { overlay: dashboardOverlayToAnalyticsTerm(selectedOverlay) });
    }, [selectedOverlay]);

    // clear selected route when going to locations view
    React.useEffect(() => {
        if (isOnLocationsTab || (!shouldShowRouteVehiclePairs && isOnVehiclesTab)) {
            setSelectedRoute(undefined);
        }
    }, [selectedView, setSelectedRoute]);

    React.useEffect(() => {
        // clear out any auto playback timer
        clearTimeout(timerRef.current);

        if (selectedMapType === undefined) {
            console.log('[Dashboard] selectedMapType is undefined: setting desired tileset to undefined');
            setDesiredTileset(undefined);
            return;
        }

        onMapSelected(selectedOverlay === 'rating' ? mapCategories[0] : mapCategories[1], selectedMapType);

        // try to keep same time index
        const tilesets = getTilesets(selectedMapType);
        let newDesiredTileset: ImpactTileset | undefined = undefined;
        if (tilesets && desiredTileset) {
            newDesiredTileset = TilesetUtils.getTileset(tilesets, desiredTileset.time);
        }
        console.log('[Dashboard] selectedMapType effect: setting desired tileset to', newDesiredTileset ?? tilesets?.[0]);
        setDesiredTileset(newDesiredTileset ?? tilesets?.[0]);
    }, [selectedMapType]);

    React.useEffect(() => {
        if (selectedOverlay !== 'lightning') {
            onLightningSelected(undefined);
        }

        if (selectedOverlay && ['radar', 'rating', 'weather'].includes(selectedOverlay)) {
            resume();
        }
    }, [selectedOverlay]);

    React.useEffect(() => {
        if (selectedOverlay !== 'lightning' || selectedCity === undefined) {
            setNearbyLightning(undefined);
            return;
        }

        let within10 = 0;
        let within20 = 0;
        let within30 = 0;
        let closestStrike: LightningData | undefined = undefined;
        let closestDistance: number | undefined = undefined;
        const thirtyMinsAgo = moment().subtract(30, 'm');
        lightning?.forEach((strike: LightningData) => {
            if (moment(strike.detectedTime) < thirtyMinsAgo) {
                return;
            }

            const point = getPointForEvent(strike);
            const distance = haversineDistance({ lat: selectedCity.latitude, lng: selectedCity.longitude }, { lat: point.latitude, lng: point.longitude }, true);
            if (distance <= 30) {
                if (distance <= 10) {
                    within10 += 1;
                } else if (distance <= 20) {
                    within20 += 1;
                } else if (distance <= 30) {
                    within30 += 1;
                }

                if (closestDistance === undefined || closestDistance > distance) {
                    closestDistance = distance;
                    closestStrike = strike;
                }
            }
        });
        setNearbyLightning({
            within10Miles: within10,
            within20Miles: within20,
            within30Miles: within30,

            closestStrike,
            closestDistance,
        });
    }, [selectedCity, selectedOverlay, lightning]);

    const filteredLocations: LocationData[] = React.useMemo(() => {
        if (!isOnLocationsTab || userData?.cities === undefined) {
            return [];
        }
        if (selectedTimelineView === 'impact' && showOnlyHighImpactLocations) {
            return userData.cities.filter(location => (location.impactSummaries?.at(selectedTimeframeIndex)?.impactLevel === ImpactLevel.High));
        }
        return userData.cities;
    }, [userData?.cities, selectedView, selectedTimeframeIndex, showOnlyHighImpactLocations, selectedTimelineView]);

    const filteredVehicles: VehicleTrackingData[] = React.useMemo(() => {
        if (!isOnVehiclesTab || userData?.vehicles === undefined) {
            return [];
        }
        if (filterNoImpactVehicles) {
            return userData.vehicles.filter(vehicle => (vehicle.currentImpact?.tags[0]?.text?.toLowerCase() !== 'none' || vehicle.id === selectedVehicle?.id));
        }
        return userData.vehicles;
    }, [userData?.vehicles, selectedView, filterNoImpactVehicles]);
    // for the visible vehicles on the map, we use vehicles unless we're on the routes tab
    // then we show the selected vehicle if its available
    const visibleVehicles: VehicleTrackingData[] = React.useMemo(() => {
        if (isOnVehiclesTab) {
            return filteredVehicles;
        } else if (shouldShowRouteVehiclePairs && isOnRoutesTab && selectedVehicle !== undefined && selectedVehicle?.id === selectedRoute?.vehicleData?.id) {
            return [selectedVehicle];
        } else {
            return [];
        }
    }, [filteredVehicles, selectedVehicle, selectedView, selectedRoute]);

    const timeframes: Timeframe[] = [
        { label: 'today', name: 'Today', startTime: moment().startOf('day'), endTime: moment().add(1, 'day').startOf('day') },
        { label: 'tomorrow', name: 'Tomorrow', startTime: moment().add(1, 'day').startOf('day'), endTime: moment().add(2, 'days').startOf('day') },
        { label: 'day3_7', name: 'Days 3-7', startTime: moment().add(2, 'days').startOf('day'), endTime: moment().add(1, 'week') },
    ];

    const impactSummaryData = timeframes.map(timeframe => {
        const routes = userData !== undefined ? filterRoutes(userData.savedRoutes, timeframe) : [];
        const impactedRoutes = routes.filter(route => {
            return route.latestRouteResults && findImpactLevel(route.latestRouteResults[0]) > ImpactLevel.Low;
        });

        let routesFraction = impactedRoutes.length / routes.length;
        if (isNaN(routesFraction)) {
            routesFraction = 0;
        }

        const impactedCities = userData !== undefined ? userData.cities.filter(city => {
            const summary = city.impactSummaries.find(s => s.label === timeframe.label);
            return summary !== undefined && summary.impactLevel > ImpactLevel.Low;
        }) : [];
        let citiesFraction = userData !== undefined ? impactedCities.length / userData.cities.length : 0;
        if (isNaN(citiesFraction)) {
            citiesFraction = 0;
        }

        const labelForFraction = (fraction: number) => {
            let percentage = fraction * 100;
            let effectivePercentage = percentage;
            let prefix = '';
            if (percentage > 0 && percentage < 1) {
                // show <1% for small amounts of impacted locations - not zero
                effectivePercentage = 1;
                prefix = '<';
            }
            return `${prefix}${Math.round(effectivePercentage)}%`;
        };

        return {
            heading: timeframe.name,
            routesFraction: Math.max(0.01, routesFraction),
            routesLabel: labelForFraction(routesFraction),
            locationsFraction: Math.max(0.01, citiesFraction),
            locationsLabel: labelForFraction(citiesFraction)
        };
    });

    let polylines: any = undefined;
    if (selectedRoute?.latestRouteResults !== undefined) {
        polylines = [];
        // reversing the order of the route options polylines so when they overlap they are drawn with lower options on top (option 1 on top of option 2)
        // when the overlapping segment of polylines is clicked the top option is selected
        selectedRoute?.latestRouteResults.slice().reverse().forEach((routeResult, i) => {
            // the index needs to reversed since the iteration is reversed so i = 0 is the last route option
            // realRouteIndex is 1-indexed up to length
            const realRouteIndex = selectedRoute!.latestRouteResults!.length - i;
            if (polylineType === 'slowdown') {
                routeResult.slowdownPolylines.forEach((polyline) => {
                    const route = { ...selectedRoute };
                    route.selectedRouteOption = `${selectedRoute.id} Option ${realRouteIndex}`;
                    polylines!.push({ ...polyline, route: route });
                });
            } else {
                routeResult.roadRiskPolylines?.forEach((polyline) => {
                    const route = { ...selectedRoute };
                    route.selectedRouteOption = `${selectedRoute.id} Option ${realRouteIndex}`;
                    polylines!.push({ ...polyline, route: route });
                });
            }

        });
    }

    // let displayAlertTypes = alertTypes.map(alertType => {
    //     return (
    //         <div key={alertType.name} className={"dashboard-alert"}>
    //             <div style={{ backgroundColor: alertType.color, width: 16, height: 16, borderRadius: 8 }} />
    //             <div>{alertType.name}</div>
    //         </div>
    //     );
    // });

    // const columns = [];
    // const columnLength = Math.ceil(displayAlertTypes.length / 3);
    // for (let i = 0; i < displayAlertTypes.length; i += columnLength) {
    //     const chunk = displayAlertTypes.slice(i, i + columnLength);
    //     columns.push((<div className={"dashboard-alert-column"}>{chunk}</div>));
    // }

    // let alertsLegend = (
    //     <div className={"dashboard-alerts-legend"} style={{ backgroundColor: cardBackgroundColor }}>
    //         <div className={"dashboard-alerts-legend-body"}>
    //             {columns.length === 0 ? "No alerts for this area." : columns}
    //         </div>
    //     </div>
    // );

    let alertsLegend = (userData?.portalToken && <AlertsLegend
        portalToken={userData.portalToken}
        mapBounds={mapBounds}
        className="dashboard"
    />);

    const getTilesets = (selectedMapType: MapType): ImpactTileset[] | undefined => {
        let mapType = selectedMapType as ImpactMapType;
        let tilesets: ImpactTileset[] | undefined = impactTilesets && impactTilesets[mapType.ratingKey];
        if (tilesets === undefined) {
            tilesets = weatherTilesets && weatherTilesets[mapType.ratingKey];
        }
        if (!tilesets) {
            return undefined;
        }
        if (selectedMapType.ratingKey === 'radar') {
            return tilesets;
        }

        const currentTime = new Date().getTime();
        const startTime = Math.floor(currentTime / 3600000) * 3600000;
        let forecastHours = 168;
        if (selectedMapType.ratingKey === 'wildfire_spread') {
            forecastHours = 1;
        } else if (selectedMapType.ratingKey === 'wildfire_conditions') {
            forecastHours = 144;
        }

        const endTimeExclusive = startTime + forecastHours * 3600000;
        // chop off any times from the model run that are before now or later than endTimeExclusive hour from now
        return tilesets.filter((tileset: ImpactTileset) => tileset.time.getTime() >= startTime && tileset.time.getTime() < endTimeExclusive);
    };

    let displayedTime: Date | undefined = desiredTileset?.time;
    let radarTimer: JSX.Element | undefined = undefined;
    let tilingTimer: JSX.Element | undefined = undefined;
    let pauseResumeButton: JSX.Element | undefined = undefined;

    const tilesets = selectedMapType && getTilesets(selectedMapType);
    if (selectedMapType && tilesets && tilesets.length > 0) {
        if (selectedOverlay === 'radar' && (selectedView === "vehicle" || ((selectedView === "route" || selectedView === "location") && selectedTimeframeIndex === 0))) {
            let displayedString = desiredTileset?.time?.toLocaleTimeString('en-US', {
                hour: 'numeric',
                hourCycle: 'h12',
                minute: 'numeric',
                timeZoneName: 'short'
            });
            radarTimer = (<div>{displayedString}</div>);
        } else {
            radarTimer = (<div>Can only be viewed for Today</div>);
        }

        if (selectedOverlay === 'weather' || selectedOverlay === "rating") {
            if (desiredTileset === undefined) {
                tilingTimer = (<div></div>);
            } else {
                const displayedDateString = displayedTime?.toLocaleDateString('en-US', {
                    weekday: 'short',
                    month: 'short',
                    day: 'numeric'
                });
                const displayedTimeString = displayedTime?.toLocaleTimeString('en-US', {
                    hour: 'numeric',
                    hourCycle: 'h12',
                    timeZoneName: 'short'
                });
                tilingTimer = (
                    <Tooltip title={displayedTime?.toISOString() ?? ""}>
                        <div style={{ textAlign: 'center' }}>
                            {displayedDateString}
                            <br />
                            {displayedTimeString}
                        </div>
                    </Tooltip>
                );
            }
        }

        if (paused) {
            pauseResumeButton = (
                <button className={'resume-button time-control-button'} disabled={displayedTime === undefined} onClick={() => resume()}>
                    <img alt={'play'} src={'/images/play.svg'} />
                </button>
            );
        } else {
            pauseResumeButton = (
                <button className={'pause-button time-control-button'} disabled={displayedTime === undefined} onClick={() => pause()}>
                    <img alt={'pause'} src={'/images/pause.svg'} />
                </button>
            );
        }
    }

    const radarLegends = [{
        label: 'Rain', image: 'Radar_TWC_Rain'
    }, {
        label: 'Snow', image: 'Radar_TWC_Snow'
    }];
    let radarLegendElements: JSX.Element | JSX.Element[][] = radarLegends.map(legend => {
        return [
            (<img style={{ "width": "100%", maxWidth: '450px' }} key={legend.label} alt={legend.label} src={`/legends/${legend.image}.svg`} />)
        ];
    });

    const radarLegend = (<div style={{ "padding": "15px 25px 25px 25px" }}>
        <div>{radarLegendElements}</div>
        <div style={{ "paddingTop": "5px" }}>{radarTimer}</div>
        <div style={{ "marginTop": "16px", "marginRight": "15px", "display": "flex", "fontWeight": "bold" }}>
            <div style={{ "paddingRight": "5px" }}>
                <button className={'rewind-button time-control-button'} disabled={displayedTime === undefined} onClick={() => rewind()}>
                    <img alt={'rewind'} src={'/images/rewind.svg'} />
                </button>
                {pauseResumeButton}
                <button className={'fast-forward-button time-control-button'} disabled={displayedTime === undefined} onClick={() => fastForward()}>
                    <img alt={'fast-forward'} src={'/images/fast-forward.svg'} />
                </button>
            </div>
        </div>
    </div>);

    const trafficLegend = <TrafficLegend
        selectedOverlay={selectedOverlay ?? 'traffic'}
        zoomLevel={zoomLevel}
        show511InPortal={userData?.show511InPortal}
        onOverlayChanged={(overlay) => onOverlayChanged(overlay)}
    />;

    const eventsLegend = (
        <div className={"dashboard-alerts-legend"} style={{ backgroundColor: cardBackgroundColor, display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', marginLeft: '20px', marginRight: '20px' }}>
            <div>
                <FormGroup>
                    <FormControlLabel className={'switch'} control={<Switch checked={isCyclonesVisible} onChange={event => {
                        onCyclonesToggled(event.target.checked);
                        // when disabling cyclones, we want to disable any model forecast tracks
                        if (!event.target.checked) {
                            setShowModelForecastTracksForCycloneId(undefined);
                        }
                    }} />} label="Cyclones" labelPlacement="start" />
                    <FormControlLabel className={'switch'} control={<Switch checked={isEarthquakesVisible} onChange={event => onEarthquakesToggled(event.target.checked)} />} label="Earthquakes" labelPlacement="start" />
                </FormGroup>
            </div>
            <div>
                <FormGroup>
                    <FormControlLabel className={'switch'} control={<Switch checked={isStormsVisible} onChange={event => onStormsToggled(event.target.checked)} />} label="Storms" labelPlacement="start" />
                    <FormControlLabel className={'switch'} control={<Switch checked={isVolcanoesVisible} onChange={event => onVolcanoesToggled(event.target.checked)} />} label="Volcanoes" labelPlacement="start" />
                </FormGroup>
            </div>
            <div>
                <FormGroup>
                    <FormControlLabel className={'switch'} control={<Switch checked={isFiresVisible} onChange={event => onFiresToggled(event.target.checked)} />} label="Fires" labelPlacement="start" />
                    <FormControlLabel className={'switch'} control={<Switch checked={isWildfiresVisible} onChange={event => onWildfiresToggled(event.target.checked)} />} label="US Wildfires" labelPlacement="start" />
                </FormGroup>
            </div>
        </div>
    );

    const lightningBlurbs: Blurb[] = [30, 25, 20, 15, 10, 5, 0].map(minutesAgo => {
        return {
            blurb: '',
            color: '#00A6CA' + Math.floor(calculateLightningOpacityFromAge(minutesAgo) * 255).toString(16).padStart(2, '0'),
            value: minutesAgo,
        };
    });
    const lightningLegend = (
        <div className={"dashboard-alerts-legend"} style={{ backgroundColor: cardBackgroundColor, paddingTop: '10px', display: 'flex', flexDirection: 'column' }}>
            <div style={{ padding: '10px 10px 0px 10px', display: 'flex' }}>
                <div style={{ margin: '10px', justifyContent: 'start', display: 'flex', }}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography sx={{ fontSize: 16, fontWeight: 'bold' }}>Lightning Distance</Typography>
                        <Typography sx={{ fontSize: 16, fontWeight: 'bold' }}>and Count</Typography>
                    </div>
                    {nearbyLightning && <div style={{ display: 'flex' }}>
                        <div style={{ marginLeft: '30px', display: 'flex', flexDirection: 'column' }}>
                            <Typography sx={{ textAlign: 'center', fontSize: 16 }}>&lt; 10 miles</Typography>
                            <Typography sx={{ textAlign: 'center', fontSize: 16, color: "red" }}>{nearbyLightning.within10Miles} strikes</Typography>
                        </div>
                        <div style={{ marginLeft: '30px', display: 'flex', flexDirection: 'column' }}>
                            <Typography sx={{ textAlign: 'center', fontSize: 16 }}>&lt; 20 miles</Typography>
                            <Typography sx={{ textAlign: 'center', fontSize: 16, color: "orange" }}>{nearbyLightning.within20Miles} strikes</Typography>
                        </div>
                        <div style={{ marginLeft: '30px', display: 'flex', flexDirection: 'column' }}>
                            <Typography sx={{ textAlign: 'center', fontSize: 16 }}>&lt; 30 miles </Typography>
                            <Typography sx={{ textAlign: 'center', fontSize: 16, color: "yellow" }}>{nearbyLightning.within30Miles} strikes</Typography>
                        </div>
                        {nearbyLightning.closestStrike && nearbyLightning.closestDistance && <div style={{ marginLeft: '30px', display: 'flex', flexDirection: 'column', borderLeft: 'thin solid #919191', paddingLeft: '30px' }}>
                            <Typography sx={{ textAlign: 'center', fontSize: 16 }}>Closest</Typography>
                            <Typography sx={{ textAlign: 'center', fontSize: 16, color: nearbyLightning.closestDistance <= 10 ? "red" : nearbyLightning.closestDistance <= 20 ? "orange" : "yellow" }}>{nearbyLightning.closestDistance.toFixed(1)} miles away</Typography>
                        </div>}
                    </div>}
                    {!nearbyLightning && <div style={{ padding: '0px 40px', textAlign: 'center', alignContent: 'center' }}>
                        <Typography sx={{ fontSize: 16, color: '#919191' }}>Select a location to see nearby lightning</Typography>
                    </div>}
                </div>
            </div>
            <div style={{ margin: '0 20px 10px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography sx={{ fontSize: 12, color: '#919191' }}>Data shown for the last 30 minutes</Typography>
                <div className={'impact-tile-legend'}>
                    <LegendComponent
                        blurbs={lightningBlurbs}
                        skipFirstBlurb={false}
                        embedValues={true}
                        legendTitle="Minutes ago"
                    />
                </div>
            </div>
        </div>
    );

    let tileLegendElements: JSX.Element | undefined = undefined;
    if (Object.keys(blurbs).length > 0) {
        let mapTimeControls: JSX.Element | undefined = undefined;
        if (selectedMapType && tilesets) {
            const times = tilesets.map(x => x.time);
            const matchingTimeIndex = findIndexWithDefaultValue(times, x => displayedTime?.getTime() === x.getTime(), 0);
            mapTimeControls = (
                <div style={{ "minWidth": "280px", "marginLeft": "40px", "marginTop": "auto", marginBottom: "auto" }}>
                    <div style={{ "display": "flex", 'marginRight': '20px', "flexDirection": "row", "alignContent": "center" }}>
                        <div style={{ "marginRight": "20px", minWidth: '80px' }}>{tilingTimer}</div>
                        <div style={{ 'display': 'flex', 'flexDirection': 'column', 'alignItems': 'center', 'justifyContent': 'center' }}>
                            <div style={{ "display": "flex", 'width': '150px', "justifyContent": "center", "alignContent": "center" }}>
                                <button className={'skip-to-first-button time-control-button'} disabled={displayedTime === undefined} onClick={() => skipToFirst()}>
                                    <img alt={'skip-to-first'} src={'/images/skip-to-first.svg'} />
                                </button>
                                <button className={'rewind-button time-control-button'} disabled={displayedTime === undefined} onClick={() => rewind()}>
                                    <img alt={'rewind'} src={'/images/rewind.svg'} />
                                </button>
                                {pauseResumeButton}
                                <button className={'fast-forward-button time-control-button'} disabled={displayedTime === undefined} onClick={() => fastForward()}>
                                    <img alt={'fast-forward'} src={'/images/fast-forward.svg'} />
                                </button>
                                <button className={'skip-to-last-button time-control-button'} disabled={displayedTime === undefined} onClick={() => skipToLast()}>
                                    <img alt={'skip-to-last'} src={'/images/skip-to-last.svg'} />
                                </button>
                            </div>
                            <div style={{ 'display': 'flex', 'width': '150px', 'alignItems': 'center', 'justifyContent': 'center' }}>
                                <Slider
                                    className={'slider'}
                                    min={0}
                                    max={tilesets.length - 1}
                                    step={1}
                                    defaultValue={0}
                                    value={matchingTimeIndex}
                                    onChange={(value: number) => goToDate(times[value])}
                                    trackStyle={{ backgroundColor: "rgba(4, 160, 253, 0.8)", height: 5 }}
                                    railStyle={{ backgroundColor: "rgba(4, 160, 253, 0.3)", height: 5 }}
                                    handleStyle={{ borderColor: "transparent", borderWidth: 1, backgroundColor: "rgb(0, 147, 255)", width: 9, height: 9, marginLeft: -4, marginTop: -2 }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        const currentMaps = selectedOverlay === 'rating' ? ratingMapTypes : weatherMapTypes;
        tileLegendElements = (
            <div>
                <div style={{ "display": "flex", "flexDirection": "row", "alignContent": "center", "justifyContent": "space-between" }}>
                    <div className={'impact-tile-legend'} style={{ "padding": "10px 15px 10px 22px" }}>
                        <Select
                            value={selectedMapType !== undefined ? selectedMapType.title : currentMaps[0].title}
                            variant={'outlined'}
                            style={{ width: 235, height: 40 }}
                            onChange={(event) => setSelectedMapType(currentMaps.filter(map => event.target.value === map.title)[0] as ImpactMapType)}
                        >
                            {currentMaps.map(map => <MenuItem key={map.title} value={map.title}>{map.title}</MenuItem>)}
                        </Select>
                    </div>
                    {tilesets && tilesets.length > 1 && mapTimeControls}
                </div>
                <div className={'impact-tile-legend'} style={{ "padding": "10px 15px 10px 0px" }}>
                    <LegendComponent
                        ratingKey={selectedMapType?.ratingKey}
                        blurbs={selectedMapType !== undefined ? blurbs[selectedMapType.ratingKey] : []}
                        skipFirstBlurb={true}
                        embedValues={isImpactKey(selectedMapType?.ratingKey)}
                        tileOpacity={tileOpacity}
                        setTileOpacity={(tileOpacity) => onSetTileOpacity(tileOpacity)}
                    />
                </div>
            </div>
        );
    }

    const tileLegend = (<div style={{ "padding": "10px 15px 15px 0px" }}>
        <div>{tileLegendElements}</div>
    </div>);

    const roadStatusLegend = (
        <div className={"dashboard-alerts-legend"} style={{ "padding": "10px 15px 15px 0px", backgroundColor: cardBackgroundColor }}>
            <div>
                <div style={{ "padding": "0px 25px 15px 25px", backgroundColor: cardBackgroundColor }}>
                    <RoadStatusLegendComponent />
                </div>
            </div>
        </div>
    );

    const showEvents = userData?.showEventsInPortal ?? false;

    const legendContainer: JSX.Element = (
        // 544px = 80px (width of left tabs) + 12px (margin) + 12px (margin) + 450px (pop up width when clicked)
        <Paper elevation={3} style={{ backgroundColor: cardBackgroundColor, borderRadius: 11, position: 'absolute', top: 8, left: 12, minWidth: 450, maxWidth: 'min(700px, calc(100% - 544px))', backgroundImage: 'none' }}>
            <header style={{ display: "flex", justifyContent: "space-between" }}>
                <Tabs
                    className="DashboardTabs"
                    value={selectedOverlay?.startsWith('traffic') ? selectedOverlay?.split("-")[0] : selectedOverlay}
                    onChange={(event, newOverlay) => onOverlayChanged(newOverlay)}
                    indicatorColor={'primary'}
                    textColor={'primary'}
                    variant="scrollable"
                    scrollButtons="auto"
                    style={{ height: 40 }}
                >
                    <Tab label="Summary" value="impact" />
                    <Tab label="Alerts" value="alerts" />
                    <Tab label="Radar" value="radar" />
                    <Tab label="Traffic" value="traffic" />
                    <Tab label="Lightning" value="lightning" />
                    {showEvents && <Tab label="Events" value="events" />}
                    <Tab label="Hyperlocal Weather" value="weather" />
                    <Tab label="Impact Indices" value="rating" />
                    {/**
                    * restrict in production to only Air Force (2684)
                    */}
                    {isValidUserForFeature(userData?.id, { 'production': [ClientId.Production.AFB] }) && <Tab label="Flood Chance" value="flood-chance" />}
                </Tabs >
                <IconButton
                    color={'primary'}
                    onClick={() => setLegendCollapsed(!legendCollapsed)}
                    style={{ height: 40 }}
                >
                    {legendCollapsed ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
                </IconButton>
            </header >

            {!legendCollapsed && selectedOverlay === 'impact' && <ImpactSummary attributesArray={impactSummaryData} />}
            {!legendCollapsed && selectedOverlay === 'alerts' && alertsLegend}
            {!legendCollapsed && selectedOverlay === 'radar' && radarLegend}
            {!legendCollapsed && selectedOverlay?.startsWith('traffic') && trafficLegend}
            {!legendCollapsed && selectedOverlay === 'lightning' && lightningLegend}
            {!legendCollapsed && selectedOverlay === 'events' && eventsLegend}
            {!legendCollapsed && selectedOverlay === 'weather' && tileLegend}
            {!legendCollapsed && selectedOverlay === 'rating' && tileLegend}
            {!legendCollapsed && selectedOverlay === 'road-status' && roadStatusLegend}
            {!legendCollapsed && selectedOverlay === 'flood-chance' && <FloodChanceLegend userData={userData} setFloodChanceGeojsonLayers={setFloodChanceGeojsonLayers} />}

        </Paper >
    );

    let weatherCalloutView: JSX.Element | undefined = undefined;
    if (selectedCityState && selectedCity && (selectedOverlay === 'lightning' || (selectedMapType && (selectedOverlay === 'weather' || selectedOverlay === 'rating')))) {
        // get the value for the callout view for either the displayed time or the current time
        let valueTime: Date = new Date();
        let selectedImpact = undefined;
        let lightningProbability = undefined;
        let valueForDisplayedTime = undefined;
        let calloutImpactKey = undefined;
        if ((selectedOverlay === 'lightning' || (selectedMapType && (selectedOverlay === 'weather' || selectedOverlay === 'rating')))) {
            // get the value for the callout view for either the displayed time or the current time
            if (selectedOverlay === 'lightning') {
                lightningProbability = weatherData?.subhourly && weatherData?.subhourly[0].lightningProbability;
                // round to last 15 min for lightning (subhourly)
                valueTime.setMinutes(Math.floor(valueTime.getMinutes() / 15) * 15, 0, 0);
                valueForDisplayedTime = weatherData?.subhourly?.find(subhour => subhour.time.getTime() === valueTime.getTime());
                if (valueForDisplayedTime) {
                    lightningProbability = valueForDisplayedTime.lightningProbability;
                }
            } else if (selectedMapType) {
                if (displayedTime !== undefined) {
                    valueTime = displayedTime;
                } else {
                    // round to last houor for hourly
                    valueTime.setMinutes(0);
                }
                if (ratingsData !== undefined && isImpactKey(selectedMapType.ratingKey)) {
                    valueForDisplayedTime = ratingsData[selectedMapType.ratingKey].find((hour: any) => hour.time.getTime() === valueTime.getTime());
                    if (valueForDisplayedTime) {
                        selectedImpact = valueForDisplayedTime.value;
                    }
                } else if (weatherData !== undefined) {
                    valueForDisplayedTime = weatherData?.hourly?.find(hour => hour.time.getTime() === valueTime.getTime());
                    if (valueForDisplayedTime) {
                        selectedImpact = valueForDisplayedTime[snakeToCamelCase(selectedMapType.ratingKey)];
                    }
                }
            }
            calloutImpactKey = selectedOverlay === 'lightning' ? 'lightningProbability' : selectedMapType?.ratingKey as string;
        }
        weatherCalloutView = (
            <WeatherCalloutView
                selectedCityState={selectedCityState}
                lightningProbability={lightningProbability}
                selectedImpact={selectedImpact}
                impactKey={calloutImpactKey}

                lat={selectedCity.latitude}
                lng={selectedCity.longitude}

                onClose={() => onCitySelected(undefined)}
                saveLocationTapped={(city) => onCitySaved(city)}
                unsaveLocationTapped={(city) => onCityUnsaved(city)}
                onCitySaveErrorDismissed={(city) => onCitySaveErrorDismissed(city)}
            />
        );
    }

    const onMapClicked = (coord: google.maps.LatLng) => {
        let city: LocationData = {
            id: undefined,
            zip: '00000',
            name: '',
            latitude: coord.lat(),
            longitude: coord.lng(),
            needsGeocoding: true,
            impactSummaries: []
        };

        onCitySelected(city);
        if (selectedOverlay === 'alerts') setShowAlertDetails(true);
    };

    let alertDetailElement: JSX.Element | undefined = undefined;
    if (showAlertDetails && selectedCity && weatherData && weatherData.alerts?.length > 0) {
        alertDetailElement = (
            <AlertDetailsComponent
                alerts={weatherData.alerts}
                portalTab="dashboard"
                setShowAlertDetails={setShowAlertDetails}
            />);
    }

    const onOverlayChanged = (overlay: DashboardOverlay) => {
        // if we changed the overlay, any timed tile load is no longer relevant
        clearTimeout(timerRef.current);

        if (overlay === 'weather') {
            setSelectedMapType(weatherMapTypes[0] as ImpactMapType);
        } else if (overlay === 'rating') {
            setSelectedMapType(ratingMapTypes[0] as ImpactMapType);
        } else if (overlay === 'radar') {
            setSelectedMapType(radarMapTypes[0] as ImpactMapType);
        } else {
            setSelectedMapType(undefined);
            // we only set the desired tileset to undefined in this case
            // because if we're switching to a different overlay with tilesets
            // we want to try to match the existing time to the new tileset
            setDesiredTileset(undefined);
        }

        if (overlay.includes('traffic')) {
            onTrafficLayerSelected(overlay);
        }

        if (overlay !== 'traffic-incidents') {
            onTrafficIncidentSelected(undefined);
        }

        onLightningToggled(overlay === 'lightning');
        if (overlay !== 'rating')
            onWildfiresToggled(false);

        if (overlay === 'alerts') {
            loadGovernmentalAlertsIfNeeded();
        }

        setSelectedOverlay(overlay);
    };

    const goToPreviousTimeOffset = () => {
        if (!desiredTileset || !selectedMapType) {
            return;
        }
        setDesiredTileset(TilesetUtils.getPreviousTileset(getTilesets(selectedMapType), desiredTileset.time));
    };

    const goToNextTimeOffset = () => {
        if (!desiredTileset || !selectedMapType) {
            return;
        }
        // there shouldn't be a case where we want to go to the next time offset
        // when our selectedMapType variable doesn't match our desired tileset variable
        if (selectedMapType.ratingKey !== desiredTileset.variable) {
            console.log('[Dashboard] skipping goToNextTimeOffset because variables are not matching', selectedMapType, desiredTileset);
            return;
        }
        const nextTileset = TilesetUtils.getNextTileset(getTilesets(selectedMapType), desiredTileset.time);
        console.log('[Dashboard] goToNextTimeOffset for', selectedMapType, 'is loading:', nextTileset);
        setDesiredTileset(nextTileset);
    };

    const goToDate = (date: Date) => {
        pause();
        setDesiredTileset(TilesetUtils.getTileset(tilesets, date));
    };

    const resume = () => {
        clearTimeout(timerRef.current);

        timerRef.current = setTimeout(() => goToNextTimeOffset(), 1000);

        setPaused(false);
    };

    const pause = () => {
        clearTimeout(timerRef.current);
        setPaused(true);
    };

    const rewind = () => {
        pause();
        goToPreviousTimeOffset();
    };

    const fastForward = () => {
        pause();
        goToNextTimeOffset();
    };

    const skipToFirst = () => {
        const tilesets = selectedMapType && getTilesets(selectedMapType);
        if (tilesets) {
            pause();
            setDesiredTileset(tilesets[0]);
        }
    };

    const skipToLast = () => {
        const tilesets = selectedMapType && getTilesets(selectedMapType);
        if (tilesets) {
            pause();
            setDesiredTileset(tilesets[tilesets.length - 1]);
        }
    };

    // wildfires has a special case where we want to show it over the wildfire indices
    const showWildfires = (selectedOverlay === 'events' && isWildfiresVisible) || (selectedMapType?.ratingKey.includes('wildfire') === true);

    // process route url params
    React.useEffect(() => {
        // TODO: do we want to error if any of these are invalid or just ignore
        if (processedRouteParams) return;
        if (!userData) return;

        const urlParams = new URLSearchParams(window.location.search);

        const routeId = urlParams.get('route_id');
        if (routeId !== null) {
            setSelectedView('route');
            const linkedRoute = userData.savedRoutes.find(route => route.id?.toString() === routeId);
            if (!linkedRoute && userData.savedRoutesMetadata.loading) {
                loadRoute(routeId);
                return;
            }

            if (linkedRoute !== undefined) {
                if (moment(linkedRoute.departureTime).isBefore(moment().endOf("day"))) {
                    setSelectedTimeframeIndex(0);
                } else if (moment(linkedRoute.departureTime).isAfter(moment().add(1, 'days').endOf("day"))) {
                    setSelectedTimeframeIndex(2);
                } else {
                    setSelectedTimeframeIndex(1);
                }
                setSelectedRoute(linkedRoute);
            }
        }
        setProcessedRouteParams(true);
    }, [userData?.savedRoutes]);

    // process location url params
    React.useEffect(() => {
        // TODO: do we want to error if any of these are invalid or just ignore
        if (processedLocationParams) return;
        if (!userData) return;

        console.log('processedLocationParams', 'starting');

        const urlParams = new URLSearchParams(window.location.search);

        const locationId = urlParams.get('location_id');
        console.log('processedLocationParams', 'location_id', locationId);
        if (locationId !== null) {
            setSelectedView('location');
            // if we don't find the location and we haven't loaded all the locations yet
            // then load this specific location to see if that finds it
            const linkedLocation = userData.cities.find(city => city.id?.toString() === locationId);
            console.log('processedLocationParams', 'linkedLocation', linkedLocation, 'loading?', userData.citiesMetadata.loading);
            if (!linkedLocation && userData.citiesMetadata.loading) {
                console.log('processedLocationParams', 'loading location id', locationId);
                loadLocation(locationId);
                return;
            }
            if (linkedLocation !== undefined) {
                // noting some current drawbacks:
                // - the locations table won't show the page of the selected city until all cities are loaded
                // - the map won't show the pin of the selected city until all cities are loaded
                onCitySelected(linkedLocation);
            }
        } else {
            const latitude = urlParams.get('latitude');
            const longitude = urlParams.get('longitude');
            if (latitude !== null && longitude !== null) {
                setSelectedView('location');
                const lat: number = parseFloat(latitude);
                const lng: number = parseFloat(longitude);
                if (!isNaN(lat) && !isNaN(lng)) {
                    // fake a map click to select passed in location
                    onMapClicked({ lat: () => lat, lng: () => lng } as google.maps.LatLng);
                }
            }
        }

        const impactTime = urlParams.get('impact_time');
        if (impactTime !== null) {
            const time = new Date(impactTime);
            const timeframeIndex = findTimeframeIndexForTime(time, timeframes);
            if (timeframeIndex) {
                setSelectedTimeframeIndex(timeframeIndex);
            }
        }
        setProcessedLocationParams(true);
    }, [userData?.cities]);

    // process tile url params
    React.useEffect(() => {
        // TODO: do we want to error if any of these are invalid or just ignore
        if (processedTileParams) return;

        const urlParams = new URLSearchParams(window.location.search);

        const mapVariable = urlParams.get('variable');
        console.log('processedTileParams', 'mapVariable:', mapVariable, 'selectedMapType:', selectedMapType);
        // only set a new map type if it's not already set
        // don't think this is strictly necessary but seems like a nice safety check
        if (mapVariable !== null && !selectedMapType) {
            if (isImpactKey(mapVariable)) {
                if (impactTilesets === undefined) return;
                setSelectedOverlay('rating');
                setSelectedMapType(ratingMapTypes.find((map: ImpactMapType) => mapVariable === map.ratingKey) as ImpactMapType);
            }
            if (isWeatherKey(mapVariable)) {
                if (weatherTilesets === undefined) return;
                setSelectedOverlay('weather');
                setSelectedMapType(weatherMapTypes.find((map: ImpactMapType) => mapVariable === map.ratingKey) as ImpactMapType);
            }
        }

        const mapTime = urlParams.get('time');
        console.log('processedTileParams', 'mapVariable:', mapTime, 'selectedMapType:', selectedMapType);
        if (mapTime !== null) {
            // navigate to the correct time after the selectedMapType is present
            // because getIndexForTime uses getTimes which requires selectedMapType
            if (!selectedMapType) {
                return;
            }

            const time = new Date(mapTime!);
            // fallback to the first tileset if the time was not found
            // this is likely because the time was in the past so we choose
            // to pause on the first tileset instead as the earliest data we have
            const desiredTileset = TilesetUtils.getTileset(tilesets, time) ?? tilesets?.[0];
            if (desiredTileset) {
                setDesiredTileset(desiredTileset);
                // pause the playback if we loaded in from a time in the URL
                // we need to do it on the next render loop so that we can pause after
                // some other logic sets it to be unpaused
                setTimeout(() => setPaused(true), 0);
            }
        }

        setProcessedTileParams(true);
    }, [impactTilesets, weatherTilesets, selectedMapType]);

    // updated url params
    React.useEffect(() => {
        if (processedLocationParams === false || processedTileParams === false || processedRouteParams === false) return;
        const urlParams = new URLSearchParams();

        if (selectedRoute?.id !== undefined) {
            urlParams.set('route_id', `${selectedRoute.id}`);
        }

        if (selectedCity?.id !== undefined) {
            urlParams.set('location_id', `${selectedCity.id}`);
        }

        if (selectedOverlay === 'rating' || selectedOverlay === 'weather') {
            let currentMapType: ImpactMapType | undefined = selectedMapType;
            if (currentMapType === undefined) {
                currentMapType = selectedOverlay === 'rating' ? ratingMapTypes[0] as ImpactMapType : weatherMapTypes[0] as ImpactMapType;
            }
            urlParams.set('variable', currentMapType.ratingKey);

            if (currentMapType?.ratingKey !== 'wildfire_spread' && paused === true) {
                const mapTime: Date | undefined = desiredTileset?.time;
                if (mapTime !== undefined) urlParams.set('time', mapTime.toISOString());
            }
        }

        if (selectedOverlay !== undefined) {
            urlParams.set('map_layer', dashboardOverlayToAnalyticsTerm(selectedOverlay));
        }

        const oldParams = window.location.toString().split('?')[1];
        if (oldParams !== urlParams.toString()) {
            history.push(`/client/dashboard?${urlParams.toString()}`);
        }
    }, [selectedRoute, selectedCity, selectedOverlay, selectedMapType, desiredTileset, paused]);

    if (userData === undefined || userData.token === undefined) {
        return <Redirect to={"/logout"} />;
    }

    if (userData.savedRoutes === undefined || userData.cities === undefined) {
        return <div />;
    }

    if (!processedRouteParams || !processedLocationParams || !processedTileParams) {
        return (
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={true}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        );
    }

    const currentStorms = (storms || [])
        .filter(storm => storm.startTime <= timeframes[selectedTimeframeIndex].endTime.toDate() && storm.endTime >= timeframes[selectedTimeframeIndex].startTime.toDate());

    return (
        <div className={'Dashboard'} style={{ position: 'relative', height: '100%' }}>
            <RouteAndMarkerMap
                {...defaultRouteAndMarkerMapProps}

                portalToken={userData.portalToken}
                selectedCity={selectedCity}
                selectedVehicle={selectedVehicle}
                // TODO: this doesn't work for multiple reasons:
                // - the output of bbox is a struct which doesn't have getCenter() which RAMM uses
                // - if you fix that, then the bounds are locked in so you can't change the bounds
                // initialBounds={selectedOverlay === 'flood-chance' && floodChanceGeojsonLayers.length > 0 ? bbox({ type: 'FeatureCollection', features: floodChanceGeojsonLayers.map(l => l.geojsonData) }) : undefined}

                // initially show the whole conus
                initialBounds={{ sw: { lat: 24.396308, lng: -125.0 }, ne: { lat: 49.384358, lng: -66.93457 } }}
                zoomLevel={zoomLevel}
                padding={{ bottom: 390, top: 200 }}
                selectedView={selectedView}
                selectedTimelineView={selectedTimelineView}

                showEventMarkersAboveLabels={['weather', 'rating'].includes(selectedOverlay ?? '')}

                isCyclonesVisible={selectedOverlay === 'events' && isCyclonesVisible}
                isEarthquakesVisible={selectedOverlay === 'events' && isEarthquakesVisible}
                isLightningVisible={selectedOverlay === 'lightning' && isLightningVisible}
                isStormsVisible={selectedOverlay === 'events' && isStormsVisible}
                isVolcanoesVisible={selectedOverlay === 'events' && isVolcanoesVisible}
                isFiresVisible={selectedOverlay === 'events' && isFiresVisible}
                isWildfiresVisible={showWildfires}

                isRoadStatusVisible={selectedOverlay === 'traffic-road-status' && isRoadStatusVisible}
                isRoadWorkVisible={selectedOverlay === 'traffic-road-work' && isRoadWorkVisible}
                isRoadClosuresVisible={selectedOverlay === 'traffic-road-closures' && isRoadClosuresVisible}
                isSpecialEventsVisible={selectedOverlay === 'traffic' && userData?.show511InPortal && isSpecialEventsVisible}
                showTrafficFlow={selectedOverlay === 'traffic'}
                showTrafficCongestion={selectedOverlay === 'traffic' && userData?.show511InPortal && isTrafficCongestionVisible}
                showLegacyHereTrafficIncidents={selectedOverlay === 'traffic-incidents' && isTrafficIncidentsVisible}
                showTrafficIncidents={selectedOverlay === 'traffic-incidents' && userData?.show511InPortal && isTrafficIncidentsVisible}
                isTruckWarningsVisible={selectedOverlay === 'traffic-truck-warnings' && isTruckWarningsVisible}
                isWeatherStationsVisible={selectedOverlay === 'traffic-road-status' && isWeatherStationsVisible}

                geojsonLayers={selectedOverlay === 'flood-chance' ? floodChanceGeojsonLayers : []}

                mapTypeControlOptions={{
                    ...defaultRouteAndMarkerMapProps.mapTypeControlOptions,
                    mapTypeIds: selectedOverlay?.includes('traffic') === true ? ["weather_optics"] : ["satellite", "weather_optics"],
                }}

                cyclones={cyclones}
                showModelForecastTracksForCycloneId={showModelForecastTracksForCycloneId}
                earthquakes={earthquakes}
                lightning={lightning}
                storms={currentStorms}
                volcanoes={volcanoes}
                fires={fires}
                wildfires={wildfires}

                roadStatus={roadStatus}
                roadWork={roadWork}
                roadClosures={roadClosures}
                specialEvents={specialEvents}
                trafficCongestion={trafficCongestion}
                trafficIncidents={trafficIncidents}
                truckWarnings={truckWarnings}
                weatherStations={weatherStations}

                selectedCyclone={selectedCyclone}
                selectedEarthquake={selectedEarthquake}
                selectedLightning={selectedLightning}
                selectedStorm={selectedStorm}
                selectedVolcano={selectedVolcano}
                selectedFire={selectedFire}
                selectedWildfire={selectedWildfire}

                selectedRoadStatus={selectedRoadStatus}
                selectedRoadWork={selectedRoadWork}
                selectedRoadClosure={selectedRoadClosure}
                selectedSpecialEvent={selectedSpecialEvent}
                selectedTrafficCongestion={selectedTrafficCongestion}
                selectedTrafficIncident={selectedTrafficIncident}
                selectedTruckWarning={selectedTruckWarning}
                selectedWeatherStation={selectedWeatherStation}

                isGovernmentalAlertsVisible={selectedOverlay === 'alerts'}
                governmentalAlerts={governmentalAlerts.value}

                maskType={['weather', 'rating'].includes(selectedOverlay ?? '') ? MaskType.LabelsAndWater : MaskType.Labels}

                tileOpacity={tileOpacity}
                desiredTileLayer={desiredTileset}
                onTileLayerLoaded={(visibleTileset) => {
                    console.log('[Dashboard] onTileLayerLoaded', visibleTileset);
                    setVisibleTileset(visibleTileset);

                    if (!paused && visibleTileset?.id === desiredTileset?.id) {
                        console.log('[Dashboard] onTileLayerLoaded, queuing a new tile load');
                        clearTimeout(timerRef.current);
                        timerRef.current = setTimeout(() => goToNextTimeOffset(), 1000);
                    }
                }}

                onCycloneSelected={(cyclone) => onCycloneSelected(cyclone)}
                onEarthquakeSelected={(earthquake) => onEarthquakeSelected(earthquake)}
                onLightningSelected={(lightning) => onLightningSelected(lightning)}
                onStormSelected={(storm) => onStormSelected(storm)}
                onVolcanoSelected={(volcano) => onVolcanoSelected(volcano)}
                onFireSelected={(fire) => onFireSelected(fire)}
                onWildfireSelected={(wildfire) => onWildfireSelected(wildfire)}

                onRoadStatusSelected={(roadStatus) => onRoadStatusSelected(roadStatus)}
                onRoadWorkSelected={(roadWork) => onRoadWorkSelected(roadWork)}
                onRoadClosureSelected={(roadClosure) => onRoadClosureSelected(roadClosure)}
                onSpecialEventSelected={(specialEvent) => onSpecialEventSelected(specialEvent)}
                onTrafficCongestionSelected={(trafficCongestion) => onTrafficCongestionSelected(trafficCongestion)}
                onTrafficIncidentSelected={(trafficIncident: TrafficIncidentData) => onTrafficIncidentSelected(trafficIncident)}
                onTruckWarningSelected={(truckWarning) => onTruckWarningSelected(truckWarning)}
                onWeatherStationSelected={(weatherStation) => onWeatherStationSelected(weatherStation)}

                isLocationsVisible={isOnLocationsTab}
                savedCities={filteredLocations}

                isVehiclesVisible={isOnVehiclesTab || (isOnRoutesTab && selectedRoute?.vehicleData?.id !== undefined)}
                vehicles={visibleVehicles}
                timeframe={timeframes[selectedTimeframeIndex]}
                citiesLoading={userData.citiesMetadata.loading}
                vehiclesLoading={userData.vehiclesMetadata.loading}

                origin={selectedRoute?.latestRouteResults?.at(0)?.origin || selectedRoute?.origin}
                destination={selectedRoute?.latestRouteResults?.at(0)?.destination || selectedRoute?.destination}
                waypoints={selectedRoute?.waypoints}
                selectedRouteOption={selectedRoute?.selectedRouteOption}
                slowdownPolylines={polylineType === 'slowdown' ? polylines : undefined}
                roadRiskPolylines={polylineType === 'slowdown' ? undefined : polylines}
                polylineType={polylineType}

                onCitySelected={(city) => onCitySelected(city)}
                onRouteSelected={(route) => setSelectedRoute(route)}
                onVehicleSelected={(vehicle) => onVehicleSelected(vehicle)}
                onCenterChanged={(_center) => { }} // TODO: do we need to use this for anything?
                onZoomLevelChanged={(zoomLevel) => setZoomLevel(zoomLevel)}
                onBoundsChanged={(bounds) => setMapBounds(bounds)}
                onMapClicked={(coord) => onMapClicked(coord)}
            >
                {weatherCalloutView}
            </RouteAndMarkerMap>
            {legendContainer}
            {selectedOverlay === 'alerts' && <div className={"DashboardAlertDetailCards"}>
                {alertDetailElement}
            </div>}
            <div className={"EventDescriptionComponent-Container"}>
                <EventDescriptionComponent
                    allowClose={true}

                    selectedCyclone={selectedCyclone}
                    showModelForecastTracksForCycloneId={showModelForecastTracksForCycloneId}
                    selectedEarthquake={selectedEarthquake}
                    selectedLightning={selectedLightning}
                    selectedStorm={selectedStorm}
                    selectedVolcano={selectedVolcano}
                    selectedFire={selectedFire}
                    selectedWildfire={selectedWildfire}
                    selectedRoadStatus={selectedRoadStatus}
                    selectedRoadWork={selectedRoadWork}
                    selectedRoadClosure={selectedRoadClosure}
                    selectedSpecialEvent={selectedSpecialEvent}
                    selectedTrafficCongestion={selectedTrafficCongestion}
                    selectedTrafficIncident={selectedTrafficIncident}
                    selectedTruckWarning={selectedTruckWarning}
                    selectedWeatherStation={selectedWeatherStation}

                    isCyclonesVisible={selectedOverlay === 'events' && isCyclonesVisible}
                    isEarthquakesVisible={selectedOverlay === 'events' && isEarthquakesVisible}
                    isStormsVisible={selectedOverlay === 'events' && isStormsVisible}
                    isVolcanoesVisible={selectedOverlay === 'events' && isVolcanoesVisible}
                    isFiresVisible={selectedOverlay === 'events' && isFiresVisible}
                    isWildfiresVisible={showWildfires}
                    isLightningVisible={selectedOverlay === 'lightning' && selectedLightning !== undefined}
                    isRoadStatusVisible={isRoadStatusVisible}
                    isRoadWorkVisible={isRoadWorkVisible}
                    isRoadClosuresVisible={isRoadClosuresVisible}
                    isSpecialEventsVisible={isSpecialEventsVisible}
                    isTrafficCongestionVisible={isTrafficCongestionVisible}
                    isTrafficIncidentsVisible={isTrafficIncidentsVisible}
                    isTruckWarningsVisible={isTruckWarningsVisible}
                    isWeatherStationsVisible={isWeatherStationsVisible}
                    // add these to dashbaord later
                    isPowerOutagesVisible={false}
                    isStormReportsVisible={false}

                    onShowModelForecastTracksForCycloneIdSelected={(cycloneId) => setShowModelForecastTracksForCycloneId(cycloneId)}
                    onClearSelectedEvent={() => onClearSelectedEvent()}
                    onClearSelectedRoadCondition={() => onClearSelectedRoadCondition()}
                />
            </div>
            <ImpactDetailView
                userData={userData}
                token={userData.token}
                timeframes={timeframes}
                selectedTimeframeIndex={selectedTimeframeIndex}
                routes={userData.savedRoutes}
                routesMetadata={userData.savedRoutesMetadata}
                refreshingRouteIds={userData.refreshingRouteIds}
                locations={userData.cities}
                locationsMetadata={userData.citiesMetadata}
                highImpactLocations={filteredLocations}
                vehicles={userData.vehicles}
                vehiclesMetadata={userData.vehiclesMetadata}
                filteredVehicles={filteredVehicles}
                lightning={lightning}
                polylineType={polylineType}
                selectedView={selectedView}
                selectedTimelineView={selectedTimelineView}
                selectedRoute={selectedRoute}
                selectedLocation={selectedCity}
                selectedVehicle={selectedVehicle}
                showOnlyHighImpactLocations={showOnlyHighImpactLocations}
                filterNoImpactVehicles={filterNoImpactVehicles}
                blurbs={blurbs}

                onRouteSelected={(route) => setSelectedRoute(route)}
                onViewSelected={(view) => {
                    setSelectedView(view);
                }}
                onLocationSelected={(location) => onCitySelected(location)}
                onVehicleSelected={(vehicle) => onVehicleSelected(vehicle)}
                onVehicleMessageRequested={onVehicleMessageRequested}
                onTimeframeIndexChanged={(timeframeIndex) => setSelectedTimeframeIndex(timeframeIndex)}
                onRouteRefreshRequested={(route) => onRouteRefreshRequested(route)}
                onShowOnlyHighImpactLocations={(filter) => setShowOnlyHighImpactLocations(filter)}
                onFilterNoImpactVehicles={(filter) => setFilterNoImpactVehicles(filter)}
                onPolylineTypeSet={(polylineType) => setPolylineType(polylineType)}
                onTimelineViewSelected={(timelineView) => setSelectedTimelineView(timelineView)}
            />
            <AutoShowErrorDialog
                title={"Error refreshing route"}
                error={userData.refreshRouteError}
                onDismiss={() => onRouteRefreshErrorDismissed()}
            />
            <PortalTour run={startPortalTour} portalTab="DASHBOARD" />

        </div>
    );
};