import * as React from 'react';
import './index.css';

import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { CircularProgress, IconButton, Paper, Tab, Tabs } from '@mui/material';

import { cardBackgroundColor, HISTORICAL_STORM_DATA_URL, IMPACT_TILES_ENDPOINT } from "../../../constants";
import { LegendComponent } from '../Impact/LegendComponent';
import { BaseLocationData, BasicLatLng, Blurb, BlurbsByIndex, getCenterOfViewport, StormData, UserState, ViewportData } from 'src/types';
import { isImpactKey, RatingKey } from 'src/types/RatingKey';
import { HistoricalAccuracyMap } from './HistoricalAccuracyMap';
import { HistoricalEventSelectionComponent } from './HistoricalEventSelectionComponent';
import { HistoricalAccuracyCalloutView } from './HistoricalAccuracyCalloutView';
import { RouteResults } from 'src/types/routes';
import { HistoricalAccuracyRouteView } from './HistoricalAccuracyRouteView';
import PortalTour from '../PortalTour';
import { unmarshalHistoricalStormEvent } from 'src/types/unmarshal';
import { Config } from 'src/components/shared/useConfig';
import { EventDescriptionComponent } from '../Impact/EventDescriptionComponent';

// Historical accuracy page - displays our prediction for historical storms to show users accuracy of our predictions.

export interface HistoricalStormEventTile {
    forecastDaysOut: number;
    url: string;
}

export interface HistoricalStormEventRoute {
    forecastDaysOut: number;
    description: string;
    optionsForRoute: RouteResults[];
}

export interface HistoricalStormEventReport {
    location: BaseLocationData;
    polygon: JSON;
    description: string;
    markerColor: string;
    markerIcon: string;
    tileUrl?: string;
    polygonFeatures?: google.maps.Data.Feature[];
}

export interface HistoricalStormEventImpact {
    name: string;
    tiles: HistoricalStormEventTile[];
    routes: HistoricalStormEventRoute[][];
    groundTruthReports: HistoricalStormEventReport[];
}

export interface HistoricalStormEvent {
    name: string;
    description: string;
    analysis: string;

    location: ViewportData;

    impacts: HistoricalStormEventImpact[];

    events: StormData[][];
}

interface Props {
    userData: UserState;

    blurbs: BlurbsByIndex;
    center?: BasicLatLng;
    zoomLevel: number;

    isCalloutViewVisible: boolean;

    onCenterChanged: (center: BasicLatLng) => void;
    onZoomLevelChanged: (zoomLevel: number) => void;
}

export const ClientHistoricalAccuracyPage = (props: Props) => {
    const [historicalStormEvents, setHistoricalStormEvents] = React.useState<HistoricalStormEvent[]>([]);
    const [selectedStormEvent, setSelectedStormEvent] = React.useState<HistoricalStormEvent | undefined>(undefined);
    const [selectedStormImpact, setSelectedStormImpact] = React.useState<HistoricalStormEventImpact>({ name: 'Road', tiles: [], routes: [], groundTruthReports: [] });
    const [selectedForecastDaysOut, setSelectedForecastDaysOut] = React.useState(5);
    const [selectedGroundTruthReport, setSelectedGroundTruthReport] = React.useState<HistoricalStormEventReport | undefined>(undefined);
    const [selectedRouteDescription, setSelectedRouteDescription] = React.useState<string>('');
    const [selectedRoute, setSelectedRoute] = React.useState<RouteResults | undefined>(undefined);
    const [selectedRouteOption, setSelectedRouteOption] = React.useState<number>(-1);
    const [selectedEventInfo, setSelectedEventInfo] = React.useState<"description" | "analysis">("description");
    const [eventDescriptionEl, setEventDescriptionEl] = React.useState<HTMLDivElement | null>(null);
    const [showGroundTruthData, setShowGroundTruthData] = React.useState(true);
    const [showForecastedStormsData, setShowForecastedStormsData] = React.useState(false);
    const [selectedStorm, setSelectedStorm] = React.useState<StormData | undefined>(undefined);
    const [showCalloutView, setShowCalloutView] = React.useState(false);
    const [resetMapToggle, setResetMapToggle] = React.useState(false);
    const [collapsed, setCollapsed] = React.useState(true);
    const [runTour, setRunTour] = React.useState(false);

    React.useEffect(() => {
        setRunTour(Config.getBoolean(Config.Key.PortalTourComplete) === false);
    }, []);

    React.useEffect(() => {
        const fetchEventData = async () => {
            const response = await fetch(HISTORICAL_STORM_DATA_URL);
            const eventsData = await response.json();
            const stormEvents = eventsData.map((event: object) => {
                return unmarshalHistoricalStormEvent(event);
            });
            setHistoricalStormEvents(stormEvents);
            setSelectedStormEvent(stormEvents[0]);
            setSelectedStormImpact(stormEvents[0].impacts[0]);
        };

        fetchEventData()
            .then(
                () => console.log('fetched historical storm data!'),
                (reason) => console.error(`failed to fetch historical storm data: ${reason}`)
            );
    }, []);

    React.useEffect(() => {
        setShowCalloutView(selectedGroundTruthReport ? true : false);
    }, [selectedGroundTruthReport]);

    React.useEffect(() => {
        if (selectedStormEvent) {
            setSelectedStormImpact(selectedStormEvent.impacts[0]);
            setSelectedForecastDaysOut(5);
            setShowGroundTruthData(true);
            resetView();
        }
    }, [selectedStormEvent]);

    React.useEffect(() => {
        if (selectedStormImpact.name === 'RightRoute') {
            // update selected route for selectedForecastDaysOut
            if (selectedRoute && selectedRouteDescription !== '' && selectedRouteOption !== -1) {
                const routesForDay = selectedStormImpact.routes[(selectedStormImpact.routes.length - 1) - selectedForecastDaysOut];
                const routeForDay = routesForDay.filter(route => route.description === selectedRouteDescription)[0];
                setSelectedRoute(routeForDay.optionsForRoute[selectedRouteOption]);
            }

            // clear out ground truth report or selected route when the seleted impact does not support them
            setSelectedGroundTruthReport(undefined);
        } else {
            // update selected ground truth report for selectedForecastDaysOut
            if (selectedGroundTruthReport && selectedGroundTruthReport.location.name !== 'MAP CLICK' && selectedStormImpact) {
                const newTileUrl = (new URL(IMPACT_TILES_ENDPOINT + selectedStormImpact.tiles[(selectedStormImpact.tiles.length - 1) - selectedForecastDaysOut].url)).searchParams.get('url');
                if (newTileUrl) {
                    setSelectedGroundTruthReport({ ...selectedGroundTruthReport, tileUrl: newTileUrl });
                }
            }

            // clear out ground truth report or selected route when the seleted impact does not support them
            setSelectedRouteDescription('');
            setSelectedRoute(undefined);
            setSelectedRouteOption(-1);
        }
    }, [selectedStormImpact, selectedForecastDaysOut]);

    React.useEffect(() => {
        // when a user switches the storm event or the impact they are looking at
        // while looking at a ground truth report, we remove the selection so you
        // don't see a flood report while looking at power data or just the wrong
        // location in the case of a different storm
        if (selectedGroundTruthReport?.location.name !== 'MAP CLICK') {
            setSelectedGroundTruthReport(undefined);
        }
    }, [selectedStormEvent, selectedStormImpact]);

    function resetView() {
        setResetMapToggle(!resetMapToggle);
    }

    const eventSelectionComponent = (
        <HistoricalEventSelectionComponent
            historicalStormEvents={historicalStormEvents}
            selectedStormEvent={selectedStormEvent}
            selectedStormImpact={selectedStormImpact}
            selectedForecastDaysOut={selectedForecastDaysOut}
            showGroundTruthData={showGroundTruthData}
            showForecastedStormsData={showForecastedStormsData}

            onStormEventSelected={(stormEvent) => setSelectedStormEvent(stormEvent)}
            onStormImpactSelected={(impact) => setSelectedStormImpact(impact)}
            onForecastDaysOutSelected={(day) => setSelectedForecastDaysOut(day)}
            onShowGroundTruthSelected={(show) => setShowGroundTruthData(show)}
            onShowForecastedStormsSelected={(show) => setShowForecastedStormsData(show)}
            onResetView={() => resetView()}
        />
    );


    let impactName = selectedStormImpact?.name.toLowerCase();
    // use road blurbs for right route
    if (impactName === 'rightroute') impactName = 'road';
    // Life & Property -> life_property
    const impactBlurbs: Blurb[] = props.blurbs[impactName?.toLowerCase().replace(' & ', '_') as RatingKey] ?? [];

    const mapElement: JSX.Element = (
        <HistoricalAccuracyMap
            portalToken={props.userData.portalToken}
            selectedStormEvent={selectedStormEvent}
            selectedStormImpact={selectedStormImpact}
            selectedForecastsDaysOut={selectedForecastDaysOut}
            selectedGroundTruthReport={selectedGroundTruthReport}
            showGroundTruthData={showGroundTruthData}
            showForecastedStormsData={showForecastedStormsData}
            selectedStorm={selectedStorm}
            showCalloutView={showCalloutView}
            resetMapToggle={resetMapToggle}

            initialCenter={selectedStormEvent ? getCenterOfViewport(selectedStormEvent.location) : props.center}
            zoomLevel={7}

            onCenterChanged={(center) => props.onCenterChanged(center)}
            onZoomLevelChanged={(zoomLevel: number) => props.onZoomLevelChanged(zoomLevel)}
            onStormSelected={(storm) => setSelectedStorm(storm)}
            onGroundTruthReportSelected={(report: HistoricalStormEventReport) => {
                setSelectedGroundTruthReport(report);
                // don't show groundtruth and route popups at the same time
                setSelectedRouteDescription('');
                setSelectedRoute(undefined);
                setSelectedRouteOption(-1);
            }}
            onRouteSelected={(description: string, route: RouteResults, routeOption: number) => {
                setSelectedRouteDescription(description);
                setSelectedRoute(route);
                setSelectedRouteOption(routeOption);
                // don't show groundtruth and route popups at the same time
                setSelectedGroundTruthReport(undefined);
            }}>
            {showCalloutView && <HistoricalAccuracyCalloutView
                apiToken={props.userData.token}
                portalToken={props.userData.portalToken}
                impactBlurbs={impactBlurbs}
                groundTruthReport={selectedGroundTruthReport}
                stormLocation={selectedStormEvent?.location}
                lat={selectedGroundTruthReport ? selectedGroundTruthReport.location.latitude : 0}
                lng={selectedGroundTruthReport ? selectedGroundTruthReport.location.longitude : 0}
                onClose={() => setSelectedGroundTruthReport(undefined)}
            />}
            {selectedRoute && <HistoricalAccuracyRouteView
                description={selectedRouteDescription}
                selectedRoute={selectedRoute}
                impactBlurbs={impactBlurbs}
                lat={props.center ? props.center.lat : 0}
                lng={props.center ? props.center.lng : 0}
                onClose={() => {
                    setSelectedRouteDescription('');
                    setSelectedRoute(undefined);
                }}
            />}
        </HistoricalAccuracyMap>
    );

    const eventDescriptionComponent = (
        <Paper elevation={3} style={{ backgroundColor: cardBackgroundColor, backgroundImage: 'none', borderRadius: 11, padding: 10, height: "95%" }}>
            <header className={"event-description-header"}>
                <Tabs
                    className="event-description-title"
                    value={selectedEventInfo}
                    onChange={(eventevent, newValue) => {
                        setSelectedEventInfo(newValue);
                        setCollapsed(false);
                        if (eventDescriptionEl) {
                            eventDescriptionEl.scrollTop = 0;
                        }
                    }}
                    indicatorColor={'primary'}
                    textColor={'primary'}
                    variant="scrollable"
                    scrollButtons="auto"
                    style={{ width: '100%', height: 50 }}
                >
                    <Tab label={'Event Description'} value={'description'} />
                    <Tab label={'Event Analysis'} value={'analysis'} />
                </Tabs>
                <IconButton
                    color={'primary'}
                    onClick={() => setCollapsed(!collapsed)}
                    style={{ height: 40 }}
                >
                    {collapsed ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                </IconButton>
            </header>

            {
                !collapsed && <div ref={setEventDescriptionEl} className={"event-description-text"} dangerouslySetInnerHTML={{
                    __html: selectedStormEvent
                        ? selectedEventInfo === 'description' ? selectedStormEvent.description : selectedStormEvent.analysis : ''
                }} />
            }
        </Paper >
    );

    const legendRatingKey = selectedStormImpact?.name.toLowerCase() as RatingKey | undefined;
    const legendComponent = (
        <LegendComponent
            ratingKey={legendRatingKey}
            blurbs={impactBlurbs}
            skipFirstBlurb={true}
            embedValues={isImpactKey(legendRatingKey)}
            tooltipPlacement='left'
        />
    );

    return (
        <>
            {!selectedStormEvent ?
                <div className={'ClientHistoricalAccuracyPage'} style={{ backgroundColor: cardBackgroundColor, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgress />
                </div>
                :
                <div className={'ClientHistoricalAccuracyPage'}>
                    <div className={'historical-accuracy-event-selector'}>
                        {eventSelectionComponent}
                    </div>

                    <div className={'maps'}>
                        {mapElement}
                    </div>

                    <div className={collapsed ? 'event-description-component-collapsed' : 'event-description-component'}>
                        {eventDescriptionComponent}
                    </div>

                    <div className={"EventDescriptionComponent-Container"}>
                        <EventDescriptionComponent
                            allowClose={true}

                            selectedStorm={selectedStorm}

                            isStormsVisible={showForecastedStormsData}

                            onClearSelectedEvent={() => setSelectedStorm(undefined)}
                        />
                    </div>

                    {legendComponent}
                    <PortalTour run={runTour} portalTab="ACCURACY" />
                </div>
            }
        </>
    );
};
