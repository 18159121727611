import { LocationData, UserState } from '../../types';
import { API_HOST } from '../../constants';
import { debounce } from 'ts-debounce';
import * as React from 'react';
import { AutocompleteDropdown, defaultAsyncStyleProps } from './AutocompleteDropdown';
import { retryIfRateLimited } from '../../actions/Ratings';
import { unmarshalLocation } from 'src/types/unmarshal';

const loadPlaceSuggestions = (input: string, token: string | undefined) => {
    if (input === "") {
        return Promise.resolve([]);
    }

    let postData: RequestInit = {
        body: JSON.stringify({ query: input, token }),
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        }
    };
    return retryIfRateLimited(() => fetch(`${API_HOST}/geocoding/location_suggestions`, postData))
        .then((response: Response) => response.json().then((json) => { return { response, json }; }))
        .then(
            ({ response, json }) => {
                if (response.status === 200) {
                    let results = json.results;

                    let cities: LocationData[] = results.map((result: any) => {
                        let city: LocationData = unmarshalLocation(result, true);
                        return city;
                    });

                    return cities;
                } else {
                    console.warn('unsuccessful response status from loading places', json);
                    return [];
                }
            },
        ).catch((error) => {
            console.warn('error loading places', error);
            return [];
        });
};

const searchCities = (input: string, token: string | undefined) => {
    if (token === undefined) {
        return Promise.resolve([]);
    }

    return retryIfRateLimited(() => fetch(`${API_HOST}/cities.json?token=${token}&limit=10&query=${input}`))
        .then(
            (response: Response) => response.json(),
            (error: Error) => console.log('Error fetching user cities in search.', error)
        ).then((json: JSON) => {
            let cities: LocationData[] = (json['cities'] as object[]).map(cityJSON => unmarshalLocation(cityJSON));
            return cities;
        });
};

export const debouncedLoadPlaceSuggestions = debounce(
    (input, token, callback) => {
        const loadSuggestedLocations = loadPlaceSuggestions(input, token);
        const loadSavedLocations = searchCities(input, token);
        return Promise.all([loadSuggestedLocations, loadSavedLocations]).then(combined => {
            const [suggestedLocations, savedLocations] = combined;
            let output = [{
                label: 'SAVED LOCATIONS',
                options: savedLocations
            }, {
                label: 'OTHER LOCATIONS',
                options: suggestedLocations
            }];
            try {
                const [latStr, lonStr] = input.split(",");
                const lat = parseFloat(latStr);
                let lon = parseFloat(lonStr);
                if (!isNaN(lon) && lon > 180) {
                    lon -= 360;
                }
                if (!isNaN(lat) && lat >= -90 && lat <= 90 && !isNaN(lon) && lon >= -180 && lon <= 180) {
                    const latLonLocations: { label: string; options: LocationData[]; } = {
                        label: 'LOCATION',
                        options: [{
                            name: '',
                            latitude: lat,
                            longitude: lon,
                            needsGeocoding: true,
                            impactSummaries: [],
                        }],
                    };
                    output = [latLonLocations].concat(output);
                }
            } catch {
                // do nothing
            }
            return output;
        }).then(groupedOptions => callback(groupedOptions));
    },
    300
);

export const LocationSelectionComponent = (props: { user: UserState; allowSelectingLocations: boolean; leftElement?: JSX.Element; savedCities: LocationData[]; selectedCity?: LocationData; onCitySelected: (city: LocationData) => void }) => {
    let searchBoxElement: JSX.Element | undefined;

    if (props.allowSelectingLocations) {
        searchBoxElement = (
            <div className={'search-box'}>
                <AutocompleteDropdown
                    {...defaultAsyncStyleProps()}
                    width={320}
                    paddingLeft={12}
                    paddingRight={32}
                    borderRadius={6}

                    loadOptions={(input, callback) => debouncedLoadPlaceSuggestions(input, props.user.token, callback)}
                    onChange={(location: LocationData) => props.onCitySelected(location)}
                    placeholder={"Search by city or zip"}

                    getOptionValue={(location: LocationData) => `${location.latitude},${location.longitude}`}
                    getOptionLabel={(location: LocationData) => location.name ? location.name : `${location.latitude}, ${location.longitude}`}

                    className={'search-box-input'}
                />
            </div>
        );
    }

    return (
        <div className={'LocationSelectionComponent'}>
            {props.leftElement}
            {searchBoxElement}
        </div>
    );
};
