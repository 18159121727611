import { DataGridPro, GridSortModel, GridRenderCellParams, GridValueFormatterParams, GridValueGetterParams } from '@mui/x-data-grid-pro';
import { getFormattedDepartureTime, RouteData, RouteWaypoint, VehicleData } from '../../../types/routes';
import * as React from 'react';
import { Button, IconButton, Tooltip, TooltipProps, tooltipClasses, styled } from '@mui/material';
import { AirportShuttleOutlined, DeleteOutline, EditOutlined, LocalShippingOutlined, PlaceOutlined } from '@mui/icons-material';
import { getUserDescriptionOfError } from 'src/types/unmarshal';

export const RoutesTable = ({ routes, onDeleteRouteClicked, onEditRouteClicked }: { routes: RouteData[], onDeleteRouteClicked: (route: RouteData) => void, onEditRouteClicked: (route: RouteData) => void }) => {
    const [sortModel, setSortModel] = React.useState<GridSortModel>([{ field: 'departureTime', sort: 'asc' }]);

    const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(() => ({
        [`& .${tooltipClasses.tooltip}`]: {
            fontSize: 12,
            backgroundColor: "dimgrey",
        },
    }));

    const columns = [{
        field: 'status',
        headerName: '',
        width: 60,
        sortComparator: (v1: any, v2: any) => {
            const { value: d1 } = v1 as { value: number };
            const { value: d2 } = v2 as { value: number };

            return d1 - d2;
        },
        valueGetter: (params: GridValueGetterParams) => {
            let value: number;
            let title: string;
            let emoji: string;
            if (params.row.latestRouteResults === undefined && params.row.latestRouteResultError) {
                value = 1;
                const { errorTitle, errorMessage } = getUserDescriptionOfError(params.row.latestRouteResultError.error);
                title = `${errorTitle}: ${errorMessage}`;
                emoji = '⚠️';
            } else {
                value = 0;
                title = '';
                emoji = '';
            }
            return { value, title, emoji };
        },
        renderCell: (params: GridRenderCellParams) => {
            const { value, title, emoji } = params.value;
            // only show errors
            if (value !== 1) {
                return <div></div>;
            }
            return (
                <Tooltip title={title}>
                    <div>
                        {emoji}
                    </div>
                </Tooltip>
            );
        }
    }, {
        field: 'externalId',
        headerName: 'External ID',
        valueGetter: (params: GridValueGetterParams) => {
            return params.row.name || params.row.externalId;
        },
    }, {
        field: 'origin',
        headerName: 'Origin',
        flex: 2,
        valueGetter: (params: GridValueGetterParams) => params.row.origin.label,
        renderCell: (params: GridRenderCellParams) => {
            return (
                <Tooltip title={`${params.row.origin.latitude.toFixed(3)}, ${params.row.origin.longitude.toFixed(3)}`}>
                    <div>
                        {params.value}
                    </div>
                </Tooltip>
            );
        }
    }, {
        field: 'destination',
        headerName: 'Destination',
        flex: 2,
        valueGetter: (params: GridValueGetterParams) => params.row.destination.label,
        renderCell: (params: GridRenderCellParams) => {
            return (
                <Tooltip title={`${params.row.destination.latitude.toFixed(3)}, ${params.row.destination.longitude.toFixed(3)}`}>
                    <div>
                        {params.value}
                    </div>
                </Tooltip>
            );
        }
    }, {
        field: 'departureTime',
        headerName: 'Departure Time',
        width: 180,
        valueFormatter: (params: GridValueFormatterParams) => getFormattedDepartureTime(params.value as Date)
    }, {
        field: 'waypoints',
        headerName: 'Waypoints',
        width: 90,
        renderCell: (params: GridRenderCellParams) => {
            const waypoints: RouteWaypoint[] = params.row.waypoints;
            if (waypoints?.length > 0) {
                const tooltip = (
                    <React.Fragment>
                        {waypoints !== undefined && waypoints.map((waypoint: RouteWaypoint, index) => <p>{`${index + 1}. ${waypoint.latitude},${waypoint.longitude} ${waypoint.stopDuration !== undefined ? `stop duration=${waypoint.stopDuration}` : ''}`}</p>)}
                    </React.Fragment>);
                return (
                    <HtmlTooltip title={tooltip} placement="top">
                        <IconButton>
                            <PlaceOutlined />
                        </IconButton>
                    </HtmlTooltip>
                );
            }
            return '';
        }
    }, {
        field: 'vehicleData',
        headerName: 'Vehicle',
        width: 80,
        renderCell: (params: GridRenderCellParams) => {
            const vehicleData: VehicleData = params.row.vehicleData;
            if (vehicleData === undefined) return '';
            const tooltip = (<React.Fragment>
                {vehicleData.type && <h3>{vehicleData.type.charAt(0).toUpperCase() + vehicleData.type.slice(1)}</h3>}
                {vehicleData.height && <p>Height: {vehicleData.height} m</p>}
                {vehicleData.width && <p>Width: {vehicleData.width} m</p>}
                {vehicleData.length && <p>Length: {vehicleData.length} m</p>}
                {vehicleData.weight && <p>Weight: {vehicleData.weight} kg</p>}
                {vehicleData.axleWeight && <p>Axle Weight: {vehicleData.axleWeight} kg</p>}
            </React.Fragment>);
            return (
                <HtmlTooltip title={tooltip} placement="top">
                    <IconButton>
                        {vehicleData.type === 'car' ? <AirportShuttleOutlined /> : <LocalShippingOutlined />}
                    </IconButton>
                </HtmlTooltip>
            );
        }
    }, {
        // fake field name to prevent the header from being highlighted weirdly
        field: 'actions',
        headerName: 'Actions',
        width: 160,
        sortable: false,
        valueGetter: (params: GridValueGetterParams) => {
            return routes.filter(x => x.id === params.id)[0];
        },
        renderCell: (params: GridRenderCellParams) => {
            return (
                <div>
                    <Button
                        onClick={() => onEditRouteClicked(params.value as RouteData)}
                    >
                        <EditOutlined />
                    </Button>
                    <Button
                        onClick={() => onDeleteRouteClicked(params.value as RouteData)}
                    >
                        <DeleteOutline />
                    </Button>
                </div>
            );
        }
    }];

    return (
        <div>
            <DataGridPro
                columns={columns}
                rows={routes}
                pageSize={10}
                pagination
                autoHeight
                sortModel={sortModel}
                onSortModelChange={(newValue) => setSortModel(newValue)}
                disableSelectionOnClick
            />
        </div>
    );
};
