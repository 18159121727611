import {MapViewState} from "../types";
import {MapViewAction, MapCenterChanged, MapZoomLevelChanged} from "../actions/MapView";

export function mapView(state: MapViewState, action: typeof MapViewAction.actions): MapViewState {
    if (state === undefined) {
        return {
            zoomLevel: 9,
        };
    }

    switch (action.type) {
        case MapCenterChanged.type:
            return {...state, center: action.center};
        case MapZoomLevelChanged.type:
            return {...state, zoomLevel: action.zoomLevel};
        default: return state;
    }
}