import * as React from "react";
import './index.css';
import { darkModeTheme } from "../../../util";
import { ThemeProvider, Theme, StyledEngineProvider } from '@mui/material/styles';
import { RouteAndMarkerMap, defaultRouteAndMarkerMapProps } from "../../shared/RouteAndMarkerMap";
import { colorForImpactLevel, findImpactLevel, ImpactLevel, RoadRiskPolylineSegment, wordForImpactLevel } from "../../../types/routes";
import { RouteData } from 'src/types/routes';
import { Button, Card, CardContent, CircularProgress, IconButton, Paper, Tab, Tabs, Tooltip } from "@mui/material";
import { ArrowDownward, ErrorOutlined, KeyboardArrowDown, KeyboardArrowUp, LocationDisabledOutlined, LocationSearchingOutlined, Place, ThumbUpOutlined, WarningAmberOutlined } from "@mui/icons-material";
import { API_HOST, cardBackgroundColor } from "src/constants";
import { RoadRiskLegend } from "./RoadRiskLegend";
import { unmarshalCachedRoute } from "src/types/unmarshal";

declare module '@mui/styles/defaultTheme' {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface DefaultTheme extends Theme { }
}

interface RouteNotification {
    color: string;
    maxRoadIndex: number;
    distance: string;
}

export interface RouteNotifications {
    current: RouteNotification;
    next: RouteNotification;
}

export interface Props {
    cacheKey: string;
}

export const RouteReportPage = (props: Props) => {
    const { cacheKey } = props;
    const [cachedRoute, setCachedRoute] = React.useState<RouteData | undefined | null>(undefined);
    const [reportMode, setReportMode] = React.useState<'selection' | 'navigation'>('selection');
    const [collapsed, setCollapsed] = React.useState<boolean>(false);
    const [notifications, setNotifications] = React.useState<RouteNotifications | undefined>(undefined);
    const [trackingMode, setTrackingMode] = React.useState<boolean>(true);
    const [selectedPolyline, setSelectedPolyline] = React.useState<RoadRiskPolylineSegment | undefined>(undefined);

    // fetch cached route data
    React.useEffect(() => {
        const getRouteData = async () => {
            const response = await fetch(`${API_HOST}/routes/fetch_from_cache?cache_key=${cacheKey}`);
            const json = await response.json();
            if (json["success"]) {
                setCachedRoute(unmarshalCachedRoute(json["data"]));
            }
            else {
                // use null cached route for error or expired route
                setCachedRoute(null);
                console.log("Error accessing cached route: ", json["error"]);
            }
        };

        getRouteData();
    }, [cacheKey]);

    const getImpactStyling = (type: string) => {
        if (cachedRoute && cachedRoute.latestRouteResults && cachedRoute.selectedRouteOption) {
            const results = cachedRoute.latestRouteResults[parseInt(cachedRoute.selectedRouteOption.split(' ')[2]) - 1];
            if (type === 'word') {
                return wordForImpactLevel(findImpactLevel(results));
            }
            return colorForImpactLevel(findImpactLevel(results));
        }
        return "Unavailable";
    };

    const getTravelTime = () => {
        if (cachedRoute && cachedRoute.latestRouteResults && cachedRoute.selectedRouteOption) {
            const results = cachedRoute.latestRouteResults[parseInt(cachedRoute.selectedRouteOption.split(' ')[2]) - 1];
            const travelTime: number = (new Date(results.adjustedArrivalTime).getTime() - new Date(cachedRoute.departureTime).getTime()) / 60000;
            return `${Math.floor(travelTime / 60)}h ${Math.round(travelTime % 60)}m`;

        }
        return 'Unavailable';
    };

    const getWeatherFlags = () => {
        if (cachedRoute && cachedRoute.latestRouteResults && cachedRoute.selectedRouteOption) {
            let flags = [] as string[];
            // if segment selected in navigation view show the weather flags for that segment otherwise show the weather flags for the entire route
            if (selectedPolyline?.weatherFlags && reportMode === 'navigation') {
                flags = selectedPolyline?.weatherFlags;
            } else {
                const results = cachedRoute.latestRouteResults[parseInt(cachedRoute.selectedRouteOption.split(' ')[2]) - 1];
                if (results?.weatherFlags) {
                    flags = results?.weatherFlags;
                }
            }
            return flags.map(flag => {
                return flag
                    .replace(/_flag/, '')
                    .replace('_', ' ')
                    .split(' ')
                    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(' ');
            }).join(', ');
        }
        return 'Unavailable';
    };

    function getFormattedDepartureTime(departureTime: Date | undefined) {
        if (departureTime) {
            const timeString = departureTime.toLocaleTimeString('en-US', { hour: "numeric", minute: "numeric", timeZoneName: "short" });
            const dateString = departureTime.toLocaleDateString('en-US', { month: "long", day: "numeric", year: "numeric" });
            return `${timeString}, ${dateString}`;
        }
        return 'Unavailable';
    }

    function impactLevelForPolylineColor(color: string): ImpactLevel {
        switch (color) {
            case "red": return ImpactLevel.High;
            case "yellow": return ImpactLevel.Moderate;
            default: return ImpactLevel.Low;
        }
    }

    function getNotificationIcon(notificationColor: string) {
        switch (impactLevelForPolylineColor(notificationColor)) {
            case ImpactLevel.High: return <ErrorOutlined style={{ color: notificationColor }} />;
            case ImpactLevel.Moderate: return <WarningAmberOutlined style={{ color: notificationColor }} />;
            default: return <ThumbUpOutlined style={{ color: notificationColor }} />;
        }
    }

    function getCurrentNavigationWarning() {
        if (notifications && notifications.current) {
            return (
                <div className={'route-navigation-notification'}>
                    {getNotificationIcon(notifications.current.color)}
                    <div style={{ width: 10 }} />
                    {`${notifications.current.distance} miles remaining of ${wordForImpactLevel(impactLevelForPolylineColor(notifications.current.color)).toLowerCase()} (${notifications.current.maxRoadIndex.toFixed(1)}) road risk`}
                    <div style={{ flex: '1 1 0' }} />
                    <IconButton
                        color={'primary'}
                        onClick={() => setCollapsed(!collapsed)}
                        style={{ height: 40 }}
                    >
                        {collapsed ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                </div>
            );
        }
        return 'Current location not on route';
    }

    function getNextNavigationWarning() {
        if (selectedPolyline) {
            return (
                <div className={'route-navigation-notification'}>
                    {getNotificationIcon(selectedPolyline.color)}
                    <div style={{ width: 10 }} />
                    {`${wordForImpactLevel(impactLevelForPolylineColor(selectedPolyline.color))} (${selectedPolyline.maxRoadIndex.toFixed(1)}) road risk`}
                </div>
            );
        }
        if (notifications && notifications.next) {
            return (
                <div className={'route-navigation-notification'}>
                    {getNotificationIcon(notifications.next.color)}
                    <div style={{ width: 10 }} />
                    {`${wordForImpactLevel(impactLevelForPolylineColor(notifications.next.color))} (${notifications.next.maxRoadIndex.toFixed(1)}) road risk starting in ${notifications.next.distance} miles`}
                </div>
            );
        }
        return 'Unavailable';
    }

    const routeInfoComponent = (
        <div className={'route-info'}>
            <Paper elevation={3} style={{ backgroundColor: cardBackgroundColor, backgroundImage: 'none', padding: 15, borderRadius: 11 }}>
                {reportMode === 'navigation' ?
                    <div className={'location'}>
                        <Button variant="text" onClick={() => setReportMode('selection')}>&lt; BACK TO OVERVIEW</Button>
                        <div style={{ flex: '1 1 0' }} />
                        <IconButton
                            color={'primary'}
                            onClick={() => setTrackingMode(!trackingMode)}
                            style={{ height: 40 }}
                        >
                            {trackingMode ?
                                <Tooltip title="Location Tracking: On" placement="right">
                                    <LocationSearchingOutlined />
                                </Tooltip>
                                :
                                <Tooltip title="Location Tracking: Off" placement="right">
                                    <LocationDisabledOutlined />
                                </Tooltip>}
                        </IconButton>
                    </div>
                    :
                    <>
                        <div className={'location'}>
                            <Place style={{ color: 'green' }} />
                            <Card className={'location-card'}>
                                <CardContent className={'location-card-content'}>{cachedRoute?.origin.label} </CardContent>
                            </Card>
                        </div>
                        <div>
                            <ArrowDownward style={{ color: 'grey', fontSize: 'small', marginLeft: '5px' }} />
                        </div>
                        <div className={'location'}>
                            <Place style={{ color: 'red' }} />
                            <Card className={'location-card'}>
                                <CardContent className={'location-card-content'}>{cachedRoute?.destination.label} </CardContent>
                            </Card>
                        </div>
                        <div className={'depart'}>
                            Depart at {getFormattedDepartureTime(cachedRoute?.departureTime)}
                        </div>
                    </>}
            </Paper>
        </div>
    );

    const weatherConditionsComponent = (
        <div className={'route-weather-flags'}>
            <Card variant="outlined" className={'route-details-card'}>
                <CardContent className={'card-title'}>WEATHER CONDITIONS</CardContent>
                <CardContent className={'card-content'}>{getWeatherFlags()}</CardContent>
            </Card>
        </div>
    );

    const selectionComponent = (
        <>
            <header style={{ padding: '12px 24px 0', display: 'flex', flexDirection: 'row' }}>
                <Tabs
                    value={cachedRoute?.selectedRouteOption ? parseInt(cachedRoute.selectedRouteOption.split(' ')[2]) - 1 : 0}
                    onChange={(eventevent, newValue) => cachedRoute && setCachedRoute({ ...cachedRoute, selectedRouteOption: `Route Option ${parseInt(newValue) + 1}` })}
                    indicatorColor={'primary'}
                    textColor={'primary'}
                    style={{ height: 40, width: '100%' }}
                >
                    {cachedRoute?.latestRouteResults?.map((_, index) => <Tab key={`RouteReportTab ${index}`} label={`Route ${String.fromCharCode(index + 'A'.charCodeAt(0))}`} style={{ minWidth: "33%" }} />)}
                </Tabs>
                <div style={{ flex: '1 1 0' }} />
                <IconButton
                    color={'primary'}
                    onClick={() => setCollapsed(!collapsed)}
                    style={{ height: 40 }}
                >
                    {collapsed ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                </IconButton>
            </header>

            <div style={{ width: '100%', height: 1, backgroundColor: '#000000' }} />
            {!collapsed && <div className={'route-option-info-content'}>
                <div className={'route-details'}>
                    <Card variant="outlined" className={'route-details-card'} style={{ borderColor: getImpactStyling('color'), borderWidth: 5 }}>
                        <CardContent className={'card-title'}>OVERALL IMPACT</CardContent>
                        <CardContent className={'card-content'}>{getImpactStyling('word')}</CardContent>
                    </Card>
                    <div style={{ width: 15 }} />
                    <Card variant="outlined" className={'route-details-card'}>
                        <CardContent className={'card-title'}>EST TRAVEL TIME</CardContent>
                        <CardContent className={'card-content'}>{getTravelTime()}</CardContent>
                    </Card>
                </div>

                {weatherConditionsComponent}

                <div className={'select-route'}>
                    <Button variant="contained" className={'select-route-button'} onClick={() => setReportMode('navigation')}>SELECT ROUTE AND START</Button>
                </div>
            </div>}
        </>
    );

    const navigationComponent = (
        <>
            <div className={'route-option-info-content'}>
                {getCurrentNavigationWarning()}
            </div>
            <div style={{ width: '100%', height: 1, backgroundColor: '#000000' }} />
            {!collapsed && getNextNavigationWarning() !== 'Unavailable' && <div className={'route-option-info-content'}>
                {getNextNavigationWarning()}
                {weatherConditionsComponent}
            </div>}
        </>
    );

    const routeOptionInfoComponent = (
        <div className={'route-option-info'}>
            <Paper elevation={3} style={{ backgroundColor: cardBackgroundColor, backgroundImage: 'none', borderRadius: 11 }}>
                {reportMode === 'selection' ? selectionComponent : navigationComponent}
            </Paper>
        </div>
    );

    let polylines: RoadRiskPolylineSegment[] | undefined = undefined;
    if (cachedRoute?.latestRouteResults !== undefined) {
        polylines = [];
        // reversing the order of the route options polylines so when they overlap they are drawn with lower options on top (option 1 on top of option 2)
        // when the overlapping segment of polylines is clicked the top option is selected
        cachedRoute?.latestRouteResults.slice().reverse().forEach((routeResult, i) => {
            // the index needs to reversed since the iteration is reversed so i = 0 is the last route option
            // realRouteIndex is 1-indexed up to length
            const realRouteIndex = cachedRoute!.latestRouteResults!.length - i;
            // draw all routes in selection mode but only selected route in navigation mode
            if (reportMode === 'selection' || (cachedRoute.selectedRouteOption && parseInt(cachedRoute.selectedRouteOption.split(' ')[2]) === realRouteIndex)) {
                routeResult.roadRiskPolylines?.forEach((polyline) => {
                    const route = { ...cachedRoute };
                    route.selectedRouteOption = `Route Option ${realRouteIndex}`;
                    polylines!.push({ ...polyline, route: route });
                });
            }
        });
    }

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={darkModeTheme}>
                {cachedRoute ? <div className={'RouteReport'}>
                    <RouteAndMarkerMap
                        {...defaultRouteAndMarkerMapProps}

                        initialCenter={{ lat: 39.82, lng: -98.57 }}
                        zoomLevel={8}

                        isLocationsVisible={false}
                        savedCities={[]}
                        timeframe={undefined}

                        origin={cachedRoute?.origin}
                        destination={cachedRoute?.destination}
                        waypoints={cachedRoute?.waypoints}
                        selectedRouteOption={cachedRoute?.selectedRouteOption}
                        roadRiskPolylines={polylines}
                        isRouteReport={true}
                        routeReportMode={reportMode}
                        trackingMode={trackingMode}

                        onCitySelected={(city) => { }}
                        onRouteSelected={(route) => setCachedRoute(route)}
                        onCenterChanged={(_center) => { }}
                        onZoomLevelChanged={(zoomLevel) => { }}
                        onNotificationsAvailable={(newNotifications) => setNotifications(newNotifications)}
                        onTrackingModeToggled={(trackingMode) => setTrackingMode(trackingMode)}
                        onPolylineSelected={(polyline) => setSelectedPolyline(polyline)}
                    />
                    {!collapsed && routeInfoComponent}
                    {routeOptionInfoComponent}
                    <div className={`route-legend ${collapsed ? 'collapsed-route-legend' : reportMode === 'selection' ? 'selection-route-legend' : 'navigation-route-legend'}`}>
                        <RoadRiskLegend />
                    </div>
                </div>
                    :
                    <div className={'RouteReport'} style={{ backgroundColor: cardBackgroundColor, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        {cachedRoute === undefined ? <CircularProgress /> : <p>This route has expired.</p>}
                    </div>}
            </ThemeProvider>
        </StyledEngineProvider>
    );
};


