import * as React from "react";
import * as turf from "@turf/turf";
import './index.css';
import { Redirect } from "react-router";
import moment from "moment";
import { RouteAndMarkerMap, defaultRouteAndMarkerMapProps } from "../../shared/RouteAndMarkerMap";
import { ImpactMapType, MapType, MaskType } from "src/types/MapType";
import { AlertData, Blurb, BlurbsByIndex, CycloneData, EarthquakeData, EventData, FireData, ImpactTag, ImpactTileset, ImpactTilesetsByIndex, LightningData, LoadableResult, LocationData, PowerOutageData, RatingsDataWithLocationInfo, RoadClosureData, RoadStatusData, RoadWorkData, SelectedCityState, SpecialEventData, StormData, StormReportData, TrafficCongestionData, TrafficIncidentData, TruckWarningData, UserState, VehicleTrackingData, ViewportData, VolcanoData, WeatherData, WeatherStationData, WeatherTilesetsByIndex, WildfireData, getEventDescription, getPointForEvent, haversineDistance, isLatLngInViewport } from "src/types";
import { ALL_RATING_KEYS, RatingKey, formatRatingKey, isImpactKey, isWeatherKey } from "src/types/RatingKey";
import { TimeSeriesGraphView } from "../Impact/TimeSeriesGraphView";
import { Button, Chip, CircularProgress, FormControlLabel, IconButton, MenuItem, Paper, Select, Switch, Tab, Tabs, Typography } from "@mui/material";
import { ImpactMapSection, getClientImpactSections, getClientRadarSection } from "../Impact/sections";
import { Close, KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { cardBackgroundColor } from "src/constants";
import { ImpactLevel, darkTagColorForImpactLevel, wordForImpactLevel } from "src/types/routes";
import { TilesetUtils } from "../Impact";
import { LegendComponent } from "../Impact/LegendComponent";
import { MapControlsComponent } from "src/components/shared/MapControlsComponent";
import AlertTimelineView from "./AlertTimelineView";
import NowcastMenu from "./NowcastMenu";
import { exportCSVFile, snakeCaseToTitleCase } from "src/util";
import { RoadStatusLegendComponent } from "../Dashboard/RoadStatusLegendComponent";
import { AlertsLegend } from "../Alerts/AlertsLegend";
import { Bounds } from "google-map-react";
import { AssetFilter, doesLocationPassFilterSubhourly, doesVehiclePassFilterSubhourly } from "src/types/AssetFilter";
import { isEqual } from "lodash";
import { StormReportsLegendComponent } from "../Dashboard/StormReportLegendComponent";
import { EventCalloutView } from "./EventCalloutView";
import { ExportIcon } from "../Dashboard/ImpactDetailView";
import { calculateLightningOpacityFromAge } from "../Impact/MapManagerV2";
import VehicleTimelineView from "./VehicleTimelineView";

export interface Props {
    userData?: UserState;

    selectedCityState: SelectedCityState;
    selectedCity?: LocationData;
    selectedVehicle?: VehicleTrackingData;
    selectedMapCategorySlug: string;
    selectedGraphView: string;
    selectedMapType: ImpactMapType;

    impactTilesets?: ImpactTilesetsByIndex;
    subhourlyImpactTilesets?: ImpactTilesetsByIndex;
    subhourlyWeatherTilesets?: WeatherTilesetsByIndex;
    speedIncrements: number[];
    selectedSpeedMultiplierIndex: number;
    baseAnimationDelay: number;
    tileOpacity: number | undefined;

    ratingsData?: RatingsDataWithLocationInfo;
    weatherData?: WeatherData;
    blurbs: BlurbsByIndex;
    isCalloutViewVisible?: boolean;

    governmentalAlerts: LoadableResult<AlertData[]>;

    cyclones?: CycloneData[];
    earthquakes?: EarthquakeData[];
    lightning?: LightningData[];
    powerOutages?: PowerOutageData[];
    storms?: StormData[];
    stormReports?: StormReportData[];
    volcanoes?: VolcanoData[];
    fires?: FireData[];
    wildfires?: WildfireData[];

    roadStatus?: RoadStatusData[];
    roadWork?: RoadWorkData[];
    roadClosures?: RoadClosureData[];
    specialEvents?: SpecialEventData[];
    trafficCongestion?: TrafficCongestionData[];
    trafficIncidents?: TrafficIncidentData[];
    truckWarnings?: TruckWarningData[];
    weatherStations?: WeatherStationData[];

    isCyclonesVisible: boolean;
    isEarthquakesVisible: boolean;
    isLightningVisible: boolean;
    isPowerOutagesVisible: boolean;
    isStormsVisible: boolean;
    isStormReportsVisible: boolean;
    isVolcanoesVisible: boolean;
    isFiresVisible: boolean;
    isWildfiresVisible: boolean;

    isRoadStatusVisible: boolean;
    isRoadWorkVisible: boolean;
    isRoadClosuresVisible: boolean;
    isSpecialEventsVisible: boolean;
    isTrafficCongestionVisible: boolean;
    isTrafficIncidentsVisible: boolean;
    isTruckWarningsVisible: boolean;
    isWeatherStationsVisible: boolean;

    selectedCyclone?: CycloneData;
    selectedEarthquake?: EarthquakeData;
    selectedLightning?: LightningData;
    selectedPowerOutage?: PowerOutageData;
    selectedStorm?: StormData;
    selectedStormReport?: StormReportData;
    selectedVolcano?: VolcanoData;
    selectedFire?: FireData;
    selectedWildfire?: WildfireData;

    selectedRoadStatus?: RoadStatusData;
    selectedRoadWork?: RoadWorkData;
    selectedRoadClosure?: RoadClosureData;
    selectedSpecialEvent?: SpecialEventData;
    selectedTrafficCongestion?: TrafficCongestionData;
    selectedTrafficIncident?: TrafficIncidentData;
    selectedTruckWarning?: TruckWarningData;
    selectedWeatherStation?: WeatherStationData;

    showDisruptionIndex: boolean;
    showWildfireIndices: boolean;

    onCitySelected: (city: LocationData | undefined) => void;
    onVehicleSelected: (vehicle: VehicleTrackingData | undefined) => void;
    onVehicleMessageRequested: (vehicle: VehicleTrackingData, message: string, urgent: boolean) => void;

    onSetCurrentTileset: (tileset: ImpactTileset) => void;
    onAnimationSpeedChanged: (selectedSpeedMultiplierIndex: number, animationDelay: number) => void;
    onSetTileOpacity: (tileOpacity: number) => void;

    onLoadEvents: () => void;
    onLoadRoadStatus: () => void;
    onReloadSelectedCity: (selectedCity: LocationData, takeb: string) => void;
    onReloadSelectedCityData: () => void;
    onCyclonesToggled: (toggled: boolean) => void;
    onEarthquakesToggled: (toggled: boolean) => void;
    onLightningToggled: (toggled: boolean) => void;
    onPowerOutagesToggled: (toggled: boolean) => void;
    onStormsToggled: (toggled: boolean) => void;
    onStormReportsToggled: (toggled: boolean) => void;
    onVolcanoesToggled: (toggled: boolean) => void;
    onFiresToggled: (toggled: boolean) => void;
    onWildfiresToggled: (toggled: boolean) => void;

    onCycloneSelected: (cyclone: CycloneData | undefined) => void;
    onEarthquakeSelected: (earthquake: EarthquakeData | undefined) => void;
    onLightningSelected: (lightning: LightningData | undefined) => void;
    onPowerOutageSelected: (powerOutage: PowerOutageData | undefined) => void;
    onStormSelected: (storm: StormData | undefined) => void;
    onStormReportSelected: (stormReport: StormReportData | undefined) => void;
    onVolcanoSelected: (volcano: VolcanoData | undefined) => void;
    onFireSelected: (fire: FireData | undefined) => void;
    onWildfireSelected: (wildfire: WildfireData | undefined) => void;
    onClearSelectedEvent: () => void;

    onRoadStatusSelected: (roadStatus: RoadStatusData | undefined) => void;
    onRoadWorkSelected: (roadWork?: RoadWorkData) => void;
    onRoadClosureSelected: (roadClosure?: RoadClosureData) => void;
    onSpecialEventSelected: (specialEvent?: SpecialEventData) => void;
    onTrafficCongestionSelected: (trafficCongestion?: TrafficCongestionData) => void;
    onTrafficIncidentSelected: (trafficIncident?: TrafficIncidentData) => void;
    onTruckWarningSelected: (truckWarning?: TruckWarningData) => void;
    onWeatherStationSelected: (weatherStation?: WeatherStationData) => void;
    onClearSelectedRoadCondition: () => void;
    onTrafficLayerSelected: (trafficLayer: string | undefined) => void;

    onMapSelected: (selectedMapCategory: ImpactMapSection, selectedMapType: MapType) => void;
    openedPage: (page: string) => void;

    loadLocation: (locationId: string) => void;
    loadGovernmentalAlertsIfNeeded: () => void;
}

/**
 *  Nowcast Asset Types.
 * Currently map directly to city.userMetadata.category
 * Eventually should include routes, vehicles, etc.
 */
export interface AssetType {
    label: string;
    /**
     * used as a unique id for determing if two asset types are equivalent
     * undefined = 'other' i.e. not marked with a category
     */
    category: string | undefined;
}

export const otherAssetType: AssetType = { label: 'Other', category: undefined };

export function cityMatchesAssetType(city: LocationData, assetType: AssetType) {
    if (assetType.category === undefined) {
        return !city.userMetadata || !city.userMetadata.category;
    } else {
        return city.userMetadata && city.userMetadata.category === assetType.category;
    }
}

export function cityMatchesAssetTypes(city: LocationData, assetTypes: AssetType[]) {
    return assetTypes.some(assetType => cityMatchesAssetType(city, assetType));
}

export function vehicleMatchesAssetType(vehicle: VehicleTrackingData, assetType: AssetType) {
    if (assetType.category === undefined) {
        return vehicle.externalGroupIds.length === 0;
    } else {
        return vehicle.externalGroupIds.includes(assetType.category);
    }
}

export function vehicleMatchesAssetTypes(vehicle: VehicleTrackingData, assetTypes: AssetType[]) {
    return assetTypes.some(assetType => vehicleMatchesAssetType(vehicle, assetType));
}

export interface NearbyLightning {
    within10Miles: number;
    within20Miles: number;
    within30Miles: number;

    closestStrike?: LightningData;
    closestDistance?: number;
}

export const getAssetName = (asset: LocationData | VehicleTrackingData): string => {
    if ((asset as any).assetType === 'vehicle') {
        const vehicle = asset as VehicleTrackingData;
        return (vehicle.name ?? "").length > 0 ? vehicle.name! : vehicle.externalId;
    } else {
        const location = asset as LocationData;
        return location.name;
    }
};

const getAssetImpactLevel = (asset: LocationData | VehicleTrackingData): ImpactLevel => {
    if ((asset as any).assetType === 'vehicle') {
        const vehicle = asset as VehicleTrackingData;
        return vehicle.currentImpact?.overallImpactLevel ?? ImpactLevel.Unknown;
    } else {
        const location = asset as LocationData;
        return location.subhourlyImpactSummary?.impactLevel ?? ImpactLevel.Unknown;
    }
};

export const ClientNowcastPage = (props: Props) => {
    // by checking if the user is present in this wrapper component
    // we can have a non null userData in the inner portion
    if (props.userData === undefined || props.userData?.token === undefined) {
        return <Redirect to={"/logout"} />;
    }
    return <ClientNowcastPageInner {...props} userData={props.userData!} />;
};

const ClientNowcastPageInner = ({
    userData, selectedCity, selectedVehicle, weatherData, ratingsData, blurbs, impactTilesets, subhourlyImpactTilesets, subhourlyWeatherTilesets, tileOpacity, governmentalAlerts,
    cyclones, earthquakes, lightning, powerOutages, storms, stormReports, volcanoes, fires, wildfires, selectedMapType, selectedMapCategorySlug,
    selectedCyclone, selectedEarthquake, selectedLightning, selectedPowerOutage, selectedStorm, selectedStormReport, selectedVolcano, selectedFire, selectedWildfire,
    roadStatus, roadWork, roadClosures, specialEvents, trafficCongestion, trafficIncidents, truckWarnings, weatherStations,
    selectedRoadStatus, selectedRoadWork, selectedRoadClosure, selectedSpecialEvent, selectedTrafficCongestion, selectedTrafficIncident, selectedTruckWarning, selectedWeatherStation,
    isRoadStatusVisible, isRoadWorkVisible, isRoadClosuresVisible, isSpecialEventsVisible, isTrafficCongestionVisible, isTrafficIncidentsVisible, isTruckWarningsVisible, isWeatherStationsVisible,
    isCyclonesVisible, isEarthquakesVisible, isLightningVisible, isPowerOutagesVisible, isStormsVisible, isStormReportsVisible, isVolcanoesVisible, isFiresVisible, isWildfiresVisible,
    showDisruptionIndex, showWildfireIndices, selectedCityState, selectedGraphView, speedIncrements, selectedSpeedMultiplierIndex, baseAnimationDelay,
    onCitySelected, onVehicleSelected, onReloadSelectedCity, onLoadEvents, onLoadRoadStatus, onMapSelected, onReloadSelectedCityData, loadGovernmentalAlertsIfNeeded, openedPage, onAnimationSpeedChanged, onSetTileOpacity, onTrafficLayerSelected,
    onCyclonesToggled, onEarthquakesToggled, onLightningToggled, onPowerOutagesToggled, onStormsToggled, onStormReportsToggled, onVolcanoesToggled, onFiresToggled, onWildfiresToggled,
    onCycloneSelected, onEarthquakeSelected, onLightningSelected, onPowerOutageSelected, onStormSelected, onStormReportSelected, onVolcanoSelected, onFireSelected, onWildfireSelected, onClearSelectedEvent,
    onRoadStatusSelected, onRoadWorkSelected, onRoadClosureSelected, onSpecialEventSelected, onTrafficCongestionSelected, onTrafficIncidentSelected, onTruckWarningSelected, onWeatherStationSelected, onClearSelectedRoadCondition, onVehicleMessageRequested,
}: Required<Pick<Props, 'userData'>> & Props) => {
    const [zoomLevel, setZoomLevel] = React.useState(5);
    const [selectedChartView, setSelectedChartView] = React.useState(selectedGraphView);
    const [selectedChartKey, setSelectedChartKey] = React.useState<string>(selectedMapType.ratingKey);

    const [alerts, setAlerts] = React.useState<AlertData[]>([]);
    const [selectedAlert, setSelectedAlert] = React.useState<AlertData | undefined>(undefined);

    const [isAlertsVisible, setIsAlertsVisible] = React.useState(false);
    // state to hold the list of filtered cities actually passed to the map.
    const [filteredCities, setFilteredCities] = React.useState<LocationData[]>([]);

    // state to hold the list of filtered vehicles actually passed to the map.
    const [filteredVehicles, setFilteredVehicles] = React.useState<VehicleTrackingData[]>([]);

    // state to hold the list of asset types to filter by
    const [locationAssetTypeOptions, setLocationAssetTypeOptions] = React.useState<AssetType[]>([otherAssetType]);
    const [locationSelectedAssetTypes, setLocationSelectedAssetTypes] = React.useState<AssetType[]>([otherAssetType]);
    const [locationCountsByAssetCategory, setLocationCountsByAssetCategory] = React.useState<Record<string, number>>({});

    const [vehicleAssetTypeOptions, setVehicleAssetTypeOptions] = React.useState<AssetType[]>([otherAssetType]);
    const [vehicleSelectedAssetTypes, setVehicleSelectedAssetTypes] = React.useState<AssetType[]>([otherAssetType]);
    const [vehicleCountsByAssetCategory, setVehicleCountsByAssetCategory] = React.useState<Record<string, number>>({});

    // list of alerts to use for filter options
    const [locationAlerts, setLocationAlerts] = React.useState<string[]>([]);
    const [vehicleAlerts, setVehicleAlerts] = React.useState<string[]>([]);

    const locationAssetTypeOptionsRef = React.useRef<AssetType[]>([]);
    const vehicleAssetTypeOptionsRef = React.useRef<AssetType[]>([]);
    const citiesRef = React.useRef<LocationData[]>([]);
    const vehiclesRef = React.useRef<VehicleTrackingData[]>([]);

    const [filters, setFilters] = React.useState<AssetFilter[]>([]);

    const [showModelForecastTracksForCycloneId, setShowModelForecastTracksForCycloneId] = React.useState<string | undefined>(undefined);

    const selectedCityRunTime = selectedCity?.subhourlyImpactSummary?.sourceRunTime?.getTime();
    const weatherDataRunTime = weatherData?.subhourly?.at(0)?.time.getTime();

    const getFilteredLocations = (locations: LocationData[]): LocationData[] => {
        return locations.filter(city => cityMatchesAssetTypes(city, locationSelectedAssetTypes))
            .filter(city => {
                return filters.every(filter => doesLocationPassFilterSubhourly(city, filter));
            });
    };

    // effect to prepopulate filtered cities & asset type options from the first successful load
    React.useEffect(() => {
        const validCities = userData.cities.filter(city => city.subhourlyImpactSummary?.sourceRunTime !== undefined);
        if (filteredCities.length === 0) {
            setFilteredCities(getFilteredLocations(validCities));
        }

        const allUserTypes = validCities.map(city => city.userMetadata ? city.userMetadata['category'] : undefined).filter(type => type !== undefined);
        const uniqueUserTypes = Array.from(new Set(allUserTypes));

        const newAssetTypeOptions = uniqueUserTypes.map((category: string) => {
            // title-case category for label
            return { label: snakeCaseToTitleCase(category), category };
        });
        setLocationAssetTypeOptions([...newAssetTypeOptions, otherAssetType]);

        const locAlerts: Set<string> = new Set();
        validCities.forEach(city => {
            city.subhourlyImpactSummary?.tags.forEach(tag => {
                if (tag.source === 'governmental_alert') locAlerts.add(tag.text);
            });
        });
        setLocationAlerts(Array.from(locAlerts));
    }, [userData.cities, userData.id]);

    // effect to prepopulate filtered cities & asset type options from the first successful load
    React.useEffect(() => {
        if (filteredVehicles.length === 0) {
            setFilteredVehicles(userData.vehicles);
        }

        const allGroupIds: string[] = userData.vehicles.flatMap(vehicle => vehicle.externalGroupIds);
        const uniqueGroupIds = Array.from(new Set(allGroupIds));

        const newAssetTypeOptions = uniqueGroupIds.map((groupId: string): AssetType | undefined => {
            // TODO: clean up these 'hacks' for Ryder
            // filter out default groups from geotab
            if (groupId.startsWith('Group')) {
                return undefined;
            }

            // hardcode groups for Ryder
            if (process.env.REACT_APP_ENV === 'staging' && userData.id === 2626 || process.env.REACT_APP_ENV === 'production' && userData.id === 2715) {
                const idToNameMapping = {
                    'b2845': '04352 FL POWER N LIGHT',
                    'b27FE': '07974 True Value Atlanta',
                    'b296A': '04657 - SHELL LA',
                    'b2B89': '07350 Goodyear GA',
                    'b2893': '07367 True Value KC',
                } as any;
                return { label: idToNameMapping[groupId] || groupId, category: groupId };
            }
            // hardcode groups for JB Hunt
            if (process.env.REACT_APP_ENV === 'staging' && userData.id === 2628 || process.env.REACT_APP_ENV === 'production' && userData.id === 2720) {
                const idToNameMapping = {
                    'b27B1': 'All Vehicles',
                    'b3820': 'Trailer - OnDemand Tracking',
                } as any;
                return { label: idToNameMapping[groupId] || groupId, category: groupId };
            }
            return { label: groupId, category: groupId };
        }).filter((x): x is AssetType => x !== undefined);

        setVehicleAssetTypeOptions([...newAssetTypeOptions, otherAssetType]);

        const vehAlerts: Set<string> = new Set();
        userData.vehicles.forEach(vehicle => {
            vehicle.currentImpact?.tags.forEach(tag => {
                if (tag.source === 'governmental_alert') vehAlerts.add(tag.text);
            });
        });
        setVehicleAlerts(Array.from(vehAlerts));
    }, [userData.vehicles, userData.id]);

    // effect to set the selected asset types to all options when the options change, if the user had already selected all
    // also sets the counts by asset type when the options change
    React.useEffect(() => {
        const previousAssetTypeOptions = locationAssetTypeOptionsRef.current;
        const previousSelectedAssetTypeOptions = locationSelectedAssetTypesRef.current;
        const firstLoad = previousAssetTypeOptions.length === 0;
        const hadSelectedAllPreviousOptions = previousAssetTypeOptions.every(assetType => previousSelectedAssetTypeOptions.find(at => at.category === assetType.category));
        if (firstLoad || hadSelectedAllPreviousOptions) {
            // select all options
            setLocationSelectedAssetTypes([...locationAssetTypeOptions]);
        }

        const haveOptionsChanged = locationAssetTypeOptions.some(assetType => previousAssetTypeOptions.find(at => at.category === assetType.category) === undefined);
        const haveCitiesChanged = filteredCities !== citiesRef.current;
        if (haveOptionsChanged || haveCitiesChanged) {
            let counts: Record<string, number> = {};
            const validCities = userData.cities?.filter(city => city.subhourlyImpactSummary?.sourceRunTime !== undefined);
            locationAssetTypeOptions.forEach(assetType => {
                counts[assetType.category || ''] = validCities.filter(city => cityMatchesAssetType(city, assetType)).length;
            });

            setLocationCountsByAssetCategory(counts);
        }

        locationAssetTypeOptionsRef.current = locationAssetTypeOptions;
    }, [locationAssetTypeOptions]);

    React.useEffect(() => {
        const previousAssetTypeOptions = vehicleAssetTypeOptionsRef.current;
        const previousSelectedAssetTypeOptions = vehicleSelectedAssetTypesRef.current;
        const firstLoad = previousAssetTypeOptions.length === 0;
        // if the length is the same for both previous ones, then all of the options were selected
        const hadSelectedAllPreviousOptions = previousAssetTypeOptions.every(assetType => previousSelectedAssetTypeOptions.find(at => at.category === assetType.category));
        if (firstLoad || hadSelectedAllPreviousOptions) {
            // select all options
            setVehicleSelectedAssetTypes([...vehicleAssetTypeOptions]);
        }

        const haveOptionsChanged = vehicleAssetTypeOptions.some(assetType => previousAssetTypeOptions.find(at => at.category === assetType.category) === undefined);
        const haveVehiclesChanged = userData.vehicles !== vehiclesRef.current;
        if (haveOptionsChanged || haveVehiclesChanged) {
            let counts: Record<string, number> = {};
            vehicleAssetTypeOptions.forEach(assetType => {
                counts[assetType.category || ''] = userData.vehicles.filter(vehicle => vehicleMatchesAssetType(vehicle, assetType)).length;
            });

            setVehicleCountsByAssetCategory(counts);
        }

        vehicleAssetTypeOptionsRef.current = vehicleAssetTypeOptions;
    }, [vehicleAssetTypeOptions]);

    const filtersRef = React.useRef<AssetFilter[]>([]);
    const locationSelectedAssetTypesRef = React.useRef<AssetType[]>([]);
    const vehicleSelectedAssetTypesRef = React.useRef<AssetType[]>([]);

    // effect to set filtered cities when the user selects an asset type filter
    React.useEffect(() => {
        const hasChangedLocationAssetTypes = !isEqual(locationSelectedAssetTypesRef.current, locationSelectedAssetTypes);
        const hasChangedVehicleAssetTypes = !isEqual(vehicleSelectedAssetTypesRef.current, vehicleSelectedAssetTypes);
        const hasChangedFilters = !isEqual(filtersRef.current, filters);
        const hasChangedCities = !isEqual(citiesRef.current, userData.cities);
        const hasChangedVehicles = !isEqual(vehiclesRef.current, userData.vehicles);

        if (!hasChangedLocationAssetTypes && !hasChangedVehicleAssetTypes && !hasChangedFilters && !hasChangedCities && !hasChangedVehicles) return;

        // console.log("APPLYING FILTERS ON MAPs", userData.cities.length, selectedAssetTypes.length, filters);

        // console.log("all tags", new Set(userData.cities.map(city => city.subhourlyImpactSummary?.tags?.filter(tag => tag.source !== 'governmental_alert' && tag.source !== 'wo_storms').map(tag => tag.text).filter(tag => tag !== undefined)).flat()));
        // console.log("all tags sources", new Set(userData.cities.map(city => city.subhourlyImpactSummary?.tags?.filter(tag => tag.source !== 'governmental_alert').map(tag => tag.source).filter(tag => tag !== undefined)).flat()));

        setFilteredCities(getFilteredLocations(userData.cities));

        setFilteredVehicles(
            userData.vehicles
                .filter(vehicle => vehicleMatchesAssetTypes(vehicle, vehicleSelectedAssetTypes))
                .filter(vehicle => {
                    return filters.every(filter => doesVehiclePassFilterSubhourly(vehicle, filter));
                })
        );

        locationSelectedAssetTypesRef.current = locationSelectedAssetTypes;
        vehicleSelectedAssetTypesRef.current = vehicleSelectedAssetTypes;
        filtersRef.current = filters;
        citiesRef.current = filteredCities;
        vehiclesRef.current = userData.vehicles;
    }, [locationSelectedAssetTypes, vehicleSelectedAssetTypes, filters, userData]);

    const mapCategories = getClientImpactSections(showDisruptionIndex, showWildfireIndices);
    const impactCategory = mapCategories.find(category => category.slug === 'impact-indices');
    const weatherCategory = mapCategories.find(category => category.slug === 'weather-indices');
    const radarCategory = getClientRadarSection();

    // const radarMapTypes = getClientRadarSection().mapTypes;
    const [paused, setPaused] = React.useState<boolean>(false);
    const timerRef = React.useRef<NodeJS.Timeout | undefined>(undefined);

    const [tilingLayer, setTilingLayer] = React.useState<string | undefined>(undefined);

    const [trafficLayer, setTrafficLayer] = React.useState<string | undefined>(undefined);

    // const [mapBounds, setMapBounds] = React.useState<Bounds | undefined>(undefined);
    const [visibleTileset, setVisibleTileset] = React.useState<ImpactTileset | undefined>(undefined);
    const [desiredTileset, setDesiredTileset] = React.useState<ImpactTileset | undefined>(undefined);

    const [selectedLegend, setSelectedLegend] = React.useState<string | undefined>(undefined);
    const [legendCollapsed, setLegendCollapsed] = React.useState(false);
    const [currentLegends, setCurrentLegends] = React.useState<any>([]);

    const [mapBounds, setMapBounds] = React.useState<Bounds | undefined>(undefined);

    const [currentStorms, setCurrentStorms] = React.useState<StormData[] | undefined>(undefined);
    const [nearbyLightning, setNearbyLightning] = React.useState<NearbyLightning | undefined>(undefined);

    const [selectedEvent, setSelectedEvent] = React.useState<EventData | undefined>(undefined);
    const [now, setNow] = React.useState<Date>(new Date());

    const shouldShowMapCalloutForEvent = (event: EventData) => {
        return ['volcano', 'earthquake', 'fire', 'lightning', 'storm_report', 'road_status', 'weather_station', 'power_outage', 'traffic_congestion', 'traffic_incident', 'road_work', 'road_closure', 'special_event', 'trucker_warning'].includes(event.type);
    };
    const showMapCalloutForSelectedEvent = selectedEvent && shouldShowMapCalloutForEvent(selectedEvent);

    React.useEffect(() => {
        openedPage('nowcast');
        onLoadEvents();
        onLoadRoadStatus();
        const interval = setInterval(() => {
            setNow(new Date());
        }, 60 * 1000);
        return () => clearInterval(interval);
    }, []);

    React.useEffect(() => {
        let cs = storms || [];
        setCurrentStorms(cs.filter(storm => storm.startTime <= timeframe.endTime.toDate() && storm.endTime >= timeframe.startTime.toDate()));
    }, [storms]);

    React.useEffect(() => {
        if (!selectedCity) return;
        if (userData.citiesMetadata.loading) return;
        if (selectedCityRunTime === weatherDataRunTime) return;
        if (selectedCityRunTime === undefined || weatherDataRunTime === undefined) return;
        if (selectedCityRunTime && weatherDataRunTime) {
            if (selectedCityRunTime < weatherDataRunTime) {
                onReloadSelectedCity(selectedCity, userData.token!);
            } else {
                onReloadSelectedCityData();
            }
        }
    }, [selectedCityRunTime, weatherDataRunTime]);

    React.useEffect(() => {
        if (selectedCity) {
            setSelectedAlert(undefined);
            onClearSelectedEvent();
            if (selectedCity?.id !== undefined) {
                const cityAlerts: AlertData[] = [];
                selectedCity.subhourlyImpactSummary?.tags.forEach(tag => {
                    if (tag.source === 'governmental_alert' && governmentalAlerts.value) {
                        const existingGovAlert = governmentalAlerts.value!.find(alert => alert.id === tag.id);
                        if (existingGovAlert) cityAlerts.push(existingGovAlert);
                    }
                });
                setAlerts(cityAlerts);
            }
        }
    }, [selectedCity]);

    // TODO: clean up the selection logic to clear all other selected items when
    // something is selected on the map?
    const clearSelectedItems = ({ chosenAlert, chosenEvent, chosenVehicle, chosenCity }: { chosenAlert?: AlertData; chosenEvent?: EventData, chosenVehicle?: VehicleTrackingData; chosenCity?: LocationData }) => {
        // if we just chose an event, but we're just going to show a callout
        // then we don't need to deselect anything else so return early
        if (chosenEvent && shouldShowMapCalloutForEvent(chosenEvent)) {
            return;
        }

        if (!chosenAlert && selectedAlert) {
            setSelectedAlert(undefined);
        }
        // we only need to deselect the event if we show the bottom large info box
        // callouts can stay (however, if another event is selected, it will remove the callout anyway)
        if (!chosenEvent && selectedEvent && !shouldShowMapCalloutForEvent(selectedEvent)) {
            setSelectedEvent(undefined);
        }
        if (!chosenVehicle && selectedVehicle) {
            onVehicleSelected(undefined);
        }
        if (!chosenCity && selectedCity) {
            onCitySelected(undefined);
        }
    };
    React.useEffect(() => {
        if (selectedAlert) {
            clearSelectedItems({ chosenAlert: selectedAlert });
        }
    }, [selectedAlert]);
    React.useEffect(() => {
        if (selectedEvent) {
            clearSelectedItems({ chosenEvent: selectedEvent });
        }
    }, [selectedEvent]);
    React.useEffect(() => {
        if (selectedVehicle) {
            clearSelectedItems({ chosenVehicle: selectedVehicle });
        }
    }, [selectedVehicle]);
    React.useEffect(() => {
        if (selectedCity) {
            clearSelectedItems({ chosenCity: selectedCity });
        }
    }, [selectedCity]);

    React.useEffect(() => {
        if (selectedVehicle) {
            setSelectedChartView('current_conditions');
        }
    }, [selectedVehicle?.id]);
    React.useEffect(() => {
        if (selectedCity) {
            setSelectedChartView('rating');
        }
    }, [selectedCity?.id]);

    React.useEffect(() => {
        if (isAlertsVisible) {
            loadGovernmentalAlertsIfNeeded();
        } else {
            setSelectedAlert(undefined);
        }
    }, [isAlertsVisible]);

    React.useEffect(() => {
        if (selectedCity?.id === undefined && isAlertsVisible) {
            const weatherAlerts = weatherData?.alerts ?? [];
            setAlerts(weatherAlerts);
            if (weatherAlerts.length > 0) {
                setSelectedAlert(weatherAlerts[0]);
            }
        }
    }, [weatherData?.alerts]);

    React.useEffect(() => {
        if (selectedCyclone) setSelectedEvent(selectedCyclone);
        else if (selectedEarthquake) setSelectedEvent(selectedEarthquake);
        else if (selectedLightning) setSelectedEvent(selectedLightning);
        else if (selectedPowerOutage) setSelectedEvent(selectedPowerOutage);
        else if (selectedStorm) setSelectedEvent(selectedStorm);
        else if (selectedStormReport) setSelectedEvent(selectedStormReport);
        else if (selectedVolcano) setSelectedEvent(selectedVolcano);
        else if (selectedFire) setSelectedEvent(selectedFire);
        else if (selectedWildfire) setSelectedEvent(selectedWildfire);
        else if (selectedRoadStatus) setSelectedEvent(selectedRoadStatus);
        else if (selectedWeatherStation) setSelectedEvent(selectedWeatherStation);
        else if (selectedTrafficCongestion) setSelectedEvent(selectedTrafficCongestion);
        else if (selectedTrafficIncident) setSelectedEvent(selectedTrafficIncident);
        else if (selectedRoadWork) setSelectedEvent(selectedRoadWork);
        else if (selectedRoadClosure) setSelectedEvent(selectedRoadClosure);
        else if (selectedSpecialEvent) setSelectedEvent(selectedSpecialEvent);
        else if (selectedTruckWarning) setSelectedEvent(selectedTruckWarning);
        else setSelectedEvent(undefined);
    }, [selectedCyclone, selectedEarthquake, selectedLightning, selectedPowerOutage, selectedStorm, selectedStormReport, selectedVolcano, selectedFire, selectedWildfire,
        selectedRoadStatus, selectedWeatherStation, selectedTrafficCongestion, selectedTrafficIncident, selectedRoadWork, selectedRoadClosure, selectedSpecialEvent, selectedTruckWarning]);

    React.useEffect(() => {
        if (isRoadStatusVisible) setTrafficLayer('road-status');
        else if (isRoadWorkVisible) setTrafficLayer('road-work');
        else if (isRoadClosuresVisible) setTrafficLayer('road-closures');
        else if (isTrafficCongestionVisible) setTrafficLayer('traffic');
        else if (isTrafficIncidentsVisible) setTrafficLayer('traffic-incidents');
        else if (isTruckWarningsVisible) setTrafficLayer('truck-warnings');
        else setTrafficLayer(undefined);
    }, [isRoadStatusVisible, isRoadWorkVisible, isRoadClosuresVisible, isTrafficCongestionVisible, isTrafficIncidentsVisible, isTruckWarningsVisible]);

    React.useEffect(() => {
        if (selectedEvent && !showMapCalloutForSelectedEvent) {
            onCitySelected(undefined);
            setSelectedAlert(undefined);
        }

        if (!selectedEvent) {
            onCycloneSelected(undefined);
            onEarthquakeSelected(undefined);
            onLightningSelected(undefined);
            onPowerOutageSelected(undefined);
            onStormSelected(undefined);
            onStormReportSelected(undefined);
            onVolcanoSelected(undefined);
            onFireSelected(undefined);
            onWildfireSelected(undefined);
            onRoadStatusSelected(undefined);
            onWeatherStationSelected(undefined);
        }
    }, [selectedEvent]);

    React.useEffect(() => {
        const mapCategory = mapCategories.find(x => x.key === selectedChartView);
        if (mapCategory) {
            if (mapCategory.key === 'rating' && isImpactKey(selectedChartKey)) return;
            if (mapCategory.key === 'weather' && (isWeatherKey(selectedChartKey))) return;
            setSelectedChartKey(mapCategory.mapTypes[0].ratingKey);
        }
    }, [selectedChartView]);

    React.useEffect(() => {
        const mapCategory = mapCategories.find(x => x.key === selectedChartView);
        if (mapCategory && selectedChartKey && selectedChartKey !== 'lightning_probability') {
            const mapType = mapCategory.mapTypes.find(mapType => mapType.ratingKey === selectedChartKey);
            onMapSelected(mapCategory, mapType!);
        }
    }, [selectedChartKey]);

    type NowcastLegend = { label: string; key: string; }
    React.useEffect(() => {
        let legends: NowcastLegend[] = [];
        if (tilingLayer !== undefined && tilingLayer !== 'lightning_probability') legends.push({ label: formatRatingKey(tilingLayer) as string, key: tilingLayer as string });
        if (isAlertsVisible) legends.push({ label: 'NWS Alerts', key: 'alerts' });
        if (isRoadStatusVisible) legends.push({ label: 'Driving Conditions', key: 'road_status' });
        if (isLightningVisible) legends.push({ label: 'Lightning', key: 'lightning' });
        if (isStormReportsVisible) legends.push({ label: 'Storm Reports', key: 'storm_reports' });

        setCurrentLegends(legends);
        if (legends.length > 0 && legends.findIndex(legend => legend.key === selectedLegend) === -1) {
            setSelectedLegend(legends[0].key);
        }
    }, [tilingLayer, isAlertsVisible, isRoadStatusVisible, isLightningVisible, isStormReportsVisible]);

    React.useEffect(() => {
        tilingLayer === 'wildfire_spread' ? onWildfiresToggled(true) : onWildfiresToggled(false);

        if (tilingLayer === undefined || tilingLayer === 'lightning_probability') {
            clearTimeout(timerRef.current);
            setDesiredTileset(undefined);
            if (tilingLayer === 'lightning_probability') onLightningToggled(true);
        } else if (radarCategory && tilingLayer === 'radar') {
            onMapSelected(radarCategory, radarCategory.mapTypes[0]);
        } else if (impactCategory && isImpactKey(tilingLayer)) {
            const matchedMap = impactCategory?.mapTypes?.find(map => map.ratingKey === tilingLayer);
            if (matchedMap) {
                onMapSelected(impactCategory, matchedMap);
            }
        } else if (weatherCategory && isWeatherKey(tilingLayer)) {
            const matchedMap = weatherCategory.mapTypes?.find(map => map.ratingKey === tilingLayer);
            if (matchedMap) {
                onMapSelected(weatherCategory, matchedMap);
            }
        }
    }, [tilingLayer]);

    React.useEffect(() => {
        // clear out any auto playback timer
        clearTimeout(timerRef.current);

        //selectedMapType is not the same as tiling layer, maptype set my chart selection
        if (tilingLayer !== selectedMapType.ratingKey) {
            return;
        }

        if (selectedMapType === undefined) {
            console.log('[Nowcast] selectedMapType is undefined');
            return;
        }

        if (tilingLayer === undefined) {
            console.log('[Nowcast] tilingLayer is undefined: setting desired tileset to undefined');
            setDesiredTileset(undefined);
            return;
        }

        //onMapSelected(isImpactKey(selectedMapType.ratingKey) ? mapCategories[0] : mapCategories[1], selectedMapType);

        // try to keep same time index
        const tilesets = getTilesets(tilingLayer);
        let newDesiredTileset: ImpactTileset | undefined = undefined;
        if (tilesets && desiredTileset) {
            newDesiredTileset = TilesetUtils.getTileset(tilesets, desiredTileset.time);
        }
        console.log('[Nowcast] selectedMapType effect: setting desired tileset to', newDesiredTileset ?? tilesets?.[0]);
        setDesiredTileset(newDesiredTileset ?? tilesets?.[0]);
    }, [selectedMapType]);

    React.useEffect(() => {
        if (!isLightningVisible || selectedCity === undefined) {
            setNearbyLightning(undefined);
            return;
        }

        let within10 = 0;
        let within20 = 0;
        let within30 = 0;
        let closestStrike: LightningData | undefined = undefined;
        let closestDistance: number | undefined = undefined;
        const thirtyMinsAgo = moment().subtract(30, 'm');
        lightning?.forEach((strike: LightningData) => {
            if (moment(strike.detectedTime) < thirtyMinsAgo) {
                return;
            }

            const point = getPointForEvent(strike);
            const distance = haversineDistance({ lat: selectedCity.latitude, lng: selectedCity.longitude }, { lat: point.latitude, lng: point.longitude }, true);
            if (distance <= 30) {
                if (distance <= 10) {
                    within10 += 1;
                } else if (distance <= 20) {
                    within20 += 1;
                } else if (distance <= 30) {
                    within30 += 1;
                }

                if (closestDistance === undefined || closestDistance > distance) {
                    closestDistance = distance;
                    closestStrike = strike;
                }
            }
        });
        setNearbyLightning({
            within10Miles: within10,
            within20Miles: within20,
            within30Miles: within30,

            closestStrike,
            closestDistance,
        });
    }, [selectedCity, isLightningVisible, lightning]);

    const getTilesets = (currentTilingLayer: string | undefined): ImpactTileset[] | undefined => {
        if (!currentTilingLayer) {
            return undefined;
        }
        let tilesets: ImpactTileset[] | undefined = subhourlyImpactTilesets?.[currentTilingLayer];
        if (tilesets === undefined) {
            tilesets = subhourlyWeatherTilesets?.[currentTilingLayer];
        }
        // fallback for wildfire spread which is only in hourly tiles
        if (tilesets === undefined && currentTilingLayer === 'wildfire_spread') {
            tilesets = impactTilesets?.[currentTilingLayer];
        }
        if (!tilesets) {
            return undefined;
        }
        if (currentTilingLayer === 'radar') {
            return tilesets;
        }

        if (currentTilingLayer === 'wildfire_spread') {
            return tilesets.slice(0, 1);
        }

        const currentTime = new Date().getTime();
        const startTime = Math.floor(currentTime / 900000) * 900000;
        let forecastHours = 6; // only show 6 hours for nowcast

        const endTimeInclusive = startTime + forecastHours * 3600000;
        // chop off any times from the model run that are later than endTimeInclusive
        return tilesets.filter((tileset: ImpactTileset) => tileset.time.getTime() <= endTimeInclusive);
    };

    const radarLegends = [{
        label: 'Rain', image: 'Radar_TWC_Rain'
    }, {
        label: 'Snow', image: 'Radar_TWC_Snow'
    }];

    let radarLegendElements: JSX.Element | JSX.Element[][] = radarLegends.map(legend => {
        return [
            (<img style={{ "width": "100%", maxWidth: '450px' }} key={legend.label} alt={legend.label} src={`/legends/${legend.image}.svg`} />)
        ];
    });

    const radarLegend = (<div style={{ "padding": "15px 25px 25px 25px" }}>
        <div>{radarLegendElements}</div>
    </div>);

    let tileLegendElements: JSX.Element | undefined = undefined;
    if ((tilingLayer && tilingLayer !== 'radar') && Object.keys(blurbs).length > 0) {
        tileLegendElements = (
            <div>
                <div className={'impact-tile-legend'} style={{ "padding": "10px 15px 10px 0px" }}>
                    <LegendComponent
                        ratingKey={tilingLayer as RatingKey}
                        blurbs={selectedMapType !== undefined ? blurbs[tilingLayer] : []}
                        skipFirstBlurb={true}
                        embedValues={isImpactKey(tilingLayer)}
                        tileOpacity={tileOpacity}
                        setTileOpacity={(tileOpacity) => onSetTileOpacity(tileOpacity)}
                    />
                </div>
            </div>
        );
    }

    const tileLegend = (<div style={{ "padding": "10px 15px 15px 0px" }}>
        <div>{tileLegendElements}</div>
    </div>);

    const goToPreviousTimeOffset = () => {
        if (!desiredTileset || !selectedMapType) {
            return;
        }
        setDesiredTileset(TilesetUtils.getPreviousTileset(getTilesets(tilingLayer), desiredTileset.time));
    };

    const goToNextTimeOffset = () => {
        if (!desiredTileset || !selectedMapType) {
            return;
        }
        // there shouldn't be a case where we want to go to the next time offset
        // when our selectedMapType variable doesn't match our desired tileset variable
        if (tilingLayer !== desiredTileset.variable) {
            console.log('[Nowcast] skipping goToNextTimeOffset because variables are not matching', tilingLayer, desiredTileset);
            return;
        }
        const nextTileset = TilesetUtils.getNextTileset(getTilesets(tilingLayer), desiredTileset.time);
        console.log('[Nowcast] goToNextTimeOffset for', tilingLayer, 'is loading:', nextTileset);
        setDesiredTileset(nextTileset);
    };

    const goToDate = (date: Date) => {
        pause();
        setDesiredTileset(TilesetUtils.getTileset(tilesets, date));
    };

    const resume = () => {
        clearTimeout(timerRef.current);

        timerRef.current = setTimeout(() => goToNextTimeOffset(), 1000);

        setPaused(false);
    };

    const pause = () => {
        clearTimeout(timerRef.current);
        setPaused(true);
    };

    const rewind = () => {
        pause();
        goToPreviousTimeOffset();
    };

    const fastForward = () => {
        pause();
        goToNextTimeOffset();
    };

    const skipToFirst = () => {
        const tilesets = getTilesets(tilingLayer);
        if (tilesets) {
            pause();
            setDesiredTileset(tilesets[0]);
        }
    };

    const skipToLast = () => {
        const tilesets = getTilesets(tilingLayer);
        if (tilesets) {
            pause();
            setDesiredTileset(tilesets[tilesets.length - 1]);
        }
    };

    const cycleSpeedIncrement = () => {
        let speedMultiplierIndex = (selectedSpeedMultiplierIndex + 1) % speedIncrements.length;
        let speedMultiplier = speedIncrements[speedMultiplierIndex];
        let animationDelay = baseAnimationDelay / speedMultiplier;

        onAnimationSpeedChanged(speedMultiplierIndex, animationDelay);
    };

    const onUserSelectedCity = (city: LocationData) => {
        onCitySelected(city);
        // set tiling Chart view to source of hichest impact
        const impactTags = city.subhourlyImpactSummary?.tags;
        if (tilingLayer === undefined) {
            if (impactTags && impactTags.length > 0) {
                impactTagSelected(impactTags[0]);
            }
        }
    };

    const onMapClicked = (coord: google.maps.LatLng) => {
        // only allowing map click to select alerts
        if (!isAlertsVisible) return;

        let city: LocationData = {
            id: undefined,
            zip: '00000',
            name: '',
            latitude: coord.lat(),
            longitude: coord.lng(),
            needsGeocoding: true,
            impactSummaries: []
        };

        onCitySelected(city);
    };

    const impactTagSelected = (tag: ImpactTag) => {
        if (tag.source === 'HYPERR') {
            if (isImpactKey(tag.variable)) setSelectedChartView('rating');
            if (isWeatherKey(tag.variable)) setSelectedChartView('weather');
            setSelectedChartKey(tag.variable!);
        } else if (tag.source === 'governmental_alert') {
            setSelectedChartView('alerts');
        } else if (tag.source === 'wo_storms') {
            onStormSelected(currentStorms?.find(storm => storm.id === tag.id));
        }
    };

    let tilingLayerLegend: JSX.Element | undefined = undefined;
    if (tilingLayer !== undefined) {
        tilingLayerLegend = tilingLayer === 'radar' ? radarLegend : tileLegend;
    }

    let alertsLegend = (userData.portalToken && <AlertsLegend
        portalToken={userData.portalToken}
        mapBounds={mapBounds}
        className="dashboard"
    />);

    const roadStatusLegend = (
        <div className={"dashboard-alerts-legend"} style={{ "padding": "10px 15px 15px 0px", backgroundColor: cardBackgroundColor }}>
            <div>
                <div style={{ "padding": "0px 25px 15px 25px", backgroundColor: cardBackgroundColor }}>
                    <RoadStatusLegendComponent />
                </div>
            </div>
        </div>
    );

    let visibleStormReportsforLegend = stormReports || [];
    if (isStormReportsVisible && mapBounds) {
        const currentViewport: ViewportData = { neLatitude: mapBounds.ne.lat, neLongitude: mapBounds.ne.lng, swLatitude: mapBounds.sw.lat, swLongitude: mapBounds.sw.lng };
        visibleStormReportsforLegend = visibleStormReportsforLegend.filter(stormReport => {
            const point = getPointForEvent(stormReport);
            return isLatLngInViewport({ lat: point.latitude, lng: point.longitude }, currentViewport);
        });
    }

    const stormReportsLegend = (
        <div className={"dashboard-alerts-legend"} style={{ backgroundColor: cardBackgroundColor }}>
            <StormReportsLegendComponent stormReports={visibleStormReportsforLegend} />
        </div>
    );

    const lightningBlurbs: Blurb[] = [30, 25, 20, 15, 10, 5, 0].map(minutesAgo => {
        return {
            blurb: '',
            color: '#00A6CA' + Math.floor(calculateLightningOpacityFromAge(minutesAgo) * 255).toString(16).padStart(2, '0'),
            value: minutesAgo,
        };
    });

    const lightningLegend = (
        <div className={"dashboard-alerts-legend"} style={{ backgroundColor: cardBackgroundColor, paddingTop: '10px', display: 'flex', flexDirection: 'column' }}>
            <div style={{ padding: '10px 10px 0px 10px', display: 'flex' }}>
                <div style={{ margin: '10px', justifyContent: 'start', display: 'flex', }}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography sx={{ fontSize: 16, fontWeight: 'bold' }}>Lightning Distance</Typography>
                        <Typography sx={{ fontSize: 16, fontWeight: 'bold' }}>and Count</Typography>
                    </div>
                    {nearbyLightning && <div style={{ display: 'flex' }}>
                        <div style={{ marginLeft: '30px', display: 'flex', flexDirection: 'column' }}>
                            <Typography sx={{ textAlign: 'center', fontSize: 16 }}>&lt; 10 miles</Typography>
                            <Typography sx={{ textAlign: 'center', fontSize: 16, color: "red" }}>{nearbyLightning.within10Miles} strikes</Typography>
                        </div>
                        <div style={{ marginLeft: '30px', display: 'flex', flexDirection: 'column' }}>
                            <Typography sx={{ textAlign: 'center', fontSize: 16 }}>&lt; 20 miles</Typography>
                            <Typography sx={{ textAlign: 'center', fontSize: 16, color: "orange" }}>{nearbyLightning.within20Miles} strikes</Typography>
                        </div>
                        <div style={{ marginLeft: '30px', display: 'flex', flexDirection: 'column' }}>
                            <Typography sx={{ textAlign: 'center', fontSize: 16 }}>&lt; 30 miles </Typography>
                            <Typography sx={{ textAlign: 'center', fontSize: 16, color: "yellow" }}>{nearbyLightning.within30Miles} strikes</Typography>
                        </div>
                        {nearbyLightning.closestStrike && nearbyLightning.closestDistance && <div style={{ marginLeft: '30px', display: 'flex', flexDirection: 'column', borderLeft: 'thin solid #919191', paddingLeft: '30px' }}>
                            <Typography sx={{ textAlign: 'center', fontSize: 16 }}>Closest</Typography>
                            <Typography sx={{ textAlign: 'center', fontSize: 16, color: nearbyLightning.closestDistance <= 10 ? "red" : nearbyLightning.closestDistance <= 20 ? "orange" : "yellow" }}>{nearbyLightning.closestDistance.toFixed(1)} miles away</Typography>
                        </div>}
                    </div>}
                    {!nearbyLightning && <div style={{ padding: '0px 40px', textAlign: 'center', alignContent: 'center' }}>
                        <Typography sx={{ fontSize: 16, color: '#919191' }}>Select a location to see nearby lightning</Typography>
                    </div>}
                </div>
            </div>
            <div style={{ margin: '0 20px 10px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography sx={{ fontSize: 12, color: '#919191' }}>Data shown for the last 30 minutes</Typography>
                <div className={'impact-tile-legend'}>
                    <LegendComponent
                        blurbs={lightningBlurbs}
                        skipFirstBlurb={false}
                        embedValues={true}
                        legendTitle="Minutes ago"
                    />
                </div>
            </div>
        </div>
    );

    const legendContainer: JSX.Element = (
        // 544px = 80px (width of left tabs) + 12px (margin) + 12px (margin) + 450px (pop up width when clicked)
        <Paper elevation={3} style={{ backgroundColor: cardBackgroundColor, borderRadius: 11, position: 'absolute', top: 8, left: 12, maxWidth: 'min(800px, calc(100% - 544px))', backgroundImage: 'none' }}>
            <header style={{ display: "flex", justifyContent: "space-between", paddingTop: currentLegends.length === 0 ? '20px' : '0px', paddingLeft: '8px' }}>
                {currentLegends.length === 0 ?
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <Typography style={{ marginLeft: "15px" }} variant={'h6'} color={'textPrimary'}>Nowcast Center</Typography>
                        {userData.citiesMetadata.loading && <>
                            <CircularProgress size={20} style={{ margin: "15px" }} color="inherit" />
                            <Typography variant={'body2'} color={'textSecondary'}>Loading assets...</Typography>
                        </>}
                    </div> :
                    <React.Fragment>
                        <Tabs
                            className="DashboardTabs"
                            value={selectedLegend}
                            onChange={(event, newLegend) => setSelectedLegend(newLegend)}
                            indicatorColor={'primary'}
                            textColor={'primary'}
                            variant="scrollable"
                            scrollButtons="auto"
                            style={{ height: 40, width: '100%' }}
                        >
                            {currentLegends.map((legend: any) => <Tab label={legend.label} value={legend.key} />)}
                        </Tabs >
                        <IconButton
                            color={'primary'}
                            onClick={() => setLegendCollapsed(!legendCollapsed)}
                            style={{ height: 40 }}
                        >
                            {legendCollapsed ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
                        </IconButton>
                    </React.Fragment>
                }
            </header>
            {currentLegends.length === 0 &&
                <div style={{ padding: '10px 8px' }}>
                    <Typography style={{ marginLeft: "15px" }} variant={'body2'} color={'textSecondary'}>Track critical weather impact over the next 6 hours.</Typography>
                    <Typography style={{ marginLeft: "15px" }} variant={'body2'} color={'textSecondary'}>Data powered by HYPERR, our high resolution short term model.</Typography>
                    <Typography style={{ marginLeft: "15px" }} variant={'body2'} color={'textSecondary'}>Updates every 15 minutes. Covers the contiguous US.</Typography>
                </div>}
            {!legendCollapsed && isAlertsVisible && selectedLegend === 'alerts' && alertsLegend}
            {!legendCollapsed && tilingLayer !== undefined && tilingLayer !== 'lightning_probability' && (isImpactKey(selectedLegend) || isWeatherKey(selectedLegend) || selectedLegend === 'radar') && tilingLayerLegend}
            {!legendCollapsed && isRoadStatusVisible && selectedLegend === 'road_status' && roadStatusLegend}
            {!legendCollapsed && isLightningVisible && selectedLegend === 'lightning' && lightningLegend}
            {!legendCollapsed && isStormReportsVisible && selectedLegend === 'storm_reports' && stormReportsLegend}
        </Paper >
    );

    let selectedAlertContainer: JSX.Element | undefined = undefined;
    if (selectedAlert) {
        const startTime = new Date(selectedAlert.timestamps.begins);
        const startDatetimeString = startTime.toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: 'numeric' })
            + ', '
            + startTime.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true, timeZoneName: 'short' });
        const endTime = new Date(selectedAlert.timestamps.expires);
        const endDatetimeString = endTime.toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: 'numeric', })
            + ', '
            + endTime.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true, timeZoneName: 'short' });
        selectedAlertContainer = (
            <div className={"TimeSeriesGraphComponent"}>
                <Paper elevation={3} style={{ backgroundColor: cardBackgroundColor, backgroundImage: 'none', borderRadius: 11, paddingLeft: 305 }}>
                    <header style={{ padding: '12px 12px 12px 0px', display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
                        <Typography style={{ marginLeft: "12px" }} variant={'body1'}>Details</Typography>
                        <div style={{ flex: '1 1 0' }} />
                        <IconButton
                            color={'primary'}
                            onClick={() => {
                                setSelectedAlert(undefined);
                            }}
                            style={{ height: 30 }}
                        >
                            <Close />
                        </IconButton>
                    </header>
                    <div style={{ height: '215px', padding: '0px 12px 12px 12px', overflowY: 'auto' }}>
                        <Typography variant={'body2'} color={'textPrimary'}>Start Time: {startDatetimeString}</Typography>
                        <Typography variant={'body2'} color={'textPrimary'}>End Time: {endDatetimeString}</Typography>
                        {selectedAlert?.details.body}
                    </div>
                </Paper>
            </div>
        );
    }

    let selectedEventContainer: JSX.Element | undefined = undefined;
    if (selectedEvent && !showMapCalloutForSelectedEvent) {
        let selectedEventType: string | undefined = undefined;
        if (selectedCyclone) selectedEventType = 'cyclone';
        else if (selectedPowerOutage) selectedEventType = 'powerOutage';
        else if (selectedStorm) selectedEventType = 'storm';
        else if (selectedWildfire) selectedEventType = 'wildfire';
        selectedEventContainer = (
            <div className={"TimeSeriesGraphComponent"}>
                <Paper elevation={3} style={{ backgroundColor: cardBackgroundColor, backgroundImage: 'none', borderRadius: 11, paddingLeft: 305 }}>
                    <header style={{ padding: '12px 12px 12px 0px', display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
                        <Typography style={{ marginLeft: "15px" }} variant={'body1'}>Details</Typography>
                        <div style={{ flex: '1 1 0' }} />
                        <IconButton
                            color={'primary'}
                            onClick={() => {
                                setSelectedEvent(undefined);
                            }}
                            style={{ height: 30 }}
                        >
                            <Close />
                        </IconButton>
                    </header>
                    <div style={{ height: '215px', margin: '0 12px 12px', overflowY: 'auto' }} >
                        {selectedCyclone && selectedCyclone.hasModelForecastTracks && <React.Fragment>
                            <FormControlLabel
                                control={<Switch
                                    checked={showModelForecastTracksForCycloneId === selectedCyclone.cycloneId}
                                    onChange={event => setShowModelForecastTracksForCycloneId(event.target.checked ? selectedCyclone?.cycloneId : undefined)}
                                />}
                                label="Model Forecast Tracks"
                                labelPlacement="start"
                                sx={{ marginLeft: '0px' }}
                            />
                        </React.Fragment>}
                        <div dangerouslySetInnerHTML={{ __html: getEventDescription(selectedEvent, selectedEventType!) }} />
                    </div>
                </Paper>
            </div>
        );
    }

    let mapControls: JSX.Element | undefined = undefined;
    let displayedTime: Date | undefined = desiredTileset?.time;
    const tilesets = getTilesets(tilingLayer);
    if (tilingLayer !== undefined && tilingLayer !== 'lightning_probability' && tilesets && tilesets.length > 1) {
        const speedIncrement = speedIncrements[selectedSpeedMultiplierIndex];
        mapControls = (
            <MapControlsComponent
                paused={paused}
                speedIncrement={speedIncrement}
                times={tilesets.map(x => x.time)}
                displayedTime={displayedTime}
                loading={visibleTileset?.id !== desiredTileset?.id}

                onPause={() => pause()}
                onResume={() => resume()}
                onFastForward={() => fastForward()}
                onRewind={() => rewind()}
                onSkipToFirst={() => skipToFirst()}
                onSkipToLast={() => skipToLast()}
                onCycleSpeedIncrement={() => cycleSpeedIncrement()}
                setSelectedDate={(date: Date) => goToDate(date)}
            />
        );
    }

    const timeframe = React.useMemo(() => {
        return {
            label: 'today',
            name: 'Today',
            startTime: moment(selectedCity?.subhourlyImpactSummary?.sourceRunTime),
            endTime: moment(selectedCity?.subhourlyImpactSummary?.sourceRunTime).add(6, 'hours'),
        };
    }, [selectedCity?.subhourlyImpactSummary?.sourceRunTime]);

    let timeSeriesGraphsComponent: JSX.Element | undefined;
    if (selectedAlert || (selectedEvent && !showMapCalloutForSelectedEvent) || (selectedCity && (selectedCity?.id || selectedAlert))) {
        const filteredRatingsData: RatingsDataWithLocationInfo = Object.assign({}, ratingsData);
        const timezone = selectedCity?.timezone;
        // find the index of the first row after 6 hours from now and stop graph there
        const sixHoursFromNow = new Date().getTime() + 6 * 3600 * 1000;
        const endIndex = weatherData?.subhourly?.findIndex(wc => wc.time.getTime() > sixHoursFromNow);
        for (const key of ALL_RATING_KEYS) {
            if (filteredRatingsData[key]) {
                filteredRatingsData[key] = filteredRatingsData[key].slice(0, endIndex);
            }
        }
        let filteredWeatherData = weatherData?.subhourly?.slice(0, endIndex) || [];
        let impactString = '';
        if (selectedCity?.subhourlyImpactSummary !== undefined) {
            impactString = `${wordForImpactLevel(selectedCity.subhourlyImpactSummary.impactLevel!)}`;
        }
        let impactTags: ImpactTag[] = [];
        selectedCity?.subhourlyImpactSummary?.tags.map(tag => {
            // create separate tags for tags that have been combined with same impact level
            if (tag.text.includes(',')) {
                tag.text.split(',').map(tagText => impactTags.push({ id: tag.id, source: tag.source, text: tagText.trim(), value: tag.value, impactLevel: tag.impactLevel }));
            } else {
                impactTags.push(tag);
            }
        });

        let chartViews = mapCategories.map(category => { return { key: category.key, title: category.title }; });
        chartViews.push({ key: 'alerts', title: 'NWS Alerts' });

        const selectedLocationInfo: JSX.Element = (
            <Paper className="location-info" elevation={4} style={{ backgroundColor: cardBackgroundColor, width: '300px', display: 'flex', flexDirection: 'column' }}>
                <Typography style={{ margin: "10px 0px 0px 15px" }} variant={'subtitle1'} fontWeight={"bold"} color={'textPrimary'}> {selectedCity?.name}</Typography>
                <Typography style={{ margin: "0px 0px 5px 15px", opacity: 0.5 }} variant={'caption'} >6 Hour Forecast - Powered by HYPERR</Typography>
                <div style={{ overflow: 'auto', height: '100%' }}>
                    <Typography style={{ margin: "5px 15px" }} variant={'body2'} fontWeight={"bold"} color={'textPrimary'}>Max Impact Level</Typography>
                    <div style={{ margin: "5px 15px 10px", display: 'flex', flexDirection: 'row', alignContent: 'center' }}>
                        <Chip
                            style={{
                                padding: 8,
                                // subhourlyImpactSummary is missing if you click on the map on impact tab then switch to nowcast and click on a saved location
                                backgroundColor: darkTagColorForImpactLevel(selectedCity?.subhourlyImpactSummary?.impactLevel ?? ImpactLevel.Unknown),
                                color: 'white',
                                fontWeight: 'bold',
                            }}
                            label={impactString}
                        />
                    </div>
                    {/* subhourlyImpactSummary is missing if you click on the map on impact tab then switch to nowcast and click on a saved location */}
                    {(selectedCity?.subhourlyImpactSummary?.impactLevel ?? ImpactLevel.Unknown) > 0 && <React.Fragment>
                        <Typography style={{ margin: "5px 15px" }} fontWeight={"bold"} variant={'body2'} color={'textPrimary'}>Specific Risks ({impactTags.length})</Typography>
                        <div style={{ overflowY: 'auto' }}>
                            {impactTags.map(tag => (
                                <div>
                                    <Chip
                                        style={{
                                            margin: "5px 15px 5px 15px",
                                            padding: 8,
                                            backgroundColor: darkTagColorForImpactLevel(tag.impactLevel),
                                            color: 'white',
                                            fontWeight: 'bold',
                                            maxWidth: 250
                                        }}
                                        label={tag.text}
                                        onClick={() => impactTagSelected(tag)}
                                    />
                                </div>
                            ))}
                        </div>
                    </React.Fragment>}
                </div>
            </Paper>);

        let selectedAlertInfo: JSX.Element | undefined = undefined;
        if (selectedAlert) {
            // TODO: should we respect the filter the user has put on when determining this list?
            // maybe add a "X assets affected not being show because of filter" or equivalent
            const assetsAffectedByAlert: (LocationData | VehicleTrackingData)[] = [];
            let filteredAssetsAffectedByAlertCount = 0;
            userData.cities.forEach(city => {
                city.subhourlyImpactSummary?.tags.forEach(tag => {
                    if (tag.id === selectedAlert.id) {
                        if (filteredCities.find(fc => fc.id === city.id)) {
                            assetsAffectedByAlert.push(city);
                        } else {
                            filteredAssetsAffectedByAlertCount += 1;
                        }
                    }
                });
            });
            userData.vehicles.forEach(vehicle => {
                vehicle.currentImpact?.tags.forEach(tag => {
                    if (tag.id === selectedAlert.id) {
                        if (filteredVehicles.find(fv => fv.id === vehicle.id)) {
                            assetsAffectedByAlert.push(vehicle);
                        } else {
                            filteredAssetsAffectedByAlertCount += 1;
                        }
                    }
                });
            });
            assetsAffectedByAlert.sort((a, b) => getAssetImpactLevel(b) - getAssetImpactLevel(a));

            selectedAlertInfo = (
                <Paper className="location-info" elevation={4} style={{ backgroundColor: cardBackgroundColor, width: '300px', display: 'flex', flexDirection: 'column' }}>
                    {alerts.length === 1 ? <Typography style={{ margin: "10px 0px 0px 15px" }} variant={'subtitle1'} fontWeight={"bold"} color={'textPrimary'}> {selectedAlert?.details.name}</Typography> :
                        <Select
                            value={selectedAlert.id}
                            variant={'outlined'}
                            style={{ margin: "5px", width: 'calc(100% - 10px)', height: 40 }}
                            onChange={(event) => setSelectedAlert(alerts.find(alert => alert.id === event.target.value))}
                        >
                            {alerts.map((alert: AlertData) => (
                                <MenuItem value={alert.id}>{alert.details.name}</MenuItem>
                            ))}
                        </Select>}
                    <div style={{ overflow: 'auto', height: '100%' }}>
                        {<React.Fragment>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <Typography style={{ margin: "5px 15px" }} fontWeight={"bold"} variant={'body2'} color={'textPrimary'}>Assets at Risk ({assetsAffectedByAlert.length})</Typography>
                                <Button color={'primary'} size={'small'} disabled={assetsAffectedByAlert.length === 0} startIcon={<ExportIcon />} onClick={() => exportCSVFile(
                                    assetsAffectedByAlert.map(asset => {
                                        return { asset: getAssetName(asset), latitude: asset.latitude, longitude: asset.longitude };
                                    }), `assets_at_risk`)}>
                                    Export
                                </Button>
                            </div>
                            {filteredAssetsAffectedByAlertCount > 0 && <Typography style={{ margin: "5px 15px", opacity: 0.5 }} variant={'caption'}>{filteredAssetsAffectedByAlertCount} affected assets filtered out</Typography>}
                            <div>
                                {(assetsAffectedByAlert.length > 0 || filteredAssetsAffectedByAlertCount > 0) ? assetsAffectedByAlert.map(asset => (
                                    <div>
                                        <Chip
                                            style={{
                                                margin: "5px 15px 5px 15px",
                                                padding: 8,
                                                backgroundColor: darkTagColorForImpactLevel(getAssetImpactLevel(asset)),
                                                color: 'white',
                                                fontWeight: 'bold',
                                                maxWidth: 250
                                            }}
                                            label={getAssetName(asset)}
                                            onClick={() => {
                                                setSelectedAlert(undefined);
                                                if ((asset as any).assetType === 'vehicle') {
                                                    onVehicleSelected(asset as VehicleTrackingData);
                                                } else {
                                                    onCitySelected(asset as LocationData);
                                                }
                                            }}
                                        />
                                    </div>
                                )) : <Typography style={{ margin: "5px 15px", opacity: 0.5 }} variant={'caption'}>No Assets Affected</Typography>}
                            </div>
                        </React.Fragment>}
                    </div>
                </Paper>);
        }

        let selectedEventName: string | undefined = undefined;
        if (selectedCyclone) selectedEventName = 'Cyclone';
        else if (selectedPowerOutage) selectedEventName = 'Power Outage';
        else if (selectedStorm) selectedEventName = selectedStorm.stormType;
        else if (selectedWildfire) selectedEventName = 'Wildfire';
        let selectedEventInfo: JSX.Element | undefined = undefined;
        // as cast fixes type casting for assignment in a forEach
        let selectedCyclonePolygon: CycloneData | undefined = undefined as CycloneData | undefined;
        if (selectedEvent && !showMapCalloutForSelectedEvent) {
            const eventAssetsMap = new Map<string, LocationData | VehicleTrackingData>();
            const eventPolygons: (GeoJSON.Polygon | GeoJSON.MultiPolygon)[] = [];
            let filteredAssetsAffectedByEventCount = 0;
            if (selectedCyclone) {
                cyclones?.forEach(cyclone => {
                    if (selectedCyclone.cycloneId !== cyclone.cycloneId) {
                        return;
                    }
                    if (cyclone.geoJson.geometry.type === 'Polygon') {
                        selectedCyclonePolygon = cyclone;
                        eventPolygons.push(turf.geometry('Polygon', cyclone.geoJson.geometry.coordinates) as GeoJSON.Polygon);
                    }
                    if (cyclone.geoJson.geometry.type === 'MultiPolygon') {
                        selectedCyclonePolygon = cyclone;
                        eventPolygons.push(turf.geometry('MultiPolygon', cyclone.geoJson.geometry.coordinates) as GeoJSON.MultiPolygon);
                    }
                });
            } else {
                if (selectedEvent.geoJson.geometry.type === 'Polygon') {
                    eventPolygons.push(turf.geometry('Polygon', selectedEvent.geoJson.geometry.coordinates) as GeoJSON.Polygon);
                }
                if (selectedEvent.geoJson.geometry.type === 'MultiPolygon') {
                    eventPolygons.push(turf.geometry('MultiPolygon', selectedEvent.geoJson.geometry.coordinates) as GeoJSON.MultiPolygon);
                }
            }
            eventPolygons.forEach(eventPoly => {
                userData.cities.forEach(city => {
                    if (city.id) {
                        const cityPoint = turf.point([city.longitude, city.latitude]);
                        if (turf.booleanPointInPolygon(cityPoint, eventPoly!)) {
                            if (filteredCities.find(fc => fc.id === city.id)) {
                                eventAssetsMap.set(String(city.id), city);
                            } else {
                                filteredAssetsAffectedByEventCount += 1;
                            }
                        }
                    }
                });
                userData.vehicles.forEach(vehicle => {
                    if (vehicle.latitude !== undefined && vehicle.longitude !== undefined) {
                        const vehiclePoint = turf.point([vehicle.longitude, vehicle.latitude]);
                        if (turf.booleanPointInPolygon(vehiclePoint, eventPoly!)) {
                            if (filteredVehicles.find(fv => fv.id === vehicle.id)) {
                                eventAssetsMap.set(vehicle.id, vehicle);
                            } else {
                                filteredAssetsAffectedByEventCount += 1;
                            }
                        }
                    }
                });
            });
            const assetsAffectedByEvent = Array.from(eventAssetsMap.values());
            assetsAffectedByEvent.sort((a, b) => getAssetImpactLevel(b) - getAssetImpactLevel(a));
            let assetAtRiskText = 'Assets at Risk';
            if (selectedCyclonePolygon?.feature === 'storm_cone_of_uncertainty') {
                assetAtRiskText = 'Assets in Cone of Uncertainty';
            } else if (selectedCyclonePolygon?.feature === 'storm_danger_swath') {
                assetAtRiskText = 'Assets in Danger Swath';
            }
            selectedEventInfo = (
                <Paper className="location-info" elevation={4} style={{ backgroundColor: cardBackgroundColor, width: '300px', display: 'flex', flexDirection: 'column' }}>
                    <Typography style={{ margin: "10px 0px 0px 15px" }} variant={'subtitle1'} fontWeight={"bold"} color={'textPrimary'}>{(selectedEvent as any | undefined)?.name ?? selectedEventName}</Typography>
                    <div style={{ overflow: 'auto', height: '100%' }}>
                        {<React.Fragment>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <Typography style={{ margin: "5px 15px" }} fontWeight={"bold"} variant={'body2'} color={'textPrimary'}>{assetAtRiskText} ({assetsAffectedByEvent.length})</Typography>
                                <Button
                                    color={'primary'}
                                    size={'small'}
                                    style={{ marginRight: '5px', 'minWidth': '85px' }}
                                    disabled={assetsAffectedByEvent.length === 0}
                                    startIcon={<ExportIcon />}
                                    onClick={() => {
                                        exportCSVFile(
                                            assetsAffectedByEvent.map(asset => {
                                                return { asset: getAssetName(asset), latitude: asset.latitude, longitude: asset.longitude };
                                            }),
                                            'assets_at_risk'
                                        );
                                    }
                                    }>
                                    Export
                                </Button>
                            </div>
                            {filteredAssetsAffectedByEventCount > 0 && <Typography style={{ margin: "5px 15px", opacity: 0.5 }} variant={'caption'}>{filteredAssetsAffectedByEventCount} affected assets filtered out</Typography>}
                            <div>
                                {(assetsAffectedByEvent.length > 0 || filteredAssetsAffectedByEventCount > 0) ? assetsAffectedByEvent.map(asset => (
                                    <div>
                                        <Chip
                                            style={{
                                                margin: "5px 15px 5px 15px",
                                                padding: 8,
                                                backgroundColor: darkTagColorForImpactLevel(getAssetImpactLevel(asset)),
                                                color: 'white',
                                                fontWeight: 'bold',
                                                maxWidth: 250
                                            }}
                                            label={getAssetName(asset)}
                                            onClick={() => {
                                                setSelectedEvent(undefined);
                                                if ((asset as any).assetType === 'vehicle') {
                                                    onVehicleSelected(asset as VehicleTrackingData);
                                                } else {
                                                    onCitySelected(asset as LocationData);
                                                }
                                            }}
                                        />
                                    </div>
                                )) : <Typography style={{ margin: "5px 15px", opacity: 0.5 }} variant={'caption'}>No Assets Affected</Typography>}
                            </div>
                        </React.Fragment>}
                    </div>
                </Paper>);
        }

        timeSeriesGraphsComponent = (
            <div className={"NowcastTimeSeriesGraphsContainer"}>
                {selectedAlert ? selectedAlertContainer : selectedEvent && !showMapCalloutForSelectedEvent ? selectedEventContainer : (selectedChartView === 'alerts' ?
                    <AlertTimelineView
                        extraStyles={{ paddingLeft: 305 }}
                        selectedChartView={selectedChartView}
                        chartViews={chartViews}
                        alerts={alerts}
                        timeframe={timeframe}
                        timezone={timezone}
                        onCloseClicked={() => onCitySelected(undefined)}
                        setSelectedChartView={(view) => setSelectedChartView(view)}
                        setSelectedAlert={(alert) => setSelectedAlert(alert)}
                    /> :
                    <TimeSeriesGraphView
                        extraStyles={{ paddingLeft: 305 }}
                        isNowcastGraph={true}
                        selectedCityState={selectedCityState}
                        selectedGraphView={selectedChartView}
                        graphViews={chartViews}
                        onValueClicked={(date) => goToDate(date)}
                        onCloseClicked={() => onCitySelected(undefined)}
                        setSelectedGraphView={(view) => setSelectedChartView(view)}
                        setTilingLayer={(layer) => setTilingLayer(layer)}

                        selectedWeatherProperty={selectedChartKey}
                        onWeatherPropertyChanged={ratingKey => setSelectedChartKey(ratingKey)}
                        weatherConditions={filteredWeatherData}

                        showDisruptionIndex={showDisruptionIndex}
                        showWildfireIndices={showWildfireIndices}
                        ratings={filteredRatingsData}
                        timezone={timezone}
                        blurbs={blurbs}
                        now={now}
                        selectedRatingKey={selectedChartKey as RatingKey}
                        onRatingKeySelected={ratingKey => setSelectedChartKey(ratingKey)}
                    />)}

                {selectedAlert ? selectedAlertInfo : selectedEvent && !showMapCalloutForSelectedEvent ? selectedEventInfo : selectedLocationInfo}
            </div>
        );

        const mapControlsDiv: HTMLElement = document.querySelector('.MapControlsComponent') as HTMLElement;
        if (mapControlsDiv) {
            mapControlsDiv.style.bottom = '340px';
        }
    } else {
        // if the time series chart is not visible and map controls are place them at bottom of screen
        const mapControlsDiv: HTMLElement = document.querySelector('.MapControlsComponent') as HTMLElement;
        if (mapControlsDiv) {
            if (selectedVehicle) {
                mapControlsDiv.style.bottom = '340px';
            } else if (selectedEvent && !showMapCalloutForSelectedEvent) {
                mapControlsDiv.style.bottom = '245px';
            } else {
                mapControlsDiv.style.bottom = '20px';
            }
        }
    }

    let selectedEventLatitude: number | undefined = undefined;
    let selectedEventLongitude: number | undefined = undefined;
    if (selectedEvent && showMapCalloutForSelectedEvent) {
        const point = selectedEvent.clickedPoint ?? getPointForEvent(selectedEvent);
        selectedEventLatitude = point.latitude;
        selectedEventLongitude = point.longitude;
    }

    const selectedVehicleElement: JSX.Element | undefined = selectedVehicle && (
        <VehicleTimelineView
            userData={userData}
            selectedVehicle={selectedVehicle}
            selectedChartView={selectedChartView}
            timeframe={timeframe}

            onCloseClicked={() => onVehicleSelected(undefined)}
            setSelectedChartView={(view) => setSelectedChartView(view)}
            setAlerts={(alerts) => setAlerts(alerts)}
            setSelectedAlert={(alert) => setSelectedAlert(alert)}
            onVehicleMessageRequested={(vehicle, message, urgent) => onVehicleMessageRequested(vehicle, message, urgent)}

            height={215}
            extraStyles={{ paddingLeft: 305 }}
        />
    );

    let metadataComponent: JSX.Element | undefined = undefined;
    if (selectedCity) {
        let graphRunTime: string | undefined = undefined;
        if (selectedChartView === 'weather') {
            graphRunTime = weatherData?.subhourly?.at(0)?.time.toISOString();
        } else if (selectedChartView === 'rating') {
            graphRunTime = ratingsData?.disruption?.at(0)?.time.toISOString();
        }
        metadataComponent = (
            <div style={{
                position: 'absolute',
                left: '50%',
                transform: 'translateX(-50%)',
                bottom: '0px',
                background: 'white',
                opacity: 0.75,
                color: 'black',
                fontSize: '10px',
                paddingLeft: '2px',
                paddingRight: '2px',
            }}>
                {`${selectedCity.latitude.toFixed(5)}, ${selectedCity.longitude.toFixed(5)} | Risk Run Time: ${moment(selectedCity.subhourlyImpactSummary?.sourceRunTime).utc().format()} | Graph Run Time: ${graphRunTime}`}
            </div>);
    }

    return (
        <div className={'Dashboard'} style={{ position: 'relative', height: '100%' }}>
            <RouteAndMarkerMap
                {...defaultRouteAndMarkerMapProps}

                portalToken={userData.portalToken}
                // initially show the whole conus
                initialCenter={{ lat: 39.82, lng: -98.57 }}
                selectedCity={selectedCity}

                isCyclonesVisible={isCyclonesVisible}
                isEarthquakesVisible={isEarthquakesVisible}
                isLightningVisible={isLightningVisible}
                isPowerOutagesVisible={isPowerOutagesVisible}
                isStormsVisible={isStormsVisible}
                isStormReportsVisible={isStormReportsVisible}
                isVolcanoesVisible={isVolcanoesVisible}
                isFiresVisible={isFiresVisible}
                isWildfiresVisible={isWildfiresVisible}

                isRoadStatusVisible={isRoadStatusVisible}
                isRoadWorkVisible={isRoadWorkVisible}
                isRoadClosuresVisible={isRoadClosuresVisible}
                isSpecialEventsVisible={isSpecialEventsVisible}
                showTrafficFlow={isTrafficCongestionVisible}
                showTrafficCongestion={isTrafficCongestionVisible}
                showLegacyHereTrafficIncidents={isTrafficIncidentsVisible}
                showTrafficIncidents={isTrafficIncidentsVisible}
                isTruckWarningsVisible={isTruckWarningsVisible}
                isWeatherStationsVisible={isWeatherStationsVisible}


                cyclones={cyclones}
                showModelForecastTracksForCycloneId={showModelForecastTracksForCycloneId}
                earthquakes={earthquakes}
                lightning={lightning}
                powerOutages={powerOutages}
                storms={currentStorms}
                stormReports={stormReports}
                volcanoes={volcanoes}
                fires={fires}
                wildfires={wildfires}

                roadStatus={roadStatus}
                roadWork={roadWork}
                roadClosures={roadClosures}
                specialEvents={specialEvents}
                trafficCongestion={trafficCongestion}
                trafficIncidents={trafficIncidents}
                truckWarnings={truckWarnings}
                weatherStations={weatherStations}

                onCycloneSelected={(cyclone) => onCycloneSelected(cyclone)}
                onEarthquakeSelected={(earthquake) => onEarthquakeSelected(earthquake)}
                onLightningSelected={(lightning) => onLightningSelected(lightning)}
                onPowerOutageSelected={(powerOutage) => onPowerOutageSelected(powerOutage)}
                onStormSelected={(storm) => onStormSelected(storm)}
                onStormReportSelected={(stormReport) => onStormReportSelected(stormReport)}
                onVolcanoSelected={(volcano) => onVolcanoSelected(volcano)}
                onFireSelected={(fire) => onFireSelected(fire)}
                onWildfireSelected={(wildfire) => onWildfireSelected(wildfire)}

                onRoadStatusSelected={(roadStatus) => onRoadStatusSelected(roadStatus)}
                onRoadWorkSelected={(roadWork) => onRoadWorkSelected(roadWork)}
                onRoadClosureSelected={(roadClosure) => onRoadClosureSelected(roadClosure)}
                onSpecialEventSelected={(specialEvent) => onSpecialEventSelected(specialEvent)}
                onTrafficCongestionSelected={(trafficCongestion) => onTrafficCongestionSelected(trafficCongestion)}
                onTrafficIncidentSelected={(trafficIncident: TrafficIncidentData) => onTrafficIncidentSelected(trafficIncident)}
                onTruckWarningSelected={(truckWarning) => onTruckWarningSelected(truckWarning)}
                onWeatherStationSelected={(weatherStation) => onWeatherStationSelected(weatherStation)}

                selectedCyclone={selectedCyclone}
                selectedEarthquake={selectedEarthquake}
                selectedLightning={selectedLightning}
                selectedStorm={selectedStorm}
                selectedVolcano={selectedVolcano}
                selectedFire={selectedFire}
                selectedWildfire={selectedWildfire}
                selectedPowerOutage={selectedPowerOutage}
                selectedStormReport={selectedStormReport}

                selectedRoadStatus={selectedRoadStatus}
                selectedRoadWork={selectedRoadWork}
                selectedRoadClosure={selectedRoadClosure}
                selectedSpecialEvent={selectedSpecialEvent}
                selectedTrafficCongestion={selectedTrafficCongestion}
                selectedTrafficIncident={selectedTrafficIncident}
                selectedTruckWarning={selectedTruckWarning}
                selectedWeatherStation={selectedWeatherStation}

                zoomLevel={zoomLevel}
                selectedView={'location'}
                selectedTimelineView={'impact'}
                timeframe={timeframe}

                isNowcastMap={true}

                showEventMarkersAboveLabels={tilingLayer !== undefined}

                isGovernmentalAlertsVisible={isAlertsVisible}
                governmentalAlerts={governmentalAlerts.value}
                selectedAlert={selectedAlert}

                maskType={(tilingLayer === undefined || tilingLayer === 'radar') ? MaskType.Labels : MaskType.LabelsAndWater}

                tileOpacity={tileOpacity}
                desiredTileLayer={desiredTileset}
                onTileLayerLoaded={(visibleTileset) => {
                    console.log('[Nowcast] onTileLayerLoaded', visibleTileset);
                    setVisibleTileset(visibleTileset);

                    if (!paused && visibleTileset?.id === desiredTileset?.id) {
                        console.log('[Nowcast] onTileLayerLoaded, queuing a new tile load');
                        clearTimeout(timerRef.current);
                        timerRef.current = setTimeout(() => goToNextTimeOffset(), 1000);
                    }
                }}

                mapTypeControlOptions={{
                    ...defaultRouteAndMarkerMapProps.mapTypeControlOptions,
                    mapTypeIds: (isRoadStatusVisible || isTrafficCongestionVisible || isTrafficIncidentsVisible ||
                        isRoadWorkVisible || isRoadClosuresVisible || isTruckWarningsVisible) ? ["weather_optics"] : ["satellite", "weather_optics"],
                }}

                isLocationsVisible={true}
                savedCities={filteredCities}

                // if we have no selected asset types, then we can just hide it without
                // clearing out all of the markers in filteredVehicles, makes toggling vehicles
                // less laggy
                isVehiclesVisible={vehicleSelectedAssetTypes.length > 0}
                vehicles={filteredVehicles}
                selectedVehicle={selectedVehicle}

                citiesLoading={userData.citiesMetadata.loading}

                onCitySelected={(city) => onUserSelectedCity(city)}
                onVehicleSelected={(vehicle) => onVehicleSelected(vehicle)}
                onMapClicked={(coord) => onMapClicked(coord)}
                onBoundsChanged={(bounds) => setMapBounds(bounds)}
                onCenterChanged={(_center) => { }} // TODO: do we need to use this for anything?
                onZoomLevelChanged={(zoomLevel) => setZoomLevel(zoomLevel)}
            >
                {selectedEvent && showMapCalloutForSelectedEvent &&
                    <EventCalloutView
                        lat={selectedEventLatitude!}
                        lng={selectedEventLongitude!}
                        portalTab={'nowcast'}

                        selectedEarthquake={selectedEarthquake}
                        selectedLightning={selectedLightning}
                        selectedStormReport={selectedStormReport}
                        selectedVolcano={selectedVolcano}
                        selectedFire={selectedFire}
                        selectedPowerOutage={selectedPowerOutage}
                        selectedDrivingCondition={selectedRoadStatus}
                        selectedRoadWork={selectedRoadWork}
                        selectedRoadClosure={selectedRoadClosure}
                        selectedSpecialEvent={selectedSpecialEvent}
                        selectedTrafficCongestion={selectedTrafficCongestion}
                        selectedTrafficIncident={selectedTrafficIncident}
                        selectedTruckWarning={selectedTruckWarning}
                        selectedWeatherStation={selectedWeatherStation}

                        isEarthquakesVisible={isEarthquakesVisible}
                        isLightningVisible={isLightningVisible}
                        isPowerOutagesVisible={isPowerOutagesVisible}
                        isStormReportsVisible={isStormReportsVisible}
                        isVolcanoesVisible={isVolcanoesVisible}
                        isFiresVisible={isFiresVisible}
                        isDrivingConditionsVisible={isRoadStatusVisible}
                        isRoadWorkVisible={isRoadWorkVisible}
                        isRoadClosuresVisible={isRoadClosuresVisible}
                        isTrafficCongestionVisible={isTrafficCongestionVisible}
                        isTrafficIncidentsVisible={isTrafficIncidentsVisible}
                        isTruckWarningsVisible={isTruckWarningsVisible}

                        onClearSelectedEvent={() => onClearSelectedEvent()}
                        onClearSelectedRoadCondition={() => onClearSelectedRoadCondition()}
                    />}
            </RouteAndMarkerMap>
            {legendContainer}
            <NowcastMenu
                tilingLayer={tilingLayer === 'lightning_probability' ? undefined : tilingLayer}
                setTilingLayer={(layer) => setTilingLayer(layer)}
                isGraphExpanded={timeSeriesGraphsComponent !== undefined}
                itemCounts={{
                    cyclones: cyclones?.reduce((acc: any, curr: CycloneData) => {
                        if (!acc.includes(curr.cycloneId))
                            acc.push(curr.cycloneId);
                        return acc;
                    }, []).length.toLocaleString() ?? undefined,
                    earthquakes: earthquakes?.reduce((acc: any, curr: EarthquakeData) => {
                        if (!acc.includes(curr.earthquakeId))
                            acc.push(curr.earthquakeId);
                        return acc;
                    }, []).length.toLocaleString() ?? undefined,
                    lightning: lightning?.length.toLocaleString() ?? undefined,
                    powerOutages: powerOutages?.length.toLocaleString() ?? undefined,
                    storms: storms?.length.toLocaleString() ? currentStorms?.length.toLocaleString() : undefined,
                    stormReports: stormReports?.length.toLocaleString() ?? undefined,
                    volcanoes: volcanoes?.length.toLocaleString() ?? undefined,
                    fires: fires?.length.toLocaleString() ?? undefined,
                    wildfires: wildfires?.length.toLocaleString() ?? undefined
                }}
                assetAlerts={Array.from(new Set([...locationAlerts, ...vehicleAlerts])).sort()}

                isAlertsVisible={isAlertsVisible}
                isCyclonesVisible={isCyclonesVisible}
                isEarthquakesVisible={isEarthquakesVisible}
                isLightningVisible={isLightningVisible}
                isPowerOutagesVisible={isPowerOutagesVisible}
                isStormsVisible={isStormsVisible}
                isStormReportsVisible={isStormReportsVisible}
                isVolcanoesVisible={isVolcanoesVisible}
                isFiresVisible={isFiresVisible}
                isWildfiresVisible={isWildfiresVisible}

                onAlertsToggled={(toggled) => setIsAlertsVisible(toggled)}
                onCyclonesToggled={(toggled) => onCyclonesToggled(toggled)}
                onEarthquakesToggled={(toggled) => onEarthquakesToggled(toggled)}
                onLightningToggled={(toggled) => onLightningToggled(toggled)}
                onPowerOutagesToggled={(toggled) => onPowerOutagesToggled(toggled)}
                onStormsToggled={(toggled) => onStormsToggled(toggled)}
                onStormReportsToggled={(toggled) => onStormReportsToggled(toggled)}
                onVolcanoesToggled={(toggled) => onVolcanoesToggled(toggled)}
                onFiresToggled={(toggled) => onFiresToggled(toggled)}
                onWildfiresToggled={(toggled) => {
                    // sync wildfire map layer and wildfire event visibility
                    onWildfiresToggled(toggled);
                    toggled ? setTilingLayer('wildfire_spread') : setTilingLayer(undefined);
                }}

                trafficLayer={trafficLayer}
                onTrafficLayerSelected={(layer) => onTrafficLayerSelected(layer)}

                isLocationsLoading={userData.citiesMetadata.loading}
                locationAssetTypeOptions={locationAssetTypeOptions}
                locationSelectedAssetTypes={locationSelectedAssetTypes}
                locationCountsByAssetCategory={locationCountsByAssetCategory}
                onLocationSelectedTypesChanged={(assetTypes) => setLocationSelectedAssetTypes(assetTypes)}

                isVehiclesLoading={userData.vehiclesMetadata.loading}
                vehicleAssetTypeOptions={vehicleAssetTypeOptions}
                vehicleSelectedAssetTypes={vehicleSelectedAssetTypes}
                vehicleCountsByAssetCategory={vehicleCountsByAssetCategory}
                onVehicleSelectedTypesChanged={(assetTypes) => setVehicleSelectedAssetTypes(assetTypes)}

                filters={filters}
                onFiltersChanged={(filters) => setFilters(filters)}
            />

            {mapControls}
            {timeSeriesGraphsComponent}
            {selectedVehicleElement}
            {metadataComponent}
        </div>
    );
};