import CreateLoginForm from '../components/CreateLoginForm';
import { StoreState } from '../types';
import {connect} from 'react-redux';
import {Action} from 'redux';
import 'redux-thunk';
import {createNewLoginAction} from '../actions/User';
import {ThunkDispatch} from 'redux-thunk';

export function mapStateToProps(state: StoreState) {
    return {
        lastError: state.user.lastLoginError,
        isLoggingIn: state.user.isLoggingIn,
    };
}

export function mapDispatchToProps(dispatch: ThunkDispatch<StoreState, void, Action<any>>) {
    return {
        initiateCreateLogin: (email: string, token: string, company: string, password: string, newLoginCreated: () => void) => dispatch(createNewLoginAction(email, token, company, password, newLoginCreated)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateLoginForm);
