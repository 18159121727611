import { RouteData, RoutesViewState } from '../types/routes';
import {
    ClearErrorOnRoutesTab,
    ReceiveErrorOnRoutesTab,
    ReceiveResultsOnRoutesTab,
    ReceiveSaveRouteError,
    ReceiveSaveRouteSuccess, RouteSaveError,
    RoutesMapCenterChanged,
    RoutesMapZoomLevelChanged,
    RoutesViewAction,
    SetAboveTemperatureThresholdOnRoutesTab,
    SetBelowTemperatureThresholdOnRoutesTab,
    SetDepartureTimeOnRoutesTab,
    SetDestinationOnRoutesTab,
    SetOriginOnRoutesTab,
    SetRunningOnRoutesTab,
    SetSelectedRoute,
    SetSelectedTabOnRoutesForm,
    SetWaypointsOnRoutesTab,
    StartSaveRoute
} from '../actions/RoutesView';

function createDefaultState(): RoutesViewState {
    return {
        cachedRouteResults: [],
        selectedTab: 0,
        center: { lat: 39.8283, lng: -98.5795 },
        zoomLevel: 5,
        // doesn't hurt to have a placeholder that speeds up the case of running a simple route
        enteredDepartureTime: new Date(),
    };
}

function updateRouteResults(array: RouteData[], idToOverride: number | undefined, newResults: RouteData): RouteData[] {
    return [...array.filter(item => item.id !== idToOverride), { ...newResults, id: idToOverride }];
}

function clearRouteResults(array: RouteData[], forId: number | undefined): RouteData[] {
    return [...array.filter(item => item.id !== forId)];
}

export function routesView(state: RoutesViewState, action: typeof RoutesViewAction.actions): RoutesViewState {
    if (typeof state === 'undefined') {
        return createDefaultState();
    }

    switch (action.type) {
        case SetSelectedRoute.type:
            return { ...state, selectedRoute: action.route };
        case SetSelectedTabOnRoutesForm.type:
            return { ...state, selectedTab: action.selectedTab };
        case SetOriginOnRoutesTab.type:
            return { ...state, enteredOrigin: action.origin };
        case SetDestinationOnRoutesTab.type:
            return { ...state, enteredDestination: action.destination };
        case SetWaypointsOnRoutesTab.type:
            return { ...state, enteredWaypoints: action.waypoints };
        case SetDepartureTimeOnRoutesTab.type:
            return { ...state, enteredDepartureTime: action.departureTime };
        case SetAboveTemperatureThresholdOnRoutesTab.type:
            return { ...state, enteredAboveTemperatureThreshold: action.temperature };
        case SetBelowTemperatureThresholdOnRoutesTab.type:
            return { ...state, enteredBelowTemperatureThreshold: action.temperature };
        case SetRunningOnRoutesTab.type:
            return { ...state, currentlyRunningRoute: action.route, routesTabError: undefined, cachedRouteResults: clearRouteResults(state.cachedRouteResults, action.route.id) };
        case StartSaveRoute.type:
            return { ...state, routeBeingSaved: action.route };
        case ReceiveSaveRouteError.type:
            return { ...state, routeBeingSaved: undefined, routesTabError: new RouteSaveError(action.error) };
        case ReceiveSaveRouteSuccess.type:
            return { ...state, routeBeingSaved: undefined };
        case ReceiveErrorOnRoutesTab.type:
            return { ...state, currentlyRunningRoute: undefined, routesTabError: action.error };
        case ClearErrorOnRoutesTab.type:
            return { ...state, routesTabError: undefined };
        case ReceiveResultsOnRoutesTab.type:
            return { ...state, currentlyRunningRoute: undefined, cachedRouteResults: updateRouteResults(state.cachedRouteResults, action.results.id, action.results) };
        case RoutesMapCenterChanged.type:
            return { ...state, center: action.center };
        case RoutesMapZoomLevelChanged.type:
            return { ...state, zoomLevel: action.zoomLevel };
        default: return state;
    }
}

/* eslint-disable @typescript-eslint/no-unused-vars */
function createMockState(): RoutesViewState {
    return {
        selectedTab: 0,
        enteredDepartureTime: new Date(new Date().getTime() + 3600 * 1000),
        enteredDestination: { label: "Philadelphia, PA, USA", latitude: 39.9525839, longitude: -75.1652215 },
        enteredOrigin: { label: "New York, NY, USA", latitude: 40.7127753, longitude: -74.0059728 },
        center: { lat: 39.8283, lng: -98.5795 },
        zoomLevel: 5,
        cachedRouteResults: []
        // cachedRouteResults: [{
        //     slowdownFraction: 0.02,
        //     weatherFlags: ['freezing_rain_flag', 'extreme_weather_flag'],
        //     departureTime: new Date(new Date().getTime() + 3600 * 1000),
        //     destination: { label: "Philadelphia, PA, USA", latitude: 39.9525839, longitude: -75.1652215 },
        //     origin: { label: "New York, NY, USA", latitude: 40.7127753, longitude: -74.0059728 },
        //     slowdownPolylines: [{"polyline": "gzmwF~etbMam@}ZdjBoz@kRzX`~@`u@t{A|O|hAq_@`yAp|A~gAz|@x~@}Wh_BxgAxe@dxAb]|wBcYxrBgZteBqBjqBjTtpCyZtwAgy@~qBqf@`}@gnA~nCgBve@hnAnQbaBxz@zwAly@zqAjo@`{AbjAb{@|x@bdAr{Adb@bzAvTl{Bhy@vdCzl@bwAhk@beBnkAb|AbaBbmB~u@|r@blAhs@xdBx{@`gAfj@j{AvdAdwAnu@x{Anb@xgCtv@n|Afe@zq@nXraBli@vaBji@p_B~i@xjAp`@diBxm@lpAr`@vwAdl@xz@nuAbv@~~AzvA|~Bbf@bx@zvAz~Bdj@zgAxx@vcBfmAvaCpfArwAf`AdqAj{@~jAnmA~aBp|@zfArzAx|Alt@rr@~zAhnBtgAf~Ari@~`Adw@deBz}@lqAlbA~bA", "color": "green"}, {"polyline": "}shsFrstgM`kBfoBbm@zyAf`AboB~e@t`A", "color": "yellow"}, {"polyline": "oq`sFpp`hMnz@lcBjoAjwAxcAftAl{@zoAr_Ab_Byb@jzAsd@tr@dKjuC`Mt|BhNnqBjE|kBrHrhCc`@zwAmd@|}Agb@byAe]bzCeG`u@", "color": "yellow"}],
        // }]
    };
}
/* eslint-enable @typescript-eslint/no-unused-vars */
