import { LocationData, SelectedCityState, locationsHaveEqualCenters } from '../../../types';
import * as React from 'react';
import { cardBackgroundColor } from "../../../constants";
import { RatingKey, formatRatingKey, isImpactKey } from 'src/types/RatingKey';
import { AutoShowErrorDialog } from 'src/components/shared/AutoShowErrorDialog';

interface VenueCalloutViewProps {
    selectedCityState: SelectedCityState;
    lightningProbability?: number;
    selectedImpact?: number;
    impactKey?: string;
    disableSaveLocation?: boolean;

    lat: number;
    lng: number;

    onClose: () => void;
    saveLocationTapped: (location: LocationData) => void;
    unsaveLocationTapped: (location: LocationData) => void;
    onCitySaveErrorDismissed: (location: LocationData) => void;
}

export const WeatherCalloutView = (props: VenueCalloutViewProps) => {
    const { selectedCityState } = props;
    const { selectedCity } = selectedCityState;

    if (selectedCity === undefined) {
        return <div />;
    }

    let weatherValue: string | undefined = undefined;

    if (props.impactKey) {
        if (props.impactKey === "lightningProbability" && props.lightningProbability !== undefined) {
            weatherValue = (props.lightningProbability! * 100).toFixed(0) + '%';
        } else if (props.selectedImpact !== undefined) {
            weatherValue = props.selectedImpact?.toString();
            if (isImpactKey(props.impactKey)) {
                weatherValue = `${weatherValue} out of ${10}`;
            } else {
                if (props.impactKey.indexOf('accumulation') !== -1) {
                    weatherValue = `${weatherValue} in`;
                } else if (props.impactKey.indexOf('wind') !== -1) {
                    weatherValue = `${weatherValue} mph`;
                } else {
                    weatherValue = `${weatherValue}°`;
                }
            }
        }
    }

    let locationButton: JSX.Element;
    let isCitySaved = selectedCity.id !== undefined;
    if (isCitySaved) {
        locationButton = (
            <div className={"location-button"} onClick={() => props.unsaveLocationTapped(selectedCity)}>
                <img src={"/images/saved_check.svg"} alt={"✓"} />
                <span>Saved</span>
            </div>
        );
    } else {
        locationButton = (
            <div className={"location-button"} onClick={() => props.saveLocationTapped(selectedCity)}>
                <span>Save Location</span>
            </div>
        );
    }

    const isSavingThisCity = selectedCityState.cityBeingSaved !== undefined &&
        locationsHaveEqualCenters(selectedCityState.cityBeingSaved, selectedCity) &&
        selectedCityState.citySavingError === undefined;

    const isLoading = selectedCityState.isGeocoding || selectedCity.needsGeocoding || isSavingThisCity;

    let extraClassNames = "";
    if (isLoading) {
        extraClassNames = " loading";
    }

    let zip = selectedCity.zip;
    if (zip === undefined || zip === '00000') {
        // don't display non-geocoded zips
        zip = '';
    }

    return (
        <div className={`MapCalloutView${extraClassNames}`} onClick={(event) => event.stopPropagation()}>
            <div className={"container"} style={{ cursor: "default" }}>
                <div className={"body"} style={{ backgroundColor: cardBackgroundColor }}>
                    <button className={"close-button"} onClick={() => props.onClose()}>
                        <img alt={'Close'} src={"/images/popover_x.svg"} />
                    </button>

                    <div className={"loading-view"}>
                        <img src={"/images/loading.gif"} alt={"Loading..."} />
                    </div>

                    <div className={"zip"}>{zip}</div>
                    <div className={"city-name"} style={props.impactKey === undefined ? { margin: '12px' } : {}}>
                        {selectedCity.name || 'Unknown Location'}
                    </div>
                    {props.impactKey && <div>
                        <div className={"index-name"}>{formatRatingKey(props.impactKey as RatingKey).replace(/([A-Z])/g, ' $1')}</div>
                        <div className={"index-value"} style={{ "paddingBottom": "10px" }}>
                            {weatherValue}
                            {/*
                                added a check if indexValue is an empty string to show error because the
                                wildfire API call uses ratingsFetchError as well so we can have an error
                                for wildfire even though the impact rating (e.g. road) loaded fine
                            */}
                            <img
                                style={{ display: weatherValue === undefined ? 'inline-block' : 'none', marginBottom: -4 }}
                                width={24}
                                height={24}
                                src={"/images/loading.gif"}
                                alt={"Loading..."}
                            />
                        </div>
                    </div>}
                    {!props.disableSaveLocation && locationButton}
                </div>
                <div className={"arrow-down"} style={{ borderTop: `20px solid ${cardBackgroundColor}` }} />
            </div>
            <AutoShowErrorDialog
                title={"Error saving location"}
                error={selectedCityState.citySavingError}
                onDismiss={() => props.onCitySaveErrorDismissed(selectedCity)}
            />
        </div>
    );
};
