import React from 'react';
import { useD3 } from '../../shared/useD3';
import * as d3 from 'd3';
import './TimelineChart.css';
import {WeatherConditions, WeatherData, } from '../../../types';
import { colorForImpactLevel, emojiForImpactLevel, ImpactLevel, wordForImpactLevel } from "../../../types/routes";
import {Timeframe, updateTimeframeForTimezone} from "./data";
import moment from 'moment';
import { normalDist } from 'src/types/math';
import { Vector } from 'ts-matrix';

type WeatherImpact = "lightningProbability" | "rainfall" | "windGustProbability" | "temperature";

interface ImpactWindow {
    key: WeatherImpact;
    impactLevel: ImpactLevel;
    from: number;
    to: number;
}

interface WeatherImpactWindows {
    lightning: ImpactWindow[];
    rainfall: ImpactWindow[];
    wind_gust: ImpactWindow[];
    temperature: ImpactWindow[];
}

enum HoursInMilliseconds {
    One = 60 * 60 * 1000,
    Three = 60 * 60 * 1000 * 3,
    Twelve = 60 * 60 * 1000 * 12
}

function getImpactConfidenceValue(precipitationValue: number, confidenceValue: number) {
    if (precipitationValue >= 0.5) {
        if (confidenceValue >= 0.75) {
            return ImpactLevel.Extreme;
        } else if (confidenceValue >= 0.25 && confidenceValue < 0.75 ) {
            return ImpactLevel.High;
        } else {
            return ImpactLevel.Moderate;
        } 
    } else if (precipitationValue >= 0.3) {
        if (confidenceValue >= .5) {
            return ImpactLevel.High;
        }
        return ImpactLevel.Moderate;
    } else if (precipitationValue >= 0.1) {
        if (confidenceValue >= .5) {
            return ImpactLevel.Moderate;
        }
        return ImpactLevel.Low;
    } else if (precipitationValue >= 0.01) {
        return ImpactLevel.Low;
    }
    return ImpactLevel.None;
}

function calculatePrecipitationConfidenceInterval(precipitationValue: number | undefined, windGustValue: number | undefined, visibilityValue: number | undefined): (ImpactLevel | number)[] {
    if (precipitationValue === undefined || windGustValue === undefined || visibilityValue === undefined) return [ImpactLevel.None, 0];

    const sampleSize: number = 10000;
    const mu: number = -0.039 + (.81 * precipitationValue) + (-0.001 * windGustValue) + (0.004 * visibilityValue);
    const yDist: number[] = normalDist(mu, 1/Math.sqrt(190.831), sampleSize);

    const yDistVec = new Vector(yDist);
    const APCPVec = new Vector(new Array(sampleSize).fill(precipitationValue));
    const APCPDist = APCPVec.subtract(yDistVec);

    const probGte01 = APCPDist.values.reduce(function (acc: number, val: number) { return val >= 0.01 ? acc + 1 : acc; }, 0) / sampleSize;
    const probGte1 = APCPDist.values.reduce(function (acc: number, val: number) { return val >= 0.1 ? acc + 1 : acc; }, 0) / sampleSize;
    const probGte3 = APCPDist.values.reduce(function (acc: number, val: number) { return val >= 0.3 ? acc + 1 : acc; }, 0) / sampleSize;
    const probGte5 = APCPDist.values.reduce(function (acc: number, val: number) { return val >= 0.5 ? acc + 1 : acc; }, 0) / sampleSize;

    if (precipitationValue >= 0.5) return [getImpactConfidenceValue(precipitationValue, probGte5), probGte5];
    if (precipitationValue >= 0.3 && precipitationValue < 0.5) return [getImpactConfidenceValue(precipitationValue, probGte3), probGte3];
    if (precipitationValue >= 0.1 && precipitationValue < 0.3) return [getImpactConfidenceValue(precipitationValue, probGte1), probGte1];
    if (precipitationValue >= 0.01 && precipitationValue < 0.1) return [getImpactConfidenceValue(precipitationValue, probGte01), probGte01];
    
    return [getImpactConfidenceValue(precipitationValue, 1), 1];
}

function findImpactLevelForWeatherData(value: number, weatherKey: WeatherImpact): ImpactLevel {
    if (weatherKey === "lightningProbability") {
        if (value >= 0.8) return ImpactLevel.Extreme;
        if (value >= 0.6 && value < 0.8) return ImpactLevel.High;
        if (value >= 0.3 && value < 0.6) return ImpactLevel.Moderate;
        if (value >= 0.1 && value < 0.3) return ImpactLevel.Low;
        if (value === undefined) return ImpactLevel.Unknown;
    } else if (weatherKey === "windGustProbability") {
        if (value >= 0.8) return ImpactLevel.Extreme;
        if (value >= 0.5 && value < 0.8) return ImpactLevel.High;
        if (value >= 0.25 && value < 0.5) return ImpactLevel.Moderate;
        if (value >= 0.1 && value < 0.25) return ImpactLevel.Low;
    } else if (weatherKey === "temperature") {
        // high temps
        if (value >= 105) return ImpactLevel.Extreme;
        if (value >= 100 && value < 105) return ImpactLevel.High;
        if (value >= 95 && value < 100) return ImpactLevel.Moderate;
        if (value >= 90 && value < 95) return ImpactLevel.Low;
    }
    return ImpactLevel.None;
}

function createImpactWindows(weatherData: any[], key: WeatherImpact): ImpactWindow[] {
    if(weatherData.length === 0){
        return [];
    }
    let impactWindows: ImpactWindow[] = [];
    let windowStack: ImpactWindow[] = [];

    let lastImpactLevel: ImpactLevel | undefined = ImpactLevel.None;
    for (const wdata of weatherData) {
        let impactLevel: ImpactLevel | undefined = undefined;
        if (key === 'rainfall') {
            [impactLevel,] = calculatePrecipitationConfidenceInterval(wdata['rainfall'], wdata['windGust'], wdata['visibility']);
        } else {
            impactLevel = findImpactLevelForWeatherData(wdata[key === 'temperature' ? 'heatIndex' : key], key);
        }
        // impact level changes signifying the start/end of impact window(s)
        if(impactLevel !== lastImpactLevel) {
            // while there are higher impact level windows in the stack remove them, add the end window time and add them to the impact window array
            while(windowStack.length > 0 && windowStack[windowStack.length-1].impactLevel > impactLevel) {
                let completeWindow: ImpactWindow | undefined = windowStack.pop();
                completeWindow!.to = wdata!.time.getTime(),
                impactWindows.push(completeWindow!);
            }
            // if the new impact level is higher than the last impact level start new impact window(s) for each level above the last impact level
            if(impactLevel > lastImpactLevel!) {
                for (let il = lastImpactLevel!+1; il <= impactLevel; il++){
                    windowStack.push({
                        key: key,
                        impactLevel: il,
                        from: wdata.time.getTime(),
                        to: -1
                    });
                }
            }
        }
        lastImpactLevel = impactLevel;
    }

    // pop any remaining impact windows off the stack and make the end time the rating range end time
    while(windowStack.length > 0) {
        let completeWindow: ImpactWindow | undefined = windowStack.pop();
        completeWindow!.to = weatherData[weatherData.length - 1]!.time.getTime() + HoursInMilliseconds.One, // add an hour to the of the time range so the last hour is displayed on the chart
        impactWindows.push(completeWindow!);
    }

    if (key === 'lightningProbability') {
        const subhourEnd = weatherData.findIndex(data =>!('lightningProbability' in data));
        if (subhourEnd >= 0) {
            impactWindows.push({
                key: key,
                impactLevel: ImpactLevel.None,
                from: weatherData[0].time.getTime(),
                to: weatherData[subhourEnd]!.time.getTime(),
            });
        }        
    }else {
        // finally, add an impact window for impact level "None" that covers the entire range (the default)
        impactWindows.push({
            key: key,
            impactLevel: ImpactLevel.None,
            from: weatherData[0].time.getTime(),
            to: weatherData[weatherData.length - 1]!.time.getTime() + HoursInMilliseconds.One,// add an hour to the of the time range so the last hour is displayed on the chart
        });
    }

    return impactWindows;
}

interface Props {
    id: string;
    weatherData: WeatherData;
    timeframe: Timeframe;
    impactSummariesUpdatedAt: Date | undefined;
    height: number;
}

function WeatherTimelineChart(props: Props) {
    const { weatherData, timeframe, impactSummariesUpdatedAt, height } = props;
    
    const weatherKeys = ["temperature", "windGustProbability", "rainfall", "lightningProbability"];
    const containerIdRef = React.useRef(`timeline-chart-${props.id}`);
    const containerId = containerIdRef.current;

    // ref/effect to recompute width of graph as needed
    const containerDivRef = React.useRef<HTMLDivElement>(null);
    const [divWidth, setDivWidth] = React.useState(0);
    React.useEffect(() => {
        function handleResize() {
            if (containerDivRef.current && containerDivRef.current.clientWidth !== divWidth) {
                setDivWidth(containerDivRef.current.clientWidth);
            }
        }

        window.addEventListener('resize', () => handleResize());
    });
    
    // update timeframe for local timezone and shift it back one day if the last updated time is before the today start date
    const timezoneTimeframe = updateTimeframeForTimezone(timeframe, weatherData.city.timezone as string, impactSummariesUpdatedAt);
    
    const filterWeatherImpactHours = (hours: WeatherConditions[], timeframe: Timeframe) => {
        let currentTime: Date = new Date();
        // round to last 15 min
        currentTime.setMinutes(Math.floor(currentTime.getMinutes() / 15) * 15, 0, 0);
        return hours.filter(hour => {
            return hour.time >= currentTime &&
                hour.time < timeframe.endTime.toDate();
        });
    };

    let filteredImpactHours = filterWeatherImpactHours(weatherData.hourly, timezoneTimeframe);
    let combinedWeatherData: WeatherConditions[] = [...filteredImpactHours];

    if (weatherData.subhourly !== undefined) {
        const filteredImpactSubhours = filterWeatherImpactHours(weatherData.subhourly!, timezoneTimeframe);
        const lastSubhour = filteredImpactSubhours.slice(-1)[0];
        // pad subhours with the last values until the next full hour
        const paddedSubHours = lastSubhour.time.getMinutes() === 0 ? 0 : (59 - lastSubhour.time.getMinutes()) / 15;
        for(let i = 1; i < paddedSubHours; i++) {
            const subHour = {...lastSubhour};
            subHour.time = moment(subHour.time).add(i * 15, 'm').toDate();
            filteredImpactSubhours.push(subHour);
        }
        
        // convert hourly to subhourly timescale and replace first 6 hours with subhourly data
        combinedWeatherData = [...filteredImpactSubhours];
        filteredImpactHours = filteredImpactHours.filter(wdata => wdata.time > filteredImpactSubhours[filteredImpactSubhours.length-1].time);
        filteredImpactHours.forEach(hour => {
            combinedWeatherData.push(hour);
            const baseTime = moment(hour.time);
            for(let i = 1; i < 4; i++) {
                const subHour = {...hour};
                subHour.time = baseTime.clone().add(i * 15, 'm').toDate(); 
                combinedWeatherData.push(subHour);
            }
        });
    }

    const impactWindowsDatasets: WeatherImpactWindows = {
        lightning: createImpactWindows(combinedWeatherData, 'lightningProbability'),
        rainfall: createImpactWindows(combinedWeatherData, 'rainfall'),
        wind_gust: createImpactWindows(combinedWeatherData, 'windGustProbability'),
        temperature: createImpactWindows(combinedWeatherData, 'temperature'),
    };

    const allImpactWindows: ImpactWindow[] = [...impactWindowsDatasets.lightning, ...impactWindowsDatasets.rainfall, ...impactWindowsDatasets.wind_gust, ...impactWindowsDatasets.temperature]
        .sort((d1, d2) => d1.impactLevel > d2.impactLevel ? 1 : -1);

    const d3SvgRef = useD3(
        (svg) => {
            if (!containerDivRef.current) return;
            if (allImpactWindows.length === 0) return;

            // clear chart if resizing
            svg.selectAll("*").remove();

            // setup new chart
            const chartWidth = containerDivRef.current.clientWidth;
            const chartHeight = containerDivRef.current.clientHeight;
            const margin = { left: 155, top: 5, bottom: 40, right: 45};
            // size of chart inside the axises
            const innerWidth = chartWidth - margin.left - margin.right;
            const innerHeight = chartHeight - margin.top - margin.bottom;

            svg.attr("width", chartWidth)
                .attr("height", chartHeight);
            
            const impactChart = svg.append("g")
                .attr("transform", "translate(" + margin.left + "," + margin.top + ")")
                .attr("width", innerWidth)
                .attr("height", innerHeight);

            const minTime = allImpactWindows.reduce(function(res, obj) {
                return (obj.from < res.from) ? obj : res;
            }).from;
            const maxTime = allImpactWindows.reduce(function(res, obj) {
                return (obj.to > res.to) ? obj : res;
            }).to;

            const xScale = d3.scaleTime()
                .domain([new Date(minTime), new Date(maxTime)])
                .range([0, innerWidth]);
            const yScale = d3.scaleBand()
                .domain(weatherKeys)
                .range([innerHeight, 0])
                .align(0.5)
                .padding(0.3);
            
            const xAxis = d3.axisBottom(xScale)
                // had to explicitly set tick values to get the axis labels to display properly (add 1 millisecond to max time to get last tick label to be added)
                .tickValues(d3.range(minTime, maxTime + 1, HoursInMilliseconds.Three).map(function(d){ return new Date(d); }))
                .tickFormat(function(d: Date, i: number) { 
                    let timeFormat = "ddd h:mm a"; // default to (non zero padded) hour AM/PM
                    return weatherData.city.timezone === undefined ? moment(d).format(timeFormat) : moment(d).tz(weatherData.city.timezone).format(timeFormat);
                });
            const yTickLabels = ["Temperature Risk", "Wind Gust Risk", "Rain Risk", "Lightning Risk"];
            const yAxis = d3.axisLeft(yScale)
                .tickSize(0) //remove y axis tick marks
                .tickFormat((d,i) => yTickLabels[i]);
            
            impactChart.append("g")
                .attr("transform", `translate(0, ${height - margin.bottom})`)
                .attr("fill", "white")
                .call(xAxis);                

            impactChart.append("g")
                .attr("fill", "white")
                .attr("transform", `translate(-120,0)`)
                .call(yAxis)
                .call(g => g.selectAll(".tick text")
                .attr("text-anchor", "start"))
                .call(g => g.selectAll(".tick text")
                .attr("font-size", "14"))
                .call(g => g.select(".domain").remove()); //remove y axis line
            
            // add impact windows to chart
            impactChart.selectAll("windows")
                .data(allImpactWindows)
                .enter().append("rect")
                .attr("className", "window")
                .attr("fill", (d: ImpactWindow) => colorForImpactLevel(d.impactLevel))
                .attr("rx", 5) // rounded corners
                .attr("ry", 5) // rounded corners
                .attr("x", (d: ImpactWindow) => xScale(d.from))
                .attr("y", (d: ImpactWindow) => yScale(d.key) as number)
                .attr("height", yScale.bandwidth())
                .attr("width", (d: ImpactWindow) => xScale(d.to) - xScale(d.from));
            
            let tooltip = d3.select("#" + containerId).select('div.timeline-tooltip');
            if (tooltip.empty()) tooltip = d3.select('#' + containerId).append('div');

            tooltip.attr("class", "timeline-tooltip")
                .style("opacity", 1.0)
                .style("visibility", "hidden")
                .style("z-index", 10005);

            const hoverLineGroup = impactChart.append("g")
                .style("visibility", "hidden")
                .style("z-index", 10005);

            hoverLineGroup.append("line")
                .attr("y1", 0)
                .attr("y2", innerHeight)
                .style("stroke", "white")
                .style("stroke-width", "2px")
                .style("opacity", 1.0);
            
            hoverLineGroup.append("path")
                .attr("d", d3.symbol().type(d3.symbolTriangle).size(50))
                .attr("fill", "white")
                .attr("transform", "rotate(180)");

            // Mouse events
            const mouseG = impactChart.append("g")
                .attr("class", "mouse-over-effects");

            mouseG.append("rect") // append a rect over the inner part of the chart to catch mouse events
                .attr("width", innerWidth)
                .attr("height", innerHeight)
                .attr("fill", "none")
                .attr("pointer-events", "all")
                .on('mouseout', () => {
                    tooltip.transition();
                    tooltip.style("visibility", "hidden");

                    hoverLineGroup.transition();
                    hoverLineGroup.style("visibility", "hidden");
                })                
                .on('mouseover', () => {
                    tooltip.transition();
                    tooltip.style("visibility", "visible");

                    hoverLineGroup.transition();
                    hoverLineGroup.style("visibility", "visible");
                })
                .on('mousemove', (event) => {
                    const coords = d3.pointer(event);
                    let timeHoveredSeconds = xScale.invert(coords[0]).getTime();
                    timeHoveredSeconds = timeHoveredSeconds < minTime ? minTime : timeHoveredSeconds; // round up to the minTime
                    timeHoveredSeconds = timeHoveredSeconds >= maxTime ? maxTime - 1 : timeHoveredSeconds; // round down to the maxTime (subtract one to account for adding one in tickValues above)
                    
                    // Tooltip
                    
                    // determine which weather variable is being hovered over
                    let index = Math.floor((coords[1] / (innerHeight / weatherKeys.length)));
                    index = index < 0 ? 0 : index; // round to the top rating 
                    index = index > (weatherKeys.length-1) ? weatherKeys.length-1 : index; // round to the bottom rating 
                    const weatherKey = yScale.domain().reverse()[index];

                    const dateHovered = new Date(timeHoveredSeconds);
                    
                    const hoveredWeather = combinedWeatherData.filter(wdata => wdata.time <= dateHovered).pop()!;
                    const datetimeString = hoveredWeather.time.toLocaleDateString('en-US', {weekday: 'short', month: 'short', day: 'numeric', timeZone: weatherData.city.timezone})
                        + ', '
                        + hoveredWeather.time.toLocaleTimeString('en-US', {hour: 'numeric', minute: 'numeric', hour12: true, timeZoneName: 'short', timeZone: weatherData.city.timezone });
                    // Date
                    let tooltipContent = `<div key="${weatherKey}_time" class="time">${datetimeString}</div>`;

                    // Weather score
                    let impactLevel = undefined;
                    let confidenceLevel = undefined;
                    if (weatherKey === 'rainfall') {
                        [impactLevel, confidenceLevel] = calculatePrecipitationConfidenceInterval(hoveredWeather['rainfall'], hoveredWeather['windGust'], hoveredWeather['visibility']);
                    } else {
                        impactLevel = findImpactLevelForWeatherData(hoveredWeather[weatherKey === 'temperature' ? 'heatIndex' : weatherKey], weatherKey as WeatherImpact);
                    }
                    const emoji = emojiForImpactLevel(impactLevel!);
                    let impactWord = wordForImpactLevel(impactLevel!);
                    impactWord = impactWord === "None" ? "Minimal" : impactWord;
                    if (weatherKey === "lightningProbability") {
                        if (!(weatherKey in hoveredWeather)) {
                            tooltipContent += `<div key="${weatherKey}_impact_score" class="impact">${`${emoji} ${impactWord} Risk`}</div>`;
                        } else {
                            const lightningProb: number = hoveredWeather[weatherKey]!;
                            const confidenceWord = lightningProb >= 0.8 ? "Very high" : lightningProb >= 0.6 ? "High" : lightningProb >= 0.3 ? "Moderate" : lightningProb >= 0.1 ? "Low": "Minimal";
                            tooltipContent += `<div key="${weatherKey}_impact_score" class="impact">${`${emoji} ${impactWord} Risk`}</div>`;
                            tooltipContent += `<div key="${weatherKey}_impact_score" class="impact">${`${confidenceWord} chance of a lightning strike <br> within a 4 mile radius in the next hour`}</div>`;
                        }                        
                    }
                    if (weatherKey === "rainfall") {                        
                        const rainfall = hoveredWeather['rainfall']!;
                        const rainWord = rainfall >= 0.5 ? "Torrential" : rainfall >= 0.3 ? "Heavy" : rainfall >= 0.1 ? "Moderate" : rainfall >= 0.01 ? "Light" : "No";
                        const confidenceWord = confidenceLevel! >= 0.75 ? "Very high" : confidenceLevel! >= 0.5 ? "High" : confidenceLevel! >= 0.25 ? "Moderate" : confidenceLevel! >= 0.05 ? "Low" : "Minimal";
                        tooltipContent += `<div key="${weatherKey}_impact_score" class="impact">${`${emoji} ${impactWord} Risk`}</div>`;
                        tooltipContent += `<div key="${weatherKey}_impact_score" class="impact">${`${rainWord} rain (${rainfall.toFixed(2)}"/hr)`}</div>`;
                        tooltipContent += `<div key="${weatherKey}_impact_score" class="impact">${`${confidenceWord} confidence`}</div>`;                        
                    }
                    if (weatherKey === "windGustProbability") {
                        const windGust = hoveredWeather['windGust']!;
                        const windWord = windGust >= 60 ? "Severe" : windGust >= 45 ? "Strong" : windGust >= 30 ? "Moderate" : "Light";
                        const windGustProb: number = hoveredWeather[weatherKey]!;
                        const confidenceWord = windGustProb >= 0.8 ? "Very high" : windGustProb >= 0.5 ? "High" : windGustProb >= 0.25 ? "Moderate" : windGustProb >= 0.1 ? "Low": "Minimal";
                        tooltipContent += `<div key="${weatherKey}_impact_score" class="impact">${`${emoji} ${impactWord} Risk`}</div>`;
                        tooltipContent += `<div key="${weatherKey}_impact_score" class="impact">${`${windWord} gusts (${windGust.toFixed(1)}mph)`}</div>`;
                        tooltipContent += `<div key="${weatherKey}_impact_score" class="impact">${`${confidenceWord} chance (next 4 hours) of gusts >= 45 mph`}</div>`;
                    }
                    if (weatherKey === "temperature") {
                        impactWord = hoveredWeather[weatherKey]! <= 70 ? "No" : impactWord;
                        tooltipContent += `<div key="${weatherKey}_impact_score" class="impact">${`${emoji} ${impactWord} Risk`}</div>`;
                        tooltipContent += `<div key="${weatherKey}_impact_score" class="impact">${`${impactWord} heat risk (${(hoveredWeather[weatherKey]!).toFixed(0)}°)`}</div>`;
                    }
                    
                    // place tooltip at the top of chart above the hover line
                    const yOffset: number = parseInt(tooltip.style("height")) + coords[1] + 25;
                    const xOffset: number = parseInt(tooltip.style("width")) / 2;
                    tooltip.html(tooltipContent)
                        .style("top", (event.clientY) - yOffset + "px")
                        .style("left", (event.clientX) - xOffset + "px");

                    hoverLineGroup!.attr('transform', `translate(${coords[0]},0)`);                    
                });
        },
        [weatherData, timeframe, containerDivRef.current, divWidth]
    );

    return (
        <div style={{display: 'flex', flexDirection: 'row'}}>
            <div ref={containerDivRef} id={containerId} style={{padding: 8, marginBottom: -4, flexGrow: 1}}>
                <svg
                    ref={d3SvgRef}
                    style={{
                        height: height - 15,
                        width: "100%",
                        marginRight: "0px",
                        marginLeft: "0px",
                        borderRadius: 3
                    }}
                >
                </svg>
            </div>
        </div>
    );
}

export default WeatherTimelineChart;
