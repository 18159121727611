import * as React from 'react';
import {Redirect} from 'react-router';

interface Props {
    token?: string;
    logOut: (token: string) => Promise<void>;
    toggleMenu: () => void;
}

const LogoutComponent = ({token, logOut, toggleMenu}: Props) => {
    if (typeof token !== 'undefined') {
        logOut(token);
    }

    toggleMenu();
    return <Redirect to="/"/>;
};

export default LogoutComponent;
