import * as React from 'react';
import {ScaledIcon} from './ScaledIcon';
import {Link} from 'react-router-dom';
import {Button, LinearProgress, Typography} from "@mui/material";

interface Props {
    isResettingPassword: boolean;
    lastError?: string;
    passwordResetSuccess: boolean;
    onResetRequested: (email: string) => Promise<void>;
    onResetCompleted: () => void;
}

interface State {
    email: string;
    password: string;
}

class ResetPasswordFormWithState extends React.Component {
    state: State = {
        email: "",
        password: "",
    };

    props: Props;

    onChange(fieldName: string, fieldValue: string) {
        this.setState({
            ...this.state,
            [fieldName]: fieldValue,
        });
    }

    onChangeInput(event: React.ChangeEvent<HTMLInputElement>) {
        this.onChange(event.target.name, event.target.value);
    }

    onSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        event.stopPropagation();
        event.bubbles = false;

        this.props.onResetRequested(this.state.email);

        return false;
    }

    render() {
        let lastErrorDisplay;
        if (this.props.lastError) {
            lastErrorDisplay = (
                <div>
                    <Typography color={'error'} variant={'body1'}>
                        {this.props.lastError}
                    </Typography>
                    <br/>
                </div>
            );
        }

        return (
            <form onSubmit={event => this.onSubmit(event)}>
                <input
                    required
                    name="email"
                    type="email"
                    onChange={(event) => this.onChangeInput(event)}
                    className={"LoginForm-textfield"}
                    placeholder="Email"
                />

                {lastErrorDisplay}

                {this.props.isResettingPassword && (
                    <div>
                        <LinearProgress/>
                        <br/>
                    </div>
                )}

                <div>
                    <Button
                        type={'submit'}
                        variant={'contained'}
                        color={'primary'}
                        disabled={this.props.isResettingPassword}
                    >
                        Submit
                    </Button>

                    &nbsp;&nbsp;&nbsp;

                    <Button
                        href={"/login"}
                        color={'secondary'}
                    >
                        Back to Login
                    </Button>
                </div>
            </form>
        );
    }
}

const ResetPasswordForm = (props: Props) => {
    let content: JSX.Element;
    if (props.passwordResetSuccess) {
        content = (
            <div>
                <p>
                    Password reset was requested.
                    <br/>
                    Please check your email for instructions on how to proceed.
                </p>

                <p>
                    <Link
                        to={"/login"}
                        onClick={() => props.onResetCompleted()}
                    >
                        Back to Login
                    </Link>
                </p>
            </div>
        );
    } else {
        content = (
            <ResetPasswordFormWithState {...props}/>
        );
    }

    return (
        <div className={"LoginForm"}>
            <div className={"LoginForm-wrap"}>
                <ScaledIcon asset='logo' className={"LoginForm-logo"} alt="WeatherOptics"/>
                <br/>
                <Typography variant={'h4'} color={'textPrimary'}>Password Reset</Typography>
                <br/>
                {content}
            </div>
        </div>
    );
};

export default ResetPasswordForm;
