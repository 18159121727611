import { snakeCaseToTitleCase } from "src/util";
import { LocationData, VehicleTrackingData } from ".";

export const numericOperators = ['>=', '>', '<', '<=', 'is', 'is not'];
export const arrayOperators = ['any', 'includes any of', 'includes all of', 'does not include'];
export const singleValueOperators = ['is', 'is not'];
export const impactLevelOperators = ['is at least', 'is at most', 'is', 'is not'];
export const booleanOperators = ['true', 'false'];

export const assetFilterVariables = ['impact_level', 'risks', 'gov_alert_issued'];//, 'road_index', 'max_wind_gust'];

export interface AssetFilter {
    variable: string | null;
    operator: string | null;
    value: number | boolean | string | string[] | null;
}

export const areAssetFiltersEqual = (a: AssetFilter, b: AssetFilter) => {
    return a.variable === b.variable && a.operator === b.operator && a.value === b.value;
};

// Basic check that all properties are specified in the filter, used to check if the filter is fully entered
export const isAssetFilterValid = (filter: AssetFilter) => {
    const isVariablePresent = filter.variable !== null;
    const isOperatorPresent = filter.operator !== null;
    const isValueNeeded = filter.operator !== 'any' && filter.operator !== 'true' && filter.operator !== 'false';
    const isValuePresent = filter.value !== null;

    return isVariablePresent && isOperatorPresent && (isValuePresent || !isValueNeeded);
};

export const labelForVariable = (variable: string) => {
    switch (variable) {
        case 'gov_alert_issued':
            return "NWS Alerts";
        default:
            return snakeCaseToTitleCase(variable);
    }
};

export const labelForOperator = (operator: string) => {
    switch (operator) {
        default:
            return snakeCaseToTitleCase(operator);
    }
};

export const labelForValueOption = (option: string) => {
    switch (option) {
        case 'none':
            return '🟢 None';
        case 'low':
            return '🟡 Low';
        case 'moderate':
            return '🟠 Moderate';
        case 'high':
            return '🔴 High';
        default:
            return snakeCaseToTitleCase(option);
    }
};

// Does this location's subhourly impact data pass the given filter?
export const doesLocationPassFilterSubhourly = (location: LocationData, filter: AssetFilter) => {
    const summary = location.subhourlyImpactSummary;

    if (!summary) return true;

    switch (filter.variable) {
        case 'impact_level':
            const numericValue = ['none', 'low', 'moderate', 'high'].indexOf(filter.value as string);
            if (filter.operator === 'is at least') {
                return summary.impactLevel >= numericValue;
            } else if (filter.operator === 'is at most') {
                return summary.impactLevel <= numericValue;
            } else if (filter.operator === 'is') {
                return summary.impactLevel === numericValue;
            } else if (filter.operator === 'is not') {
                return summary.impactLevel !== numericValue;
            }
            break;
        case 'gov_alert_issued':
            if (!Array.isArray(filter.value)) {
                console.warn("invalid filter value for 'risks':", filter.value);
                return true;
            }

            if (filter.operator !== 'any' && filter.value.length === 0) {
                return true;
            }

            if (filter.operator === 'any') {
                return summary.tags.some(t => t.source === 'governmental_alert');
            } else if (filter.operator === 'includes any of') {
                return filter.value.some(tag => summary.tags.some(t => t.text === tag));
            } else if (filter.operator === 'includes all of') {
                return filter.value.every(tag => summary.tags.some(t => t.text === tag));
            } else if (filter.operator === 'does not include') {
                return !filter.value.every(tag => summary.tags.some(t => t.text === tag));
            }
            break;
        case 'risks':
            if (!Array.isArray(filter.value)) {
                console.warn("invalid filter value for 'risks':", filter.value);
                return true;
            }

            if (filter.operator !== 'any' && filter.value.length === 0) {
                return true;
            }

            if (filter.operator === 'any') {
                return summary.tags.some(t => t.source === 'HYPERR');
            } else if (filter.operator === 'includes any of') {
                return filter.value.some(tag => summary.tags.some(t => t.text === tag));
            } else if (filter.operator === 'includes all of') {
                return filter.value.every(tag => summary.tags.some(t => t.text === tag));
            } else if (filter.operator === 'does not include') {
                return !filter.value.every(tag => summary.tags.some(t => t.text === tag));
            }
            break;
        default:
            break;
    }

    return true;
};

// Does this vehicles's subhourly impact data pass the given filter?
export const doesVehiclePassFilterSubhourly = (vehicle: VehicleTrackingData, filter: AssetFilter) => {
    const summary = vehicle.currentImpact;

    if (!summary) return true;

    switch (filter.variable) {
        case 'impact_level':
            const numericValue = ['none', 'low', 'moderate', 'high'].indexOf(filter.value as string);
            if (filter.operator === 'is at least') {
                return summary.overallImpactLevel >= numericValue;
            } else if (filter.operator === 'is at most') {
                return summary.overallImpactLevel <= numericValue;
            } else if (filter.operator === 'is') {
                return summary.overallImpactLevel === numericValue;
            } else if (filter.operator === 'is not') {
                return summary.overallImpactLevel !== numericValue;
            }
            break;
        case 'gov_alert_issued':
            if (!Array.isArray(filter.value)) {
                console.warn("invalid filter value for 'risks':", filter.value);
                return true;
            }

            if (filter.operator !== 'any' && filter.value.length === 0) {
                return true;
            }

            if (filter.operator === 'any') {
                return summary.tags.some(t => t.source === 'governmental_alert');
            } else if (filter.operator === 'includes any of') {
                return filter.value.some(tag => summary.tags.some(t => t.text === tag));
            } else if (filter.operator === 'includes all of') {
                return filter.value.every(tag => summary.tags.some(t => t.text === tag));
            } else if (filter.operator === 'does not include') {
                return !filter.value.every(tag => summary.tags.some(t => t.text === tag));
            }
            break;
        case 'risks':
            if (!Array.isArray(filter.value)) {
                console.warn("invalid filter value for 'risks':", filter.value);
                return true;
            }

            if (filter.operator !== 'any' && filter.value.length === 0) {
                return true;
            }

            if (filter.operator === 'any') {
                return summary.tags.some(t => t.source === 'HYPERR');
            } else if (filter.operator === 'includes any of') {
                return filter.value.some(tag => summary.tags.some(t => t.text === tag));
            } else if (filter.operator === 'includes all of') {
                return filter.value.every(tag => summary.tags.some(t => t.text === tag));
            } else if (filter.operator === 'does not include') {
                return !filter.value.every(tag => summary.tags.some(t => t.text === tag));
            }
            break;
        default:
            break;
    }

    return true;
};
