import { colorForImpactLevel } from "src/types/routes";
import { makeImpactClusterer } from "./markers/ImpactClusterer";
import { makeMeanClusterer } from "./markers/MeanClusterer";
import { airportMarker16Svg, locationMarker16Svg, portMarker16Svg, railroadMarker16Svg, refineryMarker16Svg, storeMarker16Svg, warehouseMarker16Svg } from "./markers/markers";

export function getLayerForMap(map, layerIndex) {
    const div = map.getDiv().querySelector(`div[class=gm-style] > div:nth-child(1) > div:nth-child(1) > div:nth-child(${layerIndex})`);
    if (!div) {
        console.warn("could not get layer div from map: this likely means that the safety-pigged code has been broken by a gmaps sdk update.");
    }
    return div;
}

export function setZIndexForLayer(map, layerIndex, zIndex) {
    const div = getLayerForMap(map, layerIndex);
    if (div) {
        div.style.zIndex = zIndex;
    }
}

export const darkModeStyle = [
    {
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#212121"
            }
        ]
    },
    {
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#757575"
            }
        ]
    },
    {
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "color": "#212121"
            }
        ]
    },
    {
        "featureType": "administrative.province",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#757575"
            }
        ]
    },
    {
        "featureType": "administrative.country",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#9e9e9e"
            }
        ]
    },
    {
        "featureType": "administrative.country",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#656565"
            }
        ]
    },
    {
        "featureType": "administrative.land_parcel",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "administrative.locality",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#bdbdbd"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#2c2c2c"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#8a8a8a"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#373737"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#3c3c3c"
            }
        ]
    },
    {
        "featureType": "road.highway.controlled_access",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#4e4e4e"
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#616161"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#000000"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#3d3d3d"
            }
        ]
    }
];

export const labelsStyle = [
    ...darkModeStyle,
    {
        featureType: 'all',
        stylers: [
            { visibility: 'off' }
        ]
    },
    {
        featureType: 'administrative',
        elementType: 'labels',
        stylers: [{ visibility: 'on' }]
    },
    {
        featureType: 'landscape',
        elementType: 'labels',
        stylers: [{ visibility: 'on' }]
    },
    {
        featureType: 'road',
        elementType: 'labels',
        stylers: [{ visibility: 'on' }]
    },
    {
        featureType: 'water',
        elementType: 'labels',
        stylers: [{ visibility: "on" }]
    },
    {
        featureType: 'transit.line',
        elementType: 'labels',
        stylers: [{ visibility: 'on' }]
    },
    {
        "featureType": "administrative",
        "elementType": "geometry.stroke",
        stylers: [{ visibility: 'on' }]
    }
];

export const maskStyle = [
    ...darkModeStyle,
    {
        featureType: 'all',
        stylers: [
            { visibility: 'off' }
        ]
    },
    {
        featureType: 'administrative',
        elementType: 'labels',
        stylers: [{ visibility: 'on' }]
    },
    {
        featureType: 'landscape',
        elementType: 'labels',
        stylers: [{ visibility: 'on' }]
    },
    {
        featureType: 'road',
        elementType: 'labels',
        stylers: [{ visibility: 'on' }]
    },
    {
        featureType: 'transit.line',
        elementType: 'labels',
        stylers: [{ visibility: 'on' }]
    },
    {
        "featureType": "water",
        stylers: [{ visibility: "on" }]
    },
    {
        "featureType": "administrative",
        "elementType": "geometry.stroke",
        stylers: [{ visibility: 'on' }]
    }
];

export function getContrastYIQ(hexcolor) {
    var r = parseInt(hexcolor.substring(1, 3), 16);
    var g = parseInt(hexcolor.substring(3, 5), 16);
    var b = parseInt(hexcolor.substring(5, 7), 16);
    var yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
    return (yiq >= 128) ? 'black' : 'white';
}

class MapManager {
    _verbose = true;
    _TAG = "MapManager";

    map = undefined;
    maps = undefined;

    desiredTileLayer = undefined;
    desiredTileLayerIndex = -1;

    _emptyCurrentTileLayer = undefined;
    currentTileLayerMetadata = undefined;

    targetTileOpacity = 1;

    _emptyTrafficIncidentsLayer = undefined;

    maskLayer = undefined;
    labelsLayer = undefined;

    // 0 = off, 1 = labels & lines, 2 = masked out bodies of water
    maskType = 0;

    _idealLayerTypes = [];

    previouslySelectedLocation = undefined;
    previousTimelineView = undefined;
    previousTimeframe = undefined;

    previouslySelectedLightning = undefined;
    previouslySelectedFire = undefined;
    previouslySelectedStormReport = undefined;

    mapsAPILoaded(map, maps) {

        if (this.maskLayer === undefined) {
            this.maskLayer = new maps.StyledMapType(maskStyle, { name: 'roads' });
        }

        if (this.labelsLayer === undefined) {
            this.labelsLayer = new maps.StyledMapType(labelsStyle, { name: 'labels' });
        }

        // this is handled in RouteAndMarkerMap
        // if (map !== this.map) {
        //     // changed map

        //     // set dark mode
        //     var styledMapType = new maps.StyledMapType([...darkModeStyle], { name: "Map" });

        //     map.mapTypes.set('weather_optics', styledMapType);
        //     map.setMapTypeId('weather_optics');
        // }

        this.map = map;
        this.maps = maps;

        this._emptyCurrentTileLayer = new maps.ImageMapType({
            getTileUrl: function (tile, zoom) {
                return "/images/empty_tile.png";
            },
            opacity: 0,
            tileSize: new this.maps.Size(256, 256),
            name: 'Empty Current Layer',
        });
        this._emptyTrafficIncidentsLayer = new maps.ImageMapType({
            getTileUrl: function (tile, zoom) {
                return "/images/empty_tile.png";
            },
            opacity: 0,
            tileSize: new this.maps.Size(256, 256),
            name: 'Empty Traffic Incidents Layer',
        });

        this.map.overlayMapTypes.insertAt(0, this._emptyCurrentTileLayer);
        this.map.overlayMapTypes.insertAt(1, this._emptyTrafficIncidentsLayer);
    }

    showTrafficIncidents(layer) {
        this.map.overlayMapTypes.setAt(1, layer ?? this._emptyTrafficIncidentsLayer);
    }

    // Mean clusterer: colors clusters differently depending on whether they have more or less locations
    // than the mean locations in a cluster
    meanClusterer = null;

    // Impact clusterer: displays a pie chart of how many locations in the cluster are impacted
    // and at what level
    impactClusterer = null;

    setupMarkers(locations, isLocationsVisible, selectedLocation, timelineView, timeframe, isNowcastMap, MarkerClusterer, Algorithm, locationClickListener, clusterClickListener) {
        console.log(`setupMarkers being called with ${locations.length} locations and is visible? ${isLocationsVisible}`);
        this.locationMarkers ||= {};

        if (this.meanClusterer === null) {
            this.meanClusterer = makeMeanClusterer(this.maps, this.map, MarkerClusterer, Algorithm, clusterClickListener);
        }

        if (this.impactClusterer === null) {
            this.impactClusterer = makeImpactClusterer(this.maps, this.map, MarkerClusterer, Algorithm, clusterClickListener);
        }

        let shouldRenderClusters = false;

        // show/hide clusterers as needed
        // we only want to call setMap when the value is changing to prevent flickering
        const isOnImpact = timelineView === 'impact';
        const wasOnImpact = this.previousTimelineView === 'impact';

        // show/hide impact clusterer
        if (isLocationsVisible && isOnImpact) {
            // show impact clusterer
            if (!this.impactClusterer.getMap()) {
                shouldRenderClusters = true;
                this.impactClusterer.setMap(this.map);
            }
        } else if (!isLocationsVisible || (!isOnImpact && wasOnImpact)) {
            // hide impact clusterer
            if (this.impactClusterer.getMap()) {
                this.impactClusterer.setMap(null);
            }
        }

        // show/hide mean clusterer
        if (isLocationsVisible && !isOnImpact) {
            // show mean clusterer
            if (!this.meanClusterer.getMap()) {
                shouldRenderClusters = true;
                this.meanClusterer.setMap(this.map);
            }
        } else if (!isLocationsVisible || (isOnImpact && !wasOnImpact)) {
            // hide mean clusterer
            if (this.meanClusterer.getMap()) {
                this.meanClusterer.setMap(null);
            }
        }

        if (!isLocationsVisible) {
            return;
        }

        // track which ids we want to create
        const locationIdsToCreate = new Set(locations.map(x => String(x.id)));
        const markersToRemove = [];
        this.locationMarkers = Object.fromEntries(Object.entries(this.locationMarkers).filter(([id, locationMarker]) => {
            // we already have a marker for this id so we remove it from the list of markers we want to create
            if (locationIdsToCreate.has(id)) {
                locationIdsToCreate.delete(id);
                return true;
            } else {
                // we don't want to create this marker so we'll store it to remove later from 
                // the clusterer and filter it from the locationMarkers dict by returning false here
                markersToRemove.push(locationMarker);
                return false;
            }
        }));

        // tracks if we've done something that would require re-rendering the clusters
        // like removing or adding markers, we defer all add/remove redraws by passing
        // true to noDraw for add/remove calls
        if (markersToRemove.length > 0) {
            shouldRenderClusters = true;
            this.impactClusterer?.removeMarkers(markersToRemove, true);
            this.meanClusterer?.removeMarkers(markersToRemove, true);
        }

        console.time('adding location markers');

        console.log(`adding ${locationIdsToCreate.size} markers for locations`);

        locations.forEach(loc => {
            const id = String(loc.id);
            // NOTE: disabled on 2024-08-20 because this wouldn't redraw
            // markers that had an updated impact summary
            // if we have this id in the set of locations we want to create
            // OR this is the selectedLocation element
            // OR this is the previouslySelectedLocation element
            // then we want to process it; otherwise, we skip
            // if (!(locationIdsToCreate.has(id)
            //     || (selectedLocation && String(selectedLocation.id) === id)
            //     || (this.previouslySelectedLocation && String(this.previouslySelectedLocation.id) === id)
            //     || (timelineView !== this.previousTimelineView)
            //     || (timeframe !== this.previousTimeframe)
            // )) {
            //     return;
            // }

            // if we have a timeframe and impact summary for this city, show a colored pin matching the impact level
            // use default red markers for site locations
            const isSelectedLocation = selectedLocation && String(selectedLocation.id) === id;
            // console.log("isSelectedLocation " + isSelectedLocation + " " + selectedLocation.id + " " + id);
            let pinScale = isSelectedLocation ? 1.35 : 1;
            const pinOptions = {
                scale: pinScale,
                // background: '#1E92F4',
                // borderColor: '#F7F7F7',
                // glyphColor: '#0967b7',
            };

            const defaultColor = '#00bfff';

            let color = defaultColor;
            let matchingImpactSummary;
            if (timelineView && timelineView === 'impact' && timeframe) {
                matchingImpactSummary = isNowcastMap ? loc.subhourlyImpactSummary : loc.impactSummaries.find((s) => s.label === timeframe.label);
                if (matchingImpactSummary) {
                    color = colorForImpactLevel(matchingImpactSummary.impactLevel);
                    pinOptions.background = color;
                    pinOptions.borderColor = color;
                    pinOptions.glyphColor = '#F7F7F7';
                }
            }
            const pin = new this.maps.marker.PinElement(pinOptions);

            const svgSize = 24;
            let svgIcon;

            const category = ((loc.userMetadata && loc.userMetadata.category) ?? "unknown").toLowerCase();
            switch (category) {
                case 'airport':
                    svgIcon = airportMarker16Svg(color);
                    break;
                case 'refinery':
                    svgIcon = refineryMarker16Svg(color);
                    break;
                case 'port':
                    svgIcon = portMarker16Svg(color);
                    break;
                case 'railroad':
                    svgIcon = railroadMarker16Svg(color);
                    break;
                case 'store':
                    svgIcon = storeMarker16Svg(color);
                    break;
                case 'warehouse':
                    svgIcon = warehouseMarker16Svg(color);
                    break;
                default:
                    svgIcon = locationMarker16Svg(color);
                    break;
            }

            svgIcon.setAttribute("width", svgSize);
            svgIcon.setAttribute("height", svgSize);
            svgIcon.setAttribute("transform", `translate(0 ${svgSize / 2})`);
            if (isSelectedLocation) {
                svgIcon.setAttribute("filter", "drop-shadow(0 0 10px " + color + ")");
            }

            const useDefaultPin = false; // leaving this in just in case we need to temporarily reverse the decision to commit to the new pins

            // if we already have a marker for this event
            // we can reuse it and just update the content to handle selectedLightning changes
            // otherwise we create a new marker
            let locationMarker = this.locationMarkers[id];
            if (locationMarker === undefined) {
                const lng = loc.longitude;
                const lat = loc.latitude;

                locationMarker = new this.maps.marker.AdvancedMarkerElement({
                    position: { lng, lat },
                    title: `Location at ${lat}, ${lng}`,
                    content: useDefaultPin ? pin.element : svgIcon
                });

                this.locationMarkers[id] = locationMarker;

                this.impactClusterer?.addMarker(locationMarker, true);
                this.meanClusterer?.addMarker(locationMarker, true);
            } else {
                locationMarker.content = useDefaultPin ? pin.element : svgIcon;
            }

            // set the click listener every time so that it has the newest data
            let locationClone = { ...loc };
            locationMarker.clickListener?.remove();
            locationMarker.clickListener = locationMarker.addListener('click', (event) => locationClickListener(event, locationClone));

            // need to always render clusters once the metadata changes
            shouldRenderClusters = true;

            // pass metadata so clusterer can later access it
            locationMarker.metadata = {
                matchingImpactSummary,
                id: loc.id,
            };
        });

        console.timeEnd('adding location markers');

        this.previouslySelectedLocation = selectedLocation;
        this.previousTimelineView = timelineView;
        this.previousTimeframe = timeframe;

        if (shouldRenderClusters) {
            console.log('rendering cluster after markers were changed');
            setTimeout(this.jiggleMap.bind(this), 0);
        }
    }

    // this 'hack' is used to re-render the markers/clusters
    // when a user toggles them to be visible
    // things that didn't work:
    // - calling render on the clusterer (dont show up)
    // - clearing and re-adding the markers on the clusterer (dont show up)
    // - using a custom algorithm that returns true for changed (shows clusters with 0 size)
    jiggleMap() {
        if (!this.map) return;

        const center = this.map.getCenter();
        this.map.panTo({ lat: center.lat() + 0.000001, lng: center.lng() + 0.000001 });
    }

    getImageMapForTileset(tileset) {
        const searchParams = new URL(tileset.url).searchParams;
        const name = searchParams.get('url') || tileset.url.split('weather/')[1]?.split('/{z}')?.[0] || 'Impact Layer';
        const tileLayerOptions = {
            getTileUrl: (tile, zoom) => {
                if (zoom < 1 || zoom > 15) {
                    return "/images/empty_tile.png";
                }
                return tileset.url.replace("{x}", tile.x)
                    .replace("{y}", tile.y)
                    .replace("{z}", zoom)
                    .replace("{size}", 1)
                    .replace("{token}", this.token);
            },
            tileSize: new this.maps.Size(256, 256),
            minZoom: 1,
            maxZoom: 15,
            name,
            opacity: 0,
        };
        const newTileLayer = new this.maps.ImageMapType(tileLayerOptions);
        newTileLayer.addListener("tilesloaded", () => {
            this.onTileLayerLoaded(newTileLayer);
            // tilesLoaded(newTileLayer);
        });
        return newTileLayer;
    }

    setDesiredTileLayer(desiredTileLayer) {
        console.log('setting desiredTileLayerIndex to', desiredTileLayer);
        this.desiredTileLayer = desiredTileLayer;

        this.invalidateLayers();
    }

    setTileOpacity(tileOpacity) {
        this.targetTileOpacity = tileOpacity;
        this.currentTileLayerMetadata?.layer?.setOpacity(this.targetTileOpacity);
    }

    setToken(token) {
        this.token = token;
    }

    _onTileLayerLoaded = undefined;

    onTileLayerLoaded(newTileLayer) {
        if (newTileLayer !== this.currentTileLayerMetadata?.layer) {
            // throw new Error('unexpected state');
            console.warn('newTileLayer not the currentTileLayer', newTileLayer, self.currentTileLayerMetadata?.layer);
            return;
        }

        // new current tile layer has loaded
        this.currentTileLayerMetadata.layer.setOpacity(this.targetTileOpacity);
        const visibleTileset = this.currentTileLayerMetadata.tileLayer;
        this._onTileLayerLoaded?.(visibleTileset);
    }

    setTileLayerLoaded(onTileLayerLoaded) {
        this._onTileLayerLoaded = onTileLayerLoaded;
    }

    invalidateLayers() {
        if (this.map === undefined) {
            return;
        }

        let shouldDisplayLabels = this.maskType === 1;
        let shouldDisplayMask = this.maskType === 2;

        if (this._verbose) console.log('overlayMapTypes before:', this.map.overlayMapTypes.getArray().map(x => x?.name));

        if (this.currentTileLayerMetadata?.tileLayer.id !== this.desiredTileLayer?.id) {
            if (this._verbose) console.log('current tileset:', this.currentTileLayerMetadata?.tileLayer, 'desired tileset:', this.desiredTileLayer);

            if (this.desiredTileLayer) {
                const previousTileLayer = this.currentTileLayerMetadata?.tileLayer;
                this.currentTileLayerMetadata = {
                    layer: this.getImageMapForTileset(this.desiredTileLayer),
                    tileLayer: this.desiredTileLayer,
                };
                // hide the old layer immediately if we're showing a different variable
                if (previousTileLayer?.variable !== this.desiredTileLayer?.variable) {
                    this.map.overlayMapTypes.getAt(0)?.setOpacity(0);
                }
                this.map.overlayMapTypes.setAt(0, this.currentTileLayerMetadata.layer);
            } else {
                this.currentTileLayerMetadata = undefined;
                // hide the old layer immediately if we have no desired tile layer
                this.map.overlayMapTypes.getAt(0)?.setOpacity(0);
                this.map.overlayMapTypes.setAt(0, this._emptyCurrentTileLayer);
            }
        }

        if (this._verbose) console.log('overlayMapTypes after:', this.map.overlayMapTypes.getArray().map(x => x?.name));

        // show the mask if we want it shown and it's hidden
        const maskLayerIndex = this.map.overlayMapTypes.getArray().indexOf(this.maskLayer);
        if (maskLayerIndex === -1 && shouldDisplayMask) {
            if (this._verbose) console.log(this._TAG, "pushing mask layer");
            this.map.overlayMapTypes.push(this.maskLayer);
        }

        // hide the mask if we want it hidden and it's being shown
        if (maskLayerIndex !== -1 && !shouldDisplayMask) {
            if (this._verbose) console.log(this._TAG, "removing mask layer");
            this.map.overlayMapTypes.removeAt(maskLayerIndex);
        }

        // show the labels if we want them shown and they're hidden
        const labelsLayerIndex = this.map.overlayMapTypes.getArray().indexOf(this.labelsLayer);
        if (labelsLayerIndex === -1 && shouldDisplayLabels) {
            if (this._verbose) console.log(this._TAG, "pushing labels layer");
            this.map.overlayMapTypes.push(this.labelsLayer);
        }

        // hide the labels if we want them hidden and they're being shown
        if (labelsLayerIndex !== -1 && !shouldDisplayLabels) {
            if (this._verbose) console.log(this._TAG, "removing labels layer");
            this.map.overlayMapTypes.removeAt(labelsLayerIndex);
        }
    }

    makeLatLngBounds(sw, ne) {
        return new this.maps.LatLngBounds(sw, ne);
    }
}

function NewLatLng(maps, lat, lng) {
    return new maps.LatLng(lat, lng);
}

export { MapManager, NewLatLng };
