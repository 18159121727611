import * as amplitude from '@amplitude/analytics-browser';
import { GA_ID } from './constants';
import ReactGA from 'react-ga4';

const disableOnDebug = false;

export function initializeAnalytics() {
    console.log("WO-ANALYTICS: test");
    // initialize amplitude
    if (!disableOnDebug || process.env.REACT_APP_ENV !== 'development') {
        console.log("WO-ANALYTICS: initializing analytics");
        amplitude.init('892e43bc1b789c8a174fbbe016764c9d', { defaultTracking: true });
    }

    // initialize GA
    ReactGA.initialize([
        {
            trackingId: GA_ID,
            gaOptions: {
                debug_mode: process.env.REACT_APP_ENV === 'development',
            },
        }
    ]);

    setUserIdForAnalytics(localStorage['userId']);
    setUserPropertyForAnalytics('Client Name', localStorage['clientName']);
    setUserPropertyForAnalytics('Environment', process.env.REACT_APP_ENV || 'Unknown');
}

export function trackEvent(name: string, parameters: Record<string, any> = {}) {
    if (!disableOnDebug || process.env.REACT_APP_ENV !== 'development') {
        console.log("WO-ANALYTICS: tracking event", name, parameters);
        amplitude.track(name, parameters);

        ReactGA.event({
            category: "portal_event",
            action: name,
        }, parameters);
    }
}

export function trackPageViewDuration(page_path: string, duration: number) {
    if (!disableOnDebug || process.env.REACT_APP_ENV !== 'development') {
        console.log("WO-ANALYTICS: tracking page view duration");
        amplitude.track('Page View Duration', {
            '[Amplitude] Page Path': page_path,
            duration,
        });
    }
}

export function setUserIdForAnalytics(userId: string) {
    if (!userId || userId === "") {
        console.warn("WO-ANALYTICS: NO USER ID or empty");
        return;
    }

    if (userId.length < 5) {
        const zeros = 5 - userId.length;
        for (let i = 0; i < zeros; i++) {
            userId = "0" + userId;
        }
    }

    if (!disableOnDebug || process.env.REACT_APP_ENV !== 'development') {
        console.log("WO-ANALYTICS: setting user ID for analytics", userId);
        amplitude.setUserId(userId);

        // TODO: set user id for GA4
    }
}

export function setUserPropertyForAnalytics(key: string, value: string) {
    if (!disableOnDebug || process.env.REACT_APP_ENV !== 'development') {
        console.log("WO-ANALYTICS: setting user property for analytics", key, value);
        const identifyEvent = new amplitude.Identify();
        identifyEvent.set(key, value);
        amplitude.identify(identifyEvent);

        const gaSafeKey = key.toLowerCase().replace(' ', '_');
        const gaProperties = {};
        gaProperties[gaSafeKey] = value;
        ReactGA.gtag("set", "user_properties", gaProperties);
    }
}