import { NowcastViewState } from '../types';
import { getClientImpactSections } from '../components/Client/Impact/sections';
import {
    NowcastSetPaused,
    NowcastSetSelectedMap,    
    NowcastSetTileOpacity,    
    NowcastViewAction
} from '../actions/NowcastView';

export function nowcastView(state: NowcastViewState, action: typeof NowcastViewAction.actions): NowcastViewState {
    if (typeof state === 'undefined') {
        let nowcastMapSections = getClientImpactSections(false, false);
        let nowcastMapSection = nowcastMapSections[0];
        let firstNowcastMapType = nowcastMapSection.mapTypes[0];

        return {
            selectedGraphView: 'rating',
            selectedMapCategorySlug: nowcastMapSection.slug,
            selectedMapType: firstNowcastMapType,
            tileOpacity: 1,
            paused: false,
        };
    }

    switch (action.type) {
        case NowcastSetSelectedMap.type:
            return {
                ...state,
                selectedGraphView: action.selectedMapCategory.key,
                selectedMapCategorySlug: action.selectedMapCategory.slug,
                selectedMapType: action.selectedMapType,
            };
        case NowcastSetTileOpacity.type:
            return { ...state, tileOpacity: action.tileOpacity };
        case NowcastSetPaused.type:
            return { ...state, paused: action.paused };
        default: return state;
    }
}
