import { Button, capitalize, TextField, Typography } from '@mui/material';
import * as React from 'react';
import { useLocation } from 'react-router';
import { ScaledIcon } from 'src/components/ScaledIcon';
import { API_HOST } from 'src/constants';

interface Props {
    provider: string;
}

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace SubmissionState {
    export enum Type {
        Unsubmitted,
        Loading,
        Error,
        Success
    }

    export interface _Unsubmitted {
        type: Type.Unsubmitted;
    }
    export function Unsubmitted(): _Unsubmitted {
        return {
            type: Type.Unsubmitted,
        };
    }
    export interface _Loading {
        type: Type.Loading;
    }
    export function Loading(): _Loading {
        return {
            type: Type.Loading,
        };
    }
    export interface _Error {
        type: Type.Error;
        message: string;
    }
    export function Error(message: string): _Error {
        return {
            type: Type.Error,
            message,
        };
    }
    export interface _Success {
        type: Type.Success;
    }
    export function Success(): _Success {
        return {
            type: Type.Success,
        };
    }
}

type SubmissionStateType = SubmissionState._Unsubmitted | SubmissionState._Loading | SubmissionState._Error | SubmissionState._Success;

const VehicleIntegrationComponent = ({ provider }: Props) => {
    const [email, setEmail] = React.useState<string | undefined>(undefined);
    const [submissionState, setSubmissionState] = React.useState<SubmissionStateType>(SubmissionState.Unsubmitted());
    const query = useQuery();

    // fetch cached route data
    const onSubmit = async (_: any) => {
        setSubmissionState(SubmissionState.Loading());

        try {
            const postData: RequestInit = {
                body: JSON.stringify({
                    email,
                    id: query.get('id'),
                    signature: query.get('signature'),
                }),
                cache: 'no-cache',
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                },
            };
            const response = await fetch(`${API_HOST}/${provider}/integration_confirmed`, postData);
            const json = await response.json();
            if (json["success"]) {
                setSubmissionState(SubmissionState.Success());
            } else {
                setSubmissionState(SubmissionState.Error(json['error'] || 'unknown error'));
            }
        } catch (e) {
            setSubmissionState(SubmissionState.Error(e.toString()));
        }
    };

    return (
        <div className={"LoginForm"}>
            <div className={"LoginForm-wrap"}>
                <ScaledIcon asset='logo' className={"LoginForm-logo"} alt="WeatherOptics" />
                <br />
                <Typography variant={'h4'} color={'textPrimary'}>WeatherOptics x {capitalize(provider)}</Typography>
                <br />
                <br />
                {(submissionState.type === SubmissionState.Type.Unsubmitted || submissionState.type === SubmissionState.Type.Error) &&
                    <div>
                        <Typography variant={'body1'} color={'textPrimary'}>Please enter your email address and a representative will reach out to get you started with our free trial.</Typography>
                        <br />
                        <br />
                        <form onSubmit={event => onSubmit(event)}>
                            <TextField
                                required
                                name="email"
                                value={email}
                                onChange={(event) => setEmail(event.target.value)}
                                style={{ minWidth: 280 }}
                                placeholder="Email"
                            />
                            <br />
                            <br />

                            {submissionState.type === SubmissionState.Type.Error &&
                                <div>
                                    <Typography variant={'body2'} color={'error'}>There was an error processing your request. Please try again or contact us at info@weatheroptics.co if the issues persists.</Typography>
                                    <p>Error Message: {submissionState.message}</p>
                                </div>
                            }
                            <div>
                                <Button
                                    type={'submit'}
                                    variant={'contained'}
                                    color={'primary'}
                                    disabled={email === ""}
                                >
                                    Submit
                                </Button>
                            </div>
                        </form>
                    </div>
                }
                {submissionState.type === SubmissionState.Type.Loading &&
                    <div>
                        <img
                            style={{ display: 'inline-block', marginBottom: -4 }}
                            width={24}
                            height={24}
                            src={"/images/loading.gif"}
                            alt={"Loading..."}
                        />
                    </div>
                }
                {submissionState.type === SubmissionState.Type.Success &&
                    <div>
                        <Typography variant={'body1'} color={'textPrimary'}>Thank you! We&apos;ll be in touch.</Typography>
                    </div>
                }
            </div>
        </div>
    );
};

export default VehicleIntegrationComponent;
