import * as React from 'react';
import { cardBackgroundColor, } from 'src/constants';
import { Box, Button, Checkbox, CircularProgress, FormControl, FormControlLabel, FormGroup, IconButton, Paper, Radio, RadioGroup, Typography } from '@mui/material';
import { Close, KeyboardArrowRight } from '@mui/icons-material';
import { AssetType } from '.';
import { TreeItem, SimpleTreeView } from '@mui/x-tree-view';
import { AssetFilter } from 'src/types/AssetFilter';
import { SingleFilterSelector } from './SingleFilterSelector';
import { ThreeDot } from 'react-loading-indicators';

const menuHeadings = {
    mapLayers: 'Map Layers',
    assetTypes: 'Asset Groups',
    filters: 'Filter Assets',
};

interface NowcastMenuProps {
    tilingLayer?: string;
    setTilingLayer: (layer?: string) => void;
    isGraphExpanded: boolean;
    itemCounts: any;
    assetAlerts: string[];

    isAlertsVisible: boolean;
    isCyclonesVisible: boolean;
    isEarthquakesVisible: boolean;
    isLightningVisible: boolean;
    isPowerOutagesVisible: boolean;
    isStormsVisible: boolean;
    isStormReportsVisible: boolean;
    isVolcanoesVisible: boolean;
    isFiresVisible: boolean;
    isWildfiresVisible: boolean;

    onAlertsToggled: (show: boolean) => void;
    onCyclonesToggled: (show: boolean) => void;
    onEarthquakesToggled: (show: boolean) => void;
    onLightningToggled: (show: boolean) => void;
    onPowerOutagesToggled: (show: boolean) => void;
    onStormsToggled: (show: boolean) => void;
    onStormReportsToggled: (show: boolean) => void;
    onVolcanoesToggled: (show: boolean) => void;
    onFiresToggled: (show: boolean) => void;
    onWildfiresToggled: (show: boolean) => void;

    trafficLayer?: string;
    onTrafficLayerSelected: (layer: string | undefined) => void;

    isLocationsLoading: boolean;
    locationAssetTypeOptions: AssetType[];
    locationSelectedAssetTypes: AssetType[];
    locationCountsByAssetCategory: Record<string, number>;
    onLocationSelectedTypesChanged: (assetTypes: AssetType[]) => void;

    isVehiclesLoading: boolean;
    vehicleAssetTypeOptions: AssetType[];
    vehicleSelectedAssetTypes: AssetType[];
    vehicleCountsByAssetCategory: Record<string, number>;
    onVehicleSelectedTypesChanged: (assetTypes: AssetType[]) => void;

    filters: AssetFilter[];
    onFiltersChanged: (filters: AssetFilter[]) => void;
}

const MenuCategoryButtons = (props: { category: string, onCategorySelected: (category: string) => void }) => {
    return (
        <>
            <header style={{ display: 'flex', flexDirection: 'row', alignContent: 'center' }}>
                <Typography variant={'subtitle1'} fontWeight={'bold'} color={'textPrimary'}>Menu</Typography>
            </header>
            <div style={{ display: 'flex', flexDirection: 'column', paddingTop: 12, paddingBottom: 12 }}>
                <Button
                    variant={props.category === 'risk-layers' ? 'contained' : 'outlined'}
                    color={'primary'}
                    size={'small'}
                    style={{ marginBottom: 12, paddingLeft: 16, paddingRight: 16 }}

                    onClick={() => props.onCategorySelected('risk-layers')}
                    href="javascript:;"
                >
                    &nbsp;
                    {menuHeadings.mapLayers}
                    <KeyboardArrowRight fontSize={'small'} />
                </Button>

                <Button
                    variant={props.category === 'asset-types' ? 'contained' : 'outlined'}
                    color={'primary'}
                    size={'small'}
                    style={{ marginBottom: 12, paddingLeft: 16, paddingRight: 16 }}

                    onClick={() => props.onCategorySelected('asset-types')}
                    href="javascript:;"
                >
                    &nbsp;
                    {menuHeadings.assetTypes}
                    <KeyboardArrowRight fontSize={'small'} />
                </Button>

                <Button
                    variant={props.category === 'filters' ? 'contained' : 'outlined'}
                    color={'primary'}
                    size={'small'}

                    onClick={() => props.onCategorySelected('filters')}
                    href="javascript:;"
                >
                    &nbsp;
                    {menuHeadings.filters}
                    <KeyboardArrowRight fontSize={'small'} />
                </Button>
            </div>
        </>
    );
};

export const EventLink = (props: { label: string, isChecked: boolean, onToggled: (checked: boolean) => void, itemCount?: any }) => {
    return (
        <FormControlLabel
            className={'checkbox'}
            control={<Checkbox
                sx={{ my: -0.5 }}
                size="small"
                checked={props.isChecked}
                onChange={event => props.onToggled(event.target.checked)} />}
            label={<Typography variant="caption" style={{ display: 'flex', alignItems: 'center' }}>
                {props.label}

                {' '}

                {/* parenthetical number of items or loading indicator if undefined */}
                {props.itemCount !== undefined ?
                    `(${props.itemCount})` :
                    <>
                        {'('}
                        <ThreeDot color="#ffffff" style={{ fontSize: 3, marginLeft: 2, marginRight: 2 }} />
                        {')'}
                    </>
                }
            </Typography>}
        />
    );
};

function NowcastMenu(props: NowcastMenuProps) {
    const [menuCategory, setMenuCategory] = React.useState('top-menu');
    const [expandedItemIds, setExpandedItemIds] = React.useState<string[]>([]);

    const mapLayerOptions: JSX.Element = (
        <div>
            <div style={{ padding: '10px, 0px' }}>
                <div style={{ width: '100%', display: 'flex', marginRight: 20 }}>
                    <FormControl>
                        <SimpleTreeView expandedItems={expandedItemIds} onExpandedItemsChange={(event, itemIds) => setExpandedItemIds(itemIds.filter(id => !expandedItemIds.includes(id)))}>
                            <TreeItem itemId="1" label={<Typography variant="caption" fontWeight={500} sx={{ fontSize: 14 }}>Map Type</Typography>}>
                                <RadioGroup
                                    value={props.tilingLayer === undefined ? 'none' : props.tilingLayer}
                                    onChange={(event, layer) => props.setTilingLayer(layer === 'none' ? undefined : layer)}
                                >
                                    {['none', 'radar', 'temperature', 'rain_accumulation', 'snow_accumulation', 'wind_speed', 'wind_gust', 'road', 'disruption', 'flood', 'power', 'life_property', 'wildfire_spread'].map((value) => (
                                        <FormControlLabel
                                            key={value}
                                            value={value}
                                            control={<Radio size="small" />}
                                            label={<Typography variant="caption">{value === 'none' ? 'No Risk Layers' : value.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}</Typography>}
                                            sx={{ my: -0.5 }}
                                        />
                                    ))}
                                </RadioGroup>
                            </TreeItem>
                            <TreeItem itemId="2" label={
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography variant="caption" fontWeight={500} sx={{ fontSize: 14 }} style={{ marginRight: 8 }}>
                                        Event Layers
                                    </Typography>
                                    {/* {(true || Object.values(props.itemCounts).some(c => c === undefined)) && <ThreeDot color="rgb(144, 202, 249)" style={{ fontSize: 6 }} />} */}
                                </div>
                            }>
                                <FormGroup>
                                    <EventLink
                                        label="Hurricanes"
                                        isChecked={props.isCyclonesVisible}
                                        onToggled={props.onCyclonesToggled}
                                        itemCount={props.itemCounts['cyclones']}
                                    />
                                    <EventLink
                                        label="Earthquakes"
                                        isChecked={props.isEarthquakesVisible}
                                        onToggled={props.onEarthquakesToggled}
                                        itemCount={props.itemCounts['earthquakes']}
                                    />
                                    <EventLink
                                        label="Lightning"
                                        isChecked={props.isLightningVisible}
                                        onToggled={props.onLightningToggled}
                                        itemCount={props.itemCounts['lightning']}
                                    />
                                    <EventLink
                                        label="Power Outages"
                                        isChecked={props.isPowerOutagesVisible}
                                        onToggled={props.onPowerOutagesToggled}
                                        itemCount={props.itemCounts['powerOutages']}
                                    />
                                    <EventLink
                                        label="Weather Events"
                                        isChecked={props.isStormsVisible}
                                        onToggled={props.onStormsToggled}
                                        itemCount={props.itemCounts['storms']}
                                    />
                                    <EventLink
                                        label="Storm Reports"
                                        isChecked={props.isStormReportsVisible}
                                        onToggled={props.onStormReportsToggled}
                                        itemCount={props.itemCounts['stormReports']}
                                    />
                                    <EventLink
                                        label="Volcanoes"
                                        isChecked={props.isVolcanoesVisible}
                                        onToggled={props.onVolcanoesToggled}
                                        itemCount={props.itemCounts['volcanoes']}
                                    />
                                    <EventLink
                                        label="Fires"
                                        isChecked={props.isFiresVisible}
                                        onToggled={props.onFiresToggled}
                                        itemCount={props.itemCounts['fires']}
                                    />
                                    <EventLink
                                        label="US Wildfires"
                                        isChecked={props.isWildfiresVisible}
                                        onToggled={props.onWildfiresToggled}
                                        itemCount={props.itemCounts['wildfires']}
                                    />
                                </FormGroup>
                            </TreeItem>

                            <TreeItem itemId="3" label={<Typography variant="caption" fontWeight={500} sx={{ fontSize: 14 }}>Traffic Layers</Typography>}>
                                <RadioGroup
                                    value={props.trafficLayer === undefined ? 'none' : props.trafficLayer}
                                    onChange={(event, layer) => props.onTrafficLayerSelected(layer === 'none' ? undefined : layer.startsWith('traffic') ? layer : `traffic-${layer}`)}
                                >
                                    {['none', 'traffic', 'traffic-incidents', 'road-status', 'road-work', 'road-closures', 'truck-warnings'].map((value) => (
                                        <FormControlLabel
                                            key={value}
                                            value={value}
                                            control={<Radio size="small" />}
                                            label={<Typography variant="caption">{value === 'none' ? 'No Traffic Layers' : value.replace(/-/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}</Typography>}
                                            sx={{ my: -0.5 }}
                                        />
                                    ))}
                                </RadioGroup>
                            </TreeItem>

                            <TreeItem itemId="4" label={<Typography variant="caption" fontWeight={500} sx={{ fontSize: 14 }}>Gov Layers</Typography>}>
                                <FormGroup>
                                    <FormControlLabel className={'checkbox'} control={<Checkbox sx={{ my: -0.5 }} size="small" checked={props.isAlertsVisible} onChange={event => props.onAlertsToggled(event.target.checked)} />} label={<Typography variant="caption">NWS Alerts</Typography>} />
                                </FormGroup>
                            </TreeItem>
                        </SimpleTreeView>
                    </FormControl>
                </div>
            </div>
        </div>
    );

    const closeButton = (
        <IconButton
            color={'primary'}
            size={'small'}
            onClick={() => setMenuCategory('top-menu')}
        >
            <Close fontSize='small' fontWeight={'bold'} />
        </IconButton>
    );

    return (
        <div className={`NowcastMenu-Container ${props.isGraphExpanded ? 'graph-expanded' : ''}`}>
            <Paper className={"NowcastMenu-Submenu"} elevation={3} style={{
                backgroundColor: cardBackgroundColor,
                backgroundImage: 'none',
                borderRadius: 12,
                padding: '8px 20px',
            }}>
                <MenuCategoryButtons category={menuCategory} onCategorySelected={category => setMenuCategory(category)} />
            </Paper>

            {menuCategory === 'risk-layers' && <Paper className={"NowcastMenu-Submenu"} elevation={3} style={{
                backgroundColor: cardBackgroundColor,
                backgroundImage: 'none',
                borderRadius: 12,
                paddingLeft: 20,
                paddingTop: 8,
                paddingRight: 20,
                width: 200,
            }}>
                <header style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Typography variant={'subtitle1'} fontWeight={'bold'} color={'textPrimary'}>{menuHeadings.mapLayers}</Typography>
                    <Box flexGrow={1} />
                    {closeButton}
                </header>
                <Box height={10} />
                <div className="content"> {/* content is scrollable */}
                    {mapLayerOptions}
                    <Box height={20} />
                </div>
            </Paper>}

            {menuCategory === 'asset-types' && <Paper className={"NowcastMenu-Submenu"} elevation={3} style={{
                backgroundColor: cardBackgroundColor,
                backgroundImage: 'none',
                borderRadius: 12,
                padding: '8px 20px',
                width: 230,
            }}>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Typography variant={'subtitle1'} fontWeight={'bold'} color={'textPrimary'}>{menuHeadings.assetTypes}</Typography>
                    {closeButton}
                </Box>

                <Box display="flex" alignItems="center" justifyContent="space-between" style={{ marginTop: 12 }}>
                    <Typography variant={'body2'} fontWeight={'bold'} color={'textPrimary'} style={{ marginRight: 8 }}>Locations {props.isLocationsLoading && <CircularProgress size={14} />}</Typography>
                    <Box flexGrow={1} />
                </Box>

                <Box display="flex" alignItems="center" justifyContent="space-between" style={{ marginTop: 12 }}>
                    <Typography variant={'caption'} color={'textSecondary'} style={{ marginRight: 8 }}>Select:</Typography>
                    <Button color="primary" size="small" sx={{ minWidth: 0, px: 0.5 }} onClick={() => props.onLocationSelectedTypesChanged(props.locationAssetTypeOptions)}>ALL</Button>
                    <Button color="primary" size="small" sx={{ minWidth: 0, px: 0.5 }} onClick={() => props.onLocationSelectedTypesChanged([])}>NONE</Button>
                    <Box flexGrow={1} />
                </Box>

                <FormControl size="small" className="content">
                    {props.locationAssetTypeOptions.map(assetType => (
                        <FormControlLabel
                            key={assetType.label}
                            value={assetType.category}
                            control={<Checkbox size="small" checked={props.locationSelectedAssetTypes.find(at => at.category === assetType.category) !== undefined} />}
                            label={
                                <Typography variant="caption">
                                    {assetType.label}
                                    {props.locationCountsByAssetCategory[assetType.category || ''] !== undefined ? ` (${props.locationCountsByAssetCategory[assetType.category || '']})` : ''}
                                </Typography>
                            }
                            sx={{ my: -0.5 }} // less vertical spacing between checkboxes
                            onChange={(event, checked) => {
                                if (checked) {
                                    props.onLocationSelectedTypesChanged([...props.locationSelectedAssetTypes, assetType]);
                                } else {
                                    props.onLocationSelectedTypesChanged(props.locationSelectedAssetTypes.filter(t => t !== assetType));
                                }
                            }}
                        />
                    ))}
                </FormControl>

                <Box display="flex" alignItems="center" justifyContent="space-between" style={{ marginTop: 12 }}>
                    <Typography variant={'body2'} fontWeight={'bold'} color={'textPrimary'} style={{ marginRight: 8 }}>Vehicles {props.isVehiclesLoading && <CircularProgress size={14} />}</Typography>
                    <Box flexGrow={1} />
                </Box>

                <Box display="flex" alignItems="center" justifyContent="space-between" style={{ marginTop: 12 }}>
                    <Typography variant={'caption'} color={'textSecondary'} style={{ marginRight: 8 }}>Select:</Typography>
                    <Button color="primary" size="small" sx={{ minWidth: 0, px: 0.5 }} onClick={() => props.onVehicleSelectedTypesChanged(props.vehicleAssetTypeOptions)}>ALL</Button>
                    <Button color="primary" size="small" sx={{ minWidth: 0, px: 0.5 }} onClick={() => props.onVehicleSelectedTypesChanged([])}>NONE</Button>
                    <Box flexGrow={1} />
                </Box>


                <FormControl size="small" className="content">
                    {props.vehicleAssetTypeOptions.map(assetType => (
                        <FormControlLabel
                            key={assetType.label}
                            value={assetType.category}
                            control={<Checkbox size="small" checked={props.vehicleSelectedAssetTypes.find(at => at.category === assetType.category) !== undefined} />}
                            label={
                                <Typography variant="caption">
                                    {assetType.label}
                                    {props.vehicleCountsByAssetCategory[assetType.category || ''] !== undefined ? ` (${props.vehicleCountsByAssetCategory[assetType.category || '']})` : ''}
                                </Typography>
                            }
                            sx={{ my: -0.5 }} // less vertical spacing between checkboxes
                            onChange={(event, checked) => {
                                if (checked) {
                                    props.onVehicleSelectedTypesChanged([...props.vehicleSelectedAssetTypes, assetType]);
                                } else {
                                    props.onVehicleSelectedTypesChanged(props.vehicleSelectedAssetTypes.filter(t => t !== assetType));
                                }
                            }}
                        />
                    ))}
                </FormControl>
            </Paper>}

            {menuCategory === 'filters' && <Paper className={"NowcastMenu-Submenu"} elevation={3} style={{
                backgroundColor: cardBackgroundColor,
                backgroundImage: 'none',
                borderRadius: 12,
                padding: '8px 20px',
                width: 200,
            }}>
                <header style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Typography variant={'subtitle1'} fontWeight={'bold'} color={'textPrimary'}>{menuHeadings.filters}</Typography>
                    <Box flexGrow={1} />
                    {closeButton}
                </header>
                <Box height={10} />
                <div className="content"> {/* content is scrollable */}
                    <SingleFilterSelector
                        filter={props.filters[0] || { variable: null, operator: null, value: null }}
                        assetAlerts={props.assetAlerts}
                        onFilterChanged={filter => props.onFiltersChanged([filter])}
                    />
                </div>
            </Paper>}
        </div >
    );
}

export default NowcastMenu;