import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, LinearProgress, TextField, TextFieldProps } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import moment, { Moment } from "moment";
import React, { Fragment } from "react";
import { RouteData } from "src/types/routes";

interface Props {
    routeToEdit: RouteData | undefined;
    editRouteResponse: RouteData | Error[] | undefined;
    setRouteToEdit: (route: RouteData | undefined) => void;
    onEditedRouteSaved: (route: RouteData) => void;
    onEditedRouteFinished: () => void;
}

export const EditRouteDialog = ({ routeToEdit, editRouteResponse, setRouteToEdit, onEditedRouteSaved, onEditedRouteFinished }: Props) => {
    const [isSavingEdit, setSavingEdit] = React.useState(false);
    const [editErrors, setEditErrors] = React.useState<string[]>([]);

    const [originLatitude, setOriginLatitude] = React.useState('');
    const [originLongitude, setOriginLongitude] = React.useState('');
    const [destinationLatitude, setDestinationLatitude] = React.useState('');
    const [destinationLongitude, setDestinationLongitude] = React.useState('');
    const [departureTimeMoment, setDepartureTimeMoment] = React.useState<Moment | null>(null);

    const prevRouteToEditRef = React.useRef<RouteData | undefined>();

    React.useEffect(() => {
        if (editRouteResponse === undefined) {
            return;
        }
        setSavingEdit(false);
        if (Array.isArray(editRouteResponse)) {
            setEditErrors(editRouteResponse.map(e => e.message));
        } else {
            onEditedRouteFinished();
            setRouteToEdit(undefined);
        }
    }, [editRouteResponse]);

    React.useEffect(() => {
        if (routeToEdit?.id === prevRouteToEditRef.current?.id) {
            return;
        }
        setOriginLatitude(routeToEdit?.origin.latitude.toString() || '');
        setOriginLongitude(routeToEdit?.origin.longitude.toString() || '');
        setDestinationLatitude(routeToEdit?.destination.latitude.toString() || '');
        setDestinationLongitude(routeToEdit?.destination.longitude.toString() || '');
        setDepartureTimeMoment(routeToEdit?.departureTime ? moment(routeToEdit.departureTime) : null);
    }, [routeToEdit]);


    React.useEffect(() => {
        let date = departureTimeMoment?.toDate();
        if (!date || isNaN(date.getTime())) {
            date = undefined;
        }
        if (!date || !routeToEdit) {
            return;
        }
        const updatedRoute = structuredClone(routeToEdit);
        updatedRoute.departureTime = date;
        setRouteToEdit(updatedRoute);
    }, [departureTimeMoment]);

    React.useEffect(() => {
        prevRouteToEditRef.current = routeToEdit;
    });

    if (routeToEdit === undefined) {
        return <div></div>;
    }

    const handleCancel = () => {
        setSavingEdit(false);
        setEditErrors([]);
        setRouteToEdit(undefined);
    };
    const handleSave = () => {
        const updatedRoute = structuredClone(routeToEdit);
        if (originLatitude) {
            updatedRoute.origin.latitude = parseFloat(originLatitude);
        }
        if (originLongitude) {
            updatedRoute.origin.longitude = parseFloat(originLongitude);
        }
        if (destinationLatitude) {
            updatedRoute.destination.latitude = parseFloat(destinationLatitude);
        }
        if (destinationLongitude) {
            updatedRoute.destination.longitude = parseFloat(destinationLongitude);
        }

        setRouteToEdit(updatedRoute);
        setSavingEdit(true);
        setEditErrors([]);
        onEditedRouteSaved(updatedRoute);
    };

    const sharedTextFieldProps: Partial<TextFieldProps> = {
        // autoFocus: true,
        margin: "dense",
        fullWidth: true,
        variant: "standard",
    };

    return (
        <Dialog open={true} onClose={handleCancel}>
            <DialogTitle>Edit Route</DialogTitle>

            <DialogContent>
                {editErrors.length > 0 && <DialogContentText>
                    There was an error saving your edits:
                    <br />
                    {editErrors.map(err => (
                        <Fragment>
                            <br />
                            • {err}
                        </Fragment>
                    ))}
                </DialogContentText>}
                <TextField
                    {...sharedTextFieldProps}
                    id="external_id"
                    label="External ID"
                    type="text"
                    autoFocus
                    value={routeToEdit.externalId}
                    onChange={(event) => {
                        const updatedRoute = {
                            ...routeToEdit,
                            externalId: event.target.value as string,
                        };
                        setRouteToEdit(updatedRoute);
                    }}
                />
                <TextField
                    {...sharedTextFieldProps}
                    id="origin_label"
                    label="Origin Label"
                    type="text"
                    value={routeToEdit.origin.label}
                    onChange={(event) => {
                        const updatedRoute = structuredClone(routeToEdit);
                        updatedRoute.origin.label = event.target.value as string;
                        setRouteToEdit(updatedRoute);
                    }}
                />
                <TextField
                    {...sharedTextFieldProps}
                    id="origin_latitude"
                    label="Origin Latitude"
                    type="number"
                    value={originLatitude ?? ""}
                    onChange={(event) => {
                        setOriginLatitude(event.target.value as string);
                    }}
                />
                <TextField
                    {...sharedTextFieldProps}
                    id="origin_longitude"
                    label="Origin Longitude"
                    type="number"
                    value={originLongitude ?? ""}
                    onChange={(event) => {
                        setOriginLongitude(event.target.value as string);
                    }}
                />
                <TextField
                    {...sharedTextFieldProps}
                    id="destination_label"
                    label="Destination Label"
                    type="text"
                    value={routeToEdit.destination.label}
                    onChange={(event) => {
                        const updatedRoute = structuredClone(routeToEdit);
                        updatedRoute.destination.label = event.target.value as string;
                        setRouteToEdit(updatedRoute);
                    }}
                />
                <TextField
                    {...sharedTextFieldProps}
                    id="destination_latitude"
                    label="Destination Latitude"
                    type="number"
                    value={destinationLatitude ?? ""}
                    onChange={(event) => {
                        setDestinationLatitude(event.target.value as string);
                    }}
                />
                <TextField
                    {...sharedTextFieldProps}
                    id="destination_longitude"
                    label="Destination Longitude"
                    type="number"
                    value={destinationLongitude ?? ""}
                    onChange={(event) => {
                        setDestinationLongitude(event.target.value as string);
                    }}
                />
                <DateTimePicker
                    label="Departure Time"
                    value={departureTimeMoment}
                    onChange={(value: Moment | null) => {
                        setDepartureTimeMoment(value);
                    }}
                    // we support up to 24 hours back
                    minDateTime={moment().subtract(24, 'hour')}
                    maxDateTime={moment().add(1, 'week')}
                    slotProps={{ textField: { variant: 'standard', fullWidth: true } }}
                />
            </DialogContent>
            {!isSavingEdit && <DialogActions>
                <Button onClick={handleCancel}>Cancel</Button>
                <Button onClick={handleSave}>Save</Button>
            </DialogActions>}
            {isSavingEdit && <LinearProgress />}
        </Dialog>
    );
};
