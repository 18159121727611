import * as React from "react";

export const RoadRiskLegend = () => {
    return (
        <div style={{display: 'flex', flexDirection: 'column', padding: '10px'}}>
            <div
                key={"heading"}
                className={"road-risk-legend-rating"}
                style={{color: 'white', paddingBottom: '5px'}}
            >
                Road Risk
            </div>

            <div
                key={`road-risk-legend-rating-low`}
                className={`road-risk-legend-rating`}
            >
                <div style={{padding: '2px 4px 2px 4px', backgroundColor: '#58D05D', color: 'white', width: '15px'}}/>
                <div style={{paddingLeft: '5px', color: 'white'}}>Low</div>
            </div>

            <div
                key={`road-risk-legend-rating-med`}
                className={`road-risk-legend-rating`}
            >
                <div style={{padding: '2px 4px 2px 4px', backgroundColor: '#FD8C44', color: 'white', width: '15px'}}/>
                <div style={{paddingLeft: '5px', color: 'white'}}>Med</div>
            </div>

            <div
                key={`road-risk-legend-rating-high`}
                className={`road-risk-legend-rating`}
            >
                <div style={{padding: '2px 4px 2px 4px', backgroundColor: '#F1352C', color: 'white', width: '15px'}}/>
                <div style={{paddingLeft: '5px', color: 'white'}}>High</div>
            </div>
        </div>
    );
};