import {StoreState} from '../../types';
import { connect } from 'react-redux';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {Action} from 'redux';
import {ThunkDispatch} from 'redux-thunk';
import {PricingCalculatorEmbed} from '../../components/Public/PricingCalculatorEmbed';

export function mapStateToProps(_state: StoreState, _ownProps: RouteComponentProps<any>) {
    return {
    };
}

export function mapDispatchToProps(_dispatch: ThunkDispatch<StoreState, void, Action<any>>) {
    return {
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PricingCalculatorEmbed));
