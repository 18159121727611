import {BasicLatLng, StoreState} from '../../types';
import {connect} from 'react-redux';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import { ClientHistoricalAccuracyPage } from 'src/components/Client/HistoricalAccuracy';
import {Action} from 'redux';
import {ThunkDispatch} from 'redux-thunk';
import { MapCenterChanged, MapZoomLevelChanged } from 'src/actions/MapView';


export function mapStateToProps(state: StoreState, _ownProps: RouteComponentProps<any>) {
    return {
        blurbs: state.ratings.blurbs,
        userData: state.user,
        center: state.mapView.center,
    };
}

export function mapDispatchToProps(dispatch: ThunkDispatch<StoreState, void, Action<any>>) {
    return {
        onCenterChanged: (center: BasicLatLng) => {
            dispatch(MapCenterChanged({center}));
        },
        onZoomLevelChanged: (zoomLevel: number) => {
            dispatch(MapZoomLevelChanged({zoomLevel}));
        },
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ClientHistoricalAccuracyPage));
