import { ImpactMapSection } from '../components/Client/Impact/sections';
import { MapType } from '../types/MapType';
import { action, props, union } from 'ts-action';
import { CycloneData, EarthquakeData, VolcanoData, FireData, WildfireData, StormData, LightningData, ImpactTileset, PowerOutageData, StormReportData, WeatherStationData } from 'src/types';

export const ImpactSetPaused = action('IMPACT_SET_PAUSED', props<{ paused: boolean }>());

export const ImpactSetTileOpacity = action('IMPACT_SET_TILE_OPACITY', props<{ tileOpacity: number }>());

export const ImpactSetCurrentTileset = action('IMPACT_SET_CURRENT_TILESET', props<{ tileset: ImpactTileset }>());

export const ImpactSetCyclonesVisible = action('IMPACT_SET_CYCLONES_VISIBLE', props<{ visible: boolean }>());

export const ImpactSetEarthquakesVisible = action('IMPACT_SET_EARTHQUAKES_VISIBLE', props<{ visible: boolean }>());

export const ImpactSetLightningVisible = action('IMPACT_SET_LIGHTNING_VISIBLE', props<{ visible: boolean }>());

export const ImpactSetPowerOutagesVisible = action('IMPACT_SET_POWER_OUTAGES_VISIBLE', props<{ visible: boolean }>());

export const ImpactSetStormsVisible = action('IMPACT_SET_STORMS_VISIBLE', props<{ visible: boolean }>());

export const ImpactSetStormReportsVisible = action('IMPACT_SET_STORM_REPORTS_VISIBLE', props<{ visible: boolean }>());

export const ImpactSetVolcanoesVisible = action('IMPACT_SET_VOLCANOES_VISIBLE', props<{ visible: boolean }>());

export const ImpactSetFiresVisible = action('IMPACT_SET_FIRES_VISIBLE', props<{ visible: boolean }>());

export const ImpactSetWildfiresVisible = action('IMPACT_SET_WILDFIRES_VISIBLE', props<{ visible: boolean }>());

export const ImpactSetMapOptionsCollapsed = action('IMPACT_SET_MAP_OPTIONS_COLLAPSED', props<{ collapsed: boolean }>());

export const ImpactSetSelectedMap = action('IMPACT_SET_SELECTED_MAP', props<{
    selectedMapCategory: ImpactMapSection;
    selectedMapType: MapType;
}>());

export const ImpactSetAnimationSpeed = action('IMPACT_SET_ANIMATION_SPEED', props<{
    selectedSpeedMultiplierIndex: number;
    animationDelay: number;
}>());

export const SelectCyclone = action('SELECT_CYCLONE', props<{ cyclone: CycloneData }>());

export const SelectEarthquake = action('SELECT_EARTHQUAKE', props<{ earthquake: EarthquakeData }>());

export const SelectLightning = action('SELECT_LIGHTNING', props<{ lightning: LightningData }>());

export const SelectPowerOutage = action('SELECT_POWER_OUTAGE', props<{ powerOutage: PowerOutageData }>());

export const SelectStorm = action('SELECT_STORM', props<{ storm: StormData }>());

export const SelectStormReport = action('SELECT_STORM_REPORT', props<{ stormReport: StormReportData }>());

export const SelectVolcano = action('SELECT_VOLCANO', props<{ volcano: VolcanoData }>());

export const SelectFire = action('SELECT_FIRE', props<{ fire: FireData }>());

export const SelectWildfire = action('SELECT_WILDFIRE', props<{ wildfire: WildfireData }>());

export const SelectWeatherStation = action('SELECT_WEATHER_STATION', props<{ weatherStation?: WeatherStationData }>());

export const ClearSelectedEvent = action('CLEAR_SELECTED_EVENT');

export const ImpactViewAction = union(ImpactSetSelectedMap, ImpactSetAnimationSpeed, ImpactSetPaused,
    ImpactSetCyclonesVisible, ImpactSetEarthquakesVisible, ImpactSetLightningVisible, ImpactSetPowerOutagesVisible, ImpactSetStormsVisible, ImpactSetStormReportsVisible, ImpactSetVolcanoesVisible, ImpactSetFiresVisible, ImpactSetWildfiresVisible,
    ImpactSetMapOptionsCollapsed, ImpactSetTileOpacity, ImpactSetCurrentTileset,
    SelectCyclone, SelectEarthquake, SelectLightning, SelectPowerOutage, SelectStorm, SelectStormReport, SelectVolcano, SelectFire, SelectWildfire, ClearSelectedEvent);
