import { BasicLatLng, CycloneData, EarthquakeData, LightningData, LocationData, StoreState, StormData, VolcanoData, FireData, WildfireData, ImpactTileset } from '../../types';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ClientImpactPage } from '../../components/Client/Impact';
import { ClearSaveCityError, createCity, deleteCity, loadCity, SelectCity } from '../../actions/SelectedCity';
import { loadEventsIfNeeded, loadWeatherIfNeeded } from '../../actions/Weather';
import { geocodeCity } from '../../actions/SelectedCity';
import {
    loadRatingsIfNeeded
} from '../../actions/Ratings';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { MapType } from '../../types/MapType';
import { ImpactMapSection } from '../../components/Client/Impact/sections';
import {
    ImpactSetAnimationSpeed,
    ImpactSetCyclonesVisible,
    ImpactSetEarthquakesVisible,
    ImpactSetLightningVisible,
    ImpactSetMapOptionsCollapsed,
    ImpactSetPaused,
    ImpactSetSelectedMap,
    ImpactSetStormsVisible,
    ImpactSetVolcanoesVisible,
    ImpactSetFiresVisible,
    ImpactSetWildfiresVisible,
    SelectCyclone,
    SelectEarthquake,
    SelectLightning,
    SelectStorm,
    SelectVolcano,
    SelectFire,
    SelectWildfire,
    ImpactSetTileOpacity,
    ImpactSetCurrentTileset,
    ClearSelectedEvent
} from '../../actions/ImpactView';
import { MapCenterChanged, MapZoomLevelChanged } from "../../actions/MapView";
import { OpenedPage } from '../../actions/User';

export function mapStateToProps(state: StoreState, _ownProps: RouteComponentProps<any>) {
    return {
        ...state.impactView,
        center: state.mapView.center,
        zoomLevel: state.mapView.zoomLevel,
        blurbs: state.ratings.blurbs,
        weatherData: state.weather.weatherData,
        cyclones: state.weather.cyclones,
        earthquakes: state.weather.earthquakes,
        lightning: state.weather.lightning,
        storms: state.weather.storms,
        volcanoes: state.weather.volcanoes,
        fires: state.weather.fires,
        wildfires: state.weather.wildfires,
        userData: state.user,
        selectedCityState: state.selectedCity,
        ratingsData: state.ratings.ratingsData,
        ratingsFetchError: state.ratings.ratingsFetchError,
        isFetching: state.ratings.isFetching,
        impactTilesets: state.ratings.impactTilesets?.value,
        impactRunTimes: state.ratings.impactTilesets?.value?.runTimes,
        impactTilesetsCreatedAt: state.ratings.impactTilesets.value?.createdAt,
        weatherTilesets: state.ratings.weatherTilesets.value,
        weatherRunTimes: state.ratings.weatherTilesets.value?.runTimes,
        weatherTilesetsCreatedAt: state.ratings.weatherTilesets.value?.createdAt,
        speedIncrements: [1, 2, 3, 0.5],
        baseAnimationDelay: 1000,
        showDisruptionIndex: state.user.showDisruptionIndex,
        showWildfireIndices: state.user.showWildfireIndices
    };
}

export function mapDispatchToProps(dispatch: ThunkDispatch<StoreState, void, Action<any>>) {
    return {
        onCitySelected: (city: LocationData | undefined) => {
            dispatch(SelectCity({ city }));
            if (city) {
                if (city.needsGeocoding) {
                    dispatch(geocodeCity(city));
                } else {
                    dispatch(loadWeatherIfNeeded());
                    dispatch(loadRatingsIfNeeded());
                }
            }
        },
        onCitySaved: (city: LocationData) => {
            dispatch(createCity(city));
        },
        onCityUnsaved: (city: LocationData) => {
            dispatch(deleteCity(city));
        },
        onCitySaveErrorDismissed: (city: LocationData) => {
            dispatch(ClearSaveCityError({ city }));
        },
        onCycloneSelected: (cyclone: CycloneData) => {
            dispatch(SelectCyclone({ cyclone }));
        },
        onEarthquakeSelected: (earthquake: EarthquakeData) => {
            dispatch(SelectEarthquake({ earthquake }));
        },
        onLightningSelected: (lightning: LightningData) => {
            dispatch(SelectLightning({ lightning }));
        },
        onStormSelected: (storm: StormData) => {
            dispatch(SelectStorm({ storm }));
        },
        onVolcanoSelected: (volcano: VolcanoData) => {
            dispatch(SelectVolcano({ volcano }));
        },
        onFireSelected: (fire: FireData) => {
            dispatch(SelectFire({ fire }));
        },
        onWildfireSelected: (wildfire: WildfireData) => {
            dispatch(SelectWildfire({ wildfire }));
        },
        onClearSelectedEvent: () => {
            dispatch(ClearSelectedEvent());
        },

        onCenterChanged: (center: BasicLatLng) => {
            dispatch(MapCenterChanged({ center }));
        },
        onZoomLevelChanged: (zoomLevel: number) => {
            dispatch(MapZoomLevelChanged({ zoomLevel }));
        },
        onSetTileOpacity: (tileOpacity: number) => {
            dispatch(ImpactSetTileOpacity({ tileOpacity }));
        },
        onSetCurrentTileset: (tileset: ImpactTileset) => {
            dispatch(ImpactSetCurrentTileset({ tileset }));
        },
        onPauseToggled: (paused: boolean) => {
            dispatch(ImpactSetPaused({ paused }));
        },
        onLoadEvents: () => {
            dispatch(loadEventsIfNeeded());
        },
        onCyclonesToggled: (visible: boolean) => {
            dispatch(ImpactSetCyclonesVisible({ visible }));
        },
        onEarthquakesToggled: (visible: boolean) => {
            dispatch(ImpactSetEarthquakesVisible({ visible }));
        },
        onLightningToggled: (visible: boolean) => {
            dispatch(ImpactSetLightningVisible({ visible }));
        },
        onStormsToggled: (visible: boolean) => {
            dispatch(ImpactSetStormsVisible({ visible }));
        },
        onVolcanoesToggled: (visible: boolean) => {
            dispatch(ImpactSetVolcanoesVisible({ visible }));
        },
        onFiresToggled: (visible: boolean) => {
            dispatch(ImpactSetFiresVisible({ visible }));
        },
        onWildfiresToggled: (visible: boolean) => {
            dispatch(ImpactSetWildfiresVisible({ visible }));
        },
        onMapOptionsToggled: (collapsed: boolean) => {
            dispatch(ImpactSetMapOptionsCollapsed({ collapsed }));
        },
        onAnimationSpeedChanged: (selectedSpeedMultiplierIndex: number, animationDelay: number) => {
            dispatch(ImpactSetAnimationSpeed({ selectedSpeedMultiplierIndex, animationDelay }));
        },
        onMapSelected: (selectedMapCategory: ImpactMapSection, selectedMapType: MapType) => {
            dispatch(ImpactSetSelectedMap({ selectedMapCategory, selectedMapType }));
            dispatch(loadWeatherIfNeeded());
            dispatch(loadRatingsIfNeeded());
        },
        openedPage: (page: string) => {
            dispatch(OpenedPage({ page }));
        },
        loadLocation: (locationId: string) => dispatch(loadCity(locationId)),
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ClientImpactPage));
