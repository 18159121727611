import { LocationData, HourRatingsData, WeatherData, SelectedCityState, locationsHaveEqualCenters, isValidUserForFeature, ClientId } from '../../../types';
import { ImpactMapType } from '../../../types/MapType';
import { RatingKey, isImpactKey } from '../../../types/RatingKey';
import * as React from 'react';
import { AutoShowErrorDialog } from "../../shared/AutoShowErrorDialog";
import { cardBackgroundColor } from "../../../constants";
import { Route } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';

interface ImpactCalloutViewProps {
    userId?: number;
    selectedCityState: SelectedCityState;
    weatherData?: WeatherData;
    selectedImpactMap?: ImpactMapType;
    paused: boolean;
    ratingsFetchError?: Error;
    isFetching: boolean;

    lat: number;
    lng: number;

    indexName?: string;
    indexKey?: RatingKey | undefined;
    hour?: HourRatingsData;

    onClose: () => void;
    saveLocationTapped: (location: LocationData) => void;
    unsaveLocationTapped: (location: LocationData) => void;
    onCitySaveErrorDismissed: (location: LocationData) => void;
}

export const ImpactCalloutView = (props: ImpactCalloutViewProps) => {
    const maxRating = 10;

    const { selectedCityState } = props;
    const { selectedCity } = selectedCityState;

    const history = useHistory();

    if (selectedCity === undefined) {
        return <div />;
    }

    let indexValue = "";

    if (props.hour !== undefined && props.indexKey !== undefined && props.isFetching === false) {
        const ratingValue = props.hour.value;

        if (ratingValue === null || ratingValue === undefined) {
            indexValue = "N/A";
        } else {
            if (isImpactKey(props.indexKey)) {
                indexValue = `${ratingValue.toString()} out of ${maxRating}`;
            } else {
                if (props.indexKey.indexOf('accumulation') !== -1) {
                    indexValue = `${ratingValue} in`;
                } else if (props.indexKey.indexOf('wind') !== -1) {
                    indexValue = `${ratingValue} mph`;
                } else {
                    indexValue = `${ratingValue}°`;
                }
            }

        }
    }

    let locationButton: JSX.Element;
    let isCitySaved = selectedCity.id !== undefined;
    if (isCitySaved) {
        locationButton = (
            <div className={"location-button"} onClick={() => props.unsaveLocationTapped(selectedCity)}>
                <img src={"/images/saved_check.svg"} alt={"✓"} />
                <span>Saved</span>
            </div>
        );
    } else {
        locationButton = (
            <div className={"location-button"} onClick={() => props.saveLocationTapped(selectedCity)}>
                <span>Save Location</span>
            </div>
        );
    }
    const routeButtonParams = selectedCity.id ? `origin_location_id=${selectedCity.id}` : `origin_latitude=${selectedCity.latitude}&origin_longitude=${selectedCity.longitude}`;
    let routeButton = (
        <div className={"location-button"} onClick={() => history.push(`/client/routes?${routeButtonParams}`)}>
            <Route style={{ color: '#04A0FD', 'marginRight': '5px' }} />
            <span>Create Route</span>
        </div>
    );

    const isSavingThisCity = selectedCityState.cityBeingSaved !== undefined &&
        locationsHaveEqualCenters(selectedCityState.cityBeingSaved, selectedCity) &&
        selectedCityState.citySavingError === undefined;

    const isLoading = selectedCityState.isGeocoding || selectedCity.needsGeocoding || isSavingThisCity;

    let extraClassNames = "";
    if (isLoading) {
        extraClassNames = " loading";
    } else if (!props.paused) {
        extraClassNames = " animated";
    }
    let zip = selectedCity.zip;
    if (zip === undefined || zip === '00000') {
        // don't display non-geocoded zips
        zip = '';
    }

    let errorMessage: string | undefined;
    if (props.ratingsFetchError !== undefined) {
        if (props.ratingsFetchError.message === "location outside model bounds") {
            errorMessage = "Outside model range";
        } else {
            errorMessage = "Unknown error - please try again later";
        }
    }

    return (
        <div className={`MapCalloutView${extraClassNames}`} onClick={(event) => event.stopPropagation()}>
            <div className={"container"} style={{ cursor: "default" }}>
                <div className={"body"} style={{ backgroundColor: cardBackgroundColor }}>
                    <button className={"close-button"} onClick={() => props.onClose()}>
                        <img alt={'Close'} src={"/images/popover_x.svg"} />
                    </button>

                    <div className={"loading-view"}>
                        <img src={"/images/loading.gif"} alt={"Loading..."} />
                    </div>

                    <div className={"zip"}>{zip}</div>
                    <div className={"city-name"}>{selectedCity.name || 'Unknown Location'}</div>
                    <div className={"index-name"}>{props.indexName}</div>
                    <div className={"index-value"}>
                        {indexValue}
                        {/*
                            added a check if indexValue is an empty string to show error because the
                            wildfire API call uses ratingsFetchError as well so we can have an error
                            for wildfire even though the impact rating (e.g. road) loaded fine
                        */}
                        {indexValue === '' && errorMessage}
                        <img
                            style={{ display: indexValue === "" && props.ratingsFetchError === undefined ? 'inline-block' : 'none', marginBottom: -4 }}
                            width={24}
                            height={24}
                            src={"/images/loading.gif"}
                            alt={"Loading..."}
                        />
                    </div>
                    <div style={{ "marginTop": "10px" }}>
                        {locationButton}
                        {/**
                        * restrict Create Route button in production to only Air Force (2684)
                        */}
                        {props.userId && isValidUserForFeature(props.userId, { 'production': [ClientId.Production.AFB] }) && routeButton}
                    </div>
                </div>
                <div className={"arrow-down"} style={{ borderTop: `20px solid ${cardBackgroundColor}` }} />
            </div>
            <AutoShowErrorDialog
                title={"Error saving location"}
                error={selectedCityState.citySavingError}
                onDismiss={() => props.onCitySaveErrorDismissed(selectedCity)}
            />
        </div>
    );
};
