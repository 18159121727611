import { locationsHaveEqualCenters, SelectedCityState } from '../types';
import {
    ClearSaveCityError,
    GeocodeStart,
    ReceiveGeocodeData, ReceiveSaveCityError,
    ReceiveSaveCitySuccess,
    SelectCity,
    SelectedCityAction,
    StartSaveCity
} from '../actions/SelectedCity';

export function selectedCity(state: SelectedCityState, action: typeof SelectedCityAction.actions): SelectedCityState {
    switch (action.type) {
        case SelectCity.type:
            return { ...state, selectedCity: action.city ? { ...action.city } : undefined, isGeocoding: action.city?.needsGeocoding === true };
        case StartSaveCity.type:
            return { ...state, cityBeingSaved: action.city, citySavingError: undefined };
        case ReceiveSaveCitySuccess.type:
            return { ...state, cityBeingSaved: undefined };
        case ReceiveSaveCityError.type:
            return { ...state, cityBeingSaved: action.city, citySavingError: action.error };
        case ClearSaveCityError.type:
            return { ...state, cityBeingSaved: undefined, citySavingError: undefined };
        case GeocodeStart.type:
            return { ...state, abortController: action.abortController, isGeocoding: true };
        case ReceiveGeocodeData.type:
            if (state.selectedCity !== null && state.selectedCity !== undefined && !locationsHaveEqualCenters(state.selectedCity, action.city)) {
                // coordinates don't match
                // this is outdated geocoding info
                return state;
            }

            if (state.selectedCity === undefined) {
                // no-op if we are geocoding a null city
                return state;
            }

            let newSelectedCity = {
                ...state.selectedCity,
                name: action.city.name,
                zip: action.city.zip,
                latitude: action.city.latitude,
                longitude: action.city.longitude,
                needsGeocoding: false,
            };

            return { ...state, selectedCity: newSelectedCity, isGeocoding: false, abortController: undefined };
    }

    if (state === null || state === undefined) {
        const emptyState = {
            selectedCity: undefined,
            isGeocoding: false
        };
        return emptyState;
    }

    return state;
}
