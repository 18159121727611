import React from 'react';
import { useD3 } from '../../shared/useD3';
import * as d3 from 'd3';
import '../Dashboard/TimelineChart.css';
import { AlertData } from "src/types";
import { Timeframe } from "../Dashboard/data";
import moment from 'moment';
import { CircularProgress, IconButton, MenuItem, Paper, Select } from '@mui/material';
import { cardBackgroundColor } from 'src/constants';
import { Close } from '@mui/icons-material';
import { getContrastYIQ } from '../Impact/MapManager';

interface AlertWindow {
    id: string;
    alertName: string;
    color: string;
    from: number;
    to: number;
}

enum HoursInMilliseconds {
    One = 60 * 60 * 1000,
}

interface Props {
    selectedChartView: string;
    chartViews: any[];
    alerts: AlertData[] | undefined;
    timeframe: Timeframe;
    timezone: string | undefined;

    onCloseClicked?: () => void;
    setSelectedChartView: (chartView: string) => void;
    setSelectedAlert: (alert: AlertData) => void;

    extraStyles?: React.CSSProperties;
}

function AlertTimelineView(props: Props) {
    const { selectedChartView, chartViews, alerts, timeframe, timezone, onCloseClicked, setSelectedChartView, setSelectedAlert } = props;

    const containerIdRef = React.useRef('nowcast-alert-timeline-view');
    const containerId = containerIdRef.current;

    const alertsLength = alerts ? alerts.length : 0;
    const margin = { left: 45, top: 0, bottom: 40, right: 45 };
    const windowHeight = 30;
    const windowPadding = 10;
    const chartHeight = Math.max((alertsLength * windowHeight) + ((alertsLength - 1) * windowPadding), 150);

    // ref/effect to recompute width of graph as needed
    const containerDivRef = React.useRef<HTMLDivElement>(null);
    const [divWidth, setDivWidth] = React.useState(0);
    React.useEffect(() => {
        function handleResize() {
            if (containerDivRef.current && containerDivRef.current.clientWidth !== divWidth) {
                setDivWidth(containerDivRef.current.clientWidth);
            }
        }

        window.addEventListener('resize', () => handleResize());
    });

    let alertKeys: string[] = [];
    if (alerts) {
        alertKeys = alerts?.map(alert => alert.details.name);
    }
    const alertWindows: AlertWindow[] = [];
    alerts?.filter(alert => alert.timestamps.begins && alert.timestamps.expires).forEach(alert => {
        const startTime = Math.max(alert.timestamps.begins.getTime(), timeframe.startTime.valueOf());
        const endTime = Math.min(alert.timestamps.expires.getTime(), timeframe.endTime.valueOf());
        // don't include alert that end at the start time of rhe time frame or that start at the enttime of the time frame
        if (startTime < endTime) {
            alertWindows.push({
                id: alert.id,
                alertName: alert.details.name,
                color: alert.details.color,
                from: startTime,
                to: endTime,
            });
        }
    });

    const d3XAxisRef = useD3(
        (svg) => {
            if (!containerDivRef.current) return;
            svg.selectAll("*").remove();
            const chartWidth = containerDivRef.current.clientWidth;
            const innerWidth = chartWidth - margin.left - margin.right;

            svg.attr("width", chartWidth)
                .attr("height", chartHeight);

            const minTime = timeframe.startTime.valueOf();
            const maxTime = timeframe.endTime.valueOf();

            const xScale = d3.scaleTime()
                .domain([new Date(minTime), new Date(maxTime)])
                .range([0, innerWidth]);

            const xAxis = d3.axisBottom(xScale)
                // had to explicitly set tick values to get the axis labels to display properly (add 1 millisecond to max time to get last tick label to be added)
                .tickValues(d3.range(minTime, maxTime + 1, HoursInMilliseconds.One).map(function (d) { return new Date(d); }))
                .tickFormat(function (d: Date, i: number) {
                    let timeFormat = "ddd h:mm a"; // default to (non zero padded) hour AM/PM
                    return timezone === undefined ? moment(d).format(timeFormat) : moment(d).tz(timezone).format(timeFormat);
                });

            svg.append("g")
                .attr("transform", `translate(${margin.left}, 0)`)
                .attr("width", innerWidth)
                .attr("fill", "white")
                .call(xAxis);
        },
        [alerts, timeframe, containerDivRef.current, divWidth]
    );

    const d3AlertWindowsRef = useD3(
        (svg) => {
            if (!containerDivRef.current) return;
            if (alertWindows?.length === 0) return;

            // clear chart if resizing
            svg.selectAll("*").remove();

            // setup new chart
            const chartWidth = containerDivRef.current.clientWidth;
            // size of chart inside the axises
            const innerWidth = chartWidth - margin.left - margin.right;
            const innerHeight = chartHeight;// - margin.top;// - margin.bottom;
            const innerMiddle = innerWidth / 2;

            svg.attr("width", chartWidth)
                .attr("height", chartHeight);

            const alertChart = svg.append("g")
                .attr("transform", "translate(" + margin.left + "," + margin.top + ")")
                .attr("width", innerWidth)
                .attr("height", innerHeight);

            const minTime = timeframe.startTime.valueOf();
            const maxTime = timeframe.endTime.valueOf();

            const xScale = d3.scaleTime()
                .domain([new Date(minTime), new Date(maxTime)])
                .range([0, innerWidth]);

            // add impact windows to chart
            const windows = alertChart.selectAll("windows")
                .data(alertWindows!)
                .enter().append("rect")
                .attr("className", "window")
                .attr("fill", (d: AlertWindow) => d.color)
                .attr("rx", 5) // rounded corners
                .attr("ry", 5) // rounded corners
                .attr("x", (d: AlertWindow) => xScale(d.from))
                .attr("y", (d: AlertWindow) => (alertWindows?.findIndex(alert => alert.id === d.id) as number) * (windowHeight + windowPadding))
                .attr("height", `${windowHeight}px`)
                .attr("width", (d: AlertWindow) => xScale(d.to) - xScale(d.from));

            const getTextWidth = (text: string) => {
                const canvas = document.createElement('canvas');
                const context = canvas.getContext('2d');
                context!.font = '16px ' + 'Roboto';
                return context!.measureText(text).width + 10; // add a little padding to make sure it fits OK                
            };

            const getLabelText = (labelText: string, windowStart: number, windowEnd: number) => {
                const windowWidth = windowEnd - windowStart;
                const textWidth = getTextWidth(labelText);
                if (textWidth > windowWidth) {
                    if (windowEnd < innerMiddle) {
                        return `← ${labelText}`;
                    }
                    return `${labelText} →`;
                }
                return labelText;
            };

            const getLabelPlacement = (labelText: string, windowStart: number, windowEnd: number) => {
                const windowWidth = windowEnd - windowStart;
                const textWidth = getTextWidth(labelText);
                if (textWidth > windowWidth) {
                    // text can not fit in window
                    if (windowEnd < innerMiddle) {
                        // put label right of window at
                        return windowEnd + ((textWidth + 35) / 2);
                    }
                    return windowStart - ((textWidth + 35) / 2);
                }
                return windowStart + (windowWidth / 2);
            };

            // add text over alert windows
            const text = alertChart.selectAll("windows")
                .data(alertWindows!)
                .enter().append("text")
                .text((d: AlertWindow) => getLabelText(d.alertName, xScale(d.from), xScale(d.to)))
                .attr("fill", (d: AlertWindow) => getTextWidth(d.alertName) < xScale(d.to) - xScale(d.from) ? getContrastYIQ(d.color) : 'white')
                .attr("font-size", (d: AlertWindow) => `16px`)
                .attr("text-anchor", "middle")
                .attr("text-height", windowHeight)
                .attr("x", (d: AlertWindow) => getLabelPlacement(d.alertName, xScale(d.from), xScale(d.to)))
                .attr("y", (d: AlertWindow) => (alertWindows?.findIndex(alert => alert.id === d.id) as number) * (windowHeight + windowPadding) + (windowHeight - (windowHeight / 3)))
                .attr("height", `${windowHeight}px`)
                .attr("width", (d: AlertWindow) => getTextWidth(d.alertName))
                .style("z-index", 10004);

            let tooltip = d3.select("#" + containerId).select('div.timeline-tooltip');
            if (tooltip.empty()) tooltip = d3.select('#' + containerId).append('div');

            tooltip.attr("class", "timeline-tooltip")
                .style("opacity", 1.0)
                .style("visibility", "hidden")
                .style("z-index", 10005);

            const hoverLineGroup = alertChart.append("g")
                .style("visibility", "hidden")
                .style("z-index", 10005);

            hoverLineGroup.append("line")
                .attr("y1", 0)
                .attr("y2", innerHeight)
                .style("stroke", "white")
                .style("stroke-width", "2px")
                .style("opacity", 1.0);

            hoverLineGroup.append("path")
                .attr("d", d3.symbol().type(d3.symbolTriangle).size(50))
                .attr("fill", "white")
                .attr("transform", "rotate(180)");

            const createAlertTooltip = (event: any) => {
                // determine which alert is being hovered over
                const coords = d3.pointer(event);
                let index = Math.floor((coords[1] / ((alertKeys.length * (windowHeight + windowPadding / 2)) / alertKeys.length)));
                index = index < 0 ? 0 : index; // round to the top rating 
                index = index > (alertKeys.length - 1) ? alertKeys.length - 1 : index; // round to the bottom rating 

                const hoveredAlert = alerts![index];

                let tooltipContent = '';
                if (hoveredAlert) {
                    const startTime = new Date(hoveredAlert.timestamps.begins);
                    const startDatetimeString = startTime.toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: 'numeric', timeZone: timezone })
                        + ', '
                        + startTime.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true, timeZoneName: 'short', timeZone: timezone });
                    const endTime = new Date(hoveredAlert.timestamps.expires);
                    const endDatetimeString = endTime.toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: 'numeric', timeZone: timezone })
                        + ', '
                        + endTime.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true, timeZoneName: 'short', timeZone: timezone });
                    let alertDescription = hoveredAlert.details.body;
                    if (alertDescription.length > 750) alertDescription = alertDescription.substring(0, 750) + '...';
                    tooltipContent = `<div>${hoveredAlert.details.name}</div>`;
                    tooltipContent += `<br>`;
                    tooltipContent += `<div class="alert-description">Details: ${alertDescription}</div>`;
                    tooltipContent += `<br>`;
                    tooltipContent += `<div>Click alert for full details.</div>`;
                    tooltipContent += `<br>`;
                    tooltipContent += `<div>Start Time: ${startDatetimeString}</div>`;
                    tooltipContent += `<div>End Time: ${endDatetimeString}</div>`;
                }

                const yOffset: number = parseInt(tooltip.style("height")) + 25;
                const xOffset: number = parseInt(tooltip.style("width")) / 2;
                tooltip.html(tooltipContent)
                    .style("top", (event.clientY) - yOffset + "px")
                    .style("left", (event.clientX) - xOffset + "px");
            };
            // Mouse events
            windows.on('click', (event) => {
                const coords = d3.pointer(event);
                let index = Math.floor((coords[1] / ((alertKeys.length * (windowHeight + windowPadding / 2)) / alertKeys.length)));
                index = index < 0 ? 0 : index; // round to the top rating 
                index = index > (alertKeys.length - 1) ? alertKeys.length - 1 : index; // round to the bottom rating 

                const selectedAlert = alerts![index];
                setSelectedAlert(selectedAlert);
            }).on('mouseout', () => {
                tooltip.transition();
                tooltip.style("visibility", "hidden");
            })
                .on('mouseover', () => {
                    tooltip.transition();
                    tooltip.style("visibility", "visible");
                }).on('mousemove', (event) => {
                    createAlertTooltip(event);
                });

            text.on('click', (event) => {
                const coords = d3.pointer(event);
                let index = Math.floor((coords[1] / ((alertKeys.length * (windowHeight + windowPadding / 2)) / alertKeys.length)));
                index = index < 0 ? 0 : index; // round to the top rating 
                index = index > (alertKeys.length - 1) ? alertKeys.length - 1 : index; // round to the bottom rating 

                const selectedAlert = alerts![index];
                setSelectedAlert(selectedAlert);
            }).on('mouseout', () => {
                tooltip.transition();
                tooltip.style("visibility", "hidden");
            })
                .on('mouseover', () => {
                    tooltip.transition();
                    tooltip.style("visibility", "visible");
                }).on('mousemove', (event) => {
                    createAlertTooltip(event);
                });
        },
        [alerts, timeframe, containerDivRef.current, divWidth]
    );

    const graphTitle = (
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: '15px' }}>
            <div className={"header-title-graph-type"}>{`${alertWindows.length} ${alertWindows.length === 1 ? 'NWS Alert' : 'NWS Alerts'}`}</div>
            <div style={{ flex: '1 1 0' }} />
        </div>
    );

    let alertTimelineChart: JSX.Element | undefined = undefined;
    if (alerts === undefined) {
        alertTimelineChart = (
            <div style={{ backgroundColor: cardBackgroundColor }}>
                <div style={{ height: 200, backgroundColor: cardBackgroundColor, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <CircularProgress />
                </div>
            </div>
        );
    } else if (alerts.length === 0) {
        alertTimelineChart = (
            <div style={{ backgroundColor: cardBackgroundColor }}>
                <div style={{ height: 200, backgroundColor: cardBackgroundColor, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    No NWS alerts for this location
                </div>
            </div>
        );
    } else {
        alertTimelineChart = (
            <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: cardBackgroundColor }}>
                <div ref={containerDivRef} id={containerId} style={{ paddingTop: 8, flexGrow: 1, maxHeight: '180px', overflowY: 'auto' }}>
                    <svg
                        ref={d3AlertWindowsRef}
                        style={{
                            height: chartHeight,
                            width: "100%",
                            marginRight: "0px",
                            marginLeft: "0px",
                            borderRadius: 3
                        }}
                    >
                    </svg>
                </div>
                <div ref={containerDivRef} id={containerId} style={{ padding: 8, flexGrow: 1, maxHeight: '25px' }}>
                    <svg
                        ref={d3XAxisRef}
                        style={{
                            height: 25,
                            width: "100%",
                            marginRight: "0px",
                            marginLeft: "0px",
                            borderRadius: 3
                        }}
                    >
                    </svg>
                </div>
            </div>
        );
    }

    return (
        <div className={"TimeSeriesGraphComponent"}>
            <Paper elevation={3} style={{ backgroundColor: cardBackgroundColor, backgroundImage: 'none', borderRadius: 11, ...props.extraStyles }}>
                <header style={{ padding: '12px 12px 12px 0px', display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
                    <Select
                        value={selectedChartView}
                        variant={'outlined'}
                        style={{ margin: "0px 5px", width: 200, height: 40 }}
                        onChange={(event) => setSelectedChartView(event.target.value)}
                    >
                        {chartViews.map(chartView => (
                            <MenuItem value={chartView.key}>{chartView.title}</MenuItem>
                        ))}
                    </Select>
                    {graphTitle}
                    <div style={{ flex: '1 1 0' }} />
                    <IconButton
                        color={'primary'}
                        onClick={() => onCloseClicked && onCloseClicked()}
                        style={{ height: 30 }}
                    >
                        <Close />
                    </IconButton>
                </header>
                {alertTimelineChart}
            </Paper>
        </div>
    );
}

export default AlertTimelineView;
