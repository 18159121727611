import { HourRatingsData, LocationData, MapViewState, VehicleTrackingData } from './index';

export type WeatherFlag =
    'snow_flag'
    | 'rain_flag'
    | 'freezing_rain_flag'
    | 'wind_flag'
    | 'visibility_flag'
    | 'extreme_weather_flag';

export function labelForWeatherFlag(weatherFlag: WeatherFlag): string {
    return {
        'snow_flag': 'Snow',
        'rain_flag': 'Rain',
        'freezing_rain_flag': 'Freezing Rain',
        'wind_flag': 'Wind',
        'visibility_flag': 'Visibility',
        'extreme_weather_flag': 'Extreme Weather'
    }[weatherFlag];
}

export interface RouteData {
    id?: number;
    name?: string;
    externalId?: string;
    origin: RouteWaypoint;
    destination: RouteWaypoint;
    departureTime: Date;
    waypoints?: RouteWaypoint[];
    vehicleData?: VehicleData;
    aboveTemperatureThreshold?: number;
    belowTemperatureThreshold?: number;
    latestRouteResults?: RouteResults[];
    selectedRouteOption?: string;
    latestRouteResultError?: RouteResultError;
    routeResultsUpdatedAt?: Date;
}

export function getFormattedDepartureTime(departureTime: Date | undefined) {
    if (departureTime === undefined) {
        return '';
    }
    const currentYear = new Date().getFullYear();
    return departureTime.toLocaleString('en-US', {
        // only include the year if it's not the current year
        year: departureTime.getFullYear() !== currentYear ? "numeric" : undefined,
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        timeZoneName: "short"
    });
}

export interface RouteResults {
    origin: RouteWaypoint;
    destination: RouteWaypoint;
    slowdownFraction: number;
    adjustedArrivalTime: Date;
    distanceMiles: number;
    weatherFlags: WeatherFlag[];
    slowdownPolylines: SlowdownPolylineSegment[];
    roadRiskPolylines?: RoadRiskPolylineSegment[];
    updatedAt: Date;
    maxRoadIndex: number;
    maxCrashIndex: number;
    aboveTemperatureThresholds: TemperatureThresholdResult[];
    belowTemperatureThresholds: TemperatureThresholdResult[];
}

export interface RouteResultError {
    error: Error;
}

export interface VehicleData {
    id: string;
    type?: string;
    height?: number;
    width?: number;
    length?: number;
    weight?: number;
    axleWeight?: number;
}

export interface RouteWaypoint {
    label: string;
    latitude: number;
    longitude: number;
    stopDuration?: number;

    location?: LocationData;
}

export function locationDataToRouteWaypoint(location: LocationData | undefined): RouteWaypoint | undefined {
    if (location) {
        return {
            label: location.name,
            latitude: location.latitude,
            longitude: location.longitude,

            location: location,
        };
    } else {
        return undefined;
    }
}

export function routeWaypointToLocationData(waypoint: RouteWaypoint | undefined): LocationData | undefined {
    if (!waypoint) {
        return undefined;
    }
    if (waypoint.location) {
        return waypoint.location;
    }
    return {
        name: waypoint.label,
        latitude: waypoint.latitude,
        longitude: waypoint.longitude,
        needsGeocoding: true,
        impactSummaries: [],
    };
}

export function vehicleTrackingDataToLocationData(vehicleData: VehicleTrackingData | undefined): LocationData | undefined {
    if (vehicleData?.latitude && vehicleData?.longitude) {
        return {
            name: vehicleData.name ?? vehicleData.id,
            latitude: vehicleData.latitude,
            longitude: vehicleData.longitude,
            needsGeocoding: true,
            impactSummaries: [],
        };
    } else {
        return undefined;
    }
}

export interface TemperatureThresholdResult {
    threshold: string;
    percentBreachingThreshold: number;
    secondsBreachingThreshold: number;
}

export interface SlowdownPolylineSegment {
    route?: RouteData;
    polyline: string;
    color: string;
}

export interface RoadRiskPolylineSegment {
    route?: RouteData;
    polyline: string;
    color: string;
    maxRoadIndex: number;
    weatherFlags: WeatherFlag[];
}

export type SlowdownPolylineColor = 'green' | 'yellow' | 'red';
export function realColorForSlowdownColor(slowdownColor: SlowdownPolylineColor): string {
    switch (slowdownColor) {
        case 'green': return '#5ED862';
        case 'yellow': return '#FF9743';
        case 'red': return '#F53A28';
    }
}

export interface RoutesViewState extends MapViewState {
    selectedTab: number;
    selectedRoute?: RouteData;

    currentlyRunningRoute?: RouteData;
    routeBeingSaved?: RouteData;
    routesTabError?: Error;

    enteredOrigin?: RouteWaypoint;
    enteredDestination?: RouteWaypoint;
    enteredWaypoints?: RouteWaypoint[];
    enteredDepartureTime?: Date;

    enteredAboveTemperatureThreshold?: number;
    enteredBelowTemperatureThreshold?: number;

    /// set of cached route results unique over 'id' prop
    cachedRouteResults: RouteData[];
}

export function calculateImpactScore(results: RouteResults): number {
    // e.g. high impact with 3.4 road risk and 1% slowdown = 2 + 0.34 + 0.001 = 2.341
    return findImpactLevel(results) + results.maxRoadIndex / 10.0 + results.slowdownFraction / 10.0;
}

export function findRouteImpactReasons(results: RouteResults): string[] {
    let reasons = [];
    if (results.slowdownFraction >= 0.05) {
        reasons.push(`Projected slowdown of ${Math.round(results.slowdownFraction * 100)}%`);
    }
    if (results.maxRoadIndex >= ImpactThreshold.Moderate) {
        reasons.push(`Projected road risk of ${results.maxRoadIndex.toFixed(1)}`);
    }
    if (results.weatherFlags.indexOf('extreme_weather_flag') !== -1) {
        reasons.push('Extreme weather risk present');
    }
    return reasons;
}

export enum ImpactLevel {
    Unknown = -1,
    None = 0,
    Low = 1,
    Moderate = 2,
    High = 3,
    Extreme = 4
}

export function emojiForImpactLevel(impactLevel: ImpactLevel): string {
    switch (impactLevel) {
        case ImpactLevel.Extreme: return "🟣";
        case ImpactLevel.High: return "🔴";
        case ImpactLevel.Moderate: return "🟠";
        case ImpactLevel.Low: return "🟡";
        case ImpactLevel.None: return "🟢";
        case ImpactLevel.Unknown: return "⚫️";
    }
}

export function colorForImpactLevel(impactLevel: ImpactLevel): string {
    switch (impactLevel) {
        case ImpactLevel.Extreme: return "#A744B6";
        case ImpactLevel.High: return "#BE1515";
        case ImpactLevel.Moderate: return "#E68400";
        case ImpactLevel.Low: return "#FFD300";
        case ImpactLevel.None: return "#019801";
        case ImpactLevel.Unknown: return "black";
    }
}

// used for vehicle tags, good for use with white text on top of the colors
export function tagColorForImpactLevel(impactLevel: ImpactLevel): string {
    switch (impactLevel) {
        case ImpactLevel.Extreme: return "#A744B6";
        case ImpactLevel.High: return "#F44335";
        case ImpactLevel.Moderate: return "#FF7A00";
        case ImpactLevel.Low: return "#F2CE0D";
        case ImpactLevel.None: return "green";
        case ImpactLevel.Unknown: return "Black";
    }
}

// Tag colors that are meant to have white text be readable.
export function darkTagColorForImpactLevel(impactLevel: ImpactLevel): string {
    switch (impactLevel) {
        case ImpactLevel.Extreme: return "#8A3A98";  // Adjusted from #A744B6
        case ImpactLevel.High: return "#E53935";     // Adjusted from #F44335
        case ImpactLevel.Moderate: return "#E56E00"; // Adjusted from #FF7A00
        case ImpactLevel.Low: return "#D4B00B";      // Adjusted from #F2CE0D
        case ImpactLevel.None: return "#008000";     // Green remains the same
        case ImpactLevel.Unknown: return "#000000";  // Black remains the same
    }
}

export function wordForImpactLevel(impactLevel: ImpactLevel): string {
    switch (impactLevel) {
        case ImpactLevel.Extreme: return "Extreme";
        case ImpactLevel.High: return "High";
        case ImpactLevel.Moderate: return "Moderate";
        case ImpactLevel.Low: return "Low";
        case ImpactLevel.None: return "None";
        case ImpactLevel.Unknown: return "Unknown";
    }
}

export function pinForImpactLevel(impactLevel: ImpactLevel): string {
    switch (impactLevel) {
        case ImpactLevel.Extreme: return "/images/extreme_impact_pin.svg";
        case ImpactLevel.High: return "/images/high_impact_pin.svg";
        case ImpactLevel.Moderate: return "/images/moderate_impact_pin.svg";
        case ImpactLevel.Low: return "/images/low_impact_pin.svg";
        case ImpactLevel.None: return "/images/none_impact_pin.svg";
        case ImpactLevel.Unknown: return "/images/unknown_impact_pin.svg";
    }
}

// NB: This is also implemented in Rails for impact debriefs, and should be changed in both places to match if changed here.
export function findImpactLevel(results: RouteResults): ImpactLevel {
    if (results.weatherFlags.indexOf('extreme_weather_flag') !== -1 || results.slowdownFraction >= 0.10 || results.maxRoadIndex >= ImpactThreshold.High) {
        return ImpactLevel.High;
    } else if (results.slowdownFraction >= 0.05 || results.maxRoadIndex >= ImpactThreshold.Moderate) {
        return ImpactLevel.Moderate;
    } else if (results.slowdownFraction >= 0.01 || results.maxRoadIndex >= ImpactThreshold.Low) {
        return ImpactLevel.Low;
    } else {
        return ImpactLevel.None;
    }
}

enum ImpactThreshold {
    Low = 2,
    Moderate = 4,
    High = 7,
}

export function findImpactLevelForHourRating(row: HourRatingsData): ImpactLevel {
    if (row.value >= ImpactThreshold.High) return ImpactLevel.High;
    if (row.value >= ImpactThreshold.Moderate && row.value < ImpactThreshold.High) return ImpactLevel.Moderate;
    if (row.value >= ImpactThreshold.Low && row.value < ImpactThreshold.Moderate) return ImpactLevel.Low;
    return ImpactLevel.None;
}