import * as React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material';

export const AutoShowErrorDialog = (props: {error?: string; title: string; onDismiss?: () => void}) => {
    const [isOpen, setOpen] = React.useState(false);

    // track previous error
    const prevErrorRef = React.useRef<string | undefined>();
    const {error} = props;
    React.useEffect(() => {
        prevErrorRef.current = error;
    }, [error]);
    const prevError = prevErrorRef.current;

    // open when we get a new error
    React.useEffect(() => {
        if (error !== prevError && error !== undefined) {
            setOpen(true);
        }
    }, [error, prevError]);

    const dismiss = () => {
        props.onDismiss?.();
        setOpen(false);
    };

    return (
        <Dialog
            open={isOpen}
            onClose={() => dismiss()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {error}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => dismiss()} color="primary" autoFocus>
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    );
};

// a variation of AutoShowErrorDialog that uses an arbitrary string key to determine whether to show or not
// and can track if the user confirmed the action
// might make sense to merge into one component
export const AutoShowConfirmationDialog = (props: {dialogKey?: string; title: string; confirmationOptionTitle: string; onDismiss?: (confirmed: boolean) => void}) => {
    const [isOpen, setOpen] = React.useState(false);

    // track previous key
    const prevKeyRef = React.useRef<string | undefined>();
    const {dialogKey} = props;
    React.useEffect(() => {
        prevKeyRef.current = dialogKey;
    }, [dialogKey]);
    const prevDialogKey = prevKeyRef.current;

    // open when we get a new key
    React.useEffect(() => {
        console.log(dialogKey, prevDialogKey);
        if (dialogKey !== prevDialogKey && dialogKey !== undefined) {
            setOpen(true);
        }
    }, [dialogKey, prevDialogKey]);

    const dismiss = (confirmed: boolean) => {
        props.onDismiss?.(confirmed);
        setOpen(false);
    };

    return (
        <Dialog
            open={isOpen}
            onClose={() => dismiss(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
            {/* <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {error}
                </DialogContentText>
            </DialogContent> */}
            <DialogActions>
                <Button onClick={() => dismiss(false)} color="info" autoFocus>
                    Cancel
                </Button>
                <Button onClick={() => dismiss(true)} color="error" autoFocus>
                    {props.confirmationOptionTitle}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
