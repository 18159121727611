import * as React from 'react';
import './ClientLayout.css';
import { Link } from 'react-router-dom';
import { Location } from 'history';
import { ScaledIcon } from '../ScaledIcon';
import { pageBackgroundColor } from "../../constants";
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, TextField, Tooltip } from '@mui/material';
import { useLocalStorage } from '../shared/useLocalStorage';
import { CalendarToday, Dashboard, DeveloperBoardOutlined, ErrorOutline, Map, Notifications, Person, PlaceOutlined, Radar, Route, Traffic } from '@mui/icons-material';
import { MyAccountState, UserState } from 'src/types';
import moment from 'moment';
import { Spacer } from '../Public/PricingCalculatorEmbed';
import { Config } from '../shared/useConfig';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';

const SettingsDialog = ({ open, setOpen }: { open: boolean; setOpen: (open: boolean) => void }) => {
    const [apiHost, setAPIHost] = useLocalStorage("API_HOST", null);
    const [shouldForceWPC, setShouldForceWPC] = Config.useBooleanConfig(Config.Key.ShouldForceWPC);
    const [lastSeenWhatsNew, setLastSeenWhatsNew] = Config.useStringConfig(Config.Key.LastSeenWhatsNew);
    const [shouldShowRouteVehiclePairs, setShouldShowRouteVehiclePairs] = Config.useBooleanConfig(Config.Key.ShowRouteVehiclePairs);
    const [seenWelcomeDialog, setSeenWelcomeDialog] = Config.useBooleanConfig(Config.Key.ShownWelcomeDialog);
    const [showGlobalAlerts, setShowGlobalAlerts] = Config.useBooleanConfig(Config.Key.ShowGlobalAlerts);
    const [showConditionsForVehiclesInNowcast, setShowConditionsForVehiclesInNowcast] = Config.useBooleanConfig(Config.Key.ShowConditionsForVehiclesInNowcast);
    const [alwaysLogStateChanges, setAlwaysLogStateChanges] = Config.useBooleanConfig(Config.Key.AlwaysLogStateChanges);

    const handleClose = () => {
        setOpen(false);

        // reset to null if it doesn't look like a URL
        if (apiHost !== null && !apiHost.startsWith('http')) {
            setAPIHost(null);
        }
        // reset to null if the field is empty
        if (lastSeenWhatsNew === '') {
            setLastSeenWhatsNew(null);
        }
        window.location.reload();
    };

    return (
        <Dialog open={open}>
            <DialogTitle>Settings</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Enter the API host you wish to use in the following format: https://staging-api.weatheroptics.co. To clear a custom host, save an empty field.
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="API Host"
                    type="website"
                    fullWidth
                    variant="standard"
                    value={apiHost}
                    onChange={(event) => setAPIHost(event.target.value as string)}
                />
                <br />
                <br />
                <FormControlLabel control={<Checkbox checked={shouldForceWPC} onChange={event => setShouldForceWPC(event.target.checked)} />} label="Show WPC storms always instead of WO storms?" />
                <br />
                <FormControlLabel control={<Checkbox checked={shouldShowRouteVehiclePairs} onChange={event => setShouldShowRouteVehiclePairs(event.target.checked)} />} label="Show route/vehicle pairs?" />
                <br />
                <FormControlLabel control={<Checkbox checked={showGlobalAlerts} onChange={event => setShowGlobalAlerts(event.target.checked)} />} label="Show global alerts instead of just US/Canada?" />
                <br />
                <FormControlLabel control={<Checkbox checked={showConditionsForVehiclesInNowcast} onChange={event => setShowConditionsForVehiclesInNowcast(event.target.checked)} />} label="Show conditions for vehicles in nowcast?" />
                <br />
                <FormControlLabel control={<Checkbox checked={alwaysLogStateChanges} onChange={event => setAlwaysLogStateChanges(event.target.checked)} />} label="Log redux state changes in production builds?" />
                <br />
                <br />
                <DialogContentText>
                    Last time that What&apos;s New was seen. To reset, clear the field.
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label=""
                    fullWidth
                    variant="standard"
                    value={lastSeenWhatsNew}
                    onChange={(event) => setLastSeenWhatsNew(event.target.value as string)}
                />
                <br />
                <FormControlLabel control={<Checkbox checked={seenWelcomeDialog} onChange={event => setSeenWelcomeDialog(event.target.checked)} />} label="Seen welcome dialog?" />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Save & Restart</Button>
            </DialogActions>
        </Dialog >
    );
};

interface WhatsNew {
    date: string;
    content: string[];
}

export const WhatsNewDialog = ({ account, automaticOpen, forceOpen, setForceOpen }: { account: MyAccountState | undefined, automaticOpen: boolean, forceOpen?: boolean, setForceOpen?: (status: boolean) => void }) => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const whatsNewContent: WhatsNew[] = require('../../whats_new.json');

    const latestContentDateString = new Date(Date.parse(whatsNewContent[0]['date'])).toISOString();
    const [lastSeenWhatsNew, setLastSeenWhatsNew] = Config.useStringConfig(Config.Key.LastSeenWhatsNew);
    const [shownWelcomeDialog] = Config.useBooleanConfig(Config.Key.ShownWelcomeDialog);
    const handleClose = () => {
        setLastSeenWhatsNew(new Date().toISOString());
        setForceOpen?.(false);
    };

    // only show whats new to currently subscribed accounts that are not logging in for the first time
    const shouldOpen = account !== undefined
        && account.isCurrentlySubscribed === true
        && shownWelcomeDialog
        && (lastSeenWhatsNew === null || lastSeenWhatsNew < latestContentDateString);

    return (
        <Dialog
            // forceOpen can control the state regardless of the last time user saw the dialog
            // automaticOpen must be true to open automatically so we use automatic for the 
            // global dialog and on the My Account page, we use forceOpen to show for the changelog
            open={forceOpen === true || automaticOpen && shouldOpen}
            onClose={handleClose}
            scroll={'paper'}
            fullWidth={true}
            maxWidth={'sm'}
        >
            <DialogTitle style={{ fontSize: '2.5em', fontWeight: 'bold', paddingBottom: 0 }}>What&apos;s New</DialogTitle>
            <DialogContent style={{ height: '40vh' }}>
                {whatsNewContent.map(entry => {
                    return (
                        <div>
                            <p style={{ fontSize: '1.3em', fontWeight: 'bold' }}>{moment(entry.date).format('MMMM D, YYYY')}</p>
                            <ul>
                                {entry.content.map(line => {
                                    return <li style={{ marginBottom: 20 }} dangerouslySetInnerHTML={{ __html: line }}></li>;
                                })}
                            </ul>
                        </div>
                    );
                })}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Got it!</Button>
            </DialogActions>
        </Dialog>
    );
};

// Shown on first login (for both subscribed users and trial users)
const WelcomeDialog = ({ account }: { account: MyAccountState | undefined }) => {
    const [shownWelcomeDialog, setShownWelcomeDialog] = Config.useBooleanConfig(Config.Key.ShownWelcomeDialog);
    const [, setLastSeenWhatsNew] = Config.useStringConfig(Config.Key.LastSeenWhatsNew);
    const trialDurationDays = account !== undefined && account.subscriptionEndsAt !== undefined ? moment(account.subscriptionEndsAt.getTime()).diff(moment(Date.now()), 'days') : 0;
    const handleClose = () => {
        setShownWelcomeDialog(true);
        // keep whats new dialog from being shown on first login
        setLastSeenWhatsNew(new Date().toISOString());
    };

    return (
        <Dialog
            open={account !== undefined && account.isCurrentlySubscribed === true && shownWelcomeDialog === false}
            scroll={'paper'}
            fullWidth={true}
            maxWidth={'md'}
        >
            <DialogContent className={'TrailStartDialogContent'}>
                <div className={'container'}>
                    <div className={'title'}>
                        Welcome to the WeatherOptics Insight Portal!
                    </div>
                    <div className={'subtitle'}>
                        WeatherOptics is a weather intelligence platform that helps businesses plan ahead for weather impacts by producing risk scores, delay times, and recommended actions for key business operations.
                    </div>
                    <div className={'tab-highlights'}>
                        <div className={'tab-highlight'}>
                            <img alt={'track-weather-risks'} src={'/images/track.svg'} />
                            <div>
                                Track weather risks in real-time for your critical shipments, locations, and active vehicles
                            </div>
                        </div>
                        <div className={'tab-highlight'}>
                            <img alt={'optimize-planning'} src={'/images/planning.svg'} />
                            <div>
                                Optimize planning with predictive insights that show how weather will impact your key operations
                            </div>
                        </div>
                        <div className={'tab-highlight'}>
                            <img alt={'alert-customers'} src={'/images/alerts.svg'} />
                            <div>
                                Alert key customers, partners, and personnel with a single source of weather truth for improved transparency and safety
                            </div>
                        </div>
                    </div>
                    <div className={'demo'}>
                        For more information and a full demo of the portal&apos;s capabilities, please go <a target="_blank" rel="noreferrer" href="https://weatheroptics.super.site">here</a>.
                    </div>
                    {account !== undefined && account.isOnATrial === true && (
                        <div className={'trial-length'}>
                            {trialDurationDays <= 1 ? trialDurationDays === 0 ? `Your trial ends today.` : `Your trial ends in 1 day.` : `Your trial ends in ${trialDurationDays} days.`} No payment required.
                        </div>
                    )}
                </div>
                <div className={'footer'}>
                    <ScaledIcon asset={"stroked_logo"} style={{ color: 'white' }} />
                    <Button onClick={handleClose} className={'close-button'}>LET&apos;S GO!</Button>
                </div>
            </DialogContent>
        </Dialog>
    );
};

const TrialEndDialog = ({ account }: { account: MyAccountState | undefined }) => {
    return (
        <Dialog
            open={account !== undefined && account.isCurrentlySubscribed === false}
            scroll={'paper'}
            maxWidth={'xs'}
        >
            <DialogTitle>Your {account?.subscriptionEndReason === 'cancelled' ? 'subscription' : 'trial'} has expired</DialogTitle>
            <DialogContent style={{ height: '50px' }}>
                If you&apos;d like to continue using the portal, please reach out to us at info@weatheroptics.co!
            </DialogContent>
            <DialogActions>
                <Button style={{ color: '#bbb' }} href='/logout'>Log Out</Button>
                <Spacer stretch={true} />
                <Button onClick={() => window.location.href = 'mailto:info@weatheroptics.co'}>Send Email</Button>
            </DialogActions>
        </Dialog>
    );
};

interface Props {
    component: React.ComponentClass<any> | React.StatelessComponent<any>;
    userState: UserState;
    location: Location;
    showImpactTab: boolean;
    showRoutesTab: boolean;
}

export const ClientLayout = ({ component, userState, location, showImpactTab, showRoutesTab }: Props) => {
    const Component = component;

    // logic for showing a modal to save a custom API host which persist to local storage
    const hostname = window.location.hostname;
    const isPreviewDeploy = hostname === "localhost" || hostname.endsWith('-weatheroptics.vercel.app');

    const [settingsOpen, setSettingsOpen] = React.useState(false);
    const handleClickOpenSettings = () => {
        setSettingsOpen(true);
    };

    document.title = "Client Forecast Center - powered by WeatherOptics";

    const tooltips = {
        'dashboard': 'View weather related impacts on your key assets, including locations, shipments, and vehicles.',
        'nowcast': 'Track critical weather events in real time with high resolution short term nowcast modeling.',
        'impact': 'See an alternate view of weather & operational impacts, along with detailed time series graphs for our impact indices.',
        'routes': 'Experiment with new lanes and view your upcoming routes.',
        'assets': 'View and manage your locations and routes.',
        'alerts': 'View NWS alerts on a map',
        'radar': 'View radar on a map.',
        'traffic': 'See traffic flow & incidents at a glance, on a map.',
        'historical': 'View the historical accuracy of WeatherOptics impact indices during real high-impact storms.',
        'account': 'View helpful resources, check your API and Portal usage limits, or contact us.',
    };

    let impactLink = (
        <Link className={"ClientPortalNavLink ImpactNavLink " + (location.pathname.toString().indexOf("/client/impact") > -1 ? "active" : "inactive")} to={"/client/impact"}>
            <Tooltip title={tooltips.impact} placement="right">
                <div>
                    <Map className={"icon"} />
                    <span>Impact</span>
                </div>
            </Tooltip>
        </Link>
    );

    let routesLink = (
        <Link className={"ClientPortalNavLink RoutesNavLink " + (location.pathname.toString().indexOf("/client/routes") > -1 ? "active" : "inactive")} to={"/client/routes"}>
            <Tooltip title={tooltips.routes} placement="right">
                <div>
                    <Route className={"icon"} />
                    <span>Routes</span>
                </div>
            </Tooltip>
        </Link>
    );

    const showNowcastTab = userState.showNowcastTab;

    const tawkMessengerRef = React.useRef<any>();

    const handleClickFeedback = () => {
        if (tawkMessengerRef.current) {
            tawkMessengerRef.current.toggle();
        }
    };

    const onLoad = () => {
        if (tawkMessengerRef.current) {
            tawkMessengerRef.current.setAttributes({
                name: userState.clientName,
            });
        }
    };

    return (
        <div className={"ClientLayout"}>
            <div className={"ClientPortalMain"}>
                <TawkMessengerReact
                    onLoad={() => onLoad()}
                    ref={tawkMessengerRef}
                    propertyId="65bd5d268d261e1b5f5bb129"
                    widgetId="1hllr7nms"
                    customStyle={{
                        visibility: {
                            desktop: {
                                xOffset: 10,
                                yOffset: 108,
                                position: 'bl',
                            },
                        }
                    }}
                />

                <nav className={"ClientPortalNav"}>
                    <div className={"ClientPortalNav-ScrollArea"}>
                        <header className={"ClientPortalHeader"}>
                            <Link to={"/"} className={"ClientHomeLink"}>
                                <ScaledIcon asset={"stroked_logo"} />
                            </Link>
                        </header>

                        <Link className={"ClientPortalNavLink DashboardNavLink " + (location.pathname.toString().endsWith("dashboard") ? "active" : "inactive")} to={"/client/dashboard"}>
                            <Tooltip title={tooltips.dashboard} placement="right">
                                <div>
                                    <Dashboard className={"icon"} />
                                    <span>Dashboard</span>
                                </div>
                            </Tooltip>
                        </Link>

                        {showNowcastTab && <Link className={"ClientPortalNavLink NowcastNavLink " + (location.pathname.toString().endsWith("nowcast") ? "active" : "inactive")} to={"/client/nowcast"}>
                            <Tooltip title={tooltips.nowcast} placement="right">
                                <div>
                                    <Notifications className={"icon"} />
                                    <span>Nowcast</span>
                                </div>
                            </Tooltip>
                        </Link>}

                        {showImpactTab && impactLink}

                        {showRoutesTab && routesLink}

                        <Link className={"ClientPortalNavLink AssetsNavLink " + (location.pathname.toString().indexOf("/client/places") > -1 ? "active" : "inactive")} to={"/client/places"}>
                            <Tooltip title={tooltips.assets} placement="right">
                                <div>
                                    <PlaceOutlined className={"icon"} />
                                    <span>Assets</span>
                                </div>
                            </Tooltip>
                        </Link>

                        <div className={"ClientPortalNav MapLayers"}>
                            <Link className={"ClientPortalNavLink " + (location.pathname.toString().endsWith("alerts") ? "active" : "inactive")} to={"/client/alerts"}>
                                <Tooltip title={tooltips.alerts} placement="right">
                                    <div>
                                        <ErrorOutline className={"icon"} />
                                        <span>Alerts</span>
                                    </div>
                                </Tooltip>
                            </Link>

                            <Link className={"ClientPortalNavLink " + (location.pathname.toString().indexOf("/client/radar") > -1 ? "active" : "inactive")} to={"/client/radar"}>
                                <Tooltip title={tooltips.radar} placement="right">
                                    <div>
                                        <Radar className={"icon"} />
                                        <span>Radar</span>
                                    </div>
                                </Tooltip>
                            </Link>

                            <Link className={"ClientPortalNavLink " + (location.pathname.toString().indexOf("/client/traffic") > -1 ? "active" : "inactive")} to={"/client/traffic"}>
                                <Tooltip title={tooltips.traffic} placement="right">
                                    <div>
                                        <Traffic className={"icon"} />
                                        <span>Traffic</span>
                                    </div>
                                </Tooltip>
                            </Link>
                        </div>

                        <Link className={"ClientPortalNavLink " + (location.pathname.toString().indexOf("/client/historical-accuracy") > -1 ? "active" : "inactive")} to={"/client/historical-accuracy"}>
                            <Tooltip title={tooltips.historical} placement="right">
                                <div>
                                    <CalendarToday className={"icon"} />
                                    <span>Historical</span>
                                </div>
                            </Tooltip>
                        </Link>

                        {isPreviewDeploy && <Link className="ClientPortalNavLink" onClick={handleClickOpenSettings} to="#">
                            <Tooltip title="Developer settings -- only shows up in preview builds" placement="right">
                                <div>
                                    <DeveloperBoardOutlined className="icon" />
                                    <span>Settings</span>
                                </div>
                            </Tooltip>
                        </Link>}
                    </div>

                    {/* <div className={"spacer"} /> */}

                    <div className={"ClientPortalNav-BottomArea"}>
                        <Button
                            className="TawkButton"
                            onClick={() => handleClickFeedback()}
                            style={{
                                marginTop: '32px',
                                marginBottom: '8px',
                                color: 'white',
                                fontSize: 11
                            }}
                        >
                            Questions?
                        </Button>

                        <Link className={"ClientPortalNavLink AccountNavLink " + (location.pathname.toString().indexOf("/client/account") > -1 ? "active" : "inactive")} to={"/client/account"}>
                            <Tooltip title={tooltips.account} placement="right">
                                <div>
                                    <Person className={"icon"} />
                                    <span>Account</span>
                                </div>
                            </Tooltip>
                        </Link>
                    </div>
                </nav>

                <div className={"ClientPortalContent"} style={{ backgroundColor: pageBackgroundColor }}>
                    <Component />
                </div>

                <div className={"mobile-error"}>
                    <div className={"mobile-error-overlay"}>
                        <div className={"mobile-error-card"}>
                            The WeatherOptics portal is only available on a larger browser window or device.
                        </div>
                    </div>
                </div>

                <SettingsDialog open={settingsOpen} setOpen={setSettingsOpen} />

                <WhatsNewDialog account={userState.myAccount} automaticOpen={true} />
                <WelcomeDialog account={userState.myAccount} />
                <TrialEndDialog account={userState.myAccount} />
            </div>
        </div>
    );
};
