import { darkTagColorForImpactLevel, emojiForImpactLevel, ImpactLevel, wordForImpactLevel } from "../../../types/routes";
import * as React from "react";
import {
    DataGridPro,
    GridColumns,
    GridColumnVisibilityModel,
    gridFilteredSortedRowIdsSelector,
    GridLinkOperator, GridRowId,
    GridSortModel,
    GridValueFormatterParams,
    GridValueGetterParams,
    useGridApiRef
} from '@mui/x-data-grid-pro';
import { CustomToolbar, TimelineView } from "./ImpactDetailView";
import { BlurbsByIndex, ImpactSummary, ImpactTypeDetailed, LightningData, LoadableResultMetadata, LocationData, StoreState } from '../../../types';
import { Timeframe } from "./data";
import TimeAgo from "javascript-time-ago";
import moment, { Moment } from "moment";
import { Chip, Tooltip } from "@mui/material";
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { ImpactTimelineComponent } from './ImpactTimelineComponent';
import { formatRatingKey } from "src/types/RatingKey";
import { TablePaginationActions } from "src/components/shared/TablePaginationActions";
import { GridInitialStatePro } from "@mui/x-data-grid-pro/models/gridStatePro";
import { WeatherTimelineComponent } from "./WeatherTimelineComponent";
import { INFO_EMAIL } from "src/constants";
import { snakeCaseToTitleCase } from "src/util";

function arrayEquals(a: any, b: any) {
    return Array.isArray(a) && Array.isArray(b) && a.length === b.length && a.every((val, index) => val === b[index]);
}

interface Props {
    locations: LocationData[];
    locationsMetadata?: LoadableResultMetadata;
    lightning?: LightningData[];
    timelineView: TimelineView;
    timeframe: Timeframe;
    selectedLocation?: LocationData;
    searchQuery: string;
    showOnlyHighImpactLocations: boolean;
    blurbs: BlurbsByIndex;
    onLocationSelected: (location: LocationData) => void;
}

export const ImpactedLocationsTable = (props: Props) => {
    const { locations, locationsMetadata, timeframe, selectedLocation, showOnlyHighImpactLocations, blurbs } = props;

    const [sortModel, setSortModel] = React.useState<GridSortModel>([{ field: 'impact', sort: 'desc' }]);
    const [clientSpecificColumns, setClientSpecificColumns] = React.useState<GridColumns>([]);
    // everything defaults to true so we only need to store falses
    const defaultColumnVisibilityModel: GridColumnVisibilityModel = {
        id: false,
    };
    const [columnVisibilityModel, setColumnVisibilityModel] = React.useState<GridColumnVisibilityModel>(defaultColumnVisibilityModel);

    const getImpactSummary: (params: GridValueGetterParams) => ImpactSummary | undefined = React.useMemo(() => {
        return (params: GridValueGetterParams) => {
            const summary = params.row.impactSummaries.find((s: ImpactSummary) => s.label === timeframe.label);
            if (summary === undefined) {
                return undefined;
            }
            return summary;
        };
    }, [timeframe]);

    const prevTableState = React.useRef<GridInitialStatePro>();
    let initialTableState: GridInitialStatePro | undefined = undefined;
    // use separate table state key for the different tables
    let tableStateKey = 'locationsTableState';
    if (props.timelineView === 'weather') {
        tableStateKey = 'locationsWeatherTableState';
    }
    let initialState = window.localStorage.getItem(tableStateKey);
    if (initialState) {
        initialTableState = JSON.parse(initialState) as GridInitialStatePro;
    }

    React.useEffect(() => {
        // ASSUMES THAT ALL LOCATIONS HAVE THE SAME USER METADATA
        // because once we've loaded in a non zero amount of columns
        // then we don't need to check anymore
        if (clientSpecificColumns.length > 0) {
            return;
        }

        if (locations.length === 0) {
            setClientSpecificColumns([]);
            return;
        }
        const location = locations[0];
        const userMetadata = location.userMetadata;
        if (userMetadata === null || userMetadata === undefined) {
            setClientSpecificColumns([]);
            return;
        }

        setClientSpecificColumns(Object.keys(userMetadata).map((key) => {
            let valueGetter: (s: string) => any = (s: string) => s;
            // try to guess the correct data type based on the format
            try {
                const parsed = JSON.parse(userMetadata[key]);

                if (typeof parsed === "number") {
                    valueGetter = (s: string) => Number(s);
                } else if (typeof parsed === "object" && Array.isArray(parsed)) {
                    valueGetter = (s: string) => JSON.parse(s).join("; ");
                } else {
                    // don't know, fall back to just showing the string
                }
            } catch (e) {
                // don't know, fall back to just showing the string
            }

            let valueFormatter: (val: any) => string = (val: any) => val ? `${val}` : '';

            if (key === 'value') {
                valueFormatter = (val: any) => `$${Math.round(val / 1_000_000 * 10) / 10}M`;
            }

            return {
                field: `userMetadata.${key}`,
                headerName: snakeCaseToTitleCase(key),
                minWidth: 70,
                disableColumnMenu: true,
                valueGetter: (params: GridValueGetterParams) => params.row.userMetadata && valueGetter(params.row.userMetadata[key]),
                valueFormatter: (params) => valueFormatter(params.value),
            };
        }));
    }, [locations.at(0)?.id]);

    // the column visibility model does not export with the rest of table state until MUI 6+
    // we handle it by storing it separately https://github.com/mui/mui-x/issues/6589
    const columnVisibilityModelKey = `impactedLocations${props.timelineView}ColumnVisibilityModel`;
    React.useEffect(() => {
        const colVisModelString = window.localStorage.getItem(columnVisibilityModelKey);
        if (colVisModelString !== null) {
            const colVisModel = JSON.parse(colVisModelString);
            const colVisModelKeys = Object.keys(colVisModel);
            const missingKeys = Object.keys(columnVisibilityModel).filter(k => !colVisModelKeys.includes(k));
            console.log(`adding default values for missing keys in visibility model: ${missingKeys}`);
            for (const key of missingKeys) {
                colVisModel[key] = columnVisibilityModel[key];
            }
            setColumnVisibilityModel(colVisModel);
        }
    }, [clientSpecificColumns]);

    React.useEffect(() => {
        window.localStorage.setItem(columnVisibilityModelKey, JSON.stringify(columnVisibilityModel));
    }, [columnVisibilityModel]);

    function getTagLabel(tag: ImpactTypeDetailed) {
        let label = formatRatingKey(tag.impactType);
        if (tag.impactValue !== undefined) {
            label += ` (${tag.impactValue.toFixed(1)})`;
        }
        return label;
    }

    function getTagWidth(text: string, font: string): number {
        const canvas: HTMLCanvasElement = document.getElementsByTagName("canvas")[0] || document.createElement("canvas");
        const context: CanvasRenderingContext2D | null = canvas.getContext("2d");
        if (context) {
            context.font = font;
            const metrics = context?.measureText(text);
            // adding a little extra padding to prevent some edge cases where tags weren't collapsing
            return metrics?.width + 21 + 4; // add padding and margin to text length
        }
        return -1;
    }

    function getFont() {
        const el = document.body;
        const fontWeight = window.getComputedStyle(el, null).getPropertyValue('font-weight') || 'normal';
        const fontSize = window.getComputedStyle(el, null).getPropertyValue('font-size') || '16px';
        const fontFamily = window.getComputedStyle(el, null).getPropertyValue('font-family') || 'Times New Roman';

        return `${fontWeight} ${fontSize} ${fontFamily}`;
    }

    function getTagWrapPoint(tags: ImpactTypeDetailed[], colWidth: number) {
        let colRemainder = colWidth;
        let wrapPoint = -1;
        tags.every((tag: ImpactTypeDetailed, index: number) => {
            colRemainder = colWidth;
            colWidth -= getTagWidth(getTagLabel(tag), getFont());
            if (colWidth < 0) {
                if (colRemainder - getTagWidth(`+${tags.length - wrapPoint}`, getFont()) < 0) {
                    wrapPoint = index - 1;
                } else {
                    wrapPoint = index;
                }
                return false;
            }
            return true;
        });
        return wrapPoint;
    }

    let columns = undefined;
    if (props.timelineView === 'weather') {
        columns = React.useMemo<GridColumns>(() => [{
            field: 'id',
            hide: true
        }, {
            field: 'name',
            headerName: 'Name',
            minWidth: 150,
            disableColumnMenu: true,
            valueGetter: (params: GridValueGetterParams) => params.row.name
        }], [locations]);
    } else {
        // columns are memoized to avoid infinite render glitch when passing sortModel
        // https://codesandbox.io/s/infinite-render-sortmodel-forked-bwv2g?file=/src/Demo.tsx:1528-1575
        columns = React.useMemo<GridColumns>(() => [{
            field: 'id',
            hide: true
        }, {
            field: 'name',
            headerName: 'Name',
            minWidth: 150,
            disableColumnMenu: true,
            valueGetter: (params: GridValueGetterParams) => params.row.name
        }, {
            field: 'impact',
            headerName: 'Impact',
            minWidth: 125,
            disableColumnMenu: true,
            sortComparator: (v1: ImpactSummary | undefined, v2: ImpactSummary | undefined) => {
                const impactLevel1 = v1?.impactLevel;
                const impactValues1 = v1?.impactTypesDetailed?.map(x => x.impactValue) ?? [];
                const impactLevel2 = v2?.impactLevel;
                const impactValues2 = v2?.impactTypesDetailed?.map(x => x.impactValue) ?? [];

                if (impactLevel1 === undefined) return -1;
                if (impactLevel2 === undefined) return 1;

                if (impactLevel1 === impactLevel2) {
                    // NWS take precedence in ranking but if a risk score is included from WO that should be the next biggest factor
                    // Red flag warning 7.5 risk score > Red flag 5.5 risk score > Red flag warning 0 risk scores
                    for (let index = 0; index < Math.max(impactValues1.length, impactValues2.length); index++) {
                        // the impact values are already sorted from highest to lowest
                        // if the value is missing, then assign it a 10 for NWS alerts
                        // if the index is beyond the list, then assign it a 0
                        const impactValue1 = index >= impactValues1.length ? 0 : (impactValues1[index] || 10);
                        const impactValue2 = index >= impactValues2.length ? 0 : (impactValues2[index] || 10);
                        if (impactValue1 !== impactValue2) {
                            return impactValue1 - impactValue2;
                        }
                    }
                    return 0;
                } else {
                    return impactLevel1 - impactLevel2;
                }
            },
            valueGetter: (params) => getImpactSummary(params),
            valueFormatter: (params) => {
                const impactSummary = params.value as ImpactSummary | undefined;
                if (impactSummary === undefined) {
                    return '';
                }

                // return words for csv export
                return wordForImpactLevel(impactSummary.impactLevel);
            },
            renderCell: (params) => {
                const impactSummary = params.value as ImpactSummary | undefined;
                if (impactSummary === undefined) {
                    return 'Updating Soon ⏲️';
                }

                const emoji = emojiForImpactLevel(impactSummary.impactLevel);
                return `${params.formattedValue} ${emoji}`;
            }
        }, ...clientSpecificColumns, {
            field: 'peakImpactTimeMoment',
            headerName: 'Peak Impact Time',
            minWidth: 150,
            disableColumnMenu: true,
            sortComparator: (v1, v2) => {
                const { date: d1 } = v1 as { date: moment.Moment };
                const { date: d2 } = v2 as { date: moment.Moment };

                if (d1 === undefined) return -1;
                if (d2 === undefined) return 1;

                return d1.toDate().getTime() - d2.toDate().getTime();
            },
            valueGetter: (params) => {
                const summary = getImpactSummary(params);

                // return a date 3 months in the future for missing summaries so they're at the end of the list when sorting by time
                if (summary === undefined) {
                    return { date: moment().add(90, 'days'), summary };
                }

                let date = summary.peakImpactTimeMoment;

                // return a date 1 month in the future for None summaries so they're after all summaries > None
                if (summary.impactLevel === ImpactLevel.None) {
                    date = date.clone().add(30, 'days');
                }

                return { date, summary };
            },
            valueFormatter: (params) => {
                const { date, summary } = params.value as { date: Moment; summary: ImpactSummary };

                if (summary === undefined || summary.impactLevel === ImpactLevel.None) {
                    return '';
                }

                return date.format('M/D, hA z');
            }
        }, {
            field: 'impactTypes',
            headerName: 'Impact Types',
            minWidth: 150,
            disableColumnMenu: true,
            sortComparator: (v1, v2) => {
                const s1 = v1 as ImpactSummary;
                const s2 = v2 as ImpactSummary;

                const getSortValue = (s: ImpactSummary) => {
                    // missing summaries get -1 to sort them after None entries
                    if (s === undefined) return -1;
                    if (s.impactLevel === ImpactLevel.None) return 0;
                    return s.impactTypesDetailed.length;
                };

                return getSortValue(s1) - getSortValue(s2);
            },
            valueGetter: (params) => {
                return getImpactSummary(params);
            },
            valueFormatter: (params) => {
                const summary = params.value as ImpactSummary;
                if (summary === undefined || summary.impactLevel === ImpactLevel.None || summary.impactTypesDetailed.length === 0) return '';
                return summary.impactTypesDetailed.map(x => formatRatingKey(x.impactType)).join(', ');
            },
            renderCell: (params) => {
                const summary = params.value as ImpactSummary;
                if (summary === undefined || summary.impactLevel === ImpactLevel.None || summary.impactTypesDetailed.length === 0) return '';

                const tags = summary.impactTypesDetailed;
                let wrapPoint = getTagWrapPoint(tags, params.colDef.computedWidth || 0);

                const expandText: string = tags.length - wrapPoint === 1 ? `${tags.length - wrapPoint} more hazard` : `${tags.length - wrapPoint} more hazards`;
                const expandNum: string = `+${tags.length - wrapPoint}`;

                let impactCell: JSX.Element = (<div></div>);
                if (wrapPoint === -1 || tags.length === 1/* || expandedRowIds[0] === params.row.id*/) {
                    impactCell = (<>{tags.map((tag) =>
                        <Tooltip title={blurbs[tag.impactType]?.at(Math.floor(tag.impactValue ?? 0))?.blurb || `Effective ${timeframe.name.toLowerCase()}`}>
                            <Chip
                                className={'vehicleTagDiv'}
                                size="small"
                                label={getTagLabel(tag)}
                                style={{ backgroundColor: darkTagColorForImpactLevel(tag.impactLevel) }}
                            />
                        </Tooltip >
                    )
                    }</>);
                } else {
                    impactCell = (<>
                        {tags.slice(0, wrapPoint).map((tag) =>
                            <Tooltip title={blurbs[tag.impactType]?.at(Math.floor(tag.impactValue ?? 0))?.blurb || `Effective ${timeframe.name.toLowerCase()}`}>
                                <Chip
                                    className={'vehicleTagDiv'}
                                    size="small"
                                    label={getTagLabel(tag)}
                                    style={{ backgroundColor: darkTagColorForImpactLevel(tag.impactLevel) }}
                                />
                            </Tooltip>
                        )}
                        <Tooltip title={expandText}>
                            <Chip
                                className={'vehicleTagDiv'}
                                size="small"
                                // onClick={(event) => {
                                //     setExpandedRowIds([params.row.id]);
                                //     event.preventDefault();
                                // }}
                                label={expandNum}
                                style={{ backgroundColor: '#0280dc', cursor: 'default' }}
                            />
                        </Tooltip>
                    </>);
                }

                return (
                    <div className={'vehicleCellDiv'}>
                        {impactCell}
                    </div>
                );
            }
        }, {
            field: 'weatherFlags',
            headerName: 'Weather Conditions at Peak Impact',
            minWidth: 250,
            disableColumnMenu: true,
            sortComparator: (v1, v2) => {
                const s1 = v1 as ImpactSummary;
                const s2 = v2 as ImpactSummary;

                const getSortValue = (s: ImpactSummary) => {
                    // missing summaries get -1 to sort them after None entries
                    if (s === undefined) return -1;
                    if (s.impactLevel === ImpactLevel.None) return 0;
                    return s.weatherFlags.length;
                };

                return getSortValue(s1) - getSortValue(s2);
            },
            valueGetter: (params) => {
                return getImpactSummary(params);
            },
            valueFormatter: (params) => {
                const summary = params.value as ImpactSummary;
                if (summary === undefined || summary.impactLevel === ImpactLevel.None) return '';

                const flags = (summary?.weatherFlags ?? []) as string[];
                return flags.map(flag => {
                    return flag
                        .replace(/_flag/, '')
                        .replace('_', ' ')
                        .split(' ')
                        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                        .join(' ');
                }).join(', ');
            }
        }, {
            field: 'impactSummariesUpdatedAt',
            headerName: 'Last Updated',
            minWidth: 120,
            disableColumnMenu: true,
            valueFormatter: (params: GridValueFormatterParams) => {
                const timeAgo = new TimeAgo('en-US');
                return params.value ? timeAgo.format(params.value as Date) : '';
            }
        }], [getImpactSummary, clientSpecificColumns]);
    }

    // logic to scroll to the correct page when a location is externally selected
    const [page, setPage] = React.useState(0);
    const pageSize = 5;

    const prevSelectedLocation = React.useRef<LocationData | undefined>();
    const prevSortModelRef = React.useRef<GridSortModel>();

    const apiRef = useGridApiRef();

    React.useEffect(() => {
        const api = apiRef.current;
        if (!api) return;
        api.applySorting();
    }, [timeframe, apiRef]);

    React.useEffect(() => {
        if (selectedLocation === undefined) return;
        if (locationsMetadata?.loading === true) return;

        const api = apiRef.current;
        if (!api) return;

        const ids = gridFilteredSortedRowIdsSelector(apiRef);
        const index = ids.findIndex(id => selectedLocation.id === id);
        if (index === -1) return;

        const pageForSelectedLocation = Math.floor(index / pageSize);
        if (page !== pageForSelectedLocation) {
            // putting set page in set timeout to avoid null pointer when trying to set page on initial render
            // thie is a nkown and open bug for MUI datagrid https://github.com/mui/mui-x/issues/6411
            setTimeout(() => setPage(pageForSelectedLocation), 0);
        }

        prevSelectedLocation.current = selectedLocation;
    }, [locations, selectedLocation, props.searchQuery, apiRef, timeframe.label]);

    // when the sort order changes, go to the first page of the results
    React.useEffect(() => {
        const prevSortModel = prevSortModelRef.current || [];
        if (arrayEquals(sortModel, prevSortModel)) return;

        setPage(0);

        prevSortModelRef.current = sortModel;
    }, [sortModel]);

    const filterModel = {
        items: [
            {
                id: 1,
                columnField: 'name',
                operatorValue: 'contains',
                value: props.searchQuery
            },
            ...(
                locations.length > 0 && locations[0].userMetadata
                    ? Object.keys(locations[0].userMetadata).map((key, index) => ({
                        id: index + 2, // Starting from 2 to avoid conflict with the 'name' field
                        columnField: `userMetadata.${key}`,
                        operatorValue: 'contains',
                        value: props.searchQuery
                    }))
                    : []
            )
        ],
        linkOperator: GridLinkOperator.Or
    };

    const useAppSelector: TypedUseSelectorHook<StoreState> = useSelector;
    const token = useAppSelector(state => state.user.token);
    const portalToken = useAppSelector(state => state.user.portalToken);
    let runTimes = useAppSelector(state => state.ratings.impactTilesets.value?.runTimes);

    const timelineHeight = 172;

    const [expandedRowIds, setExpandedRowIds] = React.useState<GridRowId[]>([]);


    return (
        <div>
            <DataGridPro
                components={{
                    Toolbar: CustomToolbar(apiRef, 'locations', props.timelineView === 'impact'),
                    NoRowsOverlay: () => {
                        return (
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%' }}>
                                <div style={{ display: 'inline-block', zIndex: 99, position: 'relative', padding: '5%' }}>
                                    {locationsMetadata?.success === true && <p style={{ textAlign: 'center' }}>
                                        No locations {showOnlyHighImpactLocations ? 'with high impact' : ''} found for {timeframe.name}.
                                    </p>}
                                    {locationsMetadata?.error !== undefined && <p style={{ textAlign: 'center' }}>
                                        There was an error while fetching your locations. Please refresh the page to try again. <a style={{ color: '#90CAF9' }} href={`mailto:${INFO_EMAIL}`}>Contact us</a> if the issue persists.
                                    </p>}
                                </div>
                            </div>
                        );
                    }
                }}
                componentsProps={{
                    pagination: {
                        ActionsComponent: TablePaginationActions
                    },
                    basePopper: {
                        placement: "top-start",
                        sx: {
                            paddingBottom: '30px',
                            '.MuiDataGrid-panelHeader': { display: "none" },
                            '.MuiDataGrid-columnsPanel .MuiDataGrid-columnsPanelRow:nth-child(-n + 2)': { display: "none" }
                        }
                    },
                }}
                apiRef={apiRef}
                columns={columns}
                rows={locations}
                pageSize={pageSize}
                page={page}
                pagination
                onPageChange={(page) => setPage(page)}
                autoHeight
                density={'compact'}
                filterModel={filterModel}
                sortModel={sortModel}
                onSortModelChange={(newValue) => setSortModel(newValue)}
                onRowClick={(row, event) => props.onLocationSelected(row.row as LocationData)}
                selectionModel={selectedLocation?.id}
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) => {
                    newModel['id'] = false;
                    setColumnVisibilityModel(newModel);
                }}
                getDetailPanelContent={({ row }) => {
                    const location = row as LocationData;
                    if (props.timelineView === 'impact') {
                        if (location.impactSummariesRatingRunTime !== undefined) {
                            runTimes = {
                                road: location.impactSummariesRatingRunTime,
                                disruption: location.impactSummariesRatingRunTime,
                                power: location.impactSummariesRatingRunTime,
                                flood: location.impactSummariesRatingRunTime,
                                life_property: location.impactSummariesRatingRunTime,
                            };
                        }
                        if (runTimes && portalToken) {
                            return <ImpactTimelineComponent
                                height={timelineHeight}
                                timeframe={timeframe}
                                token={portalToken}
                                location={location}
                                runTimes={runTimes}
                            />;
                        }
                    } else {
                        if (portalToken) {
                            return <WeatherTimelineComponent
                                height={timelineHeight}
                                lightning={props.lightning}
                                timeframe={{ label: '24hours', name: '24 Hours', startTime: moment().startOf('day'), endTime: moment().add(1, 'day') }}
                                token={token!}
                                portalToken={portalToken}
                                location={location}
                            />;
                        }
                    }
                    return <div />;
                }}
                getDetailPanelHeight={() => timelineHeight}
                detailPanelExpandedRowIds={expandedRowIds}
                onDetailPanelExpandedRowIdsChange={(ids, details) => {
                    if (ids.length === 0) {
                        setExpandedRowIds([]);
                    } else {
                        setExpandedRowIds([ids.pop()!]);
                    }
                }}
                onStateChange={(e) => {
                    const current = apiRef.current.exportState();
                    if (JSON.stringify(prevTableState.current) !== JSON.stringify(current)) {
                        prevTableState.current = current as GridInitialStatePro;
                        window.localStorage.setItem(tableStateKey, JSON.stringify(current));
                    }
                }}
                initialState={initialTableState}
            />
        </div>
    );
};
