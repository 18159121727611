import * as React from 'react';
import { DriverNotification, DriverNotificationThreshold } from '../../../types';
import { DataGridPro, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid-pro';
import { Button, Tooltip } from '@mui/material';
import { DeleteOutline, EditOutlined } from '@mui/icons-material';
import { formatRatingKey } from 'src/types/RatingKey';

export const DriverNotificationsTable = ({ driverNotifications, onDeleteDriverNotificationClicked, onEditDriverNotificationClicked, }: { driverNotifications: DriverNotification[], onDeleteDriverNotificationClicked: (driverNotifications: DriverNotification) => void, onEditDriverNotificationClicked: (driverNotification: DriverNotification) => void }) => {

    const columns = [{
        field: 'id',
        hide: true
    }, {
        field: 'name',
        headerName: 'Name',
        flex: 1
    }, {
        field: 'thresholds',
        headerName: 'Thresholds',
        flex: 1,
        renderCell: (params: any) => {
            const thresholds = params.row.thresholds;
            if (thresholds === undefined) return '';

            return (
                <Tooltip title={thresholds.map((threshold: DriverNotificationThreshold) => `${formatRatingKey(threshold.index)}: ${threshold.lowerBound} - ${threshold.upperBound}`).join(', ')}>
                    <div style={{ display: 'flex', gap: '10px' }}>{thresholds.map((threshold: DriverNotificationThreshold) => `${formatRatingKey(threshold.index)}: ${threshold.lowerBound} - ${threshold.upperBound}`).join(', ')}</div>
                </Tooltip>
            );
        },
    }, {
        field: 'alerts',
        headerName: 'Alerts',
        flex: 1,
        renderCell: (params: any) => {
            const alerts = params.row.alerts;
            if (alerts === undefined) return '';

            return (
                <Tooltip title={alerts.join(', ')}>
                    <div style={{ display: 'flex', gap: '10px' }}>{alerts.join(', ')}</div>
                </Tooltip>
            );
        },
    }, {
        field: 'template',
        headerName: 'Template',
        flex: 2,
        renderCell: (params: any) => {
            const template = params.row.template as string;
            if (template === undefined) return '';

            return (
                <Tooltip title={template}>
                    <div style={{ display: 'flex', gap: '10px' }}>{template}</div>
                </Tooltip>
            );
        },
    }, {
        field: 'status',
        headerName: 'Status',
        flex: 1,
    }, {
        // fake field name to prevent the header from being highlighted weirdly
        field: 'actions',
        headerName: 'Actions',
        width: 160,
        sortable: false,
        valueGetter: (params: GridValueGetterParams) => {
            return driverNotifications.filter(x => x.id === params.id)[0];
        },
        renderCell: (params: GridRenderCellParams) => {
            return (
                <div>
                    <Button
                        onClick={() => onEditDriverNotificationClicked(params.value as DriverNotification)}
                    >
                        <EditOutlined />
                    </Button>
                    <Button
                        onClick={() => onDeleteDriverNotificationClicked(params.value as DriverNotification)}
                    >
                        <DeleteOutline />
                    </Button>
                </div>
            );
        }
    }];

    return (
        <div>
            <div>
                <DataGridPro
                    components={{ NoRowsOverlay: () => <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%' }}><div style={{ display: 'inline-block', position: 'relative', padding: '5%' }}>No driver notifications have been created.</div></div> }}
                    columns={columns}
                    rows={driverNotifications}
                    pageSize={10}
                    pagination
                    autoHeight
                    disableSelectionOnClick
                />
            </div>
        </div>
    );
};
