import './LoginForm.css';
import * as React from 'react';
import { Redirect, useHistory } from 'react-router';
import { ScaledIcon } from './ScaledIcon';
import { Button, LinearProgress, TextField, Typography } from '@mui/material';

interface Props {
    token?: string;
    isLoggingIn: boolean;
    lastError?: string;
    initiateLogin: (email: string, password: string) => Promise<void>;
    onResetRequested: (email: string) => Promise<void>;
}

interface State {
    email: string;
    password: string;
}

class LoginFormWithState extends React.Component {
    state: State = {
        email: "",
        password: "",
    };

    props: Props;

    onChange(fieldName: string, fieldValue: string) {
        this.setState({
            ...this.state,
            [fieldName]: fieldValue,
        });
    }

    onChangeInput(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
        this.onChange(event.target.name, event.target.value);
    }

    onSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        event.stopPropagation();
        event.bubbles = false;

        this.props.initiateLogin(this.state.email, this.state.password);

        return false;
    }

    render() {
        let lastErrorDisplay;
        if (this.props.lastError) {
            lastErrorDisplay = (
                <div>
                    <Typography color={'error'} variant={'body1'}>
                        {this.props.lastError}
                    </Typography>
                    <br />
                </div>
            );
        }

        return (
            <div className={"LoginForm"}>
                <div className={"LoginForm-wrap"}>
                    <ScaledIcon asset='logo' className={"LoginForm-logo"} alt="WeatherOptics" />
                    <br />
                    <Typography variant={'h4'} color={'textPrimary'}>Client Login</Typography>
                    <br />

                    <form onSubmit={event => this.onSubmit(event)}>
                        <TextField
                            required
                            name="email"
                            type="email"
                            onChange={(event) => this.onChangeInput(event)}
                            style={{ minWidth: 280 }}
                            placeholder="Email"
                        />
                        <br />
                        <br />
                        <TextField
                            required
                            name="password"
                            type="password"
                            onChange={(event) => this.onChangeInput(event)} className={"LoginForm-textfield"}
                            placeholder="Password"
                            style={{ minWidth: 280 }}
                        />
                        <br />
                        <br />

                        {lastErrorDisplay}

                        {this.props.isLoggingIn && (
                            <div>
                                <LinearProgress />
                                <br />
                            </div>
                        )}

                        <div>
                            <Button
                                type={'submit'}
                                variant={'contained'}
                                color={'primary'}
                                disabled={this.props.isLoggingIn}
                            >
                                Login
                            </Button>

                            &nbsp;&nbsp;&nbsp;

                            <Button
                                href={"/reset-password"}
                                color={'secondary'}
                            >
                                Forgot Password?
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

const LoginForm = (props: Props) => {
    if (typeof props.token !== 'undefined') {
        const redirectURL = window.localStorage.getItem("redirectURL");
        if (redirectURL) {
            window.localStorage.removeItem("redirectURL");
            const history = useHistory();
            history.push(redirectURL);
        } else {
            // we are logged in, so redirect to home
            return <Redirect to='/' />;
        }
    }

    React.useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const trialKey = urlParams.get('trialKey');
        if (trialKey) {
            const encodedUrlParams = new URLSearchParams(atob(trialKey));
            const email = encodedUrlParams.get('email');
            const password = encodedUrlParams.get('password');
            if (email && password) {
                props.initiateLogin(email, password);
            }
        }
    }, [window.location.search]);

    // otherwise render a login form with state
    return (
        <LoginFormWithState {...props} />
    );
};

export default LoginForm;
