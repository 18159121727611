import * as React from 'react';

interface ScaledImageProps {
    asset: string;
    extension?: string;
    folder?: string;
}

export class ScaledIcon extends React.Component<ScaledImageProps & React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>> {
    render() {
        let extension = this.props.extension || "png";
        let folder = this.props.folder || "/images";
        let urlWithoutExtension = `${folder}/${this.props.asset}`;

        return (
            <img
                alt={this.props.asset} // default alt string
                {...this.props} // will overwrite default alt string if specified
                src={`${urlWithoutExtension}.${extension}`}
                srcSet={[`${urlWithoutExtension}@2x.${extension} 2x`, `${urlWithoutExtension}@3x.${extension} 3x`].join(', ')}
            />
        );
    }
}