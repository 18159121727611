import { StoreState } from '../../types';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { ClientAccountPage } from '../../components/Client/Account';
import { createActivateAccountAction, createLogoutAction, createRefreshAccountAction } from 'src/actions/User';

export function mapStateToProps(state: StoreState, _ownProps: RouteComponentProps<any>) {
    return {
        myAccount: state.user.myAccount,
        user: state.user
    };
}

export function mapDispatchToProps(dispatch: ThunkDispatch<StoreState, void, Action<any>>) {
    return {
        activateAccount: () => { dispatch(createActivateAccountAction()); },
        refreshAccount: () => { dispatch(createRefreshAccountAction()); },
        logOutLocally: () => { dispatch(createLogoutAction(undefined)); },
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ClientAccountPage));
