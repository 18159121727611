import {
    RadarSetAnimationSpeed,
    RadarSetPaused,
    RadarViewAction
} from '../actions/RadarView';
import { RadarViewState } from '../types';

export function radarView(state: RadarViewState, action: typeof RadarViewAction.actions): RadarViewState {
    if (typeof state === 'undefined') {
        return {
            selectedSpeedMultiplierIndex: 0,
            paused: false,
            animationDelay: 1000,
        };
    }

    switch (action.type) {
        case RadarSetAnimationSpeed.type:
            return {
                ...state,
                selectedSpeedMultiplierIndex: action.selectedSpeedMultiplierIndex,
                animationDelay: action.animationDelay
            };
        case RadarSetPaused.type:
            return { ...state, paused: action.paused };
        default: return state;
    }
}
