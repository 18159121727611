import { Action, ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { RoadClosureData, RoadStatusData, RoadWorkData, SpecialEventData, StoreState, TrafficCongestionData, TrafficIncidentData, TruckWarningData, WeatherStationData } from '../types';
import { ROAD_STATUS_HOST } from '../constants';
import 'redux-thunk';

import { action, props, union } from 'ts-action';
import { createLogoutAction } from './User';
import { retryIfRateLimited } from './Ratings';
import { DashboardOverlay } from 'src/components/Client/Dashboard';

export const TrafficSetTrafficLayer = action('TRAFFIC_SET_TRAFFIC_LAYER', props<{ layer: DashboardOverlay | undefined }>());

export const TrafficSetSelectedRoadStatus = action('TRAFFIC_SET_SELECTED_ROAD_STATUS', props<{ roadStatus?: RoadStatusData }>());

export const TrafficSetSelectedRoadWork = action('TRAFFIC_SET_SELECTED_ROAD_WORK', props<{ roadWork?: RoadWorkData }>());

export const TrafficSetSelectedRoadClosure = action('TRAFFIC_SET_SELECTED_ROAD_CLOSURE', props<{ roadClosure?: RoadClosureData }>());

export const TrafficSetSelectedSpecialEvent = action('TRAFFIC_SET_SELECTED_SPECIAL_EVENT', props<{ specialEvent?: SpecialEventData }>());

export const TrafficSetSelectedTrafficCongestion = action('TRAFFIC_SET_SELECTED_TRAFFIC_CONGESTION', props<{ trafficCongestion?: TrafficCongestionData }>());

export const TrafficSetSelectedTrafficIncident = action('TRAFFIC_SET_SELECTED_TRAFFIC_INCIDENT', props<{ trafficIncident?: TrafficIncidentData }>());

export const TrafficSetSelectedTruckWarning = action('TRAFFIC_SET_SELECTED_TRUCK_WARNING', props<{ truckWarning?: TruckWarningData }>());

export const TrafficSetSelectedWeatherStation = action('TRAFFIC_SET_SELECTED_WEATHER_STATION', props<{ weatherStation?: WeatherStationData }>());

export const TrafficClearSelectedRoadCondition = action('TRAFFIC_CLEAR_SELECTED_ROAD_CONDITION');

export const ReceiveRoadConditionsData = action('RECEIVE_ROAD_CONDITIONS_DATA', props<{
    roadConditionsData: object;
    receivedAt: number;
}>());

export const LoadRoadConditionsFail = action('LOAD_ROAD_CONDITIONS_FAIL', props<{ error?: object }>());

export const LoadRoadConditionsStart = action('LOAD_ROAD_CONDITIONS_START');

export const TrafficViewAction = union(
    TrafficSetSelectedRoadStatus, TrafficSetSelectedRoadWork, TrafficSetSelectedRoadClosure, TrafficSetSelectedSpecialEvent, TrafficSetSelectedTrafficCongestion,
    TrafficSetSelectedTrafficIncident, TrafficSetSelectedTruckWarning, TrafficSetSelectedWeatherStation, TrafficClearSelectedRoadCondition,
    ReceiveRoadConditionsData, LoadRoadConditionsFail, LoadRoadConditionsStart, TrafficSetTrafficLayer,
);


export function fetchRoadConditionsData(token: string) {
    return (dispatch: Dispatch<any>) => {
        dispatch(LoadRoadConditionsStart());

        let req: Promise<Response>;
        const url = `${ROAD_STATUS_HOST}/current?token=${token}`;

        req = retryIfRateLimited(() => fetch(url));

        return req.then(
            (response: Response) => response.json(),
            (error: Error) => console.log('Error fetching road status data.', error)
        ).then((json: JSON) => {
            if (json['error'] === 'invalid token') {
                dispatch(createLogoutAction(token));
                return undefined;
            } else if (json['error'] === 'permission not granted') {
                console.warn('user does not have permissions for road conditions data');
                return undefined;
            } else {
                return dispatch(ReceiveRoadConditionsData({ roadConditionsData: json, receivedAt: Date.now() }));
            }
        });
    };
}

export const loadRoadConditionsIfNeeded: ActionCreator<ThunkAction<Promise<void>, StoreState, void, Action<any>>> = () => {
    return (dispatch, getState) => {
        let trafficViewState = getState().trafficView;
        let token = getState().user.portalToken;

        if (typeof token === 'undefined') {
            return Promise.resolve();
        }

        const timestamp = Date.now();
        let roadConditionsStale = trafficViewState.roadConditionsExpiryTime === undefined || timestamp > trafficViewState.roadConditionsExpiryTime;

        if (roadConditionsStale) {
            return Promise.resolve(dispatch(fetchRoadConditionsData(token)).then(() => { }));
        } else {
            return Promise.resolve();
        }
    };
};