import * as React from 'react';
import { VehicleTrackingData } from '../../../types';
import { DataGridPro, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { Switch, Tooltip } from '@mui/material';
import { WarningAmberOutlined } from '@mui/icons-material';
import { INFO_EMAIL } from 'src/constants';

export const VehiclesTable = ({ vehicles, onVehicleNotificationsEnebledToggled }: { vehicles: VehicleTrackingData[], onVehicleNotificationsEnebledToggled: (vehicle: VehicleTrackingData, enabled: boolean) => void }) => {
    const directions = ['North', 'Northeast', 'East', 'Southeast', 'South', 'Southwest', 'West', 'Northwest'];

    const columns = [{
        field: 'id',
        headerName: 'ID',
        flex: 1
    }, {
        field: 'externalId',
        headerName: 'External ID',
        flex: 1,
        renderCell: (params: any) => {
            const truckId = params.row.externalId;
            if (truckId === undefined) return '';

            return (params.row.latitude === null || params.row.longitude === null) ? (
                <Tooltip title={'Vehicle is missing location data.'}>
                    <div style={{ display: 'flex', gap: '10px' }}>{truckId} <WarningAmberOutlined style={{ color: 'yellow' }} /></div>
                </Tooltip>
            )
                : (<div style={{ display: 'flex' }}>{truckId}</div>);
        },
    }, {
        field: 'name',
        headerName: 'Name',
        flex: 1
    }, {
        field: 'latitude',
        headerName: 'Latitude',
        flex: 1
    }, {
        field: 'longitude',
        headerName: 'Longitude',
        flex: 1
    }, {
        field: 'bearing',
        headerName: 'Bearing',
        flex: 1,
        renderCell: (params: any) => {
            const bearing = params.value;
            if (bearing === undefined) return 'Unknown';
            return (
                <div style={{ display: 'flex' }}>
                    {directions[Math.round(bearing / 45) % 8]}
                </div>
            );
        }
    },
    {
        field: 'source',
        headerName: 'Source',
        flex: 1,
        valueFormatter: (params: any) => {
            const source = params.value;
            if (source === undefined) return '';
            return source.split('_').map((word: string) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
        }
    },
    {
        field: 'notificationsEnabled',
        headerName: 'Notifications Enabled',
        flex: 1,
        sortable: false,
        renderCell: (params: GridRenderCellParams) => {
            console.log('notificaitons enabled', params);
            let notificationsEnabled = params.value;
            let vehicle = vehicles.filter(x => x.id === params.row.id)[0] as VehicleTrackingData;
            return (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
                    <Switch
                        checked={notificationsEnabled}
                        onChange={() => onVehicleNotificationsEnebledToggled(vehicle, !notificationsEnabled)}
                    />
                </div>
            );
        }
    },];

    return (
        <div>
            <DataGridPro
                components={{ NoRowsOverlay: () => <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%' }}><div style={{ display: 'inline-block', zIndex: 99, position: 'relative', padding: '5%' }}>No vehicles found. If you&apos;re currently using Motive, Samsara, Platform Science, Revenova, Turvo, Geotab, or another ELD provider, please <a style={{ color: '#90CAF9' }} href={`mailto:${INFO_EMAIL}`}>contact us</a> to get your vehicle data shown here!</div></div> }}
                columns={columns}
                rows={vehicles}
                pageSize={10}
                pagination
                autoHeight
                disableSelectionOnClick
                initialState={{
                    columns: {
                        columnVisibilityModel: (vehicles && vehicles.length > 0) ? {
                            id: vehicles[0].externalId === undefined,
                            externalId: vehicles[0].externalId !== undefined,
                        } : {
                            id: false,
                        },
                    },
                }}
            />
        </div>
    );
};
