import {
    AlertsViewAction,
    ReceivePossibleGovernmentalAlertNames
} from '../actions/AlertsView';
import { AlertsViewState } from '../types';

export function alertsView(state: AlertsViewState, action: typeof AlertsViewAction.actions): AlertsViewState {
    if (typeof state === 'undefined') {
        return {};
    }

    switch (action.type) {
        case ReceivePossibleGovernmentalAlertNames.type:
            return { ...state, possibleGovernmentalAlertNames: action.alerts };
        default: return state;
    }
}
