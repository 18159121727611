import { DashboardViewAction, SetSelectedRoute, SetSelectedVehicle, SetSelectedView, SetSelectedOverlay, DashboardSetSelectedMap, DashboardSetPaused, DashboardSetTileOpacity } from 'src/actions/DashboardView';
import { DashboardView } from 'src/components/Client/Dashboard/ImpactDetailView';
import { DashboardViewState } from '../types/DashboardView';
import { getClientImpactSections } from 'src/components/Client/Impact/sections';

const cachedSelectedViewKey = 'lastDashboardViewState';

let impactMapSections = getClientImpactSections(false, false);
let impactMapSection = impactMapSections[0];
let firstImpactMapType = impactMapSection.mapTypes[0];
const initialDialogState: DashboardViewState = {
    selectedView: localStorage.getItem(cachedSelectedViewKey) as DashboardView || 'route',
    selectedOverlay: 'impact',
    selectedRoute: undefined,
    selectedVehicle: undefined,
    tileOpacity: 1,
    paused: false,
    selectedMapCategorySlug: impactMapSection.slug,
    selectedMapType: firstImpactMapType,
};

export function dashboardView(state: DashboardViewState, action: typeof DashboardViewAction.actions): DashboardViewState {
    if (state === undefined) {
        return initialDialogState;
    }

    switch (action.type) {
        case SetSelectedView.type:
            localStorage.setItem(cachedSelectedViewKey, action.selectedView);
            return { ...state, selectedView: action.selectedView };
        case SetSelectedOverlay.type:
            return { ...state, selectedOverlay: action.selectedOverlay };
        case SetSelectedRoute.type:
            return { ...state, selectedRoute: action.selectedRoute };
        case SetSelectedVehicle.type:
            return { ...state, selectedVehicle: action.selectedVehicle ? { ...action.selectedVehicle } : undefined };
        case DashboardSetSelectedMap.type:
            return {
                ...state,
                selectedMapCategorySlug: action.selectedMapCategory.slug,
                selectedMapType: action.selectedMapType,
            };
        case DashboardSetTileOpacity.type:
            return { ...state, tileOpacity: action.tileOpacity };
        case DashboardSetPaused.type:
            return { ...state, paused: action.paused };
        default: return state;
    }
}
