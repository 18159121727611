import LogoutComponent from '../components/LogoutComponent';
import { StoreState } from '../types';
import { connect } from 'react-redux';
import 'redux-thunk';
import {createLogoutAction} from '../actions/User';
import {ThunkDispatch} from 'redux-thunk';
import {Action} from 'redux';

export function mapStateToProps(state: StoreState) {
    return {
        token: state.user.token
    };
}

export function mapDispatchToProps(dispatch: ThunkDispatch<StoreState, void, Action<any>>) {
    return {
        logOut: (token: string) => dispatch(createLogoutAction(token)),
        toggleMenu: () => {},
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(LogoutComponent);
