import * as React from 'react';
import { useRef, useState } from 'react';
import { calculateCostOfConfig, configForPermission, CostCalculationResults, MAX_CALLS, ServiceConfig } from './configs';
import {
    Button,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Slider,
    TextField,
    Typography
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { isTilingPermission, PermissionName } from '../../../types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { formattedAsPrice } from '../../Client/Account';
import { ThemeProvider } from '@mui/material/styles';
import { lightModeTheme } from '../../../util';

interface BoxInfo {
    id: number;
    service?: string;
    timeframe?: string;
    historicalPeriod?: string;

    config?: ServiceConfig;
    calls: number;
}

function newBoxInfo(id: number): BoxInfo {
    return { id, calls: 0 };
}

interface BoxProps extends BoxInfo {
    serviceIndex: number;
    onDeletePressed: () => void;
    onServiceSelected: (service: string) => void;
    onTimeframeSelected: (timeframe: string) => void;
    onHistoricalPeriodSelected: (historicalPeriod: string) => void;
    onCallsChanged: (calls: number) => void;
}

function blurbForService(service?: string): string {
    switch (service) {
        case 'impact':
            return 'Weather boiled down to a simple risk score';
        case 'weather':
            return 'Curated and digestible weather variables';
        case 'rightroute_standard':
            return 'We supply the routing and return the weather delay';
        case 'rightroute_custom':
            return 'You supply the routing and we return the weather delay';
        case 'rightroute_range':
            return 'You supply the time window of departure, we return the chances of a delay';
        case 'impact_tiles':
            return 'Includes all of the Impact Indices';
        case 'weather_tiles':
            return 'Includes classic radar and weather variables';
        default:
            return 'Select a service';
    }
}

function labelForHistoricalPeriod(historicalPeriod?: string): string {
    const currentYear = new Date().getFullYear();
    switch (historicalPeriod) {
        case '1year':
            return `${currentYear}`;
        case '5year':
            return `${currentYear - 5} - ${currentYear - 1}`;
        case 'full':
            return `Before ${currentYear - 5}`;
        default:
            return '';
    }
}

function isHistoricalAvailableForService(service?: string): boolean {
    switch (service) {
        case 'impact':
        case 'weather':
        case 'rightroute_standard':
        case 'rightroute_custom':
            return true;
        default:
            return false;
    }
}

function hasDifferentPricingBasedOffPeriod(service?: string): boolean {
    switch (service) {
        case 'rightroute_standard':
        case 'rightroute_custom':
            return true;
        default:
            return false;
    }
}

function getPermissionName(boxInfo: BoxInfo): PermissionName | undefined {
    if (boxInfo.service === 'rightroute_standard') {
        if (boxInfo.timeframe === 'forecast') {
            return PermissionName.RIGHTROUTE_FORECAST_STANDARD;
        } else if (boxInfo.timeframe === 'historical') {
            return PermissionName.RIGHTROUTE_HISTORICAL_STANDARD;
        }
    } else if (boxInfo.service === 'rightroute_custom') {
        if (boxInfo.timeframe === 'forecast') {
            return PermissionName.RIGHTROUTE_FORECAST_CUSTOM;
        } else if (boxInfo.timeframe === 'historical') {
            return PermissionName.RIGHTROUTE_HISTORICAL_CUSTOM;
        }
    } else if (boxInfo.service === 'rightroute_range') {
        return PermissionName.RIGHTROUTE_FORECAST_RANGE;
    } else if (boxInfo.service === 'impact') {
        if (boxInfo.timeframe === 'forecast') {
            return PermissionName.IMPACT;
        } else if (boxInfo.timeframe === 'historical') {
            return PermissionName.HISTORICAL_IMPACT;
        }
    } else if (boxInfo.service === 'weather') {
        if (boxInfo.timeframe === 'forecast') {
            return PermissionName.STANDARD_WEATHER;
        } else if (boxInfo.timeframe === 'historical') {
            return PermissionName.HISTORICAL_STANDARD_WEATHER;
        }
    } else if (boxInfo.service === 'impact_tiles') {
        return PermissionName.IMPACT_TILES;
    } else if (boxInfo.service === 'weather_tiles') {
        return PermissionName.WEATHER_TILES;
    }

    return undefined;
}

function callsForSlider(value: number, callsMultiplier: number) {
    const scaledValue = Math.pow(value / callsMultiplier, 3);
    return Math.floor(scaledValue * callsMultiplier * 1000000);
}

function valueForCalls(calls: number, callsMultiplier: number) {
    const scaledValue = calls / (callsMultiplier * 1000000);
    return callsMultiplier * Math.pow(scaledValue, 1 / 3);
}

// e.g. 1000000 => 1M
function numberAsAbbreviatedCalls(n: number) {
    if (n >= 1000000 && n % 1000000 === 0) {
        return '' + (n / 1000000).toFixed(0) + 'M';
    } else if (n >= 1000 && n % 1000 === 0) {
        return '' + (n / 1000).toFixed(0) + 'K';
    } else {
        return '' + n;
    }
}

export const Spacer = (props: { width?: number; stretch?: boolean }) => (
    <div
        style={{ display: 'inline-block', width: props.width ?? 25, flexGrow: (props.stretch === true ? 1 : undefined) }}>
        &nbsp;
    </div>
);

interface SimpleRow {
    name: string;
    costPer1k: number;
    charge: number;
}

export const ServiceBox = (props: BoxProps) => {
    const serviceBlurb = blurbForService(props.service);

    let historicalPeriodDropdown: JSX.Element | undefined;
    if (isHistoricalAvailableForService(props.service) && hasDifferentPricingBasedOffPeriod(props.service) && props.timeframe === 'historical') {
        historicalPeriodDropdown = (
            <FormControl style={{ width: 180 }}>
                <InputLabel shrink id={'timeframe-label'}>Historical Period</InputLabel>
                <Select
                    variant={'standard'}
                    labelId={'timeframe-label'}
                    onChange={event => props.onHistoricalPeriodSelected(event.target.value as string)}
                    value={props.historicalPeriod}
                >
                    {['1year', '5year', 'full'].map(historicalPeriod => (
                        <MenuItem value={historicalPeriod}>{labelForHistoricalPeriod(historicalPeriod)}</MenuItem>
                    ))}
                </Select>
                <FormHelperText>
                    Which time period will you be pulling most of your data from?
                </FormHelperText>
            </FormControl>
        );
    }

    const col1Heading = hasDifferentPricingBasedOffPeriod(props.service) && props.timeframe === 'historical' ? 'Time Period' : 'Monthly Calls';

    let rows: SimpleRow[] = [];
    let totalCost = 0;
    if (props.config !== undefined) {
        const results = calculateCostOfConfig(props.config, props.calls);
        totalCost = results.totalCost;

        let lastTierMax = '0';
        rows = results.tiers.map(entry => {
            let name: string;
            if (hasDifferentPricingBasedOffPeriod(props.service) && props.timeframe === 'historical') {
                name = labelForHistoricalPeriod(props.historicalPeriod);
            } else {
                const newTierMax = numberAsAbbreviatedCalls(entry.tier.maxCalls);
                if (entry.tier.maxCalls === MAX_CALLS) {
                    name = `${lastTierMax}+ Calls`;
                } else {
                    name = `${lastTierMax} - ${newTierMax} Calls`;
                }

                lastTierMax = newTierMax;
            }

            return {
                name,
                costPer1k: entry.tier.pricePerCall * 1000,
                charge: entry.cost
            };
        });
    }

    const callsMultiplier = props.config?.callsMultiplier ?? 1;

    const [isTextfieldFocused, setTextfieldFocused] = React.useState(false);

    const selectedPermission = getPermissionName(props as BoxInfo);
    const defaultTimeframeLabel = selectedPermission === PermissionName.WEATHER_TILES ? 'Live' : 'Forecast';

    return (
        <Paper variant={'outlined'}
            style={{ textAlign: 'left', marginBottom: 40, borderRadius: 31, borderColor: '#238DED', width: 800 }}>
            <div style={{ display: 'flex', alignItems: 'top', padding: '20px 30px' }}>
                <Typography variant={'h6'} style={{ display: 'inline-block', alignSelf: 'center' }}>
                    Service {props.serviceIndex + 1}
                </Typography>

                <Spacer stretch />

                <FormControl style={{ width: 200 }}>
                    <InputLabel shrink id={'service-type-label'}>Service Type</InputLabel>
                    <Select
                        variant={'standard'}
                        labelId={'service-type-label'}
                        onChange={event => props.onServiceSelected(event.target.value as string)}
                        value={props.service}
                    >
                        <MenuItem value={'rightroute_standard'}>RightRoute - Standard</MenuItem>
                        <MenuItem value={'rightroute_custom'}>RightRoute - Custom</MenuItem>
                        <MenuItem value={'rightroute_range'}>RightRoute - Range</MenuItem>
                        <MenuItem value={'impact'}>Impact</MenuItem>
                        <MenuItem value={'weather'}>Weather</MenuItem>
                        <MenuItem value={'impact_tiles'}>Impact Maps</MenuItem>
                        <MenuItem value={'weather_tiles'}>Weather Maps</MenuItem>
                    </Select>
                    <FormHelperText>{serviceBlurb}</FormHelperText>
                </FormControl>

                <Spacer />

                <FormControl>
                    <InputLabel shrink id={'timeframe-label'}>Timeframe</InputLabel>
                    <Select
                        variant={'standard'}
                        labelId={'timeframe-label'}
                        onChange={event => props.onTimeframeSelected(event.target.value as string)}
                        value={isHistoricalAvailableForService(props.service) ? props.timeframe : 'forecast'}
                        disabled={!isHistoricalAvailableForService(props.service)}
                    >
                        <MenuItem value={'forecast'}>{defaultTimeframeLabel}</MenuItem>
                        <MenuItem value={'historical'}>Historical</MenuItem>
                    </Select>
                    <FormHelperText>
                        <br />
                        <br />
                    </FormHelperText>
                </FormControl>

                {historicalPeriodDropdown !== undefined && <Spacer />}
                {historicalPeriodDropdown}

                <Spacer />

                <Button color={'primary'} variant={'contained'} style={{ alignSelf: 'center' }}
                    onClick={() => props.onDeletePressed()}>
                    <DeleteIcon />
                </Button>
            </div>

            {props.config && <div style={{ display: 'flex', marginTop: 20, padding: '20px 60px' }}>
                <div style={{ flexGrow: 1 }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant={'body1'}>
                            Calls/Month:
                        </Typography>
                        <Spacer />
                        <TextField
                            variant={'outlined'}
                            value={isTextfieldFocused ? props.calls : `${props.calls.toLocaleString()}`}
                            onFocus={() => setTextfieldFocused(true)}
                            onBlur={() => setTextfieldFocused(false)}
                            onChange={event => props.onCallsChanged(parseInt((event.target.value as string).replace(',', '')) || 0)}
                        />
                    </div>

                    <Slider
                        value={valueForCalls(props.calls, callsMultiplier)}
                        min={0}
                        max={callsMultiplier}
                        step={0.01}
                        onChange={(event, newValue) => props.onCallsChanged(callsForSlider(newValue as number, callsMultiplier))}
                    />
                </div>

                <Spacer />

                <div>
                    <Typography variant={'body1'}>
                        Cost per month:
                    </Typography>

                    <Typography variant={'body1'} color={'textSecondary'}>
                        ${Math.ceil(totalCost).toLocaleString()}
                    </Typography>
                </div>

                <Spacer width={80} />
            </div>}

            {props.config && <TableContainer style={{ margin: '20px 40px', width: 'calc(100% - 80px)' }}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>{col1Heading}</TableCell>
                            <TableCell align="right">Cost/1000 Calls</TableCell>
                            <TableCell align="right">Charge</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow key={row.name}>
                                <TableCell component="th" scope="row">{row.name}</TableCell>
                                <TableCell align="right">{formattedAsPrice(row.costPer1k)}</TableCell>
                                <TableCell align="right">{formattedAsPrice(row.charge)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>}
        </Paper>
    );
};

const TotalCostBox = ({ boxes }: { boxes: BoxInfo[] }) => {
    const totalCost = boxes
        .filter(box => box.config !== undefined)
        .map(box => calculateCostOfConfig(box.config!, box.calls))
        .reduce((accum: number, b: CostCalculationResults) => accum + b.totalCost, 0);

    const minTilingServiceCost = boxes
        .map(box => getPermissionName(box))
        .filter(permission => permission !== undefined && isTilingPermission(permission))
        .reduce((sum: number, permission: PermissionName) => sum + 250, 0);

    const minApiServiceCost = boxes
        .map(box => getPermissionName(box))
        .filter(permission => permission !== undefined && !isTilingPermission(permission))
        .reduce((sum: number, permission: PermissionName) => sum + (permission === PermissionName.RIGHTROUTE_FORECAST_RANGE ? 2000 : 1000), 0);

    const minimumChargeHeading = 'Minimum Charge';

    const minimumCost = minTilingServiceCost + minApiServiceCost;
    const effectiveCost = Math.max(minimumCost, Math.ceil(totalCost));

    return (
        <div style={{ position: 'fixed', left: 8, top: 40, width: 212 }}>
            <Paper variant={'outlined'}
                style={{ textAlign: 'center', backgroundColor: '#238DED', borderRadius: 10, padding: 20 }}>
                <Typography style={{ color: '#FFFFFF' }}>
                    Total cost per month
                </Typography>

                {effectiveCost === minimumCost &&
                    <Typography variant={'body2'} style={{ color: '#FFFFFF', opacity: 0.8 }}>
                        {minimumChargeHeading}
                    </Typography>}

                <Typography variant={'h4'} style={{ color: '#FFFFFF' }}>
                    ${effectiveCost.toLocaleString()}
                </Typography>
            </Paper>
        </div>
    );
};

export const PricingCalculatorEmbed = () => {
    let [boxes, setBoxes] = useState([newBoxInfo(0)]);
    let lastBoxIndex = useRef(0);

    const addNewBox = () => {
        setBoxes([...boxes, newBoxInfo(lastBoxIndex.current + 1)]);
        lastBoxIndex.current += 1;
    };

    const removeBox = (id: number) => {
        setBoxes(boxes.filter(boxProps => boxProps.id !== id));
    };

    const updateBoxInfo = (id: number, props: Partial<BoxInfo>) => {
        const newBoxes = [...boxes];
        const index = boxes.findIndex(boxProps => boxProps.id === id);
        newBoxes[index] = { ...boxes[index], ...props };
        const permissionName = getPermissionName(newBoxes[index]);

        if (permissionName !== undefined) {
            newBoxes[index].config = configForPermission(permissionName, newBoxes[index].historicalPeriod);
        } else {
            newBoxes[index].config = undefined;
        }

        setBoxes(newBoxes);
    };

    const hasSelectedAnyService = boxes.filter(box => box.config !== undefined).length > 0;

    return (
        <ThemeProvider theme={lightModeTheme}>
            <div>
                {hasSelectedAnyService && <TotalCostBox boxes={boxes} />}

                <div style={{
                    textAlign: 'center',
                    margin: '30px 0',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                }}>
                    <Spacer width={232} stretch />
                    <div>
                        {boxes.map((boxProps, index) => (
                            <ServiceBox
                                key={boxProps.id}
                                {...boxProps}
                                serviceIndex={index}
                                onDeletePressed={() => removeBox(boxProps.id)}
                                onServiceSelected={(service: string) => updateBoxInfo(boxProps.id, {
                                    service,
                                    timeframe: 'forecast',
                                    historicalPeriod: '1year'
                                })}
                                onTimeframeSelected={(timeframe: string) => updateBoxInfo(boxProps.id, {
                                    timeframe,
                                    historicalPeriod: '1year'
                                })}
                                onHistoricalPeriodSelected={(historicalPeriod: string) => updateBoxInfo(boxProps.id, { historicalPeriod })}
                                onCallsChanged={(calls: number) => updateBoxInfo(boxProps.id, { calls })}
                            />
                        ))}
                        <br />
                        <Button variant={'outlined'} startIcon={<AddIcon />} onClick={() => addNewBox()}>
                            Add Another Service
                        </Button>
                    </div>
                    <Spacer stretch />
                </div>
            </div>
        </ThemeProvider>
    );
};
