import * as React from 'react';
import './App.css';
import './styleguide.css';
import { Redirect, Route, withRouter } from 'react-router';
import LoginForm from './containers/LoginForm';
import LogoutComponent from './containers/LogoutComponent';
import { StoreState, UserState } from './types';
import { connect } from 'react-redux';
import { ClientLayout } from './components/Client/ClientLayout';
import { RouteComponentProps } from 'react-router-dom';
import { Location } from 'history';
import ClientRadarPage from './containers/Client/ClientRadarPage';
import ClientImpactPage from './containers/Client/ClientImpactPage';
import ClientAlertsPage from './containers/Client/ClientAlertsPage';
import ClientAccountPage from './containers/Client/ClientAccountPage';
import ResetPasswordForm from './containers/ResetPasswordForm';
import ClientRoutesPage from './containers/Client/ClientRoutesPage';
import PricingCalculatorEmbed from "./containers/Public/PricingCalculatorEmbed";
import ClientPlacesPage from './containers/Client/ClientPlacesPage';
import ClientDashboardPage from "./containers/Client/ClientDashboardPage";
import ClientHistoricalAccuracyPage from "./containers/Client/ClientHistoricalAccuracyPage";
import RouteReportPage from './containers/Public/RouteReportPage';
import { darkModeTheme } from './util';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import VehicleIntegrationPage from './containers/Public/VehicleIntegrationPage';
import CreateLoginForm from './containers/CreateLoginForm';
import ClientTrafficPage from './containers/Client/ClientTrafficPage';
import GovernmentalAlertsPage from './containers/Public/GovernmentalAlertsPage';
import CogViewerPage from './containers/Internal/CogViewerPage';
import ClientNowcastPage from './containers/Client/ClientNowcastPage';
import FreightWavesDashboardPage from './containers/CustomDashboards/FreightWaves/FreightWavesDashboardPage';

interface Props {
    userState: UserState;
    showImpactTab: boolean;
    location: Location;
    showRoutesTab: boolean;
}

const App = (props: Props) => {
    if ((props.location.pathname.indexOf("/client") === 0 || props.location.pathname.indexOf("/internal") === 0) && props.userState.token === undefined) {
        // fixes issue where having an undefined/invalid token would not cause you to actually get logged out
        return <Redirect to={"/"} />;
    }

    let impactRoutes: JSX.Element[] = [(
        <Route exact path={'/client/impact'}>
            <ClientLayout {...props} component={ClientImpactPage} />
        </Route>
    ), (
        <Route exact path={'/client/impact/:categorySlug/:mapSlug'}>
            <ClientLayout {...props} component={ClientImpactPage} />
        </Route>
    ), (
        <Route path={'/client/impact/:categorySlug/:mapSlug/:modelType'}>
            <ClientLayout {...props} component={ClientImpactPage} />
        </Route>
    )];

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={darkModeTheme}>
                <div className="">
                    <Route path={'/pricing-calculator-embed-20210930'}>
                        <PricingCalculatorEmbed />
                    </Route>

                    <Route path={'/routes/:cacheKey'}>
                        <RouteReportPage />
                    </Route>

                    {/* use /a/ to save on characters for SMS */}
                    <Route path={['/alerts/:governmentalAlertId', '/a/:governmentalAlertId']}>
                        <GovernmentalAlertsPage />
                    </Route>

                    <Route path={'/internal/cog-viewer'}>
                        <CogViewerPage />
                    </Route>

                    <Route path={'/freightwaves/embed'}>
                        <FreightWavesDashboardPage />
                    </Route>

                    <Route path={'/integration/:provider(motive|samsara)'} component={VehicleIntegrationPage} />

                    <Route path={'/client/dashboard'}>
                        <ClientLayout {...props} component={ClientDashboardPage} />
                    </Route>

                    <Route path={'/client/alerts'}>
                        <ClientLayout {...props} component={ClientAlertsPage} />
                    </Route>

                    <Route exact path={'/client/radar'}>
                        <ClientLayout {...props} component={ClientRadarPage} />
                    </Route>
                    <Route path={'/client/radar/:slug'}>
                        <ClientLayout {...props} component={ClientRadarPage} />
                    </Route>
                    <Route path={'/client/traffic'}>
                        <ClientLayout {...props} component={ClientTrafficPage} />
                    </Route>

                    <Route path={'/client/account'}>
                        <ClientLayout {...props} component={ClientAccountPage} />
                    </Route>

                    <Route path={'/client/places'}>
                        <ClientLayout {...props} component={ClientPlacesPage} />
                    </Route>

                    <Route path={'/client/historical-accuracy'}>
                        <ClientLayout {...props} component={ClientHistoricalAccuracyPage} />
                    </Route>

                    <Route exact path={'/client/nowcast'}>
                        <ClientLayout {...props} component={ClientNowcastPage} />
                    </Route>

                    {props.showImpactTab && impactRoutes}
                    {props.showRoutesTab && <Route exact path={'/client/routes'}>
                        <ClientLayout {...props} component={ClientRoutesPage} />
                    </Route>}

                    <Route path={'/setup'} component={CreateLoginForm} />
                    <Route path={'/login'} component={LoginForm} />
                    <Route path={'/reset-password'} component={ResetPasswordForm} />
                    <Route path={'/logout'} component={LogoutComponent} />
                </div>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

function mapStateToProps(state: StoreState, ownProps: RouteComponentProps<any>) {
    return {
        userState: state.user,
        showImpactTab: state.user.showImpactTab,
        showRoutesTab: state.user.showRoutesTab,
        location: ownProps.location,
    };
}

export default withRouter(connect(mapStateToProps)(App));
