import {StoreState} from '../../types';
import { connect } from 'react-redux';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {ThunkDispatch} from 'redux-thunk';
import {Action} from 'redux';
import { RouteReportPage } from 'src/components/Public/RouteReports';

export function mapStateToProps(state: StoreState, _ownProps: RouteComponentProps<any>) {
    return {
        cacheKey: _ownProps.match.params.cacheKey,
    };
}

export function mapDispatchToProps(dispatch: ThunkDispatch<StoreState, void, Action<any>>) {
    return {};
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RouteReportPage));