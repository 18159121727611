import { ImpactTileset, ImpactTilesetsByIndex, WeatherTilesetsByIndex } from '../../../types';
import { ImpactMapType, MapType } from '../../../types/MapType';

export interface ImpactMapSection {
    key: string;
    title: string;
    mapTypes: MapType[];
    slug: string;
}

export function getTimesForMapType(
    mapType: ImpactMapType,
    impactTilesets: ImpactTilesetsByIndex | undefined,
    weatherTilesets: WeatherTilesetsByIndex | undefined
): ImpactTileset[] {
    let tilesets = undefined;
    tilesets = impactTilesets?.[mapType.ratingKey];
    if (tilesets === undefined) {
        tilesets = weatherTilesets?.[mapType.ratingKey];
    }
    if (tilesets === undefined) {
        return [];
    }

    const currentTime = new Date().getTime();
    const startTime = Math.floor(currentTime / 3600000) * 3600000;
    let forecastHours = 168;
    if (mapType.ratingKey === 'wildfire_spread') {
        forecastHours = 24;
    } else if (mapType.ratingKey === 'wildfire_conditions') {
        forecastHours = 144;
    }
    const endTimeExclusive = startTime + forecastHours * 3600000;
    // chop off any times from the model run that are before now or later than endTimeExclusive hour from now
    return tilesets.filter((tileset: ImpactTileset) => {
        return tileset.time.getTime() >= startTime && tileset.time.getTime() < endTimeExclusive;
    });
}

export const getClientImpactSections = (showDisruptionIndex: boolean, showWildfireIndices: boolean) => {
    let ratingsSection: ImpactMapSection = {
        key: 'rating',
        title: 'Impact Indices',
        slug: 'impact-indices',
        mapTypes: []
    };

    ratingsSection.mapTypes.push({
        title: 'Road Conditions Index',
        slug: 'road-conditions',
        ratingKey: 'road',
    });

    if (showDisruptionIndex) {
        ratingsSection.mapTypes.push({
            title: 'Business Disruption Index',
            slug: 'business-disruption',
            ratingKey: 'disruption',
        });
    }

    ratingsSection.mapTypes.push({
        title: 'Flood Index',
        slug: 'flood',
        ratingKey: 'flood',
    }, {
        title: 'Power Outage Index',
        slug: 'power-outage-v2',
        ratingKey: 'power',
    }, {
        title: 'Life & Property Index',
        slug: 'life-property',
        ratingKey: 'life_property',
    });

    if (showWildfireIndices) {
        ratingsSection.mapTypes.push({
            title: 'Wildfire Spread Index',
            slug: 'wildfire-spread',
            ratingKey: 'wildfire_spread',
        }, {
            title: 'Wildfire Conditions Index',
            slug: 'wildfire-conditions',
            ratingKey: 'wildfire_conditions',
        });
    }

    let weatherSection: ImpactMapSection = {
        key: 'weather',
        title: 'Hyperlocal Weather',
        slug: 'weather-indices',
        mapTypes: []
    };

    weatherSection.mapTypes.push({
        title: 'Temperature',
        slug: 'temperature',
        ratingKey: 'temperature',
    }, {
        title: 'Rain Accumulation',
        slug: 'rain-accumulation',
        ratingKey: 'rain_accumulation',
    }, {
        title: 'Snow Accumulation',
        slug: 'snow-accumulation',
        ratingKey: 'snow_accumulation',
    }, {
        title: 'Wind Speed',
        slug: 'wind-speed',
        ratingKey: 'wind_speed',
    }, {
        title: 'Wind Gust',
        slug: 'wind-gust',
        ratingKey: 'wind_gust',
    });

    const sections = [
        ratingsSection,
        weatherSection,
    ];

    return sections;
};

export const getClientRadarSection = () => {
    let radarSection: ImpactMapSection = {
        key: 'radar',
        title: 'Radar',
        slug: 'radar',
        mapTypes: []
    };

    radarSection.mapTypes.push({
        title: 'Radar',
        slug: 'precipitation-radar',
        ratingKey: 'radar',
    });
    return radarSection;
};
