import { RatingKey } from './RatingKey';

export interface Legend {
    label: string;
    image: string;
}

export enum MaskType {
    Off = 0,
    Labels,
    LabelsAndWater
}

export interface ImpactMapType {
    title: string;
    slug: string;
    ratingKey: RatingKey;
}

export type MapType = ImpactMapType;

