import React from "react";

const getRemainingTime = (lastTriggeredTime: number, throttleMs: number) => {
    const elapsedTime = Date.now() - lastTriggeredTime;
    const remainingTime = throttleMs - elapsedTime;

    return (remainingTime < 0) ? 0 : remainingTime;
};

export type useThrottledValueProps<T> = {
    value: T;
    throttleMs: number;
};

export const useThrottledValue = <T,>({
    value,
    throttleMs,
}: useThrottledValueProps<T>) => {
    const [throttledValue, setThrottledValue] = React.useState<T>(value);
    const lastTriggered = React.useRef<number>(Date.now());
    const timeoutRef = React.useRef<NodeJS.Timeout | null>(null);

    const cancel = React.useCallback(() => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
            timeoutRef.current = null;
        }
    }, []);

    React.useEffect(() => {
        let remainingTime = getRemainingTime(lastTriggered.current, throttleMs);

        if (remainingTime === 0) {
            lastTriggered.current = Date.now();
            setThrottledValue(value);
            cancel();
        } else if (!timeoutRef.current) {
            timeoutRef.current = setTimeout(() => {
                remainingTime = getRemainingTime(lastTriggered.current, throttleMs);

                if (remainingTime === 0) {
                    lastTriggered.current = Date.now();
                    setThrottledValue(value);
                    cancel();
                }
            }, remainingTime);
        }

        return cancel;
    }, [cancel, throttleMs, value]);

    return throttledValue;
};
