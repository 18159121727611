import * as React from 'react';
import './index.css';
import { BasicLatLng, LocationData, RoadClosureData, RoadStatusData, RoadWorkData, SelectedCityState, SpecialEventData, TrafficCongestionData, TrafficIncidentData, TruckWarningData, UserState, WeatherStationData } from '../../../types';
import { LocationSelectionComponent } from '../../shared/LocationSelectionComponent';
import { TrafficCalloutView } from './TrafficCalloutView';
import { MaskType } from '../../../types/MapType';
import PortalTour from '../PortalTour';
import { Config } from 'src/components/shared/useConfig';
import { RouteAndMarkerMap, defaultRouteAndMarkerMapProps } from '../../shared/RouteAndMarkerMap';
import { DashboardOverlay } from '../Dashboard';
import { TrafficLegend } from './TrafficLegend';
import { EventDescriptionComponent } from '../Impact/EventDescriptionComponent';

interface Props {
    selectedCityState: SelectedCityState;
    userData?: UserState;

    center?: BasicLatLng;
    zoomLevel: number;

    isRoadStatusVisible: boolean;
    isRoadWorkVisible: boolean;
    isRoadClosuresVisible: boolean;
    isSpecialEventsVisible: boolean;
    isTrafficCongestionVisible: boolean;
    isTrafficIncidentsVisible: boolean;
    isTruckWarningsVisible: boolean;
    isWeatherStationsVisible: boolean;

    roadStatus?: RoadStatusData[];
    roadWork?: RoadWorkData[];
    roadClosures?: RoadClosureData[];
    specialEvents?: SpecialEventData[];
    trafficCongestion?: TrafficCongestionData[];
    trafficIncidents?: TrafficIncidentData[];
    truckWarnings?: TruckWarningData[];
    weatherStations?: WeatherStationData[];

    selectedRoadStatus?: RoadStatusData;
    selectedRoadWork?: RoadWorkData;
    selectedRoadClosure?: RoadClosureData;
    selectedSpecialEvent?: SpecialEventData;
    selectedTrafficCongestion?: TrafficCongestionData;
    selectedTrafficIncident?: TrafficIncidentData;
    selectedTruckWarning?: TruckWarningData;
    selectedWeatherStation?: WeatherStationData;

    onCitySelected: (city: LocationData | undefined) => void;
    onCitySaved: (city: LocationData) => void;
    onCityUnsaved: (city: LocationData) => void;
    onCitySaveErrorDismissed: (city: LocationData) => void;

    onCenterChanged: (center: BasicLatLng) => void;
    onZoomLevelChanged: (zoomLevel: number) => void;

    onTrafficLayerSelected: (trafficLayer: string) => void;

    onRoadStatusSelected: (roadStatus?: RoadStatusData) => void;
    onRoadWorkSelected: (roadWork?: RoadWorkData) => void;
    onRoadClosureSelected: (roadClosure?: RoadClosureData) => void;
    onSpecialEventSelected: (specialEvent?: SpecialEventData) => void;
    onTrafficCongestionSelected: (trafficCongestion?: TrafficCongestionData) => void;
    onTrafficIncidentSelected: (trafficIncident?: TrafficIncidentData) => void;
    onTruckWarningSelected: (truckWarning?: TruckWarningData) => void;
    onWeatherStationSelected: (weatherStation?: WeatherStationData) => void;
    onClearSelectedRoadCondition: () => void;
}

export class ClientTrafficPageInner extends React.Component {
    props: Props;

    state: {
        runTour: boolean;
        layer: string;
    };

    constructor(props: Props) {
        super(props);

        this.state = {
            runTour: false,
            layer: 'traffic',
        };
    }

    componentDidMount(): void {
        super.componentDidMount?.();

        this.setState({
            ...this.state,
            runTour: Config.getBoolean(Config.Key.PortalTourComplete) === false
        });
        this.props.onTrafficLayerSelected('traffic');
    }

    calloutViewCoordinateSelected(coord: google.maps.LatLng) {
        let city: LocationData = {
            id: undefined,
            zip: '00000',
            name: '',
            latitude: coord.lat(),
            longitude: coord.lng(),
            needsGeocoding: true,
            impactSummaries: []
        };

        this.props.onCitySelected(city);
    }

    render() {
        const { userData, selectedCityState } = this.props;
        const { selectedCity } = selectedCityState;

        if (userData?.portalToken === undefined) {
            return <div />;
        }

        const trafficDescriptionComponent = (
            <div>
                <EventDescriptionComponent
                    allowClose={true}

                    selectedRoadStatus={this.props.selectedRoadStatus}
                    selectedRoadWork={this.props.selectedRoadWork}
                    selectedRoadClosure={this.props.selectedRoadClosure}
                    selectedSpecialEvent={this.props.selectedSpecialEvent}
                    selectedTrafficCongestion={this.props.selectedTrafficCongestion}
                    selectedTrafficIncident={this.props.selectedTrafficIncident}
                    selectedTruckWarning={this.props.selectedTruckWarning}
                    selectedWeatherStation={this.props.selectedWeatherStation}

                    isRoadStatusVisible={this.props.isRoadStatusVisible}
                    isRoadWorkVisible={this.props.isRoadWorkVisible}
                    isRoadClosuresVisible={this.props.isRoadClosuresVisible}
                    isSpecialEventsVisible={this.props.isSpecialEventsVisible}
                    isTrafficCongestionVisible={this.props.isTrafficCongestionVisible}
                    isTrafficIncidentsVisible={this.props.isTrafficIncidentsVisible}
                    isTruckWarningsVisible={this.props.isTruckWarningsVisible}
                    isWeatherStationsVisible={this.props.isWeatherStationsVisible}

                    onClearSelectedRoadCondition={() => this.props.onClearSelectedRoadCondition()}
                />
            </div>
        );

        return (
            <div className={"ClientAlertsPage"}>
                {trafficDescriptionComponent}
                <RouteAndMarkerMap
                    {...defaultRouteAndMarkerMapProps}

                    portalToken={userData.portalToken}
                    initialCenter={this.props.center}
                    // initially show the whole conus
                    initialBounds={{ sw: { lat: 24.396308, lng: -125.0 }, ne: { lat: 49.384358, lng: -66.93457 } }}
                    zoomLevel={this.props.zoomLevel}
                    minZoom={5}
                    maxZoom={17}
                    maskType={MaskType.Labels}

                    mapTypeControlOptions={{
                        ...defaultRouteAndMarkerMapProps.mapTypeControlOptions,
                        mapTypeIds: ["weather_optics"],
                    }}

                    isRoadStatusVisible={this.state.layer === 'traffic-road-status' && this.props.isRoadStatusVisible}
                    roadStatus={this.props.roadStatus}
                    selectedRoadStatus={this.props.selectedRoadStatus}
                    onRoadStatusSelected={(roadStatus) => this.props.onRoadStatusSelected(roadStatus)}

                    isRoadWorkVisible={this.state.layer === 'traffic-road-work' && this.props.isRoadWorkVisible}
                    roadWork={this.props.roadWork}
                    selectedRoadWork={this.props.selectedRoadWork}
                    onRoadWorkSelected={(roadWork) => this.props.onRoadWorkSelected(roadWork)}

                    isRoadClosuresVisible={this.state.layer === 'traffic-road-closures' && this.props.isRoadClosuresVisible}
                    roadClosures={this.props.roadClosures}
                    selectedRoadClosure={this.props.selectedRoadClosure}
                    onRoadClosureSelected={(roadClosure) => this.props.onRoadClosureSelected(roadClosure)}

                    isSpecialEventsVisible={this.state.layer === 'traffic' && userData?.show511InPortal && this.props.isSpecialEventsVisible}
                    specialEvents={this.props.specialEvents}
                    selectedSpecialEvent={this.props.selectedSpecialEvent}
                    onSpecialEventSelected={(specialEvent) => this.props.onSpecialEventSelected(specialEvent)}

                    showTrafficFlow={this.state.layer === 'traffic'}

                    showTrafficCongestion={this.state.layer === 'traffic' && userData?.show511InPortal && this.props.isTrafficCongestionVisible}
                    trafficCongestion={this.props.trafficCongestion}
                    selectedTrafficCongestion={this.props.selectedTrafficCongestion}
                    onTrafficCongestionSelected={(trafficCongestion) => this.props.onTrafficCongestionSelected(trafficCongestion)}

                    showLegacyHereTrafficIncidents={this.state.layer === 'traffic-incidents' && this.props.isTrafficIncidentsVisible}
                    showTrafficIncidents={this.state.layer === 'traffic-incidents' && userData.show511InPortal && this.props.isTrafficIncidentsVisible}
                    trafficIncidents={this.props.trafficIncidents}
                    selectedTrafficIncident={this.props.selectedTrafficIncident}
                    onTrafficIncidentSelected={(trafficIncident) => this.props.onTrafficIncidentSelected(trafficIncident)}

                    isTruckWarningsVisible={this.state.layer === 'traffic-truck-warnings' && this.props.isTruckWarningsVisible}
                    truckWarnings={this.props.truckWarnings}
                    selectedTruckWarning={this.props.selectedTruckWarning}
                    onTruckWarningSelected={(truckWarning) => this.props.onTruckWarningSelected(truckWarning)}

                    isWeatherStationsVisible={this.state.layer === 'traffic-road-status' && this.props.isWeatherStationsVisible}
                    weatherStations={this.props.weatherStations}
                    selectedWeatherStation={this.props.selectedWeatherStation}
                    onWeatherStationSelected={(weatherStation) => this.props.onWeatherStationSelected(weatherStation)}

                    selectedCity={selectedCity}

                    onMapClicked={(coord) => this.calloutViewCoordinateSelected(coord)}
                    onCenterChanged={(center) => this.props.onCenterChanged(center)}
                    onZoomLevelChanged={(zoomLevel: number) => this.props.onZoomLevelChanged(zoomLevel)}
                >
                    {selectedCity && <TrafficCalloutView
                        selectedCityState={selectedCityState}
                        lat={selectedCity.latitude}
                        lng={selectedCity.longitude}

                        onClose={() => this.props.onCitySelected(undefined)}
                        saveLocationTapped={(city) => this.props.onCitySaved(city)}
                        unsaveLocationTapped={(city) => this.props.onCityUnsaved(city)}
                        onCitySaveErrorDismissed={(city) => this.props.onCitySaveErrorDismissed(city)}
                    />}
                </RouteAndMarkerMap>

                <TrafficLegend
                    selectedOverlay={this.state.layer as DashboardOverlay}
                    zoomLevel={this.props.zoomLevel}
                    show511InPortal={userData?.show511InPortal}

                    onOverlayChanged={(overlay: DashboardOverlay) => {
                        this.setState({ ...this.state, layer: overlay });
                        this.props.onTrafficLayerSelected(overlay);
                    }}
                />

                <div className={"LocationSelectionComponent-Container"}>
                    <LocationSelectionComponent
                        user={userData}
                        savedCities={userData.cities}
                        selectedCity={selectedCity}
                        onCitySelected={city => this.props.onCitySelected(city)}
                        allowSelectingLocations
                    />
                </div>

                <PortalTour run={this.state.runTour} portalTab="TRAFFIC" />
            </div >
        );
    }
}

export const ClientTrafficPage = (props: Props) => {
    return <ClientTrafficPageInner {...props} />;
};