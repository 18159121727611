import React from "react";
import JoyRide, { ACTIONS, EVENTS, STATUS } from "react-joyride";
import { cardBackgroundColor } from "src/constants";
import { Config } from "../shared/useConfig";

const DEFAULT_STATE = {
  run: false,
  continuous: true,
  loading: false,
  stepIndex: 0,
  showSkipButton: true,
  hideBackButton: false,
  spotlightClicks: true,
  disableOverlayClose: true,
  floaterProps: {
    placement: 'right-end'
  },
  locale: {
    last: "End Tour",
    skip: "Skip Tour"
  },
  styles: {
    options: {
      arrowColor: cardBackgroundColor,
      backgroundColor: cardBackgroundColor,
      primaryColor: '#04a0fd',
      textColor: '#e1e1e1',
      zIndex: 1000,
    }
  }
};

const reducer = (state = DEFAULT_STATE, action: any) => {
  switch (action.type) {
    case "START":
      return { ...state, run: true };
    case "STOP":
      return { ...state, run: false };
    case "NEXT_OR_PREV":
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const TOUR_STEPS = {};

TOUR_STEPS["DASHBOARD"] = [
  {
    target: ".DashboardNavLink",
    content: "Stay ahead of operational risks with the Dashboard tab: Easily identify which shipments, locations, and vehicles face the greatest weather-related threats.",
    disableBeacon: true
  },
  {
    target: ".DashboardTabs",
    content: "View map layers that visually highlight alerts and other weather risks. Scroll right for more!",
    disableBeacon: true
  },
  {
    target: ".DashboardViewSelector",
    content: "See individual shipment impacts, operational risks at key locations, hyperlocal weather insights, and live vehicle alerts. Hover over any of them to get more info.",
    disableBeacon: true
  },
  {
    target: ".AssetsNavLink"
  }
];

TOUR_STEPS["ASSETS"] = [
  {
    target: ".AssetsNavLink",
    content: (<div>Add your assets in the Assets tab. Upload bulk lists of locations and shipments. <a style={{"color": "#0d93e4", "textDecoration": "none"}} target="_blank" rel="noreferrer" href="https://weatheroptics.super.site/#89217bf388cf4123a7d05c87be14f31f">Read the user manual</a> to learn more</div>),
    disableBeacon: true
  },
  {
    target: ".RoutesNavLink"
  }
];

TOUR_STEPS["ROUTES"] = [
  {
    target: ".RoutesNavLink",
    content: "Quickly analyze and add single shipments in the Routes tab. Click “Save” to add them to the Dashboard Tab.",
    disableBeacon: true
  },
  {
    target: ".ImpactNavLink"
  },
];

TOUR_STEPS["IMPACT"] = [
  {
    target: ".ImpactNavLink",
    content: (<div>Quickly analyze and add single locations in the Impact tab. Just click the map or search bar to find your location, then click “Save Location”. <a style={{"color": "#0d93e4", "textDecoration": "none"}} target="_blank" rel="noreferrer" href="https://weatheroptics.super.site/#0b365ed268e84aedaecb1d044a8e1df4">Read the user manual</a> to learn more about this tab.</div>),
    disableBeacon: true
  },
  {
    target: ".MapLayers",
    content: "View individual map layers for Alerts, Radar, & Traffic tabs."
  },
  {
    target: ".TawkButton",
    content: "Stuck? Contact us with any questions.",
  },
  {
    target: ".AccountNavLink"
  }
];

TOUR_STEPS["ACCOUNT"] = [
  {
    target: ".AccountNavLink",
    content: 'You can access this tour again at any time from the “Account” page.',
    disableBeacon: true
  }
];


const PortalTour = ({ run, portalTab }: { run: boolean, portalTab: string }) => {
  const [tourState, dispatch] = React.useReducer(reducer, { ...DEFAULT_STATE, steps: TOUR_STEPS[portalTab] });
  const [, setPortalTourComplete] = Config.useBooleanConfig(Config.Key.PortalTourComplete);

  const handleTourCallback = (data: any) => {
    const { action, index, step, type, status } = data;

    if (action === ACTIONS.CLOSE || (status === STATUS.SKIPPED && tourState.run) || status === STATUS.FINISHED) {
      dispatch({ type: "STOP" });
      setPortalTourComplete(true);
    } else if (type === EVENTS.STEP_BEFORE && step.content === undefined) {
      dispatch({ type: "STOP" });
      document.querySelector(step.target).click();
    } else if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
      if (step.target === '.DashboardViewSelector') {
        // if the user changed the selected view change it back to routes so that the timeframe and grid controls tour elements will be shown
        const selectInput: HTMLInputElement = (document.querySelector('.DashboardViewSelector')?.childNodes[1] as HTMLInputElement);
        if (selectInput.value !== 'route') {
          Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, "value")?.set?.call(selectInput, "route");
          selectInput.dispatchEvent(new Event("input", { bubbles: true }));
        }
        selectInput.dispatchEvent(new MouseEvent("mousedown", { bubbles: true }));
      }
      dispatch({ type: "NEXT_OR_PREV", payload: { stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) } });
    }
  };

  React.useEffect(() => {
    if (run) dispatch({ type: "START" });
  }, [run]);

  return (
    <JoyRide
      {...tourState}
      disableScrollParentFix={true}
      callback={handleTourCallback}
    />
  );
};

export default PortalTour;
