import { StoreState } from '../types';
import { combineReducers } from 'redux';
import { user } from './User';
import { selectedCity } from './SelectedCity';
import { ratings } from './Ratings';
import { weather } from './Weather';
import { impactView } from './ImpactView';
import { radarView } from './RadarView';
import { alertsView } from './AlertsView';
import { routesView } from './RoutesView';
import { mapView } from "./MapView";
import { placesView } from './PlacesView';
import { dashboardView } from './DashboardView';
import { trafficView } from './TrafficView';
import { nowcastView } from './NowcastView';

export const rootReducer = combineReducers<StoreState>({ user, weather, selectedCity, ratings, impactView, radarView, alertsView, routesView, mapView, placesView, dashboardView, trafficView, nowcastView });
