import * as React from 'react';
import { DriverNotification, DriverNotificationFrequency, DriverNotificationType, LocationData, UserState, VehicleTrackingData } from '../../../types';
import { Button, MenuItem, Select, Tab, Tabs, TextField, Typography } from '@mui/material';
import { getFormattedDepartureTime, RouteData } from '../../../types/routes';
import AddIcon from '@mui/icons-material/Add';
import { LocationsTable } from './LocationsTable';
import { RoutesTable } from './RoutesTable';
import { UploadDialog } from './UploadDialog';
import { BatchType, PlacesViewState } from '../../../types/PlacesView';
import { AutoShowConfirmationDialog, AutoShowErrorDialog } from '../../shared/AutoShowErrorDialog';
import { EditRouteDialog } from './EditRouteDialog';
import { EditLocationDialog } from './EditLocationDialog';
import { VehiclesTable } from './VehiclesTable';
import PortalTour from '../PortalTour';
import { EditDriverNotificationDialog } from './EditDriverNotificationsDialog';
import { DriverNotificationsTable } from './DriverNotificationsTable';
import { MessagesTable } from './MessagesTable';
import { Config } from 'src/components/shared/useConfig';
import { loadPossibleGovernmentalAlertNamesIfNeeded } from 'src/actions/AlertsView';

export interface Props extends PlacesViewState {
    userData: UserState;
    selectedTab: number;
    cities: LocationData[];
    savedRoutes: RouteData[];
    vehicles: VehicleTrackingData[];
    driverNotifications: DriverNotification[];
    alerts: string[];
    editRouteResponse: RouteData | Error[] | undefined;
    editLocationResponse: LocationData | Error[] | undefined;
    editDriverNotificationResponse: DriverNotification | Error[] | undefined;

    setSelectedTab: (selectedTab: number) => void;
    onUploadDialogOpened: (batchType: BatchType) => void;
    onUploadDialogClosed: () => void;
    onBatchUploadInitiated: (blob: Blob, filename: string) => void;
    onProcessUploadClicked: (batchUploadId: number) => void;
    onDeleteRouteClicked: (route: RouteData) => void;
    onEditedRouteSaved: (route: RouteData) => void;
    onEditedRouteFinished: () => void;
    onDeleteLocationClicked: (location: LocationData) => void;
    onEditedLocationSaved: (location: LocationData) => void;
    onEditedLocationFinished: () => void;
    onCreatedLocationSaved: (location: LocationData) => void,
    onCreatedDriverNotificationSaved: (driverNotification: DriverNotification) => void,
    onDeleteDriverNotificationClicked: (driverNotification: DriverNotification) => void
    onEditedDriverNotificationSaved: (driverNotification: DriverNotification) => void,
    onEditedDriverNotificationFinished: () => void,
    setVehicleNotificationsEnabled: (vehicle: VehicleTrackingData, enabled: boolean) => void
}

export const ClientPlacesPage = (props: Props) => {
    const [routeToDelete, setRouteToDelete] = React.useState<RouteData>();
    const [routeToEdit, setRouteToEdit] = React.useState<RouteData>();
    const [locationToDelete, setLocationToDelete] = React.useState<LocationData>();
    const [locationToEdit, setLocationToEdit] = React.useState<LocationData>();
    const [creatingNewLocation, setCreatingNewLocation] = React.useState(false);
    const [driverNotificationToDelete, setDriverNotificationToDelete] = React.useState<DriverNotification>();
    const [driverNotificationToEdit, setDriverNotificationToEdit] = React.useState<DriverNotification | undefined>();
    const [creatingNewDriverNotification, setCreatingNewDriverNotification] = React.useState(false);
    const [driverNotificationType, setDriverNotificationType] = React.useState<DriverNotificationType>('alert');
    const [messageSearch, setMessageSearch] = React.useState('');
    const [runTour, setRunTour] = React.useState(false);

    React.useEffect(() => {
        setRunTour(Config.getBoolean(Config.Key.PortalTourComplete) === false);
    }, []);

    // load governmental alerts if they failed to load on initial load/login
    React.useEffect(() => {
        loadPossibleGovernmentalAlertNamesIfNeeded();
    }, []);

    return (
        <div className={"ClientPlacesPage"} style={{ padding: 40 }}>
            <Typography variant={'h3'} color={'textPrimary'}>Assets</Typography>
            <br />
            <Tabs value={props.selectedTab} onChange={(event, newValue) => props.setSelectedTab(newValue)}
                indicatorColor={'primary'}>
                <Tab label={"Upcoming Routes"} id={'tab-routes'} />
                <Tab label={"Locations"} id={'tab-locations'} />
                <Tab label={"Vehicles"} id={'tab-vehicles'} />
                <Tab label={"Driver Notifications"} id={'tab-driver-notifications'} />
                <Tab label={"Message Log"} id={'tab-messages'} />
            </Tabs>
            <br />
            {props.selectedTab < 2 && <Button
                variant={'contained'}
                color={'primary'}

                onClick={() => props.onUploadDialogOpened(props.selectedTab === 0 ? BatchType.Route : BatchType.Location)}

                // for some reason href is required to make the primary color always show, not only on hover
                href={`javascript:;`}
            >
                <AddIcon />
                Upload CSV
            </Button>}
            {props.selectedTab === 1 && <Button
                variant={'contained'}
                color={'primary'}
                style={{ 'marginLeft': '15px' }}
                onClick={() => {
                    setCreatingNewLocation(true);
                    setLocationToEdit({
                        name: '',
                        latitude: NaN,
                        longitude: NaN,
                        needsGeocoding: true,
                        impactSummaries: []
                    } as LocationData);
                }}
                // for some reason href is required to make the primary color always show, not only on hover
                href={`javascript:;`}
            >
                <AddIcon />
                Create New Location
            </Button>}

            {
                props.selectedTab === 3 && <div style={{ display: 'flex' }}>
                    <Select
                        value={driverNotificationType}
                        variant={'outlined'}
                        style={{ width: 250, height: 40, marginRight: 20 }}
                        onChange={(event) => setDriverNotificationType(event.target.value as DriverNotificationType)}
                    >
                        <MenuItem value={'alert'}>National Weather Service</MenuItem>
                        {/* <MenuItem value={'impact'}>Risk Scores</MenuItem> */}
                    </Select>
                    <Button
                        variant={'contained'}
                        color={'primary'}
                        onClick={() => {
                            setCreatingNewDriverNotification(true);
                            setDriverNotificationToEdit({
                                id: undefined,
                                name: '',
                                status: 'testing',
                                notificationType: driverNotificationType,
                                frequency: 'change' as DriverNotificationFrequency,
                                interval: 30,
                                windowSize: 1,
                                thresholds: [],
                                alerts: [],
                                template: driverNotificationType === 'impact' ?
                                    'Alert:\n{risk scores}(we recommend you add an action you’d like your driver to take such as “reducing speed”)' :
                                    'Alert:\n{event}\n(we recommend you add an action you’d like your driver to take such as “reducing speed”)',
                                urgent: true
                            } as DriverNotification);
                        }}
                        // for some reason href is required to make the primary color always show, not only on hover
                        href={`javascript:;`}
                    >
                        <AddIcon />
                        Create New Driver Notification
                    </Button>
                </div>
            }
            {
                props.selectedTab === 4 && <div style={{ display: 'flex' }}>
                    <TextField
                        id='searchMessages'
                        label='Search Messages'
                        type='text'
                        value={messageSearch}
                        onChange={(event) => {
                            setMessageSearch(event.target.value as string);
                        }} />
                </div>
            }
            <br />
            <br />
            {props.selectedTab === 0 && <RoutesTable routes={props.savedRoutes} onDeleteRouteClicked={setRouteToDelete} onEditRouteClicked={setRouteToEdit} />}
            {props.selectedTab === 1 && <LocationsTable locations={props.cities} onDeleteLocationClicked={setLocationToDelete} onEditLocationClicked={setLocationToEdit} />}
            {props.selectedTab === 2 && <VehiclesTable vehicles={props.vehicles} onVehicleNotificationsEnebledToggled={props.setVehicleNotificationsEnabled} />}
            {props.selectedTab === 3 && <DriverNotificationsTable driverNotifications={props.driverNotifications} onDeleteDriverNotificationClicked={setDriverNotificationToDelete} onEditDriverNotificationClicked={setDriverNotificationToEdit} />}
            {props.selectedTab === 4 && <MessagesTable search={messageSearch} userData={props.userData} />}

            <UploadDialog
                batchType={props.uploadBatchType}
                isOpen={props.isUploadDialogOpen}

                fileName={props.fileName}
                pendingBatchUploadId={props.pendingBatchUploadId}
                isUploading={props.isUploading}
                uploadErrors={props.uploadErrors}
                isProcessing={props.isProcessing}
                processingError={props.processingError}

                onClose={() => props.onUploadDialogClosed()}
                onBatchUploadInitiated={(blob, filename) => props.onBatchUploadInitiated(blob, filename)}
                onProcessUploadClicked={(batchUploadId) => props.onProcessUploadClicked(batchUploadId)}
            />

            <AutoShowConfirmationDialog
                dialogKey={routeToDelete?.id?.toString()}
                title={`Are you sure you want to delete the route from ${routeToDelete?.origin.label} to ${routeToDelete?.destination.label} at ${getFormattedDepartureTime(routeToDelete?.departureTime)}?`}
                confirmationOptionTitle={"Delete"}
                onDismiss={(confirmed: boolean) => {
                    if (confirmed && routeToDelete) {
                        props.onDeleteRouteClicked(routeToDelete);
                    }
                    setRouteToDelete(undefined);
                }}
            />

            <EditRouteDialog
                routeToEdit={routeToEdit}
                editRouteResponse={props.editRouteResponse}
                setRouteToEdit={setRouteToEdit}
                onEditedRouteSaved={props.onEditedRouteSaved}
                onEditedRouteFinished={props.onEditedRouteFinished}
            />

            <AutoShowConfirmationDialog
                dialogKey={locationToDelete?.id?.toString()}
                title={`Are you sure you want to delete ${locationToDelete?.name}?`}
                confirmationOptionTitle={"Delete"}
                onDismiss={(confirmed: boolean) => {
                    if (confirmed && locationToDelete) {
                        props.onDeleteLocationClicked(locationToDelete);
                    }
                    setLocationToDelete(undefined);
                }}
            />

            <EditLocationDialog
                locationToEdit={locationToEdit}
                editLocationResponse={props.editLocationResponse}
                creatingNewLocation={creatingNewLocation}
                setCreatingNewLocation={setCreatingNewLocation}
                onCreatedLocationSaved={props.onCreatedLocationSaved}
                setLocationToEdit={setLocationToEdit}
                onEditedLocationSaved={props.onEditedLocationSaved}
                onEditedLocationFinished={props.onEditedLocationFinished}
            />

            <AutoShowConfirmationDialog
                dialogKey={driverNotificationToDelete?.id?.toString()}
                title={`Are you sure you want to delete ${driverNotificationToDelete?.name}?`}
                confirmationOptionTitle={"Delete"}
                onDismiss={(confirmed: boolean) => {
                    if (confirmed && driverNotificationToDelete) {
                        props.onDeleteDriverNotificationClicked(driverNotificationToDelete);
                    }
                    setDriverNotificationToDelete(undefined);
                }}
            />

            <EditDriverNotificationDialog
                userId={props.userData.id}
                driverNotificationToEdit={driverNotificationToEdit}
                creatingNewDriverNotification={creatingNewDriverNotification}
                editDriverNotificationResponse={props.editDriverNotificationResponse}
                alerts={props.alerts}
                setCreatingNewDriverNotification={setCreatingNewDriverNotification}
                setDriverNotificationToEdit={setDriverNotificationToEdit}
                onCreatedDriverNotificationSaved={props.onCreatedDriverNotificationSaved}
                onEditedDriverNotificationSaved={props.onEditedDriverNotificationSaved}
                onEditedDriverNotificationFinished={props.onEditedDriverNotificationFinished}
            />

            <AutoShowErrorDialog title={"Processing error"} error={props.processingError} />
            <PortalTour run={runTour} portalTab="ASSETS" />
        </div >
    );
};
