import * as React from 'react';
import './index.css';
import { AlertData, BasicLatLng, LoadableResult, LocationData, SelectedCityState, UserState, WeatherData } from '../../../types';
import { LocationSelectionComponent } from '../../shared/LocationSelectionComponent';
import { AlertDetailsComponent } from './AlertDetailsComponent';
import { AlertCalloutView } from './AlertCalloutView';
import { MaskType } from '../../../types/MapType';
import PortalTour from '../PortalTour';
import { Config } from '../../shared/useConfig';
import { RouteAndMarkerMap, defaultRouteAndMarkerMapProps } from '../../shared/RouteAndMarkerMap';
import { AlertsLegend } from './AlertsLegend';
import { Bounds } from 'google-map-react';

interface Props {
    weatherData?: WeatherData;
    selectedCityState: SelectedCityState;
    userData?: UserState;

    center?: BasicLatLng;
    zoomLevel: number;

    governmentalAlerts: LoadableResult<AlertData[]>;

    onCitySelected: (city: LocationData | undefined) => void;
    onCitySaved: (city: LocationData) => void;
    onCityUnsaved: (city: LocationData) => void;
    onCitySaveErrorDismissed: (city: LocationData) => void;

    onCenterChanged: (center: BasicLatLng) => void;
    onZoomLevelChanged: (zoomLevel: number) => void;

    loadGovernmentalAlertsIfNeeded: () => void;
}

export class ClientAlertsPageInner extends React.Component {
    props: Props;

    state: {
        runTour: boolean;
        mapBounds: Bounds | undefined;
    };

    constructor(props: Props) {
        super(props);

        this.state = {
            runTour: false,
            mapBounds: undefined,
        };
    }

    componentDidMount(): void {
        super.componentDidMount?.();

        this.setState({
            ...this.state,
            runTour: Config.getBoolean(Config.Key.PortalTourComplete) === false
        });

        this.props.loadGovernmentalAlertsIfNeeded();
    }

    calloutViewCoordinateSelected(coord: google.maps.LatLng) {
        let city: LocationData = {
            id: undefined,
            zip: '00000',
            name: '',
            latitude: coord.lat(),
            longitude: coord.lng(),
            needsGeocoding: true,
            impactSummaries: []
        };

        this.props.onCitySelected(city);
    }

    render() {
        const { userData, selectedCityState } = this.props;
        const { selectedCity } = selectedCityState;

        if (userData?.portalToken === undefined) {
            return <div />;
        }

        let detailElement: JSX.Element | undefined = undefined;
        if (selectedCity && !selectedCity.needsGeocoding && this.props.weatherData && this.props.weatherData.alerts?.length > 0) {
            detailElement = (
                <AlertDetailsComponent
                    alerts={this.props.weatherData.alerts}
                    location={selectedCity}
                />);
        }

        return (
            <div className={"ClientAlertsPage"}>
                <RouteAndMarkerMap
                    {...defaultRouteAndMarkerMapProps}

                    portalToken={userData.portalToken}
                    initialCenter={this.props.center}
                    // initially show the whole conus
                    initialBounds={{ sw: { lat: 24.396308, lng: -125.0 }, ne: { lat: 49.384358, lng: -66.93457 } }}
                    zoomLevel={this.props.zoomLevel}

                    maskType={MaskType.Labels}

                    isGovernmentalAlertsVisible={true}
                    governmentalAlerts={this.props.governmentalAlerts.value}

                    selectedCity={selectedCity}
                    savedCities={this.props.userData?.cities ?? []}

                    onCitySelected={(city) => this.props.onCitySelected(city)}
                    onMapClicked={(coord) => this.calloutViewCoordinateSelected(coord)}
                    onCenterChanged={(center) => this.props.onCenterChanged(center)}
                    onBoundsChanged={(bounds) => this.setState({ ...this.state, mapBounds: bounds })}
                    onZoomLevelChanged={(zoomLevel: number) => this.props.onZoomLevelChanged(zoomLevel)}
                >
                    {selectedCity && <AlertCalloutView
                        selectedCityState={selectedCityState}
                        lat={selectedCity.latitude}
                        lng={selectedCity.longitude}

                        onClose={() => this.props.onCitySelected(undefined)}
                        saveLocationTapped={(city) => this.props.onCitySaved(city)}
                        unsaveLocationTapped={(city) => this.props.onCityUnsaved(city)}
                        onCitySaveErrorDismissed={(city) => this.props.onCitySaveErrorDismissed(city)}
                    />}
                </RouteAndMarkerMap>

                {/* TODO: figure out how styling should work */}
                <AlertsLegend
                    portalToken={userData.portalToken}
                    mapBounds={this.state.mapBounds}
                />

                <div className={"LocationSelectionComponent-Container"}>
                    <LocationSelectionComponent
                        user={userData}
                        savedCities={userData.cities}
                        selectedCity={selectedCity}
                        onCitySelected={city => this.props.onCitySelected(city)}
                        allowSelectingLocations
                    />
                </div>

                <div className={"AlertDetailCards"}>
                    {detailElement}
                </div>
                <PortalTour run={this.state.runTour} portalTab="ALERTS" />
            </div>
        );
    }
}

export const ClientAlertsPage = (props: Props) => {
    return <ClientAlertsPageInner {...props} />;
};
