import * as React from 'react';
import { isMobile } from "react-device-detect";
import * as Plotly from 'plotly.js';
import Plot from "react-plotly.js";


// WIDGETS
export interface ClientWidgetProps {
    // placed in body of widget.
    children?: any;

    // placed in header, starting from right.
    headerChildren?: any;

    // additional class to namespace styles
    additionalClasses: string;

    title: string | JSX.Element | JSX.Element[] | undefined;
    headerStyles?: React.CSSProperties;
    bodyStyles?: React.CSSProperties;

    compactVersion?: boolean;

    row?: number;
    column?: number;
}

export const WidgetContainer = ({ children, headerChildren, title, additionalClasses, row, column, headerStyles, bodyStyles }: ClientWidgetProps) => {
    let style = {};
    if (row !== undefined) {
        style['grid-row'] = `${row} / ${row + 1}`;
    }
    if (column !== undefined) {
        style['grid-column'] = `${column} / ${column + 1}`;
    }

    let titleElements: JSX.Element[] | undefined;
    if (title === undefined) {
        titleElements = undefined;
    } else if (typeof title === "string") {
        titleElements = [<div key={"widget-title"} className={"ClientWidgetContainer-title"}>{title}</div>];
    } else if (Array.isArray(title)) {
        titleElements = title;
    } else {
        titleElements = [title];
    }

    return (
        <div className={"ClientWidgetContainer " + additionalClasses} style={style}>
            {titleElements !== undefined && <div className={"ClientWidgetContainer-header"} style={{ ...headerStyles }}>
                {titleElements}
                <div className={"ClientWidgetContainer-header-right"}>{headerChildren}</div>
            </div>}
            <div className={"ClientWidgetContainer-body"} style={{ ...bodyStyles }}>
                {children}
            </div>
        </div>
    );
};

export interface GraphSection<T> {
    // data in this section, should be a subset of dataset.allData
    data: T[];

    // hex/rgba color code
    color: string;

    // label, used in legend
    label: string;

    // optional suffix to show in tooltip
    suffix?: string;
}

export interface GraphDataset<T> {
    label: string;

    // all the data points across all sections, used to generate x-axis labels and viewport.
    allData: T[];

    sections: GraphSection<T>[];

    // functions that define how to plot a key/value (by transforming it to a number, which is trivial to plot).
    keyTransform: (point: T) => number;
    valueTransform: (point: T) => number;

    // function that defines how to display a key in user-readable format, for x-axis labels.
    keyDisplayTransform: (point: T) => string;

    // function that defines how to display a value when a user hovers over a datapoint.
    valueDisplayTransform: (point: T) => string;

    // suffix for displaying user-readable values on the y-axis labels. (plotly.js limitation)
    valueSuffix: string;

    rangeMode: 'normal' | 'tozero' | 'nonnegative';
    maxYRange: number;

    type: 'bar' | 'scatter';

    colorFunction?: (point: T) => string;

    timezone?: string;
}

export interface GraphWidgetProps<T> {
    showFullDomain: boolean;
    datasets: GraphDataset<T>[];

    // overrides normal links
    headerChildren?: JSX.Element[];

    zoom?: number;
    tickInterval?: string;
    selectedDataset?: GraphDataset<T>;
    backgroundColor?: string;
    now?: Date;

    onClick?: (event: Plotly.PlotMouseEvent) => void;
}

interface GraphWidgetState<T> {
    selectedDataset: GraphDataset<T>;
    offset: number;
}

export class GraphWidget<T> extends React.Component {
    props: GraphWidgetProps<T> & ClientWidgetProps;

    state: GraphWidgetState<T>;

    constructor(props: GraphWidgetProps<T> & ClientWidgetProps) {
        super(props);

        this.state = { selectedDataset: props.datasets[0], offset: 0 };
    }

    componentDidUpdate(_prevProps: Readonly<GraphWidgetProps<T> & ClientWidgetProps>, prevState: Readonly<GraphWidgetState<T>>, _snapshot?: any): void {
        if (this.props.datasets.indexOf(prevState.selectedDataset) === -1) {
            this.setState({ selectedDataset: this.props.datasets[0] });
        }
    }

    selectDataset(dataset: GraphDataset<T>) {
        this.setState({ ...this.state, selectedDataset: dataset });
    }

    scrollRight() {
        this.setState({ ...this.state, offset: (this.state.offset || 0) + 3 });
    }

    render() {
        let currentDataset = this.props.selectedDataset || this.state.selectedDataset;
        let timePoints = currentDataset.allData;
        let timeValues = currentDataset.allData.map((point: T) => currentDataset.keyTransform(point));

        let zoom = this.props.zoom === undefined ? 0.5 : this.props.zoom;
        // at zoom = 0 let's show all datapoints
        // at zoom = 1 let's show 3
        let rangeLength = Math.floor((1 - zoom) * (timeValues.length - 3) + 3);

        let firstRangeIndex = this.state.offset || 0;
        let lastRangeIndex = Math.min(firstRangeIndex + rangeLength, timeValues.length) - 1;
        firstRangeIndex = lastRangeIndex + 1 - rangeLength; // correct first range index if we capped last range index

        let rangeStart = timeValues[firstRangeIndex];
        let rangeEnd = timeValues[lastRangeIndex];

        // xrange is what controls the 'zoom level' on the plot
        let xrange = [rangeStart, rangeEnd];

        if (isMobile) {
            // total override of zoom on mobile
            timePoints = timePoints.filter((_value, index) => index % 3 === 0);
            timeValues = timeValues.filter((_value, index) => index % 3 === 0);
            xrange = [timeValues[0], timeValues[3]];
        }

        if (this.props.showFullDomain && timeValues.length > 1) {
            // total override of zoom on fulldomain
            let diff = timeValues[1] - timeValues[0];
            xrange = [timeValues[0] - 0.5 * diff, timeValues[timeValues.length - 1] + 0.5 * diff];
        }

        // put ticks for the start of each new day / hour based on tick interval (default is day)
        let tickInterval = this.props.tickInterval === undefined ? 'day' : this.props.tickInterval;
        let tickSpacing = Math.floor(Math.abs(1 - zoom) * 7) > 1 ? 24 : 12;
        if (tickInterval === 'subhour') tickSpacing = 4;
        let tickOffset = 0;

        let tickText: string[] = [];
        if (tickInterval === 'subhour') {
            tickText = timePoints
                .filter((_value, index) => index >= tickOffset && (index - tickOffset) % tickSpacing === 0)
                .map((point: T, index, array) => currentDataset.keyDisplayTransform(point));
        } else {
            const dataStartTime = currentDataset.keyDisplayTransform(timePoints[0]);
            const hour = parseInt(dataStartTime.split(':')[0]);
            const isAM = dataStartTime.split(' ')[1] === 'AM';
            // key display is in 12 hour time so adjust tick offset 
            if (hour === 12) {
                tickOffset = isAM ? 0 : 12;
            } else if (!isAM) {
                tickOffset = 12 - hour;
            } else {
                tickOffset = 24 - hour;
            }

            tickText = timePoints
                .filter((_value, index) => index >= tickOffset && (index - tickOffset) % tickSpacing === 0)
                .map((point: T, index, array) => {
                    const timeString = currentDataset.keyDisplayTransform(point);
                    let currentPointDate = new Date(currentDataset.keyTransform(point));
                    const dateString = currentPointDate.toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: 'numeric', timeZone: currentDataset.timezone });
                    if (index === 0) {
                        return `${timeString}<br>${dateString}`;
                    }

                    let prevPointDate = new Date(currentDataset.keyTransform(array[index - 1]));
                    if (index === 0 || currentPointDate.getDate() !== prevPointDate.getDate()) {
                        return `${timeString}<br>${dateString}`;
                    } else {
                        return timeString;
                    }
                });
        }
        let tickValues = timeValues.filter((_value, index) => index >= tickOffset && (index - tickOffset) % tickSpacing === 0);

        let graphNow = new Date().getTime();
        if (this.props.compactVersion) {
            // (keep the first tick so the graph looks correct but don't label it so that the text fits correctly)
            if (this.props.now) {
                graphNow = this.props.now.getTime();
            }
            tickValues.splice(1, 0, graphNow); // now should always be between the first and second value in tick values 
            tickText.splice(1, 0, '<br>now'); // add now text for now tick
        }

        // https://plot.ly/javascript/axes/#tick-placement-color-and-style
        let layout: Partial<Plotly.Layout> = {
            showlegend: false,
            hovermode: 'closest',
            hoverlabel: { bgcolor: "#6a6a6a", font: { family: "sans-serif", color: "#ffffff", size: 14 } },
            paper_bgcolor: this.props.backgroundColor ?? 'rgb(11, 19, 23)',
            plot_bgcolor: this.props.backgroundColor ?? 'rgb(11, 19, 23)',
            xaxis: {
                fixedrange: true,
                linecolor: '#e7ebef',
                ticks: 'outside',
                tickvals: tickValues,
                ticktext: tickText,
                range: xrange,
                side: 'bottom',
                tickangle: 0,
                tickfont: {
                    family: "sans-serif",
                    size: 14,
                    color: "#9fa3a7",
                },
                showgrid: !isMobile,
                gridcolor: "#6b7176",
                showspikes: true,
                spikemode: 'toaxis+across',
                spikesnap: 'cursor',
                spikedash: 'solid',
                showline: true,
            },
            yaxis: {
                showgrid: false,
                fixedrange: true,
                ticks: 'outside',
                tickcolor: "#9fa3a7",
                ticksuffix: currentDataset.valueSuffix,
                tickfont: {
                    family: "sans-serif",
                    size: 14,
                    color: "#9fa3a7",
                },
                rangemode: currentDataset.rangeMode
            },
            height: this.props.compactVersion ? 195 : 265,
            margin: {
                l: 70,
                r: 45,
                b: 45,
                t: 10,
            }
        };

        if (currentDataset.sections.length > 1) {
            layout.yaxis = {
                ...layout.yaxis,
                title: currentDataset.sections[0].label,
                titlefont: {
                    family: "sans-serif",
                    size: 14,
                    color: "#9fa3a7",
                },
            };
            layout.yaxis2 = {
                title: currentDataset.sections[1].label,
                titlefont: {
                    family: "sans-serif",
                    size: 14,
                    color: "#9fa3a7",
                },
                showgrid: false,
                fixedrange: true,
                ticks: 'outside',
                tickcolor: "#9fa3a7",
                ticksuffix: currentDataset.sections[1].suffix ?? currentDataset.valueSuffix,
                tickfont: {
                    family: "sans-serif",
                    size: 14,
                    color: "#9fa3a7",
                },
                rangemode: currentDataset.rangeMode,
                anchor: 'x',
                overlaying: 'y',
                side: 'right'
            };
            layout.margin = {
                l: 70,
                r: 100,
                b: 45,
                t: 10,
            };
        }

        if (currentDataset.maxYRange) {
            const maxYRange = Math.max(currentDataset.maxYRange, ...currentDataset.allData.map((point: T) => currentDataset.valueTransform(point)));
            const minYRange = Math.min(0, ...currentDataset.allData.map((point: T) => currentDataset.valueTransform(point)));
            if (currentDataset.rangeMode === "tozero") {
                layout.yaxis!.range = [minYRange, 0];
            }
            else if (currentDataset.rangeMode === "nonnegative") {
                layout.yaxis!.range = [0, maxYRange];
                if (layout.yaxis2) layout.yaxis2!.range = [0, maxYRange];
            }
            else {
                layout.yaxis!.range = [minYRange, maxYRange];
            }
        }

        let plotlyData: Plotly.Data[] = [];
        currentDataset.sections.map((section, index) => {
            let xValues = section.data.map((point: T) => currentDataset.keyTransform(point));
            let yValues = section.data.map((point: T) => currentDataset.valueTransform(point));
            let labels = section.data.map((point: T) => {
                let currentPointDate = new Date(currentDataset.keyTransform(point));
                let dateString = currentPointDate.toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: 'numeric', timeZone: currentDataset.timezone });
                let timeString = currentDataset.keyDisplayTransform(point);
                let label = `${dateString}, ${timeString}<br>${section.label}: `;
                // empty value suffix mean it is a rating key, otherwise it is weather
                return currentDataset.valueSuffix === "" ? label + `${currentDataset.valueTransform(point)}` : label + `${currentDataset.valueDisplayTransform(point)}`;
            });
            let colors: string[] = [];
            if (currentDataset.colorFunction !== undefined) {
                colors = section.data.map(value => currentDataset.colorFunction!(value));
            } else if (currentDataset.type === 'bar') {
                colors = yValues.map(value => {
                    const maxProgress = 10;
                    let progress = value / maxProgress;
                    let g = 1 - 0.85 * progress;
                    let r = Math.min(1, 0.4 + progress);
                    let b = 0.4 - progress * 0.1;

                    return `rgb(${r * 255}, ${g * 255}, ${b * 255}`;
                });
            }

            if (currentDataset.type === 'bar') {
                plotlyData = [{
                    x: xValues,
                    y: yValues,
                    text: labels,
                    hoverinfo: 'text',
                    type: currentDataset.type,
                    mode: "lines+markers",
                    marker: {
                        size: 12,
                        symbol: 'circle',
                        sizemode: 'diameter',
                        color: colors
                    }
                }];
            } else {
                for (let i = 0; i < xValues.length - 1; i++) {
                    // lines can not be gradients so we have to pick a color for each line segment to be, using the highest colr value seems to give the best result
                    let segmentColor = colors[i];
                    if (yValues[i] && yValues[i + 1]) {
                        segmentColor = yValues[i] > yValues[i + 1] ? colors[i] : colors[i + 1];
                    }
                    let currentData: Plotly.Data = {
                        x: [xValues[i], xValues[i + 1]],
                        y: [yValues[i], yValues[i + 1]],
                        text: section.suffix !== undefined ? `${labels[i].split(' ').slice(0, -1).join(' ')}${section.suffix}` : labels[i],
                        hoverinfo: 'text',
                        type: currentDataset.type,
                        mode: "lines+markers",
                        line: {
                            width: 5,
                            shape: "spline",
                            color: segmentColor || section.color
                        },
                        marker: {
                            size: 4
                        }
                    };
                    if (index === 1) {
                        currentData.yaxis = 'y2';
                    }

                    plotlyData.push(currentData);
                }
                if (this.props.compactVersion) {
                    // add a more obvious white line for the now tick
                    const maxY = layout.yaxis?.range?.at(-1);
                    if (maxY !== undefined) {
                        plotlyData.push({
                            type: 'bar',
                            x: [graphNow],
                            y: [maxY],
                            marker: {
                                color: 'white',
                                width: 2,
                            },
                        });
                    }
                }
            }
        });

        let links = this.props.datasets.filter(dataset => dataset.label.length > 0).map(dataset => {
            let className = "ClientWidgetContainer-header-link";
            if (dataset === this.state.selectedDataset) {
                className += " active";
            }
            return (
                <button className={className} key={dataset.label} onClick={() => this.selectDataset(dataset)}>{dataset.label}</button>
            );
        });

        let spacer = <div className="spacer" key={"legend-spacer"} />;

        let legendItems = currentDataset.sections

            // filter out no-label sections
            .filter(section => section.label.length > 0)

            // sort ascending by name for legend
            .sort((a, b) => a.label.localeCompare(b.label))

            // coalesce sections that have the same label
            .reduce(
                (accum: GraphSection<T>[], value: GraphSection<T>) => {
                    if (accum.length === 0) {
                        return [value];
                    }

                    let last = accum[accum.length - 1] as GraphSection<T>;
                    if (last.label === value.label && last.color === value.color) {
                        return accum;
                    } else {
                        return accum.concat(value);
                    }
                },
                []
            )

            // make legend items for each unique section
            .map((section: GraphSection<T>) => {
                return (
                    <div key={section.label} className={"GraphWidgetLegendItem"}>
                        <div className={"circle"} style={{ backgroundColor: section.color }} />
                        <div className={"label"} style={{ color: section.color }}>{section.label}</div>
                    </div>
                );
            });

        // put legend rows in a vertically laid out div within the horizontal flexbox layout of the header
        let legend = <div className={"desktop-legend"} key={"desktop-legend"}>{legendItems}</div>;
        let mobileLegend;

        let allItems = links;
        if (legendItems.length > 0) {
            allItems = [legend, spacer].concat(...links);
            mobileLegend = <div className={"mobile-legend"}>{legendItems}</div>;
        }

        let config: Partial<Plotly.Config> = {
            displayModeBar: false,
        };

        return (
            <WidgetContainer {...this.props} headerChildren={this.props.headerChildren || allItems}>
                {mobileLegend}
                <Plot data={plotlyData} layout={layout} config={config} onClick={(event) => this.props.onClick?.(event)} />
            </WidgetContainer>
        );
    }
}
