import { ImpactMapSection } from '../components/Client/Impact/sections';
import { MapType } from '../types/MapType';
import { action, props, union } from 'ts-action';

export const NowcastSetSelectedMap = action('NOWCAST_SET_SELECTED_MAP', props<{
    selectedMapCategory: ImpactMapSection;
    selectedMapType: MapType;
}>());

export const NowcastSetPaused = action('NOWCAST_SET_PAUSED', props<{ paused: boolean }>());

export const NowcastSetTileOpacity = action('NOWCAST_SET_TILE_OPACITY', props<{ tileOpacity: number }>());

export const NowcastViewAction = union(NowcastSetSelectedMap, NowcastSetPaused, NowcastSetTileOpacity);
