import { Typography } from '@mui/material';
import { labelForWeatherFlag, RouteData, RouteResults } from '../../../types/routes';
import * as React from 'react';
import strftime from 'strftime';
import { getUserDescriptionOfError } from 'src/types/unmarshal';

const ResultsInfoRow = ({ label, value }: { label: string; value: string }) => {
    return (
        <div>
            <Typography color={'textPrimary'} variant={'body1'} display={'inline'}>{label}:</Typography>
            {' '}
            <Typography color={'textSecondary'} variant={'body1'} display={'inline'}>{value}</Typography>
        </div>
    );
};

export const ResultsInfoComponent = (props: { route: RouteData, results: RouteResults | undefined }) => {
    if (!props.results) {
        const { errorTitle, errorMessage } = getUserDescriptionOfError(props.route.latestRouteResultError?.error);
        return (
            <div>
                <Typography color={'textPrimary'} variant={'h6'}>{'⚠️ Error'}</Typography>
                <ResultsInfoRow label={'Error'} value={errorTitle} />
                <ResultsInfoRow label={'Description'} value={errorMessage} />
            </div>
        );
    }

    const fromText = props.route.origin.label;
    const toText = props.route.destination.label;
    const timezoneAbbreviation = props.route.departureTime.toLocaleTimeString('en-US', { timeZoneName: 'short' }).split(' ')[2];
    const departureTimeText = `${strftime("%e-%b %l:%M %p", props.route.departureTime)} ${timezoneAbbreviation}`;
    const slowdownText = `${Math.round(props.results.slowdownFraction * 100)}%`;

    const weatherFlagsText = props.results.weatherFlags.length > 0 ?
        props.results.weatherFlags.map(weatherFlag => labelForWeatherFlag(weatherFlag)).join(', ') :
        'None';

    let aboveTempThresholds = undefined;
    if (props.results.aboveTemperatureThresholds?.length > 0) {
        const tempThreshold = props.results.aboveTemperatureThresholds[0];
        const time = Math.round(tempThreshold.secondsBreachingThreshold / 60);
        const timeStr = time > 59 ? `${Math.floor(time / 60)}:${(time % 60).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })} hrs` : `${time} mins`;
        aboveTempThresholds = `${timeStr} (${Math.round(tempThreshold.percentBreachingThreshold * 100)}% of route)`;
    }       
    
    let belowTempThresholds = undefined;
    if (props.results.belowTemperatureThresholds?.length > 0) {
        const tempThreshold = props.results.belowTemperatureThresholds[0];
        const time = Math.round(tempThreshold.secondsBreachingThreshold / 60);
        const timeStr = time > 59 ? `${Math.floor(time / 60)}:${(time % 60).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })} hrs` : `${time} mins`;
        belowTempThresholds = `${timeStr} (${Math.round(tempThreshold.percentBreachingThreshold * 100)}% of route)`;
    }
    
    return (
        <div>
            <Typography color={'textPrimary'} variant={'h6'}>Results</Typography>
            <ResultsInfoRow label={'Slowdown'} value={slowdownText} />
            <ResultsInfoRow label={'Weather Flags'} value={weatherFlagsText} />
            {aboveTempThresholds && <ResultsInfoRow label={'Above Temperature Threshold'} value={aboveTempThresholds} />}
            {belowTempThresholds && <ResultsInfoRow label={'Below Temperature Threshold'} value={belowTempThresholds} />}
            <ResultsInfoRow label={'From'} value={fromText} />
            <ResultsInfoRow label={'To'} value={toText} />
            <ResultsInfoRow label={'Dep. Time'} value={departureTimeText} />
        </div>
    );
};
