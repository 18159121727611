import { StoreState } from '../../types';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import VehicleIntegrationComponent from 'src/components/Public/VehicleIntegration';

export function mapStateToProps(state: StoreState, _ownProps: RouteComponentProps<any>) {
    return {
        provider: _ownProps.match.params.provider,
    };
}

export function mapDispatchToProps(dispatch: ThunkDispatch<StoreState, void, Action<any>>) {
    return {};
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VehicleIntegrationComponent));