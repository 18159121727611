import { createTheme } from '@mui/material/styles';

export function copyToClipboard(text: string) {
    const elem = document.createElement('textarea');
    elem.value = text;
    document.body.appendChild(elem);
    elem.select();
    document.execCommand('copy');
    document.body.removeChild(elem);
}

export const darkModeTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#90CAF9'
        }
    }
});

export const lightModeTheme = createTheme({
    palette: {
        mode: 'light'
    }
});

export const snakeCaseToTitleCase = (key: string) => key
                .replace(/_/g, ' ')
                .replace(/\b\w/g, char => char.toUpperCase());

const jsonToCsv =(items: any) => {
    const header = Object.keys(items[0]);
    const headerString = header.join(',');
    // handle null or undefined values here
    const replacer = (key: any, value: any) => value ?? '';
    const rowItems = items.map((row: any) =>
        header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(',')
    );
    // join header and body, and break into separate lines
    const csv = [headerString, ...rowItems].join('\r\n');
    return csv;
};

export const exportCSVFile = (items: any[], fileName: string) => {
    const csv = jsonToCsv(items);
    const exportedFilenmae = fileName + '.csv' || 'export.csv';
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    
    const link = document.createElement("a");
    if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", exportedFilenmae);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
};