import { Algorithm, Cluster, ClusterStats, MarkerClusterer, Renderer, SuperClusterViewportAlgorithm, SuperClusterViewportOptions } from "@googlemaps/markerclusterer";
import { CycloneData, EarthquakeData, EventData, FireData, getColorForReportType, getEarthquakeColorForMagnitude, getRoadConditionColorForPrecipitationType, LightningData, PowerOutageData, RoadClosureData, RoadStatusData, RoadWorkData, SpecialEventData, StormData, StormReportData, TrafficCongestionData, TrafficIncidentData, TruckWarningData, VehicleTrackingData, VolcanoData, WeatherStationData, WildfireData } from "../../../types";
import { cycloneCircleSvg, cycloneCrossSvg, cycloneSwirlSvg, drivingConditionsSvg, earthquakeClusterSvg, earthquakeMarkerSvg, fireClusterSvg, fireMarkerSvg, lightningClusterSvg, lightningMarkerSvg, roadClosureCluster, roadClosureSvg, roadWorkCluster, roadWorkSvg, specialEventCluster, specialEventSvg, stormReportSVG, trafficCongestionCluster, trafficCongestionSvg, trafficIncidentCluster, trafficIncidentSvg, truckerWarningCluster, truckerWarningSvg, vehicleSvg, volcanoClusterSvg, volcanoMarkerSvg } from "./markers/markers";
import TimeAgo from "javascript-time-ago";
import { drivingConditionsClusterSvg } from "./markers/DrivingConditionClusterer";
import { stormReportClusterSvg } from "./markers/stormReports";
import { colorForImpactLevel, ImpactLevel, tagColorForImpactLevel } from "../../../types/routes";
import { createImpactClusterSVG } from "./markers/ImpactClusterer";

type Marker = google.maps.marker.AdvancedMarkerElement;

const computeClusterScale = (featureCount: number, featuresForFullSize = 1000, variablePartOfScale = 0.3) => {
    return (1 - variablePartOfScale) + Math.min(variablePartOfScale, featureCount / featuresForFullSize * variablePartOfScale);
};

export const calculateLightningOpacity = (timestampMs: number) => {
    return calculateLightningOpacityFromAge((new Date().getTime() - timestampMs) / 1000 / 60);
};

export const calculateLightningOpacityFromAge = (ageMin: number) => {
    const baseOpacity = 0.3;
    return baseOpacity + (1 - baseOpacity) * (1 - Math.min(1, ageMin / 30));
};

type RedrawMode = 'never' | 'on-selection' | 'always';

interface VisualizationConfig<T extends EventData> {
    dataType: string;
    isDataVisible: boolean,
    dataList: T[],
    selectedData: T | undefined,
    clickListener: (event: google.maps.MapMouseEvent, item: T) => void;
    marker?: {
        getId: (data: T) => string,
        /**
         * specifies how ofter markers need to be redrawn -- defaults to 'always'
         * - never: marker can be reused indefinitely for an id -- no change on selection
         * - on-selection: redraw selected and previously selected element
         * - always: redraw all elements
         */
        redrawMode?: RedrawMode,
        /**
         * the number of milliseconds to yield for when creating a lot of markers
         */
        yieldTimeout?: number,
        shouldRender?: (data: T) => boolean,
        getMarkerOptions: (data: T, isSelected: boolean, existingMarker?: Marker) => Partial<google.maps.marker.AdvancedMarkerElementOptions>,
        addMetadataToMarker?: (data: T, marker: Marker) => void;
        /**
         * TODO: support re-creating the cluster if the inputs change after the initial render?
         */
        cluster?: {
            algorithm: Algorithm,
            onClusterClick: (event: google.maps.MapMouseEvent, cluster: Cluster, map: google.maps.Map) => void,
            clusterRenderer: (info: Cluster, stats: ClusterStats, map: google.maps.Map) => Marker;
        }
    },
    line?: {
        getId: (data: T) => string,
        shouldRender?: (data: T) => boolean,
        getDataOptions: (data: T, isSelected: boolean) => google.maps.Data.StylingFunction | google.maps.Data.StyleOptions,
    },
    polygon?: {
        getId: (data: T) => string,
        shouldRender?: (data: T) => boolean,
        getDataOptions: (data: T, isSelected: boolean) => google.maps.Data.StylingFunction | google.maps.Data.StyleOptions,
    },
}

export class MapManagerV2 {

    map: google.maps.Map | null = null;

    geoJsonDataMetadata: {
        [key: string]: {
            markers?: { [key: string]: Marker },
            markerClusterer?: MarkerClusterer,
            previouslySelectedData?: any,
            lines?: { [key: string]: google.maps.Data },
            polygons?: { [key: string]: google.maps.Data },
        }
    } = {};

    mapsAPILoaded(map: google.maps.Map) {
        this.map = map;
    }

    setupCyclones(
        cyclones: CycloneData[],
        isCyclonesVisible: boolean,
        selectedCyclone: CycloneData | undefined,
        showModelForecastTracksForCycloneId: string | undefined,
        cycloneClickListener: (event: google.maps.MapMouseEvent, cyclone: CycloneData) => void
    ) {
        this.setupGeoJsonData({
            dataType: 'cyclone',
            isDataVisible: isCyclonesVisible,
            dataList: cyclones,
            selectedData: selectedCyclone,
            clickListener: (event, cyclone) => cycloneClickListener(event, cyclone),
            marker: {
                getId: (cyclone) => cyclone.id,
                redrawMode: 'on-selection',
                shouldRender: (cyclone) => {
                    // skip any features that are alternates if we're not viewing model forecast tracks for that same cyclone
                    if (cyclone['feature'].includes("_alternate") && cyclone.cycloneId !== showModelForecastTracksForCycloneId) {
                        return false;
                    }
                    return true;
                },
                getMarkerOptions: (cyclone, isSelected) => {
                    const stormColor = cyclone.geoJson.properties?.['marker-color'];
                    const stormSymbol = cyclone.geoJson.properties?.['marker-symbol']?.toUpperCase();
                    // if we want to add to adjust the scale of the markers based off strength
                    // const windMPH = (cyclone as CyclonePointData).windMPH;
                    // const windScaleFactor = windMPH ? 1.0 + Math.min(1.0, Math.max(0.0, (windMPH - 74) / 75)) : 1.0;
                    const selectedScaleFactor = isSelected ? 1.333 : 1.0;
                    let svgEl;
                    let collisionBehavior = google.maps.CollisionBehavior.REQUIRED_AND_HIDES_OPTIONAL;
                    if (cyclone['feature'] === 'disturbance_point') {
                        svgEl = cycloneCrossSvg(stormColor, 1 * selectedScaleFactor);
                    } else if (cyclone['feature'] === 'storm_track_point') {
                        svgEl = cycloneSwirlSvg(stormColor, 1.5 * selectedScaleFactor, stormSymbol);
                    } else {
                        svgEl = cycloneCircleSvg(stormColor, 'white', 0.4 * selectedScaleFactor, stormSymbol);
                        if (!isSelected) {
                            collisionBehavior = google.maps.CollisionBehavior.OPTIONAL_AND_HIDES_LOWER_PRIORITY;
                        }
                    }
                    return {
                        content: svgEl,
                        collisionBehavior,
                    };
                },
            },
            line: {
                getId: (cyclone) => cyclone.id,
                shouldRender: (cyclone) => {
                    // skip any features that are alternates if we're not viewing model forecast tracks for that same cyclone
                    if (cyclone['feature'].includes("_alternate") && cyclone.cycloneId !== showModelForecastTracksForCycloneId) {
                        return false;
                    }
                    return true;
                },
                getDataOptions: (cyclone, isSelected) => {
                    let featureStyle = {
                        strokeColor: cyclone.geoJson.properties?.['stroke'],
                        strokeOpacity: cyclone.geoJson.properties?.['stroke-opacity'],
                        strokeWeight: cyclone.geoJson.properties?.['stroke-width'],
                        // markers are always on top of lines/polygons so we just make sure
                        // that the lines (cyclone tracks) are on top of cones/swaths
                        zIndex: 10050,
                    };

                    if (isSelected) {
                        featureStyle = {
                            ...featureStyle,
                            strokeOpacity: 1.0,
                            strokeWeight: 10,
                        };
                    }
                    return featureStyle;
                }
            },
            polygon: {
                getId: (cyclone) => cyclone.id,
                getDataOptions: (cyclone, isSelected) => {
                    let featureStyle = {
                        fillColor: cyclone.geoJson.properties?.['fill'],
                        fillOpacity: cyclone.geoJson.properties?.['fill-opacity'],
                        strokeColor: cyclone.geoJson.properties?.['stroke'],
                        strokeOpacity: cyclone.geoJson.properties?.['stroke-opacity'],
                        strokeWeight: cyclone.geoJson.properties?.['stroke-width'],
                        zIndex: 10025,
                    };

                    if (isSelected) {
                        featureStyle = {
                            ...featureStyle,
                            fillOpacity: 0.4,
                            strokeOpacity: 1.0,
                        };
                    }
                    return featureStyle;
                }
            }
        });
    }

    setupVolcanoes(
        volcanoes: VolcanoData[],
        isVolcanoesVisible: boolean,
        selectedVolcano: VolcanoData | undefined,
        volcanoClickListener: (event: google.maps.MapMouseEvent, volcano: VolcanoData) => void,
        clusterClickListener: (event: google.maps.MapMouseEvent, cluster: Cluster, map: google.maps.Map) => void,
    ) {
        this.setupGeoJsonData({
            dataType: 'volcano',
            isDataVisible: isVolcanoesVisible,
            dataList: volcanoes,
            selectedData: selectedVolcano,
            clickListener: (event, volcano) => volcanoClickListener(event, volcano),
            marker: {
                getId: (data) => data.id,
                redrawMode: 'always',
                getMarkerOptions: (data, isSelected) => {
                    return {
                        content: volcanoMarkerSvg('#FF0000', '#964B00'),
                    };
                },
                cluster: {
                    algorithm: new SuperClusterViewportAlgorithm({ radius: 100 } as SuperClusterViewportOptions),
                    clusterRenderer: (info) => {
                        const scale = computeClusterScale(info.count);
                        const svgEl = volcanoClusterSvg(info.count, scale, false);
                        return new google.maps.marker.AdvancedMarkerElement({
                            position: info.position,
                            map: this.map,
                            title: `Cluster of ${info.count} volcanoes`,
                            content: svgEl,
                        });
                    },
                    onClusterClick: (event, cluster, map) => clusterClickListener(event, cluster, map),
                }
            },
        });
    }

    setupEarthquakes(
        earthquakes: EarthquakeData[],
        isEarthquakesVisible: boolean,
        selectedEarthquake: EarthquakeData | undefined,
        earthquakeClickListener: (event: google.maps.MapMouseEvent, earthquake: EarthquakeData) => void,
        clusterClickListener: (event: google.maps.MapMouseEvent, cluster: Cluster, map: google.maps.Map) => void,
    ) {
        this.setupGeoJsonData({
            dataType: 'earthquake',
            isDataVisible: isEarthquakesVisible,
            dataList: earthquakes,
            selectedData: selectedEarthquake,
            clickListener: (event, earthquake) => earthquakeClickListener(event, earthquake),
            marker: {
                getId: (data) => data.id,
                redrawMode: 'never',
                getMarkerOptions: (data, isSelected) => {
                    const scaleAdjustment = (data.magnitude - 4.5) * 0.2;
                    const svgEl = earthquakeMarkerSvg(getEarthquakeColorForMagnitude(data.magnitude), 1.0, 1.5 + scaleAdjustment);
                    return {
                        content: svgEl,
                    };
                },
                addMetadataToMarker: (data, marker) => {
                    (marker as any).metadata = {
                        magnitude: data.magnitude,
                    };
                },
                cluster: {
                    algorithm: new SuperClusterViewportAlgorithm({ radius: 50 } as SuperClusterViewportOptions),
                    clusterRenderer: (info) => {
                        const maxMagnitude = (info.markers || []).map(m => (m as any).metadata.magnitude).reduce((a, b) => Math.max(a, b), -Infinity);
                        const scaleAdjustment = (maxMagnitude - 4.5) * 0.1;
                        const svgEl = earthquakeClusterSvg(info.count, 0.7 + scaleAdjustment, getEarthquakeColorForMagnitude(maxMagnitude), 1.0, false);
                        return new google.maps.marker.AdvancedMarkerElement({
                            position: info.position,
                            map: this.map,
                            title: `Cluster of ${info.count} earthquakes, max magnitude: ${Math.floor(maxMagnitude * 10) / 10}`,
                            content: svgEl,
                        });
                    },
                    onClusterClick: (event, cluster, map) => clusterClickListener(event, cluster, map),
                }
            },
            line: {
                getId: (earthquake) => earthquake.id,
                shouldRender: (earthquake) => selectedEarthquake?.earthquakeId === earthquake.earthquakeId,
                getDataOptions: (data, isSelected) => {
                    const noIcon = {
                        path: "",
                        scale: 0,
                    };
                    const featureStyle = {
                        strokeColor: data.geoJson.properties?.stroke,
                        strokeOpacity: 1.0,
                        strokeWeight: data.geoJson.properties?.['stroke-width'],
                        icon: noIcon,
                    };
                    return featureStyle;
                }
            },
        });
    }

    updateLightningColors() {
        const lightningMetadata = this.geoJsonDataMetadata['lightning'];
        if (!lightningMetadata || !lightningMetadata.markers) {
            return;
        }

        const markers = lightningMetadata.markers;
        for (const id of Object.keys(markers)) {
            const marker = markers[id];
            const detectedTimestamp = (marker as any).metadata.detectedTime;
            if (detectedTimestamp) {
                const opacity = calculateLightningOpacity(detectedTimestamp);// * Math.random(); // => for testing
                const svgEl = lightningMarkerSvg(opacity, lightningMetadata.previouslySelectedData?.id === id, marker);
                marker.content = svgEl;
            } else {
                console.warn("no metadata detectedTimestamp for lightning marker #", id);
            }
        }
    }

    setupLightning(
        lightning: LightningData[],
        isLightningVisible: boolean,
        selectedLightning: LightningData | undefined,
        lightningClickListener: (event: google.maps.MapMouseEvent, lightning: LightningData) => void,
        clusterClickListener: (event: google.maps.MapMouseEvent, cluster: Cluster, map: google.maps.Map) => void,
    ) {
        this.setupGeoJsonData({
            dataType: 'lightning',
            isDataVisible: isLightningVisible,
            dataList: lightning,
            selectedData: selectedLightning,
            clickListener: (event, data) => lightningClickListener(event, data),
            marker: {
                getId: (data) => data.id,
                // TODO: determine if this desirable behavior from team
                // yieldTimeout: 250,
                getMarkerOptions: (data, isSelected, existingMarker) => {
                    const opacity = calculateLightningOpacity(data.detectedTime.getTime());
                    const svgEl = lightningMarkerSvg(opacity, isSelected, existingMarker);
                    return {
                        content: svgEl,
                    };
                },
                addMetadataToMarker: (data, marker) => {
                    (marker as any).metadata = {
                        detectedTime: data.detectedTime.getTime(),
                    };
                },
                cluster: {
                    algorithm: new SuperClusterViewportAlgorithm({ radius: 100 } as SuperClusterViewportOptions),
                    clusterRenderer: (info) => {
                        const markers = info.markers || [];
                        let newestTime = (markers[0] as any).metadata.detectedTime;
                        for (const marker of markers) {
                            newestTime = Math.max(newestTime, (marker as any).metadata.detectedTime);
                        }

                        const scale = computeClusterScale(info.count);
                        const opacity = calculateLightningOpacity(newestTime);
                        const svgEl = lightningClusterSvg(info.count, scale, opacity, false);

                        const timeAgo = new TimeAgo('en-US');
                        return new google.maps.marker.AdvancedMarkerElement({
                            position: info.position,
                            map: this.map,
                            title: `${info.count} lightning flashes, most recent ${timeAgo.format(newestTime)}`,
                            content: svgEl,
                        });
                    },
                    onClusterClick: (event, cluster, map) => clusterClickListener(event, cluster, map),
                }
            },
        });
    }

    setupStorms(
        storms: StormData[],
        isStormsVisible: boolean,
        selectedStorm: StormData | undefined,
        stormClickListener: (event: google.maps.MapMouseEvent, storm: StormData) => void,
    ) {
        this.setupGeoJsonData({
            dataType: 'storm',
            isDataVisible: isStormsVisible,
            dataList: storms,
            selectedData: selectedStorm,
            clickListener: (event, data) => stormClickListener(event, data),
            polygon: {
                getId: (data) => data.id,
                getDataOptions: (data, isSelected) => {
                    const noIcon = {
                        path: "",
                        scale: 0,
                    };
                    let featureStyle = {
                        fillColor: data.geoJson.properties?.['fill'],
                        fillOpacity: data.geoJson.properties?.['fill-opacity'],
                        strokeColor: data.geoJson.properties?.['stroke'],
                        strokeOpacity: data.geoJson.properties?.['stroke-opacity'],
                        strokeWeight: data.geoJson.properties?.['stroke-width'],
                        icon: noIcon,
                        zIndex: 10002
                    };

                    if (isSelected) {
                        featureStyle = {
                            ...featureStyle,
                            fillOpacity: 0.48,
                            strokeWeight: 4,
                        };
                    }
                    return featureStyle;
                }
            }
        });
    }

    setupFires(
        fires: FireData[],
        isFiresVisible: boolean,
        selectedFire: FireData | undefined,
        fireClickListener: (event: google.maps.MapMouseEvent, fire: any) => void,
        clusterClickListener: (event: google.maps.MapMouseEvent, cluster: Cluster, map: google.maps.Map) => void,
    ) {
        this.setupGeoJsonData({
            dataType: 'fire',
            isDataVisible: isFiresVisible,
            dataList: fires,
            selectedData: selectedFire,
            clickListener: (event, data) => fireClickListener(event, data),
            marker: {
                getId: (data) => data.id,
                redrawMode: 'on-selection',
                // TODO: determine if this desirable behavior from team
                // yieldTimeout: 250,
                getMarkerOptions: (data, isSelected, existingMarker) => {
                    return {
                        content: fireMarkerSvg(isSelected, existingMarker),
                    };
                },
                cluster: {
                    algorithm: new SuperClusterViewportAlgorithm({ radius: 100 } as SuperClusterViewportOptions),
                    clusterRenderer: (info) => {
                        const scale = computeClusterScale(info.count);
                        const svgEl = fireClusterSvg(info.count, scale, false);
                        return new google.maps.marker.AdvancedMarkerElement({
                            position: info.position,
                            map: this.map,
                            title: `Cluster of ${info.count} fires`,
                            content: svgEl,
                        });
                    },
                    onClusterClick: (event, cluster, map) => clusterClickListener(event, cluster, map),
                }
            },
        });
    }

    setupWildfirePerimeters(
        wildfires: WildfireData[],
        isWildfiresVisible: boolean,
        selectedWildfire: WildfireData | undefined,
        wildfireClickListener: (event: google.maps.MapMouseEvent, fire: any) => void,
    ) {
        this.setupGeoJsonData({
            dataType: 'wildfire',
            isDataVisible: isWildfiresVisible,
            dataList: wildfires,
            selectedData: selectedWildfire,
            clickListener: (event, data) => wildfireClickListener(event, data),
            polygon: {
                getId: (data) => data.id,
                getDataOptions: (data, isSelected) => {
                    let featureStyle = {
                        fillColor: '#ffffff',
                        fillOpacity: 0.5,
                        strokeWeight: 1,
                        strokeColor: '#ffffff',
                        strokeOpacity: 1.0,
                        zIndex: 10000
                    };

                    if (isSelected) {
                        featureStyle = {
                            ...featureStyle,
                            fillOpacity: 1,
                            strokeWeight: 4,
                        };
                    }
                    return featureStyle;
                }
            }
        });
    }

    setupStormReports(
        stormReports: StormReportData[],
        isStormReportsVisible: boolean,
        selectedStormReport: StormReportData | undefined,
        stormReportClickListener: (event: google.maps.MapMouseEvent, stormReport: StormReportData) => void,
        clusterClickListener: (event: google.maps.MapMouseEvent, cluster: Cluster, map: google.maps.Map) => void
    ) {
        this.setupGeoJsonData({
            dataType: 'storm_report',
            isDataVisible: isStormReportsVisible,
            dataList: stormReports,
            selectedData: selectedStormReport,
            clickListener: (event, data) => stormReportClickListener(event, data),
            marker: {
                getId: (data) => data.id,
                redrawMode: 'never',
                getMarkerOptions: (data, isSelected) => {
                    const actualColor = getColorForReportType(data.reportType);
                    const svgEl = stormReportSVG(actualColor);
                    return {
                        content: svgEl,
                    };
                },
                addMetadataToMarker: (data, marker) => {
                    (marker as any).metadata = {
                        reportType: data.reportType,
                    };
                },
                cluster: {
                    algorithm: new SuperClusterViewportAlgorithm({ radius: 100 } as SuperClusterViewportOptions),
                    clusterRenderer: (info) => {
                        const svgEl = stormReportClusterSvg(info.count, info.markers);
                        return new google.maps.marker.AdvancedMarkerElement({
                            position: info.position,
                            map: this.map,
                            title: `Cluster of ${info.count} storm reports`,
                            content: svgEl,
                        });
                    },
                    onClusterClick: (event, cluster, map) => clusterClickListener(event, cluster, map),
                }
            },
        });
    }

    setupPowerOutages(
        powerOutages: PowerOutageData[],
        isPowerOutagesVisible: boolean,
        selectedPowerOutage: PowerOutageData | undefined,
        powerOutageClickListener: (event: google.maps.MapMouseEvent, powerOutage: PowerOutageData) => void,
    ) {
        this.setupGeoJsonData({
            dataType: 'power_outage',
            isDataVisible: isPowerOutagesVisible,
            dataList: powerOutages,
            selectedData: selectedPowerOutage,
            clickListener: (event, data) => powerOutageClickListener(event, data),
            polygon: {
                getId: (data) => data.id,
                getDataOptions: (data, isSelected) => {
                    const noIcon = {
                        path: "",
                        scale: 0,
                    };
                    let featureStyle = {
                        fillColor: data.fill,
                        fillOpacity: data.fillOpacity,
                        strokeColor: "white",
                        strokeWeight: 2,
                        icon: noIcon,
                        zIndex: 10001
                    };

                    if (isSelected) {
                        featureStyle = {
                            ...featureStyle,
                            fillOpacity: Math.min(featureStyle.fillOpacity * 1.5, 1.0),
                            strokeWeight: 4,
                        };
                    }
                    return featureStyle;
                }
            }
        });
    }

    setupRoadStatus(
        roadStatus: RoadStatusData[],
        isRoadStatusVisible: boolean,
        selectedRoadStatus: RoadStatusData | undefined,
        roadStatusClickListener: (event: google.maps.MapMouseEvent, roadStatus: any) => void,
        clusterClickListener: (event: google.maps.MapMouseEvent, cluster: Cluster, map: google.maps.Map) => void,
    ) {
        this.setupGeoJsonData({
            dataType: 'road_status',
            isDataVisible: isRoadStatusVisible,
            dataList: roadStatus,
            selectedData: selectedRoadStatus,
            clickListener: (event, roadStatus) => roadStatusClickListener(event, roadStatus),
            marker: {
                getId: (data) => data.id,
                redrawMode: 'always',
                getMarkerOptions: (data, isSelected) => {
                    const color = data.color ? data.color : getRoadConditionColorForPrecipitationType(data.status ? data.status : data.description);
                    const svgEl = drivingConditionsSvg(color, 1);
                    return {
                        content: svgEl,
                    };
                },
                cluster: {
                    algorithm: new SuperClusterViewportAlgorithm({ radius: 100 } as SuperClusterViewportOptions),
                    clusterRenderer: (info) => {
                        const svgEl = drivingConditionsClusterSvg(info.count, info.markers);
                        return new google.maps.marker.AdvancedMarkerElement({
                            position: info.position,
                            map: this.map,
                            title: `Cluster of ${info.count} driving conditions`,
                            content: svgEl,
                        });
                    },
                    onClusterClick: (event, cluster, map) => clusterClickListener(event, cluster, map),
                }
            },
            line: {
                getId: (data) => data.id,
                getDataOptions: (data, isSelected) => {
                    const color = getRoadConditionColorForPrecipitationType(data.status ? data.status : data.description);
                    let featureStyle = {
                        strokeWeight: 5,
                        strokeColor: color,
                        strokeOpacity: 0.5,
                    };

                    if (isSelected) {
                        featureStyle = {
                            ...featureStyle,
                            strokeWeight: 10,
                            strokeOpacity: 1,
                        };
                    }
                    return featureStyle;
                }
            },
        });
    }

    setupRoadWork(
        roadWork: RoadWorkData[],
        isRoadWorkVisible: boolean,
        selectedRoadWork: RoadWorkData | undefined,
        roadWorkClickListener: (event: google.maps.MapMouseEvent, roadWork: any) => void,
        clusterClickListener: (event: google.maps.MapMouseEvent, cluster: Cluster, map: google.maps.Map) => void,
    ) {
        this.setupGeoJsonData({
            dataType: 'road_work',
            isDataVisible: isRoadWorkVisible,
            dataList: roadWork,
            selectedData: selectedRoadWork,
            clickListener: (event, data) => roadWorkClickListener(event, data),
            marker: {
                getId: (data) => data.id,
                getMarkerOptions: (data, isSelected) => {
                    const svgEl = roadWorkSvg('orange', 1);
                    return {
                        content: svgEl,
                    };
                },
                cluster: {
                    algorithm: new SuperClusterViewportAlgorithm({ radius: 200 } as SuperClusterViewportOptions),
                    clusterRenderer: (info) => {
                        const scale = computeClusterScale(info.count);
                        const svgEl = roadWorkCluster(info.count, 'orange', scale);
                        return new google.maps.marker.AdvancedMarkerElement({
                            position: info.position,
                            map: this.map,
                            title: `Cluster of ${info.count} road work`,
                            content: svgEl,
                        });
                    },
                    onClusterClick: (event, cluster, map) => clusterClickListener(event, cluster, map),
                }
            },
            line: {
                getId: (data) => data.id,
                getDataOptions: (data, isSelected) => {
                    let featureStyle = {
                        strokeWeight: 5,
                        strokeColor: 'orange',
                        strokeOpacity: 0.5,
                    };

                    if (isSelected) {
                        featureStyle = {
                            ...featureStyle,
                            strokeWeight: 10,
                            strokeOpacity: 1,
                        };
                    }
                    return featureStyle;
                }
            },
        });
    }

    setupRoadClosures(
        roadClosures: RoadClosureData[],
        isRoadClosuresVisible: boolean,
        selectedRoadClosure: RoadClosureData | undefined,
        roadClosureClickListener: (event: google.maps.MapMouseEvent, roadClosure: any) => void,
        clusterClickListener: (event: google.maps.MapMouseEvent, cluster: Cluster, map: google.maps.Map) => void,
    ) {
        this.setupGeoJsonData({
            dataType: 'road_closure',
            isDataVisible: isRoadClosuresVisible,
            dataList: roadClosures,
            selectedData: selectedRoadClosure,
            clickListener: (event, data) => roadClosureClickListener(event, data),
            marker: {
                getId: (data) => data.id,
                getMarkerOptions: (data, isSelected) => {
                    const svgEl = roadClosureSvg('#FF1F00', 1);
                    return {
                        content: svgEl,
                    };
                },
                cluster: {
                    algorithm: new SuperClusterViewportAlgorithm({ radius: 100 } as SuperClusterViewportOptions),
                    clusterRenderer: (info) => {
                        const scale = computeClusterScale(info.count);
                        const svgEl = roadClosureCluster(info.count, '#FF1F00', scale);
                        return new google.maps.marker.AdvancedMarkerElement({
                            position: info.position,
                            map: this.map,
                            title: `Cluster of ${info.count} road closures`,
                            content: svgEl,
                        });
                    },
                    onClusterClick: (event, cluster, map) => clusterClickListener(event, cluster, map),
                }
            },
            line: {
                getId: (data) => data.id,
                getDataOptions: (data, isSelected) => {
                    let featureStyle = {
                        strokeWeight: 5,
                        strokeColor: '#FF1F00',
                        strokeOpacity: 0.5,
                    };

                    if (isSelected) {
                        featureStyle = {
                            ...featureStyle,
                            strokeWeight: 10,
                            strokeOpacity: 1,
                        };
                    }
                    return featureStyle;
                }
            },
        });
    }

    setupSpecialEvents(
        specialEvents: SpecialEventData[],
        isSpecialEventsVisible: boolean,
        selectedSpecialEvent: SpecialEventData | undefined,
        specialEventClickListener: (event: google.maps.MapMouseEvent, specialEvent: any) => void,
        clusterClickListener: (event: google.maps.MapMouseEvent, cluster: Cluster, map: google.maps.Map) => void,
    ) {
        this.setupGeoJsonData({
            dataType: 'special_event',
            isDataVisible: isSpecialEventsVisible,
            dataList: specialEvents,
            selectedData: selectedSpecialEvent,
            clickListener: (event, data) => specialEventClickListener(event, data),
            marker: {
                getId: (data) => data.id,
                getMarkerOptions: (data, isSelected) => {
                    const svgEl = specialEventSvg('orange', 1);
                    return {
                        content: svgEl,
                    };
                },
                cluster: {
                    algorithm: new SuperClusterViewportAlgorithm({ radius: 100 } as SuperClusterViewportOptions),
                    clusterRenderer: (info) => {
                        const scale = computeClusterScale(info.count);
                        const svgEl = specialEventCluster(info.count, 'orange', scale);
                        return new google.maps.marker.AdvancedMarkerElement({
                            position: info.position,
                            map: this.map,
                            title: `Cluster of ${info.count} special events`,
                            content: svgEl,
                        });
                    },
                    onClusterClick: (event, cluster, map) => clusterClickListener(event, cluster, map),
                }
            },
            line: {
                getId: (data) => data.id,
                getDataOptions: (data, isSelected) => {
                    let featureStyle = {
                        strokeWeight: 5,
                        strokeColor: 'orange',
                        strokeOpacity: 0.5,
                    };

                    if (isSelected) {
                        featureStyle = {
                            ...featureStyle,
                            strokeWeight: 10,
                            strokeOpacity: 1,
                        };
                    }
                    return featureStyle;
                }
            },
        });
    }

    setupTrafficCongestion(
        trafficCongestion: TrafficCongestionData[],
        isTrafficCongestionVisible: boolean,
        selectedTrafficCongestion: TrafficCongestionData | undefined,
        trafficCongestionClickListener: (event: google.maps.MapMouseEvent, trafficCongestion: any) => void,
        clusterClickListener: (event: google.maps.MapMouseEvent, cluster: Cluster, map: google.maps.Map) => void,
    ) {
        this.setupGeoJsonData({
            dataType: 'traffic_congestion',
            isDataVisible: isTrafficCongestionVisible,
            dataList: trafficCongestion,
            selectedData: selectedTrafficCongestion,
            clickListener: (event, data) => trafficCongestionClickListener(event, data),
            marker: {
                getId: (data) => data.id,
                getMarkerOptions: (data, isSelected) => {
                    const svgEl = trafficCongestionSvg('orange', 1);
                    return {
                        content: svgEl,
                    };
                },
                cluster: {
                    algorithm: new SuperClusterViewportAlgorithm({ radius: 100 } as SuperClusterViewportOptions),
                    clusterRenderer: (info) => {
                        const scale = computeClusterScale(info.count);
                        const svgEl = trafficCongestionCluster(info.count, 'orange', scale);
                        return new google.maps.marker.AdvancedMarkerElement({
                            position: info.position,
                            map: this.map,
                            title: `Cluster of ${info.count} traffic congestion`,
                            content: svgEl,
                        });
                    },
                    onClusterClick: (event, cluster, map) => clusterClickListener(event, cluster, map),
                }
            },
            line: {
                getId: (data) => data.id,
                getDataOptions: (data, isSelected) => {
                    let featureStyle = {
                        strokeWeight: 5,
                        strokeColor: 'orange',
                        strokeOpacity: 0.5,
                    };

                    if (isSelected) {
                        featureStyle = {
                            ...featureStyle,
                            strokeWeight: 10,
                            strokeOpacity: 1,
                        };
                    }
                    return featureStyle;
                }
            },
        });
    }

    setupTrafficIncidents(
        trafficIncidents: TrafficIncidentData[],
        isTrafficIncidentsVisible: boolean,
        selectedTrafficIncident: TrafficIncidentData | undefined,
        trafficIncidentClickListener: (event: google.maps.MapMouseEvent, trafficIncident: any) => void,
        clusterClickListener: (event: google.maps.MapMouseEvent, cluster: Cluster, map: google.maps.Map) => void,
    ) {
        this.setupGeoJsonData({
            dataType: 'traffic_incident',
            isDataVisible: isTrafficIncidentsVisible,
            dataList: trafficIncidents,
            selectedData: selectedTrafficIncident,
            clickListener: (event, data) => trafficIncidentClickListener(event, data),
            marker: {
                getId: (data) => data.id,
                getMarkerOptions: (data, isSelected) => {
                    const svgEl = trafficIncidentSvg('orange', 1);
                    return {
                        content: svgEl,
                    };
                },
                cluster: {
                    algorithm: new SuperClusterViewportAlgorithm({ radius: 100 } as SuperClusterViewportOptions),
                    clusterRenderer: (info) => {
                        const scale = computeClusterScale(info.count);
                        const svgEl = trafficIncidentCluster(info.count, 'orange', scale);
                        return new google.maps.marker.AdvancedMarkerElement({
                            position: info.position,
                            map: this.map,
                            title: `Cluster of ${info.count} traffic incidents`,
                            content: svgEl,
                        });
                    },
                    onClusterClick: (event, cluster, map) => clusterClickListener(event, cluster, map),
                }
            },
            line: {
                getId: (data) => data.id,
                getDataOptions: (data, isSelected) => {
                    let featureStyle = {
                        strokeWeight: 5,
                        strokeColor: 'orange',
                        strokeOpacity: 0.5,
                    };

                    if (isSelected) {
                        featureStyle = {
                            ...featureStyle,
                            strokeWeight: 10,
                            strokeOpacity: 1,
                        };
                    }
                    return featureStyle;
                }
            },
        });
    }

    setupTruckWarnings(
        truckWarnings: TruckWarningData[],
        istruckWarningsVisible: boolean,
        selectedTruckWarning: TruckWarningData | undefined,
        truckWarningClickListener: (event: google.maps.MapMouseEvent, truckWarning: any) => void,
        clusterClickListener: (event: google.maps.MapMouseEvent, cluster: Cluster, map: google.maps.Map) => void,
    ) {
        this.setupGeoJsonData({
            dataType: 'truck_warning',
            isDataVisible: istruckWarningsVisible,
            dataList: truckWarnings,
            selectedData: selectedTruckWarning,
            clickListener: (event, data) => truckWarningClickListener(event, data),
            marker: {
                getId: (data) => data.id,
                getMarkerOptions: (data, isSelected) => {
                    const svgEl = truckerWarningSvg('orange', 1);
                    return {
                        content: svgEl,
                    };
                },
                cluster: {
                    algorithm: new SuperClusterViewportAlgorithm({ radius: 160 } as SuperClusterViewportOptions),
                    clusterRenderer: (info) => {
                        const scale = computeClusterScale(info.count) * 1.4;
                        const svgEl = truckerWarningCluster(info.count, 'orange', scale);
                        return new google.maps.marker.AdvancedMarkerElement({
                            position: info.position,
                            map: this.map,
                            title: `Cluster of ${info.count} truck warnings`,
                            content: svgEl,
                        });
                    },
                    onClusterClick: (event, cluster, map) => clusterClickListener(event, cluster, map),
                }
            },
            line: {
                getId: (data) => data.id,
                getDataOptions: (data, isSelected) => {
                    let featureStyle = {
                        strokeWeight: 5,
                        strokeColor: 'orange',
                        strokeOpacity: 0.5,
                    };

                    if (isSelected) {
                        featureStyle = {
                            ...featureStyle,
                            strokeWeight: 10,
                            strokeOpacity: 1,
                        };
                    }
                    return featureStyle;
                }
            },
        });
    }

    setupWeatherStations(
        weatherStations: WeatherStationData[],
        isWeatherStationsVisible: boolean,
        selectedWeatherStation: WeatherStationData | undefined,
        weatherStationClickListener: (event: google.maps.MapMouseEvent, weatherStation: any) => void,
        clusterClickListener: (event: google.maps.MapMouseEvent, cluster: Cluster, map: google.maps.Map) => void,
    ) {
        this.setupGeoJsonData({
            dataType: 'weather_station',
            isDataVisible: isWeatherStationsVisible,
            dataList: weatherStations,
            selectedData: selectedWeatherStation,
            clickListener: (event, data) => weatherStationClickListener(event, data),
            marker: {
                getId: (data) => data.id,
                getMarkerOptions: (data, isSelected) => {
                    const color = getRoadConditionColorForPrecipitationType(data.precipitation ? data.precipitation : data.description);
                    const svgEl = drivingConditionsSvg(color, 1);
                    return {
                        content: svgEl,
                    };
                },
                cluster: {
                    algorithm: new SuperClusterViewportAlgorithm({ radius: 100 } as SuperClusterViewportOptions),
                    clusterRenderer: (info) => {
                        const svgEl = drivingConditionsClusterSvg(info.count, info.markers);
                        return new google.maps.marker.AdvancedMarkerElement({
                            position: info.position,
                            map: this.map,
                            title: `Cluster of ${info.count} weather stations`,
                            content: svgEl,
                        });
                    },
                    onClusterClick: (event, cluster, map) => clusterClickListener(event, cluster, map),
                }
            },
            line: {
                getId: (data) => data.id,
                getDataOptions: (data, isSelected) => {
                    const color = getRoadConditionColorForPrecipitationType(data.status ? data.status : data.description);
                    let featureStyle = {
                        strokeWeight: 5,
                        strokeColor: color,
                        strokeOpacity: 0.5,
                    };

                    if (isSelected) {
                        featureStyle = {
                            ...featureStyle,
                            strokeWeight: 10,
                            strokeOpacity: 1,
                        };
                    }
                    return featureStyle;
                }
            },
        });
    }

    setupVehicles(
        vehicles: VehicleTrackingData[],
        isVehiclesVisible: boolean,
        selectedVehicle: VehicleTrackingData | undefined,
        vehicleClickListener: (event: google.maps.MapMouseEvent, vehicle: VehicleTrackingData) => void,
        clusterClickListener: (event: google.maps.MapMouseEvent, cluster: Cluster, map: google.maps.Map) => void,
    ) {
        // TODO: determine a better strategy than pretending that vehicles are lightning geojson data
        const transformedVehicles = vehicles
            .filter(v => v.latitude && v.longitude)
            .map((v: VehicleTrackingData): VehicleTrackingData & EventData => {
                return {
                    ...v,
                    type: 'lightning',
                    geoJson: {
                        type: 'Feature',
                        properties: {

                        },
                        geometry: {
                            type: 'Point',
                            coordinates: [v.longitude!, v.latitude!]
                        }
                    }
                };
            });
        const transformedSelectedVehicle: (VehicleTrackingData & EventData) | undefined = selectedVehicle ? {
            ...selectedVehicle,
            type: 'lightning',
            geoJson: {
                type: 'Feature',
                properties: {

                },
                geometry: {
                    type: 'Point',
                    coordinates: [selectedVehicle.longitude!, selectedVehicle.latitude!]
                }
            }
        } : undefined;

        this.setupGeoJsonData({
            dataType: 'vehicle',
            isDataVisible: isVehiclesVisible,
            dataList: transformedVehicles,
            selectedData: transformedSelectedVehicle,
            clickListener: (event, data) => vehicleClickListener(event, data),
            marker: {
                getId: (data) => data.id,
                getMarkerOptions: (data, isSelected) => {
                    const overallImpactLevel = data.currentImpact?.overallImpactLevel ?? ImpactLevel.Unknown;
                    return {
                        content: vehicleSvg(tagColorForImpactLevel(overallImpactLevel), 1.0, isSelected ? 1.0 : 0.5, data.bearing),
                    };
                },
                addMetadataToMarker: (data, marker) => {
                    (marker as any).metadata = {
                        overallImpactLevel: data.currentImpact?.overallImpactLevel
                    };
                },
                cluster: {
                    algorithm: new SuperClusterViewportAlgorithm({ radius: 100, maxZoom: 12 } as SuperClusterViewportOptions),
                    clusterRenderer: (info) => {
                        const markers = info.markers ?? [];
                        // const highestImpactLevel = Math.max(...markers.map(marker => ((marker as any).metadata.overallImpactLevel ?? ImpactLevel.Unknown) as ImpactLevel));
                        // const svgEl = genericClusterSvg(tagColorForImpactLevel(highestImpactLevel), info.count);

                        // Code for clusters that are pie charts representing impact
                        // TODO: how do we disambiguate with locations?
                        const markersInImpactLevel = (impactLevel: ImpactLevel) => markers.filter(marker => (marker as any).metadata.overallImpactLevel === impactLevel);
                        const impactLevels = [ImpactLevel.None, ImpactLevel.Low, ImpactLevel.Moderate, ImpactLevel.High, ImpactLevel.Extreme];
                        const segments = impactLevels.map(impactLevel => {
                            const color = colorForImpactLevel(impactLevel);
                            return { color, fraction: markersInImpactLevel(impactLevel).length / info.count };
                        });
                        const svgEl = createImpactClusterSVG(segments, info.count);

                        return new google.maps.marker.AdvancedMarkerElement({
                            position: info.position,
                            map: this.map,
                            title: `Cluster of ${info.count} vehicles`,
                            content: svgEl,
                        });
                    },
                    onClusterClick: (event, cluster, map) => clusterClickListener(event, cluster, map),
                }
            },
        });
    }

    setupGeoJsonData<T extends EventData>(config: VisualizationConfig<T>) {
        // TODO: prevent concurrent runs for the same dataType?
        this.setupGeoJsonDataAsync(config)
            .then(
                () => console.log(`finished loading geojson data for ${config.dataType}`),
                (reason) => console.error(`failed to load geojson data for ${config.dataType}`, reason),
            );
    }

    async setupGeoJsonDataAsync<T extends EventData>(config: VisualizationConfig<T>) {
        console.log(`setupGeoJsonData for ${config.dataType}: ${config.dataList.length} items, isVisible? ${config.isDataVisible}`);

        let metadata = this.geoJsonDataMetadata[config.dataType];
        if (metadata === undefined) {
            metadata = this.geoJsonDataMetadata[config.dataType] = {};
        }
        metadata.markers ||= {};
        metadata.lines ||= {};
        metadata.polygons ||= {};

        const clusterMetadata = config.marker?.cluster;
        if (clusterMetadata && metadata.markerClusterer === undefined) {
            const clusterRenderer: Renderer = {
                render: (cluster: Cluster, stats: ClusterStats, map: google.maps.Map) => {
                    return clusterMetadata.clusterRenderer(cluster, stats, map);
                }
            };

            metadata.markerClusterer = new MarkerClusterer({
                markers: [],
                algorithm: clusterMetadata.algorithm,
                onClusterClick: clusterMetadata.onClusterClick,
                renderer: clusterRenderer,
            });
        }

        // tracks if we've done something that would require re-rendering the clusters
        // like removing or adding markers, we defer all add/remove redraws by passing
        // true to noDraw for add/remove calls
        let shouldRenderClusters = false;
        // we only want to call setMap when the value is changing to prevent flickering
        if (!metadata.markerClusterer?.getMap() && config.isDataVisible) {
            shouldRenderClusters = true;
            metadata.markerClusterer?.setMap(this.map);
        } else if (metadata.markerClusterer?.getMap() && !config.isDataVisible) {
            metadata.markerClusterer?.setMap(null);
        }

        // defer the lag to when you enable the data
        if (!config.isDataVisible) {
            // clean up the visible stuff
            if (config.marker?.cluster === undefined) {
                Object.values(metadata.markers).forEach(m => m.map = null);
            }
            Object.values(metadata.lines).forEach(m => m.setMap(null));
            Object.values(metadata.polygons).forEach(m => m.setMap(null));
            return;
        }

        if (config.marker) {
            const markerDataList = Object.fromEntries(
                config.dataList
                    .filter(x => x.geoJson.geometry.type === 'Point')
                    .filter(x => config.marker!.shouldRender?.(x) !== false)
                    .map(x => [config.marker!.getId(x), x])
            );

            // track which ids we want to create
            const markerIdsToVisualize = new Set(Object.keys(markerDataList));
            metadata.markers = Object.fromEntries(Object.entries(metadata.markers).filter(([id, marker]) => {
                if (markerIdsToVisualize.has(id)) {
                    // we already have a marker for this id -- keep the old marker
                    return true;
                } else {
                    // this marker is no longer needed so we remove it from
                    // the clusterer/map and filter it from the markers dict by returning false here
                    if (metadata.markerClusterer) {
                        shouldRenderClusters = true;
                        metadata.markerClusterer!.removeMarker(marker, true);
                    } else {
                        marker.map = null;
                    }
                    return false;
                }
            }));

            console.time(`adding ${config.dataType} markers`);

            let numMarkersAdded = 0;
            const selectedId = config.selectedData ? config.marker!.getId(config.selectedData) : undefined;
            const previouslySelectedId = metadata.previouslySelectedData ? config.marker!.getId(metadata.previouslySelectedData) : undefined;
            for (const [id, data] of Object.entries(markerDataList)) {
                const pointGeometry = data.geoJson.geometry as GeoJSON.Point;
                const lng = pointGeometry.coordinates[0];
                const lat = pointGeometry.coordinates[1];

                // if we already have a marker for this event
                // we can reuse it otherwise we create a new marker
                let marker = metadata.markers?.[id];
                let addingNewMarker = false;
                if (marker === undefined) {
                    addingNewMarker = true;
                    // new marker means we need to update clusters
                    shouldRenderClusters = true;

                    const markerOptions = config.marker!.getMarkerOptions(data, id === selectedId, marker);
                    marker = new google.maps.marker.AdvancedMarkerElement({
                        position: { lng, lat },
                        ...markerOptions,
                    });

                    marker.addListener('click', (event: google.maps.MapMouseEvent) => config.clickListener(event, (marker as any).data));

                    numMarkersAdded += 1;

                    if (config.marker!.yieldTimeout !== undefined && numMarkersAdded % 100 === 0) {
                        // got this from https://calendar.perfplanet.com/2023/yielding-main-thread-breaking-up-tasks-fix-inp/
                        // Yield control back to the browser's scheduler using setTimeout
                        // await new Promise(resolve => setTimeout(resolve, config.marker!.yieldTimeout));
                        // await new Promise(resolve => requestAnimationFrame(resolve));
                        await new Promise(resolve => requestIdleCallback(resolve, { timeout: config.marker!.yieldTimeout }));
                    }
                }

                // always add new metadata to the marker
                config.marker?.addMetadataToMarker?.(data, marker);
                // update data on marker for click listener
                (marker as any).data = { ...data };

                // store the marker for later
                metadata.markers![id] = marker;

                let shouldUpdateMarker = false;
                switch (config.marker!.redrawMode ?? 'always') {
                    case 'never':
                        shouldUpdateMarker = !addingNewMarker;
                        break;
                    case 'on-selection':
                        shouldUpdateMarker = !addingNewMarker && (id === selectedId || id === previouslySelectedId);
                        break;
                    case 'always':
                        shouldUpdateMarker = !addingNewMarker;
                        break;
                }

                if (shouldUpdateMarker) {
                    // TODO: we should apply other options to the existing marker
                    const markerOptions = config.marker!.getMarkerOptions(data, id === selectedId, marker);
                    marker.position = { lng, lat };
                    marker.content = markerOptions.content;
                    marker.collisionBehavior = markerOptions.collisionBehavior;
                }

                // add to the map
                if (addingNewMarker && metadata.markerClusterer) {
                    metadata.markerClusterer!.addMarker(marker, true);
                }
                if (metadata.markerClusterer === undefined && !marker.map) {
                    marker.map = this.map;
                }
            }

            console.timeEnd(`adding ${config.dataType} markers`);
        }

        if (config.line) {
            const lineDataList = Object.fromEntries(
                config.dataList
                    .filter(x => x.geoJson.geometry.type === 'LineString' || x.geoJson.geometry.type === 'MultiLineString')
                    .filter(x => config.line!.shouldRender?.(x) !== false)
                    .map(x => [config.line!.getId(x), x])
            );
            // track which ids we want to create
            const lineIdsToVisualize = new Set(Object.keys(lineDataList));
            metadata.lines = Object.fromEntries(Object.entries(metadata.lines).filter(([id, data]) => {
                // we already have a line data for this id  -- keep the old data
                if (lineIdsToVisualize.has(id)) {
                    return true;
                } else {
                    // this line data is no longer needed so we remove it from the map and
                    // filter it from the lines dict by returning false here
                    data.setMap(null);
                    return false;
                }
            }));

            const selectedId = config.selectedData ? config.line!.getId(config.selectedData) : undefined;
            // const previouslySelectedId = metadata.previouslySelectedData ? config.line!.getId(metadata.previouslySelectedData) : undefined;
            Object.entries(lineDataList).forEach(([id, data]) => {
                const featureStyle = config.line!.getDataOptions(data, id === selectedId);
                let lineMapData = metadata.lines![id];
                if (lineMapData === undefined) {
                    lineMapData = new google.maps.Data();
                    lineMapData.addGeoJson(data.geoJson);
                    lineMapData.setStyle(featureStyle);
                    lineMapData.setMap(this.map);
                    (lineMapData as any).data = { ...data };
                    metadata.lines![id] = lineMapData;

                    lineMapData.addListener('click', (event: google.maps.MapMouseEvent) => {
                        const clickedPoint = event.latLng ? {
                            latitude: event.latLng.lat(),
                            longitude: event.latLng.lng(),
                        } : undefined;
                        config.clickListener(event, { ...(lineMapData as any).data, clickedPoint });
                    });
                } else {
                    lineMapData.setStyle(featureStyle);
                    if (lineMapData.getMap() === null) {
                        lineMapData.setMap(this.map);
                    }
                }
            });
        }

        if (config.polygon) {
            const polygonDataList = Object.fromEntries(
                config.dataList
                    .filter(x => x.geoJson.geometry.type === 'Polygon' || x.geoJson.geometry.type === 'MultiPolygon')
                    .filter(x => config.polygon!.shouldRender?.(x) !== false)
                    .map(x => [config.polygon!.getId(x), x])
            );
            // track which ids we want to create
            const polygonIdsToVisualize = new Set(Object.keys(polygonDataList));
            metadata.polygons = Object.fromEntries(Object.entries(metadata.polygons).filter(([id, data]) => {
                // we already have a polygon data for this id  -- keep the old data
                if (polygonIdsToVisualize.has(id)) {
                    return true;
                } else {
                    // this polygon data is no longer needed so we remove it from the map and
                    // filter it from the polygons dict by returning false here
                    data.setMap(null);
                    return false;
                }
            }));

            const selectedId = config.selectedData ? config.polygon!.getId(config.selectedData) : undefined;
            // const previouslySelectedId = metadata.previouslySelectedData ? config.polygon!.getId(metadata.previouslySelectedData) : undefined;
            Object.entries(polygonDataList).forEach(([id, data]) => {
                const featureStyle = config.polygon!.getDataOptions(data, id === selectedId);
                let polygonMapData = metadata.polygons![id];
                if (polygonMapData === undefined) {
                    polygonMapData = new google.maps.Data();
                    polygonMapData.addGeoJson(data.geoJson);
                    polygonMapData.setStyle(featureStyle);
                    polygonMapData.setMap(this.map);
                    (polygonMapData as any).data = { ...data };
                    metadata.polygons![id] = polygonMapData;

                    polygonMapData.addListener('click', (event: google.maps.MapMouseEvent) => {
                        const clickedPoint = event.latLng ? {
                            latitude: event.latLng.lat(),
                            longitude: event.latLng.lng(),
                        } : undefined;
                        config.clickListener(event, { ...(polygonMapData as any).data, clickedPoint });
                    });
                } else {
                    polygonMapData.setStyle(featureStyle);
                    if (polygonMapData.getMap() === null) {
                        polygonMapData.setMap(this.map);
                    }
                }
            });
        }

        metadata.previouslySelectedData = config.selectedData;

        if (metadata.markerClusterer && shouldRenderClusters) {
            console.log('rendering cluster after markers were changed');
            setTimeout(this.jiggleMap.bind(this), 0);
        }
    }

    // this 'hack' is used to re-render the markers/clusters
    // when a user toggles them to be visible
    // things that didn't work:
    // - calling render on the clusterer (dont show up)
    // - clearing and re-adding the markers on the clusterer (dont show up)
    // - using a custom algorithm that returns true for changed (shows clusters with 0 size)
    jiggleMap() {
        if (!this.map) return;

        const center = this.map.getCenter();
        if (!center) return;

        this.map.panTo({ lat: center.lat() + 0.000001, lng: center.lng() + 0.000001 });
    }
}