import { Close } from '@mui/icons-material';
import { FormControlLabel, IconButton, Paper, Switch } from '@mui/material';
import * as React from 'react';
import { cardBackgroundColor } from 'src/constants';
import { CycloneData, EarthquakeData, FireData, LightningData, PowerOutageData, RoadClosureData, RoadStatusData, RoadWorkData, SpecialEventData, StormData, StormReportData, TrafficCongestionData, TrafficIncidentData, TruckWarningData, VolcanoData, WeatherStationData, WildfireData } from 'src/types';
import { getEventDescription } from 'src/types';

interface EventDescriptionComponentProps {
    allowClose: boolean;

    selectedCyclone?: CycloneData;
    showModelForecastTracksForCycloneId?: string;
    selectedEarthquake?: EarthquakeData;
    selectedLightning?: LightningData;
    selectedPowerOutage?: PowerOutageData;
    selectedStorm?: StormData;
    selectedStormReport?: StormReportData;
    selectedVolcano?: VolcanoData;
    selectedFire?: FireData;
    selectedWildfire?: WildfireData;
    selectedRoadStatus?: RoadStatusData;
    selectedRoadWork?: RoadWorkData;
    selectedRoadClosure?: RoadClosureData;
    selectedSpecialEvent?: SpecialEventData;
    selectedTrafficCongestion?: TrafficCongestionData;
    selectedTrafficIncident?: TrafficIncidentData;
    selectedTruckWarning?: TruckWarningData;
    selectedWeatherStation?: WeatherStationData;

    isCyclonesVisible?: boolean;
    isEarthquakesVisible?: boolean;
    isLightningVisible?: boolean;
    isPowerOutagesVisible?: boolean
    isStormsVisible?: boolean;
    isStormReportsVisible?: boolean;
    isVolcanoesVisible?: boolean;
    isFiresVisible?: boolean;
    isWildfiresVisible?: boolean;
    isRoadStatusVisible?: boolean;
    isRoadWorkVisible?: boolean;
    isRoadClosuresVisible?: boolean;
    isSpecialEventsVisible?: boolean;
    isTrafficCongestionVisible?: boolean;
    isTrafficIncidentsVisible?: boolean;
    isTruckWarningsVisible?: boolean;
    isWeatherStationsVisible?: boolean;

    onShowModelForecastTracksForCycloneIdSelected?: (cycloneId: string | undefined) => void;
    onClearSelectedEvent?: () => void;
    onClearSelectedRoadCondition?: () => void;
}

export const EventDescriptionComponent = (props: EventDescriptionComponentProps) => {
    let eventIcon = undefined;
    let eventName = undefined;
    let eventDescription = undefined;

    let mainClassName = "LayerDescriptionComponent";

    React.useEffect(() => {
        // if we select a cyclone that isn't the one we're showing forecast tracks for,
        // then turn off the forecast tracks for the previous cyclone
        if (props.selectedCyclone?.cycloneId !== props.showModelForecastTracksForCycloneId) {
            props.onShowModelForecastTracksForCycloneIdSelected?.(undefined);
        }
    }, [props.selectedCyclone]);

    if (props.isCyclonesVisible && props.selectedCyclone) {
        const cyclone: CycloneData = props.selectedCyclone;
        const backgroundColor = cyclone.geoJson.properties?.['marker-color'] ?? 'black';
        eventIcon = <div style={{ width: '24px', height: '24px', borderRadius: '50%', backgroundColor, flexShrink: 0, marginRight: '10px' }}></div>;
        eventName = cyclone.name;
        eventDescription = `<div>${getEventDescription(cyclone, 'cyclone')}</div>`;
    }

    if (props.isEarthquakesVisible && props.selectedEarthquake) {
        const earthquake: EarthquakeData = props.selectedEarthquake;
        eventIcon = <div style={{ width: '24px', height: '24px', borderRadius: '50%', flexShrink: 0, marginRight: '10px', marginBottom: '10px' }}><span style={{ fontSize: "2em", verticalAlign: "middle" }}>❗</span></div>;
        eventName = 'Earthquake';
        eventDescription = `<div>${getEventDescription(earthquake, 'earthquake')}</div>`;
    }

    if (props.isLightningVisible && props.selectedLightning) {
        const lightning: LightningData = props.selectedLightning;
        eventIcon = <div style={{ width: '24px', height: '24px', borderRadius: '50%', flexShrink: 0, marginRight: '10px', marginBottom: '10px' }}><span style={{ fontSize: "2em", verticalAlign: "middle" }}>⚡</span></div>;
        eventName = 'Lightning';
        eventDescription = `<div>${getEventDescription(lightning, 'lightning')}</div>`;
    }

    if (props.isPowerOutagesVisible && props.selectedPowerOutage) {
        const powerOutage: PowerOutageData = props.selectedPowerOutage;
        eventIcon = <div style={{ width: '24px', height: '24px', borderRadius: '50%', backgroundColor: props.selectedPowerOutage.fill || "black", flexShrink: 0, marginRight: '10px' }}></div>;
        eventName = 'Power Outage';
        eventDescription = `<div>${getEventDescription(powerOutage, 'powerOutage')}</div>`;
    }

    if (props.isStormsVisible && props.selectedStorm) {
        const storm: StormData = props.selectedStorm;
        eventIcon = <div style={{ width: '24px', height: '24px', borderRadius: '50%', backgroundColor: props.selectedStorm.geoJson.properties?.['stroke'] || "black", flexShrink: 0, marginRight: '10px' }}></div>;
        eventName = storm.stormType;
        eventDescription = `<div>${getEventDescription(storm, 'storm')}</div>`;
    }

    if (props.isStormReportsVisible && props.selectedStormReport) {
        const stormReport: StormReportData = props.selectedStormReport;
        eventIcon = <div style={{ width: '24px', height: '24px', borderRadius: '50%', flexShrink: 0, marginRight: '10px', marginBottom: '10px' }}><span style={{ fontSize: "2em", verticalAlign: "middle" }}>🔍</span></div>;
        eventName = stormReport.reportType;
        eventDescription = `<div>${getEventDescription(stormReport, 'stormReport')}</div>`;
    }

    if (props.isVolcanoesVisible && props.selectedVolcano) {
        const volcano: VolcanoData = props.selectedVolcano;
        eventIcon = <div style={{ width: '24px', height: '24px', borderRadius: '50%', flexShrink: 0, marginRight: '10px', marginBottom: '10px' }}><span style={{ fontSize: "2em", verticalAlign: "middle" }}>🌋</span></div>;
        eventName = volcano.name;
        eventDescription = `<div>${getEventDescription(volcano, 'volcano')}</div>`;
    }

    if (props.isFiresVisible && props.selectedFire) {
        const f: FireData = props.selectedFire;
        eventIcon = <div style={{ width: '24px', height: '24px', borderRadius: '50%', flexShrink: 0, marginRight: '10px', marginBottom: '10px' }}><span style={{ fontSize: "2em", verticalAlign: "middle" }}>🔥</span></div>;
        eventName = "Fire";
        eventDescription = `<div>${getEventDescription(f, 'fire')}</div>`;
    }

    if (props.isWildfiresVisible && props.selectedWildfire) {
        const wf: WildfireData = props.selectedWildfire;
        eventIcon = <div style={{ width: '24px', height: '24px', borderRadius: '50%', flexShrink: 0, marginRight: '10px', marginBottom: '10px' }}><span style={{ fontSize: "2em", verticalAlign: "middle" }}>🔥</span></div>;
        eventName = wf.name !== undefined ? wf.name : "Wildfire";
        eventDescription = `<div>${getEventDescription(wf, 'wildfire')}</div>`;
    }

    if (props.isRoadStatusVisible && props.selectedRoadStatus) {
        const roadStatus: RoadStatusData = props.selectedRoadStatus;
        eventIcon = <div style={{ width: '24px', height: '24px', borderRadius: '50%', flexShrink: 0, marginRight: '10px', marginBottom: '10px' }}><span style={{ fontSize: "2em", verticalAlign: "middle" }}>❗</span></div>;
        eventName = 'Road Status';
        eventDescription = `<div>${getEventDescription(roadStatus, 'roadStatus')}</div>`;
    }

    if (props.isRoadWorkVisible && props.selectedRoadWork) {
        const roadWork: RoadWorkData = props.selectedRoadWork;
        eventIcon = <div style={{ width: '24px', height: '24px', borderRadius: '50%', flexShrink: 0, marginRight: '10px', marginBottom: '10px' }}><span style={{ fontSize: "2em", verticalAlign: "middle" }}>🚧</span></div>;
        eventName = "Road Work";
        eventDescription = `<div>${getEventDescription(roadWork, 'roadWork')}</div>`;
    }

    if (props.isRoadClosuresVisible && props.selectedRoadClosure) {
        const roadClosure: RoadClosureData = props.selectedRoadClosure;
        eventIcon = <div style={{ width: '24px', height: '24px', borderRadius: '50%', flexShrink: 0, marginRight: '10px', marginBottom: '10px' }}><span style={{ fontSize: "2em", verticalAlign: "middle" }}>⛔</span></div>;
        eventName = "Road Closure";
        eventDescription = `<div>${getEventDescription(roadClosure, 'roadClosure')}</div>`;
    }

    if (props.isSpecialEventsVisible && props.selectedSpecialEvent) {
        const specialEvent: SpecialEventData = props.selectedSpecialEvent;
        eventIcon = <div style={{ width: '24px', height: '24px', borderRadius: '50%', flexShrink: 0, marginRight: '10px', marginBottom: '10px' }}><span style={{ fontSize: "2em", verticalAlign: "middle" }}>⚠</span></div>;
        eventName = 'Special Event';
        eventDescription = `<div>${getEventDescription(specialEvent, 'specialEvent')}</div>`;
    }

    if (props.isTrafficCongestionVisible && props.selectedTrafficCongestion) {
        const trafficCongestion: TrafficCongestionData = props.selectedTrafficCongestion;
        eventIcon = <div style={{ width: '24px', height: '24px', borderRadius: '50%', flexShrink: 0, marginRight: '10px', marginBottom: '10px' }}><span style={{ fontSize: "2em", verticalAlign: "middle" }}>🚦</span></div>;
        eventName = 'Traffic';
        eventDescription = `<div>${getEventDescription(trafficCongestion, 'trafficCongestion')}</div>`;
    }

    if (props.isTrafficIncidentsVisible && props.selectedTrafficIncident) {
        const trafficIncident: TrafficIncidentData = props.selectedTrafficIncident;
        eventIcon = <div style={{ width: '24px', height: '24px', borderRadius: '50%', flexShrink: 0, marginRight: '10px', marginBottom: '10px' }}><span style={{ fontSize: "2em", verticalAlign: "middle" }}>🚓</span></div>;
        eventName = 'Traffic Incident';
        eventDescription = `<div>${getEventDescription(trafficIncident, 'trafficIncident')}</div>`;
    }

    if (props.isTruckWarningsVisible && props.selectedTruckWarning) {
        const truckWarning: TruckWarningData = props.selectedTruckWarning;
        eventIcon = <div style={{ width: '24px', height: '24px', borderRadius: '50%', flexShrink: 0, marginRight: '10px', marginBottom: '10px' }}><span style={{ fontSize: "2em", verticalAlign: "middle" }}>🚛</span></div>;
        eventName = 'Truck Warning';
        eventDescription = `<div>${getEventDescription(truckWarning, 'truckWarning')}</div>`;
    }

    if (props.isRoadStatusVisible && props.selectedWeatherStation) {
        const weatherStation: WeatherStationData = props.selectedWeatherStation;
        eventIcon = <div style={{ width: '24px', height: '24px', borderRadius: '50%', flexShrink: 0, marginRight: '10px', marginBottom: '10px' }}><span style={{ fontSize: "2em", verticalAlign: "middle" }}>❗</span></div>;
        eventName = 'Weather Station';
        eventDescription = `<div>${getEventDescription(weatherStation, 'weatherStation')}</div>`;
    }

    return eventDescription ? (
        <div className={`${mainClassName}-Container`}>
            <Paper className={mainClassName} elevation={3} style={{ backgroundColor: cardBackgroundColor, backgroundImage: 'none', borderRadius: 11, padding: 10, height: "95%" }}>
                <header className={"layer-description-header"}>
                    {eventIcon}
                    <h3 style={{ "width": "80%", "display": "flex", "justifyContent": "flex-start" }}>{eventName}</h3>
                    {props.allowClose ? <IconButton
                        color={'primary'}
                        onClick={() => {
                            props.onClearSelectedEvent && props.onClearSelectedEvent();
                            props.onClearSelectedRoadCondition && props.onClearSelectedRoadCondition();
                        }}
                        style={{ height: 30 }}
                    >
                        <Close />
                    </IconButton> : <div />
                    }
                </header >
                <div className={"layer-description-text"}>
                    {props.selectedCyclone && props.selectedCyclone.hasModelForecastTracks && <React.Fragment>
                        <FormControlLabel
                            control={<Switch
                                checked={props.showModelForecastTracksForCycloneId === props.selectedCyclone.cycloneId}
                                onChange={event => props.onShowModelForecastTracksForCycloneIdSelected?.(event.target.checked ? props.selectedCyclone?.cycloneId : undefined)}
                            />}
                            label="Model Forecast Tracks"
                            labelPlacement="start"
                            sx={{ marginLeft: '0px' }}
                        />
                    </React.Fragment>}

                    {eventDescription !== undefined ?
                        <div dangerouslySetInnerHTML={{ __html: eventDescription }} />
                        : eventName}
                </div>
            </Paper >
        </div >
    ) : <div />;
};