import IndexPage from '../components/IndexPage';
import { StoreState } from '../types/index';
import { connect } from 'react-redux';
import {Action, Dispatch} from 'redux';

export function mapStateToProps(state: StoreState) {
    return {
        user: state.user
    };
}

export function mapDispatchToProps(_dispatch: Dispatch<Action>) {
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(IndexPage);
