import * as React from "react";
import { PolylineType } from ".";
import { MenuItem, Select } from "@mui/material";

interface Props {
    type: PolylineType;

    setPolylineType: (polylineType: PolylineType) => void;
}

export const PolylineLegend = (props: Props) => {
    return (
        <div style={{ display: 'inline-block' }}>
            <div className={"impact-legend-numbers"}>
                <div
                    key={"heading"}
                    className={"impact-legend-rating"}
                    style={{ backgroundColor: "#2e3438", color: 'white' }}
                >
                    <Select
                        value={props.type}
                        variant={'outlined'}
                        style={{ height: 23 }}
                        onChange={(event) => props.setPolylineType(event.target.value as PolylineType)}
                        sx={{ fontSize: '12px', fontWeight: 'bold' }}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'right',
                            },
                            transformOrigin: {
                                vertical: 'bottom',
                                horizontal: 'right',
                            }
                        }}
                    >
                        <MenuItem sx={{ fontSize: '12px', fontWeight: 'bold' }} value={'slowdown'}>Slowdown</MenuItem>
                        <MenuItem sx={{ fontSize: '12px', fontWeight: 'bold' }} value={'road-risk'}>Road Risk</MenuItem>
                    </Select>
                </div>

                <div
                    key={`impact-legend-rating-low`}
                    className={`impact-legend-rating`}
                    style={{ backgroundColor: '#58D05D', color: 'white', width: 67 }}
                >
                    {props.type === "slowdown" ? <>0-5%</> : <>Low</>}
                </div>

                <div
                    key={`impact-legend-rating-med`}
                    className={`impact-legend-rating`}
                    style={{ backgroundColor: '#FD8C44', color: 'white', width: 67 }}
                >
                    {props.type === "slowdown" ? <>5-10%</> : <>Moderate</>}
                </div>

                <div
                    key={`impact-legend-rating-high`}
                    className={`impact-legend-rating`}
                    style={{ backgroundColor: '#F1352C', color: 'white', width: 67 }}
                >
                    {props.type === "slowdown" ? <>{'>'}10%</> : <>High</>}
                </div>
            </div>
        </div>
    );
};