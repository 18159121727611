import * as React from "react";
import './index.css';
import { RouteAndMarkerMap, defaultRouteAndMarkerMapProps } from "../../shared/RouteAndMarkerMap";
import { Button, MenuItem, Paper, Select, TextField } from "@mui/material";
import { IMPACT_TILES_ENDPOINT, cardBackgroundColor } from "src/constants";
import { ImpactTileset } from "src/types";

export interface Props {
    token?: string;
}

export const CogViewerPage = (props: Props) => {
    const { token } = props;

    const [urlInput, setUrlInput] = React.useState('');
    const [colorMap, setColorMap] = React.useState('disruption');
    const [submitted, setSubmitted] = React.useState(false);
    const [desiredTileLayer, setDesiredTileLayer] = React.useState<ImpactTileset | undefined>(undefined);

    const colorMaps = [
        'road', 'disruption', 'power_v2', 'flood', 'life_property',
        'temperature', 'rain_accumulation', 'snow_accumulation', 'wind_speed', 'wind_gust', 'lightning_probability',
        'radar',
    ];

    // process tile url params
    React.useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);

        const cogURL = urlParams.get('cog-url');
        if (cogURL) {
            console.log('CogViewerPage', 'linked cog url:', cogURL);
            setUrlInput(cogURL);
            const urlParts = cogURL.split('/');
            // could be more robust but good enough for now
            const mapPart = urlParts[urlParts.length - 1];
            console.log(mapPart);
            for (const map of colorMaps) {
                if (mapPart.includes(map)) {
                    setColorMap(map);
                    setSubmitted(true);
                }
            }
        }
    }, [window.location.search]);

    React.useEffect(() => {
        if (!submitted) return;

        let formattedUrl = `${IMPACT_TILES_ENDPOINT}/tiles/WebMercatorQuad/{z}/{x}/{y}@{size}x.png?url=${urlInput}&colormap_name=${colorMap}&token={token}`;
        if (colorMap === 'road') {
            formattedUrl += '&mask_to_roads=true';
        }
        const tileset: ImpactTileset = {
            id: formattedUrl,
            variable: colorMap,
            time: new Date(),
            url: formattedUrl,
        };
        setDesiredTileLayer(tileset);

        setSubmitted(false);
    }, [submitted]);

    const urlInputComponent = (
        <div className={'alert-info'}>
            <Paper elevation={3} style={{
                backgroundColor: cardBackgroundColor,
                backgroundImage: 'none',
                padding: 15,
                borderRadius: 11,
                position: 'absolute',
                left: '12px',
                top: '12px',
                width: '300px',
            }}>
                <TextField
                    autoFocus={true}
                    margin="dense"
                    fullWidth={true}
                    variant="standard"
                    id="url"
                    label="GCS URL to COG"
                    type="text"
                    value={urlInput}
                    onChange={(event) => {
                        setUrlInput(event.target.value as string);
                    }}
                />

                <Select
                    value={colorMap}
                    variant={'outlined'}
                    style={{ width: 235, height: 40 }}
                    onChange={(event) => setColorMap(event.target.value as string)}
                >
                    {colorMaps.map(key => (<MenuItem value={key}>{key}</MenuItem>))}
                </Select>

                <Button onClick={() => setSubmitted(true)}>Submit</Button>
            </Paper>
        </div>
    );

    return (
        <div className={"CogViewer"}>
            <RouteAndMarkerMap
                {...defaultRouteAndMarkerMapProps}

                initialCenter={{ lat: 39.82, lng: -98.57 }}
                padding={{ bottom: 300 }}
                zoomLevel={8}
                // enum that's in the types says it should be 3 to get it to be bottom right
                // but that gives top right, 9 gets it to bottom right
                zoomControlOptions={{ position: 9.0 }}

                portalToken={token}
                desiredTileLayer={desiredTileLayer}

                isLocationsVisible={false}
                savedCities={[]}

                onCitySelected={(city) => { }}
                onCenterChanged={(_center) => { }}
                onZoomLevelChanged={(zoomLevel) => { }}
                onNotificationsAvailable={(newNotifications) => { }}
                onTrackingModeToggled={(trackingMode) => { }}
                onPolylineSelected={(polyline) => { }}
            />

            {urlInputComponent}
        </div>
    );
};


