import * as React from 'react';
import { LightningData, LocationData, WeatherData, haversineDistance } from '../../../types';
import { Timeframe } from "./data";
import { CircularProgress } from "@mui/material";
import { fetchWeatherDataOnce } from 'src/actions/Weather';
import WeatherTimelineChart from './WeatherTimelineChart';

interface Props {
    token: string;
    portalToken: string;
    location: LocationData;
    lightning?: LightningData[];
    timeframe: Timeframe;
    height: number;
}

export const WeatherTimelineComponent = (props: Props) => {
    const { token, portalToken, location, timeframe } = props;
    const [weatherData, setWeatherData] = React.useState<WeatherData | undefined>(undefined);

    const fetchWeatherData = React.useMemo(
        () => {
            return () => {
                return fetchWeatherDataOnce(token, portalToken, location, new AbortController(), location.impactSummariesRatingRunTime, true);
            };
        },
        [token, location?.id]
    );

    React.useEffect(() => {
        // clear initial state when component is reused
        setWeatherData(undefined);

        fetchWeatherData().then(value => {
            const baseTime = value?.subhourly !== undefined ? value.subhourly[0].time : value.hourly[0].time;
            const lightningValues = [...Array(402).fill(0)];
            props.lightning?.forEach(strike => {
                const timeIndex = (Math.abs(strike.detectedTime.getTime() - baseTime!.getTime()) / (15 * 60 * 1000)).toFixed(0);
                const coords = strike.geoJson["geometry"]["coordinates"];
                const distance = haversineDistance({ lat: location.latitude, lng: location.longitude }, { lat: coords[1], lng: coords[0] }, true);
                if (distance <= 10) {
                    if (lightningValues[timeIndex] < 3) lightningValues[timeIndex] = 3;
                } else if (distance <= 20) {
                    if (lightningValues[timeIndex] < 2) lightningValues[timeIndex] = 2;
                } else if (distance <= 30) {
                    if (lightningValues[timeIndex] < 1) lightningValues[timeIndex] = 1;
                }
            });

            setWeatherData(value);
        });

    }, [fetchWeatherData]);

    if (weatherData === undefined) {
        return (
            <div style={{ backgroundColor: 'rgb(44, 52, 59)' }}>
                <div style={{ height: props.height, backgroundColor: 'rgba(144, 202, 249, 0.16)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <CircularProgress />
                </div>
            </div>
        );
    }
    return (
        <div style={{ backgroundColor: 'rgb(44, 52, 59)' }}>
            <div style={{ backgroundColor: 'rgba(144, 202, 249, 0.16)' }}>
                <WeatherTimelineChart
                    id={`${location.id}-${'timeline'}`}
                    weatherData={weatherData}
                    timeframe={timeframe}
                    impactSummariesUpdatedAt={location.impactSummariesUpdatedAt}
                    height={props.height}
                />
            </div>
        </div>
    );
};
