import { Card } from '@mui/material';
import * as React from 'react';
import { realColorForSlowdownColor } from '../../../types/routes';

export const SlowdownLegendComponent = () => {
    return (
        <Card className={'route-legend'}>
            <div
                key={"heading"}
                className={"impact-legend-rating"}
                style={{ color: 'white' }}
            >
                Slowdown
            </div>
            <div
                key={`green`}
                className={`impact-legend-rating`}
                style={{ backgroundColor: realColorForSlowdownColor('green'), color: 'white', width: 67 }}
            >
                0-5%
            </div>
            <div
                key={`yellow`}
                className={`impact-legend-rating`}
                style={{ backgroundColor: realColorForSlowdownColor('yellow'), color: 'white', width: 67 }}
            >
                5-10%
            </div>
            <div
                key={`red`}
                className={`impact-legend-rating`}
                style={{ backgroundColor: realColorForSlowdownColor('red'), color: 'white', width: 67 }}
            >
                {'>'}10%
            </div>
        </Card>
    );
};
