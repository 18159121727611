import React from 'react';
import * as d3 from 'd3';
import {Selection} from 'd3-selection';

export const useD3 = (renderChartFn: (el: Selection<SVGSVGElement, any, null, undefined>) => void, dependencies: React.DependencyList) => {
    const ref = React.useRef<SVGSVGElement>(null);

    React.useEffect(() => {
        if (ref.current === null) return;
        renderChartFn(d3.select<SVGSVGElement, any>(ref.current));
        return () => {};
    }, dependencies);

    return ref;
};
