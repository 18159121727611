import * as React from 'react';
import { RatingKey, isImpactKey } from '../../../types/RatingKey';
import ReactTooltip from 'react-tooltip';
import {
    Blurb, ClientId, UserState, isValidUserForFeature,
} from '../../../types';
import { cardBackgroundColor } from "../../../constants";
import { Slider, Typography } from '@mui/material';

export class LegendComponent extends React.Component {
    props: {
        userData?: UserState;
        blurbs: Blurb[];
        ratingKey?: RatingKey;

        skipFirstBlurb: boolean;
        embedValues: boolean;

        legendTitle?: string;
        tooltipPlacement?: ReactTooltip.Place;
        tileOpacity?: number
        setTileOpacity?: (tileOpacity: number) => void;
    };

    state: {
        hoverIndex?: number;
    };

    componentDidUpdate(prevProps: any, prevState: any): void {
        super.componentDidUpdate?.(prevProps, prevState);
        if (prevProps.blurbs !== this.props.blurbs) {
            ReactTooltip.rebuild();
        }
    }

    render() {
        // without this, tooltips will sometimes get disabled
        ReactTooltip.rebuild();

        const embeddedValueWidth = 39;
        const unembeddedValueWidth = 22;
        const width = this.props.embedValues ? embeddedValueWidth : unembeddedValueWidth;

        let ratingDivs: JSX.Element[] = [
            (
                <div
                    key={"heading"}
                    className={"impact-legend-rating"}
                    style={{ backgroundColor: cardBackgroundColor, color: 'white' }}
                >
                    <Typography variant={'body2'} style={{ color: '#ffffff', textAlign: 'center' }}>
                        {this.props.legendTitle ?? 'Legend'}
                    </Typography>
                </div>
            )
        ];
        let valueDivs: JSX.Element[] = [
            (
                <div
                    key={"heading"}
                    className={"impact-legend-rating"}
                    style={{ backgroundColor: cardBackgroundColor, color: 'white' }}
                >
                </div>
            )
        ];
        for (let i = this.props.skipFirstBlurb ? 1 : 0; i < this.props.blurbs.length; i++) {
            const fullBlurb = this.props.blurbs[i];
            const blurb = fullBlurb.blurb;
            const color = fullBlurb.color;
            const value = fullBlurb.value;

            let ratingDiv = (
                <div
                    key={`impact-legend-rating-${value}-${i}`}
                    className={`impact-legend-rating`}
                    data-tip={blurb}
                    style={{
                        backgroundColor: color,
                        color: 'white',
                        textShadow: '0px 0px 4px #000000',
                        width,
                    }}
                >
                    {this.props.embedValues ? value : ''}
                </div>
            );

            ratingDivs.push(ratingDiv);

            let valueDiv = (
                <div
                    key={`impact-legend-rating-${value}-${i}`}
                    className={`impact-legend-rating`}
                    data-tip={blurb}
                    style={{
                        backgroundColor: cardBackgroundColor,
                        color: 'white',
                        textShadow: '0px 0px 4px #000000',
                        width,
                    }}
                >
                    {value}
                </div>
            );

            valueDivs.push(valueDiv);
        }

        let headerWord = "Danger";
        switch (this.props.ratingKey) {
            case 'road':
                headerWord = "Road Danger";
                break;
            case 'disruption':
                headerWord = "Storm Disruption";
                break;
            case 'flood':
                headerWord = "Flood Risk";
                break;
            case 'power':
                headerWord = "Outage Risk";
                break;
            case 'life_property':
                headerWord = "Life & Property Risk";
                break;
            case 'temperature':
                headerWord = "° Fahrenheit";
                break;
            case 'rain_accumulation':
            case 'snow_accumulation':
                headerWord = "Inches";
                break;
            case 'wind_speed':
            case 'wind_gust':
                headerWord = "MPH";
                break;
        }

        let legendWordClass = 'legend-word-generic';

        let wordDivs = [
            <div key={"legend-word-danger"} className={"legend-word-danger"}>{headerWord}</div>,
            <div key={"spacer"} className={"spacer"} />,
            // <div key={"legend-word-marginal"} className={legendWordClass}>Marginal</div>,
            <div key={"legend-word-low"} className={legendWordClass}>Low</div>,
            <div key={"legend-word-moderate"} className={legendWordClass}>Moderate</div>,
            <div key={"legend-word-high"} className={legendWordClass}>High</div>,
            <div key={"legend-word-extreme"} className={legendWordClass}>Extreme</div>
        ];
        valueDivs.unshift(<div key={"legend-word-danger"} className={"legend-word-danger"}>
            <Typography variant={'body2'} style={{ color: '#ffffff', textAlign: 'center' }}>
                {headerWord}
            </Typography>
        </div>);


        return (
            <div className={"impact-legend"}>
                <div className={"impact-legend-numbers"}>
                    {ratingDivs}
                </div>
                {(this.props.ratingKey || !this.props.embedValues) && <div className={"impact-legend-words"} style={{ backgroundColor: cardBackgroundColor }}>
                    {isImpactKey(this.props.ratingKey) ? <div style={{ width: 510, display: 'flex' }}>{wordDivs}</div> : valueDivs}
                </div>}
                {/**
                * restrict in production to only Air Force (2684)
                */}
                {this.props.tileOpacity !== undefined && isValidUserForFeature(this.props.userData?.id, { 'production': [ClientId.Production.AFB] }) &&
                    <div className={"impact-legend-numbers"} style={{ backgroundColor: cardBackgroundColor }}>
                        <div key={"opacity"} className={"legend-word-danger"} style={{ 'marginTop': '5px' }}>Opacity</div>
                        <Slider
                            className={'slider'}
                            style={{
                                'width': `${width * (ratingDivs.length - 1) - 20}px`,
                                'margin': '0px 10px 0px 10px'
                            }}
                            min={0}
                            max={1}
                            step={0.05}
                            defaultValue={1}
                            size="small"
                            value={this.props.tileOpacity}
                            onChange={(event: Event, newValue: number | number[]) => {
                                this.props.setTileOpacity && this.props.setTileOpacity(newValue as number);
                            }}
                        />
                    </div>}

                <ReactTooltip place={this.props.tooltipPlacement || 'bottom'} multiline />
            </div>
        );
    }
}