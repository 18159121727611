import { Paper, Typography } from "@mui/material";
import * as React from "react";
import { cardBackgroundColor } from "../../../constants";

export interface CanvasComponentProps {
    onDraw?: (canvas: HTMLCanvasElement) => void;
}

export class CanvasComponent extends React.Component {
    props: CanvasComponentProps & React.HTMLProps<HTMLCanvasElement>;

    render() {
        let canvasProps = { ...this.props };
        delete canvasProps.onDraw;

        let style: React.CSSProperties | undefined = this.props.style;
        if (typeof this.props.width === 'number' && typeof this.props.height === 'number') {
            style = { ...this.props.style, width: this.props.width, height: this.props.height };
        }

        return (
            <canvas
                {...canvasProps}
                style={style} // this is part of the retina fix below
                ref={(canvas) => {
                    if (canvas !== null) {
                        // retina fix for canvas resolution
                        // https://coderwall.com/p/vmkk6a/how-to-make-the-canvas-not-look-like-crap-on-retina
                        if (typeof this.props.width === 'number' && typeof this.props.height === 'number') {
                            const scale = 2;
                            canvas.width = this.props.width * scale;
                            canvas.height = this.props.height * scale;
                            canvas.getContext('2d')?.scale(2, 2);
                        }
                        this.props.onDraw?.(canvas);
                    }
                }}
            />
        );
    }
}

export const circleDraw = (circleDiameter: number, currentValue: number, maxValue: number, activeColor: string, passiveColor: string, backgroundColor: string, arcWidth: number) => {
    return (canvas: HTMLCanvasElement) => {
        let context = canvas.getContext("2d")!;
        context.clearRect(0, 0, circleDiameter, circleDiameter);

        let progress = Math.min(currentValue / maxValue, 1);
        // let progress = 0.0;
        let arcAngle = progress * Math.PI * 2;
        // draw start cap
        let r = (circleDiameter / 2 - arcWidth / 2);
        let startTheta = -Math.PI / 2;
        let cxStartCap = r * Math.cos(startTheta) + circleDiameter / 2;
        let cyStartCap = r * Math.sin(startTheta) + circleDiameter / 2;

        context.beginPath();
        context.fillStyle = activeColor;
        context.moveTo(cxStartCap, cyStartCap);
        context.arc(cxStartCap, cyStartCap, arcWidth / 2, 0, 2 * Math.PI);
        context.fill();

        // draw background stroke
        context.beginPath();
        context.moveTo(circleDiameter / 2, circleDiameter / 2);
        context.arc(circleDiameter / 2, circleDiameter / 2, circleDiameter / 2, 0, Math.PI * 2);
        context.fillStyle = passiveColor;
        context.fill();

        // draw active stroke
        context.beginPath();
        context.moveTo(circleDiameter / 2, circleDiameter / 2);
        context.arc(circleDiameter / 2, circleDiameter / 2, circleDiameter / 2, -Math.PI / 2, arcAngle - Math.PI / 2, false);
        context.fillStyle = activeColor;
        context.fill();

        // cut out middle of circle
        context.beginPath();
        context.moveTo(circleDiameter / 2, circleDiameter / 2);
        context.arc(circleDiameter / 2, circleDiameter / 2, circleDiameter / 2 - arcWidth, 0, Math.PI * 2);
        context.fillStyle = backgroundColor;
        context.fill();

        // draw end cap
        let theta = (progress) * 2 * Math.PI - Math.PI / 2;
        let cxEndCap = r * Math.cos(theta) + circleDiameter / 2;
        let cyEndCap = r * Math.sin(theta) + circleDiameter / 2;

        context.beginPath();
        context.fillStyle = activeColor;
        context.moveTo(cxEndCap, cyEndCap);
        context.arc(cxEndCap, cyEndCap, arcWidth / 2, 0, 2 * Math.PI);
        context.fill();
    };
};

interface ImpactSummaryAttributes {
    heading: string; // 'Today'
    routesFraction: number; // 0.52
    routesLabel: string;
    locationsFraction: number; // 0.35
    locationsLabel: string;
}

const ImpactCircleComponent = ({ percent, label, heading, activeColor }: { percent: number; label: string; heading: string; activeColor: string }) => {
    const circleDiameter = 65;
    return (
        <div>
            <div style={{ position: 'relative', width: circleDiameter, height: circleDiameter }}>
                <CanvasComponent
                    className={"circle"}
                    onDraw={circleDraw(circleDiameter, percent, 1, activeColor, '#FFFFFF21', '#1E1E1E', 8)}
                    width={circleDiameter}
                    height={circleDiameter}
                    style={{ position: 'absolute' }}
                />
                <div style={{ position: 'absolute', display: 'flex', alignItems: 'center', justifyContent: 'center', width: circleDiameter, height: circleDiameter }}>
                    <Typography variant={'body1'} style={{ color: '#FFFFFF' }}>{label}</Typography>
                </div>
            </div>
            <Typography variant={'body2'} style={{ color: '#ffffff', opacity: 0.7, width: circleDiameter, textAlign: 'center' }}>
                {heading}
            </Typography>
        </div>
    );
};

export const ImpactSummary = ({ attributesArray }: { attributesArray: ImpactSummaryAttributes[] }) => {
    return (
        <Paper
            elevation={3}
            style={{ backgroundColor: cardBackgroundColor, borderRadius: 11, backgroundImage: 'none', boxShadow: 'none' }}
        >
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', margin: '18px' }}>
                {attributesArray.map((attributes, index) => (
                    [<div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                        <Typography
                            variant={'subtitle1'}
                            style={{ color: '#FFFFFF', marginBottom: 16 }}
                        >
                            {attributes.heading}
                        </Typography>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <ImpactCircleComponent
                                percent={attributes.routesFraction}
                                label={attributes.routesLabel}
                                heading={'Impacted Routes'}
                                activeColor={'#4EA47B'}
                            />
                            <div style={{ width: 24 }} />
                            <ImpactCircleComponent
                                percent={attributes.locationsFraction}
                                label={attributes.locationsLabel}
                                heading={'Impacted Locations'}
                                activeColor={'#178BF0'}
                            />
                        </div>
                    </div>, (index < attributesArray.length - 1) && <div style={{ width: 1, backgroundColor: '#FFFFFF3A' }} />]
                ))}
            </div>
        </Paper>
    );
};