function createMeanClusterSVG(meanMarkerCount, numLocations) {
    // change color if this cluster has more markers than the mean cluster
    const color = numLocations > Math.max(10, meanMarkerCount) ? "#00bfff" : "#00bfff";

    // create svg literal with fill color
    const svg = `<svg fill="${color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240" width="50" height="50">
<circle cx="120" cy="120" opacity=".9" r="70" />
<circle cx="120" cy="120" opacity=".45" r="90" />
<circle cx="120" cy="120" opacity=".3" r="110" />
<text x="50%" y="50%" style="fill:#fff" text-anchor="middle" font-size="50" dominant-baseline="middle" font-family="roboto,arial,sans-serif">${numLocations}</text>
</svg>`;

    // create cluster SVG element
    const parser = new DOMParser();
    return parser.parseFromString(
        svg,
        "image/svg+xml"
    ).documentElement;
}

export function makeMeanClusterer(maps, map, MarkerClusterer, Algorithm, clusterClickListener) {
    const locationClusterRenderer = {
        render: (info, stats) => {
            const { count, position } = info;

            let svgEl = createMeanClusterSVG(stats.clusters.markers.mean, count);

            svgEl.setAttribute("transform", "translate(0 25)");

            return new maps.marker.AdvancedMarkerElement({
                position,
                map: map,
                title: `Cluster of ${count} locations`,
                content: svgEl,
                // adjust zIndex to be above other markers
                // zIndex: Number(maps.Marker.MAX_ZINDEX) + count,
            });
        }
    };

    return new MarkerClusterer({
        markers: [],
        algorithm: new Algorithm({ radius: 100 }),
        onClusterClick: clusterClickListener,
        renderer: locationClusterRenderer,
    });
}