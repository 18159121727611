import {
    RatingsDataWithLocationInfo,
    RatingsState, LoadableResult,
} from '../types';
import {
    LoadBlurbsStart, LoadRatingsFail,
    LoadRatingsStart,
    RatingsAction,
    ReceiveBlurbsData, ReceiveImpactTilesetsData, ReceiveWeatherTilesetsData,
    ReceiveRatingsData,
    LoadWildfireRatingsFail,
    ReceiveWildfireRatingsData,
    LoadWildfireRatingsStart,
    ReceiveGovernmentalAlertData,
    ReceiveSubhourlyImpactTilesetsData,
    ReceiveSubhourlyWeatherTilesetsData
} from '../actions/Ratings';
import { RATINGS_CACHE_INTERVAL } from '../constants';

export const coerceDateToHour = (date: Date) => {
    let d = new Date(date);
    if (Math.abs(d.getTimezoneOffset()) % 60 === 30) {
        // half hour timezones: need special handling, if you set the minute to zero on them then it'll come up with an invalid model run time because it'll be 30 min early when converted back to UTC
        d.setMinutes(30);
    } else if (d.getTimezoneOffset() % 60 === -45) {
        // 45-min timezones: need even more special handling: don't ask me. just JS things.
        d.setMinutes(45);
    } else {
        d.setMinutes(0);
    }
    d.setSeconds(0);
    d.setMilliseconds(0);
    return d;
};

export const areCoordsEqual = (a: number, b: number) => {
    return Math.abs(a - b) < 0.000000001;
};

export function ratings(state: RatingsState, action: typeof RatingsAction.actions): RatingsState {
    switch (action.type) {
        case ReceiveRatingsData.type:
            // may be called multiple times per cycle

            let newestRatingsData = { ...action.ratingsData };

            let masterRatingsData: RatingsDataWithLocationInfo;
            let existingRatingsData = state.ratingsData;
            if (action.subhourly) {
                existingRatingsData = state.subhourlyRatingsData;
            }

            if (existingRatingsData === undefined) {
                masterRatingsData = newestRatingsData;
            } else {
                masterRatingsData = { ...existingRatingsData };

                if (areCoordsEqual(newestRatingsData.latitude, existingRatingsData.latitude) &&
                    areCoordsEqual(newestRatingsData.longitude, existingRatingsData.longitude)) {

                    ['road', 'disruption', 'power', 'flood', 'life_property'].forEach(index => {
                        if (newestRatingsData[index].length > 0) {
                            // this index is present in the new data
                            masterRatingsData[index] = newestRatingsData[index];
                        }
                    });
                }

                masterRatingsData.timezone = newestRatingsData.timezone;
            }

            let ratingsData = masterRatingsData;

            let cacheInfo = { ...state.cacheInfo };
            ['road', 'disruption', 'power', 'flood', 'life_property'].forEach(key => {
                if (action.ratingsData[key].length > 0) {
                    cacheInfo[key].expiresAt = new Date(action.receivedAt + RATINGS_CACHE_INTERVAL);
                }
            });

            if (action.subhourly) {
                return {
                    ...state,
                    isFetching: false,
                    ratingsFetchError: undefined,
                    subhourlyRatingsData: ratingsData,
                    subhourlyCacheInfo: cacheInfo,
                    abortController: undefined
                };
            }
            return {
                ...state,
                isFetching: false,
                ratingsFetchError: undefined,
                ratingsData,
                cacheInfo,
                abortController: undefined
            };
        case ReceiveWildfireRatingsData.type:
            let newestWildfireRatingsData = { ...action.ratingsData };

            let updatedRatingsData: RatingsDataWithLocationInfo;

            let nonWildfireRatingsData = state.ratingsData;
            if (action.subhourly) {
                nonWildfireRatingsData = state.subhourlyRatingsData;
            }

            if (nonWildfireRatingsData === undefined) {
                updatedRatingsData = newestWildfireRatingsData;
            } else {
                updatedRatingsData = { ...nonWildfireRatingsData };

                if (areCoordsEqual(newestWildfireRatingsData.latitude, nonWildfireRatingsData.latitude) &&
                    areCoordsEqual(newestWildfireRatingsData.longitude, nonWildfireRatingsData.longitude)) {

                    ['wildfire_spread', 'wildfire_conditions'].forEach(index => {
                        if (newestWildfireRatingsData[index].length > 0) {
                            // this index is present in the new data
                            updatedRatingsData[index] = newestWildfireRatingsData[index];
                        }
                    });
                }

                updatedRatingsData.timezone = newestWildfireRatingsData.timezone;
            }

            let updatedCacheInfo = { ...state.cacheInfo };
            ['wildfire_spread', 'wildfire_conditions'].forEach(key => {
                if (action.ratingsData[key].length > 0) {
                    updatedCacheInfo[key].expiresAt = new Date(action.receivedAt + RATINGS_CACHE_INTERVAL);
                }
            });

            if (action.subhourly) {
                return {
                    ...state,
                    isFetching: false,
                    ratingsFetchError: undefined,
                    subhourlyRatingsData: updatedRatingsData,
                    subhourlyCacheInfo: updatedCacheInfo,
                    abortController: undefined
                };
            }
            return {
                ...state,
                isFetching: false,
                ratingsFetchError: undefined,
                ratingsData: updatedRatingsData,
                cacheInfo: updatedCacheInfo,
                abortController: undefined
            };
        case LoadRatingsFail.type:
        case LoadWildfireRatingsFail.type: {
            // only called once per cycle
            return { ...state, isFetching: false, ratingsFetchError: action.error };
        }
        case LoadRatingsStart.type:
        case LoadWildfireRatingsStart.type:
            // only called once per cycle
            // we no longer set the ratings data to undefined - we are loading carefully chosen indices now and caching
            // but we have to update the lat/long
            let startRatingsData = state.ratingsData;
            const previousRatingsData = startRatingsData;
            if (previousRatingsData !== undefined && !areCoordsEqual(action.latitude, previousRatingsData.latitude) && !areCoordsEqual(action.longitude, previousRatingsData.longitude)) {
                startRatingsData = { ...previousRatingsData, disruption: [], power: [], road: [], flood: [], life_property: [], wildfire_spread: [], wildfire_conditions: [], latitude: action.latitude, longitude: action.longitude };
            }
            let startSubhourlyRatingsData = state.subhourlyRatingsData;
            const previousSubhourlyRatingsData = startSubhourlyRatingsData;
            if (previousSubhourlyRatingsData !== undefined && !areCoordsEqual(action.latitude, previousSubhourlyRatingsData.latitude) && !areCoordsEqual(action.longitude, previousSubhourlyRatingsData.longitude)) {
                startSubhourlyRatingsData = { ...previousRatingsData, disruption: [], power: [], road: [], flood: [], life_property: [], wildfire_spread: [], wildfire_conditions: [], latitude: action.latitude, longitude: action.longitude };
            }
            return { ...state, isFetching: true, ratingsFetchError: undefined, abortController: action.abortController, ratingsData: startRatingsData, subhourlyRatingsData: startSubhourlyRatingsData };
        case LoadBlurbsStart.type:
            return { ...state, blurbsAbortController: action.abortController };
        case ReceiveBlurbsData.type:
            return { ...state, blurbs: action.blurbs, blurbsAbortController: undefined };
        case ReceiveImpactTilesetsData.type:
            return {
                ...state,
                impactTilesets: action.impactTilesetsResult,
            };
        case ReceiveSubhourlyImpactTilesetsData.type:
            return {
                ...state,
                subhourlyImpactTilesets: action.impactTilesetsResult,
            };
        case ReceiveWeatherTilesetsData.type:
            return {
                ...state,
                weatherTilesets: action.weatherTilesetsResult,
            };
        case ReceiveSubhourlyWeatherTilesetsData.type:
            return {
                ...state,
                subhourlyWeatherTilesets: action.weatherTilesetsResult,
            };
        case ReceiveGovernmentalAlertData.type:
            return {
                ...state,
                governmentalAlerts: action.governmentalAlertsResult,
            };
    }

    let emptyCacheInfo = {
        road: { expiresAt: new Date() },
        disruption: { expiresAt: new Date() },
        flood: { expiresAt: new Date() },
        power: { expiresAt: new Date() },
        life_property: { expiresAt: new Date() },
        wildfire_spread: { expiresAt: new Date() },
        wildfire_conditions: { expiresAt: new Date() },
    };

    return state || {
        ratingsData: undefined,
        subhourlyRatingsData: undefined,
        blurbs: {},
        tilesets: [],
        isFetching: false,
        cacheInfo: emptyCacheInfo,
        subhourlyCacheInfo: emptyCacheInfo,

        governmentalAlerts: new LoadableResult(5 * 60),
        impactTilesets: new LoadableResult(5 * 60),
        weatherTilesets: new LoadableResult(5 * 60),
        subhourlyImpactTilesets: new LoadableResult(5 * 60),
        subhourlyWeatherTilesets: new LoadableResult(5 * 60),
    };
}
