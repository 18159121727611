import { API_HOST, CABLE_HOST } from '../constants';
import { Action, ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { ALL_PERMISSIONS, ApiAccess, LoadableResultMetadata, DriverNotification, LocationData, MyAccountState, StoreState, VehicleTrackingData } from '../types';
import 'redux-thunk';
import { SetSelectedVehicle } from './DashboardView';
import { loadWeatherIfNeeded } from './Weather';
import {
    loadBlurbsIfNeeded,
    loadImpactTilesetsIfNeeded,
    loadWeatherTilesetsIfNeeded,
    loadRatingsIfNeeded,
    retryIfRateLimited,
    loadSubhourlyRatingsIfNeeded,
    loadSubhourlyImpactTilesetsIfNeeded,
    loadSubhourlyWeatherTilesetsIfNeeded,
} from './Ratings';
import { action, props, union } from 'ts-action';
import { RouteData } from "../types/routes";
import * as ActionCable from '@rails/actioncable';
import moment from "moment";
import { camelToSnakeCase, unmarshalDriverNotification, unmarshalLocation, unmarshalRoute, unmarshalServerJSON, unmarshalVehicle } from 'src/types/unmarshal';
import { loadPossibleGovernmentalAlertNamesIfNeeded } from './AlertsView';
import { findMatchingVehicleForRoute } from 'src/components/Client/Dashboard/data';
import { Config } from 'src/components/shared/useConfig';
import { SelectCity } from './SelectedCity';
import { throttle } from 'lodash';

export const StartedLogin = action('STARTED_LOGIN');

export const StartedLogout = action('STARTED_LOGOUT');

export const ReceiveLoginResponse = action('RECEIVE_LOGIN_RESPONSE', props<{ user: object }>());

export const ReceiveLoginError = action('RECEIVE_LOGIN_ERROR', props<{ error?: string }>());

export const ReceiveUserProfile = action('RECEIVE_USER_PROFILE', props<{ user: object }>());

export const ReceiveUserCities = action('RECEIVE_USER_CITIES', props<{ cities: LocationData[]; metadata: LoadableResultMetadata }>());
export const ReceiveUserCityCreated = action('RECEIVE_USER_CITY_CREATED', props<{ city: LocationData }>());
export const ReceiveUserCityDeleted = action('RECEIVE_USER_CITY_DELETED', props<{ city: LocationData }>());
export const ReceiveUserCityUpdated = action('RECEIVE_USER_CITY_UPDATED', props<{ city: LocationData }>());
export const ReceiveUserCityUpdatedSuccess = action('RECEIVE_USER_CITY_UPDATED_SUCCESS', props<{ city: LocationData }>());
export const ReceiveUserCityUpdatedError = action('RECEIVE_USER_CITY_UPDATED_ERROR', props<{ errors: Error[] }>());
export const ClearUserCityUpdatedResponse = action('CLEAR_USER_CITY_UPDATED_RESPONSE');

export const ReceiveUserDriverNotifications = action('RECEIVE_USER_DRIVER_NOTIFICATIONS', props<{ driverNotifications: DriverNotification[] }>());
export const ReceiveUserDriverNotificationCreated = action('RECEIVE_USER_DRIVER_NOTIFICATION_CREATED', props<{ driverNotification: DriverNotification }>());
export const ReceiveUserDriverNotificationDeleted = action('RECEIVE_USER_DRIVER_NOTIFICATION_DELETED', props<{ driverNotification: DriverNotification }>());
export const ReceiveUserDriverNotificationUpdated = action('RECEIVE_USER_DRIVER_NOTIFICATION_UPDATED', props<{ driverNotification: DriverNotification }>());
export const ReceiveUserDriverNotificationUpdatedSuccess = action('RECEIVE_USER_DRIVER_NOTIFICATION_UPDATED_SUCCESS', props<{ driverNotification: DriverNotification }>());
export const ReceiveUserDriverNotificationUpdatedError = action('RECEIVE_USER_DRIVER_NOTIFICATION_UPDATED_ERROR', props<{ errors: Error[] }>());
export const ClearUserDriverNotificationUpdatedResponse = action('CLEAR_USER_DRIVER_NOTIFICATION_UPDATED_RESPONSE');

export const ReceiveUserRoutes = action('RECEIVE_USER_ROUTES', props<{ routes: RouteData[]; metadata: LoadableResultMetadata }>());
export const ReceiveUserRouteCreated = action('RECEIVE_USER_ROUTE_CREATED', props<{ route: RouteData }>());
export const ReceiveUserRouteDeleted = action('RECEIVE_USER_ROUTE_DELETED', props<{ route: RouteData }>());
export const ReceiveUserRouteUpdatedSuccess = action('RECEIVE_USER_ROUTE_UPDATED_SUCCESS', props<{ route: RouteData }>());
export const ReceiveUserRouteUpdatedError = action('RECEIVE_USER_ROUTE_UPDATED_ERROR', props<{ errors: Error[] }>());
export const ClearUserRouteUpdatedResponse = action('CLEAR_USER_ROUTE_UPDATED_RESPONSE');

export const ReceiveUpdatedRoute = action('RECEIVE_UPDATED_ROUTE', props<{ route: RouteData }>());

export const StartedRouteRefresh = action('STARTED_ROUTE_REFRESH', props<{ route: RouteData }>());
export const ReceiveRouteRefreshError = action('RECEIVE_ROUTE_REFRESH_ERROR', props<{ route: RouteData; error: string }>());
export const ClearRouteRefreshError = action('CLEAR_ROUTE_REFRESH_ERROR');

export const ReceiveUserVehicles = action('RECEIVE_USER_VEHICLES', props<{ vehicles: VehicleTrackingData[]; metadata: LoadableResultMetadata }>());
export const ReceiveVehicleNotificationsEnabled = action('RECEIVE_VEHICLES_NOTIFICATIONS_ENABLED_UPDATED', props<{ vehicle: VehicleTrackingData }>());

export const ReceiveFeatureFlags = action('RECEIVE_FEATURE_FLAGS', props<{
    showImpactTab: boolean;
    showRoutesTab: boolean;
    showDisruptionIndex: boolean;
    showWildfireIndices: boolean;
    showNowcastTab: boolean;
    show511InPortal: boolean;
    showEventsInPortal: boolean;
}>());

export const ReceiveAccountInfo = action('RECEIVE_ACCOUNT_INFO', props<{ myAccount: MyAccountState }>());

export const ReceiveAccountError = action('RECEIVE_ACCOUNT_ERROR', props<{ error: string }>());

export const StartedPasswordReset = action('STARTED_PASSWORD_RESET');

export const PasswordResetSucceeded = action('PASSWORD_RESET_SUCCEEDED');

export const ReceivePasswordResetError = action('RECEIVE_PASSWORD_RESET_ERROR', props<{ error?: string }>());

export const ClearPasswordResetState = action('CLEAR_PASSWORD_RESET_STATE');

export const OpenedPage = action('OPENED_PAGE', props<{ page: string }>());

export const UserAction = union(StartedLogin, StartedLogout, ReceiveLoginResponse, ReceiveLoginError
    , StartedPasswordReset, PasswordResetSucceeded, ReceivePasswordResetError, ClearPasswordResetState
    , ReceiveUserProfile, ReceiveFeatureFlags, ReceiveAccountInfo, ReceiveUserCities, ReceiveUserCityCreated
    , ReceiveUserCityDeleted, ReceiveUserCityUpdated, ReceiveUserCityUpdatedSuccess, ReceiveUserCityUpdatedError, ClearUserCityUpdatedResponse
    , ReceiveUserDriverNotifications, ReceiveUserDriverNotificationCreated, ReceiveUserDriverNotificationDeleted, ReceiveUserDriverNotificationUpdated
    , ReceiveUserDriverNotificationUpdatedSuccess, ReceiveUserDriverNotificationUpdatedError, ClearUserDriverNotificationUpdatedResponse
    , ReceiveUserRoutes, ReceiveUserRouteCreated, ReceiveUserRouteDeleted, ReceiveAccountError
    , ReceiveUserRouteUpdatedSuccess, ReceiveUserRouteUpdatedError, ClearUserRouteUpdatedResponse, ReceiveUpdatedRoute
    , StartedRouteRefresh, ReceiveRouteRefreshError, ClearRouteRefreshError, ReceiveUserVehicles, ReceiveVehicleNotificationsEnabled
    , OpenedPage);

export const createLoginAction: ActionCreator<ThunkAction<Promise<void>, StoreState, void, Action<any>>> = (email: string, password: string) => {
    return (dispatch) => {
        return dispatch(initiateWOLogin(email, password)).then(() => { });
    };
};

export const createNewLoginAction: ActionCreator<ThunkAction<Promise<void>, StoreState, void, Action<any>>> = (email: string, token: string, company: string, password: string, newLoginCreated: () => void) => {
    return (dispatch, getState) => {
        return Promise.resolve(dispatch(confirmPasswordReset(password, token))
            .then(() => dispatch(initiateWOLogin(email, password))
                .then(() => dispatch(updateCompanyName(company, getState().user.token!))
                    .then(() => { newLoginCreated(); }))));
    };
};

export function initiateWOLogin(email: string, password: string) {
    return initiateLoginWithData({ email, password });
}

export function initiateLoginWithData(loginData: any) {
    return async (dispatch: Dispatch<any>) => {
        dispatch(StartedLogin());
        // await delay(2000); // uncomment for login loading testing

        let postData: RequestInit = {
            body: JSON.stringify(loginData),
            cache: 'no-cache',
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
        };

        return retryIfRateLimited(() => fetch(`${API_HOST}/api_client_signin.json`, postData))
            .then((response: Response) => response.json().then((json) => { return { response, json }; }))
            .then(
                ({ response, json }) => {
                    if (response.status === 200) {
                        let token = json['token'] as string;
                        let portalToken = json['portal_token'] as string;

                        if (token === undefined || portalToken === undefined) {
                            throw new Error("did not get both token and portal token from login route");
                        }

                        dispatch(fetchUserProfile(token));
                        dispatch(fetchAccountInfo(token));
                        dispatch(fetchFeatureFlags(token, portalToken));
                        dispatch(fetchUserCities(token));
                        dispatch(fetchUserRoutes(token));
                        dispatch(fetchUserVehicles(token));
                        dispatch(fetchUserDriverNotifications(token));
                        dispatch(loadPossibleGovernmentalAlertNamesIfNeeded(token));
                        dispatch(openChannels(token));

                        // receive user data
                        return dispatch(ReceiveLoginResponse({ user: json }));
                    } else {
                        const error = json['error'] && (json['error'] as string);
                        return dispatch(ReceiveLoginError({ error }));
                    }
                },
            ).catch((error) => {
                console.log('Login error', error);
                dispatch(ReceiveLoginError({ error: 'Unknown request error' }));
            });
    };
}

export let Cable: ActionCable.Consumer | undefined;
export let routesChannel: ActionCable.Subscription | undefined;
export let weatherDataUpdateChannel: ActionCable.Subscription | undefined;
export let vehiclesChannel: ActionCable.Subscription | undefined;

/**
 * tracks which vehicles need to be updated
 * when it is set to undefined, it means we should refresh all vehicles
 */
let vehiclesToUpdate: Set<number> | undefined = new Set();
/**
 * batches up requested vehicle updates to be once every minute
 */
const fetchUpdatedVehicles = throttle((dispatch: Dispatch<any>, token: string) => {
    if (vehiclesToUpdate) {
        dispatch(fetchSpecificVehicles(token, Array.from(vehiclesToUpdate)));
    } else {
        dispatch(fetchUserVehicles(token));
    }
    vehiclesToUpdate = new Set();
}, 60 * 1000);

function loggingMixin(channel: string): ActionCable.Mixin {
    return {
        connected() {
            console.log(channel, "connected");
        },

        received(data: any) {
            console.log(channel, "received", data);
        },

        disconnected() {
            console.log(channel, "disconnected");
        },

        rejected() {
            console.log(channel, "rejected");
        },

        initialized() {
            console.log(channel, "initialized");
        },

        install() {
            console.log(channel, "install");
        },

        uninstall() {
            console.log(channel, "uninstall");
        },

        appear() {
            console.log(channel, "appear");
        },

        away() {
            console.log(channel, "away");
        }
    };
}

export function openChannels(token: string) {
    return (dispatch: Dispatch<any>) => {
        if (Cable === undefined) {
            Cable = ActionCable.createConsumer(`${CABLE_HOST}/cable?token=${token}`);
        }

        console.log("CABLE", "firing off connection");
        routesChannel = Cable.subscriptions.create<any>("RoutesChannel", {
            ...loggingMixin("RoutesChannel"),
            received(data: any) {
                // dispatch here
                try {
                    console.log("RoutesChannel", "received", data);
                    const route = unmarshalRoute(JSON.parse(data));
                    console.log("RoutesChannel", "received route", route);
                    dispatch(ReceiveUpdatedRoute({ route }));
                } catch (e) {
                    console.error("ERROR RECEIVING UPDATED ROUTE", e, "\nDATA:", data);
                }
            },
        });

        weatherDataUpdateChannel = Cable.subscriptions.create<any>("WeatherDataUpdateChannel", {
            ...loggingMixin("WeatherDataUpdateChannel"),
            received(receivedData: string | any) {
                try {
                    console.log("WeatherDataUpdateChannel", "received", receivedData);
                    let data: any;
                    // seems like the broadcast messages come in parsed, but the individual messages come in as
                    // strings so we handle both cases here
                    if (typeof receivedData === 'string') {
                        data = JSON.parse(receivedData);
                    } else {
                        data = receivedData;
                    }
                    let locationIds: number[] | undefined = undefined;
                    if (data.location_ids) {
                        locationIds = data.location_ids;
                    }
                    // wait a random amount of time up to 10 seconds to prevent every client from requesting
                    // new location data at the same time
                    const sleepTime = Math.floor(Math.random() * 10000);
                    console.log("WeatherDataUpdateChannel", `waiting for ${sleepTime} ms before updating`);
                    setTimeout(() => {
                        console.log("WeatherDataUpdateChannel", 'requesting updated impact summaries');
                        dispatch(fetchUserCities(token, locationIds));
                    }, sleepTime);
                } catch (e) {
                    console.error("ERROR RECEIVING WEATHER DATA UPDATE", e, "\nDATA:", receivedData);
                }
            },
        });

        vehiclesChannel = Cable.subscriptions.create<any>("VehiclesChannel", {
            ...loggingMixin("VehiclesChannel"),
            received(receivedData: any) {
                try {
                    console.log("VehiclesChannel", "received", receivedData);
                    let data: any;
                    // seems like the broadcast messages come in parsed, but the individual messages come in as
                    // strings so we handle both cases here
                    if (typeof receivedData === 'string') {
                        data = JSON.parse(receivedData);
                    } else {
                        data = receivedData;
                    }
                    // record what vehicles need to be updated
                    if (data.vehicle_ids) {
                        data.vehicle_ids.forEach((id: number) => vehiclesToUpdate?.add(id));
                    } else {
                        vehiclesToUpdate = undefined;
                    }
                    // call our throttled function to load data
                    fetchUpdatedVehicles(dispatch, token);
                } catch (e) {
                    console.error("ERROR RECEIVING VEHICLES UPDATE", e, "\nDATA:", receivedData);
                }
            },
        });
    };
}

function closeChannels() {
    routesChannel?.unsubscribe();
    routesChannel = undefined;
    weatherDataUpdateChannel?.unsubscribe();
    weatherDataUpdateChannel = undefined;
    vehiclesChannel?.unsubscribe();
    vehiclesChannel = undefined;
}

export const createResetPasswordAction: ActionCreator<ThunkAction<Promise<void>, StoreState, void, Action<any>>> = (email: string) => {
    return (dispatch) => {
        return dispatch(initiatePasswordReset(email)).then(() => { });
    };
};

function initiatePasswordReset(email: string) {
    return (dispatch: Dispatch<any>) => {
        dispatch(StartedPasswordReset());

        let postData: RequestInit = {
            body: JSON.stringify({ email }),
            cache: 'no-cache',
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
        };

        return retryIfRateLimited(() => fetch(`${API_HOST}/users/request_password_reset.json`, postData))
            .then((response: Response) => response.json().then((json) => { return { response, json }; }))
            .then(
                ({ response, json }) => {
                    if (response.status === 200) {
                        return dispatch(PasswordResetSucceeded());
                    } else {
                        const error = json['error'] && (json['error'] as string);
                        return dispatch(ReceivePasswordResetError({ error }));
                    }
                },
            ).catch((error) => {
                console.log('Password reset error', error);
                dispatch(ReceivePasswordResetError({ error: 'Unknown request error' }));
            });
    };
}

function confirmPasswordReset(password: string, token: string) {
    return (dispatch: Dispatch<any>) => {
        dispatch(StartedPasswordReset());

        let postData: RequestInit = {
            body: JSON.stringify({ password, token }),
            cache: 'no-cache',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        };

        return retryIfRateLimited(() => fetch(`${API_HOST}/confirm_password_reset.json`, postData))
            .then((response: Response) => response.json().then((json) => { return { response, json }; }))
            .then(
                ({ response, json }) => {
                    if (response.status === 200) {
                        return dispatch(PasswordResetSucceeded());
                    } else {
                        console.log(json);
                        const error = json['error'] && (json['error'] as string);
                        return dispatch(ReceivePasswordResetError({ error }));
                    }
                },
            ).catch((error) => {
                console.log('Password reset error', error);
                dispatch(ReceivePasswordResetError({ error: 'Unknown request error' }));
            });
    };
}

function updateCompanyName(name: string, token: string) {
    return (dispatch: Dispatch<any>) => {

        let patchData: RequestInit = {
            body: JSON.stringify({ name, token }),
            cache: 'no-cache',
            method: 'PATCH',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
        };

        return retryIfRateLimited(() => fetch(`${API_HOST}/users/me`, patchData))
            .then((response: Response) => response.json().then((json) => { return { response, json }; }))
            .then(
                ({ response, json }) => {
                    if (response.status === 200) {
                        dispatch(ReceiveUserProfile({ user: json }));
                    } else {
                        console.log(json);
                    }
                },
            );
    };
}

export const createActivateAccountAction: ActionCreator<ThunkAction<Promise<void>, StoreState, void, Action<any>>> = () => {
    return (dispatch, getState) => {
        let token = getState().user.token;

        if (typeof token === 'undefined') {
            return Promise.resolve();
        }

        dispatch(activateAccount(token));
        return Promise.resolve();
    };
};

function activateAccount(token: string) {
    return (dispatch: Dispatch<any>) => {

        let postData: RequestInit = {
            body: JSON.stringify({ token }),
            cache: 'no-cache',
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
        };

        return retryIfRateLimited(() => fetch(`${API_HOST}/stripe/upgrade_now`, postData))
            .then(
                (response: Response) => response.json(),
                (error: Error) => {
                    console.log('Error upgrading user account.', error);
                    dispatch(ReceiveAccountError({ error: error.toString() }));
                }
            ).then((json: JSON) => {
                dispatch(fetchAccountInfo(token));

                return Promise.resolve();
            });
    };
}

export const createRefreshAccountAction: ActionCreator<ThunkAction<Promise<void>, StoreState, void, Action<any>>> = () => {
    return (dispatch, getState) => {
        let token = getState().user.token;

        if (typeof token === 'undefined') {
            return Promise.resolve();
        }

        dispatch(fetchAccountInfo(token));
        return Promise.resolve();
    };
};

export function fetchUserProfile(token: string) {
    return (dispatch: Dispatch<any>) => {
        return retryIfRateLimited(() => fetch(`${API_HOST}/users/me?token=${token}`))
            .then(
                (response: Response) => response.json(),
                (error: Error) => console.log('Error fetching user profile data.', error)
            ).then((json: JSON) => {
                dispatch(ReceiveUserProfile({ user: json }));
                dispatch(loadRatingsIfNeeded());
                dispatch(loadSubhourlyRatingsIfNeeded());
                dispatch(loadImpactTilesetsIfNeeded());
                dispatch(loadWeatherTilesetsIfNeeded());
                dispatch(loadSubhourlyImpactTilesetsIfNeeded());
                dispatch(loadSubhourlyWeatherTilesetsIfNeeded());

                // todo: why does this happen?
                dispatch(loadWeatherIfNeeded());

                return Promise.resolve();
            });
    };
}

export function fetchUserCities(token: string, locationIds: number[] | undefined = undefined) {
    return async (dispatch: Dispatch<any>, getState: () => StoreState) => {
        if (getState().user.citiesMetadata.loading) {
            console.log('Skip fetching user cities because they are still loading from last fetch.');
            return Promise.resolve();
        }
        const limit = 500;
        let offset = 0;
        let hasCitiesRemaining = true;
        const cities: LocationData[] = [...(getState().user.cities || [])];
        try {
            // don't set loading true if only loading a few cities, reserve for full city reload
            const isLoading = locationIds === undefined ? true : false;
            dispatch(ReceiveUserCities({ cities, metadata: { success: false, loading: isLoading, error: undefined } }));
            while (hasCitiesRemaining) {
                let url: string = `${API_HOST}/cities.json?token=${token}&limit=${limit}&offset=${offset}`;
                if (locationIds) {
                    url += `&ids=${locationIds.join(',')}`;
                }
                const response = await retryIfRateLimited(() => fetch(url));
                const json = await response.json();
                const citiesJson = json['cities'] as object[];
                citiesJson.forEach(obj => {
                    const city = unmarshalLocation(obj);
                    const index = cities.findIndex(c => c.id === city.id);
                    if (index !== -1) {
                        cities.splice(index, 1, city);
                    } else {
                        cities.push(city);
                    }
                });

                dispatch(ReceiveUserCities({ cities, metadata: { success: false, loading: isLoading, error: undefined } }));

                hasCitiesRemaining = citiesJson.length === limit;
                offset += limit;
            }
            dispatch(ReceiveUserCities({ cities, metadata: { success: true, loading: false, error: undefined } }));

            const currentSelectedCity = getState().selectedCity.selectedCity;
            if (currentSelectedCity) {
                const updatedSelectedCity = cities.find(x => x.id === currentSelectedCity.id);
                if (locationIds === undefined) {
                    // if updating all cities and we find a newer version of the selected city, lets update it
                    // otherwise, if we can't find it, let's deselect it since we don't have it
                    dispatch(SelectCity({ city: updatedSelectedCity }));
                } else {
                    if (locationIds.find(c => c === currentSelectedCity.id) !== undefined) {
                        // if the selected city is one of the location ids also update it
                        dispatch(SelectCity({ city: updatedSelectedCity }));
                    }
                }
            }

            return Promise.resolve();
        } catch (error) {
            console.log('Error fetching user cities in initial load.', error);
            dispatch(ReceiveUserCities({ cities, metadata: { success: false, loading: false, error } }));
            return Promise.reject(error);
        }
    };
}

export function fetchUserRoutes(token: string) {
    return async (dispatch: Dispatch<any>, getState: () => StoreState) => {
        if (getState().user.savedRoutesMetadata.loading) {
            console.log('Skip fetching user routes because they are still loading from last fetch.');
            return Promise.resolve();
        }
        // filter out all routes that will not be shown in the portal's window (7 days starting with current day)
        // keeping routes that departed up to 7 days ago to account for long routes that may be still active in portal window
        const departureTimeAfter = moment().startOf('day').subtract(7, 'days').format();
        const departureTimeBefore = moment().startOf('day').add(8, 'days').format();
        const limit = 300;
        let offset = 0;
        let hasRoutesRemaining = true;
        const routes: RouteData[] = [...(getState().user.savedRoutes || [])];
        try {
            dispatch(ReceiveUserRoutes({ routes, metadata: { success: false, loading: true, error: undefined } }));
            while (hasRoutesRemaining) {
                const response = await retryIfRateLimited(() => fetch(`${API_HOST}/routes.json?token=${token}&limit=${limit}&offset=${offset}&departure_time_after=${departureTimeAfter}&departure_time_before=${departureTimeBefore}`));
                const json = await response.json();
                const routesJson = json['routes'] as object[];
                routesJson.forEach(obj => {
                    const route = unmarshalRoute(obj);
                    const index = routes.findIndex(r => r.id === route.id);
                    if (index !== -1) {
                        routes.splice(index, 1, route);
                    } else {
                        routes.push(route);
                    }
                });

                dispatch(ReceiveUserRoutes({ routes, metadata: { success: false, loading: true, error: undefined } }));

                hasRoutesRemaining = routesJson.length === limit;
                offset += limit;
            }
            return dispatch(ReceiveUserRoutes({ routes, metadata: { success: true, loading: false, error: undefined } }));
        } catch (error) {
            console.log('Error fetching user routes in initial load.', error);
            dispatch(ReceiveUserRoutes({ routes, metadata: { success: false, loading: false, error } }));
            return Promise.reject(error);
        }
    };
}

export function fetchUserDriverNotifications(token: string) {
    return (dispatch: Dispatch<any>) => {
        return retryIfRateLimited(() => fetch(`${API_HOST}/driver_notifications.json?token=${token}&limit=10000`))
            .then(
                (response: Response) => response.json(),
                (error: Error) => console.log('Error fetching user driver notifications in initial load.', error)
            ).then((json: JSON) => {
                let driverNotifications: DriverNotification[] = (json['driver_notifications'] as object[]).map(notificationJSON => unmarshalDriverNotification(notificationJSON));

                dispatch(ReceiveUserDriverNotifications({ driverNotifications }));

                return Promise.resolve();
            });
    };
}

export const selectVehicleIfNeeded: ActionCreator<ThunkAction<Promise<void>, StoreState, void, Action<any>>> = (vehicles: VehicleTrackingData[]) => {
    return (dispatch, getState) => {
        const selectedVehicle = getState().dashboardView.selectedVehicle;
        if (selectedVehicle !== undefined && selectedVehicle.id !== undefined) {
            return Promise.resolve();
        }

        // select paired vehicle if selected route is set and vehicle should be on route (current time is after departure time)
        if (Config.getBoolean(Config.Key.ShowRouteVehiclePairs)) {
            const selectedRoute = getState().dashboardView.selectedRoute;
            const routes = getState().user.savedRoutes;
            const pairedVehicle = findMatchingVehicleForRoute(selectedRoute, routes, vehicles);
            if (pairedVehicle) {
                dispatch(SetSelectedVehicle({ selectedVehicle: pairedVehicle }));
                return Promise.resolve();
            }
        }

        // otherwise sort vehicles by descending tag priority  and select the first
        const sortedVehicles = vehicles.slice().sort((v1: VehicleTrackingData, v2: VehicleTrackingData) => {
            if (v1?.currentImpact === undefined || v1.currentImpact.tagPriority === undefined) return -1;
            if (v2?.currentImpact === undefined || v2.currentImpact.tagPriority === undefined) return 1;

            return v1.currentImpact.tagPriority - v2.currentImpact.tagPriority;
        }).reverse();

        dispatch(SetSelectedVehicle({ selectedVehicle: sortedVehicles[0] }));
        return Promise.resolve();
    };
};

export function fetchUserVehicles(token: string) {
    return async (dispatch: Dispatch<any>, getState: () => StoreState) => {
        if (getState().user.vehiclesMetadata.loading) {
            console.log('Skip fetching user vehicles because they are still loading from last fetch.');
            return Promise.resolve();
        }
        const limit = 500;
        let offset = 0;
        let hasVehiclesRemaining = true;
        const vehicles: VehicleTrackingData[] = [...(getState().user.vehicles || [])];
        try {
            dispatch(ReceiveUserVehicles({ vehicles, metadata: { success: false, loading: true, error: undefined } }));
            while (hasVehiclesRemaining) {
                const url: string = `${API_HOST}/vehicles.json?token=${token}&limit=${limit}&offset=${offset}`;
                const response = await retryIfRateLimited(() => fetch(url));
                const json = await response.json();
                const vehiclesJson = json['vehicles'] as object[];
                vehiclesJson.forEach(obj => {
                    const vehicle = unmarshalVehicle(obj);
                    const index = vehicles.findIndex(v => v.id === vehicle.id);
                    if (index !== -1) {
                        vehicles.splice(index, 1, vehicle);
                    } else {
                        vehicles.push(vehicle);
                    }
                });

                dispatch(ReceiveUserVehicles({ vehicles, metadata: { success: false, loading: true, error: undefined } }));

                hasVehiclesRemaining = vehiclesJson.length === limit;
                offset += limit;
            }
            dispatch(ReceiveUserVehicles({ vehicles, metadata: { success: true, loading: false, error: undefined } }));

            const currentSelectedVehicle = getState().dashboardView.selectedVehicle;
            if (currentSelectedVehicle) {
                const updatedSelectedVehicle = vehicles.find(x => x.id === currentSelectedVehicle?.id);
                // if updating all vehicles and we find a newer version of the selected vehicle, lets update it
                // otherwise, if we can't find it, let's deselect it since we don't have it
                dispatch(SetSelectedVehicle({ selectedVehicle: updatedSelectedVehicle }));
            }

            return Promise.resolve();
        } catch (error) {
            console.error('Error fetching user vehicles in initial load.', error);
            dispatch(ReceiveUserVehicles({ vehicles, metadata: { success: false, loading: false, error } }));
            return Promise.reject(error);
        }
    };
}

// https://stackoverflow.com/a/55435856
function* chunks<T>(arr: T[], n: number): Generator<T[], void> {
    for (let i = 0; i < arr.length; i += n) {
        yield arr.slice(i, i + n);
    }
}

export function fetchSpecificVehicles(token: string, vehicleIds: number[]) {
    return async (dispatch: Dispatch<any>, getState: () => StoreState) => {
        if (getState().user.vehiclesMetadata.loading) {
            console.log('Skip fetching specific user vehicles because they are still loading from last full fetch.');
            return Promise.resolve();
        }
        const vehicles: VehicleTrackingData[] = [...(getState().user.vehicles || [])];
        try {
            // don't set loading true if only loading a few vehicles, reserve for full vehicle reload
            const isLoading = false;
            console.log(`in fetchSpecificVehicles: loading ${vehicleIds.length} vehicles`);
            dispatch(ReceiveUserVehicles({ vehicles, metadata: { success: false, loading: isLoading, error: undefined } }));

            // need to 'paginate' the ids because otherwise the url can get too long 
            for (const chunk of chunks(vehicleIds, 500)) {
                const url: string = `${API_HOST}/vehicles.json?token=${token}&ids=${chunk.join(',')}`;
                const response = await retryIfRateLimited(() => fetch(url));
                const json = await response.json();
                const vehiclesJson = json['vehicles'] as object[];
                vehiclesJson.forEach(obj => {
                    const vehicle = unmarshalVehicle(obj);
                    const index = vehicles.findIndex(v => v.id === vehicle.id);
                    if (index !== -1) {
                        vehicles.splice(index, 1, vehicle);
                    } else {
                        vehicles.push(vehicle);
                    }
                });

                // only dispatch update after all pages have loaded for fetching specific vehicles
                // dispatch(ReceiveUserVehicles({ vehicles, metadata: { success: false, loading: isLoading, error: undefined } }));
            }
            dispatch(ReceiveUserVehicles({ vehicles, metadata: { success: true, loading: false, error: undefined } }));

            const currentSelectedVehicle = getState().dashboardView.selectedVehicle;
            if (currentSelectedVehicle) {
                const updatedSelectedVehicle = vehicles.find(x => x.id === currentSelectedVehicle?.id);
                if (vehicleIds.find(c => `${c}` === currentSelectedVehicle.id) !== undefined) {
                    // if the selected vehicle is one of the vehicle ids also update it
                    dispatch(SetSelectedVehicle({ selectedVehicle: updatedSelectedVehicle }));
                }
            }

            return Promise.resolve();
        } catch (error) {
            console.error('Error fetching specific user vehicles', error);
            dispatch(ReceiveUserVehicles({ vehicles, metadata: { success: false, loading: false, error } }));
            return Promise.reject(error);
        }
    };
}

export const setVehicleNotificationsEnabled: ActionCreator<ThunkAction<Promise<void>, StoreState, void, Action<any>>> = (vehicle: VehicleTrackingData, enabled: boolean) => {
    return (dispatch, getState) => {
        const token = getState().user.token;

        if (token === undefined) {
            throw new Error('setting notifications enabled without token');
        }

        const payload: RequestInit = {
            cache: 'no-cache',
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token,
                enabled,
            }),
        };

        return retryIfRateLimited(() => fetch(`${API_HOST}/vehicles/${vehicle.id}/set_notifications_enabled`, payload))
            .then(
                (response: Response) => response.json(),
                (error: Error) => {
                    console.log('Error setting notifications enabled.', error);
                }
            ).then((json: JSON) => {
                if (json['error'] !== undefined) {
                    const error = json['error'];
                    console.log('Error setting notifications enabled, from server:', error);
                    return Promise.reject();
                }
                const vehicle: VehicleTrackingData = unmarshalVehicle(json['vehicle']);
                if (vehicle) {
                    dispatch(ReceiveVehicleNotificationsEnabled({ vehicle }));
                }

                return Promise.resolve();
            });
    };
};

export const fetchFeatureFlags: ActionCreator<ThunkAction<Promise<void>, StoreState, void, Action<any>>> = (token: string, portalToken: string) => {
    return (dispatch: Dispatch<any>) => {
        return retryIfRateLimited(() => fetch(`${API_HOST}/users/me/portal_features?token=${token}&portal_token=${portalToken}`))
            .then(
                (response: Response) => response.json(),
                (error: Error) => console.log('Error fetching portal features data.', error)
            ).then((json: JSON) => {
                const showImpactTab = json['show_impact_tab'] as boolean;
                const showRoutesTab = json['show_routes_tab'] as boolean;
                const showDisruptionIndex = json['show_disruption_index'] as boolean;
                const showWildfireIndices = json['show_wildfire_indices'] as boolean;
                const showNowcastTab = json['show_nowcast_tab'] as boolean;
                const show511InPortal = json['feature_flags']?.includes('511_in_portal') ?? false;
                const showEventsInPortal = json['feature_flags']?.includes('events_in_portal') ?? false;

                dispatch(ReceiveFeatureFlags({ showImpactTab, showRoutesTab, showDisruptionIndex, showWildfireIndices, showNowcastTab, show511InPortal, showEventsInPortal }));
                dispatch(loadBlurbsIfNeeded());

                return Promise.resolve();
            });
    };
};

export const fetchAccountInfo: ActionCreator<ThunkAction<Promise<void>, StoreState, void, Action<any>>> = (token: string) => {
    return (dispatch: Dispatch<any>) => {
        return retryIfRateLimited(() => fetch(`${API_HOST}/users/me/portal_account_info?token=${token}`))
            .then((response: Response) => response.json())
            .then((json: JSON) => {
                if (json['error'] === 'please authenticate') {
                    console.log('Error fetching portal account info.', json);
                    dispatch(ReceiveAccountError({ error: json['error'].toString() }));
                    return Promise.resolve();
                }

                const myAccount = unmarshalServerJSON(json) as MyAccountState;

                if (myAccount.apiAccess) {
                    const apiAccessRaw = myAccount.apiAccess as object;
                    for (const key of Object.keys(apiAccessRaw)) {
                        if (key !== camelToSnakeCase(key) && ALL_PERMISSIONS.indexOf(camelToSnakeCase(key)) !== -1) {
                            apiAccessRaw[camelToSnakeCase(key)] = apiAccessRaw[key];
                            delete apiAccessRaw[key];
                        }
                    }

                    myAccount.apiAccess = apiAccessRaw as ApiAccess;
                }

                if (myAccount.subscriptionEndsAt) {
                    myAccount.subscriptionEndsAt = new Date(myAccount.subscriptionEndsAt);
                }

                dispatch(ReceiveAccountInfo({ myAccount }));
                return Promise.resolve();
            }, (error: Error) => {
                console.log('Error fetching portal account info.', error);
                dispatch(ReceiveAccountError({ error: error.toString() }));
            });
    };
};

function map<A, B>(value: A | undefined, f: (value: A) => B): B | undefined {
    if (value === undefined) return undefined;
    return f(value);
}

export const fetchPortalMetadata: ActionCreator<ThunkAction<Promise<void>, StoreState, void, Action<any>>> = () => {
    return (dispatch: Dispatch<any>, getState) => {
        const token = getState().user.token;
        if (token === undefined) {
            return Promise.resolve();
        }

        return retryIfRateLimited(() => fetch(`${API_HOST}/users/me/portal_metadata?token=${token}`))
            .then(
                (response: Response) => response.json(),
                (error: Error) => {
                    console.log('Error fetching portal metadata.', error);
                    return Promise.reject(error);
                }
            ).then((json: JSON) => {
                const weatherDataLatestRunTime = map(json['weather_data_latest_run_time'], x => new Date(Date.parse(x)));
                const latestImpactSummaryTime = getState().user.cities.at(0)?.impactSummariesRatingRunTime;
                // fetch the latest weather_data run time and compare it to our local impact summary run time
                // if they differ, we should refresh our locations in order to pull in fresh impact summary data
                if (latestImpactSummaryTime && weatherDataLatestRunTime && latestImpactSummaryTime.getTime() !== weatherDataLatestRunTime.getTime()) {
                    console.debug('reloading locations to fetch new impact summaries, old time:', latestImpactSummaryTime, ', new time:', weatherDataLatestRunTime);
                    // it was decided that data older than 3 hours is "too old"
                    if (weatherDataLatestRunTime.getTime() - latestImpactSummaryTime.getTime() >= 3 * 3600 * 1000) {
                        // clear the locations data once we detect that it is too old to show a client
                        console.debug('clearing locations because they are too old before a reload');
                        dispatch(ReceiveUserCities({ cities: [], metadata: { success: false, loading: false, error: undefined } }));
                    }
                    dispatch(fetchUserCities(token));
                }
                return Promise.resolve();
            });
    };
};

export const loadInitialUserData: ActionCreator<ThunkAction<Promise<void>, StoreState, void, Action<any>>> = () => {
    return (dispatch, getState) => {
        let token = getState().user.token;
        let portalToken = getState().user.portalToken;

        if (token !== undefined) {
            dispatch(openChannels(token));
            dispatch(fetchFeatureFlags(token, portalToken)).then(() => { });
            dispatch(fetchAccountInfo(token)).then(() => { });
            dispatch(fetchUserCities(token));
            dispatch(fetchUserRoutes(token));
            dispatch(fetchUserVehicles(token));
            dispatch(fetchUserDriverNotifications(token));
            return dispatch(fetchUserProfile(token)).then(() => { });
        } else {
            return Promise.resolve();
        }
    };
};

export const createLogoutAction: ActionCreator<ThunkAction<Promise<void>, StoreState, void, Action<any>>> = (token: string | undefined) => {
    return (dispatch) => {
        return dispatch(initiateLogout(token)).then(() => { });
    };
};

function initiateLogout(token: string | undefined) {
    return (dispatch: Dispatch<any>) => {
        dispatch(StartedLogout()); // this hits the reducers

        closeChannels();

        if (token === undefined) {
            return Promise.resolve();
        }

        // the request is just a formality
        let postData: RequestInit = {
            body: JSON.stringify({ token }),
            cache: 'no-cache',
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
        };

        return retryIfRateLimited(() => fetch(`${API_HOST}/api_sign_out.json`, postData))
            .then(
                (response) => {
                    if (response.status === 200) {
                        console.log("Signed out successfully");
                    } else {
                        console.log(`unexpected status ${response.status} on signout`);
                    }
                },
            ).catch((error) => {
                console.log('Logout error', error);
            });
    };
}
