import {LocationData, locationsHaveEqualCenters, SelectedCityState} from '../../../types';
import * as React from 'react';
import {AutoShowErrorDialog} from "../../shared/AutoShowErrorDialog";
import {cardBackgroundColor} from "../../../constants";

interface AlertCalloutViewProps {
    selectedCityState: SelectedCityState;
    lat: number;
    lng: number;

    onClose: () => void;
    saveLocationTapped: (location: LocationData) => void;
    unsaveLocationTapped: (location: LocationData) => void;
    onCitySaveErrorDismissed: (location: LocationData) => void;
}

export const AlertCalloutView = (props: AlertCalloutViewProps) => {
    const {selectedCityState} = props;
    const {selectedCity} = selectedCityState;

    if (selectedCity === undefined) {
        return <div/>;
    }

    let locationButton: JSX.Element;
    let isCitySaved = selectedCity.id !== undefined;
    if (isCitySaved) {
        locationButton = (
            <div className={"location-button"} onClick={() => props.unsaveLocationTapped(selectedCity)}>
                <img src={"/images/saved_check.svg"} alt={"✓"}/>
                <span>Saved</span>
            </div>
        );
    } else {
        locationButton = (
            <div className={"location-button"} onClick={() => props.saveLocationTapped(selectedCity)}>
                <span>Save Location</span>
            </div>
        );
    }

    const isSavingThisCity = selectedCityState.cityBeingSaved !== undefined &&
        locationsHaveEqualCenters(selectedCityState.cityBeingSaved, selectedCity) &&
        selectedCityState.citySavingError === undefined;

    const isLoading = selectedCityState.isGeocoding || selectedCity.needsGeocoding || isSavingThisCity;

    let extraClassNames = "";
    if (isLoading) {
        extraClassNames = " loading";
    }

    let zip = selectedCity.zip;
    if (zip === '00000') {
        // don't display non-geocoded zips
        zip = '';
    }

    return (
        <div className={`MapCalloutView${extraClassNames}`} onClick={(event) => event.stopPropagation()}> 
            <div className={"container"} style={{cursor: "default"}}>
                <div className={"body"} style={{backgroundColor: cardBackgroundColor}}>
                    <button className={"close-button"} onClick={() => props.onClose()}>
                        <img alt={"Close"} src={"/images/popover_x.svg"}/>
                    </button>

                    <div className={"loading-view"}>
                        <img src={"/images/loading.gif"} alt={"Loading..."}/>
                    </div>

                    <div className={"zip"}>{zip}</div>
                    <div className={"city-name"}>{selectedCity.name || 'Unknown Location'}</div>
                    {locationButton}
                </div>
                <div className={"arrow-down"} style={{borderTop: `20px solid ${cardBackgroundColor}`}}/>
            </div>
            <AutoShowErrorDialog
                title={"Error saving location"}
                error={selectedCityState.citySavingError}
                onDismiss={() => props.onCitySaveErrorDismissed(selectedCity)}
            />
        </div>
    );
};
