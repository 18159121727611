import { action, props, union } from 'ts-action';

export const RadarSetPaused = action('RADAR_SET_PAUSED', props<{ paused: boolean }>());

export const RadarSetAnimationSpeed = action('RADAR_SET_ANIMATION_SPEED', props<{
    selectedSpeedMultiplierIndex: number;
    animationDelay: number;
}>());

export const RadarViewAction = union(RadarSetAnimationSpeed, RadarSetPaused);
