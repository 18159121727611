export const drivingConditionsClusterSvg = (count, markers) => {
  let markerCount = count;

  // get all unique colors
  const colors = markers.map(marker => {
    // not a massive fan of doing it this way -- but it's a POC
    return Array.from(marker.content.childNodes).filter(el => el.tagName === 'path')[0].getAttribute('fill');
  });

  // organize them by count
  const colorCounts = {};
  for (const color of colors) {
    if (color in colorCounts) {
      colorCounts[color] += 1;
    } else {
      colorCounts[color] = 1;
    }
  }

  // get an array sorted by count in descending order like [{color: '#fcfcfc', count: 3}, ...]
  const colorsWithCounts = Object.keys(colorCounts).map(color => {
    return { color, count: colorCounts[color] };
  }).sort((a, b) => b.count - a.count);

  let start = 0;
  const colorsWithOffsets = colorsWithCounts.map(colorWithCount => {
    const { color, count } = colorWithCount;
    const end = start + count / markerCount;
    const result = { color, start, end };
    start = end;
    return result;
  });

  //console.log(colors, colorsWithCounts, colorsWithOffsets);

  const svg = generateClusterSVG(colorsWithOffsets);

  // create cluster SVG element
  const parser = new DOMParser();
  const svgEl = parser.parseFromString(
    svg,
    "image/svg+xml"
  ).documentElement;

  svgEl.setAttribute("transform", "translate(0 25)");

  return svgEl;
};

function generateClusterSVG(colorsWithOffsets) {
  const svgns = "http://www.w3.org/2000/svg"; // SVG namespace

  const createArcs = (centerX, centerY, radius, segments) => {
    let startAngle = 0;

    return segments.map(segment => {
      let fraction = segment.end - segment.start;
      // Calculate end angle
      fraction = fraction >= 1 ? 0.999 : fraction;
      const endAngle = startAngle + fraction * 2 * Math.PI;

      // Convert angles to Cartesian coordinates
      const startX = centerX + radius * Math.cos(startAngle - Math.PI / 2);
      const startY = centerY + radius * Math.sin(startAngle - Math.PI / 2);
      const endX = centerX + radius * Math.cos(endAngle - Math.PI / 2);
      const endY = centerY + radius * Math.sin(endAngle - Math.PI / 2);

      // Large arc flag
      const largeArcFlag = (fraction > 0.5) ? 1 : 0;

      // Create the path
      const path = document.createElementNS(svgns, 'path');
      const d = [
        `M ${centerX} ${centerY}`, // Move to center
        `L ${startX} ${startY}`, // Line to start
        `A ${radius} ${radius} 0 ${largeArcFlag} 1 ${endX} ${endY}`, // Arc to end
        `Z` // Close path
      ].join(' ');

      path.setAttribute('d', d);
      path.setAttribute('fill', segment.color);
      // svg.appendChild(path);

      // Update start angle to the end angle of the current segment
      startAngle = endAngle;

      return path.outerHTML;
    }).join('');
  };

  return `
    <svg width="32" height="28" viewBox="0 0 32 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <mask id="triangleMask0">
          <circle cx="13" cy="13" r="11" fill="white"/>
        </mask>
        <mask id="triangleMask1">
          <circle cx="13" cy="13" r="12.5" stroke="white"/>
        </mask>
      </defs>
      <g mask="url(#triangleMask0)">
        ${createArcs(13, 13, 13, colorsWithOffsets)}
      </g>
      <g mask="url(#triangleMask1)">
        ${createArcs(13, 13, 13, colorsWithOffsets)}
      </g>
    </svg>
  `;
}