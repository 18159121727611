import {
    calculateImpactScore,
    RouteData
} from "../../../types/routes";
import {
    GridCsvExportOptions,
    gridSortedRowIdsSelector,
    GridToolbarContainer
} from "@mui/x-data-grid";
import * as React from "react";
import { PolylineLegend } from "./PolylineLegend";
import {
    Button,
    ButtonProps,
    Checkbox,
    CircularProgress,
    createSvgIcon,
    FormControlLabel,
    IconButton,
    InputAdornment,
    MenuItem,
    Paper,
    Select,
    Tab,
    Tabs,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import { filterRoutes, findMatchingRouteForVehicle, findMatchingVehicleForRoute, Timeframe } from "./data";
import { ImpactedRoutesTable } from "./ImpactedRoutesTable";
import { BlurbsByIndex, LightningData, LoadableResultMetadata, LocationData, UserState, VehicleTrackingData } from '../../../types';
import { ImpactedLocationsTable } from "./ImpactedLocationsTable";
import { GridApi, GridToolbarColumnsButton } from "@mui/x-data-grid-pro";
import { ClearOutlined, KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { cardBackgroundColor } from "../../../constants";
import { ImpactedVehiclesTable } from "./ImpactedVehiclesTable";
import { PolylineType } from ".";
import { Config } from "src/components/shared/useConfig";

export const ExportIcon = createSvgIcon(
    <path d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z" />,
    'SaveAlt',
);

const getTimestampForFileName = () => {
    const date = new Date();
    return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}_${date.getHours().toString().padStart(2, '0')}-${date.getMinutes().toString().padStart(2, '0')}-${date.getSeconds().toString().padStart(2, '0')}`;
};

export const CustomToolbar = (apiRef: React.MutableRefObject<GridApi>, fileNamePrefix: string, showColumnsPicker: boolean) => {
    return function _CustomToolbar() {
        const handleExport = (options: GridCsvExportOptions) => apiRef.current.exportDataAsCsv(options);

        const buttonBaseProps: ButtonProps = {
            color: 'primary',
            size: 'small',
            startIcon: <ExportIcon />,
        };

        return (
            <GridToolbarContainer>
                <div className={'DashboardGridToolbar'} /* classname is used for portal tour, no css assigned to it */>
                    <Button
                        {...buttonBaseProps}
                        onClick={() => handleExport({
                            fileName: `${fileNamePrefix}_${getTimestampForFileName()}`,
                            getRowsToExport: () => gridSortedRowIdsSelector(apiRef)
                        })}
                    >
                        Export
                    </Button>
                    {showColumnsPicker && <GridToolbarColumnsButton />}
                </div>
            </GridToolbarContainer>
        );
    };
};

export type DashboardView = 'route' | 'location' | 'vehicle';
export type DashboardViewMetadata = {
    key: string,
    name: string,
    tooltip: string,
}
export type TimelineView = 'impact' | 'weather';

interface ImpactDetailViewProps {
    userData: UserState;
    token: string | undefined;
    routes: RouteData[];
    routesMetadata: LoadableResultMetadata;
    refreshingRouteIds: number[];
    locations: LocationData[];
    highImpactLocations: LocationData[];
    locationsMetadata: LoadableResultMetadata;
    vehicles: VehicleTrackingData[];
    vehiclesMetadata: LoadableResultMetadata;
    filteredVehicles: VehicleTrackingData[];
    lightning?: LightningData[];
    timeframes: Timeframe[];
    selectedView: DashboardView;
    selectedTimelineView: TimelineView;
    polylineType: PolylineType;

    selectedLocation?: LocationData;
    selectedRoute?: RouteData;
    selectedVehicle?: VehicleTrackingData;
    selectedTimeframeIndex: number;
    showOnlyHighImpactLocations: boolean;
    filterNoImpactVehicles?: boolean;

    onlyImpactTable?: boolean;

    filteredViews?: string[];

    blurbs: BlurbsByIndex;

    onRouteSelected: (route: RouteData | undefined) => void;
    onLocationSelected: (location: LocationData) => void;
    onVehicleSelected: (vehicle: VehicleTrackingData | undefined) => void;
    onVehicleMessageRequested: (vehicle: VehicleTrackingData, message: string, urgent: boolean) => void;
    onViewSelected: (view: DashboardView) => void;
    onTimelineViewSelected: (timelineView: TimelineView) => void;
    onTimeframeIndexChanged: (timeframeIndex: number) => void;
    onRouteRefreshRequested: (route: RouteData) => void;
    onShowOnlyHighImpactLocations: (filter: boolean) => void;
    onFilterNoImpactVehicles: (filter: boolean) => void;
    onPolylineTypeSet: (polylineType: PolylineType) => void;
}

export const ImpactDetailView = (props: ImpactDetailViewProps) => {
    const { userData, routes, routesMetadata, refreshingRouteIds, locations, locationsMetadata, highImpactLocations, timeframes, vehicles, vehiclesMetadata, filteredVehicles, lightning, polylineType, selectedRoute, selectedLocation, selectedVehicle, selectedView, selectedTimelineView, selectedTimeframeIndex, showOnlyHighImpactLocations, filterNoImpactVehicles, filteredViews, onlyImpactTable, blurbs, onRouteSelected, onLocationSelected, onVehicleSelected, onVehicleMessageRequested, onViewSelected, onTimeframeIndexChanged, onRouteRefreshRequested, onShowOnlyHighImpactLocations, onFilterNoImpactVehicles, onPolylineTypeSet, onTimelineViewSelected } = props;

    const [collapsed, setCollapsed] = React.useState(false);
    const [searchQuery, setSearchQuery] = React.useState('');

    const totalVehicleCount = vehicles.length;
    const selectedTimeframe = timeframes[selectedTimeframeIndex];
    const routesInTimeframe = filterRoutes(routes, selectedTimeframe);

    // clear search query when changing views so the new view doesnt use the old search query
    React.useEffect(() => {
        setSearchQuery('');

        // views that do not have timeframe selectors (weather risks and vehcles) default to 'today' timeframe
        if (selectedView === 'vehicle' || (selectedView === 'location' && selectedTimelineView === 'weather')) {
            onTimeframeIndexChanged(0);
        }
    }, [selectedView]);

    // populate initially selected route
    React.useEffect(() => {
        if (selectedView !== 'route') {
            // only select inital route if in routes view
            return;
        }

        if (routesMetadata.loading) {
            // wait to select the initial route until all routes are loaded so the datagrid doesn't jump between pages
            return;
        }

        if (routesInTimeframe.length === 0) {
            // not enough data loaded yet
            return;
        }

        if (selectedRoute !== undefined && routes.filter((route) => route.id === selectedRoute.id).length > 0) {
            // initial route already loaded in current timeframe
            return;
        }

        // reverse sort (high to low) for impact value to select the route with the highest impact score
        // (should also be the first route in the table because imapct value is the default sort for the table)
        const sortedRoutesInTimeframe = [...routesInTimeframe].sort((a: RouteData, b: RouteData) => {
            if (a === undefined || a!.latestRouteResults === undefined) return 1;
            if (b === undefined || b!.latestRouteResults === undefined) return -1;
            return calculateImpactScore(b.latestRouteResults[0]) - calculateImpactScore(a.latestRouteResults[0]);
        });

        const firstRoute = sortedRoutesInTimeframe[0];

        onRouteSelected(firstRoute);
        // select paired vehicle is vehicle should be on route (current time is after departure time)
        if (Config.getBoolean(Config.Key.ShowRouteVehiclePairs)) {
            onVehicleSelected(findMatchingVehicleForRoute(firstRoute, routes, vehicles));
        }
    }, [routesMetadata.loading, routesInTimeframe, selectedRoute, onRouteSelected, selectedView]);

    const loadingProgressIndicator = (
        <div style={{ width: 50, height: 40, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {/* we check if either routes or vehicles is loading because the vehicles column won't be complete until vehicles are loaded */}
            {selectedView === 'route' && (routesMetadata.loading || vehiclesMetadata.loading) && <CircularProgress size={20} />}
            {selectedView === 'route' && routesMetadata.error !== undefined && <Tooltip title={'There was an error while fetching your routes. Please refresh the page to try again. Contact us if the issue persists.'}>
                <div>
                    {'⚠️'}
                </div>
            </Tooltip>}
            {selectedView === 'location' && locationsMetadata.loading && <CircularProgress size={20} />}
            {selectedView === 'location' && locationsMetadata.error !== undefined && <Tooltip title={'There was an error while fetching your locations. Please refresh the page to try again. Contact us if the issue persists.'}>
                <div>
                    {'⚠️'}
                </div>
            </Tooltip>}
            {/* we check if either vehicles or routes is loading because the route columns (destination, route) won't be complete until routes are loaded */}
            {selectedView === 'vehicle' && (vehiclesMetadata.loading || routesMetadata.loading) && <CircularProgress size={20} />}
            {selectedView === 'vehicle' && vehiclesMetadata.error !== undefined && <Tooltip title={'There was an error while fetching your vehicles. Please refresh the page to try again. Contact us if the issue persists.'}>
                <div>
                    {'⚠️'}
                </div>
            </Tooltip>}
        </div>
    );

    let dashboardViewMetadata: DashboardViewMetadata[] = [
        {
            key: 'route',
            name: 'Shipment Impacts',
            tooltip: 'See route slowdowns and weather risks for drivers.',
        },
        {
            key: "location-impact",
            name: 'Operational Impacts',
            tooltip: 'See operational impact risks for your saved sites and locations, categorized by type of impact, like life & property or business disruption.',
        },
    ];
    // only add weather risks/vehicles if nowcast is disabled
    if (!userData.showNowcastTab) {
        dashboardViewMetadata.push({
            key: "location-weather",
            name: 'Weather Risks',
            tooltip: 'See weather-related risks for your saved sites and locations, categorized by type of weather, like lightning or wind.',
        });
        dashboardViewMetadata.push({
            key: "vehicle",
            name: 'Live Vehicle Impacts',
            tooltip: 'Track your vehicles to understand current and upcoming weather risks along with recommended driver actions.',
        });
    }

    if (filteredViews) {
        dashboardViewMetadata = dashboardViewMetadata.filter(x => filteredViews?.includes(x.key));
    }

    // if we can't find the selected view in our dashboard metadata, select the first available item
    // this could happen if a user was viewing vehicles and then we enabled nowcast on their account
    // so vehicles got removed from the dashboardViewMetadata
    if (dashboardViewMetadata.find(x => x.key.split('-')[0] === selectedView) === undefined) {
        onViewSelected(dashboardViewMetadata[0].key.split('-')[0] as DashboardView);
    }

    const selectedViewPlusSelectedTimelineView = selectedView === 'location' ? `${selectedView}-${selectedTimelineView}` : selectedView;

    return (
        <div style={{ position: 'absolute', bottom: 24, left: 12, right: 64 }}>
            {selectedView === 'route' && <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', marginBottom: 12 }}>
                <PolylineLegend type={polylineType} setPolylineType={(type) => onPolylineTypeSet(type)} />
            </div>}
            <Paper elevation={3} style={{ backgroundColor: cardBackgroundColor, backgroundImage: 'none', borderRadius: 11 }}>
                <header style={{ padding: '12px 12px 10px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    {!onlyImpactTable && <Select
                        className={'DashboardViewSelector'} // classname is used for portal tour, no css assigned to it
                        value={selectedViewPlusSelectedTimelineView}
                        variant={'outlined'}
                        style={{ width: 200, height: 40 }}
                        onChange={(event) => {
                            const value = event.target.value as string;
                            if (value.includes('-')) {
                                const [dashboardView, timelineView] = value.split('-');
                                onViewSelected(dashboardView as DashboardView);
                                onTimelineViewSelected(timelineView as TimelineView);
                            } else {
                                onViewSelected(value as DashboardView);
                            }
                        }}
                    >
                        {dashboardViewMetadata.map((metadata) => {
                            return (
                                <MenuItem value={metadata.key}>
                                    <Tooltip placement="right" title={metadata.tooltip}>
                                        <div style={{ width: '100%', height: '100%' }}>
                                            {metadata.name}
                                        </div>
                                    </Tooltip>
                                </MenuItem>
                            );
                        })}
                    </Select>}
                    {/* put loading progress indicator after tabs in impact only mode */}
                    {!onlyImpactTable && loadingProgressIndicator}
                    {
                        ((selectedView === 'route') || (selectedView === 'location' && selectedTimelineView !== 'weather')) && <Tabs
                            className={'DashboardTimeframeSelector'} // classname is used for portal tour, no css assigned to it
                            value={selectedTimeframeIndex}
                            onChange={(eventevent, newValue) => onTimeframeIndexChanged(newValue)}
                            indicatorColor={'primary'}
                            textColor={'primary'}
                        >
                            {timeframes.map(timeframe => <Tab label={timeframe.name} />)}
                        </Tabs>
                    }
                    {onlyImpactTable && loadingProgressIndicator}
                    {selectedView === 'location' && selectedTimelineView === 'impact' && <FormControlLabel style={{ marginLeft: "10px" }} label="Only High Impact" control={<Checkbox checked={showOnlyHighImpactLocations} onChange={() => onShowOnlyHighImpactLocations(!showOnlyHighImpactLocations)} />} />}
                    {selectedView === 'vehicle' && <FormControlLabel label="Only Impacted Vehicles" control={<Checkbox checked={filterNoImpactVehicles} onChange={() => onFilterNoImpactVehicles(!filterNoImpactVehicles)} />} />}
                    <div style={{ flex: '1 1 0' }} />
                    {
                        !collapsed && <TextField
                            placeholder={"Search"}
                            variant={'standard'}
                            style={{ marginRight: 20, marginTop: 4 }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <IconButton size="small" onClick={() => setSearchQuery('')}>
                                        <ClearOutlined style={{ visibility: searchQuery ? 'visible' : 'hidden' }} />
                                    </IconButton>
                                )
                            }}
                            value={searchQuery}
                            onChange={(event) => setSearchQuery(event.target.value as string)}
                        />
                    }
                    <Button
                        variant={'contained'}
                        color={'primary'}
                        href={`javascript:;`}
                        onClick={() => setCollapsed(!collapsed)}
                        style={{ height: 40 }}
                    >
                        {collapsed ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </Button>
                </header >

                {!collapsed && <Typography style={{ opacity: '70%' }} paddingLeft={'12px'} paddingBottom={'10px'} fontSize={'0.75rem'}>
                    {dashboardViewMetadata.find(x => x.key === selectedViewPlusSelectedTimelineView)?.tooltip}
                </Typography>}

                {!collapsed && <div style={{ width: '100%', height: 1, backgroundColor: '#FFFFFF47' }} />}

                {
                    !collapsed && selectedView === 'route' && <ImpactedRoutesTable
                        token={props.token}
                        routes={routesInTimeframe}
                        routesMetadata={routesMetadata}
                        vehicles={vehicles}
                        vehiclesMetadata={vehiclesMetadata}
                        refreshingRouteIds={refreshingRouteIds}
                        selectedRoute={selectedRoute}
                        selectedView={selectedView}
                        onViewSelected={onViewSelected}
                        searchQuery={searchQuery}
                        blurbs={blurbs}
                        onRouteSelected={(route) => {
                            onRouteSelected(route);
                            // select paired vehicle is vehicle should be on route (current time is after departure time)
                            if (Config.getBoolean(Config.Key.ShowRouteVehiclePairs)) {
                                onVehicleSelected(findMatchingVehicleForRoute(route, routes, vehicles));
                            }
                        }}
                        onRouteRefreshRequested={(route) => onRouteRefreshRequested(route)}
                    />
                }

                {
                    !collapsed && selectedViewPlusSelectedTimelineView === 'location-impact' && <ImpactedLocationsTable
                        locations={highImpactLocations}
                        locationsMetadata={locationsMetadata}
                        lightning={lightning}
                        timelineView={selectedTimelineView}
                        timeframe={selectedTimeframe}
                        selectedLocation={selectedLocation}
                        searchQuery={searchQuery}
                        showOnlyHighImpactLocations={showOnlyHighImpactLocations}
                        blurbs={blurbs}
                        onLocationSelected={(location) => onLocationSelected(location)}
                    />
                }

                {
                    !collapsed && selectedViewPlusSelectedTimelineView === 'location-weather' && <ImpactedLocationsTable
                        locations={locations}
                        locationsMetadata={locationsMetadata}
                        lightning={lightning}
                        timelineView={selectedTimelineView}
                        timeframe={selectedTimeframe}
                        selectedLocation={selectedLocation}
                        searchQuery={searchQuery}
                        showOnlyHighImpactLocations={false}
                        blurbs={blurbs}
                        onLocationSelected={(location) => onLocationSelected(location)}
                    />
                }

                {
                    !collapsed && selectedView === 'vehicle' && <ImpactedVehiclesTable
                        vehicles={filteredVehicles}
                        vehiclesMetadata={vehiclesMetadata}
                        totalVehicleCount={totalVehicleCount}
                        routes={routesInTimeframe}
                        routesMetadata={routesMetadata}
                        selectedVehicle={selectedVehicle}
                        selectedView={selectedView}
                        filterNoImpactVehicles={filterNoImpactVehicles}
                        onViewSelected={onViewSelected}
                        searchQuery={searchQuery}
                        onVehicleSelected={(vehicle) => {
                            onVehicleSelected(vehicle);
                            if (Config.getBoolean(Config.Key.ShowRouteVehiclePairs)) {
                                onRouteSelected(findMatchingRouteForVehicle(vehicle, routes));
                            }
                        }}
                        onVehicleMessageRequested={onVehicleMessageRequested}
                    />
                }
            </Paper >
        </div >
    );
};
