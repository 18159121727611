import { uniq } from 'lodash';
import * as React from 'react';
import { cardBackgroundColor } from 'src/constants';
import { categoryToColorMapping, getCategoryForReportType, getColorForReportType, StormReportData } from 'src/types';

interface Props {
    stormReports?: StormReportData[];
}

export const StormReportsLegendComponent = (props: Props) => {
    // true = show overall categories in legend, false = show all individual report types
    const showOnlyCategories = true;

    let stormReports = props.stormReports ?? [];
    const reportTypes = uniq(stormReports.map(report => report.reportType));
    const sortedReportTypes = reportTypes.sort((a, b) => {
        return getCategoryForReportType(a).localeCompare(getCategoryForReportType(b));
    });

    const categories = uniq(sortedReportTypes.map(reportType => getCategoryForReportType(reportType)));
    const sortedCategories = categories.sort((a, b) => {
        return a.localeCompare(b);
    });

    const legendItems = showOnlyCategories ? sortedCategories : sortedReportTypes;
    const colorFunction = showOnlyCategories ?
        (category: string) => categoryToColorMapping[category] :
        (reportType: string) => getColorForReportType(reportType);

    const stormReportLegendItems = legendItems.map(item => {
        return (
            <div key={item} className={'storm-report'}>
                <div style={{
                    backgroundColor: colorFunction(item),
                    width: 16,
                    height: 16,
                    borderRadius: 8,
                    flex: 'none'
                }} />

                <div>{item}</div>
            </div>
        );
    });

    const columns = [];
    const columnLength = Math.ceil(stormReportLegendItems.length / 3);
    for (let i = 0; i < stormReportLegendItems.length; i += columnLength) {
        const chunk = stormReportLegendItems.slice(i, i + columnLength);
        columns.push((<div className={`storm-report-column`}>{chunk}</div>));
    }

    return (
        <div className={`storm-report-legend`} style={{ backgroundColor: cardBackgroundColor }}>
            <div className={`storm-report-legend-body`}>
                {columns.length === 0 ? "No storm reports for this area." : columns}
            </div>
        </div>
    );
};
