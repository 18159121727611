import * as React from "react";
import './index.css';
import { RouteAndMarkerMap, defaultRouteAndMarkerMapProps } from "../../shared/RouteAndMarkerMap";
import { CircularProgress, Paper } from "@mui/material";
import { ALERTS_HOST, cardBackgroundColor } from "src/constants";
import { AlertData, LoadableResultMetadata } from "src/types";
import { unmarshalAlertData } from "src/types/unmarshal";
import { MaskType } from "../../../types/MapType";

export interface GeoJSONLayer {
    id: any;
    geojsonData: any;
    style?: any | ((feature: any) => void);
    onClick?: (feature: any) => void;
}

export interface Props {
    governmentalAlertId: string;
    token: string;
    showAlertInfo: boolean;
    showMapControls: boolean;
}

// ported from https://www.npmjs.com/package/geojson-bbox
export const bbox = (gj: object) => {
    let coords, bbox;
    // if (!gj.hasOwnProperty('type')) return;
    coords = getCoordinatesDump(gj);
    bbox = [Number.POSITIVE_INFINITY, Number.POSITIVE_INFINITY,
    Number.NEGATIVE_INFINITY, Number.NEGATIVE_INFINITY,];
    const result = coords.reduce(function (prev: any, coord: any) {
        return [
            Math.min(coord[0], prev[0]),
            Math.min(coord[1], prev[1]),
            Math.max(coord[0], prev[2]),
            Math.max(coord[1], prev[3])
        ];
    }, bbox);
    // Array(4) [ 171.50031500315004, 53.2007060117665, 180, 58.99996347717463 ]
    return {
        sw: {
            lat: result[1],
            lng: result[0]
        },
        ne: {
            lat: result[3],
            lng: result[2]
        }
    };
};

const getCoordinatesDump = (gj: any) => {
    let coords: any;
    if (gj.type === 'Point') {
        coords = [gj.coordinates];
    } else if (gj.type === 'LineString' || gj.type === 'MultiPoint') {
        coords = gj.coordinates;
    } else if (gj.type === 'Polygon' || gj.type === 'MultiLineString') {
        coords = gj.coordinates.reduce(function (dump: any, part: any) {
            return dump.concat(part);
        }, []);
    } else if (gj.type === 'MultiPolygon') {
        coords = gj.coordinates.reduce(function (dump: any, poly: any) {
            return dump.concat(poly.reduce(function (points: any, part: any) {
                return points.concat(part);
            }, []));
        }, []);
    } else if (gj.type === 'Feature') {
        coords = getCoordinatesDump(gj.geometry);
    } else if (gj.type === 'GeometryCollection') {
        coords = gj.geometries.reduce(function (dump: any, g: any) {
            return dump.concat(getCoordinatesDump(g));
        }, []);
    } else if (gj.type === 'FeatureCollection') {
        coords = gj.features.reduce(function (dump: any, f: any) {
            return dump.concat(getCoordinatesDump(f));
        }, []);
    }
    return coords;
};

export const GovernmentalAlertsPage = (props: Props) => {
    const { governmentalAlertId, token, showAlertInfo, showMapControls } = props;
    const [alertData, setAlertData] = React.useState<AlertData | undefined>(undefined);
    const [alertMetadata, setAlertMetadata] = React.useState<LoadableResultMetadata>({ success: false, loading: false, error: undefined });
    const [alertLayer, setAlertLayer] = React.useState<GeoJSONLayer | undefined>(undefined);

    // fetch alert data
    React.useEffect(() => {
        const getAlertData = async () => {
            setAlertMetadata({ success: false, loading: true, error: undefined });
            let json: any;
            try {
                const response = await fetch(`${ALERTS_HOST}/weather/${governmentalAlertId}?token=${token}`);
                json = await response.json();
            } catch (e) {
                json = {
                    "error": e.toString(),
                };
            }
            const error = json['error'];
            if (error) {
                console.log("Error getting alert: ", error);
                setAlertData(undefined);
                let errorMessage: string;
                if (error === 'alert not found') {
                    errorMessage = 'The alert was not found because it does not exist or is no longer active.';
                } else {
                    errorMessage = 'There was an issue loading the alert. Please try again later.';
                }
                setAlertMetadata({ success: false, loading: false, error: new Error(errorMessage) });
            } else {
                // success
                setAlertData(unmarshalAlertData(json));
                setAlertMetadata({ success: true, loading: false, error: undefined });
                json.properties.type = 'governmental_alert';
                setAlertLayer({
                    id: json['id'],
                    geojsonData: json,
                    style: (feature: any) => {
                        const fillColor = feature.getProperty('fill');
                        const fillOpacity = 0.7; // feature.getProperty('fill-opacity');
                        const priority = feature.getProperty('priority');
                        return {
                            fillColor,
                            fillOpacity,
                            strokeWeight: 1,
                            strokeColor: '#5A5A5A',
                            strokeOpacity: 0.3,
                            zIndex: 10000 - priority
                        };
                    },
                    onClick: (feature: any) => {
                        console.log(`clicked ${feature}`);
                    },
                });
            }
        };

        getAlertData();
    }, [governmentalAlertId]);

    const alertInfoComponent = (
        <div className={'alert-info'}>
            <Paper elevation={3} style={{ backgroundColor: cardBackgroundColor, backgroundImage: 'none', padding: 15, borderRadius: 11 }}>
                <div className={"active-alert-title"}>
                    {alertData?.details.name}
                </div>

                <div className={"active-alert-issued"}>
                    Issued
                    {" "}
                    {alertData && new Date(alertData.timestamps.issued).toLocaleDateString('en-US', { month: 'numeric', day: 'numeric', year: 'numeric' })}
                    {", "}
                    {alertData && new Date(alertData.timestamps.issued).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true, timeZoneName: 'short' })}
                </div>

                <div className={"active-alert-full-description"}>
                    {alertData && alertData.details.body}
                </div>
            </Paper>
        </div>
    );

    if (!alertMetadata.success) {
        return (
            <div className={'RouteReport'} style={{ backgroundColor: cardBackgroundColor, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {alertMetadata.loading ? <CircularProgress /> : <p>{alertMetadata.error?.message}</p>}
            </div>
        );
    }

    return (
        <div className={'GovernmentalAlert'}>
            <RouteAndMarkerMap
                {...defaultRouteAndMarkerMapProps}

                initialBounds={alertLayer && bbox(alertLayer.geojsonData)}
                padding={{ bottom: showAlertInfo ? 300 : 0 }}
                zoomLevel={8}
                // this shows up in the top right even though that doesn't match the enum
                // that's in the types...
                zoomControlOptions={{
                    position: 3.0,
                }}
                mapTypeControlOptions={{
                    ...defaultRouteAndMarkerMapProps.mapTypeControlOptions,
                    position: 3.0,
                }}

                maskType={MaskType.Labels}

                mapTypeControl={showMapControls}
                zoomControl={showMapControls}

                geojsonLayers={alertLayer ? [alertLayer] : []}

                portalToken={token}
            />
            {showAlertInfo && alertInfoComponent}
        </div >
    );
};


