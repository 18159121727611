import GoogleMapReact from 'google-map-react';
import * as React from 'react';
import { realColorForSlowdownColor } from 'src/types/routes';
import { bootstrapURLKeys, IMPACT_TILES_ENDPOINT } from '../../../constants';
import { maskStyle } from '../Impact/MapManager';
import { darkModeStyle, labelsStyle } from "../Impact/MapManager";
import { getLayerForMap } from '../Impact/MapManager';
import { MapManagerV2 } from '../Impact/MapManagerV2';

export const HistoricalAccuracyMap = (props) => {
    const [map, setMap] = React.useState(undefined);
    const [maps, setMaps] = React.useState(undefined);
    const [markers, setMarkers] = React.useState([]);
    const [tileLayer, setTileLayer] = React.useState(null);
    const [tileUrl, setTileUrl] = React.useState(null);
    const [segmentPaths, setSegmentPaths] = React.useState([]);
    const [mapManagerV2] = React.useState(() => new MapManagerV2());
    // track previous segment paths when they change so we can remove old ones
    // (they aren't stored on the map anywhere, so we have to deal with it)
    // https://blog.logrocket.com/how-to-get-previous-props-state-with-react-hooks/
    const prevSegmentPathsRef = React.useRef();
    React.useEffect(() => {
        prevSegmentPathsRef.current = segmentPaths;
    });

    const prevMarkersRef = React.useRef();
    React.useEffect(() => {
        prevMarkersRef.current = markers;
    });

    const { onGroundTruthReportSelected, onRouteSelected, onCenterChanged, selectedGroundTruthReport, selectedStormEvent, selectedStormImpact, selectedForecastsDaysOut, showGroundTruthData, showForecastedStormsData, resetMapToggle, portalToken, selectedStorm, onStormSelected } = props;

    // lets us get up to date values in callbacks
    const tileUrlRef = React.useRef();
    tileUrlRef.current = tileUrl;
    const selectedGroundTruthReportRef = React.useRef();
    selectedGroundTruthReportRef.current = selectedGroundTruthReport;

    // effect to fit bounds to selected storm event
    React.useEffect(() => {
        if (!map || !maps) return;

        if (!selectedStormEvent || !selectedStormImpact) {
            return;
        }

        // for right route impact fit map bounds to routes
        if (selectedStormImpact.name === 'RightRoute') {
            let bounds = new maps.LatLngBounds();
            const routesForDay = selectedStormImpact.routes[(selectedStormImpact.routes.length - 1) - selectedForecastsDaysOut];
            routesForDay?.forEach((route) => {
                route.optionsForRoute.forEach((routeOption) => {
                    routeOption.roadRiskPolylines?.forEach((segment) => {
                        const segmentPath = new maps.Polyline({ path: maps.geometry.encoding.decodePath(segment.polyline) });
                        segmentPath.getPath().forEach((e) => {
                            bounds.extend(e);
                        });
                    });
                });
            });
            map.fitBounds(bounds);
        } else {
            // for everything else fit map bounds to storm event
            map.fitBounds(new maps.LatLngBounds({ lat: selectedStormEvent.location.swLatitude, lng: selectedStormEvent.location.swLongitude }, { lat: selectedStormEvent.location.neLatitude, lng: selectedStormEvent.location.neLongitude }));
        }

    }, [map, maps, selectedStormEvent, selectedStormImpact, resetMapToggle]);

    // effect to render impact tile
    React.useEffect(() => {
        if (!map || !maps) return;

        if (selectedStormEvent === undefined || selectedStormImpact === undefined || selectedForecastsDaysOut === undefined) {
            // no storm event impact available to draw tile for
            return;
        }

        // remove previous impact layer
        if (map.overlayMapTypes.getArray().indexOf(tileLayer) !== -1) {
            map.overlayMapTypes.removeAt(map.overlayMapTypes.getArray().indexOf(tileLayer));
        }

        // Remove all previously-added segment paths
        const prevSegmentPaths = prevSegmentPathsRef.current;
        for (const segment of prevSegmentPaths) {
            segment.setMap(null);
        }

        // show tiles for most impact types
        if (selectedStormImpact.tiles !== undefined && selectedStormImpact.tiles.length > 0) {
            // get new impact layer
            const impactTileURL = IMPACT_TILES_ENDPOINT + selectedStormImpact.tiles[(selectedStormImpact.tiles.length - 1) - selectedForecastsDaysOut].url;
            setTileUrl((new URL(impactTileURL)).searchParams.get('url'));
            const nextImpactTileLayer = {
                getTileUrl: function (tile, zoom) {
                    if (zoom < 1 || zoom > 15) {
                        return "/images/empty_tile.png";
                    }
                    return impactTileURL.replace("{x}", tile.x)
                        .replace("{y}", tile.y)
                        .replace("{z}", zoom)
                        .replace("{size}", 1)
                        .replace("{token}", portalToken);
                },
                tileSize: new maps.Size(256, 256),
                minZoom: 1,
                maxZoom: 15,
                name: `${selectedStormEvent.name}: ${selectedStormImpact.name}-${selectedForecastsDaysOut} ${impactTileURL}`
            };

            const impactLayerType = new maps.ImageMapType(nextImpactTileLayer);
            setTileLayer(impactLayerType);

            // add new impact layer
            map.overlayMapTypes.insertAt(0, impactLayerType);

        }

        // show routes for right route impact
        if (selectedStormImpact.routes !== undefined && selectedStormImpact.routes.length > 0) {
            let newSegmentPaths = [];
            const routesForDay = selectedStormImpact.routes[(selectedStormImpact.routes.length - 1) - selectedForecastsDaysOut];
            routesForDay?.forEach((route) => {
                // get the bounds for teh route result to use for panning the map to if the route is clicked
                let bounds = new maps.LatLngBounds();
                route.optionsForRoute.forEach((routeOption) => {
                    routeOption.roadRiskPolylines?.forEach((segment) => {
                        const segmentPath = new maps.Polyline({ path: maps.geometry.encoding.decodePath(segment.polyline) });
                        segmentPath.getPath().forEach((e) => {
                            bounds.extend(e);
                        });
                    });
                });

                // add routes to map and add click listeners to routes
                route.optionsForRoute.forEach((routeOption, index) => {
                    routeOption.roadRiskPolylines?.forEach((segment) => {
                        const segmentPath = new maps.Polyline({
                            path: maps.geometry.encoding.decodePath(segment.polyline),
                            strokeColor: realColorForSlowdownColor(segment.color),
                            strokeOpacity: 1.0,
                            strokeWeight: 5,
                        });
                        segmentPath['maxRoadIndex'] = segment.maxRoadIndex;
                        segmentPath['color'] = segment.color;
                        segmentPath.setMap(map);
                        segmentPath.addListener('click', (event) => {
                            map.fitBounds(bounds);
                            const center = map.getCenter();
                            onCenterChanged({ lat: center.lat(), lng: center.lng() });
                            onRouteSelected(route.description, routeOption, index);
                        });
                        newSegmentPaths.push(segmentPath);
                    });
                });
            });

            setSegmentPaths(newSegmentPaths);

            // hack to makes the polyline show UNDER the place labels map layer
            const polylineLayerDiv = getLayerForMap(map, 2);

            if (polylineLayerDiv) {
                polylineLayerDiv.style.zIndex = 98;
            } else {
                console.warn("could not get polyline layer div: this likely means that the safety-pigged code has been broken by a gmaps sdk update.");
            }
        }

    }, [map, maps, selectedStormEvent, selectedStormImpact, selectedForecastsDaysOut]);

    // effect to render storms
    React.useEffect(() => {
        if (!map || !maps) return;

        if (selectedStormEvent === undefined || selectedForecastsDaysOut === undefined) {
            // no storm event impact available to draw tile for
            return;
        }

        mapManagerV2.setupStorms(
            selectedStormEvent.events[selectedStormEvent.events.length - 1 - selectedForecastsDaysOut],
            showForecastedStormsData,
            selectedStorm,
            (event, storm) => {
                event.domEvent.stopPropagation();
                onStormSelected(storm);
            },
        );
    }, [map, maps, selectedStormEvent, selectedForecastsDaysOut, showForecastedStormsData, selectedStorm]);

    // when the forecast day changes, we clear the storm because it is no longer going to be shown
    React.useEffect(() => {
        onStormSelected(undefined);
    }, [selectedForecastsDaysOut]);

    const onMapClicked = (latLng) => {
        const clickGroundTruthReport = {
            location: {
                zip: '00000',
                name: 'MAP CLICK',
                latitude: parseFloat(latLng.lat()),
                longitude: parseFloat(latLng.lng()),
                impactSummaries: []
            },
            polygon: null,
            description: null,
            tileUrl: tileUrlRef.current
        };
        onGroundTruthReportSelected(clickGroundTruthReport);
    };

    const onMarkerMouseOver = (report) => {
        report.tileUrl = tileUrlRef.current;
        report.polygonFeatures = map.data.addGeoJson(report.polygon);
        map.data.setStyle({
            fillColor: 'white',
            fillOpacity: 0.3,
            strokeColor: "white",
            strokeWeight: 1
        });
    };

    const onMarkerMouseOut = (report) => {
        report.polygonFeatures?.forEach(function (feature) {
            map.data.remove(feature);
        });
    };

    const addMarker = (report, markerList, relativeZIndex) => {
        // for right route impact only include markers on a polyline
        if (selectedStormImpact.name === 'RightRoute') {
            let onARoute = false;
            const markerPos = new maps.LatLng(report.location.latitude, report.location.longitude);
            const routesForDay = selectedStormImpact.routes[(selectedStormImpact.routes.length - 1) - selectedForecastsDaysOut];
            routesForDay?.forEach((route) => {
                route.optionsForRoute.forEach((routeOption) => {
                    routeOption.roadRiskPolylines?.forEach((segment) => {
                        const segmentPath = new maps.Polyline({ path: maps.geometry.encoding.decodePath(segment.polyline) });
                        if (maps.geometry.poly.isLocationOnEdge(markerPos, segmentPath, 1e-3)) {
                            onARoute = true;
                        }
                    });
                });
            });
            if (!onARoute) {
                return;
            }
        }

        const template = document.createElement('template');
        template.innerHTML = `<div style="background-color: ${report.markerColor}; width: 26px; height: 26px; display: flex; -webkit-border-radius: 100%; -moz-border-radius: 100%; border-radius: 100%; justify-content: center; align-items: center; border: 1px solid white; transform: translate(0, 13px)"><p style="font-family: 'Material Icons'; font-size: 18px; color: white;">${report.markerIcon}</p></div>`;
        const marker = new maps.marker.AdvancedMarkerElement({
            position: new maps.LatLng(report.location.latitude, report.location.longitude),
            map: map,
            content: template.content.firstChild,
            zIndex: 1006 + relativeZIndex,
        });

        marker.content.addEventListener('mouseenter', () => onMarkerMouseOver(report));
        marker.content.addEventListener('mouseleave', () => onMarkerMouseOut(report));
        marker.addListener('click', (event) => {
            onGroundTruthReportSelected(report);
            event.domEvent.stopPropagation();
        });

        markerList.push(marker);
    };

    // effect to render the ground truth report locations and polygons
    React.useEffect(() => {
        if (!map || !maps) return;

        // Remove all previously-added route markers
        const prevMarkers = prevMarkersRef.current;
        for (const marker of prevMarkers) {
            marker.map = null;
        }

        if (selectedStormEvent === undefined || selectedStormImpact === undefined || showGroundTruthData === false) {
            // if no storm event is available or user has unchecked show groiund truth option don't draw markers or polygons
            return;
        }

        // add report location markers
        let markers = [];
        let relativeZIndex = 0;
        if (selectedStormImpact.name === 'Disruption') {
            selectedStormEvent.impacts.forEach((impact) => {
                impact.groundTruthReports.forEach((report) => {
                    addMarker(report, markers, relativeZIndex);
                    relativeZIndex += 1;
                });
            });
        }
        else {
            selectedStormImpact.groundTruthReports.forEach((report) => {
                addMarker(report, markers, relativeZIndex);
                relativeZIndex += 1;
            });
        }
        setMarkers(markers);

    }, [map, maps, selectedStormEvent, selectedStormImpact, showGroundTruthData, tileUrl]);

    // only pass the center and zoom to gmaps on initial render - cuts down on perf issues
    const defaultCenterRef = React.useRef();
    if (defaultCenterRef.current === undefined) {
        defaultCenterRef.current = props.initialCenter;
    }

    const defaultZoomRef = React.useRef();
    if (defaultZoomRef.current === undefined) {
        defaultZoomRef.current = props.zoomLevel;
    }

    const mapsAPILoaded = (map, maps) => {
        map.overlayMapTypes.push(new maps.StyledMapType(maskStyle, { name: 'roads' }));
        map.overlayMapTypes.push(new maps.StyledMapType(labelsStyle, { name: 'labels' }));

        var styledMapType = new maps.StyledMapType([...darkModeStyle], { name: "Map" });
        map.mapTypes.set('weather_optics', styledMapType);
        map.setMapTypeId('weather_optics');
    };

    return (
        <div className={'historical-accuracy-map'} style={{ height: '100%', width: '100%' }}>
            <GoogleMapReact
                bootstrapURLKeys={bootstrapURLKeys}
                center={defaultCenterRef.current}
                defaultZoom={defaultZoomRef.current}
                options={{
                    mapTypeControl: true,
                    mapTypeControlOptions: { style: 0, position: 7, mapTypeIds: ["satellite", "weather_optics"] },
                    fullscreenControl: false,
                    mapId: 'DEMO_MAP_ID',
                }}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) => {
                    setMap(map);
                    setMaps(maps);
                    mapManagerV2.mapsAPILoaded(map);
                    mapsAPILoaded(map, maps);
                }}
                onClick={(event) => {
                    if (selectedStormImpact.name !== 'RightRoute') {
                        onMapClicked(new maps.LatLng(event.lat, event.lng));
                    }
                }}
            >
                {props.children}
            </GoogleMapReact>
        </div>
    );
};