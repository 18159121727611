import * as React from 'react';
import { trackPageViewDuration } from '../../analytics';

const withTracker = (WrappedComponent, options = {}) => {
    const HOC = class extends React.Component {
        startTime = new Date();

        componentDidMount() {
            this.startTime = new Date();
        }

        UNSAFE_componentWillReceiveProps(nextProps) {
            const currentPage = this.props.location.pathname;
            const nextPage = nextProps.location.pathname;

            if (currentPage !== nextPage) {
                trackPageViewDuration(currentPage, Math.round((new Date().getTime() - this.startTime) / 1000));
                this.startTime = new Date();
            }
        }

        render() {
            return <WrappedComponent {...this.props} />;
        }
    };
    HOC.displayName = 'HOC';

    return HOC;
};

export default withTracker;
