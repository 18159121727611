import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { unregister as unregisterServiceWorker } from './registerServiceWorker';
import { createLogger } from 'redux-logger';
import { Action, applyMiddleware, createStore, StoreEnhancer } from 'redux';
import thunk, { ThunkDispatch } from 'redux-thunk';
import { rootReducer } from './reducers';
import './index.css';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { loadEventsIfNeeded, loadSubhourlyWeatherIfNeeded, loadWeatherIfNeeded } from './actions/Weather';
import HomePage from './containers/IndexPage';
import { Route, Switch } from 'react-router';
import { routerMiddleware } from 'react-router-redux';
import { createBrowserHistory } from 'history';
import { fetchPortalMetadata, fetchUserCities, loadInitialUserData } from './actions/User';
import { StoreState } from './types';
import { loadBlurbsIfNeeded, loadGovernmentalAlertsIfNeeded, loadImpactTilesetsIfNeeded, loadSubhourlyImpactTilesetsIfNeeded, loadSubhourlyWeatherTilesetsIfNeeded, loadWeatherTilesetsIfNeeded } from './actions/Ratings';
import withTracker from './components/shared/with-tracker';
import TimeAgo from 'javascript-time-ago';
import en from "javascript-time-ago/locale/en.json";
import { LicenseInfo } from '@mui/x-license-pro';
import { Config } from './components/shared/useConfig';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { initializeAnalytics, trackEvent } from './analytics';
import { loadPossibleGovernmentalAlertNamesIfNeeded } from './actions/AlertsView';
import { loadRoadConditionsIfNeeded } from './actions/TrafficView';
import { logOutLocally } from './reducers/User';

const ancestorOrigins = window.location.ancestorOrigins;
const isFreightWavesIframe = ancestorOrigins.length > 0 && (process.env.NODE_ENV === 'development' || ancestorOrigins[0].endsWith('//qa.sonar.surf') || ancestorOrigins[0].endsWith('//sonar.surf'));
const disableLogging = isFreightWavesIframe;
if (disableLogging) {
    const consoleMethodsToPatch = [
        'debug',
        'log',
        'warn',
        'error',
        'time',
        'timeEnd',
    ];
    const originalConsoleMethods = Object.fromEntries(consoleMethodsToPatch.map(method => [method, (console as any)[method]]));
    consoleMethodsToPatch.forEach(method => {
        (console as any)[method] = (...args: any[]) => {
            if (disableLogging) return;

            originalConsoleMethods[method](...args);
        };
    });
}

const loggerMiddleware = createLogger();

type MyThunkDispatch = ThunkDispatch<StoreState, void, Action<any>>;

// not sure why we have to type-coerce this in order to dispatch thunk actions to the store -
// might be a gap in my knowledge of react or just a TS idiosyncracy with the multi-middleware apply func
const middlewares = [
    routerMiddleware(createBrowserHistory()),
    thunk,
];

// only log redux state changes when in development and overall logging is not disabled -- or if user forced it
const userForcedStateChangeLogs = Config.getBoolean(Config.Key.AlwaysLogStateChanges);
const shouldLogReduxStates = userForcedStateChangeLogs || (process.env.NODE_ENV !== 'production' && !disableLogging);
if (shouldLogReduxStates) {
    console.log("Logging redux state changes");
    middlewares.unshift(loggerMiddleware);
}

let enhancer: StoreEnhancer<{ dispatch: MyThunkDispatch }> = applyMiddleware(
    ...middlewares
) as StoreEnhancer<{ dispatch: MyThunkDispatch }>;

export const store = createStore(
    rootReducer,
    enhancer
);

if (process.env.REACT_APP_ENV === 'production' && window.location.protocol !== 'https:') {
    window.location.href = 'https:' + window.location.href.substring(window.location.protocol.length);
} else {
    // each load of freightwaves iframe, we want to re-run a login so that users migrate smoothly
    // if they are already logged in and we "upgrade" their FW organization to have custom locations
    if (isFreightWavesIframe) {
        logOutLocally();
    }

    // redirect to dashboard if logged in, the tour has not run and it is not a deep link url
    if (
        localStorage['token']
        && Config.getBoolean(Config.Key.PortalTourComplete) === false
        && window.location.search.length === 0
        && Config.getBoolean(Config.Key.PortalTourHasRedirected) === false
    ) {
        // record that we have redirected to prevent redirecting in a loop
        Config.setBoolean(Config.Key.PortalTourHasRedirected, true);
        const updatedURL = window.location.protocol + '//' + window.location.hostname + ':' + window.location.port + '/';
        window.location.href = updatedURL;
    } else {
        Config.setBoolean(Config.Key.PortalTourHasRedirected, false);
    }

    if (window.location.search.length > 1) {
        window.localStorage.setItem("redirectURL", window.location.pathname + window.location.search);
    }

    initializeAnalytics();

    trackEvent('Portal Opened');

    LicenseInfo.setLicenseKey(
        '62979bedc024be933b5d3945fbd1fc27T1JERVI6NDE2NTQsRVhQSVJZPTE2ODEzMzg3NzEyODksS0VZVkVSU0lPTj0x',
    );

    // set up TimeAgo
    TimeAgo.addLocale(en);

    // load weather initially
    store.dispatch(loadInitialUserData());
    store.dispatch(loadWeatherIfNeeded());
    store.dispatch(loadSubhourlyWeatherIfNeeded());
    store.dispatch(loadEventsIfNeeded());
    store.dispatch(loadRoadConditionsIfNeeded());
    store.dispatch(loadWeatherTilesetsIfNeeded());
    store.dispatch(loadImpactTilesetsIfNeeded());
    store.dispatch(loadWeatherTilesetsIfNeeded());
    store.dispatch(loadSubhourlyImpactTilesetsIfNeeded());
    store.dispatch(loadSubhourlyWeatherTilesetsIfNeeded());
    store.dispatch(loadGovernmentalAlertsIfNeeded());
    store.dispatch(loadBlurbsIfNeeded());
    store.dispatch(loadPossibleGovernmentalAlertNamesIfNeeded());
    setInterval(() => store.dispatch(loadWeatherIfNeeded()), 5 * 60 * 1000); // reload data every 5min
    setInterval(() => store.dispatch(loadSubhourlyWeatherIfNeeded()), 5 * 60 * 1000); // reload data every 5min
    setInterval(() => store.dispatch(loadEventsIfNeeded()), 5 * 60 * 1000); // reload data every 5min
    setInterval(() => store.dispatch(loadRoadConditionsIfNeeded()), 5 * 60 * 1000); // reload data every 5min
    setInterval(() => store.dispatch(loadImpactTilesetsIfNeeded()), 5 * 60 * 1000); // latest run load should cause ratings to reload also - unless it fails
    setInterval(() => store.dispatch(loadWeatherTilesetsIfNeeded()), 5 * 60 * 1000); // latest run load should cause ratings to reload also - unless it fails
    setInterval(() => store.dispatch(loadSubhourlyImpactTilesetsIfNeeded()), 5 * 60 * 1000);
    setInterval(() => store.dispatch(loadSubhourlyWeatherTilesetsIfNeeded()), 5 * 60 * 1000);
    setInterval(() => store.dispatch(loadGovernmentalAlertsIfNeeded()), 5 * 60 * 1000); // reload data every 5min
    setInterval(() => store.dispatch(fetchPortalMetadata()), 5 * 60 * 1000);

    document.addEventListener('visibilitychange', () => {
        // when page is returned from minimization or user switches back to this tab, force a reload of weather/ratings
        if (document.visibilityState === 'visible') {
            store.dispatch(loadWeatherIfNeeded());
            store.dispatch(loadSubhourlyWeatherIfNeeded());
            store.dispatch(loadEventsIfNeeded());
            store.dispatch(loadRoadConditionsIfNeeded());
            store.dispatch(loadImpactTilesetsIfNeeded());
            store.dispatch(loadWeatherTilesetsIfNeeded());
            store.dispatch(loadSubhourlyImpactTilesetsIfNeeded());
            store.dispatch(loadSubhourlyWeatherTilesetsIfNeeded());
            store.dispatch(loadGovernmentalAlertsIfNeeded());
            store.dispatch(loadBlurbsIfNeeded());
            store.dispatch(fetchPortalMetadata());

            const token = store.getState().user?.token;
            const selectedCityId = store.getState().selectedCity?.selectedCity?.id;
            if (token && selectedCityId) {
                store.dispatch(fetchUserCities(token, [selectedCityId]));
            }
        }
    });

    ReactDOM.render(
        <Provider store={store}>
            <BrowserRouter>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <Switch>
                        <Route exact path={"/"} component={HomePage} />
                        <Route exact path={"/environment"} component={() => <div>{process.env.REACT_APP_ENV}</div>} />
                        <Route component={withTracker(App)} />
                    </Switch>
                </LocalizationProvider>
            </BrowserRouter>
        </Provider>,
        document.getElementById('root') as HTMLElement
    );

    unregisterServiceWorker();
}
