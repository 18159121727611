import React from 'react';
import { useD3 } from '../../shared/useD3';
import * as d3 from 'd3';
import './TimelineChart.css';
import {HourRatingsData, RatingsDataWithLocationInfo} from '../../../types';
import { colorForImpactLevel, emojiForImpactLevel, findImpactLevelForHourRating, ImpactLevel } from "../../../types/routes";
import {formatRatingKey, RatingKey} from "../../../types/RatingKey";
import {filterImpactHours, Timeframe, updateTimeframeForTimezone} from "./data";
import moment from 'moment';

interface ImpactWindow {
    ratingKey: RatingKey;
    impactLevel: ImpactLevel;
    from: number;
    to: number;
}

interface RatingImpactWindows {
    disruption: ImpactWindow[];
    road: ImpactWindow[];
    flood: ImpactWindow[];
    power: ImpactWindow[];
    life_property: ImpactWindow[];
}

enum HoursInMilliseconds {
    One = 60 * 60 * 1000,
    Three = 60 * 60 * 1000 * 3,
    Twelve = 60 * 60 * 1000 * 12
}

function createImpactWindows(ratings: HourRatingsData[], ratingKey: RatingKey): ImpactWindow[] {
    if(ratings.length === 0){
        return [];
    }
    let impactWindows: ImpactWindow[] = [];
    let windowStack: ImpactWindow[] = [];

    let lastImpactLevel: ImpactLevel | undefined = ImpactLevel.None;
    for (const hour of ratings) {
        const impactLevel = findImpactLevelForHourRating(hour);
        // impact level changes signifying the start/end of impact window(s)
        if(impactLevel !== lastImpactLevel) {
            // while there are higher impact level windows in the stack remove them, add the end window time and add them to the impact window array
            while(windowStack.length > 0 && windowStack[windowStack.length-1].impactLevel > impactLevel) {
                let completeWindow: ImpactWindow | undefined = windowStack.pop();
                completeWindow!.to = hour!.time.getTime(),
                impactWindows.push(completeWindow!);
            }
            // if the new impact level is higher than the last impact level start new impact window(s) for each level above the last impact level
            if(impactLevel > lastImpactLevel) {
                for (let il = lastImpactLevel+1; il <= impactLevel; il++){
                    windowStack.push({
                        ratingKey: ratingKey,
                        impactLevel: il,
                        from: hour.time.getTime(),
                        to: -1
                    });
                }
            }
        }
        lastImpactLevel = impactLevel;
    }

    // pop any remaining impact windows off the stack and make the end time the rating range end time
    while(windowStack.length > 0) {
        let completeWindow: ImpactWindow | undefined = windowStack.pop();
        completeWindow!.to = ratings[ratings.length - 1]!.time.getTime() + HoursInMilliseconds.One, // add an hour to the of the time range so the last hour is displayed on the chart
        impactWindows.push(completeWindow!);
    }

    // finally, add an impact window for impact level "None" that covers the entire range (the default)
    impactWindows.push({
        ratingKey: ratingKey,
        impactLevel: ImpactLevel.None,
        from: ratings[0].time.getTime(),
        to: ratings[ratings.length - 1]!.time.getTime() + HoursInMilliseconds.One,// add an hour to the of the time range so the last hour is displayed on the chart
    });
    
    return impactWindows;
}

interface Props {
    id: string;
    ratingsData: RatingsDataWithLocationInfo;
    timeframe: Timeframe;
    impactSummariesUpdatedAt: Date | undefined;
    height: number;
}

function TimelineChart(props: Props) {
    const { ratingsData, timeframe, impactSummariesUpdatedAt, height } = props;
    
    const ratingKeys = ["life_property", "power", "flood", "road", "disruption"];
    const containerIdRef = React.useRef(`timeline-chart-${props.id}`);
    const containerId = containerIdRef.current;

    // ref/effect to recompute width of graph as needed
    const containerDivRef = React.useRef<HTMLDivElement>(null);
    const [divWidth, setDivWidth] = React.useState(0);
    React.useEffect(() => {
        function handleResize() {
            if (containerDivRef.current && containerDivRef.current.clientWidth !== divWidth) {
                setDivWidth(containerDivRef.current.clientWidth);
            }
        }

        window.addEventListener('resize', () => handleResize());
    });
    
    // update timeframe for local timezone and shift it back one day if the last updated time is before the today start date
    const timezoneTimeframe = updateTimeframeForTimezone(timeframe, ratingsData.timezone as string, impactSummariesUpdatedAt);
    
    const impactWindowsDatasets: RatingImpactWindows = {
        disruption: createImpactWindows(filterImpactHours(ratingsData.disruption, timezoneTimeframe), 'disruption'),
        road: createImpactWindows(filterImpactHours(ratingsData.road, timezoneTimeframe), 'road'),
        flood: createImpactWindows(filterImpactHours(ratingsData.flood, timezoneTimeframe), 'flood'),
        power: createImpactWindows(filterImpactHours(ratingsData.power, timezoneTimeframe), 'power'),
        life_property: createImpactWindows(filterImpactHours(ratingsData.life_property, timezoneTimeframe), 'life_property')
    };

    const allImpactWindows: ImpactWindow[] = [...impactWindowsDatasets.disruption, ...impactWindowsDatasets.road, ...impactWindowsDatasets.flood, ...impactWindowsDatasets.power, ...impactWindowsDatasets.life_property]
        .sort((d1, d2) => d1.impactLevel > d2.impactLevel ? 1 : -1);

    const d3SvgRef = useD3(
        (svg) => {
            if (!containerDivRef.current) return;
            if (allImpactWindows.length === 0) return;

            // clear chart if resizing
            svg.selectAll("*").remove();

            // setup new chart
            const chartWidth = containerDivRef.current.clientWidth;
            const chartHeight = containerDivRef.current.clientHeight;
            const margin = { left: 155, top: 5, bottom: 40, right: 45};
            // size of chart inside the axises
            const innerWidth = chartWidth - margin.left - margin.right;
            const innerHeight = chartHeight - margin.top - margin.bottom;

            svg.attr("width", chartWidth)
                .attr("height", chartHeight);
            
            const impactChart = svg.append("g")
                .attr("transform", "translate(" + margin.left + "," + margin.top + ")")
                .attr("width", innerWidth)
                .attr("height", innerHeight);

            const minTime = allImpactWindows.reduce(function(res, obj) {
                return (obj.from < res.from) ? obj : res;
            }).from;
            const maxTime = allImpactWindows.reduce(function(res, obj) {
                return (obj.to > res.to) ? obj : res;
            }).to;

            const xScale = d3.scaleTime()
                .domain([new Date(minTime), new Date(maxTime)])
                .range([0, innerWidth]);
            const yScale = d3.scaleBand()
                .domain(ratingKeys)
                .range([innerHeight, 0])
                .align(0.5)
                .padding(0.3);
            
            const xAxis = d3.axisBottom(xScale)
                // had to explicitly set tick values to get the axis labels to display properly (add 1 millisecond to max time to get last tick label to be added)
                .tickValues(d3.range(minTime, maxTime + 1, timeframe.label === 'day3_7' ? 
                                     HoursInMilliseconds.Twelve : timeframe.label === 'tomorrow' ? 
                                     HoursInMilliseconds.Three : HoursInMilliseconds.One).map(function(d){ return new Date(d); }))
                .tickFormat(function(d: Date, i: number) { 
                    let timeFormat = "h a"; // default to (non zero padded) hour AM/PM
                    if(timeframe.label === 'tomorrow' && i === 0) timeFormat = "ddd h a"; // show day for first tick
                    if(timeframe.label === 'day3_7' && i % 2 === 0) timeFormat = "ddd h a"; // show day for even ticks
                    return ratingsData.timezone === undefined ? moment(d).format(timeFormat) : moment(d).tz(ratingsData.timezone).format(timeFormat);
                });
            const yTickLabels = ["Life & Property", "Power", "Flood", "Road", "Disruption"];
            const yAxis = d3.axisLeft(yScale)
                .tickSize(0) //remove y axis tick marks
                .tickFormat((d,i) => yTickLabels[i]);
            
            impactChart.append("g")
                .attr("transform", `translate(0, ${height - margin.bottom})`)
                .attr("fill", "white")
                .call(xAxis);                

            impactChart.append("g")
                .attr("fill", "white")
                .attr("transform", `translate(-100,0)`)
                .call(yAxis)
                .call(g => g.selectAll(".tick text")
                .attr("text-anchor", "start"))
                .call(g => g.selectAll(".tick text")
                .attr("font-size", "14"))
                .call(g => g.select(".domain").remove()); //remove y axis line
            
            // add impact windows to chart
            impactChart.selectAll("windows")
                .data(allImpactWindows)
                .enter().append("rect")
                .attr("className", "window")
                .attr("fill", (d: ImpactWindow) => colorForImpactLevel(d.impactLevel))
                .attr("rx", 5) // rounded corners
                .attr("ry", 5) // rounded corners
                .attr("x", (d: ImpactWindow) => xScale(d.from))
                .attr("y", (d: ImpactWindow) => yScale(d.ratingKey) as number)
                .attr("height", yScale.bandwidth())
                .attr("width", (d: ImpactWindow) => xScale(d.to) - xScale(d.from));
            
            let tooltip = d3.select("#" + containerId).select('div.timeline-tooltip');
            if (tooltip.empty()) tooltip = d3.select('#' + containerId).append('div');

            tooltip.attr("class", "timeline-tooltip")
                .style("opacity", 1.0)
                .style("visibility", "hidden")
                .style("z-index", 10005);

            const hoverLineGroup = impactChart.append("g")
                .style("visibility", "hidden")
                .style("z-index", 10005);

            hoverLineGroup.append("line")
                .attr("y1", 0)
                .attr("y2", innerHeight)
                .style("stroke", "white")
                .style("stroke-width", "2px")
                .style("opacity", 1.0);
            
            hoverLineGroup.append("path")
                .attr("d", d3.symbol().type(d3.symbolTriangle).size(50))
                .attr("fill", "white")
                .attr("transform", "rotate(180)");

            // Mouse events
            const mouseG = impactChart.append("g")
                .attr("class", "mouse-over-effects");

            mouseG.append("rect") // append a rect over the inner part of the chart to catch mouse events
                .attr("width", innerWidth)
                .attr("height", innerHeight)
                .attr("fill", "none")
                .attr("pointer-events", "all")
                .on('mouseout', () => {
                    tooltip.transition();
                    tooltip.style("visibility", "hidden");

                    hoverLineGroup.transition();
                    hoverLineGroup.style("visibility", "hidden");
                })                
                .on('mouseover', () => {
                    tooltip.transition();
                    tooltip.style("visibility", "visible");

                    hoverLineGroup.transition();
                    hoverLineGroup.style("visibility", "visible");
                })
                .on('mousemove', (event) => {
                    const coords = d3.pointer(event);
                    let timeHoveredSeconds = xScale.invert(coords[0]).getTime();
                    timeHoveredSeconds = timeHoveredSeconds < minTime ? minTime : timeHoveredSeconds; // round up to the minTime
                    timeHoveredSeconds = timeHoveredSeconds >= maxTime ? maxTime - 1 : timeHoveredSeconds; // round down to the maxTime (subtract one to account for adding one in tickValues above)
                    
                    // Tooltip
                    
                    // determine which rating is being hovered over
                    let index = Math.floor((coords[1] / (innerHeight / ratingKeys.length)));
                    index = index < 0 ? 0 : index; // round to the top rating 
                    index = index > (ratingKeys.length-1) ? ratingKeys.length-1 : index; // round to the bottom rating 
                    const ratingKey = yScale.domain().reverse()[index];

                    const dateHovered = new Date(timeHoveredSeconds);
                    const hour: HourRatingsData = ratingsData[ratingKey].filter((hour: HourRatingsData) => hour.time <= dateHovered).pop()!;
                    const datetimeString = hour.time.toLocaleDateString('en-US', {weekday: 'short', month: 'short', day: 'numeric', timeZone: ratingsData.timezone})
                        + ', '
                        + hour.time.toLocaleTimeString('en-US', {hour: 'numeric', hour12: true, timeZoneName: 'short', timeZone: ratingsData.timezone });
                    // Date
                    let tooltipContent = `<div key="${ratingKey}_time" class="time">${datetimeString}</div>`;
                    // Rating and Impact score
                    tooltipContent += `<div key="${ratingKey}_impact_score" class="impact">${emojiForImpactLevel(findImpactLevelForHourRating(hour))}  ${formatRatingKey(ratingKey)}: ${hour.value.toFixed(1)}/10</div>`;
                    
                    
                    // place tooltip at the top of chart above the hover line
                    const yOffset: number = parseInt(tooltip.style("height")) + coords[1] + 25;
                    const xOffset: number = parseInt(tooltip.style("width")) / 2;
                    tooltip.html(tooltipContent)
                        .style("top", (event.clientY) - yOffset + "px")
                        .style("left", (event.clientX) - xOffset + "px");

                    hoverLineGroup!.attr('transform', `translate(${coords[0]},0)`);                    
                });
        },
        [ratingsData, timeframe, containerDivRef.current, divWidth]
    );

    return (
        <div style={{display: 'flex', flexDirection: 'row'}}>
            <div ref={containerDivRef} id={containerId} style={{padding: 8, marginBottom: -4, flexGrow: 1}}>
                <svg
                    ref={d3SvgRef}
                    style={{
                        height: height - 15,
                        width: "100%",
                        marginRight: "0px",
                        marginLeft: "0px",
                        borderRadius: 3
                    }}
                >
                </svg>
            </div>
        </div>
    );
}

export default TimelineChart;
