import { uniq } from "lodash";
import { categoryToColorMapping, getCategoryForReportType } from "src/types";

export const stormReportClusterSvg = (count, markers) => {
  let markerCount = count;

  const reportTypes = markers.map(marker => marker.metadata.reportType);
  const categories = reportTypes.map(reportType => getCategoryForReportType(reportType));
  const sortedCategorySet = uniq(categories).sort((a, b) => a.localeCompare(b));

  const colorsWithCounts = sortedCategorySet.map(category => {
    return {
      color: categoryToColorMapping[category] ?? '#FF00FF',
      count: categories.filter(c => c === category).length,
    };
  });

  let start = 0;
  const colorsWithOffsets = colorsWithCounts.map(colorWithCount => {
    const { color, count } = colorWithCount;
    const end = start + count / markerCount;
    const result = { color, start, end };
    start = end;
    return result;
  });

  const svg = generateClusterSVG(colorsWithOffsets);

  // create cluster SVG element
  const parser = new DOMParser();
  const svgEl = parser.parseFromString(
    svg,
    "image/svg+xml"
  ).documentElement;

  svgEl.setAttribute("transform", "translate(0 25)");

  return svgEl;
};

function generateClusterSVG(colorsWithOffsets) {
  const svgns = "http://www.w3.org/2000/svg"; // SVG namespace

  const createArcs = (centerX, centerY, radius, segments) => {
    let startAngle = 0;

    return segments.map(segment => {
      let fraction = segment.end - segment.start;
      // Calculate end angle
      fraction = fraction >= 1 ? 0.999 : fraction;
      const endAngle = startAngle + fraction * 2 * Math.PI;

      // Convert angles to Cartesian coordinates
      const startX = centerX + radius * Math.cos(startAngle - Math.PI / 2);
      const startY = centerY + radius * Math.sin(startAngle - Math.PI / 2);
      const endX = centerX + radius * Math.cos(endAngle - Math.PI / 2);
      const endY = centerY + radius * Math.sin(endAngle - Math.PI / 2);

      // Large arc flag
      const largeArcFlag = (fraction > 0.5) ? 1 : 0;

      // Create the path
      const path = document.createElementNS(svgns, 'path');
      const d = [
        `M ${centerX} ${centerY}`, // Move to center
        `L ${startX} ${startY}`, // Line to start
        `A ${radius} ${radius} 0 ${largeArcFlag} 1 ${endX} ${endY}`, // Arc to end
        `Z` // Close path
      ].join(' ');

      path.setAttribute('d', d);
      path.setAttribute('fill', segment.color);
      // svg.appendChild(path);

      // Update start angle to the end angle of the current segment
      startAngle = endAngle;

      return path.outerHTML;
    }).join('');
  };

  const width = 28;
  const strokeWidth = 3;
  const halfWidth = width / 2;

  return `
    <svg width="${width}" height="${width}" viewBox="0 0 ${width} ${width}" fill="none" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <mask id="triangleMask1">
          <circle cx="${halfWidth}" cy="${halfWidth}" r="${halfWidth}" fill="white"/>
        </mask>
      </defs>
      <g mask="url(#triangleMask1)">
        ${createArcs(halfWidth, halfWidth, halfWidth, colorsWithOffsets)}
      </g>

      <circle cx="${halfWidth}" cy="${halfWidth}" r="${halfWidth - strokeWidth}" fill="#333"/>

      <!-- this house icon path is based on 32x32 -->
      <g transform="translate(-2 -2.5)">
        <path 
          d="M15.4999 12.6944L10.5679 17.212C10.5313 17.2462 10.4846 17.2621 10.4444 17.2905V21.625C10.4444 21.8322 10.606 22 10.8055 22H14.1735L13.3333 20.2935L15.683 18.7935L14.3252 15.9993L17.6666 19.2051L15.3169 20.7051L16.2179 22H20.1944C20.3939 22 20.5555 21.8322 20.5555 21.625V17.2914C20.5171 17.2642 20.4715 17.2488 20.4368 17.2164L15.4999 12.6944ZM21.8801 15.5378L20.5555 14.323V11.125C20.5555 10.9178 20.3939 10.75 20.1944 10.75H18.7499C18.5504 10.75 18.3888 10.9178 18.3888 11.125V12.3365L16.1037 10.2416C15.9315 10.0809 15.7157 10.0002 15.4999 10C15.2842 9.99977 15.0689 10.0799 14.8973 10.2407L9.1198 15.5378C8.97152 15.6763 8.95911 15.9133 9.09249 16.0675L9.57548 16.6258C9.70864 16.7798 9.93704 16.7927 10.0855 16.6541L15.2612 11.9132C15.3977 11.7883 15.6024 11.7883 15.739 11.9132L20.9146 16.6539C21.0628 16.7922 21.2912 16.7795 21.4246 16.6255L21.9076 16.0673C22.0408 15.9133 22.0286 15.6761 21.8801 15.5378Z" 
          fill="white"
        />
      </g>
    </svg>
  `;
}