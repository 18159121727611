import * as React from 'react';
import { LocationData, UserState, BasicLatLng, WeatherData, SelectedCityState, ImpactTileset, WeatherTilesetsByIndex } from '../../../types';
import './index.css';
import { MapControlsComponent } from '../../shared/MapControlsComponent';
import { RouteComponentProps } from 'react-router-dom';
import { LocationSelectionComponent } from '../../shared/LocationSelectionComponent';
import { AlertCalloutView } from '../Alerts/AlertCalloutView';
import { cardBackgroundColor } from "../../../constants";
import PortalTour from '../PortalTour';
import { Config } from 'src/components/shared/useConfig';
import { MaskType } from '../../../types/MapType';
import { RouteAndMarkerMap, defaultRouteAndMarkerMapProps } from '../../shared/RouteAndMarkerMap';
import { TilesetUtils } from '../Impact';

interface Props extends RouteComponentProps {
    userData?: UserState;
    weatherData?: WeatherData;
    speedIncrements: number[];
    baseAnimationDelay: number;
    selectedCityState: SelectedCityState;

    center?: BasicLatLng;
    zoomLevel: number;

    tileOpacity: number;
    selectedSpeedMultiplierIndex: number;
    animationDelay: number;
    paused: boolean;

    weatherTilesets?: WeatherTilesetsByIndex;

    onCenterChanged: (center: BasicLatLng) => void;
    onZoomLevelChanged: (zoomLevel: number) => void;
    onPauseToggled: (paused: boolean) => void;
    onAnimationSpeedChanged: (selectedSpeedMultiplierIndex: number, animationDelay: number) => void;

    // callback for when a city is selected/created from the geocoder
    onCitySelected: (city: LocationData | undefined) => void;
    onCitySaved: (city: LocationData) => void;
    onCityUnsaved: (city: LocationData) => void;
    onCitySaveErrorDismissed: (city: LocationData) => void;
}

class ClientRadarPageInner extends React.Component {
    props: Props;

    state: {
        runTour: boolean;

        visibleTileset?: ImpactTileset;
        desiredTileset?: ImpactTileset;
    };

    timer?: NodeJS.Timeout;

    constructor(props: Props) {
        super(props);

        this.state = {
            runTour: false,
            visibleTileset: undefined,
            desiredTileset: undefined,
        };
    }

    componentDidMount(): void {
        super.componentDidMount?.();

        this.setState({
            ...this.state,
            runTour: Config.getBoolean(Config.Key.PortalTourComplete) === false
        });
    }

    componentWillUnmount(): void {
        super.componentWillUnmount?.();
        clearTimeout(this.timer);
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<any>, snapshot?: any): void {
        super.componentDidUpdate?.(prevProps, prevState, snapshot);

        const tilesets = this.getTilesets();
        if (this.state.desiredTileset === undefined && tilesets[0] !== undefined) {
            this.setState({
                ...this.state,
                desiredTileset: tilesets[0],
            });
        } else if (this.state.desiredTileset && tilesets.findIndex(x => x.id === this.state.desiredTileset!.id) === -1) {
            console.log('setting new desired tileset because the currently desired one is no longer in the tileset list', tilesets, this.state.desiredTileset);
            const matchingDesiredTileset = tilesets.find(x => x.time.getTime() === this.state.desiredTileset?.time.getTime());
            this.setState({
                ...this.state,
                desiredTileset: matchingDesiredTileset ?? tilesets[0],
            });
        }
    }

    getTilesets() {
        return this.props.weatherTilesets?.radar ?? [];
    }

    goToPreviousTimeOffset() {
        if (!this.state.desiredTileset) {
            return;
        }

        this.setState({
            ...this.state,
            desiredTileset: TilesetUtils.getPreviousTileset(this.getTilesets(), this.state.desiredTileset.time),
        });
    }

    // increments our time offset, kicking off a new map load.
    goToNextTimeOffset() {
        if (!this.state.desiredTileset) {
            return;
        }

        this.setState({
            ...this.state,
            desiredTileset: TilesetUtils.getNextTileset(this.getTilesets(), this.state.desiredTileset.time),
        });
    }

    goToDate(date: Date) {
        this.pause();
        this.setState({
            ...this.state,
            desiredTileset: TilesetUtils.getTileset(this.getTilesets(), date),
        });
    }

    resume() {
        clearTimeout(this.timer);

        this.timer = setTimeout(() => this.goToNextTimeOffset(), this.props.animationDelay);

        this.props.onPauseToggled(false);
    }

    rewind() {
        this.pause();
        this.goToPreviousTimeOffset();
    }

    skipToFirst() {
        this.pause();
        this.setState({
            ...this.state,
            desiredTileset: this.getTilesets()[0],
        });
    }

    skipToLast() {
        this.pause();
        const tilesets = this.getTilesets();
        this.setState({
            ...this.state,
            desiredTileset: tilesets[tilesets.length - 1],
        });
    }

    fastForward() {
        this.pause();
        this.goToNextTimeOffset();
    }

    pause() {
        clearTimeout(this.timer);
        this.props.onPauseToggled(true);
    }

    cycleSpeedIncrement() {
        let speedMultiplierIndex = (this.props.selectedSpeedMultiplierIndex + 1) % this.props.speedIncrements.length;
        let speedMultiplier = this.props.speedIncrements[speedMultiplierIndex];
        let animationDelay = this.props.baseAnimationDelay / speedMultiplier;

        this.props.onAnimationSpeedChanged(speedMultiplierIndex, animationDelay);
    }

    calloutViewCoordinateSelected(coord: google.maps.LatLng) {
        let city: LocationData = {
            id: undefined,
            zip: '00000',
            name: '',
            latitude: coord.lat(),
            longitude: coord.lng(),
            needsGeocoding: true,
            impactSummaries: []
        };

        this.props.onCitySelected(city);
    }

    render() {
        const { userData, selectedCityState } = this.props;
        const { selectedCity } = selectedCityState;

        if (userData?.portalToken === undefined) {
            return <div />;
        }

        const radarLegends = [{
            label: 'Rain', image: 'Radar_TWC_Rain'
        }, {
            label: 'Snow', image: 'Radar_TWC_Snow'
        }];
        const radarLegendElements: JSX.Element | JSX.Element[][] = radarLegends.map(legend => {
            return [
                (<div className={'legend-label'}>{legend.label}</div>),
                (<img style={{ "width": "100%", maxWidth: '450px' }} key={legend.label} alt={legend.label} src={`/legends/${legend.image}.svg`} />)
            ];
        });

        let mapControls: JSX.Element | undefined = undefined;
        const tilesets = this.getTilesets();
        if (tilesets.length > 0) {
            const visibleTileset = this.state.visibleTileset;
            const desiredTileset = this.state.desiredTileset;
            const speedIncrement = this.props.speedIncrements[this.props.selectedSpeedMultiplierIndex];
            mapControls = (
                <MapControlsComponent
                    paused={this.props.paused}
                    speedIncrement={speedIncrement}
                    times={tilesets.map(x => x.time)}
                    displayedTime={desiredTileset?.time}
                    loading={visibleTileset?.id !== desiredTileset?.id}

                    onPause={() => this.pause()}
                    onResume={() => this.resume()}
                    onFastForward={() => this.fastForward()}
                    onRewind={() => this.rewind()}
                    onSkipToFirst={() => this.skipToFirst()}
                    onSkipToLast={() => this.skipToLast()}
                    onCycleSpeedIncrement={() => this.cycleSpeedIncrement()}
                    setSelectedDate={(date: Date) => this.goToDate(date)}
                />
            );
        }

        let mapElement = (
            <RouteAndMarkerMap
                {...defaultRouteAndMarkerMapProps}

                portalToken={userData.portalToken}

                initialCenter={this.props.center}
                // initially show the whole conus
                initialBounds={{ sw: { lat: 24.396308, lng: -125.0 }, ne: { lat: 49.384358, lng: -66.93457 } }}
                zoomLevel={this.props.zoomLevel}
                onCenterChanged={(center) => this.props.onCenterChanged(center)}
                onZoomLevelChanged={(zoomLevel: number) => this.props.onZoomLevelChanged(zoomLevel)}
                onMapClicked={(coord) => this.calloutViewCoordinateSelected(coord)}

                maskType={MaskType.Labels}

                tileOpacity={this.props.tileOpacity}
                desiredTileLayer={this.state.desiredTileset}
                onTileLayerLoaded={(visibleTileset) => {
                    this.setState({
                        ...this.state,
                        visibleTileset,
                    });

                    if (!this.props.paused && visibleTileset?.id === this.state.desiredTileset?.id) {
                        clearTimeout(this.timer);
                        this.timer = setTimeout(() => this.goToNextTimeOffset(), this.props.animationDelay);
                    }
                }}

                selectedCity={selectedCity}
            >
                {selectedCity && <AlertCalloutView
                    selectedCityState={selectedCityState}
                    lat={selectedCity.latitude}
                    lng={selectedCity.longitude}

                    onClose={() => this.props.onCitySelected(undefined)}
                    saveLocationTapped={(city) => this.props.onCitySaved(city)}
                    unsaveLocationTapped={(city) => this.props.onCityUnsaved(city)}
                    onCitySaveErrorDismissed={(city) => this.props.onCitySaveErrorDismissed(city)}
                />}
            </RouteAndMarkerMap>
        );

        return (
            <div className={'ClientRadarPage'}>
                <div className={'radar-top-left'}>
                    <div
                        className={'radar-legend'}
                        style={{
                            backgroundColor: cardBackgroundColor,
                            display: 'flex',
                            flexDirection: 'column',
                        }}>
                        {radarLegendElements}
                    </div>
                </div>
                <div className={'LocationSelectionComponent-Container'}>
                    <LocationSelectionComponent
                        user={userData}
                        savedCities={userData.cities}
                        selectedCity={selectedCity}
                        onCitySelected={city => this.props.onCitySelected(city)}
                        allowSelectingLocations
                    />
                </div>

                <div className={'maps'}>
                    {mapElement}
                    {mapControls}
                </div>
                <PortalTour run={this.state.runTour!} portalTab="RADAR" />
            </div>
        );
    }
}

export const ClientRadarPage = (props: Props) => {
    return <ClientRadarPageInner {...props} />;
};
