import { EarthquakeData, FireData, getEventDescription, LightningData, PowerOutageData, RoadClosureData, RoadStatusData, RoadWorkData, SpecialEventData, StormReportData, TrafficCongestionData, TrafficIncidentData, TruckWarningData, VolcanoData, WeatherStationData } from '../../../types';
import * as React from 'react';
import { cardBackgroundColor } from "../../../constants";

interface EventCalloutViewProps {
    lat: number;
    lng: number;
    portalTab?: string;

    selectedEarthquake?: EarthquakeData;
    selectedLightning?: LightningData;
    selectedPowerOutage?: PowerOutageData;
    selectedStormReport?: StormReportData;
    selectedVolcano?: VolcanoData;
    selectedFire?: FireData;
    selectedDrivingCondition?: RoadStatusData;
    selectedRoadWork?: RoadWorkData;
    selectedRoadClosure?: RoadClosureData;
    selectedSpecialEvent?: SpecialEventData;
    selectedTrafficCongestion?: TrafficCongestionData;
    selectedTrafficIncident?: TrafficIncidentData;
    selectedTruckWarning?: TruckWarningData;
    selectedWeatherStation?: WeatherStationData;

    isEarthquakesVisible: boolean;
    isLightningVisible: boolean;
    isPowerOutagesVisible: boolean;
    isStormReportsVisible: boolean;
    isVolcanoesVisible: boolean;
    isFiresVisible: boolean;
    isDrivingConditionsVisible: boolean;
    isRoadWorkVisible: boolean;
    isRoadClosuresVisible: boolean;
    isTrafficCongestionVisible: boolean;
    isTrafficIncidentsVisible: boolean;
    isTruckWarningsVisible: boolean;

    onClearSelectedEvent: () => void;
    onClearSelectedRoadCondition: () => void;
}

export const EventCalloutView = (props: EventCalloutViewProps) => {
    let eventName = undefined;
    let eventDescription = undefined;

    if (props.isEarthquakesVisible && props.selectedEarthquake) {
        const earthquake: EarthquakeData = props.selectedEarthquake;
        eventName = 'Earthquake';
        eventDescription = `<div>${getEventDescription(earthquake, 'earthquake')}</div>`;
    }

    if (props.isLightningVisible && props.selectedLightning) {
        const lightning: LightningData = props.selectedLightning;
        eventName = 'Lightning';
        eventDescription = `<div>${getEventDescription(lightning, 'lightning')}</div>`;
    }

    if (props.isPowerOutagesVisible && props.selectedPowerOutage) {
        const powerOutage: PowerOutageData = props.selectedPowerOutage;
        eventName = 'Power Outage';
        eventDescription = `<div>${getEventDescription(powerOutage, 'powerOutage')}</div>`;
    }

    if (props.isStormReportsVisible && props.selectedStormReport) {
        const stormReport: StormReportData = props.selectedStormReport;
        eventName = stormReport.reportType;
        if (stormReport.magnitude && stormReport.units) {
            if (stormReport.reportType.toLowerCase() === 'tornado') {
                eventName += ` (${stormReport.units} ${stormReport.magnitude})`;
            } else {
                eventName += ` (${stormReport.magnitude} ${stormReport.units})`;
            }
        }
        eventDescription = `<div>${getEventDescription(stormReport, 'stormReport')}</div>`;
    }

    if (props.isVolcanoesVisible && props.selectedVolcano) {
        const volcano: VolcanoData = props.selectedVolcano;
        eventName = volcano.name;
        eventDescription = `<div>${getEventDescription(volcano, 'volcano')}</div>`;
    }

    if (props.isFiresVisible && props.selectedFire) {
        const f: FireData = props.selectedFire;
        eventName = "Fire";
        eventDescription = `<div>${getEventDescription(f, 'fire')}</div>`;
    }

    if (props.isDrivingConditionsVisible && props.selectedDrivingCondition) {
        const dc: RoadStatusData = props.selectedDrivingCondition;
        eventName = "Driving Condition";
        eventDescription = `<div>${getEventDescription(dc, 'roadStatus')}</div>`;
    }

    if (props.isPowerOutagesVisible && props.selectedPowerOutage) {
        const po: PowerOutageData = props.selectedPowerOutage;
        eventName = "Power Outage";
        eventDescription = `<div>${getEventDescription(po, 'powerOutage')}</div>`;
    }

    if (props.isDrivingConditionsVisible && props.selectedWeatherStation) {
        const ws: WeatherStationData = props.selectedWeatherStation;
        eventName = "Weather Station";
        eventDescription = `<div>${getEventDescription(ws, 'weatherStation')}</div>`;
    }

    if (props.isTrafficCongestionVisible && props.selectedTrafficCongestion) {
        const tc: TrafficCongestionData = props.selectedTrafficCongestion;
        eventName = "Traffic Flow";
        eventDescription = `<div>${getEventDescription(tc, 'trafficCongestion')}</div>`;
    }

    if (props.isTrafficIncidentsVisible && props.selectedTrafficIncident) {
        const ti: TrafficIncidentData = props.selectedTrafficIncident;
        eventName = "Traffic Incident";
        eventDescription = `<div>${getEventDescription(ti, 'trafficIncident')}</div>`;
    }

    if (props.isRoadWorkVisible && props.selectedRoadWork) {
        const rw: RoadWorkData = props.selectedRoadWork;
        eventName = "Road Work";
        eventDescription = `<div>${getEventDescription(rw, 'roadWork')}</div>`;
    }

    if (props.isRoadClosuresVisible && props.selectedRoadClosure) {
        const rc: RoadClosureData = props.selectedRoadClosure;
        eventName = "Road Closure";
        eventDescription = `<div>${getEventDescription(rc, 'roadClosure')}</div>`;
    }

    if (props.isTrafficCongestionVisible && props.selectedSpecialEvent) {
        const se: SpecialEventData = props.selectedSpecialEvent;
        eventName = "Event";
        eventDescription = `<div>${getEventDescription(se, 'specialEvent')}</div>`;
    }

    if (props.isTruckWarningsVisible && props.selectedTruckWarning) {
        const tw: TruckWarningData = props.selectedTruckWarning;
        eventName = "Truck Warning";
        eventDescription = `<div>${getEventDescription(tw, 'truckWarning')}</div>`;
    }

    return eventDescription ? (
        <div className={`MapCalloutView`} onClick={(event) => event.stopPropagation()}>
            <div className={"container"} style={{ cursor: "default" }}>
                <div className={props.portalTab === 'nowcast' ? "" : "layer-description-text"} style={{ backgroundColor: cardBackgroundColor }}>
                    {/* there is a small gap that can be seen between the arrow and the box that the marginBottom helps cover */}
                    <div style={{ marginBottom: '-1px' }}>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div className={"event-name"}>{eventName}</div>
                            <div style={{ flex: '1 1 0' }} />
                            <button className={"close-button"} onClick={() => {
                                props.onClearSelectedEvent && props.onClearSelectedEvent();
                                props.onClearSelectedRoadCondition && props.onClearSelectedRoadCondition();
                            }}>
                                <img alt={'Close'} src={"/images/popover_x.svg"} />
                            </button>
                        </div>
                        <div className={"event-description"} dangerouslySetInnerHTML={{ __html: eventDescription }} />
                    </div>
                </div>
                <div className={"arrow-down"} style={{ borderTop: `20px solid ${cardBackgroundColor}` }} />
            </div>
        </div>
    ) : <div />;
};
