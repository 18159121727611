import * as React from 'react';
import { ALERTS_HOST, cardBackgroundColor } from "../../../constants";
import { AlertType } from '../../../constants/Alerts';
import { Bounds } from 'google-map-react';
import { useThrottledValue } from '../../shared/useThrottledValue';
import { LoadableResult } from '../../../types';
import { Config } from '../../shared/useConfig';


interface Props {
    portalToken: string;
    mapBounds?: Bounds;
    className?: string;
    children?: JSX.Element;
}

export const AlertsLegend = (props: Props) => {
    const { portalToken, mapBounds } = props;

    const [alertTypesResult, setAlertTypesResult] = React.useState<LoadableResult<AlertType[]>>(new LoadableResult());
    const mapBoundsThrottled = useThrottledValue({ value: mapBounds, throttleMs: 500 });

    React.useEffect(() => {
        if (!mapBounds) {
            return;
        }

        const mapBoundsStr = [
            mapBounds.ne.lat,
            mapBounds.ne.lng,
            mapBounds.sw.lat,
            mapBounds.sw.lng,
        ].join(",");
        const boundsParams = `&bounds=${mapBoundsStr}`;

        alertTypesResult.abort();
        alertTypesResult.setLoading();
        setAlertTypesResult(alertTypesResult.copy());

        const showGlobalAlerts = Config.getBoolean(Config.Key.ShowGlobalAlerts);
        const countriesParam = showGlobalAlerts ? '&countries=all' : '';

        fetch(`${ALERTS_HOST}/weather/current?token=${portalToken}&omit_geometries=true${boundsParams}${countriesParam}`, {
            signal: alertTypesResult.abortController.signal,
        })
            .then(response => {
                return response.json();
            })
            .then(json => {
                let alertNames = new Set();
                let alertTypes: AlertType[] = [];
                // alert are sorted by priority (low to high) so that higher priority alerts are drawn last (on top or lower priority alerts)
                // reversing the alert types list stored in state so that the higher priority alerts are at the top in the legend
                // we reverse when collecting the colors because for global alerts, sometimes the same event will have different priorities
                // and we want to show the color for the highest priority one
                json['features']?.reverse().forEach((feature: any) => {
                    const fillColor = feature['properties']['fill'];
                    const event = feature['properties']['event'];
                    if (!alertNames.has(event)) {
                        alertNames.add(event);
                        alertTypes.push({ name: event, color: fillColor });
                    }
                });
                alertTypesResult.setSuccess(alertTypes);
                setAlertTypesResult(alertTypesResult.copy());
            }).catch(e => {
                if (e.name !== 'AbortError') {
                    console.error('failed to load gov alerts in viewport:', e);
                }
            });
        return () => {
            alertTypesResult.abort();
        };
    }, [mapBoundsThrottled]);

    const classNamePrefix = props.className ? `${props.className}-` : '';
    const displayAlertTypes = (alertTypesResult.value ?? []).map(alertType => {
        return (
            <div key={alertType.name} className={`${classNamePrefix}alert`}>
                <div style={{ backgroundColor: alertType.color, width: 16, height: 16, borderRadius: 8, flex: 'none' }} />
                <div>{alertType.name}</div>
            </div>
        );
    });

    const columns = [];
    const columnLength = Math.ceil(displayAlertTypes.length / 3);
    for (let i = 0; i < displayAlertTypes.length; i += columnLength) {
        const chunk = displayAlertTypes.slice(i, i + columnLength);
        columns.push((<div className={`${classNamePrefix}alert-column`}>{chunk}</div>));
    }

    return (
        <div className={`${classNamePrefix}alerts-legend`} style={{ backgroundColor: cardBackgroundColor }}>
            <header className={`${classNamePrefix}alerts-legend-header`}>Active Alerts</header>
            <div className={`${classNamePrefix}alerts-legend-body`}>
                {columns.length === 0 ? "No alerts for this area." : columns}
            </div>
            {props.children}
        </div>
    );
};
