import { CycloneData, EarthquakeData, FireData, LightningData, LocationData, RoadClosureData, RoadStatusData, RoadWorkData, SpecialEventData, StoreState, StormData, TrafficCongestionData, TrafficIncidentData, TruckWarningData, VehicleTrackingData, VolcanoData, WeatherStationData, WildfireData } from '../../types';
import { connect } from 'react-redux';
import { ClientDashboardPage, DashboardOverlay } from '../../components/Client/Dashboard';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { ClearSaveCityError, SelectCity, createCity, deleteCity, geocodeCity, loadCity, loadRoute } from "../../actions/SelectedCity";
import { SetSelectedView, SetSelectedRoute, refreshRoute, SetSelectedVehicle, sendMessageToVehicle, SetSelectedOverlay, DashboardSetSelectedMap, DashboardSetTileOpacity } from 'src/actions/DashboardView';
import { RouteData } from 'src/types/routes';
import { DashboardView } from 'src/components/Client/Dashboard/ImpactDetailView';
import { ClearRouteRefreshError, OpenedPage } from 'src/actions/User';
import { loadEventsIfNeeded, loadWeatherIfNeeded } from 'src/actions/Weather';
import { TrafficClearSelectedRoadCondition, TrafficSetSelectedRoadClosure, TrafficSetSelectedRoadStatus, TrafficSetSelectedRoadWork, TrafficSetSelectedSpecialEvent, TrafficSetSelectedTrafficCongestion, TrafficSetSelectedTrafficIncident, TrafficSetSelectedTruckWarning, TrafficSetSelectedWeatherStation, TrafficSetTrafficLayer, loadRoadConditionsIfNeeded } from 'src/actions/TrafficView';
import { ClearSelectedEvent, ImpactSetCyclonesVisible, ImpactSetEarthquakesVisible, ImpactSetFiresVisible, ImpactSetLightningVisible, ImpactSetStormsVisible, ImpactSetVolcanoesVisible, ImpactSetWildfiresVisible, SelectCyclone, SelectEarthquake, SelectFire, SelectLightning, SelectStorm, SelectVolcano, SelectWildfire } from 'src/actions/ImpactView';
import { ImpactMapSection } from 'src/components/Client/Impact/sections';
import { MapType } from 'src/types/MapType';
import { loadGovernmentalAlertsIfNeeded, loadRatingsIfNeeded } from 'src/actions/Ratings';

export function mapStateToProps(state: StoreState, _ownProps: RouteComponentProps<any>) {
    return {
        userData: state.user,
        selectedView: state.dashboardView.selectedView,
        selectedOverlay: state.dashboardView.selectedOverlay,
        selectedRoute: state.dashboardView.selectedRoute,
        selectedCityState: state.selectedCity,
        selectedCity: state.selectedCity.selectedCity,
        selectedVehicle: state.dashboardView.selectedVehicle,
        selectedCyclone: state.impactView.selectedCyclone,
        selectedEarthquake: state.impactView.selectedEarthquake,
        selectedLightning: state.impactView.selectedLightning,
        selectedStorm: state.impactView.selectedStorm,
        selectedVolcano: state.impactView.selectedVolcano,
        selectedFire: state.impactView.selectedFire,
        selectedWildfire: state.impactView.selectedWildfire,

        selectedRoadStatus: state.trafficView.selectedRoadStatus,
        selectedRoadWork: state.trafficView.selectedRoadWork,
        selectedRoadClosure: state.trafficView.selectedRoadClosure,
        selectedSpecialEvent: state.trafficView.selectedSpecialEvent,
        selectedTrafficCongestion: state.trafficView.selectedTrafficCongestion,
        selectedTrafficIncident: state.trafficView.selectedTrafficIncident,
        selectedTruckWarning: state.trafficView.selectedTruckWarning,
        selectedWeatherStation: state.trafficView.selectedWeatherStation,

        ratingsData: state.ratings.ratingsData,
        weatherData: state.weather.weatherData,
        tileOpacity: state.dashboardView.tileOpacity,
        blurbs: state.ratings.blurbs,
        cyclones: state.weather.cyclones,
        earthquakes: state.weather.earthquakes,
        lightning: state.weather.lightning,
        storms: state.weather.storms,
        volcanoes: state.weather.volcanoes,
        fires: state.weather.fires,
        wildfires: state.weather.wildfires,

        roadStatus: state.trafficView.roadStatus,
        roadWork: state.trafficView.roadWork,
        roadClosures: state.trafficView.roadClosures,
        specialEvents: state.trafficView.specialEvents,
        trafficCongestion: state.trafficView.trafficCongestion,
        trafficIncidents: state.trafficView.trafficIncidents,
        truckWarnings: state.trafficView.truckWarnings,
        weatherStations: state.trafficView.weatherStations,

        impactTilesets: state.ratings.impactTilesets?.value,
        weatherTilesets: state.ratings.weatherTilesets?.value,
        governmentalAlerts: state.ratings.governmentalAlerts,

        isCyclonesVisible: state.impactView.isCyclonesVisible,
        isEarthquakesVisible: state.impactView.isEarthquakesVisible,
        isLightningVisible: state.impactView.isLightningVisible,
        isStormsVisible: state.impactView.isStormsVisible,
        isVolcanoesVisible: state.impactView.isVolcanoesVisible,
        isFiresVisible: state.impactView.isFiresVisible,
        isWildfiresVisible: state.impactView.isWildfiresVisible,

        isRoadStatusVisible: state.trafficView.isRoadStatusVisible,
        isRoadWorkVisible: state.trafficView.isRoadWorkVisible,
        isRoadClosuresVisible: state.trafficView.isRoadClosuresVisible,
        isSpecialEventsVisible: state.trafficView.isSpecialEventsVisible,
        isTrafficCongestionVisible: state.trafficView.isTrafficCongestionVisible,
        isTrafficIncidentsVisible: state.trafficView.isTrafficIncidentsVisible,
        isTruckWarningsVisible: state.trafficView.isTruckWarningsVisible,
        isWeatherStationsVisible: state.trafficView.isWeatherStationsVisible,

        showDisruptionIndex: state.user.showDisruptionIndex,
        showWildfireIndices: state.user.showWildfireIndices
    };
}

export function mapDispatchToProps(dispatch: ThunkDispatch<StoreState, void, Action<any>>) {
    return {
        setSelectedView: (view: DashboardView) => dispatch(SetSelectedView({ selectedView: view })),
        setSelectedOverlay: (overlay: DashboardOverlay) => dispatch(SetSelectedOverlay({ selectedOverlay: overlay })),
        setSelectedRoute: (route: RouteData | undefined) => dispatch(SetSelectedRoute({ selectedRoute: route })),
        onCitySelected: (city: LocationData | undefined) => {
            dispatch(SelectCity({ city }));
            if (city) {
                if (city.needsGeocoding) {
                    dispatch(geocodeCity(city));
                } else {
                    dispatch(loadWeatherIfNeeded());
                    dispatch(loadRatingsIfNeeded());
                }
            }
        },
        onCitySaved: (city: LocationData) => {
            dispatch(createCity(city));
        },
        onCityUnsaved: (city: LocationData) => {
            dispatch(deleteCity(city));
        },
        onCitySaveErrorDismissed: (city: LocationData) => {
            dispatch(ClearSaveCityError({ city }));
        },
        onSetTileOpacity: (tileOpacity: number) => {
            dispatch(DashboardSetTileOpacity({ tileOpacity }));
        },
        onVehicleSelected: (vehicle: VehicleTrackingData) => dispatch(SetSelectedVehicle({ selectedVehicle: vehicle })),
        onVehicleMessageRequested: (vehicle: VehicleTrackingData, message: string, urgent: boolean) => dispatch(sendMessageToVehicle(vehicle, message, urgent)),
        onRouteRefreshRequested: (route: RouteData) => dispatch(refreshRoute(route)),
        onRouteRefreshErrorDismissed: () => dispatch(ClearRouteRefreshError()),
        onMapSelected: (selectedMapCategory: ImpactMapSection, selectedMapType: MapType) => {
            dispatch(DashboardSetSelectedMap({ selectedMapCategory, selectedMapType }));
            dispatch(loadWeatherIfNeeded());
            dispatch(loadRatingsIfNeeded());
        },
        onTrafficLayerSelected: (layer: DashboardOverlay) => {
            dispatch(TrafficSetTrafficLayer({ layer }));
            dispatch(TrafficClearSelectedRoadCondition());
        },
        onLoadEvents: () => {
            dispatch(loadEventsIfNeeded());
        },
        onLoadRoadStatus: () => {
            dispatch(loadRoadConditionsIfNeeded());
        },
        onCyclonesToggled: (visible: boolean) => {
            dispatch(ImpactSetCyclonesVisible({ visible }));
        },
        onEarthquakesToggled: (visible: boolean) => {
            dispatch(ImpactSetEarthquakesVisible({ visible }));
        },
        onLightningToggled: (visible: boolean) => {
            dispatch(ImpactSetLightningVisible({ visible }));
        },
        onStormsToggled: (visible: boolean) => {
            dispatch(ImpactSetStormsVisible({ visible }));
        },
        onVolcanoesToggled: (visible: boolean) => {
            dispatch(ImpactSetVolcanoesVisible({ visible }));
        },
        onFiresToggled: (visible: boolean) => {
            dispatch(ImpactSetFiresVisible({ visible }));
        },
        onWildfiresToggled: (visible: boolean) => {
            dispatch(ImpactSetWildfiresVisible({ visible }));
        },
        onCycloneSelected: (cyclone: CycloneData) => {
            dispatch(TrafficClearSelectedRoadCondition());
            dispatch(SelectCyclone({ cyclone }));
        },
        onEarthquakeSelected: (earthquake: EarthquakeData) => {
            dispatch(TrafficClearSelectedRoadCondition());
            dispatch(SelectEarthquake({ earthquake }));
        },
        onLightningSelected: (lightning: LightningData) => {
            dispatch(TrafficClearSelectedRoadCondition());
            dispatch(SelectLightning({ lightning }));
        },
        onStormSelected: (storm: StormData) => {
            dispatch(TrafficClearSelectedRoadCondition());
            dispatch(SelectStorm({ storm }));
        },
        onVolcanoSelected: (volcano: VolcanoData) => {
            dispatch(TrafficClearSelectedRoadCondition());
            dispatch(SelectVolcano({ volcano }));
        },
        onFireSelected: (fire: FireData) => {
            dispatch(TrafficClearSelectedRoadCondition());
            dispatch(SelectFire({ fire }));
        },
        onWildfireSelected: (wildfire: WildfireData) => {
            dispatch(TrafficClearSelectedRoadCondition());
            dispatch(SelectWildfire({ wildfire }));
        },
        onRoadStatusSelected: (roadStatus?: RoadStatusData) => {
            dispatch(ClearSelectedEvent());
            dispatch(TrafficSetSelectedRoadStatus({ roadStatus }));
        },
        onRoadWorkSelected: (roadWork?: RoadWorkData) => {
            dispatch(ClearSelectedEvent());
            dispatch(TrafficSetSelectedRoadWork({ roadWork }));
        },
        onRoadClosureSelected: (roadClosure?: RoadClosureData) => {
            dispatch(ClearSelectedEvent());
            dispatch(TrafficSetSelectedRoadClosure({ roadClosure }));
        },
        onSpecialEventSelected: (specialEvent?: SpecialEventData) => {
            dispatch(ClearSelectedEvent());
            dispatch(TrafficSetSelectedSpecialEvent({ specialEvent }));
        },
        onTrafficCongestionSelected: (trafficCongestion?: TrafficCongestionData) => {
            dispatch(ClearSelectedEvent());
            dispatch(TrafficSetSelectedTrafficCongestion({ trafficCongestion }));
        },
        onTrafficIncidentSelected: (trafficIncident?: TrafficIncidentData) => {
            dispatch(ClearSelectedEvent());
            dispatch(TrafficSetSelectedTrafficIncident({ trafficIncident }));
        },
        onTruckWarningSelected: (truckWarning?: TruckWarningData) => {
            dispatch(ClearSelectedEvent());
            dispatch(TrafficSetSelectedTruckWarning({ truckWarning }));
        },
        onWeatherStationSelected: (weatherStation?: WeatherStationData) => {
            dispatch(ClearSelectedEvent());
            dispatch(TrafficSetSelectedWeatherStation({ weatherStation }));
        },
        onClearSelectedEvent: () => {
            dispatch(ClearSelectedEvent());
        },
        onClearSelectedRoadCondition: () => {
            dispatch(TrafficClearSelectedRoadCondition());
        },
        openedPage: (page: string) => {
            dispatch(OpenedPage({ page }));
        },

        loadLocation: (locationId: string) => dispatch(loadCity(locationId)),
        loadRoute: (routeId: string) => dispatch(loadRoute(routeId)),
        loadGovernmentalAlertsIfNeeded: () => dispatch(loadGovernmentalAlertsIfNeeded()),
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ClientDashboardPage));
