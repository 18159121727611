import * as React from 'react';
import { cardBackgroundColor } from "../../../constants";
import { RouteResults } from 'src/types/routes';
import { Blurb } from 'src/types';


interface HistoricalAccuracyRouteViewProps {
    description: string;
    selectedRoute: RouteResults;
    impactBlurbs: Blurb[];

    lat: number;
    lng: number;

    onClose: () => void;
}

export const HistoricalAccuracyRouteView = (props: HistoricalAccuracyRouteViewProps) => {
    const {description, selectedRoute, impactBlurbs} = props;

    return (
        <div className={'MapCalloutView'} onClick={(event) => event.stopPropagation()}>
            <div className={"route-container"}  style={{cursor: "default"}}>
                <div className={"body"} style={{backgroundColor: cardBackgroundColor}}>
                    <button className={"close-button"} onClick={() => props.onClose()}>
                        <img alt={'Close'} src={"/images/popover_x.svg"}/>
                    </button>
                    <div className={'historical-accuracy-route-content'}>
                        <div className={'historical-accuracy-route-content-title'}>
                            Sample Route:
                        </div>
                        <div className={'historical-accuracy-route-content-text'}>
                            {description}
                        </div>
                    </div>
                    <div className={'historical-accuracy-route-content'}>
                        <div className={'historical-accuracy-route-content-title'}>
                            Max Speed Reduction:
                        </div>
                        <div className={'historical-accuracy-route-content-text'}>
                            {Math.round(selectedRoute.slowdownFraction * 100)}%
                        </div>
                    </div>
                    <div className={'historical-accuracy-route-content'}>
                        <div className={'historical-accuracy-route-content-title'}>
                            Max Road Index:
                        </div>
                        <div className={'historical-accuracy-route-content-text'}>
                            {`${selectedRoute.maxRoadIndex} - ${impactBlurbs[Math.floor(selectedRoute.maxRoadIndex)].blurb}`}
                        </div>
                    </div>
                    <div className={'historical-accuracy-route-content'}>
                        <div className={'historical-accuracy-route-content-title'}>
                            Extreme Weather Flag:
                        </div>
                        <div className={'historical-accuracy-route-content-text'}>
                            {selectedRoute.weatherFlags.indexOf('extreme_weather_flag') !== -1 ? "True - high likelihood of road closures" : "False - low likelihood of road closures"}
                        </div>
                    </div>
                </div>
                <div className={"arrow-down"} style={{borderTop: `20px solid ${cardBackgroundColor}`}}/>
            </div>
        </div>
    );
};
