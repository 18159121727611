import { AlertData, LocationData } from '../../../types';
import * as React from 'react';
import { Close, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { IconButton, Paper, Tab, Tabs } from '@mui/material';
import { cardBackgroundColor } from 'src/constants';

interface AlertDetailsProps {
    alerts: AlertData[];
    location?: LocationData;
    portalTab?: string;
    setShowAlertDetails?: (show: boolean) => void;
}
export const AlertDetailsComponent = (props: AlertDetailsProps) => {
    const [alertDetailsEl, setAlertDetailsEl] = React.useState<HTMLDivElement | null>(null);
    const [collapsed, setCollapsed] = React.useState(false);
    const [selectedAlertForDetail, setSelectedAlertForDetail] = React.useState(props.alerts[0]);

    let mainClassName = props.portalTab === "dashboard" ? "DashboardAlertDetailsComponent" : "AlertDetailsComponent";
    let auxiliaryClassName = collapsed ? "collapsed" : "expanded";
    let className = `${mainClassName} ${auxiliaryClassName}`;

    if (!selectedAlertForDetail) {
        return <div></div>;
    }

    let alertDescription: JSX.Element | undefined = undefined;
    if (props.portalTab === "dashboard") {
        // dashboard tab use list
        alertDescription = (
            <ul>
                {selectedAlertForDetail.details.body.split("*").map(item => {
                    item = item.trim();
                    // saw an example where the ADDITIONAL DETAILS wasn't a list with hyphens
                    // so I made the check more specific to the pattern we're targeting
                    const needle = "ADDITIONAL DETAILS...\n-";
                    if (item.startsWith(needle)) {
                        return (<li>ADDITIONAL DETAILS
                            <ul>
                                {item.substring(needle.length + 1).split("-").map(addItem => (<li>{addItem.trim()}</li>))}
                            </ul>
                        </li>
                        );
                    } else if (item === "") {
                        return "";
                    } else {
                        return (<li>{item}</li>);
                    }
                })}
            </ul>);
    } else {
        // alerts tab use columns 
        alertDescription = (<>{selectedAlertForDetail.details.body}</>);
    }

    return (
        <div className={className}>
            <Paper elevation={3} style={{ backgroundColor: cardBackgroundColor, backgroundImage: 'none', borderRadius: 11, padding: 10, height: "95%" }}>
                <header className={`${mainClassName}-tabbar`}>
                    <Tabs
                        value={selectedAlertForDetail.id}
                        onChange={(event, newAlertId) => {
                            const newAlert = props.alerts.filter(x => x.id === newAlertId)[0];
                            setSelectedAlertForDetail(newAlert);
                            setCollapsed(false);
                            if (alertDetailsEl) {
                                alertDetailsEl.scrollTop = 0;
                            }
                        }}
                        indicatorColor={'primary'}
                        textColor={'primary'}
                        variant="scrollable"
                        scrollButtons="auto"
                        style={{ width: '100%', height: 50 }}
                    >
                        {props.alerts.map((alert: AlertData) => (
                            <Tab label={
                                <div className={`${mainClassName}-header`}>
                                    <div className={"active-alert-type"}>{alert.details.name}</div>
                                </div>} value={alert.id} />
                        ))}
                    </Tabs>
                    {props.portalTab === 'dashboard' ?
                        <IconButton
                            color={'primary'}
                            onClick={() => props.setShowAlertDetails && props.setShowAlertDetails(false)}
                            style={{ height: 40 }}
                        >
                            <Close />
                        </IconButton>
                        :
                        <IconButton
                            color={'primary'}
                            onClick={() => setCollapsed(!collapsed)}
                            style={{ height: 40 }}
                        >
                            {collapsed ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                        </IconButton>
                    }

                </header>
                {!collapsed && (<div ref={setAlertDetailsEl} className={`${mainClassName}-body`}>
                    <div className={"active-alert-issued"}>
                        Issued
                        {" "}
                        {new Date(selectedAlertForDetail.timestamps.issued).toLocaleDateString('en-US', { month: 'numeric', day: 'numeric', year: 'numeric' })}
                        {", "}
                        {new Date(selectedAlertForDetail.timestamps.issued).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true, timeZoneName: 'short' })}
                    </div>

                    <div className={"active-alert-full-description"}>
                        {alertDescription}
                    </div>
                </div>)}
            </Paper >
        </div>
    );
};
