export enum BatchType {
    Location = 'location',
    Route = 'route'
}

export interface PlacesViewState {
    selectedTab: number;
    isUploadDialogOpen: boolean;
    uploadBatchType: BatchType;
    fileName?: string;
    isUploading: boolean;
    uploadErrors: string[];
    pendingBatchUploadId?: number;
    isProcessing: boolean;
    processingError?: string;
}
